export const messageTypes = {
    pickup: "pickup",
    item: "item",
    problem: "divider",
    hospiceProfit: "profit/non-profit",
    hospiceStatus: "active/inactive",
    notice: "notice",
    patientOrder: "patientOrder",
    noPatientData: "noPatientData",
    dmeStatus: "dmeStatus",
    userStatus: "userStatus",
    userMFA: "userMFA",
    addToCart: "addToCart",
    archived: "Archived",
    emailFound: "emailFound",
    emailNotFound: "emailNotFound",
    contractStatus: "contractStatus",
    deleteUser: "deleteUser",
    contractSelectItems: "contractSelectItems",
    orderCreated: "orderCreated",
    archivedPatient: "archivedPatient",
    disabledTechnician: "disabledTechnician",
};

export const notificationAccordionSummaryTypes = {
    openOrder: "Open State",
    pendingOrder: "Pending State",
    processingOrder: "Processing State",
    surveyUserResponses: "User Responses",
    patientCreation: "Created Patient",
}