import React, {useEffect, useState} from "react";
import {alertMessages} from "../../../constants/alert/alertMessages";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {sectionTitles} from "../../../constants/names/titles";
import Grid from "@material-ui/core/Grid";
import {ItemElement} from "../../../common/addItem/AddItem";
import {AlertDialog} from "../../../common/alerts/generalAlert/generalAlert";
import {alertTitles} from "../../../constants/alert/alertTitle";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import {buttonTypes} from "../../../constants/alert/buttonTypes";
import {makeStyles} from "@material-ui/core/styles";
import {endpoints} from "../../../constants/endpoints";
import {status} from "../../../constants/status/status";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import MaterialTable from "material-table";
import {tableColumnTitles} from "../../../constants/names/columnTitles";
import jwt from "jwt-decode";
import _ from "lodash";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useDispatch} from "react-redux";

export const LogOxygenTanks = ({}) => {

    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const token = JSON.parse(localStorage.getItem('key'));
    const userData = jwt(token.accessToken).payload;

    const [lot, setLot] = useState([""]);
    const [asset, setAsset] = useState([""]);

    const [validLot, setValidLot] = useState([true]);
    const [validAsset, setValidAsset] = useState([true]);

    const [validationError, setValidationError] = useState(false);
    const [validationErrorMessage, setValidationErrorMessage] = useState([]);

    const [warehouse, setWarehouse] = useState([""]);
    const [validWarehouse, setValidWarehouse] = useState([false]);

    const [completionMessage, setCompletionMessage] = useState("");
    const [viewLogs, setViewLogs] = useState(false);
    const [data, setData] = useState([]);
    const [warehouseList, setWarehouseList] = useState([]);
    const [warehouseData, setWarehouseData] = useState();

    const fields = [
        {type: "input", name: "asset", label: "Asset #", value: asset, validator: validAsset},
        {type: "input", name: "lot", label: "Lot #", value: lot, validator: validLot},
        {
            type: "select",
            options: warehouseList,
            name: "warehouse",
            label: "Warehouse",
            value: warehouse,
            isMandatory: true,
            tooltip: "This Field is Mandatory!"
        },
    ];

    const columns = [
        {title: tableColumnTitles.asset, field: 'asset', width: 400},
        {title: tableColumnTitles.lot, field: 'lot', width: 400},
    ];


    const handleInput = (event, index) => {
        const {name, value} = event.target;
        let newValidator;
        switch (name) {
            case "asset":
                let newAsset = [...asset];
                newValidator = [...validAsset];
                newAsset[index] = value;
                newValidator[index] = true;
                setAsset(newAsset);
                setValidAsset(newValidator);
                break;
            case "lot":
                let newLot = [...lot];
                newValidator = [...validLot];
                newLot[index] = value;
                newValidator[index] = true;
                setLot(newLot);
                setValidLot(newValidator);
                break;
            case "warehouse":
                let newWarehouse = [...warehouse];
                let newValidWarehouse = [...validWarehouse];
                newWarehouse[index] = value;
                newValidWarehouse[index] = true;
                setWarehouse(newWarehouse);
                setValidWarehouse(newValidWarehouse);
                break;
            default:
                console.log(`[Warning]: Input type --> ${name} <-- not handled!`);
        }
    };

    const handleKeyPress = (event) => {
        event.preventDefault();
        addNewItem(asset.length - 1);
    };

    const switchToViewLogs = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        if (viewLogs === false) {
            fetch(endpoints.oxygenLogs + userData.dme_organization_id, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            }).then(res => {
                return res.json()
            }).then(res => {
                if (res.status === "success") {
                    setData(res.data);
                    setViewLogs(true);
                }
            })
        } else {
            setViewLogs(false);
        }
    };

    const addNewItem = (index, duplicate) => {

        const insertUnderIndex = index + 1;

        let newLot = [...lot];
        let newAsset = [...asset];
        let newWarehouse = [...warehouse];

        let newValidLot = [...validLot];
        let newValidAsset = [...validAsset];
        let newValidWarehouse = [...validWarehouse];

        if (duplicate) {
            newLot.splice(insertUnderIndex, 0, newLot[index]);
            newAsset.splice(insertUnderIndex, 0, "");
            newWarehouse.splice(insertUnderIndex, 0, newWarehouse[index]);

            newValidLot.splice(insertUnderIndex, 0, newValidLot[index]);
            newValidAsset.splice(insertUnderIndex, 0, newValidAsset[index]);
            newValidWarehouse.splice(insertUnderIndex, 0, newValidWarehouse[index]);
        } else {
            newAsset.splice(insertUnderIndex, 0, "");
            newLot.splice(insertUnderIndex, 0, "");
            newWarehouse.splice(insertUnderIndex, 0, "");

            newValidAsset.splice(insertUnderIndex, 0, true);
            newValidLot.splice(insertUnderIndex, 0, true);
            newValidWarehouse.splice(insertUnderIndex, 0, true);
        }

        setLot(newLot);
        setAsset(newAsset);
        setWarehouse(newWarehouse);

        setValidLot(newValidLot);
        setValidAsset(newValidAsset);
        setValidWarehouse(newValidWarehouse);
    };

    const removeItem = (index) => {

        let newLot = [...lot];
        let newAsset = [...asset];
        let newWarehouse = [...warehouse];

        let newValidAsset = [...validAsset];
        let newValidLot = [...validLot];
        let newValidWarehouse = [...validWarehouse];

        newAsset.splice(index, 1);
        newLot.splice(index, 1);
        newWarehouse.splice(index, 1);

        newValidAsset.splice(index, 1);
        newValidLot.splice(index, 1);
        newValidWarehouse.splice(index, 1);

        if (index === 0 && asset.length === 1) {
            newAsset.push("");
            newLot.push("");
            newWarehouse.push("");

            newValidLot.push(true);
            newValidAsset.push(true);
            newValidWarehouse.push(true);
        }

        setAsset(newAsset);
        setLot(newLot);
        setWarehouse(newWarehouse);

        setValidLot(newValidLot);
        setValidAsset(newValidAsset);
        setValidAsset(newValidWarehouse);
    };

    const checkUploadStatus = () => {
        let status = true;
        for (let index = 0; index < asset.length; index++) {
            if (validLot[index] === false || validAsset [index] === false || validWarehouse[index] === false) {
                status = false;
            }
        }
        if (status === true) {
            setValidationErrorMessage([alertMessages.itemsWillBeAdded]);
        } else {
            setValidationErrorMessage([alertMessages.invalidItems]);
        }
        setValidationError(status);
    };

    const clearAll = () => {
        setAsset([""]);
        setLot([""]);
        setWarehouse([""]);

        setValidLot([true]);
        setValidAsset([true]);
        setValidWarehouse([true]);
    };

    const getUserWarehouses = async () => {
        const promise = await fetch(endpoints.getUserWarehouses + userData.user_id, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        let arr = [];
        if(response && response.data) {
            for (const item of response.data) {
                arr.push({value: item.name, label: item.name})
            }
        }
        setWarehouseList(arr);
        setWarehouseData(response.data);
    };

    useEffect(() => {
        getUserWarehouses();
    }, []);

    useEffect(() => {
        checkUploadStatus();
    }, [validAsset, validLot, validWarehouse]);

    const uploadInventory = () => {
        let items = [];
        let index;
        for (index = 0; index < asset.length; index++) {
            items.push({
                asset: asset[index],
                lot: lot[index],
                warehouse_id: warehouseData[_.findIndex(warehouseData, {"name": warehouse[index]})].warehouse_id
            })
        }
        const body = {};
        body["items"] = items;
        body["dme_organization_id"] = userData.dme_organization_id;

        fetch(endpoints.oxygenLogs, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(body),
        }).then(res => {
            if (res.status === status.created) {
                setCompletionMessage("Logs Created");
                setAsset([""]);
                setLot([""]);
                setWarehouse([""]);
                setValidAsset([true]);
                setValidLot([true]);
                setValidWarehouse([true]);
            } else {
                setCompletionMessage("An error occurred");

            }
        })
    };

    return (
        <React.Fragment>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    {viewLogs === false ?
                        <Grid container>
                            <Typography component="h1" variant="h5" align="center" style={{paddingTop: 10}}>
                                {sectionTitles.addNewItems}
                            </Typography>
                            <Grid container spacing={1} direction="column">
                                {asset.map((itemValues, index) =>
                                    <Grid item key={index} style={{zIndex: asset.length - index}}>
                                        {/*<Typography>*/}
                                        {/*    {"Item " + (index + 1)}*/}
                                        {/*</Typography>*/}
                                        <ItemElement fields={fields}
                                                     index={index}
                                                     handleInput={handleInput}
                                                     addNewItem={addNewItem}
                                                     removeItem={removeItem}
                                                     allItems={asset}
                                                     handleKeyPress={handleKeyPress}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container justify="center">
                                <Button
                                    variant="outlined"
                                    color="default"
                                    className={classes.button}
                                    onClick={() => {
                                        history.push(`/inventory`)
                                    }}
                                >
                                    {buttonText.backToInventory}
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="default"
                                    className={classes.button}
                                    onClick={switchToViewLogs}
                                >
                                    {buttonText.viewLogs}
                                </Button>
                                <AlertDialog
                                    title={alertTitles.warn}
                                    denyActionText={buttonText.negative}
                                    confirmActionText={buttonText.proceed}
                                    content={alertMessages.clearWarning}
                                    confirmAction={clearAll}
                                    buttonText={buttonText.clearItems}
                                    type={buttonTypes.deleteIcon}
                                    disabledButton={false}
                                />
                                <AlertDialog
                                    title={alertTitles.warn}
                                    denyActionText={buttonText.negative}
                                    confirmActionText={buttonText.proceed}
                                    content={validationErrorMessage}
                                    confirmAction={uploadInventory}
                                    buttonText={buttonText.addItems}
                                    type={buttonTypes.uploadIcon}
                                    disabledButton={!validationError}
                                />
                            </Grid>
                        </Grid> : <Grid container justify={"center"} direction={"column"}>
                            <Grid item>
                                <MaterialTable
                                    title={sectionTitles.viewOxygenLogs}
                                    columns={columns}
                                    data={data}
                                    isLoading={data === []}
                                    options={{
                                        pageSize: 10,

                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={switchToViewLogs}
                                >
                                    {buttonText.addLots}
                                </Button>
                            </Grid>
                        </Grid>
                    }
                </Paper>
            </main>
        </React.Fragment>
    )
};

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
            width: 1200,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        width: 'auto',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));
