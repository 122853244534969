import React from 'react';
import moment from "moment";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {reportTypes} from "../../../../constants/types/reportTypes";
import {dollarCurrencyFormatter} from "../../../../common/utils/utils";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const exportPDF = (hospiceLogo, reportType, tableData) => {
    const today = moment().format('MMMM Do, YYYY');
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];

    let quantityBasedColumns = [{text: 'Item', style: 'tableHeader', value: 'billedItem'},
        {text: 'Patient Name', style: 'tableHeader', value: 'fullName'},
        {text: 'Monthly Cost', style: 'tableHeader', value: 'totalDays'},
        {text: 'Month', style: 'tableHeader', value: 'month'},
    ];

    let riskBasedColumns = [{text: 'Item', style: 'tableHeader', value: 'billedItem'},
        {text: 'Patient Name', style: 'tableHeader', value: 'fullName'},
        {text: 'Monthly Cost', style: 'tableHeader', value: 'totalDays'},
        {text: 'Month', style: 'tableHeader', value: 'month'},
    ];

    let noQuantityNoRiskBasedColumns = [{text: 'Item', style: 'tableHeader', value: 'billedItem'},
        {text: 'Patient Name', style: 'tableHeader', value: 'fullName'},
        {text: 'Monthly Cost', style: 'tableHeader', value: 'totalDays'},
        {text: 'Month', style: 'tableHeader', value: 'month'},
    ];

    function buildTableBody(data, columns) {
        let body = [];
        body.push(columns);
        data.forEach(function (row) {
            let dataRow = [];
            columns.forEach(function (column) {
                dataRow.push(row[column.value].toString());
            })
            body.push(dataRow);
        });
        return body;
    }

    function buildQuarterCalculation(quarterData) {
        let body = [
            [{text: 'Total', style: 'tableHeader'}, {text: 'Type', style: 'tableHeader'}],
            ['Total Patient Days', quarterData.totalPatientDays.toLocaleString()],
            ['CPPD - Patient Days', dollarCurrencyFormatter(quarterData.cppdPrice)],
            ['Total CPPD - Per Diem', dollarCurrencyFormatter(quarterData.totalCppdPrice)],
            ['Total DME Spend', dollarCurrencyFormatter(quarterData.totalDmeSpend)],
            ['Outlier Total', dollarCurrencyFormatter(quarterData.outlierTotal)],
            ['Outlier % of Spend', quarterData.outlierSpend + '%'],
            ['Cost Per Patient Day', dollarCurrencyFormatter(quarterData.costPerPatientDay)],
        ]
        return body;
    }

    function buildQuarterCalculationByMonth(quarterData) {
        let monthsPerQuarter = [];
        let months = [];
        for (let monthItem of quarterData.q1.allMonthsTotalPatientDays) {
            months.push(monthItem.month);
        }
        for (let month of months) {
            let bodyPerMonth = [
                [{text: 'Total', style: 'tableHeader'}, {text: 'Type', style: 'tableHeader'}],
                ['Total Patient Days', quarterData.q1[`${month}_totalPatientDays`].toLocaleString()],
                ['CPPD - Patient Days', dollarCurrencyFormatter(quarterData.q1[`${month + '_cppdPrice'}`])],
                ['Total CPPD - Per Diem', dollarCurrencyFormatter(quarterData.q1[`${month + '_totalCppdPrice'}`])],
                ['Total DME Spend', dollarCurrencyFormatter(quarterData.q1[`${month + '_totalDmeSpend'}`])],
                ['Outlier Total', dollarCurrencyFormatter(quarterData.q1[`${month + '_outlierTotal'}`])],
                ['Outlier % of Spend', quarterData.q1[`${month + '_outlierSpend'}`] + '%'],
                ['Cost Per Patient Day', dollarCurrencyFormatter(quarterData.q1[`${month + '_costPerPatientDay'}`])],
            ];
            let objPerMonth = {
                style: 'tableStyle',
                table: {
                    headerRows: 1,
                    body: bodyPerMonth,
                    unbreakable: true
                },
                layout: {
                    hLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 2 : 1;
                    },
                    vLineWidth: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                    },
                    hLineColor: function (i, node) {
                        return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                    },
                    vLineColor: function (i, node) {
                        return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                    },
                }
            };
            let monthName = {
                    text: month,
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 18,
                    margin: [0, 20, 0, 0],
                };
            monthsPerQuarter.push(monthName);
            monthsPerQuarter.push(objPerMonth);
        }

        return monthsPerQuarter;
    }

    function buildContent(reportType) {
        if (reportType === reportTypes.quarterly) {
            return [
                {
                    text: ['Quarter 1'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: buildQuarterCalculation(tableData.patientDayComponent.q1),
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Quarter 1 Breakdown by Month'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                buildQuarterCalculationByMonth(tableData.patientDayComponent),
                {
                    text: ['Outlier Q1'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    text: ['Patient Billed Based On Quantity'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: buildTableBody(tableData.outlierSpendComponent.q1.extendedQuantityData.extendedCostInformation, quantityBasedColumns),
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Patient Billed Based On Risk'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Item', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Monthly Cost', style: 'tableHeader'},
                                {text: 'Month', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Patient Billed Based On No Risk No Quantity'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Item', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Monthly Cost', style: 'tableHeader'},
                                {text: 'Delivery Date', style: 'tableHeader'},
                                {text: 'Pickup Date', style: 'tableHeader'},
                                {text: 'Days Of Service In Quarter', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Utilization - Top 10 Items Q1'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    text: ['Utilization - Lowest 10 Items Q1'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    text: ['Lost Items Q1'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Pickup Order Id', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Completed Date', style: 'tableHeader'},
                                {text: 'Item Name', style: 'tableHeader'},
                                {text: 'Location', style: 'tableHeader'},
                                {text: 'Location Description', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
            ]
        }
        if (reportType === reportTypes.yearly) {
            return [
                {
                    text: ['Quarter 1'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: buildQuarterCalculation(tableData.patientDayComponent.q1),
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Quarter 2'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: buildQuarterCalculation(tableData.patientDayComponent.q2),
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Quarter 3'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: buildQuarterCalculation(tableData.patientDayComponent.q3),
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Quarter 4'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: buildQuarterCalculation(tableData.patientDayComponent.q4),
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Quarter 1 Breakdown by Month'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    text: ['Quarter 2 Breakdown by Month'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    text: ['Quarter 3 Breakdown by Month'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    text: ['Quarter 4 Breakdown by Month'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    text: ['Outlier Q1'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    text: ['Patient Billed Based On Quantity'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: buildTableBody(tableData.outlierSpendComponent.q1.extendedQuantityData.extendedCostInformation, quantityBasedColumns),
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Patient Billed Based On Risk'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Item', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Monthly Cost', style: 'tableHeader'},
                                {text: 'Month', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Patient Billed Based On No Risk No Quantity'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Item', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Monthly Cost', style: 'tableHeader'},
                                {text: 'Delivery Date', style: 'tableHeader'},
                                {text: 'Pickup Date', style: 'tableHeader'},
                                {text: 'Days Of Service In Quarter', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Outlier Q2'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    text: ['Patient Billed Based On Quantity'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: buildTableBody(tableData.outlierSpendComponent.q2.extendedQuantityData.extendedCostInformation, quantityBasedColumns),
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Patient Billed Based On Risk'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Item', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Monthly Cost', style: 'tableHeader'},
                                {text: 'Month', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Patient Billed Based On No Risk No Quantity'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Item', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Monthly Cost', style: 'tableHeader'},
                                {text: 'Delivery Date', style: 'tableHeader'},
                                {text: 'Pickup Date', style: 'tableHeader'},
                                {text: 'Days Of Service In Quarter', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Outlier Q3'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    text: ['Patient Billed Based On Quantity'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: buildTableBody(tableData.outlierSpendComponent.q3.extendedQuantityData.extendedCostInformation, quantityBasedColumns),
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Patient Billed Based On Risk'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Item', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Monthly Cost', style: 'tableHeader'},
                                {text: 'Month', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Patient Billed Based On No Risk No Quantity'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Item', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Monthly Cost', style: 'tableHeader'},
                                {text: 'Delivery Date', style: 'tableHeader'},
                                {text: 'Pickup Date', style: 'tableHeader'},
                                {text: 'Days Of Service In Quarter', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Outlier Q4'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    text: ['Patient Billed Based On Quantity'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: buildTableBody(tableData.outlierSpendComponent.q4.extendedQuantityData.extendedCostInformation, quantityBasedColumns),
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Patient Billed Based On Risk'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Item', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Monthly Cost', style: 'tableHeader'},
                                {text: 'Month', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Patient Billed Based On No Risk No Quantity'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 16,
                    margin: [0, 20, 0, 0],
                },
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Item', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Monthly Cost', style: 'tableHeader'},
                                {text: 'Delivery Date', style: 'tableHeader'},
                                {text: 'Pickup Date', style: 'tableHeader'},
                                {text: 'Days Of Service In Quarter', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Lost Items Q1'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Pickup Order Id', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Completed Date', style: 'tableHeader'},
                                {text: 'Item Name', style: 'tableHeader'},
                                {text: 'Location', style: 'tableHeader'},
                                {text: 'Location Description', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Lost Items Q2'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Pickup Order Id', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Completed Date', style: 'tableHeader'},
                                {text: 'Item Name', style: 'tableHeader'},
                                {text: 'Location', style: 'tableHeader'},
                                {text: 'Location Description', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Lost Items Q3'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Pickup Order Id', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Completed Date', style: 'tableHeader'},
                                {text: 'Item Name', style: 'tableHeader'},
                                {text: 'Location', style: 'tableHeader'},
                                {text: 'Location Description', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
                {
                    text: ['Lost Items Q4'],
                    fillColor: '#F2F2F2',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {canvas: [{type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1}]},
                {
                    style: 'tableStyle',
                    table: {
                        headerRows: 1,
                        body: [
                            [{text: 'Pickup Order Id', style: 'tableHeader'},
                                {text: 'Patient Name', style: 'tableHeader'},
                                {text: 'Completed Date', style: 'tableHeader'},
                                {text: 'Item Name', style: 'tableHeader'},
                                {text: 'Location', style: 'tableHeader'},
                                {text: 'Location Description', style: 'tableHeader'},
                            ],
                        ],
                        unbreakable: true
                    },
                    layout: {
                        hLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 2 : 1;
                        },
                        vLineWidth: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 2 : 1;
                        },
                        hLineColor: function (i, node) {
                            return (i === 0 || i === node.table.body.length) ? 'black' : 'gray';
                        },
                        vLineColor: function (i, node) {
                            return (i === 0 || i === node.table.widths.length) ? 'black' : 'gray';
                        },
                    }
                },
            ]
        }
    }

    let template = {
        content: [
            buildContent(reportType)
        ],
        header: {
            columns: [
                {
                    image: hospiceLogo,
                    fit: [60, 60],
                    style: 'headerLogo',
                },
                {
                    text: today,
                    style: 'headerText',
                    alignment: 'center'
                },
                {
                    text: reportType,
                    style: 'headerText',
                    alignment: 'right'
                },
            ]
        },
        pageMargins: [40, 60, 40, 60],
        pageSize: 'A4',
        styles: {
            headerLogo: {
                marginTop: 20,
                marginLeft: 40
            },
            headerText: {
                marginTop: 30,
                marginRight: 20
            },
            tableStyle: {
                margin: [0, 10, 0, 10]
            },
            tableHeader: {
                bold: true,
                fontSize: 14,
                color: 'black',
                fillColor: '#a7a7a9',
            }
        }
    }
    pdfMake.createPdf(template).print();
};