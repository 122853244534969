import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import OrganizationDetails from './organizationDetails';
import ConfigureServiceAgreement from './configureServiceAgreement';
import Finalize from './Finalize';
import './add-new-patient.css'
import {useHistory} from "react-router-dom";
import CancelActionAlert from "../../../../common/alerts/cancelActionAlert/cancelActionAlert";
import {alertLevel, cancellingAlertFlags} from "../../../../constants/alert/alertFlags";
import {endpoints} from "../../../../constants/endpoints";
import _ from "lodash";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Review from "./review";
import {sectionTitles} from "../../../../constants/names/titles";
import {CircularProgress} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import {useSnackbar} from "notistack";
import {getMessage} from "../../../../constants/messages/messages";
import {messageTypes} from "../../../../constants/types/messageTypes";
import {itemTypes} from "../../../../constants/types/ItemTypes";
import ConfigureServiceAgreementSecondStep from "./configureServiceAgreementSecondStep";
import {validators} from "../../../../constants/validations/validators";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        marginTop: theme.spacing(4),
        width: 'auto',
        // marginLeft: theme.spacing(2),
        // marginRight: theme.spacing(2),
        [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
            minWidth: 1200,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        width: 'auto',
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    paper: {
        padding: 20
    },
    title: {
        paddingTop: 10,
        paddingLeft: 10,
        fontSize: 20
    }
}));

export default function CreateContract(props) {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const data = JSON.parse(localStorage.getItem('key'));
    const edit = props.edit;
    let history = useHistory();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [showAddNewDME, setShowAddNewDME] = useState(false);
    const [masterItems, setMasterItems] = useState([]);
    const [dmeData, setDmeData] = useState([]);
    const [dmeList, setDmeList] = useState([]);
    const [hospiceData, setHospiceData] = useState([]);
    const [hospiceList, setHospiceList] = useState([]);
    const [wasSuccessful, setWasSuccessful] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [hospiceRegionsData, setHospiceRegionsData] = useState([]);
    const [hospiceRegionsList, setHospiceRegionsList] = useState([]);
    const [contractedItems, setContractedItems] = useState({});
    const [initialEdit, setInitialEdit] = useState({org: false, reg: false, items: false});
    const [contractId, setContractId] = useState();
    const [dbResponse, setDbResponse] = useState({items: []});
    const [allPdItems, setAllPdItems] = useState(false);
    const [allPiItems, setAllPiItems] = useState(false);
    const [allLfItems, setAllLfItems] = useState(false);
    const [allOofItems, setAllOofItems] = useState(false);
    const [disableNextStep, setDisableNextStep] = useState(true);
    const [contract, setContract] = useState({
        dme_id: '',
        active: true,
        hospice_id: '',
        hospice_region_id: '',
        per_diem_fee: null,
        billing_terms: 11,
        fiscal_year: null,
        tax_rate: null,
        discount_rate: 5,
        patient_days: true,
        dme_days:null,
        dme_days_type: false,
        fee_for_service: false,
        subject_to_taxes: false,
        renewal_date: null,
        items: [],
        contract_terms:'patient_days',
    });
    const getDmes = () => {
        fetch(endpoints.getDmeIds, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].dme_name, label: response.data[i].dme_name})
                }
                setDmeList(object);
                setDmeData(response.data);
            })
            .catch(err => console.log('error', err))
    };
    const getHospiceOrganizations = () => {
        fetch(endpoints.getAllHospiceIds, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].hospice_name, label: response.data[i].hospice_name})
                }
                setHospiceData(response.data);
                setHospiceList(object);
            })
            .catch(err => console.log('error', err))
    };
    const getHospiceRegions = () => {
        fetch(`${endpoints.getHospiceRegions}${contract.hospice_id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].name, label: response.data[i].name})
                }
                setHospiceRegionsData(response.data);
                setHospiceRegionsList(object);
            })
            .catch(err => console.log('error', err))
    };
    const getMasterItems = () => {
        fetch(endpoints.getAllMasterItems, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let arr = response.data.map((item, index) => {
                    item.item_type = {};
                    item.item_type.pd = false;
                    item.item_type.pi = false;
                    item.item_type.oof = false;
                    item.item_type.lf = false;
                    item.item_type.monthly = false;
                    item.item_type.type = '';
                    item.item_type.approval = false;
                    item.item_type.visible = false;
                    item.item_type.not_contracted = false;
                    item.item_type.existent = true;
                    item.item_type.can_be_pickup = false;
                    item.item_type.prescription_required = false;
                    return item;
                });
                setMasterItems(arr);
            })
            .catch(err => console.log('error', err))
    };
    const validateSelection = () => {
        let region = contract.hospice_region_id !== '' ? contract.hospice_region_id : null;
        fetch(endpoints.validateContract, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            },
            body: JSON.stringify({
                dme_id: contract.dme_id,
                hospice_id: contract.hospice_id,
                hospice_region_id: region
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data.length === 0) {
                    setValidSelection(true)
                } else {
                    setValidSelection(false)
                }
            })
            .catch(err => console.log('error', err))
    };
    const getContractDetails = () => {
        fetch(`${endpoints.getContractDetails}${contractId}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = {items: []};
                object.hospice_id = Number(response.data[0].hospice_id);
                object.active = response.data[0].active;
                object.hospice_region_id = response.data[0].hospice_region_id ? Number(response.data[0].hospice_region_id) : "";
                object.dme_id = Number(response.data[0].dme_id);
                object.contract_id = response.data[0].contract_id;
                object.per_diem_fee = response.data[0].per_diem_fee;
                object.dme_days = response.data[0].dme_days;
                object.fee_for_service = response.data[0].fee_for_service;
                object.billing_terms = response.data[0].billing_terms;
                object.fiscal_year = response.data[0].fiscal_year;
                object.tax_rate = response.data[0].tax_rate;
                object.renewal_date = response.data[0].contract_renewal_date;
                object.discount_rate = response.data[0].discount_rate;
                response.data.map(item => {
                    let existingItem = {item_type: {}};
                    existingItem.category = item.category;
                    existingItem.id = item.id;
                    existingItem.image = item.image;
                    existingItem.item_id = item.item_id;
                    existingItem.item_type.can_be_pickup = item.can_be_pickup;
                    existingItem.item_type.approval = item.approval;
                    existingItem.item_type.not_contracted = item.not_contracted;
                    existingItem.item_type.lf = item.type === 'LF';
                    existingItem.item_type.pd = item.type === 'PD';
                    existingItem.item_type.pi = item.type === 'PI';
                    existingItem.item_type.oof = item.type === 'OOF';
                    existingItem.item_type.type = item.type;
                    existingItem.item_type.visible = item.visible;
                    existingItem.item_type.monthly = item.monthly;
                    existingItem.item_type.prescription_required = item.prescription_required;
                    existingItem.max_quantity_per_item = item.max_quantity_per_item;
                    existingItem.require_clinical_input = item.require_clinical_input;
                    existingItem.tags = item.tags;
                    existingItem.priority_code = item.priority_code;
                    existingItem.item_type.price = item.supplemental_price;
                    existingItem.item_type.cppd = item.cppd;
                    existingItem.item_type.item_type_id = item.item_type_id;
                    existingItem.item_type.risk = item.risk;
                    existingItem.item_type.contracted_quantity = item.contracted_quantity;
                    existingItem.name = item.name;
                    existingItem.price= item.supplemental_price;
                    object.items.push(existingItem);
                });
                if (object.dme_days !== null) {
                    object.contract_terms = 'dme_days';
                } else if (object.per_diem_fee !== null) {
                    object.contract_terms = 'patient_days';
                } else {
                    object.contract_terms = 'fee_for_service';
                }
                setDbResponse(object);
            })
            .catch(err => console.log('error', err))
    };
    useEffect(() => {
        setContract({...contract, items: masterItems})
    }, [masterItems]);

    useEffect(() => {
        getDmes();
        getHospiceOrganizations();
        getMasterItems();
    }, []);

    useEffect(() => {
        if (edit && contract.dme_id === '' && contractId) {
            getContractDetails()
        }
    }, [contractId]);
    useEffect(() => {
        if (contract.hospice_id !== '') {
            getHospiceRegions()
        }
    }, [contract.hospice_id]);


    const handleOrganizationSelect = (event, type) => {
        const cloneContract = _.cloneDeep(contract);
        if (type === 'DME' && event !== null && !event.__isNew__) {
            cloneContract.dme_id = dmeData[_.findIndex(dmeData, {"dme_name": event.value})].dme_organization_id;
        } else if (type === 'Hospice' && event !== null && !event.__isNew__) {
            cloneContract.hospice_id = hospiceData[_.findIndex(hospiceData, {"hospice_name": event.value})].hospice_organization_id;
            cloneContract.hospice_region_id = ''
        } else if (type === 'DME' && event === null) {
            cloneContract.dme_id = ''
        } else if (type === 'Hospice' && event === null) {
            cloneContract.hospice_id = ''
        } else if (type === 'Hospice Region' && event === null) {
            cloneContract.hospice_region_id = ''
        } else if (event !== null && type === 'Hospice Region' && !event.__isNew__) {
            cloneContract.hospice_region_id = hospiceRegionsData[_.findIndex(hospiceRegionsData, {"name": event.value})].region_id;
        } else if (event.__isNew__ && event !== null && type === 'DME') {
            window.open("/dme/new", "_blank")
        } else if (event.__isNew__ && event !== null && type === 'Hospice') {
            window.open("/hospice/new", "_blank")
        }
        setContract(cloneContract);
    };

    const deselectItems = (itemType) => {
        const contractClone20 = _.cloneDeep(contract);
        closeSnackbar();
        const action = key => (
            <React.Fragment>
                <Button onClick={() => {
                    masterItems.map((item, index) => {
                        contractClone20.items[index].item_type.not_contracted = true;
                        contractClone20.items[index].item_type.visible = false;
                        contractClone20.items[index].item_type.can_be_pickup = false;
                        contractClone20.items[index].item_type.pd = false;
                        contractClone20.items[index].item_type.pi = false;
                        contractClone20.items[index].item_type.lf = false;
                        contractClone20.items[index].item_type.oof = false;
                        contractClone20.items[index].item_type.monthly = false;
                        contractClone20.items[index].item_type.require_prescription = false;
                    });
                    setAllPdItems(false);
                    setAllOofItems(false);
                    setAllLfItems(false);
                    setAllPiItems(false);
                    closeSnackbar();
                }}>
                    Yes
                </Button>
                <Button onClick={() => {
                    closeSnackbar()
                }}>
                    No
                </Button>
            </React.Fragment>
        );

        enqueueSnackbar(getMessage(messageTypes.contractSelectItems, itemType), {
            variant: 'warning',
            // autoHideDuration: 3000,
            action,
        });
        setContract(contractClone20);
    };

    const selectAllItems = (itemType, checked) => {
        const contractClone20 = _.cloneDeep(contract);
        masterItems.map((item, index) => {
            contractClone20.items[index].item_type.not_contracted = false;
            contractClone20.items[index].item_type.visible = true;
            contractClone20.items[index].item_type.can_be_pickup = true;
            contractClone20.items[index].item_type.pd = itemType === itemTypes.pd ? checked : false;
            contractClone20.items[index].item_type.pi = itemType === itemTypes.pi ? checked : false;
            contractClone20.items[index].item_type.lf = itemType === itemTypes.lf ? checked : false;
            contractClone20.items[index].item_type.oof = itemType === itemTypes.oof ? checked : false;
            contractClone20.items[index].item_type.type = itemType;
        });
        setContract(contractClone20);

        switch (itemType) {
            case itemTypes.pd:
                setAllPdItems(true);
                setAllPiItems(false);
                setAllLfItems(false);
                setAllOofItems(false);
                break;
            case itemTypes.pi:
                setAllPdItems(false);
                setAllPiItems(true);
                setAllLfItems(false);
                setAllOofItems(false);
                break;
            case itemTypes.lf:
                setAllPdItems(false);
                setAllPiItems(false);
                setAllLfItems(true);
                setAllOofItems(false);
                break;
            case itemTypes.oof:
                setAllPdItems(false);
                setAllPiItems(false);
                setAllLfItems(false);
                setAllOofItems(true);
                break;

        }
    };

    const handleChange = (e, index) => {
        const {value, name, checked} = e.target;
        switch (name) {
            case "active":
                setContract({...contract, active: checked});
                break;
            case "renewal_date":
                setContract({...contract, renewal_date: value});
                break;
            case "tax_rate":
                if(validators.numberValidator.test(value)) {
                    setContract({
                        ...contract,
                        tax_rate: value
                    });
                }
                break;
            case "patient_days":
                if (checked) {
                    setContract({...contract, patient_days: checked});
                } else {
                    setContract({...contract, patient_days: checked, per_diem_fee: null});
                }
                break;
            case "dme_days":
                if(validators.numberValidator.test(value)) {
                    setContract({
                        ...contract,
                        dme_days: value
                    });
                }
                break;
            case "fee_for_service":
                setContract({...contract, fee_for_service: checked});
                break;
            case "per_diem_fee":
                if(validators.numberValidator.test(value)) {
                    setContract({
                        ...contract,
                        per_diem_fee: value
                    });
                }
                break;
            case "subject_to_taxes":
                if (value === "true") {
                    setContract({...contract, subject_to_taxes: true})
                } else if (value === "false") {
                    setContract({...contract, subject_to_taxes: false, tax_rate: null})
                }
                break;
            case "contract_terms":
                if (value === "patient_days") {
                    setContract({...contract, patient_days: true, contract_terms:"patient_days", dme_days: '' })
                } else if (value === "dme_days") {
                    setContract({...contract, dme_days_type: true, contract_terms:"dme_days", per_diem_fee: '' })
                } else if (value === "fee_for_service") {
                    setContract({...contract, fee_for_service: true, contract_terms:"fee_for_service", dme_days: '', per_diem_fee: '' })
                }
                break;
            case "pd":
                const contractClone = _.cloneDeep(contract);
                if (checked) {
                    contractClone.items[index].item_type.not_contracted = false;
                    contractClone.items[index].item_type.visible = true;
                    contractClone.items[index].item_type.can_be_pickup = true;
                } else {
                    contractClone.items[index].item_type.not_contracted = true;
                    contractClone.items[index].item_type.visible = false;
                    contractClone.items[index].item_type.can_be_pickup = false;
                }
                contractClone.items[index].item_type.pd = checked;
                contractClone.items[index].item_type.pi = false;
                contractClone.items[index].item_type.lf = false;
                contractClone.items[index].item_type.oof = false;
                contractClone.items[index].item_type.monthly = false;
                contractClone.items[index].item_type.type = checked ? "PD" : null;
                setContract(contractClone);
                break;
            case "allPd":
                if (allPdItems === false) {
                    selectAllItems(itemTypes.pd, checked)
                } else if (allPdItems === true) {
                    deselectItems(itemTypes.pd);
                }
                break;
            case "pi":
                const contractClone2 = _.cloneDeep(contract);
                if (checked) {
                    contractClone2.items[index].item_type.not_contracted = false;
                    contractClone2.items[index].item_type.visible = true;
                    contractClone2.items[index].item_type.can_be_pickup = true;
                } else {
                    contractClone2.items[index].item_type.not_contracted = true;
                    contractClone2.items[index].item_type.visible = false;
                    contractClone2.items[index].item_type.can_be_pickup = false;
                }
                contractClone2.items[index].item_type.pd = false;
                contractClone2.items[index].item_type.pi = checked;
                contractClone2.items[index].item_type.lf = false;
                contractClone2.items[index].item_type.oof = false;
                contractClone2.items[index].item_type.monthly = false;
                contractClone2.items[index].item_type.type = checked ? "PI" : null;
                setContract(contractClone2);
                break;
            case "allPi":
                if (allPiItems === false) {
                    selectAllItems(itemTypes.pi, checked)
                } else if (allPiItems === true) {
                    deselectItems(itemTypes.pi);
                }
                break;
            case "oof":
                const contractClone3 = _.cloneDeep(contract);
                if (checked) {
                    contractClone3.items[index].item_type.not_contracted = false;
                    contractClone3.items[index].item_type.visible = true;
                    contractClone3.items[index].item_type.can_be_pickup = true;
                } else {
                    contractClone3.items[index].item_type.not_contracted = true;
                    contractClone3.items[index].item_type.visible = false;
                    contractClone3.items[index].item_type.can_be_pickup = false;
                }
                contractClone3.items[index].item_type.pd = false;
                contractClone3.items[index].item_type.pi = false;
                contractClone3.items[index].item_type.lf = false;
                contractClone3.items[index].item_type.oof = checked;
                contractClone3.items[index].item_type.type = checked ? "OOF" : null;

                setContract(contractClone3);
                break;
            case "allOof":
                if (allOofItems === false) {
                    selectAllItems(itemTypes.oof, checked)
                } else if (allOofItems === true) {
                    deselectItems(itemTypes.oof);
                }
                break;
            case "lf":
                const contractClone4 = _.cloneDeep(contract);
                if (checked) {
                    contractClone4.items[index].item_type.not_contracted = false;
                    contractClone4.items[index].item_type.visible = true;
                    contractClone4.items[index].item_type.can_be_pickup = true;
                } else {
                    contractClone4.items[index].item_type.not_contracted = true;
                    contractClone4.items[index].item_type.visible = false;
                    contractClone4.items[index].item_type.can_be_pickup = false;
                }
                contractClone4.items[index].item_type.pd = false;
                contractClone4.items[index].item_type.pi = false;
                contractClone4.items[index].item_type.lf = checked;
                contractClone4.items[index].item_type.oof = false;
                contractClone4.items[index].item_type.type = checked ? "LF" : null;

                setContract(contractClone4);
                break;
            case "prescription_required":
                const contractClone44 = _.cloneDeep(contract);
                if (checked) {
                    contractClone44.items[index].item_type.prescription_required = true;
                } else {
                    contractClone44.items[index].item_type.prescription_required = false;
                }
                setContract(contractClone44);
                break;
            case "monthly":
                const contractClone40 = _.cloneDeep(contract);

                contractClone40.items[index].item_type.pd = false;
                contractClone40.items[index].item_type.pi = false;
                contractClone40.items[index].item_type.monthly = checked;

                setContract(contractClone40);
                break;
            case "allLf":
                if (allLfItems === false) {
                    selectAllItems(itemTypes.lf, checked)
                } else if (allLfItems === true) {
                    deselectItems(itemTypes.lf);
                }
                break;
            case "approval":
                const contractClone5 = _.cloneDeep(contract);
                contractClone5.items[index].item_type.approval = checked;
                setContract(contractClone5);
                break;
            case "visible":
                const contractClone6 = _.cloneDeep(contract);
                contractClone6.items[index].item_type.visible = checked;
                setContract(contractClone6);
                break;
            case "not_contracted":
                const contractClone7 = _.cloneDeep(contract);
                contractClone7.items[index].item_type.not_contracted = checked;
                setContract(contractClone7);
                break;
            case "can_be_pickup":
                const contractClone12 = _.cloneDeep(contract);
                contractClone12.items[index].item_type.can_be_pickup = checked;
                setContract(contractClone12);
                break;
            case "risk":
                const contractClone8 = _.cloneDeep(contract);
                if(validators.numberValidator.test(value)) {
                    contractClone8.items[index].item_type.risk = value;
                    setContract(contractClone8);
                }
                break;
            case "qty":
                const contractClone9 = _.cloneDeep(contract);
                if(validators.numberValidator.test(value)) {
                    contractClone9.items[index].item_type.contracted_quantity = value;
                    setContract(contractClone9);
                }
                break;
            case "cppd":
                const contractClone10 = _.cloneDeep(contract);
                if(validators.numberValidator.test(value)) {
                    contractClone10.items[index].item_type.cppd = value;
                    setContract(contractClone10);
                }
                break;
            case "price":
                const contractClone11 = _.cloneDeep(contract);
                if(validators.numberValidator.test(value)) {
                    contractClone11.items[index].item_type.price = value;
                    setContract(contractClone11);
                }
                break;
            default:
                throw new Error('Unknown step');
        }
    };

    const handleChangeSecondStep = (e, index, item) => {
        const {value, name, checked} = e.target;
        switch (name) {
            case "pd":
                const contractClone = _.cloneDeep(contract);
                let contractCloneIndex = _.findIndex(contractClone.items, {
                    item_id: item.item_id
                })
                const contractedClone = _.cloneDeep(contractedItems);
                if (checked) {
                    contractClone.items[contractCloneIndex].item_type.not_contracted = false;
                    contractClone.items[contractCloneIndex].item_type.visible = true;
                    contractClone.items[contractCloneIndex].item_type.can_be_pickup = true;
                } else {
                    contractClone.items[contractCloneIndex].item_type.not_contracted = true;
                    contractClone.items[contractCloneIndex].item_type.visible = false;
                    contractClone.items[contractCloneIndex].item_type.can_be_pickup = false;
                }
                contractClone.items[contractCloneIndex].item_type.pd = checked;
                contractClone.items[contractCloneIndex].item_type.pi = false;
                contractClone.items[contractCloneIndex].item_type.lf = false;
                contractClone.items[contractCloneIndex].item_type.oof = false;
                contractClone.items[contractCloneIndex].item_type.monthly = false;
                contractClone.items[contractCloneIndex].item_type.type = checked ? "PD" : null;

                if (checked) {
                    contractedClone[index].item_type.not_contracted = false;
                    contractedClone[index].item_type.visible = true;
                    contractedClone[index].item_type.can_be_pickup = true;
                } else {
                    contractedClone[index].item_type.not_contracted = true;
                    contractedClone[index].item_type.visible = false;
                    contractedClone[index].item_type.can_be_pickup = false;
                }
                contractedClone[index].item_type.pd = checked;
                contractedClone[index].item_type.pi = false;
                contractedClone[index].item_type.lf = false;
                contractedClone[index].item_type.oof = false;
                contractedClone[index].item_type.monthly = false;
                contractedClone[index].item_type.type = checked ? "PD" : null;
                setContract(contractClone);
                setContractedItems(contractedClone);
                break;
            case "allPd":
                if (allPdItems === false) {
                    selectAllItems(itemTypes.pd, checked)
                } else if (allPdItems === true) {
                    deselectItems(itemTypes.pd);
                }
                break;
            case "pi":
                const contractClone2 = _.cloneDeep(contract);
                let contractCloneIndex2 = _.findIndex(contractClone2.items, {
                    item_id: item.item_id
                })
                const contractedClone2 = _.cloneDeep(contractedItems);
                if (checked) {
                    contractClone2.items[contractCloneIndex2].item_type.not_contracted = false;
                    contractClone2.items[contractCloneIndex2].item_type.visible = true;
                    contractClone2.items[contractCloneIndex2].item_type.can_be_pickup = true;
                } else {
                    contractClone2.items[contractCloneIndex2].item_type.not_contracted = true;
                    contractClone2.items[contractCloneIndex2].item_type.visible = false;
                    contractClone2.items[contractCloneIndex2].item_type.can_be_pickup = false;
                }
                contractClone2.items[contractCloneIndex2].item_type.pd = false;
                contractClone2.items[contractCloneIndex2].item_type.pi = checked;
                contractClone2.items[contractCloneIndex2].item_type.lf = false;
                contractClone2.items[contractCloneIndex2].item_type.oof = false;
                contractClone2.items[contractCloneIndex2].item_type.monthly = false;
                contractClone2.items[contractCloneIndex2].item_type.type = checked ? "PI" : null;

                if (checked) {
                    contractedClone2[index].item_type.not_contracted = false;
                    contractedClone2[index].item_type.visible = true;
                    contractedClone2[index].item_type.can_be_pickup = true;
                } else {
                    contractedClone2[index].item_type.not_contracted = true;
                    contractedClone2[index].item_type.visible = false;
                    contractedClone2[index].item_type.can_be_pickup = false;
                }
                contractedClone2[index].item_type.pd = false;
                contractedClone2[index].item_type.pi = checked;
                contractedClone2[index].item_type.lf = false;
                contractedClone2[index].item_type.oof = false;
                contractedClone2[index].item_type.monthly = false;
                contractedClone2[index].item_type.type = checked ? "PI" : null;
                setContract(contractClone2);
                setContractedItems(contractedClone2);
                break;
            case "allPi":
                if (allPiItems === false) {
                    selectAllItems(itemTypes.pi, checked)
                } else if (allPiItems === true) {
                    deselectItems(itemTypes.pi);
                }
                break;
            case "oof":
                const contractClone3 = _.cloneDeep(contract);
                let contractCloneIndex3 = _.findIndex(contractClone3.items, {
                    item_id: item.item_id
                })
                const contractedClone3 = _.cloneDeep(contractedItems);
                if (checked) {
                    contractClone3.items[contractCloneIndex3].item_type.not_contracted = false;
                    contractClone3.items[contractCloneIndex3].item_type.visible = true;
                    contractClone3.items[contractCloneIndex3].item_type.can_be_pickup = true;
                } else {
                    contractClone3.items[contractCloneIndex3].item_type.not_contracted = true;
                    contractClone3.items[contractCloneIndex3].item_type.visible = false;
                    contractClone3.items[contractCloneIndex3].item_type.can_be_pickup = false;
                }
                contractClone3.items[contractCloneIndex3].item_type.pd = false;
                contractClone3.items[contractCloneIndex3].item_type.pi = false;
                contractClone3.items[contractCloneIndex3].item_type.lf = false;
                contractClone3.items[contractCloneIndex3].item_type.oof = checked;
                contractClone3.items[contractCloneIndex3].item_type.type = checked ? "OOF" : null;

                if (checked) {
                    contractedClone3[index].item_type.not_contracted = false;
                    contractedClone3[index].item_type.visible = true;
                    contractedClone3[index].item_type.can_be_pickup = true;
                } else {
                    contractedClone3[index].item_type.not_contracted = true;
                    contractedClone3[index].item_type.visible = false;
                    contractedClone3[index].item_type.can_be_pickup = false;
                }
                contractedClone3[index].item_type.pd = false;
                contractedClone3[index].item_type.pi = false;
                contractedClone3[index].item_type.lf = false;
                contractedClone3[index].item_type.oof = checked;
                contractedClone3[index].item_type.type = checked ? "OOF" : null;

                setContract(contractClone3);
                setContractedItems(contractedClone3);
                break;
            case "allOof":
                if (allOofItems === false) {
                    selectAllItems(itemTypes.oof, checked)
                } else if (allOofItems === true) {
                    deselectItems(itemTypes.oof);
                }
                break;
            case "lf":
                const contractClone4 = _.cloneDeep(contract);
                let contractCloneIndex4 = _.findIndex(contractClone4.items, {
                    item_id: item.item_id
                })
                const contractedClone4 = _.cloneDeep(contractedItems);
                if (checked) {
                    contractClone4.items[contractCloneIndex4].item_type.not_contracted = false;
                    contractClone4.items[contractCloneIndex4].item_type.visible = true;
                    contractClone4.items[contractCloneIndex4].item_type.can_be_pickup = true;
                } else {
                    contractClone4.items[contractCloneIndex4].item_type.not_contracted = true;
                    contractClone4.items[contractCloneIndex4].item_type.visible = false;
                    contractClone4.items[contractCloneIndex4].item_type.can_be_pickup = false;
                }
                contractClone4.items[contractCloneIndex4].item_type.pd = false;
                contractClone4.items[contractCloneIndex4].item_type.pi = false;
                contractClone4.items[contractCloneIndex4].item_type.lf = checked;
                contractClone4.items[contractCloneIndex4].item_type.oof = false;
                contractClone4.items[contractCloneIndex4].item_type.type = checked ? "LF" : null;

                if (checked) {
                    contractedClone4[index].item_type.not_contracted = false;
                    contractedClone4[index].item_type.visible = true;
                    contractedClone4[index].item_type.can_be_pickup = true;
                } else {
                    contractedClone4[index].item_type.not_contracted = true;
                    contractedClone4[index].item_type.visible = false;
                    contractedClone4[index].item_type.can_be_pickup = false;
                }
                contractedClone4[index].item_type.pd = false;
                contractedClone4[index].item_type.pi = false;
                contractedClone4[index].item_type.lf = checked;
                contractedClone4[index].item_type.oof = false;
                contractedClone4[index].item_type.type = checked ? "LF" : null;

                setContract(contractClone4);
                setContractedItems(contractedClone4);
                break;
            case "prescription_required":
                const contractClone44 = _.cloneDeep(contract);
                let contractCloneIndex44 = _.findIndex(contractClone44.items, {
                    item_id: item.item_id
                })
                const contractedClone44 = _.cloneDeep(contractedItems);
                if (checked) {
                    contractClone44.items[contractCloneIndex44].item_type.prescription_required = true;
                    contractedClone44[index].item_type.prescription_required = true;
                } else {
                    contractClone44.items[contractCloneIndex44].item_type.prescription_required = false;
                    contractedClone44[index].item_type.prescription_required = false;
                }
                setContract(contractClone44);
                setContractedItems(contractedClone44);
                break;
            case "monthly":
                const contractClone40 = _.cloneDeep(contract);
                let contractCloneIndex40 = _.findIndex(contractClone40.items, {
                    item_id: item.item_id
                })
                const contractedClone40 = _.cloneDeep(contractedItems);

                contractClone40.items[contractCloneIndex40].item_type.pd = false;
                contractClone40.items[contractCloneIndex40].item_type.pi = false;
                contractClone40.items[contractCloneIndex40].item_type.monthly = checked;

                contractedClone40[index].item_type.pd = false;
                contractedClone40[index].item_type.pi = false;
                contractedClone40[index].item_type.monthly = checked;

                setContract(contractClone40);
                setContractedItems(contractedClone40);
                break;
            case "allLf":
                if (allLfItems === false) {
                    selectAllItems(itemTypes.lf, checked)
                } else if (allLfItems === true) {
                    deselectItems(itemTypes.lf);
                }
                break;
            case "approval":
                const contractClone5 = _.cloneDeep(contract);
                let contractCloneIndex5 = _.findIndex(contractClone5.items, {
                    item_id: item.item_id
                })
                const contractedClone5 = _.cloneDeep(contractedItems);
                contractClone5.items[contractCloneIndex5].item_type.approval = checked;
                contractedClone5[index].item_type.approval = checked;
                setContract(contractClone5);
                setContractedItems(contractedClone5);
                break;
            case "visible":
                const contractClone6 = _.cloneDeep(contract);
                let contractCloneIndex6 = _.findIndex(contractClone6.items, {
                    item_id: item.item_id
                })
                const contractedClone6 = _.cloneDeep(contractedItems);
                contractClone6.items[contractCloneIndex6].item_type.visible = checked;
                contractedClone6[index].item_type.visible = checked;
                setContract(contractClone6);
                setContractedItems(contractedClone6);
                break;
            case "not_contracted":
                const contractClone7 = _.cloneDeep(contract);
                let contractCloneIndex7 = _.findIndex(contractClone7.items, {
                    item_id: item.item_id
                })
                const contractedClone7 = _.cloneDeep(contractedItems);
                contractClone7.items[contractCloneIndex7].item_type.not_contracted = checked;
                contractedClone7[index].item_type.not_contracted = checked;
                setContract(contractClone7);
                setContractedItems(contractedClone7);
                break;
            case "can_be_pickup":
                const contractClone12 = _.cloneDeep(contract);
                let contractCloneIndex12 = _.findIndex(contractClone12.items, {
                    item_id: item.item_id
                })
                const contractedClone12 = _.cloneDeep(contractedItems);
                contractClone12.items[contractCloneIndex12].item_type.can_be_pickup = checked;
                contractedClone12[index].item_type.can_be_pickup = checked;
                setContract(contractClone12);
                setContractedItems(contractedClone12);
                break;
            case "risk":
                const contractClone8 = _.cloneDeep(contract);
                let contractCloneIndex8 = _.findIndex(contractClone8.items, {
                    item_id: item.item_id
                })
                const contractedClone8 = _.cloneDeep(contractedItems);
                contractClone8.items[contractCloneIndex8].item_type.risk = value;
                contractedClone8[index].item_type.risk = value;
                setContract(contractClone8);
                setContractedItems(contractedClone8);
                break;
            case "qty":
                const contractClone9 = _.cloneDeep(contract);
                let contractCloneIndex9 = _.findIndex(contractClone9.items, {
                    item_id: item.item_id
                })
                const contractedClone9 = _.cloneDeep(contractedItems);
                contractClone9.items[contractCloneIndex9].item_type.contracted_quantity = value;
                contractedClone9[index].item_type.contracted_quantity = value;
                setContract(contractClone9);
                setContractedItems(contractedClone9);
                break;
            case "cppd":
                const contractClone10 = _.cloneDeep(contract);
                let contractCloneIndex10 = _.findIndex(contractClone10.items, {
                    item_id: item.item_id
                })
                const contractedClone10 = _.cloneDeep(contractedItems);
                contractClone10.items[contractCloneIndex10].item_type.cppd = value;
                contractedClone10[index].item_type.cppd = value;
                setContract(contractClone10);
                setContractedItems(contractedClone10);
                break;
            case "price":
                const contractClone11 = _.cloneDeep(contract);
                let contractCloneIndex11 = _.findIndex(contractClone11.items, {
                    item_id: item.item_id
                })
                const contractedClone11 = _.cloneDeep(contractedItems);
                contractClone11.items[contractCloneIndex11 ].item_type.price = value;
                contractedClone11[index].item_type.price = value;
                setContract(contractClone11);
                setContractedItems(contractedClone11);
                break;
            default:
                throw new Error('Unknown step');
        }
    };
    const [validSelection, setValidSelection] = useState(true);
    const [entered, setEntered] = useState(false);

    const steps = [sectionTitles.organizationDetails, sectionTitles.configureServiceAgreement, sectionTitles.configureServiceAgreementSecondStep, sectionTitles.contractReview, sectionTitles.finalize];

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <OrganizationDetails
                    handleOrganizationSelect={handleOrganizationSelect}
                    handleChange={handleChange}
                    dmeList={dmeList}
                    hospiceList={hospiceList}
                    hospiceData={hospiceData}
                    hospiceRegionsList={hospiceRegionsList}
                    hospiceRegionsData={hospiceRegionsData}
                    dmeData={dmeData}
                    contract={contract}
                    edit={edit}
                    contractId={contractId}
                />;
            case 1:
                return <ConfigureServiceAgreement
                    masterItems={masterItems}
                    items={contract.items}
                    handleChange={handleChange}
                />;
            case 2:
                return <ConfigureServiceAgreementSecondStep
                    masterItems={contract.items}
                    items={contractedItems}
                    handleChange={handleChangeSecondStep}
                />;
            case 3:
                return <Review
                    masterItems={contract.items}
                    items={contractedItems}
                />;
            case 4:
                return <Finalize
                    contract={contract}
                    items={contractedItems}
                />;
            default:
                throw new Error('Unknown step');
        }
    }

    const handleNext = () => {
        if (activeStep === 0 && validSelection && !disableNextStep) {
            setActiveStep(activeStep + 1);
        }
        if (activeStep === 1) {
            let itemsClone = _.cloneDeep(contract.items);
            let result = [];
            itemsClone.map(item => {
                if (item.item_type.lf ||
                    item.item_type.pd ||
                    item.item_type.oof ||
                    item.item_type.pi ||
                    item.item_type.approval ||
                    item.item_type.visible ||
                    (item.item_type.cppd !== '' &&
                        item.item_type.cppd !== undefined) ||
                    (item.item_type.price !== '' &&
                        item.item_type.price !== undefined) ||
                    (item.item_type.risk !== '' &&
                        item.item_type.risk !== undefined) ||
                    (item.item_type.qty !== '' &&
                        item.item_type.qty !== undefined)
                ) {
                    if (item.item_type.price === "") {
                        item.item_type.price = null
                    }
                    result.push(item)
                } else if (!item.item_type.existent && item.item_type.not_contracted) {
                    result.push(item)
                }
            });
            setContractedItems(result);
            setActiveStep(activeStep + 1);
        }
        if (activeStep === 2) {
            setActiveStep(activeStep + 1);
        }
        if (activeStep === 3) {
            setActiveStep(activeStep + 1);
        }
        if (activeStep === 4) {
            let hospice_region_id = contract.hospice_region_id === '' ? null : contract.hospice_region_id
            if (edit) {
                const clonedItems = _.cloneDeep(contractedItems);
                let updatedItems = [];
                let newItems = [];
                clonedItems.map(item => {
                    if (item.item_type.existent) {
                        newItems.push(item)
                    } else {
                        updatedItems.push(item)
                    }
                });
                fetch(`${endpoints.updateContractDetails}${contractId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + data.accessToken
                    },
                    body: JSON.stringify({
                        ...contract,
                        newItems: newItems,
                        updatedItems: updatedItems,
                        hospice_region_id: hospice_region_id
                    })
                })
                    .then(response => {
                        if (response.status === 200) {
                            setIsLoading(false);
                            setWasSuccessful(true)
                        } else {
                            setIsLoading(false);
                            setWasSuccessful(false)
                        }
                    })
                    .catch(err => console.log('error', err.stack));
            } else {
                fetch(`${endpoints.createContractDetails}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + data.accessToken
                    },
                    body: JSON.stringify({
                        ...contract,
                        items: contractedItems,
                        hospice_region_id: hospice_region_id,
                    })
                })
                    .then(response => {
                        if (response.status === 201) {
                            setIsLoading(false);
                            setWasSuccessful(true)
                        } else {
                            setIsLoading(false);
                            setWasSuccessful(false)
                        }
                    })
                    .catch(err => console.log('error', err.stack));
            }
            setActiveStep(activeStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };
    useEffect(() => {
        const haveDataForOrg = (dmeList.length > 0 && hospiceList.length > 0 && dbResponse.dme_id);
        const haveDataForRegion = (hospiceRegionsList.length > 0 && dbResponse.hospice_region_id !== null && dbResponse.hospice_id);

        if (haveDataForOrg && edit && !initialEdit.org) {
            setContract({
                ...contract, dme_id: dbResponse.dme_id, hospice_id: dbResponse.hospice_id
            });
            setInitialEdit({...initialEdit, org: true})
        }
        if (haveDataForRegion && edit && !initialEdit.reg) {
            setContract({
                ...contract, hospice_region_id: dbResponse.hospice_region_id
            });
            setInitialEdit({...initialEdit, reg: true})
        }
    }, [hospiceList, dmeList, hospiceRegionsList, dbResponse]);

    useEffect(() => {
        let clonedItems;
        if (contract.items.length > 0 && !initialEdit.items && dbResponse.items.length > 0 && contract.dme_id !== '') {
            clonedItems = _.cloneDeep(contract.items);
            dbResponse.items.map(item => {
                let index = contract.items.findIndex(i => i.item_id === Number(item.item_id));
                if (index !== -1) {
                    clonedItems[index] = item;
                }
            });
            let sub = !!dbResponse.tax_rate;
            let days = !!dbResponse.per_diem_fee;
            setContract({
                ...contract,
                items: clonedItems,
                renewal_date: dbResponse.renewal_date,
                per_diem_fee: dbResponse.per_diem_fee,
                dme_days: dbResponse.dme_days,
                billing_terms: dbResponse.billing_terms,
                fiscal_year: dbResponse.fiscal_year,
                tax_rate: dbResponse.tax_rate,
                discount_rate: dbResponse.discount_rate,
                subject_to_taxes: sub,
                patient_days: days,
                contract_terms: dbResponse.contract_terms,
                fee_for_service: dbResponse.fee_for_service,
                active: dbResponse.active

            });
            setContractedItems(dbResponse.items);
            setInitialEdit({...initialEdit, items: true})
        }
        setDisableNextStep(contract.hospice_id === '' || contract.dme_id === '')
        // condition for required contract terms
        // setDisableNextStep(contract.hospice_id === '' || contract.dme_id === '' || contract.renewal_date === null || ((contract.contract_terms === 'dme_days' && (contract.dme_days?.length === 0 || contract.dme_days === null)) || (contract.contract_terms === 'patient_days' && (contract.per_diem_fee?.length === 0 || contract.per_diem_fee === null))) || (contract.subject_to_taxes === true && (contract.tax_rate?.length === 0 || contract.tax_rate === null)))
    }, [contract, dbResponse]);

    useEffect(() => {
        let urlParameters = window.location.href.split('/');
        setContractId(Number(urlParameters[urlParameters.length - 1]))
    }, []);
    useEffect(() => {
        if (!edit && contract.dme_id !== '' && contract.hospice_id !== '') {
            validateSelection();
        }
    }, [contract.dme_id, contract.hospice_id, contract.hospice_region_id]);

    return (
        <React.Fragment>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h5" align="left" className={classes.title}>
                        {edit ? 'Edit a ' : 'Add new '} contract
                    </Typography>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <React.Fragment>
                                {isLoading ? <CircularProgress/> :
                                    <React.Fragment>
                                        <Typography variant="h5" gutterBottom>
                                            {wasSuccessful && 'Success'}
                                            {(!wasSuccessful && edit) && 'Unable to update Contract'}
                                            {(!wasSuccessful && !edit) && 'Unable to create Contract'}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {(wasSuccessful && !edit) && 'Contract has been created.'}
                                            {(wasSuccessful && edit) && 'Contract has been updated.'}
                                            {!wasSuccessful && 'Please try again'}
                                        </Typography>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {getStepContent(activeStep)}
                                <div className={classes.buttons}>
                                    {entered ? <CancelActionAlert
                                        flag={cancellingAlertFlags.newPatient}
                                        CSS={classes.button}
                                        alertLevel={alertLevel.warn}
                                    /> : <Button
                                        variant={'outlined'}
                                        color="primary"
                                        onClick={() => {
                                            history.push('/admin')
                                        }} className={classes.button}>
                                        Cancel
                                    </Button>
                                    }
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} className={classes.button}>
                                            Back
                                        </Button>
                                    )}
                                    {validSelection ? (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            id={'next'}
                                            onClick={handleNext}
                                            className={classes.button}
                                            disabled={disableNextStep}
                                        >
                                            {(activeStep === steps.length - 1 && edit) && 'Update Contract'}
                                            {(activeStep === steps.length - 1 && !edit) && 'Create Contract'}
                                            {activeStep !== steps.length - 1 && 'Next'}
                                        </Button>
                                    ) : (
                                        <Tooltip title={'Contract between this organizations exist already'}>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                id={'next'}
                                                onClick={handleNext}
                                                className={classes.button}
                                                disabled={disableNextStep}
                                            >
                                                {(activeStep === steps.length - 1 && edit) && 'Update Contract'}
                                                {(activeStep === steps.length - 1 && !edit) && 'Create Contract'}
                                                {activeStep !== steps.length - 1 && 'Next'}
                                            </Button>
                                        </Tooltip>
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Paper>
            </main>
            <Dialog
                className={'order'}
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'xl'}
                open={showAddNewDME}
                onClose={() => setShowAddNewDME(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    {/*// <CreateDmeProvider />*/}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
