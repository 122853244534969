import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Select from 'react-select';
import {endpoints} from "../../../constants/endpoints";
import jwt from "jwt-decode";
import _ from "lodash";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import {Accordion, AccordionDetails, CircularProgress, Dialog, DialogContent, TextField} from "@material-ui/core";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import {useSnackbar} from "notistack";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import {useHistory} from "react-router-dom";
import {parseTimeWithUserTimezone} from "../../../common/timeHandler/timeHandler";

export default function CleaningAndMaintenance(){
    const [warehouses, setWarehouses] = useState();
    const [selectedWarehouse, setSelectedWarehouse] = useState([]);
    const [selectedItem, setSelectedItem] = useState([]);
    const history = useHistory();
    const [allItems, setAllItems] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [logModal, setLogModal] = useState(false);
    const [auditModal, setAuditModal] = useState(false);
    const [logType, setLogType] = useState('');
    const [comment, setComment] = useState('');
    const [selectedInventoryItem, setSelectedInventoryItem] = useState({});
    const [logs, setLogs] = useState([]);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [loadingLogs, setLoadingLogs] = useState(false);

    const getUserWarehouses = () => {
        const token = JSON.parse(localStorage.getItem('key'));
        let user = jwt(token.accessToken);
        fetch(`${endpoints.getUserWarehouses}${user.payload.user_id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let arr=[];
                response.data.map(warehouse => {
                    arr.push({
                        label: warehouse.name,
                        value: warehouse.warehouse_id
                    })
                })
                setWarehouses(arr);
            })
            .catch(err => console.log('error'))
    }

    const getWarehouseItems = () => {
        const token = JSON.parse(localStorage.getItem('key'));
        let user = jwt(token.accessToken);
        fetch(`${endpoints.getAllInventoryItems}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                warehouses: [selectedWarehouse],
                dmeID:user.payload.dme_organization_id
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setAllItems(response.data)
            })
            .catch(err => console.log('error'))
    }

    function generateItemValues(){
        let itemValues = [];
        allItems.map(item => {
            let index = _.findIndex(itemValues, {label: item.item_name})
            if (index === -1){
                itemValues.push({
                    value:item.inventory_item_id,
                    label: item.item_name
                })
            }
        })
        setItemList(itemValues);
    }

    function generateFilteredList(){
        let filteredList = [];
        allItems.map(item => {
            if (item.item_name === selectedItem.label){
                filteredList.push(item)
            }
        })
        setFilteredList(filteredList);
    }

    function createLog(type){
        const token = JSON.parse(localStorage.getItem('key'));
        let user = jwt(token.accessToken);
        fetch(`${endpoints.createInventoryLog}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                type:type,
                comments:comment,
                inventory_item_id: selectedInventoryItem.inventory_item_id,
                user_id:user.payload.user_id,
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                enqueueSnackbar("Log added with success", {variant: "success"});
            })
            .catch(err => console.log('error'))
    }

    function getCleanAndMaintenanceInventory(id){
        setLoadingLogs(true);
        const token = JSON.parse(localStorage.getItem('key'));
        let user = jwt(token.accessToken);
        fetch(`${endpoints.getInventoryInfo}${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setLogs(response.data);
                setLoadingLogs(false);
            })
            .catch(err => console.log('error'))
    }

    useEffect(()=> {
        getUserWarehouses();
    },[])

    useEffect(()=> {
        selectedWarehouse.value && getWarehouseItems()
    }, [selectedWarehouse]);

    useEffect(()=> {
        allItems.length > 0 && generateItemValues();
    },[allItems])

    useEffect(()=> {
        selectedItem.value > 0 && generateFilteredList();
    },[selectedItem])

    return (
        <Grid container style={{padding:24}}>
            <Grid container style={{padding:16,background:'white', borderRadius:4}} spacing={2}>
                <Grid item xs={12}>
                    <h2 style={{margin:0, padding:0, textAlign:'left'}}>
                        Cleaning And Maintenance
                    </h2>
                </Grid>
                <Grid item xs={12} style={{zIndex:201, display: "flex"}}>
                    <Button
                        variant="outlined"
                        color="default"
                        onClick={() => {
                            history.push(`/inventory`)
                        }}
                    >
                        {buttonText.backToInventory}
                    </Button>
                </Grid>
                <Grid item md={3} sm={4} xs={12} style={{zIndex:201}}>
                    <div style={{textAlign:"left", color:'grey'}}>Warehouse</div>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="color"
                        placeholder='Select Warehouse'
                        options={warehouses}
                        value={selectedWarehouse}
                        onChange={(e)=> setSelectedWarehouse(e)}
                    />
                </Grid>
                <Grid item  md={3} sm={4} xs={12} style={{zIndex:200}}>
                    <div style={{textAlign:"left", color:'grey'}}>Item</div>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        placeholder='Select Item'
                        name="color"
                        options={itemList}
                        value={selectedItem}
                        onChange={(e)=> setSelectedItem(e)}
                    />
                </Grid>
                <Grid item xs={12}>
                   <MaterialTable columns={[
                       {title: 'Item name', field: 'item_name',},
                       {title: 'Asset', field: 'asset'},
                       {title: 'Serial', field: 'serial_id'},
                       {title: 'Action', field: 'serial_id',
                           render: rowData => <Grid container>
                               <Button color={'primary'} variant={'outlined'} size={'small'} style={{marginRight:8}}
                               onClick={()=> {
                                setLogModal(true);
                                setLogType('cleaning');
                                setSelectedInventoryItem(rowData);
                                setComment('')
                               }}
                               >Clean</Button>
                               <Button color={'primary'} variant={'outlined'} size={'small'}
                                       onClick={()=> {
                                           setLogModal(true);
                                           setLogType('maintenance');
                                           setSelectedInventoryItem(rowData);
                                           setComment('');
                                       }}
                               >Maintenance</Button>
                           </Grid>
                       },
                       {title: 'Audit Log', field: 'serial_id',
                           render: rowData => <Grid container>
                               <Button color={'primary'} variant={'outlined'} size={'small'} onClick={()=> {
                                   setAuditModal(true);
                                   setSelectedInventoryItem(rowData);
                                   getCleanAndMaintenanceInventory(rowData.inventory_item_id)
                               }}>Audit Log</Button>
                           </Grid>},
                   ]} data={filteredList}
                   options={
                       {showTitle:false}
                   }
                   />
                </Grid>
            </Grid>
            <Dialog
                open={logModal}
            >
                <DialogContent>
                    <div style={{paddingBottom:16}}>
                        {logType === 'cleaning' && `You selected to perform cleaning for item ${selectedItem.label}`}
                        {logType === 'maintenance' && `You selected to perform maintenance for item ${selectedItem.label}`}
                    </div>
                   <TextField
                        multiline
                        rows={4}
                        fullWidth
                        variant='outlined'
                        label={'Comments'}
                        placeholder={'Insert comment...'}
                        size={'small'}
                        value={comment}
                        onChange={(e)=> setComment(e.target.value)}
                   />
                    <div style={{paddingBottom:16, paddingTop:16}}>
                        Do you want to continue?
                    </div>
                    <Grid container justify='flex-end' spacing={1} style={{paddingBottom:16}}>
                        <Grid item>
                            <Button color={'secondary'} variant={'outlined'} onClick={()=> setLogModal(false)}>No</Button>
                        </Grid>
                        <Grid item>
                            <Button color={'primary'} variant={'contained'}
                            onClick={()=>{
                                logType === 'cleaning' &&  createLog('clean');
                                logType === 'maintenance' &&  createLog('maintenance');
                                setLogModal(false);
                            }}
                            >Yes</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog
                open={auditModal}
                onClose={()=> setAuditModal(false)}
            >
                <DialogContent>
                    <Grid container style={{paddingBottom:8, paddingTop:8}} spacing={1}>
                        <div style={{fontSize:20, fontWeight:'bold', paddingBottom:8}}>Audit Log <span style={{color:'grey'}}>- {selectedInventoryItem.item_name}</span> </div>
                        {loadingLogs ? (
                            <Grid container style={{minHeight:240, minWidth:260}} justify={'center'} alignItems={'center'} alignContent={'center'}>
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography
                                            >Cleaning Logs</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container>
                                                {logs.clean_logs?.map((log, i)=> (
                                                    <Grid item xs={12} key={i} style={{padding:8, marginBottom:8, border:'1px solid lightgrey', borderRadius:4}}>
                                                        {log.substring(0, log.indexOf(" in ") + 3)} {parseTimeWithUserTimezone(log.substring(log.indexOf(" in ")+4, log.indexOf(" by ") -3))} {log.substring(log.indexOf(" by "), log.length -1)}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography
                                                // className={classes.heading}
                                            >Maintenance Logs</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container>
                                                {logs.maintenance_logs?.map((log, i)=> (
                                                    <Grid item xs={12} key={i} style={{padding:8, marginBottom:8, border:'1px solid lightgrey', borderRadius:4}}>
                                                        {log.substring(0, log.indexOf(" in ") + 3)} {parseTimeWithUserTimezone(log.substring(log.indexOf(" in ")+4, log.indexOf(" by ") -3))} {log.substring(log.indexOf(" by "), log.length -1)}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </React.Fragment>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
        </Grid>
    )
}
