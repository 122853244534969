export const orderHeaderStyle = (theme) => {
    return {
        h1: {
            textAlign: 'left',
            color: '#00223D',
            fontSize: 26,
            fontWeight: "bold",
            lineHeight: '38px',
            borderBottom: '1px solid #B2D1D9',
            marginBottom: 16,
            textTransform: "uppercase"
        },
        h4: {
            fontSize: 26,
            fontWeight: "bold",
            color: "#1A1818"
        },
        h5: {
            fontSize: 14,
            color:"#2E3236"
        },
        afterHoursOrders: {
            color: '#CEC129',
            fontSize: 26,
            fontWeight: "bold"
        },
    }};