import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {sectionTitles} from "../../../constants/names/titles";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import {placeholders} from "../../../constants/inputs/paceholders";
import {labels} from "../../../constants/inputs/labels";
import moment from 'moment';
import {withStyles} from "@material-ui/core";
import AsyncSelect from 'react-select/async';
import {endpoints} from "../../../constants/endpoints";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {maxInputLength} from "../../../constants/inputs/values";
import {checkPermissions} from "../../../common/permissions/checkPermissions";
import {permissions} from "../../../constants/permissions/permissions";
import {PrescriptionItemsTable} from "./prescriptionItems/PrescriptionItemsTable";

const CustomCheckbox = withStyles({
    root: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        },
        "&:checked": {
            color: "#4F86A0"
        },
        "&:disabled": {
            color: "#4F86A0"
        }
    },
    checked: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        }
    },
    disabled: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        }
    }
})(Checkbox);

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100,
        textAlign: 'left'
    })
};


export default function Finalize(props) {
    const [items, setItems] = useState([]);
    const [value, setValue] = useState([]);
    const useStyles = makeStyles({
        underline: {
            "&&&:before": {
                borderBottom: "none"
            },
            "&&:after": {
                borderBottom: "none"
            }
        },
        notesSection: {
            display: 'flex',
            border: '1px solid #CCCCCC',
            backgroundColor: '#F2F2F2',
            borderRadius: 4,
            padding: 16,
            marginBottom: 12,
            justifyContent: 'space-between',
        },
        author: {
            fontStyle: 'italic',
            color: "#B6B6B6",
        }
    });
    const {
        currentNote, setCurrentNote, handleNoteDelete, handleActiveNoteDelete, patient,
        handleInfectiousChange, handlePrescriptionChange, handleNoteInput, initialNotes, additionalNotes, addNote,
        emptyNote, edit
    } = props;
    const classes = useStyles();

    const searchValues = inputValue => {
            const token = JSON.parse(localStorage.getItem('key'));
            const url = `${endpoints.getICD10Codes}${inputValue.toLowerCase()}`;
            return fetch(url,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                }
            })
                .then(response => response.json())
                .then(res => {
                    let arr = [];
                    res.data.map(item => arr.push({
                            value:item.icd_code_id,
                            label:`${item.description + " (" + item.code + ')'}`
                        })
                    )
                    return arr;
                })
                .catch(err => {
                    console.log(err);
                });
    };

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom style={{display: "flex", paddingBottom: 40}}>
                {sectionTitles.clinicalNotes}
            </Typography>
            <Grid container spacing={2} alignItems={"stretch"}>
                {patient.note.filter((i, indeX) => (indeX < initialNotes)).map((note, index) => {
                    return (
                        <Grid item key={index} xs={12}>
                            <div key={index} className={classes.notesSection}>
                                <div>{note.description}</div>
                                <div
                                    className={classes.author}>{note.first_name + " " + note.last_name + ", " + moment(note.created_date).format('LLL')}</div>
                            </div>
                            <Divider style={{margin: '10px'}}/>
                        </Grid>
                    )
                })}
                {patient.note.filter((i, index) => (index > initialNotes - 1 && index < initialNotes + additionalNotes)).map((note, index) => {
                    return (
                        <Grid item xs={12} key={index}>
                            <TextField
                                placeholder=""
                                multiline
                                name='note'
                                rows={3}
                                rowsMax={5}
                                fullWidth
                                value={note}
                                style={{
                                    border: '1px solid gray',
                                    padding: '6px',
                                    borderRadius: '5px',
                                    width: '99%'
                                }}
                                InputProps={{classes}}
                                inputProps={{maxLength: maxInputLength.L2000}}
                                helperText={note && `${note.length < maxInputLength.L2000 ? note.length.toString() + `/${maxInputLength.L2000}`: note.length.toString() + `/${maxInputLength.L2000} You have reached the character limit for one note` }`}
                                error={note && note.length === maxInputLength.L2000}
                                onChange={(e) => {
                                    if (e.target.value.length) {
                                        handleNoteInput(e, index + initialNotes)
                                    } else {
                                        handleNoteInput(e, index + initialNotes)
                                        handleNoteDelete(index + initialNotes - 1)
                                    }
                                }}
                            />
                            <Divider style={{margin: '5px'}}/>
                        </Grid>
                    )
                })}
            </Grid>
            <TextField
                placeholder={placeholders.addAdditionalNote}
                multiline
                name='note'
                rows={2}
                rowsMax={9}
                value={currentNote}
                style={{
                    border: '1px solid gray',
                    padding: '6px',
                    borderRadius: '5px',
                    width: '99%'
                }}
                onChange={(e) => {
                    if (e.target.value.length) {
                        handleNoteInput(e, initialNotes + additionalNotes)
                        setCurrentNote(e.target.value);
                    } else {
                        handleNoteInput(e, initialNotes + additionalNotes)
                        handleActiveNoteDelete(initialNotes + additionalNotes - 1)
                        setCurrentNote(e.target.value);
                    }
                }}
                InputProps={{classes}}
                inputProps={{maxLength: maxInputLength.L2000}}
                helperText={currentNote && `${currentNote.length < maxInputLength.L2000 ? currentNote.length.toString() + `/${maxInputLength.L2000}`: currentNote.length.toString() + `/${maxInputLength.L2000} You have reached the character limit for one note` }`}
                error={currentNote && currentNote.length === maxInputLength.L2000}
            />
            <div style={{
                alignContent: 'right',
                justifyContent: 'space-between',
                display: 'flex',
                paddingTop: '10px',
                width: '100%'
            }}>
                {emptyNote ? (
                    <span style={{color: 'red'}}>Note must not be empty or contain only spaces !</span>
                ): (
                    <span/>
                )}
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => {
                        addNote();
                        setCurrentNote('')
                    }}>{buttonText.addNote}</Button>

            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" gutterBottom style={{display: "flex"}}>
                        {sectionTitles.infectiousDiseasePrecautions}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={patient.infectious_disease_protocol.contact}
                                        onChange={handleInfectiousChange}
                                        name="contact"
                                        color="primary"
                                    />
                                }
                                label={labels.contact}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={patient.infectious_disease_protocol.droplet}
                                        onChange={handleInfectiousChange}
                                        name="droplet"
                                        color="primary"
                                    />
                                }
                                label={labels.droplet}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={patient.infectious_disease_protocol.respiratory}
                                        onChange={handleInfectiousChange}
                                        name="respiratory"
                                        color="primary"
                                    />
                                }
                                label={labels.respiratory}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={patient.infectious_disease_protocol.covid_19}
                                        onChange={handleInfectiousChange}
                                        name="covid_19"
                                        color="primary"
                                    />
                                }
                                label={labels.covid19}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <CustomCheckbox
                                        checked={patient.infectious_disease_protocol.other}
                                        onChange={handleInfectiousChange}
                                        name="other"
                                    />
                                }
                                label={labels.other}

                            />
                        </Grid>
                        <Grid item>
                            {(patient.infectious_disease_protocol.other) && <TextField
                                required
                                id="other_infectious_disease_protocol"
                                name="other_disease"
                                label={labels.otherDisease}
                                variant={"outlined"}
                                size={"small"}
                                fullWidth
                                placeholder={placeholders.otherDiseaseProtocol}
                                value={patient.infectious_disease_protocol.other_disease}
                                onChange={handleInfectiousChange}
                                inputProps={{maxLength: maxInputLength.L255}}
                                helperText={patient?.infectious_disease_protocol?.other_disease && `${patient.infectious_disease_protocol.other_disease.length < maxInputLength.L255 ? patient.infectious_disease_protocol.other_disease.length.toString() + `/${maxInputLength.L255}`: patient.infectious_disease_protocol.other_disease.length.toString() + `/${maxInputLength.L255} You have reached the character limit for disease.` }`}
                                error={patient?.infectious_disease_protocol?.other_disease && patient.infectious_disease_protocol.other_disease.length === maxInputLength.L255}
                            />
                            }
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            {edit ? 
            <Grid container spacing={2}>
                <Grid item  style={{display: "flex", flexDirection: "row"}}>
                    <Typography variant="h6" style={{marginTop: 5}}>
                        {sectionTitles.prescriptionNeeded}
                    </Typography> 
                    
                    <CustomCheckbox
                        disabled={!checkPermissions([permissions.create_patients], "DME")}
                        checked={patient.prescription_required}
                        onChange={handlePrescriptionChange}
                        name="prescription_required"
                        color="primary"
                    />
                              
                </Grid>
                
            </Grid>
            : checkPermissions([permissions.create_patients], "DME") &&
            <Grid container spacing={2}>
                <Grid item  style={{display: "flex", flexDirection: "row"}}>
                    <Typography variant="h6" style={{marginTop: 5}}>
                        {sectionTitles.prescriptionNeeded}
                    </Typography> 
                    
                    <CustomCheckbox
                        disabled={!checkPermissions([permissions.create_patients], "DME")}
                        checked={patient.prescription_required}
                        onChange={handlePrescriptionChange}
                        name="prescription_required"
                        color="primary"
                    />
                              
                </Grid>
                
            </Grid>

            }
            {edit && <Grid item xs={12} container justify={"flex-start"} alignItems={"flex-start"}>
                <PrescriptionItemsTable hospiceId={patient.hospice_organization_id} regionId={patient.region_id} patientId={edit}/>
            </Grid>}
           
            {props.showIcdCodes && (
                <Grid container style={{maxWidth:570}}>
                    <Grid item xs={12} style={{fontSize:20, fontWeight:500, textAlign:'left', paddingTop:16, paddingLeft:8}}>ICD 10 Codes</Grid>
                    <Grid item  xs={12} style={{paddingLeft:6, paddingRight:6, paddingBottom:4}}>
                        <AsyncSelect
                            placeholder={'Select ICD'}
                            cacheOptions
                            defaultOptions
                            loadOptions={searchValues}
                            onChange={(e)=> {
                                setItems([...items, e])
                                setValue([])
                                props.addIcdCode(e)
                            }}
                            value={value}
                            styles={selectStyles}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            {patient.icd_codes.map((item, idx) =>{
                                return (
                                    <Grid item xs={12} style={{display:'flex',justifyContent:'space-between',  alignItems:'center', alignContent:'center', background:'whitesmoke', borderRadius:4, margin:4,marginTop:idx === 0 ? 8 : 0, marginBottom:8, padding:8, paddingTop:8}} key={idx}>
                                        <Grid item style={{fontSize:15, paddingLeft:8, textAlign:'left'}}>
                                            {item.label}
                                        </Grid>
                                        <Grid item>
                                            <IconButton size={"small"} onClick={()=> {
                                                props.removeIcdCode(item.icd_code_id)
                                            }}>
                                                <HighlightOffIcon color={'primary'} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                )
                            })
                            }
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </React.Fragment>
    );
}
