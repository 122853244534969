import React, {useEffect, useRef, useState} from "react";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";
import Grid from "@material-ui/core/Grid";
import {Backdrop, Dialog, DialogContent} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExportForm from "../../../common/exportForm/exportForm";
import MaterialTable from "material-table";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {endpoints} from "../../../constants/endpoints";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import jwt from "jwt-decode";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import html2canvas from "html2canvas";
import moment from "moment";
import Pie from "./components/pieChart";
import PieChart from "./components/pieChart";
import PieChartComponent from "./components/pieChart";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100,
        textAlign: 'left'
    })
};


export const NurseOrdering = ({}) => {
    const dispatch = useDispatch();
    const element =  useRef();
    const elementPieChart =  useRef();
    const history = useHistory();
    const [selectedTimeframe, setSelectedTimeframe] = useState(null);
    const [userType, setUserType] = useState(null);
    const [data, setData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [startDate, setStartDate] = useState(moment().format("MM-DD-YYYY"));
    const [endDate, setEndDate] = useState(moment().format("MM-DD-YYYY"));
    const [list, setList] = useState([]);
    const sortedList = list.sort((a, b) => a.label.localeCompare(b.label));
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [enableViewData, setEnableViewData] = useState(false);
    const [showPie, setShowPie] = useState(false)
    const [pieData, setPieData] = useState({
        delivery: {
            total:0,
            percent:0
        },
        pickup: {
            total:0,
            percent:0
        },
        exchange: {
            total:0,
            percent:0
        },
    })

    function getData() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getNurseOrderingReport, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                hospice_id: selectedOrg.value,
                start: `${startDate} 00:00:00.000`,
                end: `${endDate} 23:59:59.999`
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let obj =  {
                    delivery: {
                        total:0,
                        percent:0
                    },
                    pickup: {
                        total:0,
                        percent:0
                    },
                    exchange: {
                        total:0,
                        percent:0
                    }
                };
                response.data.map(interval => {
                    obj.delivery.total += interval.deliveries
                    obj.pickup.total += interval.pickups
                    obj.exchange.total += interval.exchanges
                })

                let total = obj.delivery.total + obj.pickup.total + obj.exchange.total
                obj.delivery.percent = Number((obj.delivery.total * 100 / total).toFixed(2));
                obj.pickup.percent = Number((obj.pickup.total * 100 / total).toFixed(2));
                obj.exchange.percent = Number((obj.exchange.total * 100 / total).toFixed(2));
                setPieData(obj)
                setData(response.data)
                setEnableViewData(true)
            })
            .catch(err => {
                console.log(err, 'error');
            })
    }

    async function getDetailsData(payload) {
        const availableToken = await checkTokenAvailability();
        if (!availableToken) {
            dispatch(logout());
            history.push('/login');
        }
        const token = JSON.parse(localStorage.getItem('key'));
        let promise = await fetch(endpoints.getNurseOrderingReportDetails, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(payload)
        })
        let response = await promise.json();
        if(response.status === "success"){
            let responseArr = [];
            response.data.map(row => {
                let newArr = [];
                newArr.push(row.name);
                newArr.push(new Date(row.created_date));
                newArr.push(row.type);
                newArr.push(row.delivery_order_id ? process.env.REACT_APP_API_URL + "order/delivery/" + row.delivery_order_id : "");
                newArr.push(row.pickup_order_id ? process.env.REACT_APP_API_URL + "order/pickup/" + row.pickup_order_id : "");
                newArr.push(row.exchange_order_id ? process.env.REACT_APP_API_URL + "order/exchange/" + row.exchange_order_id : "");
                newArr.push(row.quantity);
                newArr.push(row.first_name);
                newArr.push(row.last_name);
                newArr.push(row.patient_id);
                responseArr.push(newArr);
            })
            return responseArr;
        }
        return [];

    }

    function getSimpleDetailsData(){
        let arr = [];
        tableData.map(user => {
            user.delivery_ids.map(order => arr.push(["Delivery", `D100${order}`]))
            user.pickup_ids.map(order => arr.push(["Pickup", `P200${order}`]))
            user.exchange_ids.map(order => arr.push(["Exchange", `S300${order}`]))
        })
        return arr;
    }
    function getAllDetailsData(){
        let arr = [];
        data.map(interval => {
            interval.users.map(user => {
                user.delivery_ids.map(order => arr.push(["Delivery", `D100${order}`]))
                user.pickup_ids.map(order => arr.push(["Pickup", `P200${order}`]))
                user.exchange_ids.map(order => arr.push(["Exchange", `S300${order}`]))
            })
        })
        return arr;
    }

    useEffect(()=> {
        const token = JSON.parse(localStorage.getItem('key'))
        if (token && token.accessToken){
            let user = jwt(token.accessToken);
            if (user.payload.type === "Hospice"){
                setUserType('Hospice');
                let user = jwt(token.accessToken);
                let hospice_id = user.payload.hospice_organization_id;
                setSelectedOrg({
                    value:hospice_id
                })
            } else if (user.payload.type === "DME"){
                if (user.payload.role_id === 6){
                    setUserType('Admin');
                    getAllHospiceOrganizations();
                } else {
                    setUserType('DME');
                    getContractedHospiceOrganizations()
                }
            }
        }
    },[])

    useEffect(()=> {
       let index = data.findIndex(time => time.name === selectedTimeframe)
        data[index] && setTableData(data[index].users)
    }, [selectedTimeframe])


    async function getAllHospiceOrganizations(){

        const token = JSON.parse(localStorage.getItem("key"));
        const promise = await fetch(endpoints.getAllHospiceIds, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        if(response.status === "success"){
            let arr = [];
            response.data.map(org => {
                arr.push({
                    label:org.hospice_name,
                    value:org.hospice_organization_id
                })
            })
            setList(arr);

        }
        setLoading(false)
    }
    const createScreenshot = () => {
        html2canvas(element.current).then((canvas) => {
            document.body.appendChild(canvas)
            const a = document.createElement('a')
            a.id = "test"
            a.download = 'download.png'
            a.href = canvas.toDataURL('image/png')
            a.click();
            a.remove();
            document.body.removeChild(canvas)
        })
    }

    const createScreenshotPie = () => {
        html2canvas(elementPieChart.current).then((canvas) => {
            document.body.appendChild(canvas)
            const a = document.createElement('a')
            a.id = "test"
            a.download = 'chart.png'
            a.href = canvas.toDataURL('image/png')
            a.click();
            a.remove();
            document.body.removeChild(canvas)
        })
    }

    async function getContractedHospiceOrganizations(){
        const token = JSON.parse(localStorage.getItem("key"));
        let user = jwt(token.accessToken);
        const promise = await fetch(endpoints.getContractedHospicesByDmeIds, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            method: 'POST',
            body: JSON.stringify({
                dmeIds: [user.payload.dme_organization_id]
            })
        });
        const response = await promise.json();
        if(response.status === "success"){
            let arr = [];
            response.data[0].hospices.map(org => {
                arr.push({
                    label:org.hospice_name,
                    value:org.hospice_id
                })
            })
            setList(arr);

        }
        setLoading(false)
    }

    const clearData = () => {
        setData([]);
        setTableData([]);
        setSelectedOrg(null);
        setStartDate(moment().format("MM-DD-YYYY"));
        setEndDate(moment().format("MM-DD-YYYY"));
        setEnableViewData(false);
    }

    const headers = [
        {text: "Item Name", style: 'tableHeader'},
        {text: "Created Date", style: 'tableHeader'},
        {text: "Type", style: 'tableHeader'},
        {text: "Delivery Id", style: 'tableHeader'},
        {text: "Pickup Id", style: 'tableHeader'},
        {text: "Exchange Id", style: 'tableHeader'},
        {text: "Quantity", style: 'tableHeader'},
        {text: "Patient_First_Name", style: 'tableHeader'},
        {text: "Patient_Last_Name", style: 'tableHeader'},
        {text: "Patient_ID", style: 'tableHeader'},
    ];
    const excelHeaders = [
        "Item_Name",
        "Created_Date",
        "Type",
        "Delivery_Id",
        "Pickup_Id",
        "Exchange_Id",
        "Quantity",
        "Patient_First_Name",
        "Patient_Last_Name",
        "Patient_ID",
    ];
    const simpleHeaders = [
        {text: "Action", style: 'tableHeader'},
        {text: "Order_ID", style: 'tableHeader'},
    ];
    const simpleExcelHeaders = [
        "Action",
        "Order_ID",
    ];

    const columns = [
        {title: 'User', field: 'user',},
        {title: 'Email', field: 'email',},
        {title: 'Orders Placed', field: 'total'},
        {title: 'Deliveries Placed', field: 'deliveries'},
        {title: 'Pickups Placed', field: 'pickups'},
        {title: 'Exchanges Placed', field: 'exchanges'},
        {title: 'Chart', field: 'exchanges', sorting:false,headerStyle: {textAlign: 'center'},
            render: rowData => <Button style={{marginLeft:8, marginRight:8}} variant={'outlined'} color={'primary'} disabled={!startDate || !endDate || !selectedOrg} onClick={()=> showPieModal('user', rowData)}>
                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                     focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                     data-testid="PieChartOutlineIcon" style={{paddingRight:4}}>
                    <path fill='#00223D' d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm1 2.07c3.61.45 6.48 3.33 6.93 6.93H13V4.07zM4 12c0-4.06 3.07-7.44 7-7.93v15.87c-3.93-.5-7-3.88-7-7.94zm9 7.93V13h6.93c-.45 3.61-3.32 6.48-6.93 6.93z"/>
                </svg>
                Chart
            </Button>},
        {title: 'Download Details', field: 'exchanges',
        render: rowData => <ExportForm
            landscape={true}
            fileName={`${rowData.user}_Ordering`}
            getExportValues={() => getDetailsData(rowData)}
            showPrint={false}
            headers={headers}
            excelHeaders={excelHeaders}
        />

},
    ]

    function showPieModal(type, receivedData){
        switch (type){
            case "full":
                let objVal =  {
                    delivery: {
                        total:0,
                        percent:0
                    },
                    pickup: {
                        total:0,
                        percent:0
                    },
                    exchange: {
                        total:0,
                        percent:0
                    }
                };
                data.map(interval => {
                    objVal.delivery.total += interval.deliveries
                    objVal.pickup.total += interval.pickups
                    objVal.exchange.total += interval.exchanges
                })

                let totalVal = objVal.delivery.total + objVal.pickup.total + objVal.exchange.total
                objVal.delivery.percent = Number((objVal.delivery.total * 100 / totalVal).toFixed(2));
                objVal.pickup.percent = Number((objVal.pickup.total * 100 / totalVal).toFixed(2));
                objVal.exchange.percent = Number((objVal.exchange.total * 100 / totalVal).toFixed(2));
                setPieData(objVal)
                setShowPie(true);
                break
            case "user":
                let obj =  {
                    delivery: {
                        total: receivedData.delivery_ids.length,
                        percent:0
                    },
                    pickup: {
                        total: receivedData.pickup_ids.length,
                        percent:0
                    },
                    exchange: {
                        total: receivedData.exchange_ids.length,
                        percent:0
                    }
                };
                let total = obj.delivery.total + obj.pickup.total + obj.exchange.total
                obj.delivery.percent = Number((obj.delivery.total * 100 / total).toFixed(2));
                obj.pickup.percent = Number((obj.pickup.total * 100 / total).toFixed(2));
                obj.exchange.percent = Number((obj.exchange.total * 100 / total).toFixed(2));
                setPieData(obj)
                setShowPie(true);
                break
            default :
                return
        }

    }


    const [loading, setLoading] = useState(false);
    return <Grid container style={{padding: 32}}>
          <Grid container style={{backgroundColor: 'white', borderRadius: 4}}>
            <Backdrop open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Grid container
                  style={{textAlign: 'left', fontWeight: 'bold', fontSize: 24, paddingTop: 24, paddingLeft: 24}}>
               Ordering Report
            </Grid>
            {
                (userType === "Admin" || userType === "DME") && (
                    <Grid item md={2} sm={4} xs={12} style={{padding:16}}>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            isClearable
                            isSearchable
                            value={selectedOrg}
                            name="color"
                            options={sortedList}
                            styles={selectStyles}
                            onChange={(e)=> setSelectedOrg(e)}
                        />
                    </Grid>
                )
            }
            <Grid item style={{paddingLeft:16}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                            margin="normal"
                            style={{
                                backgroundColor:'white',
                                width:'100%'
                            }}
                            inputVariant="outlined"
                            placeholder='MM/DD/YYYY'
                            size={"small"}
                            label="Start Date"
                            format="MM/dd/yyyy"
                            value={startDate}
                            onChange={(e, value) => {
                               setStartDate(value);
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item style={{paddingLeft:8}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justify="space-around">
                        <KeyboardDatePicker
                            margin="normal"
                            style={{
                                backgroundColor:'white',
                                width:'100%'
                            }}
                            inputVariant="outlined"
                            placeholder='MM/DD/YYYY'
                            size={"small"}
                            label="End Date"
                            format="MM/dd/yyyy"
                            value={endDate}
                            onChange={(e, value) => {
                                setEndDate(value);
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            </Grid>
            <Grid item style={{paddingTop:16, paddingLeft:8}}>
                <Button variant={'contained'} color={'primary'} onClick={getData} disabled={!startDate || !endDate || !selectedOrg}>Generate Report</Button>
            </Grid>
            <Grid item style={{paddingTop:16, paddingLeft:8}}>
                <Button variant={'outlined'} color={'primary'} onClick={clearData}>Clear Filters</Button>
            </Grid>
            {enableViewData && (
                <Grid container>
                    <Grid container item xs={12} ref={element}>
                        <BarChart
                            onClick={(e)=> {
                                setSelectedTimeframe(e.activeLabel)
                            }}
                            width={1230}
                            height={340}
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis dataKey="name"/>
                            <YAxis/>
                            <Tooltip/>
                            <Legend/>
                            <Bar dataKey="deliveries" stackId="a" fill="#ffc658"/>
                            <Bar dataKey="pickups" stackId="b" fill="#8884d8"/>
                            <Bar dataKey="exchanges" stackId="c" fill="#82ca9d"/>
                        </BarChart>
                    </Grid>
                    <Grid item={12} style={{paddingBottom:8, paddingLeft:8, display:'flex',alignContent:'center', alignItems:'center', justifyContent:'space-between', width:'100%' ,maxWidth:1200}}>
                        <div style={{fontSize:16, fontWeight:'bold', paddingLeft:16}}>
                            {tableData.length > 0 ? `Selected Interval: ${selectedTimeframe}` : "Select an interval to show more informartion"}
                        </div>
                        <div style={{display:"flex"}}>
                            {
                                tableData.length > 0 && (
                                    <ExportForm
                                        landscape={true}
                                        fileName={`Ordering Report`}
                                        getExportValues={getSimpleDetailsData}
                                        showPrint={false}
                                        headers={simpleHeaders}
                                        excelHeaders={simpleExcelHeaders}
                                        buttonText={'Download Selected Data'}
                                    />
                                   )
                            }
                            <ExportForm
                                landscape={true}
                                fileName={`Ordering Report`}
                                getExportValues={getAllDetailsData}
                                showPrint={false}
                                headers={simpleHeaders}
                                excelHeaders={simpleExcelHeaders}
                                buttonText={'Download All Data'}
                            />
                            <Button style={{marginLeft:8, marginRight:8}} variant={'outlined'} color={'primary'} onClick={createScreenshot} disabled={!startDate || !endDate || !selectedOrg} onClick={()=> showPieModal('full')}>
                                <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                                     focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                                     data-testid="PieChartOutlineIcon" style={{paddingRight:4}}>
                                    <path fill='#00223D' d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm1 2.07c3.61.45 6.48 3.33 6.93 6.93H13V4.07zM4 12c0-4.06 3.07-7.44 7-7.93v15.87c-3.93-.5-7-3.88-7-7.94zm9 7.93V13h6.93c-.45 3.61-3.32 6.48-6.93 6.93z"/>
                                </svg>
                                Advanced Chart
                            </Button>
                            <Button variant={'outlined'} color={'primary'} onClick={createScreenshot} disabled={!startDate || !endDate || !selectedOrg}>Download Chart</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            tableData.length > 0 && (
                                <MaterialTable
                                    options={{
                                        search: true,
                                        showTitle: false
                                    }}
                                    columns={columns}
                                    data={tableData}
                                    style={{width: 1200}}/>
                            )
                        }
                    </Grid>
                </Grid>
            )}
              <Dialog open={showPie} onClose={()=> setShowPie(false)}>
                  <DialogContent>
                      <Grid container justify={'space-between'} style={{fontSize:20}}>
                          <div>
                              Breakdown Report
                          </div>
                          <div>
                              <IconButton size={'small'} onClick={()=>setShowPie(false)}>
                                  <CloseIcon />
                              </IconButton>
                          </div>

                      </Grid>
                      <div ref={elementPieChart}>
                          <PieChartComponent data={pieData}/>
                      </div>
                      <Grid container justify={'flex-end'} style={{paddingBottom:16}}>
                          <Button color={'primary'} variant={'outlined'} onClick={createScreenshotPie}>
                              Download Chart
                          </Button>
                      </Grid>
                  </DialogContent>
              </Dialog>
        </Grid>
    </Grid>

}
