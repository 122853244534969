import {endpoints} from "../../constants/endpoints";
import jwt from "jwt-decode"
import {status} from "../../constants/status/status";
import {utilsTokenIdentifiers} from "../../constants/inputs/values";
import moment from "moment";

export const checkUsersForThisEmail = async (email, roleName, orgType) => {
    const token = JSON.parse(localStorage.getItem('key'));

    const rolePromise = await fetch(endpoints.getRoleId + roleName + "/" + orgType, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    });
    const roleData = await rolePromise.json();

    if (rolePromise.status === status.success) {
        const promise = await fetch(endpoints.checkUserByEmailAndRole, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(
                {
                    role_id: roleData.data,
                    email: email
                })
        });
        const response = await promise.json();
        return response.status === "success";
    } else {
        return false;
    }
};

export const checkTokenAvailability = async () => {
    const localToken = JSON.parse(localStorage.getItem('key'));
    if (localToken !== null && localToken !== undefined){
        const token = jwt(localToken.accessToken);
        const {exp} = token;
        const currentTime = new Date().getTime();
        return currentTime < exp * 1000;
    } else {
        return false
    }
};

export const checkPaceHospice = async (hospiceId) => {
    if (hospiceId) {
        const token = JSON.parse(localStorage.getItem('key'));
        const paceHospicePromise = await fetch(endpoints.getPaceSettings + hospiceId, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const resData = await paceHospicePromise.json();
        return resData.data[0].ispace;
    }

};

export const checkIsOutsideBusinessHours = async (hospiceId, dmeId) => {
    if (dmeId && hospiceId) {
        const token = JSON.parse(localStorage.getItem('key'));
        const dmeBusinessHoursPromise = await fetch(endpoints.getBusinessHoursInfo + dmeId + "/" + hospiceId, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const resData = await dmeBusinessHoursPromise.json();
        return resData.data;
    }
};

export const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
export const formatNumber = (number, version) => {
    if(number) {
        let match = number.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            switch (version) {
                case "parentheses":
                    return `(${match[1]}) ${match[2]}-${match[3]}`
                default:
                    return match[1] + '-' + match[2] + '-' + match[3]
            }
        }
    }
    return number;
}

export const convertImgToBase64 = (url, callback) => {
    let canvas = document.createElement('CANVAS');
    let ctx = canvas.getContext('2d');
    let img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img,0,0);
        let dataURL = canvas.toDataURL('image/png');
        callback.call(this, dataURL);
        // Clean up
        canvas = null;
    };
    img.src = url;
}

export const getHospiceLogo = async (setter, token, hospiceId) => {
    const promise = await fetch(endpoints.getHospiceLogo + hospiceId, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    })
    if(promise.status === status.success) {
        const response = await promise.json();
        if (response.status === "success") {
            setter(response.data);
        }
    }
}


export const getTokenInformation = (history, requiredField) => {
    const token = localStorage.getItem("key");
    if(token){
        const parsedToken = JSON.parse(token);
        if(parsedToken && checkTokenAvailability()){
            const decodedToken = jwt(parsedToken.accessToken);
            if(decodedToken) {
                const {payload} = decodedToken;
                switch (requiredField) {
                    case utilsTokenIdentifiers.accessToken:
                        return parsedToken.accessToken;
                    case utilsTokenIdentifiers.refreshToken:
                        return parsedToken.refreshToken;
                    case utilsTokenIdentifiers.payload:
                        return payload;
                    case utilsTokenIdentifiers.userId:
                        return payload.user_id;
                    case utilsTokenIdentifiers.userType:
                        return payload.type;
                    case utilsTokenIdentifiers.dmeName:
                        return payload.dme_name;
                        /// anything else that is needed
                    default:
                        return parsedToken.accessToken;
                }
            } else {
                history.push("/login");
            }
        } else {
            history.push("/login");
        }
    } else {
        history.push("/login");
    }
}

export const getYearRange = (startDate) => {
    const currentDate = moment();
    const date = moment(startDate);
    const yearArray = [];
    while(date < currentDate) {
        yearArray.push(date.get('year'));
        date.add(1,"year");
    }
    return yearArray;

}

let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const dollarCurrencyFormatter = (value) => {
    return dollarUS.format(value);
}

export const redirectAfterLogin = (history, location) => {
    const {redirectOriginPathname} = location;
    const originPathnameComponents = redirectOriginPathname.split('/');
    if(originPathnameComponents[1] === 'redirect' && originPathnameComponents[2] === 'order'){
        return history.push(`/order/${originPathnameComponents[3]}/${originPathnameComponents[4]}`);
    }
    if (originPathnameComponents[1] === 'redirect' && originPathnameComponents[2] === 'vieworders') {
        return history.push(`/orders`);
    }
    history.push('/dashboard');
}