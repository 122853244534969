import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Backdrop} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExportForm from "../../../common/exportForm/exportForm";
import Button from "@material-ui/core/Button";
import {getTokenInformation} from "../../../common/utils/utils";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {useSnackbar} from "notistack";
import {Table} from "../../../common/table/table";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import DateRangeIcon from "@material-ui/icons/DateRange";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {alertTitles} from "../../../constants/alert/alertTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {DateRangePicker} from "react-date-range";
import DialogActions from "@material-ui/core/DialogActions";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import {subDays} from "date-fns";
import {utilsTokenIdentifiers} from "../../../constants/inputs/values";
import {endpoints} from "../../../constants/endpoints";
import {endpointStatus} from "../../../constants/messages/endpointStatus";


export const LengthOfStayReport = ({}) => {
    const {enqueueSnackbar} = useSnackbar();
    const history = useHistory();
    const [withDoSData, setWithDosData] = useState([]);
    const [withoutDoSData, setWithoutDosData] = useState([]);
    const [dateStart, setDateStart] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'));
    const [selectedOrg, setSelectedOrg] = useState(null);
    const tableRef = React.createRef();
    const tableRef2 = React.createRef();
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [dateSelection, setDateSelection] = useState([
        {
            startDate: subDays(new Date(), 30),
            endDate: new Date(),
            key: "selection"
        }
    ]);

    const getContractedHospices = async () => {
        const payload = getTokenInformation(history, utilsTokenIdentifiers.payload);
        if(payload?.dme_organization_id){
            const promise = await fetch(endpoints.getContractedHospicesByDmeIds, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                },
                body: JSON.stringify({dmeIds: [payload.dme_organization_id]})

            });
            const response = await promise.json();
            setSelectedOrg(response.data[0].hospices[0]);
        }

    };

    useEffect(() => {
        getContractedHospices();
    }, []);

    const getData = async () => {
        setLoading(true);
        setWithDosData([]);
        setWithDosData([]);
        const withDosPromise = await fetch(endpoints.lengthOfStayWithDOS, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            },
            body: JSON.stringify({
                hospice_id: selectedOrg.hospice_id,
                start_date: dateStart,
                end_date: dateEnd,
            })
        });
        const withoutDosPromise = await fetch(endpoints.lengthOfStayWithoutDOS + selectedOrg.hospice_id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            },
        });
        const withDosResponse = await withDosPromise.json();
        const withoutDosResponse = await withoutDosPromise.json();
        if(withDosResponse.status === "success"){
            setWithDosData(withDosResponse.data);
        }
        if(withoutDosResponse.status === "success"){
            setWithoutDosData(withoutDosResponse.data);
        }
        setLoading(false);
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const getExportValues = (ref) => {
        let finalArr = [];
        ref.current.state.data.map((el) => {
            let newArr = [];
            newArr.push(el.patient_id);
            newArr.push(el.medical_record);
            newArr.push(el.patient_full_name);
            newArr.push(el.status);
            newArr.push(new Date(el.patient_created_date));
            newArr.push(el.first_delivery_date_in_selected_period ? new Date(el.first_delivery_date_in_selected_period) : "");
            newArr.push(el.last_pickup_date_in_selected_period ? new Date(el.last_pickup_date_in_selected_period) : "" );
            newArr.push(el.days_of_stay ? el.days_of_stay : '-');

            finalArr.push(newArr);
        })

        return finalArr;
    };

    const headers = [
        {text: "Patient ID", style: 'tableHeader'},
        {text: "Patient Medical Record", style: 'tableHeader'},
        {text: "Patient Full Name", style: 'tableHeader'},
        {text: "Status", style: 'tableHeader'},
        {text: "Patient Created Date", style: 'tableHeader'},
        {text: "First Delivery Date", style: 'tableHeader'},
        {text: "Last Pickup Date", style: 'tableHeader'},
        {text: "Days Of Stay", style: 'tableHeader'},
    ];
    const excelHeaders = [
        "Patient ID",
        "Medical Record",
        "Patient Name",
        "Status",
        "Patient Created Date",
        "First Delivery Date",
        "Last Pickup Date",
        "Days Of Stay",
    ];
    const columns = [
        {title: 'Patient ID', field: 'patient_id'},
        {title: 'Medical Record', field: 'medical_record'},
        {title: 'Patient Name', field: 'patient_full_name'},
        {title: 'Status', field: 'status'},
        {title: 'Patient Created Date', field: 'patient_created_date'},
        {title: 'First Delivery Date', field: 'first_delivery_date_in_selected_period'},
        {title: 'Last Pickup Date', field: 'last_pickup_date_in_selected_period'},
        {
            title: 'Days Of Stay', field: 'days_of_stay',
            render: rowData => <span>
                {rowData.days_of_stay ? rowData.days_of_stay : '-'}
            </span>
        }
    ];

    const actionModalCloseDateSelection = () => {
        setOpen(false)
    };

    const applyDateRangeSelection = (ordersStartDate, ordersEndDate) => {

        setOpen(false)
    }

    const handleOnChange = ranges => {
        const {selection} = ranges;
        setDateEnd(moment(selection.endDate).format('YYYY-MM-DD'))
        setDateStart(moment(selection.startDate).format('YYYY-MM-DD'))
        setDateSelection([selection]);
    };

    const [loading, setLoading] = useState(false);
    return <Grid container className={classes.topContainer}>
        <Backdrop open={loading} className={classes.backdrop}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Grid container className={classes.header}>
            Length Of Stay Report
        </Grid>
        <Grid item container xs={12} justify={"flex-end"} className={classes.paddingR32}>
            <Grid item>
            </Grid>
            <Grid item className={classes.paddingButton}>
                <Button
                    className={classes.button}
                    variant={'contained'}
                    color={'primary'}
                    startIcon={<DateRangeIcon/>}
                    style={{cursor: 'pointer'}}
                    onClick={handleClickOpen}
                >
                    Select Range
                </Button>
            </Grid>
            <Grid className={classes.paddingButton}>
                <Button
                    className={classes.button}
                    variant={'contained'}
                    color={'primary'}
                    startIcon={<DateRangeIcon/>}
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                        let orderStartDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
                        let orderEndDate = moment().format('YYYY-MM-DD')
                        setDateStart(orderStartDate);
                        setDateEnd(orderEndDate);
                        setWithoutDosData([]);
                        setWithDosData([]);
                        applyDateRangeSelection(orderStartDate, orderEndDate)
                    }}
                >
                    Clear Range
                </Button>
            </Grid>
            <Grid item className={classes.paddingButton}>
                <Button variant={'contained'} color={'primary'} onClick={getData}
                        disabled={!dateStart || !dateEnd || !selectedOrg }>Generate
                    Report</Button>
            </Grid>
        </Grid>
        <Grid item xs={12}>
            {selectedOrg && <Typography className={classes.dateRangeSelection}>
                {`Generate report for ${getTokenInformation(history, utilsTokenIdentifiers.dmeName)} and ${selectedOrg.hospice_name} `}
                for range selection: {moment(dateStart).format('MM/DD/YYYY')} - {moment(dateEnd).format('MM/DD/YYYY')}
            </Typography>}
        </Grid>
        <Grid container className={classes.padding24} justify={'center'}>
            <Grid item container xs={12} justify={'flex-end'}>
                <ExportForm
                    fileName={`${selectedOrg?.hospice_name ? selectedOrg?.hospice_name : "Hospice"}_WITH_DOS`}
                    headers={headers}
                    excelHeaders={excelHeaders}
                    showPrint={true}
                    getExportValues={() => getExportValues(tableRef)}
                />
            </Grid>
            <Grid item xs={12}>
                <Table
                    title={"Patients with DOS"}
                    tableRef={tableRef}
                    search={true}
                    filtering={true}
                    showSearch={true}
                    columns={columns}
                    items={withDoSData}
                    options={{
                        search: true,
                        showTitle: false
                    }}
                    backGroundColor={"rgba(237, 242, 244, 0.73)"}
                    textColor={"rgba(46, 50, 54, 1)"}
                />
            </Grid>
        </Grid>
        <Grid container className={classes.padding24} justify={'center'}>
            <Grid item container xs={12} justify={'flex-end'}>
                <ExportForm
                    fileName={`${selectedOrg?.hospice_name ? selectedOrg?.hospice_name : "Hospice"}_WITHOUT_DOS`}
                    headers={headers}
                    excelHeaders={excelHeaders}
                    showPrint={true}
                    getExportValues={() => getExportValues(tableRef2)}
                />
            </Grid>
            <Grid item xs={12}>
                <Table
                    title={"Patients without DOS"}
                    tableRef={tableRef2}
                    search={true}
                    filtering={true}
                    showSearch={true}
                    columns={columns}
                    items={withoutDoSData}
                    options={{
                        search: true,
                        showTitle: false
                    }}
                    backGroundColor={"rgba(237, 242, 244, 0.73)"}
                    textColor={"rgba(46, 50, 54, 1)"}
                />
            </Grid>
        </Grid>
        <Dialog
            open={open}
            onClose={actionModalCloseDateSelection}
        >
            <DialogTitle>{alertTitles.selectDateRange}</DialogTitle>
            <DialogContent>
                <DateRangePicker
                    minDate={new Date(2019, 0)}
                    maxDate={new Date(2034, 11, 31)}
                    onChange={handleOnChange}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={1}
                    ranges={dateSelection}
                    direction="horizontal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={actionModalCloseDateSelection} variant="outlined" color="secondary">
                    {buttonText.cancelSelection}
                </Button>
                <Button
                    onClick={() => applyDateRangeSelection()}
                    variant="contained" color="primary">
                    {buttonText.applySelection}
                </Button>
            </DialogActions>
        </Dialog>
    </Grid>

}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    header: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 24,
        paddingTop: 24,
        paddingLeft: 24
    },
    topContainer: {
        margin: 32,
        padding: 24,
        backgroundColor: 'white',
        borderRadius: 4
    },
    datePicker: {
        backgroundColor: 'white',
        width: '100%'
    },
    paddingR8: {paddingRight: 8},
    paddingL8: {paddingLeft: 8},
    paddingL16: {paddingLeft: 16},
    padding16: {padding: 16},
    paddingR32: {paddingRight: 32},
    paddingT24: {paddingTop: 24},
    padding24: {padding: 24},
    paddingButton: {paddingTop: 16, paddingLeft: 8},
    textAlignLeft: {textAlign: "left"},
    dateRangeSelection: {
        textAlign: 'left',
        color: '#4E6071',
        fontSize: 18,
        fontWeight: "bold",
        marginBottom: 16,
        paddingTop: 20,
        paddingLeft: 24
    },
}));
