import React from 'react'
import {useHistory} from 'react-router-dom'
import {Button, Grid} from "@material-ui/core";

export default function RouteNotFound(){
    const history = useHistory();
    const [timer,setTimer] = React.useState(5);

    React.useEffect(() => {
        const interval = setInterval(() => {
            setTimer((prev) => prev-1);
            if(timer === 0){
                history.push('/');
            }
        },1000);

        return () => clearInterval(interval)
    },[timer])

    return (
        <Grid container direction="column" style={{paddingTop: '36px'}} spacing={4}>
            <Grid item style={{fontSize:'36px',fontWeight:700}}>
                Whoops!
            </Grid>
            <Grid item style={{fontSize:'18px'}}>
                We could not find the page you are looking for !
            </Grid>
            <Grid item style={{fontSize:'18px'}}>
                You will be automatically redirected in {timer}
            </Grid>

            <Grid item>
                <Button color='primary' variant='contained' onClick={() => history.push('/')}>
                    Go back to Dashboard!
                </Button>
            </Grid>
        </Grid>
    )
}