import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import FormHelperText from "@material-ui/core/FormHelperText";
import {titles} from "../../../constants/inputs/tableColumnTitles";
import {sectionTitles} from "../../../constants/names/titles";
import {Table} from "../../../common/table/table";
import {patientInventoryStyles} from "../requestExchangeStyles";

export default function PickupComponent(props) {
    const classes = patientInventoryStyles();
    const {patientInventory, exchangeCart, handleInput, exchangeCartTableColumns} = props;

    const pickupTableColumns = [
        {
            title: titles.item, field: "item_name", width: 150,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {rowData.name}
                </Typography>
            </Grid>
        },
        {
            title: titles.itemQuantity, field: "quantity", width: 100,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {rowData.quantity}
                </Typography>
            </Grid>
        },
        {
            title: titles.asset, field: "asset", width: 150,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {rowData.asset}
                </Typography>
            </Grid>
        },
        {
            title: titles.deliveryDate, field: "delivery_completed_date", width: 150,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {rowData.delivery_completed_date && moment(rowData.delivery_completed_date).format('MMMM DD YYYY')}
                </Typography>
            </Grid>
        },
        {
            title: titles.provider, field: "dme_name", width: 200,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {rowData.dme_name ? rowData.dme_name : rowData.delivery_dme_name}
                </Typography>
            </Grid>
        },
        {
            title: titles.pickup, field: "pickup", width: 150,
            render: rowData => <Grid item className={classes.center}>
                <Switch checked={rowData.pickup}
                        disabled={!props.dmeId === rowData.dmeId}
                        onChange={e => {
                            const obj = {
                                target: {
                                    ...e,
                                    name: "pickup",
                                    findItem: {
                                        ...rowData,
                                    },
                                    value: !rowData.pickup,

                                }
                            };
                            handleInput(obj);
                        }}
                        name="status"
                        color="primary"
                />

                <FormHelperText style={{width: 50}}>
                    {rowData.pickup && titles.pickup}
                </FormHelperText>
            </Grid>
        },
    ];

    return (
        <Grid container justify="center" alignitems="center" direction={"row"} spacing={5}>
            <Grid item className={classes.center}  style={{justifyContent:'flex-start',alignItems:'flex-start', overflowX: "auto"}}>
                <Typography variant={"h6"}>
                    {sectionTitles.patientInventory}
                </Typography>
                <Table columns={pickupTableColumns} items={patientInventory} isLoading={false} pageSize={10} backGroundColor={'#F2F6F7'} textColor={"#2E3236"}/>
            </Grid>
            <Grid item className={classes.center} style={{justifyContent:'flex-start',alignItems:'flex-start', overflowX: "auto"}}>
                <Typography variant={"h6"}>
                    {sectionTitles.exchangeCart}
                </Typography>
                <Table columns={exchangeCartTableColumns} items={exchangeCart} isLoading={false} pageSize={10} backGroundColor={'#F2F6F7'} textColor={"#2E3236"}/>
            </Grid>
        </Grid>
    )
}