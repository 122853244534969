import React, {useEffect, useState} from 'react';
import Card from '@material-ui/core/Card';
import Input from '@material-ui/core/Input';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from "react-select";
import Switch from "@material-ui/core/Switch";
import SearchIcon from "../../../assets/icon_search.svg";
import {Popover, Tooltip} from "@material-ui/core";
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: 275,
        paddingBottom: '15px',
        marginBottom: '5px',
        overflow: "visible",
        width:'100%'
    },
    searchIcon: {
        color: '#546e7a',
        textAlign: 'left'
    },
    keywords: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'left',
        width: '100%',
        textAlign: 'left',
        verticalAlign: 'center'
    },
    input: {
        '&::placeholder': {
            color: 'blue'
        }
    },
    criteria: {
        margin: theme.spacing(1)
    },
    criterias: {
        padding: theme.spacing(0),
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingLeft: '7%',
        height: '48px'
    },
    selects: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        padding: theme.spacing(1),
    },
    inNetwork: {
        marginLeft: '-7px'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
    },
    menuItem: {
        padding: 0
    },
    margin: {
        margin: theme.spacing(1),
    },
    searchButton: {
        color: '#FFF',
        fontSize: 12,
        fontWeight: 'bold',
        backgroundColor: 'none',
        paddingLeft: 43,
        paddingRight: 43,
        "&:hover": {
            backgroundColor: '#004175'
        },
        maxWidth:"100%"
    },
    clearButton: {
        color: '#00223D',
        fontSize: 12,
        fontWeight: 'bold',
        backgroundColor: '#fff',
        border:'1px solid #00223D',
        paddingLeft:22,
        paddingRight:22,
        paddingTop:9,
        paddingBottom:9,
        maxWidth:135,
        [theme.breakpoints.down('md')]: {
            paddingLeft: 22,
            paddingRight: 22,
        },
    },
    filterOptions: {
        zIndex: '11',
    },
    filterContainer: {
        paddingDown: theme.spacing(3),
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    filter: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    filterName: {
        width: "200px"
    },
    popoverContainer: {
        backgroundColor: `rgb(249, 250, 252)`,
        padding: 8,
        margin: 0,
    }
}));

export const TabSearchBar = ({ enableHelper = false, ...props}) => {
    const classes = useStyles();
    const [input, setInput] = useState(props.filtersValue.input);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    useEffect(() => {
        setInput(props.filtersValue.input);
    }, [props.filtersValue.input]);
    useEffect(() => {
        props.handleInputChange(input)
    }, [input]);

    const handleSearch = () => {
        props.getPatients && props.getPatients(10,0);
        props.setPage && props.setPage(0);
        props.setRowsPerPage && props.setRowsPerPage(10);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const infoArray = [
        {value:`Typing the value "John" will yield all patients that contains the value "John" in the following fields:`, type: "subtitle1"},
        {value:`Last Name contains John`, type: "body1"},
        {value:`Or First Name contains John`, type: "body1"},
        {value:`Or Medical Record Number contains John`, type: "body1"},
        {value:`Or Address1 contains John`, type: "body1"},
        {value:`Or Address2 contains John`, type: "body1"},
        {value:`Or Zip contains John`, type: "body1"},
        {value:`Or City contains John`, type: "body1"},
        {value:`Or State contains John`, type: "body1"},
        {value:`Or Patient ID contains John`, type: "body1"},
        {value:`NOTE: Changing "JOHN" to "PARK STR" will search for "PARK STR" in all the fields mentioned above but also in the "FirstName LastName" combination.`, type: "body2"},
    ];

    return (
        <Card className={classes.root} style={{boxShadow: 'none', backgroundColor: '#F9FAFC',}}>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <div className={classes.keywords} style={{boxShadow: 'none', backgroundColor: 'none'}}>
                        <Grid item sm={7} xs={7}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: '1px solid #A3C7D2',
                                height: '100%'
                            }}>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Grid container spacing={1} direction={"column"} alignContent={"flex-start"} justify={"flex-start"} className={classes.popoverContainer}>
                                        <Grid item xs={12}>
                                            <Typography variant={"h6"}>
                                                Search example:
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {infoArray.map((item, index) => {
                                                return <Typography key={index} variant={item.type} style={{paddingTop: 4, paddingBottom: 4, backgroundColor: index %2 === 0 ? `rgb(242, 242, 242)` : `rgb(249, 250, 252)`}} id={"item.type" + index.toString()}>
                                                    {item.value}
                                                </Typography>
                                            })}
                                        </Grid>
                                    </Grid>
                                </Popover>
                                {enableHelper && <InfoRoundedIcon color={"primary"} style={{fontSize: 32}}
                                                  onClick={(event) => setAnchorEl(event.currentTarget)}/>}
                                {props.placeholder ? <Tooltip title={props.placeholder} placement={"top-end"}>
                                        <img
                                            src={SearchIcon}
                                            alt="logo"
                                            width='24'
                                            className={classes.cursor}
                                            style={{marginRight: 16, marginLeft: 18}}
                                        />
                                    </Tooltip> :
                                    <img
                                        src={SearchIcon}
                                        alt="logo"
                                        width='24'
                                        className={classes.cursor}
                                        style={{marginRight: 16, marginLeft: 18}}
                                    />}
                                <Input
                                    id={"searchInput"}
                                    disableUnderline
                                    onChange={(e) => setInput(e.target.value)}
                                    placeholder={props.placeholder ? props.placeholder : "Search..."}
                                    value={input}
                                    style={{width: '98%'}}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={4} style={{textAlign:'right', paddingRight: '10px'}}>
                            <Button className={classes.searchButton} variant='contained' color='primary'
                                    onClick={()=>handleSearch()}>{props.buttonText ? props.buttonText : 'SEARCH'}</Button>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{
                        boxShadow: 'none', backgroundColor: 'none',
                        display: 'flex',
                        alignItems: 'left',
                        width: '100%',
                        textAlign: 'left',
                        verticalAlign: 'center'
                    }}>
                    <Grid item lg={12} md={7} sm={7} xs={7} style={{display:'flex', alignItems:'center', marginRight:0, paddingTop:8}}>
                        <Grid container spacing={1} className={classes.filterContainer}>
                            {
                                props.filterOptions.map((filter, index) => (
                                    filter.isSelect ?
                                        <Grid item lg={2} md={4} sm={6} xs={12} key={index} style={{zIndex: 300 - index}}>
                                            <Select
                                                fullwidth
                                                isClearable
                                                className={classes.filterOptions}
                                                isSearchable={true}
                                                name={filter.name}
                                                placeholder={filter.placeholder}
                                                value={(filter.value === "All" || filter.value === undefined) ? null : [{
                                                    label: filter.value,
                                                    value: filter.value
                                                }]}
                                                options={filter.options}
                                                onChange={(event) => {
                                                    const obj = {
                                                        target: {
                                                            ...event,
                                                            name: filter.name,
                                                        }
                                                    };
                                                    props.handleFilterUpdate(obj);
                                                }}
                                                onKeyDown={handleKeyDown}
                                            />
                                        </Grid> :
                                        <Grid item xs={2} className={classes.filter} key={index}>
                                            <Typography variant="subtitle2" className={classes.filterName}>
                                                {filter.value ? filter.positive : filter.negative}
                                            </Typography>
                                            <Switch checked={filter.value}
                                                    onClick={(event) => {
                                                        const obj = {
                                                            target: {
                                                                name: event.target.name,
                                                                value: !filter.value
                                                            }
                                                        };
                                                        props.handleFilterUpdate(obj)
                                                    }}
                                                    name={filter.name}
                                                    color="primary"
                                            />
                                        </Grid>
                                ))}
                        </Grid>
                    </Grid>
                        <Grid item xs={1}></Grid>
                    <Grid item xs={4} style={{textAlign: 'right', paddingTop: '4px', paddingRight: '10px'}}>
                        <Button variant="outlined" className={classes.clearButton} onClick={props.clearFilters}>CLEAR FILTERS</Button>
                    </Grid>
                    </div>
                </Grid>
            </Grid>
        </Card>
    )
};
