import React, {forwardRef, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import {ArrowDownward} from "@material-ui/icons";
import {tableColumnTitles} from "../../../../../constants/names/columnTitles";
import Checkbox from "@material-ui/core/Checkbox";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import {endpoints} from "../../../../../constants/endpoints";
import {checkTokenAvailability} from "../../../../../common/utils/utils";
import {logout} from "../../../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";

export const ViewCustomPackages = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const isSmallScreen = useMediaQuery('(max-width:420px)');
    const [name, setName] = useState('');
    const [hospiceList, setHospiceList] = useState([]);
    const [dmeList, setDmeList] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [hospiceLoading, setHospiceLoading] = useState(false);
    const [regionLoading, setRegionLoading] = useState(false);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [selectedDme, setSelectedDme] =useState([]);
    const [selectedHospice, setSelectedHospice] =useState([]);
    const [selectedRegion, setSelectedRegion] =useState([]);
    const [contractedItems, setContractedItems] =useState([]);
    const [selectedItems, setSelectedItems] =useState([]);
    const [validForm, setValidForm] = useState(false);
    const columns = [
        {title: 'Created Date', field: 'created_date', width: 200,
            render: rowData => <Grid container >
                {moment(rowData.created_date).format("L")}
            </Grid>
        },
        {title: 'Package Name', field: 'name', width: 240,},
        {title: 'Is active', field: 'state', width: 200, sorting: false,
            render: rowData => <Grid container>
                <Checkbox
                    color={"primary"}
                    checked={rowData.active}
                    onChange={(e)=> {
                        updateCustomPackage(e.target.checked, rowData.custom_package_id);
                        let clone = _.cloneDeep(contractedItems);
                        let index = _.findIndex(contractedItems, {custom_package_id: rowData.custom_package_id});
                        clone[index].active = e.target.checked;
                        setContractedItems(clone)
                    }}
                />
            </Grid>
        },
        {title: 'Delete', field: 'state', width: 200, sorting: false, headerStyle: {
                textAlign: 'center'
            },
            render: rowData => <Grid container justify={'center'}>
                <Button variant={'outlined'} color={'primary'}
                onClick={()=> {
                    deleteCustomPackage(rowData.custom_package_id);
                    let clone = _.cloneDeep(contractedItems);
                    let index = _.findIndex(contractedItems, {custom_package_id: rowData.custom_package_id});
                    clone.splice(index,1);
                    setContractedItems(clone)
                }}
                >Delete Package</Button>
            </Grid>
        },

    ];

    function getHospiceOrganizations() {
        setHospiceLoading(true);
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getContractedHospices}${selectedDme.value}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].hospice_id, label: response.data[i].hospice_name})
                }
                // setHospiceData(response.data);
                setHospiceList(object);
                setHospiceLoading(false);

            })
            .catch(err => {
                setHospiceLoading(false);
                console.log('error', err)
            })
    }
    function getContractedHospiceRegions() {
        setRegionLoading(true);
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getContractedHospices}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                dmeId:selectedDme.value,
                hospiceId: selectedHospice.value,
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].hospice_region_id){
                        object.push({value: response.data[i].hospice_region_id, label: response.data[i].name})
                    } else {
                        object.unshift({value:null, label: 'Without Region'})
                    }
                }
                setRegionList(object);
                setRegionLoading(false);
            })
            .catch(err => {
                setRegionLoading(false);
                console.log('error', err)
            })
    }
    function getDmes() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getDmeIds, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].dme_organization_id, label: response.data[i].dme_name})
                }
                setDmeList(object);
            })
            .catch(err => console.log('error', err))
    }

    function getContractedItems(){
        setItemsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getAllCustomPackage}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    dme_id: selectedDme.value,
                    hospice_id: selectedHospice.value,
                    hospice_region_id: selectedRegion.value,
                })
            }
        )
            .then(response => {
                return response.json();
            }).then(response => {
            if (response.data.length > 0) {
                let clonedItems = _.cloneDeep(response.data);
                clonedItems.map(item => {
                    if (!item.max_quantity_per_item) {
                        item.max_quantity_per_item = 10;
                    }
                });
                console.log(clonedItems)
                setContractedItems(clonedItems);
                setItemsLoading(false);
                // setItemsList(clonedItems);
                // setFilteredItemsList(clonedItems)
            } else {
                setItemsLoading(false)
                // setItemsList([]);
                // setFilteredItemsList([])
            }
        })
            .catch(err => console.log('error', err));
    }

    function updateCustomPackage(status, id) {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getCustomPackageById}${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                status:status
            })

        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.status === "success") {
                    enqueueSnackbar("Custom Package was updated successfully.", {variant: "success"});
                    // history.push('/refresh/admin/custompackage/view')
                } else {
                    enqueueSnackbar("Unable to update custom packages", {variant: "warning"});
                }
            })
            .catch(err => {
                console.log('error', err)
            })
    }
    function deleteCustomPackage(id) {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getCustomPackageById}${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.status === "success") {
                    enqueueSnackbar("Custom Package was deleted successfully.", {variant: "success"});
                } else {
                    enqueueSnackbar("Unable to delete custom packages", {variant: "warning"});
                }
            })
            .catch(err => {
                console.log('error', err)
            })
    }
    useEffect(()=> {
        if (
            selectedDme.value &&
            selectedHospice.value &&
            selectedRegion.label
        ){
            getContractedItems()
        }
    },[selectedDme, selectedHospice, selectedRegion]);

    useEffect(()=> {
        getDmes();
    },[]);

    useEffect(()=> {
        if (selectedDme.value){
            getHospiceOrganizations();
        }
    },[selectedDme]);

    useEffect(()=> {
        if (selectedHospice.value){
            getContractedHospiceRegions();
        }
    },[selectedHospice]);

    useEffect(()=>{
        if (selectedItems.length > 0 && name !== ''){
            setValidForm(true);
        } else {
            setValidForm(false);
        }
    },[selectedItems, selectedHospice, selectedDme, name]);
    return (
        <Grid container style={{padding: isSmallScreen ? 16 : 32}}>
            <Grid item xs={12} style={{borderBottom:'1px solid #B2D1D9', margin:0, padding:0}}>
                <Typography style={{textAlign:'left', fontSize:24, fontWeight:'bold', color:'#00223D', }}>
                    View Custom Hospice Packages
                </Typography>
            </Grid>
            <Grid container style={{padding:16,backgroundColor:'white', borderRadius:4, maxWidth:870, marginTop:8}}>
                <Grid container spacing={2} alignContent={'flex-end'} alignItems={'flex-end'}>
                    <Grid item md={3} sm={5} xs={12} style={{textAlign:'left', zIndex:23, minWidth:220}}>
                        <div style={{color:'grey'}}>DME Organization:</div>
                        <Select
                            options={dmeList}
                            placeholder={`Select DME`}
                            onChange={(e)=> {
                                setHospiceList([]);
                                setSelectedHospice([]);
                                setContractedItems([]);
                                setSelectedItems([]);
                                setSelectedDme(e)
                            }}
                            value={selectedDme}
                        />
                    </Grid>
                    <Grid item md={3} sm={5} xs={12} style={{textAlign:'left', zIndex:22, minWidth:220}}>
                        <div style={{color:'grey'}}>Hospice Organization:</div>
                        <Select
                            isLoading={hospiceLoading}
                            options={hospiceList}
                            placeholder={`Select Hospice`}
                            onChange={(e)=> {
                                setRegionList([]);
                                setSelectedRegion([]);
                                setContractedItems([]);
                                setSelectedItems([]);
                                setSelectedHospice(e)
                            }}
                            value={selectedHospice}
                        />
                    </Grid>
                    <Grid item md={3} sm={5} xs={12} style={{textAlign:'left', zIndex:21, minWidth:220}}>
                        <div style={{color:'grey'}}>Hospice Region:</div>
                        <Select
                            isLoading={regionLoading}
                            options={regionList}
                            placeholder={`Select Region`}
                            onChange={(e)=> {
                                setContractedItems([]);
                                setSelectedItems([]);
                                setSelectedRegion(e)
                            }}
                            value={selectedRegion}
                        />
                    </Grid>

                </Grid>
                <Grid className={'general_table'} item style={{overflowX: 'scroll', paddingTop:24}}>
                    <MaterialTable
                        title={'Custom Item Packages'}
                        component={{
                            Container: props => <Paper {...props} elevation={0}/>
                        }}
                        components={{
                            Container: props => <Paper {...props} elevation={0}/>,
                            Pagination: props => <TablePagination {...props} style={{
                                backgroundColor: "rgba(237, 242, 244, 0.73)",
                                display: "flex"
                            }}/>,
                        }}
                        isLoading={itemsLoading}
                        icons={{
                            Filter: () => <tr/>,
                            SortArrow: forwardRef((props, ref) => <ArrowDownward style={{color: "#77A6BC"}} {...props} ref={ref}/>),
                        }}
                        // isLoading={loading}
                        options={{
                            // initialPage:page,
                            rowStyle: {backgroundColor: '#FFF'},
                            emptyRowsWhenPaging: false,
                            toolbar: true,
                            // showTitle: false,
                            // filtering: true,
                            // showEmptyDataSourceMessage: users ? users.length === 0 : true,
                            pageSize: 5,
                            headerStyle: {
                                backgroundColor: '#F2F6F7',
                                color: "#2E3236",
                                height: 20,
                                '&:hover': {color: '#FFF'}
                            },
                        }}
                        columns={columns}
                        data={contractedItems}
                        onOrderChange={(colId, ord) =>{
                            let sortClone;
                            if (colId !== -1) {
                                sortClone = ["","","", "", "","","","", "", "","","","", "", "", ];
                                sortClone[colId] = ord;
                            }
                            else {
                                sortClone = ["","","", "", "","","","", "", "","","","", "", "", ];
                            }
                            // setSort(sortClone);
                        }}
                        onChangePage={(newPage) => {
                            // setPage(newPage);
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
