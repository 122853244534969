import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {endpoints} from "../../../constants/endpoints";
import ExportForm from "../../../common/exportForm/exportForm";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Table} from "../../../common/table/table";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReportsHeader from "../../../common/reportsHeader/reportsHeader";
import {ActiveRentalStyle} from "./ActiveRental.style";
import useMediaQuery from '@material-ui/core/useMediaQuery';

export const ActiveRental = ({inactivePatientsOption = false}) => {
    let mobile = useMediaQuery('(max-width:960px)');
    const isLogged = useSelector(state => state.isLogged);
    const classes = ActiveRentalStyle();
    const token = JSON.parse(localStorage.getItem('key'));
    const [results, setResults] = useState([]);
    const [exportValues, setExportValues] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    const generateReport = (payload) => {
        setIsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        fetch(endpoints.getActiveRental, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                ...payload,
                report_type: "FullActiveUtilization",
                inactivePatientsOption: inactivePatientsOption
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setResults(response.data);
                setIsLoading(false);
            })
            .catch(err => {
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
                setIsLoading(false);
            })
    };

    const clearData = () => {
        setResults([]);
    };
    useEffect(() => {
        if (results !== undefined) {
            let final = [];
            results.map(value => {
                let newArr = [];
                newArr.push(value.hospice_name);
                newArr.push(value.patient_id);
                newArr.push(value.first_name);
                newArr.push(value.last_name);
                newArr.push(value.medical_record);
                newArr.push(value.item_name);
                newArr.push(new Date(value.delivery_date));
                newArr.push(value.team_name);
                newArr.push(value.care_setting);
                newArr.push(value.category);
                newArr.push(value.address1);
                newArr.push(value.address2);
                newArr.push(value.city);
                newArr.push(value.state);
                newArr.push(value.zip);
                newArr.push(value.facility_name);
                newArr.push(value.asset);
                final.push(newArr)
            });
            setExportValues(final);
        }
    }, [results]);
    const headers = [
        {text: "Organization_Name", style: 'tableHeader'},
        {text: "Patient ID", style: 'tableHeader'},
        {text: "Patient First Name", style: 'tableHeader'},
        {text: "Patient Last Name", style: 'tableHeader'},
        {text: "Medical Record Number", style: 'tableHeader'},
        {text: "Item Name", style: 'tableHeader'},
        {text: "Delivery Date", style: 'tableHeader'},
        {text: "Team Name", style: 'tableHeader'},
        {text: "Care Setting", style: 'tableHeader'},
        {text: "Category", style: 'tableHeader'},
        {text: "Address1", style: 'tableHeader'},
        {text: "Address2", style: 'tableHeader'},
        {text: "City", style: 'tableHeader'},
        {text: "State", style: 'tableHeader'},
        {text: "Zip", style: 'tableHeader'},
        {text: "Facility Name", style: 'tableHeader'},
        {text: "Item Asset", style: 'tableHeader'},
    ];
    const excelHeaders = [
        "Organization_Name",
        "Patient_ID",
        "Patient_First_Name",
        "Patient_Last_Name",
        "Medical_Record_Number",
        "Item_Name",
        "Delivery_Date",
        "Team_Name",
        "Care_Setting",
        "Category",
        "Address1",
        "Address2",
        "City",
        "State",
        "Zip",
        "Facility_Name",
        "Item_Asset",
    ];
    return (
        <div className={classes.div}>
            {(isLogged) &&
                <Grid container className={classes.container}>
                    <Grid item xs={12}>
                    <Typography
                        className={classes.maintitle}>{inactivePatientsOption === false ? "Active Utilization Report" : "Inactive Patient Report"}
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ReportsHeader generateReport={generateReport} clearData={clearData}/>
                    </Grid>
                    <Grid xs={12} className={classes.select}>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 20,
                            marginBottom: 12
                        }}>
                            <ExportForm
                                fileName='Active Utilization Report'
                                exportValues={exportValues}
                                headers={headers}
                                excelHeaders={excelHeaders}
                                showPrint={true}
                                landscape={true}
                            />
                        </div>

                    </Grid>
                    <Grid item xs={12}
                          style={{overflow: 'hidden', maxWidth: `calc(100vw - ${mobile ? '32px' : "80px"})`}}>
                        <Table
                            search={true}
                            showSearch={true}
                            filtering={true}
                            columns={[{title: 'Organization', field: 'hospice_name', width: 200},
                                {title: 'Patient ID', field: 'patient_id', width: 200},
                                {title: 'Last Name', field: 'last_name', width: 200},
                                {title: 'First Name', field: 'first_name', width: 200},
                                {title: 'Medical Record Number', field: 'medical_record', width: 200},
                                {title: 'Item Name', field: 'item_name', width: 200},
                                {title: 'Delivery Date', field: 'delivery_date', width: 200},
                                {title: 'Team Name', field: 'team_name', width: 200},
                                {title: 'Care Setting', field: 'care_setting', width: 200},
                                {title: 'Category', field: 'category', width: 200},
                                {title: 'Address1', field: 'address1', width: 200},
                                {title: 'Address2', field: 'address2', width: 200},
                                {title: 'City', field: 'city', width: 200},
                                {title: 'State', field: 'state', width: 200},
                                {title: 'Zip', field: 'zip', width: 200},
                                {title: 'Facility Name', field: 'facility_name', width: 200},
                                {title: 'Item Asset', field: 'asset', width: 200},
                            ]}
                            items={results}
                            backGroundColor={"rgba(237, 242, 244, 0.73)"}
                            textColor={"rgba(46, 50, 54, 1)"}
                        />
                    </Grid>
                    <Backdrop className={classes.backdrop} open={isLoading}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </Grid>
            }
        </div>
    )
};
