export const routes = {
    patient_profile: `/patients/patientprofile/`,
    dme_user_edit: `/dmeuser/edit/`,
    dme_provider_edit: `/dmeprovider/edit/`,
    hospice_organization_edit: `/hospice/edit/`,
    hospice_user_edit: "/hospiceuser/edit/",
    password_change: "/profile/edit",
    scan_image: "/scan/image",
    add_patient: "/patients/new",
    view_patients: "/patients/all",
    login: "/login",
    user_information: "/user/info",
    changePassword: "/profile/edit",
    editContract: "/admin/contract/edit/",
    dashboard: "/dashboard",
    root: "/",
    view_orders: "/orders",
    reports: "/reports",
    inventory: "/inventory",
    admin: "/admin",
    support: "/support",
    engagements: "/engagements",
    requests: "/requests",
    qapi: "/qapi",
    logistics: "/logistics",
};