export const reportTypes = {
    all: ["Yearly", "Quarterly"],
    yearly: "Yearly",
    quarterly: "Quarterly",
}

export const totalSpentTimeValues = {
    previousMonthTotal: "previousMonthTotal",
    currentMonthTotal: "currentMonthTotal",
    YTD: "YTD"
}