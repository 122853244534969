import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import {
    logout,
    setCareSettingValue,
    setCriteriaValue,
    setInactiveValue,
    setInputValue,
    setNurseValue,
    setTeamValue
} from '../../redux/actions';
import {useHistory} from 'react-router-dom';
import './navbar.css';
import jwt from 'jwt-decode';
import {
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Hidden,
    Radio,
    RadioGroup
} from "@material-ui/core";
import {NavMenu} from "./menu/menu";
import {makeStyles} from "@material-ui/core/styles";
import SimpleLogo from "../../assets/logo_caption_dove_outline.svg";
import {routes} from "../../constants/routes/routes";
import {endpoints} from "../../constants/endpoints";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from "@material-ui/core/Button";
import BigLogo from "../../assets/logo_caption@2x.png";
import NewNotificationCenter from "../newNotificationCenter/newNotificationCenter";
import {OrgMenu} from "./orgMenu/orgMenu";
export default function NavBar(props) {
    const classes = useStyles();
    let token = localStorage.getItem('key');
    let username;
    let accountType;
    if (JSON.parse(token) !== null) {
        token = JSON.parse(token);
        let user = jwt(token.accessToken);
        username = user.payload.username;
        accountType = user.payload.type;
    }
    let history = useHistory();
    const dispatch = useDispatch();
    const isLogged = useSelector(state => state.isLogged);
    const [hasToken, setHasToken] = useState(false);
    const [number, setNumber] = useState(0);
    const {impersonateDialogOpen, setImpersonateDialogOpen} = props;
    const {impersonateDmeIds, setImpersonateDmeIds} = props;
    const [selectedImpersonation, setSelectedImpersonation] = useState((token && token !== 'null') ? jwt(token.accessToken).payload.dme_name : {});
    const [impersonationData, setImpersonationData] = useState({});
    const [originalUserDmeData, setOriginalUserDmeData] = useState({});

    const onSignOut = () => {
        dispatch(logout());
        dispatch(setInputValue(''));
        dispatch(setCriteriaValue(['All']));
        dispatch(setNurseValue('All'));
        dispatch(setCareSettingValue('All'));
        dispatch(setTeamValue('All'));
        dispatch(setInactiveValue(false));
        setImpersonationData({});
        setOriginalUserDmeData({});
        setImpersonateDmeIds([]);
        history.push('/login');
        localStorage.setItem('key', JSON.stringify(null));
    };

    const checkUser = async () => {
        if (username) {
            setHasToken(true);
            const promise = await fetch(endpoints.getDMEUserDataById + jwt(token.accessToken).payload.user_id, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            });
            const response = await promise.json();
            if (response.status === 'success') {
                const {data} = response;
                setOriginalUserDmeData(data[0]);
            }

            const impersonationPromise = await fetch(endpoints.getUserImpersonations + jwt(token.accessToken).payload.user_id + '/core', {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            });
            if (impersonationPromise.status !== 204) {
                const impersonationResponse = await impersonationPromise.json();
                const {data} = impersonationResponse;
                let values = [];
                if (data) {
                    for (let index = 0; index < data.length; index++) {
                        values.push({dme_name: data[index].dme_organization_name, dme_organization_id: Number(data[index].dme_organization_id)});
                    }
                }
                setImpersonateDmeIds(values);
            } else {
                setImpersonateDmeIds([]);
            }
        }
        setNumber(number + 1);
    };

    const handleImpersonationOperation = async (isImpersonation) => {
        let payload;
        if (isImpersonation === true) {
            payload = {
                dmeData: impersonationData,
                refreshToken: token.refreshToken,
            }
        }
        if (isImpersonation === false) {
            payload = {
                dmeData: originalUserDmeData,
                refreshToken: token.refreshToken,
            }
        }
        const impersonationPromise = await fetch(endpoints.setDmeImpersonation, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: (JSON.stringify(payload))
        });
        const impersonationResponse = await impersonationPromise.json();
        localStorage.setItem('key', JSON.stringify(impersonationResponse));
        setImpersonateDialogOpen(false);
        history.push(`/refresh${window.location.pathname}`)
    }

    useEffect(() => {
        if (isLogged && !hasToken && accountType === 'DME') {
            checkUser();
        } else if (isLogged && !hasToken && accountType === 'Hospice'){
            setImpersonateDmeIds([]);
        } else if (!isLogged) {
            setHasToken(false);
        }
    }, [number, isLogged]);

    return (
        <header className={'header'}>
            <div className={classes.ct}>
                <div className={classes.logocontainer}>
                    {['/dashboard', "/"].includes(window.location.pathname) &&
                        (<img src={BigLogo} className={classes.bigLogo} />)}
                </div>
                <div style={{width: 70, display: 'flex', justifyContent: 'center', alignContent: 'center'}}>
                    <Hidden mdUp>
                        <img
                            src={SimpleLogo}
                            alt="logo"
                            width={'52'}
                            onClick={() => history.push(routes.root)}
                            className={classes.simpleLogo}
                        />
                    </Hidden>
                </div>
                {isLogged && (
                    <div style={{display: "flex"}}>
                        <Hidden xsDown>
                            <OrgMenu
                                impersonateDmeIds={impersonateDmeIds}
                                setImpersonateDialogOpen={setImpersonateDialogOpen}
                            />
                        </Hidden>
                        <NewNotificationCenter />
                        <div style={{width: 110, display: 'flex', justifyContent: "flex-end"}}>

                            <React.Fragment>
                                <div style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                                    <NavMenu type={'user'} name={username} onSignOut={onSignOut} setImpersonateDialogOpen={setImpersonateDialogOpen} impersonateDmeIds={impersonateDmeIds}/></div>
                            </React.Fragment>
                        </div>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Hidden lgUp>
                                <MenuIcon onClick={() => props.setDrawerState(true)} style={{cursor: "pointer"}}/>
                            </Hidden>
                        </div>
                    </div>
                )}
            </div>
            <Dialog open={impersonateDialogOpen}>
                <DialogContent>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Impersonate Dme</FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={selectedImpersonation} onChange={(event) => {
                            setSelectedImpersonation(event.target.value)
                            setImpersonationData(impersonateDmeIds.filter(obj => {
                                return obj.dme_name === event.target.value
                            })[0]);
                        }}>
                            {impersonateDmeIds.map((item, index) => {
                                return <FormControlLabel key={index} value={item.dme_name} control={<Radio />} label={item.dme_name} />
                            })}
                        </RadioGroup>
                    </FormControl>
                    <DialogActions>
                        {isLogged && hasToken && Number(originalUserDmeData.dme_organization_id) !== Number(jwt(token.accessToken).payload.dme_organization_id) && <Button variant={"outlined"} color={"primary"} onClick={() => handleImpersonationOperation(false)}>Revert
                            To Original</Button>}
                        <Button variant={"outlined"} color={"secondary"} onClick={() => setImpersonateDialogOpen(false)}>Cancel</Button>
                        <Button variant={"outlined"} color={"primary"} onClick={() => handleImpersonationOperation(true)}>Impersonate</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </header>
    )
}

const useStyles = makeStyles((theme) => ({
    button: {
        color: 'black',
        "&:hover": {
            backgroundColor: '#f5f5f5'
        },
        height: 28,
        padding: '2px 4px',
        display: "flex",
        cursor: 'pointer',
        justifyContent: "center",
        alignItems: "center"
    },
    search: {
        [theme.breakpoints.down('md')]: {
            width: 50,
            paddingRight: 80
        },
        [theme.breakpoints.up('lg')]: {
            width: 50,
            paddingRight: 80
        },
    },
    header: {
        position: 'fixed'
    },
    bigLogo: {
        position: 'relative',
        left: '6%',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    simpleLogo: {
        cursor: 'pointer',
    },
    ct: {
        display: "flex",
        height: 65,
        padding: 8,
        width: '100vw',
        paddingBottom: 4,
        paddingTop: 4,
        justifyContent: "space-between",
        borderBottom: '1px solid #E5E8F1',
        backgroundColor: '#FFF',
    },
    logocontainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: 0,
            display: 'none',
        }
    }

}));
