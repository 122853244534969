import React from "react";
import {BRStyles} from "../BusinessReview";
import {dollarCurrencyFormatter} from "../../../../common/utils/utils";

export const BRDisplayYearTable = ({values}) => {
    const classes = BRStyles();
    return (
        <table style={{textAlign: "left"}}>
            <tr>
                <th className={classes.tdHeaderCell}>Data Point</th>
                <th className={classes.tdHeaderCell}>Quarter 1</th>
                <th className={classes.tdHeaderCell}>Quarter 2</th>
                <th className={classes.tdHeaderCell}>Quarter 3</th>
                <th className={classes.tdHeaderCell}>Quarter 4</th>
                <th className={classes.tdHeaderCell}>Total</th>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>Total Patient Days</td>
                <td className={classes.tdDataCell}>{values.q1.totalPatientDays}</td>
                <td className={classes.tdDataCell}>{values.q2.totalPatientDays}</td>
                <td className={classes.tdDataCell}>{values.q3.totalPatientDays}</td>
                <td className={classes.tdDataCell}>{values.q4.totalPatientDays}</td>
                <td className={classes.tdDataCell}>{values.q1.totalPatientDays + values.q2.totalPatientDays + values.q3.totalPatientDays + values.q4.totalPatientDays}</td>

            </tr>
            <tr>
                <td className={classes.tdDataCell}>CPPD - Patient Days</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q1.cppdPrice)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q2.cppdPrice)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q3.cppdPrice)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q4.cppdPrice)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q1.cppdPrice + values.q2.cppdPrice + values.q3.cppdPrice + values.q4.cppdPrice)}</td>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>Total CPPD - Per Diem</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q1.totalCppdPrice)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q2.totalCppdPrice)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q3.totalCppdPrice)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q4.totalCppdPrice)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter((Number(values.q1.totalCppdPrice) + Number(values.q2.totalCppdPrice) + Number(values.q3.totalCppdPrice) + Number(values.q4.totalCppdPrice))/4)}</td>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>Total DME Spend</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q1.totalDmeSpend)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q2.totalDmeSpend)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q3.totalDmeSpend)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q4.totalDmeSpend)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(Number(values.q1.totalDmeSpend) + Number(values.q2.totalDmeSpend) + Number(values.q3.totalDmeSpend) + Number(values.q4.totalDmeSpend))}</td>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>Outlier Total</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q1.outlierTotal)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q2.outlierTotal)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q3.outlierTotal)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q4.outlierTotal)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(Number(values.q1.outlierTotal) + Number(values.q2.outlierTotal) + Number(values.q3.outlierTotal) + Number(values.q4.outlierTotal))}</td>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>Outlier % of Spend</td>
                <td className={classes.tdDataCell}>{values.q1.outlierSpend}%</td>
                <td className={classes.tdDataCell}>{values.q2.outlierSpend}%</td>
                <td className={classes.tdDataCell}>{values.q3.outlierSpend}%</td>
                <td className={classes.tdDataCell}>{values.q4.outlierSpend}%</td>
                <td className={classes.tdDataCell}>{((values.q1.outlierSpend + values.q2.outlierSpend + values.q3.outlierSpend + values.q4.outlierSpend)/4).toFixed(2)}%</td>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>Cost Per Patient Day</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q1.costPerPatientDay)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q2.costPerPatientDay)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q3.costPerPatientDay)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.q4.costPerPatientDay)}</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter((values.q1.costPerPatientDay + values.q2.costPerPatientDay + values.q3.costPerPatientDay + values.q4.costPerPatientDay)/4)}</td>
            </tr>
        </table>
    )
}