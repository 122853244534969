import React from 'react';
import {useStyles} from "./orgMenu.style";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import Grid from "@material-ui/core/Grid";
import jwt from "jwt-decode";
import {Grow} from "@material-ui/core";

export const OrgMenu = props => {
    const token = JSON.parse(localStorage.getItem('key'));
    const classes = useStyles();
    const handleClick = (event) => {
        props.setImpersonateDialogOpen(true)
    };

    return (
        <Grid container justify={'center'} alignContent={'center'} alignItems={'center'}>
            {props.impersonateDmeIds.length > 0 && (
                <Grow in={true}>
                    <div className={classes.button}
                         onClick={handleClick}
                         id='userMenu'
                         style={{minWidth:200}}
                    >
                        <div style={{display:'flex', alignContent:'center', alignItems:'center'}}>
                            <LocationCityIcon color='primary'/>
                            <strong>{(token && token !== 'null') && true && jwt(token.accessToken).payload.dme_name} </strong>
                        </div>
                    </div>
                </Grow>

            )}

        </Grid>
    );
};




