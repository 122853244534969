export const labels = {
    medical_record_number:'Medical Record',
    patient_information: "Patient Information",
    address_information: "Address Information",
    patient_name: "Patient Name",
    medical_record: "Medical Record",
    status: "Status",
    hospice: "Hospice",
    hospiceOrganization: "Hospice / Organization",
    dme: "DME",
    nurse: "Nurse",
    address1: "Address 1",
    address2: "Address 2",
    care_setting: "Care Setting",
    city: "City",
    state: "State",
    zip: "Zip",
    primary_caregiver: "Primary Caregiver",
    secondary_caregiver: "Secondary Caregiver",
    third_caregiver: "Third Caregiver",
    demographics: "Demographics",
    name: "Name",
    relation: "Relation",
    phone: "Phone Number",
    dispatchPhone: "Dispatch Phone Number",
    date_of_birth: "Date Of Birth",
    sex: "Sex",
    gender: "Gender",
    height: "Height",
    patient_weight: "Weight",
    mi: "Middle Name",
    fiscal_year: "Fiscal Year (MM/DD)",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    emailOrPhone: "Email or phone number",
    user_email: "E-mail Address",
    hospice_status: "Hospice Status",
    add_facilities: "Add Facilities",
    facility: "Facility",
    region: "Region",
    team: "Team",
    region_billing_contact: "Region Billing Contact",
    region_address: "Region Address",
    medical_supplies_vendor_link: "Medical Supplies Vendor Link",
    pbm_link: "PBM Link",
    organization_name: "Organization Name",
    req_organization_name: "Organization Name *",
    requiredPhone: "Phone Number *",
    secondaryPhone: "Secondary Phone Number",
    regionPhoneNumber: "Region Phone Number",
    patient_height_in_feet: 'Height (Feet)',
    patient_height_in_inches: 'Height (Inches)',
    pickup_date: "Pickup Date",
    username: "Username",
    pickup_order: "Pickup Order:",
    ordered_by: "Ordered by:",
    requiredPrimaryCaregiverPhone: "Phone Number *",
    primaryCaregiverPhone: "Phone Number",
    requiredPrimaryCaregiverFirstName: "First Name",
    requiredPrimaryCaregiverLastName: "Last Name",
    primaryCaregiverFirstName: "Full Name",
    secondaryCaregiverFirstName: "First Name",
    secondaryCaregiverLastName: "Last Name",
    secondaryCaregiverPhone: "Phone Number",
    requiredRelationToPatient: "Relation to Patient *",
    relationToPatient: "Relation to Patient",
    otherDisease: "Other Precautions",
    other: "Other",
    respiratory: "Respiratory",
    covid19: "COVID_19",
    droplet: "Droplet",
    contact: "Contact",
    prescription: "Prescription",
    in_formulary: "In Formulary",
    out_of_formulary: "Out of Formulary",
    zero_price: "$0.00",
    oxygen_select: "Select Air PSI Range:",
    liters_per_minute_select: "Select Liters Per Minute Range:",
    continuous_oxygen_therapy: "Select Oxygen Therapy",
    delivery_method:'Select Delivery Method:',
    humidifier_bottle: "Humidifier bottle %",
    patientWeight: "Patient weight:",
    lift_size: "Select Size:",
    increase_quantity: "Increase quantity",
    max_quantity_reached: "Max. quantity reached",
    showInactivePatients: "Show Inactive Patients",
    possibleDuplicates: "Possible Duplicates",
    subjectToTaxes: "Subject to Taxes ?",
    regionBillingContact: "Billing Contact for Region",
    regionState: "Region State",
    regionCity: "Region City",
    organization: "Organization",
    issueDescription: "Brief Description of Issue",
    thirdCaregiverFirstName: "First Name",
    thirdCaregiverLastName: "Last Name",
    thirdCaregiverPhoneNumber: "Phone Number",
    archivedStatus: "Archived Status",
    weight: "Weight",
    phoneWAreaCode: "Phone w/Area Code",
    organization_address: "Organization Address",
    month: "Month",
    year: "Year",
    parientDays: "Patient Days",
    purchase_item: "Purchase Item",
    limited_formulary: "Limited Formulary",
    id: "ID",
    package: "Package",
    priority: "Priority",
    dmeProvider: "DME Provider",
    hospiceProvider: "Hospice Provider",
    organizationType: "Organization Type",
    createAccountBy:"Create account based on:",
    selectOrganization:'Select Organization',
    selectRole:'Select Role',
    selectUser:'Select User',
    paceOOF: "Daily Fee",
    paceMonthly: "Monthly Fee",
    paceFormularyItem: "Formulary Item",
    serviceNote: "Service Note* :",
    patientMR: "Patient MR#",
    patientProfileLink: "Patient Profile Link",
    incidentLogNote: "Add Incident Log Note",
};
