import React, {useEffect, useState} from 'react'
import Grid from "@material-ui/core/Grid";
import {useSelector} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import {componentTitles, sectionTitles} from "../../../constants/names/titles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import {tooltips} from "../../../constants/inputs/tooltips";
import CancelActionAlert from "../../../common/alerts/cancelActionAlert/cancelActionAlert";
import {alertLevel, cancellingAlertFlags} from "../../../constants/alert/alertFlags";
import {useHistory} from "react-router-dom";
import {DmeProviderStyle} from "./DmeProviderIndex.style";
import {userNavigation} from "../../../constants/types/actionTypes";
import {GeneralInformation} from "./components/GeneralInformation";
import {inputType} from "../../../constants/types/inputType";
import {endpoints} from "../../../constants/endpoints";
import {getOrgData, getRegionData, getRegions, processDME, processItContact, saveRegion} from "./dmeProviderHelper";
import _ from "lodash";
import {useSnackbar} from "notistack";
import {DmeProviderContacts} from "./components/DmeProviderContacts";
import {ProviderRegions} from "./components/ProviderRegions";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {ConfirmPerformChanges} from "../../../common/alerts/confirmPerformChanges/ConfirmPerformChanges";

export const CreateDmeProviderIndex = ({edit = false}) => {
    const isLoggedIn = useSelector(state => state.isLogged);
    const [regionList, setRegionList] = useState([]);
    const history = useHistory();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(edit === true);
    const [updatingField, setUpdatingField] = useState(false);
    const [successSave, setSuccessSave] = useState(false);
    const [openProviderStatusDialog, setOpenProviderStatusDialog] = useState(false);
    const [steps, setSteps] = useState([sectionTitles.generalInformation, sectionTitles.regions, sectionTitles.contacts]);
    const token = JSON.parse(localStorage.getItem('key'));
    const [dme, setDme] = useState({
        status: true,
        dme_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        second_phone: "",
        oxygen_address1: '',
        oxygen_address2: '',
        oxygen_city: '',
        oxygen_state: '',
        oxygen_zip: '',
        oxygen_phone: '',
        dme_retail: '',
        dme_wholesale: '',
        supplier_name: '',
    });
    const [contacts, setContacts] = useState({
        it_contact: [{
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
        }]
    });
    const [selectedRegionIndex, setSelectedRegionIndex] = useState(0);
    const [regions, setRegions] = useState([]);
    const [selectedRegionData, setSelectedRegionData] = useState({
        name: "",
        region_phone: "",
        teams: [],
        warehouses: [],
        onCallStart: "",
        onCallEnd: "",
        onCallPhone: "",
        address_region: [{
            address1: "",
            address2: '',
            city: "",
            state: "",
            zip: ""
        }],
        billing_contact_region: [{
            first_name: "",
            last_name: "",
            phone: "",
            email: ""
        }]
    });

    const [newRegionTeam, setNewRegionTeam] = useState("");
    const [newRegionWarehouse, setNewRegionWarehouse] = useState("");
    const [validFields, setValidFields] = useState({
        dme_name: true, address1: true, address2: true, city: true,
        state: true, zip: true, phone: true,
    });
    const [missingFields, setMissingFields] = useState({
        dme_name: false, address1: false, address2: false, city: false,
        state: false, zip: false, phone: false,
    });
    const [dmeId, setDmeId] = useState("");
    const [isModified, setIsModified] = useState(false);

    const [firstStep, setFirstStep] = useState(false);
    const [secondStep, setSecondStep] = useState();

    const renderCancelButton = () => {
        if (isModified && activeStep < steps.length - 1) {
            return (
                <Grid item>
                    <CancelActionAlert
                        flag={cancellingAlertFlags.newPatient}
                        CSS={classes.button}
                        alertLevel={alertLevel.warn}
                        target={"/admin"}
                    />
                </Grid>
            )
        }
        if (!isModified && activeStep < steps.length - 1) {
            return (
                <Grid item>
                    <Button
                        variant={'outlined'}
                        color="primary"
                        onClick={() => {
                            history.push('/admin')
                        }} className={classes.button}>
                        Cancel
                    </Button>
                </Grid>
            )
        } else {
            return null;
        }
    };
    const handleStatus = async () => {
        setLoading(true);
        const changeStatusCall = await fetch(endpoints.changeDmeOrganizationStatus,{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({dmeId: dmeId, status: dme.status})
        });
        setOpenProviderStatusDialog(prevState => !prevState);
        setLoading(false);
        if(changeStatusCall.status === 200){
            history.push('/admin/dme/all');
        }

    };
    const getZipRelatedData = async (payload, setterType, position) => {
        if (setterType === "dme") {
            if (payload.length === 5 && dme.city?.length < 1) {
                setDme({...dme, zip: payload});
                const target = endpoints.zipCode + payload;
                const token = JSON.parse(localStorage.getItem('key'));
                const getZipPromise = await fetch(target, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                });
                const getZipResponse = await getZipPromise.json();

                setDme({...dme, city: getZipResponse.city, state: getZipResponse.state, zip: payload});
                setMissingFields({...missingFields, city: false, state: false, zip: false})

            } else if (payload.length < 6) {
                setDme({...dme, zip: payload})
                setMissingFields({...missingFields, zip: payload.length === 0})
            }
        }
        if (setterType === "regionAddress") {
            if (payload.length === 5 && selectedRegionData.address_region[position].city.length < 1 && selectedRegionData.address_region[position].state.length < 1) {
                const target = endpoints.zipCode + payload;
                const token = JSON.parse(localStorage.getItem('key'));
                const getZipPromise = await fetch(target, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                });
                const getZipResponse = await getZipPromise.json();
                let clone = _.cloneDeep(selectedRegionData);
                clone.address_region[position].city = getZipResponse.city;
                clone.address_region[position].state = getZipResponse.state;
                clone.address_region[position].zip = payload;
                setSelectedRegionData(clone);
            }
        }
        if (setterType === "oxygenAddress") {
            if (payload.length === 5 && (dme.oxygen_city === null || dme.oxygen_city.length < 1) && (dme.oxygen_state === null || dme.oxygen_state.length < 1)) {
                const target = endpoints.zipCode + payload;
                const token = JSON.parse(localStorage.getItem('key'));
                const getZipPromise = await fetch(target, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                });
                const getZipResponse = await getZipPromise.json();
                let clone = _.cloneDeep(dme);
                clone.oxygen_city = getZipResponse.city;
                clone.oxygen_state = getZipResponse.state;
                clone.oxygen_zip = payload;
                setDme(clone)
            } else if (payload.length < 6) {
                setDme({...dme, oxygen_zip: payload})
            }
        }
    };
    const handleInput = async (e) => {
        let clone;
        const {name} = e.target;
        switch (e.inputEventType) {
            case inputType.required:
                switch (name) {
                    case "dme_name":
                    case "address1":
                    case "city":
                    case "state":
                    case "phone":
                    case "supplier_name":
                    case "oxygen_address1":
                    case "oxygen_address2":
                    case "oxygen_city":
                    case "oxygen_state":
                    case "oxygen_phone":
                    case "dme_retail":
                    case "dme_wholesale":
                        setDme({...dme, [name]: e.target.value});
                        setMissingFields({...missingFields, [name]: e.target.length === 0});
                        setIsModified(true);
                        break;
                    case "zip":
                        getZipRelatedData(e.target.value, "dme");
                        setIsModified(true);
                        break;
                    case "oxygen_zip":
                        getZipRelatedData(e.target.value, "oxygenAddress");
                        setIsModified(true);
                        break;
                    // default:
                    //     console.log("Case NOT treated: [", name, "]")
                    //     break;
                }
                break;

            case inputType.standard:
                if (activeStep === 0 || (activeStep === 1 && selectedRegionIndex === regions.length)) {
                    setIsModified(true);
                }
                switch (name) {
                    case "address2":
                    case "second_phone":
                        setDme({...dme, [name]: e.target.value});
                        setIsModified(true);
                        break;
                    case "providerStatus":
                        const values = {...dme};
                        values.status = !dme.status;
                        setDme(values);
                        setOpenProviderStatusDialog(prevState => !prevState);
                        break;
                    case "it_contact_first_name":
                        clone = _.cloneDeep(contacts);
                        clone.it_contact[e.position].first_name = e.target.value;
                        setContacts(clone);
                        break;
                    case "it_contact_last_name":
                        clone = _.cloneDeep(contacts);
                        clone.it_contact[e.position].last_name = e.target.value;
                        setContacts(clone);
                        break;
                    case "it_contact_email":
                        clone = _.cloneDeep(contacts);
                        clone.it_contact[e.position].email = e.target.value;
                        setContacts(clone);
                        break;
                    case "it_contact_phone_number":
                        clone = _.cloneDeep(contacts);
                        clone.it_contact[e.position].phone = e.target.value;
                        setContacts(clone);
                        break;
                    case "tableMovement":
                        setLoading(true);
                        if (e.target.value < regions.length) {
                            setSelectedRegionIndex(regions[e.target.value].region_id);
                            await getRegionData(setSelectedRegionData, regions[e.target.value].region_id, dmeId);
                            setLoading(false);
                        } else {
                            setSelectedRegionData({
                                name: "",
                                region_phone: "",
                                teams: [],
                                onCallStart: "",
                                onCallEnd: "",
                                onCallPhone: "",
                                warehouses: [],
                                address_region: [{
                                    address1: "",
                                    address2: '',
                                    city: "",
                                    state: "",
                                    zip: ""
                                }],
                                billing_contact_region: [{
                                    first_name: "",
                                    last_name: "",
                                    phone: "",
                                    email: ""
                                }]
                            });
                            setLoading(false);
                            setSelectedRegionIndex(regions.length);
                        }
                        break;
                    case "regionName":
                        if (e.type === "change") {
                            clone = _.cloneDeep(selectedRegionData);
                            clone.region_id = e.value;
                            clone.name = e.label;
                            setSelectedRegionData(clone);
                        }
                        break;
                    case "regionPhoneNumber":
                        if (e.type === "change") {
                            clone = _.cloneDeep(selectedRegionData);
                            clone.region_phone = e.target.value;
                            setSelectedRegionData(clone);
                        }
                        break;
                    case "team":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.teams[e.position] = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "warehouse":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.warehouses[e.position] = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "newTeam":
                        setNewRegionTeam(e.target.value);
                        break;
                    case "newWarehouse":
                        setNewRegionWarehouse(e.target.value);
                        break;
                    case "region_address1":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.address_region[e.position].address1 = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "region_address2":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.address_region[e.position].address2 = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "region_city":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.address_region[e.position].city = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "region_state":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.address_region[e.position].state = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "region_zip":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.address_region[e.position].zip = e.target.value;
                        setSelectedRegionData(clone);
                        getZipRelatedData(e.target.value, "regionAddress", e.position);
                        break;
                    case "billingFirstName":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.billing_contact_region[e.position].first_name = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "billingLastName":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.billing_contact_region[e.position].last_name = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "billingEmail":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.billing_contact_region[e.position].email = e.target.value;
                        setSelectedRegionData(clone);
                        break;
                    case "billingRegionPhone":
                        if (e.type === "change") {
                            clone = _.cloneDeep(selectedRegionData);
                            clone.billing_contact_region[e.position].phone = e.target.value;
                            setSelectedRegionData(clone);
                        }
                        break;
                    case "addNewTeam":
                        if (!selectedRegionData.teams.includes(newRegionTeam)) {
                            clone = _.cloneDeep(selectedRegionData);
                            clone.teams = [...clone.teams, newRegionTeam];
                            setSelectedRegionData(clone);
                            setNewRegionTeam("");
                        } else {
                            enqueueSnackbar("Team already exists.", {variant: "info"});
                        }
                        break;
                    case "addNewWarehouse":
                        if (!selectedRegionData.warehouses.includes(newRegionWarehouse)) {
                            clone = _.cloneDeep(selectedRegionData);
                            clone.warehouses = [...clone.warehouses, newRegionWarehouse];
                            setSelectedRegionData(clone);
                            setNewRegionWarehouse("")
                        } else {
                            enqueueSnackbar("Warehouse already exists.", {variant: "info"});
                        }
                        break;
                    case "clearNewTeam":
                        setNewRegionTeam("");
                        break;
                    case "clearNewWarehouse":
                        setNewRegionWarehouse("");
                        break;
                    case "deleteTeam":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.teams.splice(e.position, 1);
                        setSelectedRegionData(clone);
                        break;
                    case "deleteWarehouse":
                        clone = _.cloneDeep(selectedRegionData);
                        clone.warehouses.splice(e.position, 1);
                        setSelectedRegionData(clone);
                        break;
                    case "onCallStart":
                    case "onCallEnd":
                    case "onCallPhone":
                        if (e.target.value) {
                            setSelectedRegionData({...selectedRegionData, [e.target.name]: e.target.value});
                        }
                        break;
                    // default:
                    //     console.log("Case NOT treated: [", name, "]");
                    //     break;
                }
                break;
            case inputType.editOnBlur:
                if (e.type === "change") {
                    switch (name) {
                        case "team":
                            clone = _.cloneDeep(selectedRegionData);
                            clone.teams[e.position].team_name = e.target.value;
                            setSelectedRegionData(clone);
                            break;
                        case "warehouse":
                            clone = _.cloneDeep(selectedRegionData);
                            clone.warehouses[e.position].name = e.target.value;
                            setSelectedRegionData(clone);
                            break;
                        case "region_zip":
                            await getZipRelatedData(e.target.value, "regionAddress", e.position);
                            break;
                        case "billingFirstName":
                            clone = _.cloneDeep(selectedRegionData);
                            clone.billing_contact_region[e.position].first_name = e.target.value;
                            setSelectedRegionData(clone);
                            break;
                        case "billingLastName":
                            clone = _.cloneDeep(selectedRegionData);
                            clone.billing_contact_region[e.position].last_name = e.target.value;
                            setSelectedRegionData(clone);
                            break
                        case "billingRegionPhone":
                            clone = _.cloneDeep(selectedRegionData);
                            clone.billing_contact_region[e.position].phone = e.target.value;
                            setSelectedRegionData(clone);
                            break
                        case "billingEmail":
                            clone = _.cloneDeep(selectedRegionData);
                            clone.billing_contact_region[e.position].email = e.target.value;
                            setSelectedRegionData(clone);
                            break
                        case "onCallStart":
                        case "onCallEnd":
                            const editOnCallHours = async () => {
                                setUpdatingField(true);
                                let promise = await fetch(endpoints.updateOnCallInformation, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        dmeId: dmeId,
                                        [e.target.name]: e.target.value,
                                        regionId: selectedRegionData.region_id
                                    })
                                })
                                let response = await promise.json();
                                if (response.status === "success") {
                                    enqueueSnackbar("Update region on call hours successfully", {variant: "success"});
                                    setSelectedRegionData({...selectedRegionData, [e.target.name]: e.target.value});
                                } else {
                                    enqueueSnackbar("Could not update region on call hours", {variant: "warning"});
                                }
                                setUpdatingField(false);
                            }
                            editOnCallHours();
                            break;
                        case "onCallPhone":
                            setSelectedRegionData({...selectedRegionData, [e.target.name]: e.target.value});
                            break;
                        // default:
                        //     console.log("Case NOT treated: [", name, "]");
                        //     break;
                    }
                }
                if (e.type === "blur") { // TODO call require endpoint => selected region index and dmeId
                    switch (name) {
                        case "team":
                            const editTeam = async () => {
                                setUpdatingField(true);
                                let promise = await fetch(endpoints.addOrUpdateRegionTeam + dmeId, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        teamName: selectedRegionData.teams[e.position].team_name,
                                        teamId: selectedRegionData.teams[e.position].team_id,
                                        regionId: selectedRegionData.region_id,
                                    })
                                })
                                let response = await promise.json()
                                if (response.status === "success") {
                                    enqueueSnackbar("Updated region team successfully", {variant: "success"});
                                } else {
                                    enqueueSnackbar("Could not update team", {variant: "warning"});
                                }
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, dmeId);
                                setUpdatingField(false);
                            }
                            if (_.findIndex(selectedRegionData.teams, {"team_name": e.target.value}) === -1) {
                                editTeam();
                            } else {
                                setUpdatingField(true);
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, dmeId);
                                setUpdatingField(false);
                                enqueueSnackbar(`Team already exists`, {variant: "info"})
                            }
                            break;
                        case "warehouse":
                            const editWarehouse = async () => {
                                setUpdatingField(true);
                                let promise = await fetch(endpoints.addOrUpdateRegionWarehouse + dmeId, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        warehouseName: selectedRegionData.warehouses[e.position].name,
                                        warehouseId: selectedRegionData.warehouses[e.position].warehouse_id,
                                        regionId: selectedRegionData.region_id,
                                    })
                                })
                                let response = await promise.json()
                                if (response.status === "success") {
                                    enqueueSnackbar("Updated region warehouse successfully", {variant: "success"});
                                } else {
                                    enqueueSnackbar("Could not update warehouse", {variant: "warning"});
                                }
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, dmeId);
                                setUpdatingField(false);
                            }
                            if (_.findIndex(selectedRegionData.warehouses, {"name": e.target.value}) === -1) {
                                editWarehouse();
                            } else {
                                setUpdatingField(true);
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, dmeId);
                                setUpdatingField(false);
                                enqueueSnackbar(`Warehouse already exists`, {variant: "info"})
                            }
                            break;
                        case "it_contact_first_name":
                        case "it_contact_last_name":
                        case "it_contact_email":
                        case "it_contact_phone_number":
                            const editItContact = async () => {
                                setUpdatingField(true);
                                await processItContact(contacts, dmeId, setContacts, enqueueSnackbar);
                                await getOrgData(setDme, dmeId, setContacts, contacts, setLoading);
                                setUpdatingField(false);
                            }
                            editItContact();
                            break
                        case "regionPhoneNumber":
                            const editRegionPhone = async () => {
                                setUpdatingField(true);
                                let promise = await fetch(endpoints.updateDMEOrganizationRegionPhone, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        dmeId: dmeId,
                                        phone: selectedRegionData.region_phone,
                                        regionId: selectedRegionData.region_id
                                    })
                                })
                                let response = await promise.json();
                                if (response.status === "success") {
                                    enqueueSnackbar("Update region phone successfully", {variant: "success"});
                                } else {
                                    enqueueSnackbar("Could not update region", {variant: "warning"});
                                }
                                setUpdatingField(false);
                            }
                            editRegionPhone();
                            break;
                        case "region_address1":
                        case "region_address2":
                        case "region_city":
                        case "region_state":
                        case "region_zip":
                            const editRegionAddress = async () => {
                                setUpdatingField(true);
                                let promise = await fetch(endpoints.addOrUpdateAddressRegion, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        dmeId: dmeId,
                                        region: selectedRegionData,
                                        regionId: selectedRegionData.region_id
                                    })
                                })
                                let response = await promise.json();
                                if (response.status === "success") {
                                    enqueueSnackbar("Update region address successfully", {variant: "success"});
                                } else {
                                    enqueueSnackbar("Could not update region address", {variant: "warning"});
                                }
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, dmeId);
                                setUpdatingField(false);
                            }
                            editRegionAddress();
                            break;
                        case "billingFirstName":
                        case "billingLastName":
                        case "billingRegionPhone":
                        case "billingEmail":
                            const editBillingContact = async () => {
                                setUpdatingField(true);
                                let promise = await fetch(endpoints.addOrUpdateBillingContactRegion, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        dmeId: dmeId,
                                        region: selectedRegionData,
                                        regionId: selectedRegionData.region_id
                                    })
                                })
                                let response = await promise.json();
                                if (response.status === "success") {
                                    enqueueSnackbar("Update region billing contact successfully", {variant: "success"});
                                } else {
                                    enqueueSnackbar("Could not update billing contact", {variant: "warning"});
                                }
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, dmeId);
                                setUpdatingField(false);
                            }
                            editBillingContact();
                            break;
                        case "onCallPhone":
                            const editOnCallPhone = async () => {
                                setUpdatingField(true);
                                let promise = await fetch(endpoints.updateOnCallInformation, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        dmeId: dmeId,
                                        [e.target.name]: selectedRegionData.onCallPhone,
                                        regionId: selectedRegionData.region_id
                                    })
                                })
                                let response = await promise.json();
                                if (response.status === "success") {
                                    enqueueSnackbar("Update region on call phone successfully", {variant: "success"});
                                } else {
                                    enqueueSnackbar("Could not update region on call phone", {variant: "warning"});
                                }
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, dmeId);
                                setUpdatingField(false);
                            }
                            editOnCallPhone();
                            break;
                        default:
                            break;
                    }
                }
                break;

            case inputType.buttonAction:
                switch (name) {
                    case "addNewTeam":
                        const addRegionTeam = async () => {
                            setUpdatingField(true);
                            let promise = await fetch(endpoints.addOrUpdateRegionTeam + dmeId, {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + token.accessToken
                                },
                                body: JSON.stringify({teamName: newRegionTeam, regionId: selectedRegionData.region_id})
                            })
                            let response = await promise.json();
                            if (response.status === "success") {
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, dmeId);
                                enqueueSnackbar("Added team successfully.", {variant: "success"});
                                setNewRegionTeam("");
                            }
                            setUpdatingField(false);
                        }
                        if (_.findIndex(selectedRegionData.teams, {"team_name": newRegionTeam}) === -1)
                            addRegionTeam();
                        else {
                            enqueueSnackbar("Team already exists", {variant: "info"});
                        }
                        break;
                    case "deleteTeam":
                        const removeRegionTeam = async () => {
                            setUpdatingField(true);
                            let promise = await fetch(endpoints.deleteRegionTeam, {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + token.accessToken
                                },
                                body: JSON.stringify({
                                    teamId: selectedRegionData.teams[e.position].team_id,
                                    regionId: selectedRegionData.region_id
                                })
                            })
                            let response = await promise.json();
                            if (response.status === "success") {
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, dmeId);
                                enqueueSnackbar("Removed team successfully.", {variant: "success"});
                                setNewRegionTeam("");
                            } else {
                                enqueueSnackbar("Could not remove team", {variant: "warning"});
                            }
                            setUpdatingField(false);
                        }
                        removeRegionTeam()
                        break;
                    case "addNewWarehouse":
                        const addRegionWarehouse = async () => {
                            setUpdatingField(true);
                            let check = await fetch(endpoints.verifyWarehouse, {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + token.accessToken
                                },
                                body: JSON.stringify({warehouseName: newRegionWarehouse, dmeId: dmeId})
                            })
                            let checkResponse = await check.json();
                            if (checkResponse.status === "success" && checkResponse.data === false) {
                                let promise = await fetch(endpoints.addOrUpdateRegionWarehouse + dmeId, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + token.accessToken
                                    },
                                    body: JSON.stringify({
                                        warehouseName: newRegionWarehouse,
                                        regionId: selectedRegionData.region_id
                                    })
                                })
                                let response = await promise.json();
                                if (response.status === "success") {
                                    await getRegionData(setSelectedRegionData, selectedRegionData.region_id, dmeId);
                                    enqueueSnackbar("Added warehouse successfully.", {variant: "success"});
                                    setNewRegionWarehouse("");
                                } else {
                                    enqueueSnackbar("Could not add warehouse", {variant: "warning"});
                                }
                            }
                            setUpdatingField(false);
                        }
                        if (_.findIndex(selectedRegionData.warehouses, {"name": newRegionWarehouse}) === -1) {
                            addRegionWarehouse()
                        } else {
                            enqueueSnackbar("Warehouse already exists", {variant: "info"});
                        }
                        break;
                    case "deleteWarehouse":
                        const removeRegionWarehouse = async () => {
                            setUpdatingField(true);
                            let promise = await fetch(endpoints.deleteRegionWarehouse, {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + token.accessToken
                                },
                                body: JSON.stringify({
                                    warehouseId: selectedRegionData.warehouses[e.position].warehouse_id,
                                    regionId: selectedRegionData.region_id
                                })
                            })
                            let response = await promise.json();
                            if (response.status === "success") {
                                await getRegionData(setSelectedRegionData, selectedRegionData.region_id, dmeId);
                                enqueueSnackbar("Removed warehouse successfully.", {variant: "success"});
                                setNewRegionTeam("");
                            } else {
                                enqueueSnackbar("Could not remove warehouse", {variant: "warning"});
                            }
                            setUpdatingField(false);
                        }
                        removeRegionWarehouse()
                        break;
                    case "saveRegion":
                        const saveRegionFunction = async () => {
                            setLoading(true);
                            await saveRegion(setSuccessSave, dme, dmeId, selectedRegionData, enqueueSnackbar);
                            await getAllRegionsList();
                            await getRegions(setRegions, dmeId);
                            setLoading(false);
                        };
                        saveRegionFunction();
                        break;
                }
                break;
            // default:
            //     console.log("Case NOT treated: [", name, "]");
            //     break;
        }

    };
    useEffect(() => {
        if (regions.length > 0) {
            getRegionData(setSelectedRegionData, regions[0].region_id, dmeId);
        }
    }, [regions]);

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <GeneralInformation
                    handleInput={handleInput}
                    validType={validFields}
                    missingValues={missingFields}
                    dme={dme}
                    setMissingValues={setMissingFields}
                    dmeId={dmeId}
                    loading={loading}
                />;
            case 1:
                return <ProviderRegions
                    regionData={selectedRegionData}
                    regionIdentifiers={regions}
                    newRegionTeam={newRegionTeam}
                    newRegionWarehouse={newRegionWarehouse}
                    handleInput={handleInput}
                    loading={loading}
                    enableWarehouses={true}
                    updatingField={updatingField}
                    regionList={regionList}
                />;
            case 2:
                return <DmeProviderContacts
                    handleInput={handleInput}
                    contacts={contacts}
                    loading={loading}
                    updatingField={updatingField}
                />;
        }
    };
    const handleStepChange = (movement) => {
        if (movement === userNavigation.next) {
            switch (activeStep) {
                case 0:
                    setMissingFields({
                        dme_name: dme.dme_name.length === 0,
                        address1: dme.address1.length === 0,
                        city: dme.city.length === 0,
                        state: dme.state.length === 0,
                        zip: dme.zip.length === 0,
                        phone: dme.phone.length < 10,
                    });
                    if (dme.dme_name.length > 0 &&
                        dme.address1.length > 0 &&
                        dme.city.length > 0 &&
                        dme.state.length > 0 &&
                        dme.zip.length > 0 &&
                        dme.phone.length === 10) {
                        const create = async () => {
                            setLoading(true);
                            await processDME(setDmeId, dme, "create", dmeId, enqueueSnackbar, setIsModified, history);
                            setLoading(false);
                            setActiveStep(prevState => prevState + 1);
                        };
                        const editDme = async () => {
                            setLoading(true);
                            await processDME(setDmeId, dme, "edit", dmeId, enqueueSnackbar, setIsModified, history);
                            setLoading(false);
                            setActiveStep(prevState => prevState + 1);
                        }
                        if (edit === false) {
                            create();
                        } else if (edit === true) {
                            if (isModified === true) {
                                editDme();
                            } else {
                                setActiveStep(prevState => prevState + 1);
                            }
                        }
                    }
                    break;
                case 1:
                    loading === false && updatingField === false && setActiveStep(prevState => prevState + 1);
                    break;
                case 2:
                    loading === false && updatingField === false && setActiveStep(prevState => prevState + 1);
                    break;
            }
        } else {
            loading === false && updatingField === false && setActiveStep(prevState => prevState - 1);
        }
    }
    useEffect(() => {
        if (activeStep === 1) {
            getRegions(setRegions, dmeId);
        }
    }, [activeStep, dmeId]);

    async function getAllRegionData() {
        if (selectedRegionIndex === 0 && regions.length > 0) {
            await getRegionData(setSelectedRegionData, regions[0].region_id, dmeId);
            setLoading(false)
        } else if (selectedRegionIndex === 0 && edit !== true) {
            setLoading(false)
        }
    }

    useEffect(() => {

        getAllRegionData();
    }, [regions]);


    const getAllRegionsList = async () => {
        const token = JSON.parse(localStorage.getItem('key'));
        const response = await fetch(endpoints.regions, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            }
        });
        const {status, data} = await response.json();
        if (status === "success") {
            setRegionList(data.map(item => {
                return {value: item.region_id, label: item.name}
            }))
        }
    };
    useEffect(() => {
        getAllRegionsList();
    }, []);

    useEffect(() => {
        if (dme.dme_name && dme.address1 && dme.city && dme.state && dme.zip && dme.phone) {
            if (dme.dme_name.length > 0 &&
                dme.address1.length > 0 &&
                dme.city.length > 0 &&
                dme.state.length > 0 &&
                dme.zip.length > 0 &&
                dme.phone.length === 10) {
                setFirstStep(true)
            } else {
                setFirstStep(false)
            }
        }
    }, [dme]);

    useEffect(() => {
        if (edit === true) {
            let urlParameters = window.location.href.split('/');
            setDmeId(urlParameters[urlParameters.length - 1]);
            getOrgData(setDme, urlParameters[urlParameters.length - 1], setContacts, contacts, setLoading)

        }
    }, []);
    return (
        <Grid container>
            {isLoggedIn ?
                <Grid container>
                    <main className={classes.layout}>
                        <Paper className={classes.paper}>
                            <Typography component="h1" variant="h5" align="center" style={{paddingTop: 10}}>
                                {edit === true ? componentTitles.editDME : componentTitles.newDME}
                            </Typography>
                            <Stepper activeStep={activeStep} className={classes.stepper}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <Grid container>
                                {getStepContent(activeStep)}

                                <Grid container spacing={1} className={classes.buttons}>
                                    {
                                        renderCancelButton()
                                    }
                                    {(activeStep !== 0 && activeStep < steps.length) && (
                                        <Grid item>
                                            <Button onClick={() => handleStepChange()}
                                                    className={classes.button}>
                                                Back
                                            </Button>
                                        </Grid>
                                    )}
                                    {activeStep === 0 && (
                                        <Tooltip
                                            title={firstStep === true ? '' : tooltips.pleaseFillInRequired}
                                            arrow placement="bottom-end">
                                            <Grid item>
                                                <Button
                                                    id={'next'}
                                                    variant={firstStep === true ? "contained" : 'outlined'}
                                                    color="primary"
                                                    onClick={() => handleStepChange(userNavigation.next)}
                                                    className={classes.button}
                                                >
                                                    Next
                                                </Button>
                                            </Grid>
                                        </Tooltip>
                                    )}
                                    {activeStep === 1 && (
                                        <Tooltip
                                            title={updatingField === false ? '' : tooltips.pleaseFillInRequired}
                                            arrow placement="bottom-end">
                                            <Grid item>
                                                <Button
                                                    id={'next'}
                                                    variant={updatingField === false ? "contained" : 'outlined'}
                                                    color="primary"
                                                    onClick={() => handleStepChange(userNavigation.next)}
                                                    className={classes.button}
                                                >
                                                    {activeStep !== steps.length - 1 && buttonText.next}
                                                </Button>
                                            </Grid>
                                        </Tooltip>
                                    )}
                                </Grid>
                            </Grid>
                        </Paper>
                    </main>
                </Grid>
                :
                <React.Fragment>
                    <h1>Please Log In</h1>
                </React.Fragment>

            }
            <ConfirmPerformChanges
                open={openProviderStatusDialog}
                handleConfirm ={() => {
                    dmeId !== "" && handleStatus();
                }}
                handleCancel={() => {
                    setOpenProviderStatusDialog(prevState => !prevState)
                    const values = {...dme};
                    values.status = !dme.status;
                    setDme(values);
                }}
            />
        </Grid>
    )
}

const useStyles = makeStyles((theme) => (DmeProviderStyle(theme)));
