import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import jwt from "jwt-decode";
import {endpoints} from "../../../constants/endpoints";
import moment from "moment";
import {getTokenInformation} from "../../../common/utils/utils";
import {useHistory} from "react-router-dom";
import {utilsTokenIdentifiers} from "../../../constants/inputs/values";

export default function UnauthorizedAccess(){
    const [unauthorizedList, setUnauthorizedList] = useState([]);
    const history = useHistory();
    function getUnauthorizedAccessList(){
        fetch(endpoints.getUnauthorizedLogs,{
            method:"GET",
            headers:{
                "Content-Type":"application/json",
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            },
        }).then(res => res.json())
            .then(res => setUnauthorizedList(res.data))

    }
    useEffect(()=>{
       getUnauthorizedAccessList()
    },[])

    return(
        <Grid container style={{padding:16}}>
            <Grid container>
                <Grid item xs={12} style={{fontSize:20, fontWeight:'bold', textAlign:'left', padding:16}}>
                    Following users had tried to access restricted resources and got the Unauthorized screen:
                </Grid>
                {unauthorizedList.map((entry, i)=> {
                    return(
                        <Grid item lg={3} md={4} sm={6} xs={12} style={{padding:8}}>
                            <Grid container style={{textAlign:'left', padding:16, backgroundColor:"white", borderRadius:4, border:'1px solid whitesmoke'}}>
                                <Grid item xs={12}>
                                    Name: <strong>{entry.full_name}</strong>
                                </Grid>
                                <Grid item xs={12}>
                                    Action: <strong>{entry.operation}</strong>
                                </Grid>
                                <Grid item xs={12} style={{color:'grey'}}>
                                    Username:  <strong>{entry.username}</strong> - #{entry.user_id}
                                </Grid>
                                <Grid item xs={12} style={{color:'grey'}}>
                                    Email: <strong>{entry.email}</strong>
                                </Grid>
                                <Grid item xs={12} style={{color:'grey'}}>
                                    Date: <strong>{moment(entry.created_date).utcOffset(new Date(entry.created_date).getTimezoneOffset(), true).format("LLL")}</strong>
                                </Grid>

                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}
