import {withStyles} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

export const InfectiousDiseasesStyles = (theme) => (
        {
        root: {
            display: 'flex',
        },
        mainContainer: {
            padding: 16,
            // display: "flex",
            paddingBottom: 12
        },
        headerTypography: {
            textAlign: "flex-start",
            fontWeight: 600,
            fontSize: 18,
            color: "#1A1818",
        },
        otherDisease: {
            display: "flex",
            paddingLeft: 0,
            paddingRight: "24px",
            alignItems: "center"
        },


    }
)

export const CustomInfectiousDiseasesCheckbox = withStyles({
    root: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        },
        "&:checked": {
            color: "#4F86A0"
        },
        "&:disabled": {
            color: "#4F86A0"
        }
    },
    checked: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        }
    },
    disabled: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        }
    }
})(Checkbox);
