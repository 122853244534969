import makeStyles from "@material-ui/core/styles/makeStyles";

export const exchageStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    backButton: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },

    snackbar: {
        backgroundColor: '#4CAF51'
    },
    mobileButton: {
        color: '#FFFFFF',
        fontWeight:'bold',
        backgroundColor: '#84B4C2',
        fontSize: 16,
        width: 200,
        height: 60,
        paddingTop: 16,
        paddingBottom: 16,
        "&:hover": {
            backgroundColor: '#BBD3DE'
        },
        border: 'none',
    },
    button: {
        fontWeight:'bold',
        color: '#FFFFFF',
        backgroundColor: '#84B4C2',
        fontSize: 12,
        width: 160,
        height: 40,
        paddingTop: 8,
        paddingBottom: 8,
        "&:hover": {
            backgroundColor: '#BBD3DE'
        },
        border: 'none',
    },
    h1: {
        textAlign: 'left',
        color: '#00223D',
        fontSize: 26,
        fontWeight: "bold",
        lineHeight: '38px',
        borderBottom: '1px solid #B2D1D9',
        marginBottom: 16,
        textTransform: "uppercase"
    },
    h4: {
        fontSize: 26,
        fontWeight: "bold",
        color: "#1A1818"
    },
    h5: {
        fontSize: 14,
        color:"#2E3236"
    },
    paperFullWidth: {
        width: 'calc(100% - 16px)',
        minHeight:328,
    },
    paperScrollPaper: {
        maxHeight: 'calc(100% - 16px)'
    },
    title: {
        paddingTop:16,
        paddingLeft:16,
        fontSize:24,
        fontWeight:'bold',
        color: "#00223D",
        textTransform:'uppercase',
    },
    patient_name: {
        paddingTop:8,
        paddingLeft:16,
        fontSize:20,
        fontWeight:'bold',
        color: "#000",
    },
    patient_address: {
        paddingTop:0,
        paddingLeft:16,
        fontSize:14,
        fontWeight:'bold',
        color: "gray",
    },
    modalRoot: {
        padding:"8px 16px"
    }
}));

export const patientInventoryStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    snackbar: {
        backgroundColor: '#4CAF51'
    },
    button: {
        color: '#4F88A1',
    },
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingBottom: "20px",
    },
    select: {
        minWidth: 200,
        zIndex: 12,
        paddingTop: 10,
        paddingLeft: 10,
        paddingRight: 10,
    },
    masterList: {
        maxWidth: 600,
    },
    suggestionContainer: {
        minWidth: 200,
        display: "flex",
    }

}));
