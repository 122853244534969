import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import {makeStyles} from "@material-ui/core/styles";
import {BulkStyle} from "./bulkUpload.style";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {endpoints} from "../../../../constants/endpoints";
import jwt from 'jwt-decode';
import XLSX from "xlsx";
import {useSnackbar} from "notistack";
import {getAllDmes, getAllHospices} from "../../../utils/helpers/dataGetter";

export const BulkUserUpload = ({}) => {

    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch();
    const selectRef = React.useRef();
    const fileRef = React.useRef();
    const token = JSON.parse(localStorage.getItem('key'));
    const [organizationLoading, setOrganizationLoading] = useState(false);
    const [dmeList, setDmeList] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState([]);
    const [organizationType, setOrganizationType] = useState({label: 'DME', value: 'dme'});
    const [hospiceList, setHospiceList] = useState([]);
    const userData = jwt(token.accessToken).payload;
    const regNumbers = new RegExp('^[0-9,]*$');

    const handleUpload = async (e) => {
        let uploadData = [];
        let files = e.target.files, f = files[0];
        let reader = new FileReader();
        reader.onload = async function (e) {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, {type: 'array'});

            const wsname = workbook.SheetNames[0];
            const ws = workbook.Sheets[wsname];

            const dataParse = XLSX.utils.sheet_to_json(ws, {header: 1});
            dataParse.map((row, i) => {
                if (i > 0 && row[3] && row[2]) {
                    if (organizationType.value === "dme") {
                        uploadData.push({
                            first_name: row[0] && row[0].trim(),
                            last_name: row[1] && row[1].trim(),
                            email: row[2] && row[2].trim(),
                            username: row[3] && row[3].trim(),
                            phone: row[4],
                            role: row[5] && row[5].trim(),
                            region_id: row[6],
                            team_id: row[7],
                            title: row[8] && row[8].trim(),
                            warehouses: row[9] && String(row[9]).trim(),
                            package_id: row[10]
                        })
                    }
                    if (organizationType.value === "hospice") {
                        uploadData.push({
                            first_name: row[0] && row[0].trim(),
                            last_name: row[1] && row[1].trim(),
                            email: row[2] && row[2].trim(),
                            username: row[3] && row[3].trim(),
                            phone: row[4],
                            role: row[5] && row[5].trim(),
                            region_id: row[6],
                            team_id: row[7],
                            title: row[8] && row[8].trim(),
                            package_id: row[9]
                        })
                    }
                }
            })
            let body;
            if (organizationType.value === "dme") {
                body = {
                    userList: uploadData,
                    dmeId: selectedOrganization.value,
                    email: userData.email,
                }
            }
            if (organizationType.value === "hospice") {
                body = {
                    userList: uploadData,
                    hospiceId: selectedOrganization.value,
                    email: userData.email,
                }
            }
            const promise = await fetch(`${endpoints.bulkUserUpload}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify(body)
            })
            const response = await promise.json();
            if (response.status === "success") {
                enqueueSnackbar("Updated information successfully.", {variant: "success"});
            } else {
                enqueueSnackbar("Something happened while updating information", {variant: "warning"});
            }

        };
        reader.readAsArrayBuffer(f);

        selectRef.current.select.clearValue();
        fileRef.current.value = '';
    }

    useEffect(() => {
        (async () => {
            if (token !== null) {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
                getAllDmes(setDmeList);
                getAllHospices(setHospiceList);
            }
        })();
    }, [])

    return (
        <Grid container alignItems={"flex-end"} justify={"flex-start"} alignContent={"flex-end"}
              style={{padding: 24, maxWidth: 1000, background: 'white', marginTop: 64, borderRadius: 4}}
        >
            <Grid item xs={12}>
                <Typography variant={"h6"} style={{fontWeight: 'bold', paddingBottom: 16, textAlign: 'left'}}>
                    Upload Users
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}
                  className={classes.selectInput}>
                <Select
                    isCearable
                    ref={selectRef}
                    isLoading={organizationLoading}
                    value={organizationType}
                    options={[
                        {label: 'DME', value: 'dme'},
                        {label: 'Hospice', value: 'hospice'},
                    ]}
                    onChange={(e) => {
                        setOrganizationType(e);
                        setSelectedOrganization([]);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={4}
                  className={classes.selectInput}>
                <Select
                    isCearable
                    ref={selectRef}
                    isLoading={organizationLoading}
                    value={selectedOrganization}
                    options={organizationType && organizationType.value === "dme" ? dmeList.map((item) => {
                        return {value: item.dme_organization_id, label: item.dme_name}
                    }) : hospiceList.map((item) => {
                        return {value: item.hospice_organization_id, label: item.hospice_name}
                    })}
                    onChange={(e) => {
                        setSelectedOrganization(e);
                    }}
                />
            </Grid>
            {organizationType && organizationType.value === "dme" && <Grid item>
                <a href='https://masteritemsimages.s3.amazonaws.com/Upload_Dme_Users_Template.xlsx'
                   rel="noopener noreferrer" download style={{textDecoration: 'none'}}>
                    <Button
                        variant="outlined"
                        color="default"
                        className={classes.button}
                    >
                        <GetAppIcon className={classes.icon}/>
                        Download template
                    </Button>
                </a>
            </Grid>}
            {organizationType && organizationType.value === "hospice" && <Grid item>
                <a href='https://masteritemsimages.s3.amazonaws.com/Upload_Hospice_Users_Template.xlsx'
                   rel="noopener noreferrer" download style={{textDecoration: 'none'}}>
                    <Button
                        variant="outlined"
                        color="default"
                        className={classes.button}
                    >
                        <GetAppIcon className={classes.icon}/>
                        Download template
                    </Button>
                </a>
            </Grid>}
            {selectedOrganization && selectedOrganization.value && <Grid item xs={12} sm={12} md={6} style={{display: 'flex'}}>
                <input
                    style={{display: 'none'}}
                    id="raised-button-file"
                    type="file"
                    onChange={handleUpload}
                    ref={fileRef}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="outlined" component="span" className={classes.button}
                    >
                        <CloudUploadIcon className={classes.icon}/>
                        Upload Template
                    </Button>
                </label>
            </Grid>}
        </Grid>
    )
}

const useStyles = makeStyles((theme) => BulkStyle(theme));
