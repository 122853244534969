import React, {useEffect, useState} from 'react';
import {endpoints} from "../../constants/endpoints";

export default function CheckInventory ({children, patient_id, width }) {
    const [hasItems, setHasItems] = useState(null);

    const checkPatientInventory = async (patient_id) => {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(endpoints.pickupItems + "true" + '/' + patient_id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        let response = await promise.json();
        let {data} = response;
        if (data && data.length === 0) {
            setHasItems(false)
        } else {
            setHasItems(true)
        }
    };
    useEffect(()=> {
        hasItems === null && checkPatientInventory(patient_id)
    }, [patient_id]);

    return (
        <React.Fragment>
            {
                hasItems === true ? (<React.Fragment>{children}</React.Fragment>) : (<div style={{width: width === true ? 0 : 300}} />)
            }
        </React.Fragment>
    )
};
