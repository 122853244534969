import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import jwt from "jwt-decode";
import MaterialTable from "material-table";
import {endpoints} from "../../../constants/endpoints";
import {Backdrop} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Select from 'react-select';
import Button from "@material-ui/core/Button";
import ExportForm from "../../../common/exportForm/exportForm";
import {Table} from "../../../common/table/table";

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100,
        textAlign: 'left'
    })
};

export default function PaceReport(props) {
    const [userType, setUserType] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [list, setList] = useState([]);
    const [noAvailableHospice, setNoAvailableHospice] = useState(false);
    const classes = useStyles();
    const sortedList = list.sort((a, b) => a.label.localeCompare(b.label));

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('key'))
        if (token && token.accessToken) {
            let user = jwt(token.accessToken);
            if (user.payload.type === "Hospice") {
                setUserType('Hospice');
                getUsersReport(user.payload.hospice_organization_id)
            } else if (user.payload.type === "DME") {
                if (user.payload.role_id === 6) {
                    setUserType('Admin');
                    getAllHospiceOrganizations()
                } else {
                    setUserType('DME');
                    getContractedHospiceOrganizations();
                }
            }
        }
    }, [])

    async function getUsersReport(hospice_id) {
        setLoading(true)
        const token = JSON.parse(localStorage.getItem("key"));
        const promise = await fetch(endpoints.getPaceReport, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            method: "POST",
            body: JSON.stringify({
                hospice_id: hospice_id
            })
        });
        const response = await promise.json();
        if (response.status === "success") {
            setData(response.data)
        }
        setLoading(false);
    }

    const clearData = () => {
        setData([]);
        setSelectedOrg(null);
    };

    async function getAllHospiceOrganizations() {

        const token = JSON.parse(localStorage.getItem("key"));
        const promise = await fetch(endpoints.getAllPaceHospiceIds, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        if (response.status === "success") {
            let arr = [];
            response.data.map(org => {
                arr.push({
                    label: org.hospice_name,
                    value: org.hospice_organization_id
                })
            })
            setList(arr);

        } else {
            setNoAvailableHospice(true);
        }
        setLoading(false)
    }

    async function getContractedHospiceOrganizations() {
        const token = JSON.parse(localStorage.getItem("key"));
        let user = jwt(token.accessToken);
        const promise = await fetch(`${endpoints.getAllPaceHospiceIds}${user.payload.dme_organization_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        if (response.status === "success") {
            let arr = [];
            response.data.map(org => {
                arr.push({
                    label: org.hospice_name,
                    value: org.hospice_organization_id
                })
            })
            setList(arr);

        } else {
            setNoAvailableHospice(true);
        }
        setLoading(false)
    }

    const getExportValues = () => {
        let arr = []
        data.map(user => {
            let completed_date;
            if (user.delivery_completed_date) {
                completed_date = moment(user.delivery_completed_date).format("MM/DD/YYYY");
            } else if (!user.delivery_completed_date && user.delivery_preffered_date) {
                completed_date = moment(user.delivery_preffered_date).format("MM/DD/YYYY");
            } else if (user.exchange_completed_date) {
                completed_date = moment(user.exchange_completed_date).format("MM/DD/YYYY")
            }

            let order_id = [];
            if (user.delivery_order_id) {
                order_id.push(`D100${user.delivery_order_id}`);
            } else if (user.pickup_order_id) {
                order_id.push(`P200${user.pickup_order_id}`);
            } else if (user.exchange_order_id) {
                order_id.push(`S300${user.exchange_order_id}`);
            }

            let auth_no = [];
            if (user.delivery_auth_no) {
                auth_no.push(`Delivery Auth#: ${user.delivery_auth_no}`);
            } else if (user.pickup_auth_no) {
                auth_no.push(`Pickup Auth#: ${user.pickup_auth_no}`);
            } else if (user.exchange_auth_no) {
                auth_no.push(`Exchange Auth#: ${user.exchange_auth_no}`);
            }

            let diagnosis = [];

            user.icd10_code_and_description.map(diagnose => diagnosis.push(diagnose));

            let newArr = [];
            newArr.push(user.patient_id);
            newArr.push(user.first_name);
            newArr.push(user.last_name);
            newArr.push(user.medical_record ? user.medical_record : "");
            newArr.push(user.hospice_name);
            newArr.push(completed_date ? new Date(completed_date) : "");
            newArr.push(order_id.toString());
            newArr.push(auth_no.toString());
            newArr.push(user.name);
            newArr.push(diagnosis.toString());
            arr.push(newArr)
        });
        return arr
    }

    const headers = [
        {text: "Patient ID", style: 'tableHeader'},
        {text: "Patient First Name", style: 'tableHeader'},
        {text: "Patient Last Name", style: 'tableHeader'},
        {text: "Medical Record", style: 'tableHeader'},
        {text: "Organization", style: 'tableHeader'},
        {text: "Completed Date", style: 'tableHeader'},
        {text: "Order ID", style: 'tableHeader'},
        {text: "Auth No", style: 'tableHeader'},
        {text: "Item Name", style: 'tableHeader'},
        {text: "Diagnosis Codes", style: 'tableHeader'},
    ];

    const excelHeaders = [
        "Patient_ID",
        "Patient_First_Name",
        "Patient_Last_Name",
        "Medical_Record",
        "Organization",
        "Completed_Date",
        "Order_ID",
        "Auth_No",
        "Item_Name",
        "Diagnosis_Codes"
    ];


    return (
        <Grid container className={classes.topContainer}>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Grid container className={classes.header}>
                    Pace Report
                </Grid>
                {noAvailableHospice === true && userType !== 'Hospice' && (
                    <Grid container className={classes.noPaceOrg}>
                        No PACE organization found
                    </Grid>
                )}

                {userType === 'Hospice' && (
                    <Grid container className={classes.padding24} justify={'center'}>
                        <Grid container justify={'flex-end'}>
                            <ExportForm
                                fileName='PaceReport'
                                headers={headers}
                                excelHeaders={excelHeaders}
                                showPrint={true}
                                getExportValues={getExportValues}
                                landscape={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Table
                                showSearch={true}
                                search={true}
                                filtering={true}
                                columns={[
                                    {title: 'Patient ID', field: 'patient_id', width: 200},
                                    {title: 'First Name', field: 'first_name', width: 100},
                                    {title: 'Last Name', field: 'last_name', width: 100},
                                    {title: 'Medical Record', field: 'medical_record', width: 200},
                                    {title: 'Organization', field: 'hospice_name', width: 200},
                                    {
                                        title: 'Completed Date', field: 'last_login_date', width: 200,
                                        render: rowData => <React.Fragment>
                                            <div>{rowData.delivery_completed_date && moment(rowData.delivery_completed_date).format("MM/DD/YYYY")}</div>
                                            <div>{(!rowData.delivery_completed_date && rowData.delivery_preffered_date) && moment(rowData.delivery_preffered_date).format("MM/DD/YYYY")}</div>
                                            <div>{rowData.exchange_completed_date && moment(rowData.exchange_completed_date).format("MM/DD/YYYY")}</div>
                                        </React.Fragment>
                                    },
                                    {
                                        title: 'Order ID', field: 'delivery_order_id', width: 200,
                                        render: rowData => <React.Fragment>
                                            <div>{rowData.delivery_order_id && "D100" + rowData.delivery_order_id}</div>
                                            <div>{rowData.pickup_order_id && "P200" + rowData.pickup_order_id}</div>
                                            <div>{rowData.exchange_order_id && "S300" + rowData.exchange_order_id}</div>
                                        </React.Fragment>
                                    },
                                    {
                                        title: 'Auth No', field: 'delivery_auth_no', width: 200,
                                        render: rowData => <React.Fragment>
                                            <div>{rowData.delivery_auth_no && 'Delivery Auth#: ' + rowData.delivery_auth_no}</div>
                                            <div>{rowData.exchange_auth_no && 'Exchange Auth#:' + rowData.exchange_auth_no}</div>
                                            <div>{rowData.pickup_auth_no && 'Pickup Auth#: ' + rowData.pickup_auth_no}</div>
                                        </React.Fragment>
                                    },
                                    {title: 'Item Name', field: 'name', width: 200},
                                    {
                                        title: 'Diagnosis Codes', field: 'delivery_auth_no', width: 200,
                                        render: rowData => <React.Fragment>
                                            {rowData.icd10_code_and_description.map(diagnosis =>
                                                <div>{diagnosis && diagnosis}</div>)}
                                        </React.Fragment>
                                    },
                                ]}
                                items={data}
                                options={{
                                    search: true,
                                    showTitle: false
                                }}
                                backGroundColor={"rgba(237, 242, 244, 0.73)"}
                                textColor={"rgba(46, 50, 54, 1)"}
                            />
                        </Grid>
                    </Grid>
                )}
                {userType === 'DME' && (
                    <Grid container className={classes.padding24} justify={'flex-start'} spacing={2}>
                        <Grid item xs={12} className={classes.textAlignLeft}>Select Organization</Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable
                                isSearchable
                                value={selectedOrg}
                                name="color"
                                options={sortedList}
                                styles={selectStyles}
                                onChange={(e) => setSelectedOrg(e)}
                            />
                        </Grid>
                        <Grid item className={classes.paddingL16}>
                            <Button variant={'contained'} color={'primary'} disabled={selectedOrg === null}
                                    onClick={() => getUsersReport(selectedOrg.value)}
                            >Generate Report</Button>
                        </Grid>
                        <Grid item className={classes.paddingL16}>
                            <Button variant={'outlined'} color={'primary'}
                                    onClick={() => clearData()}
                            >Clear Filters</Button>
                        </Grid>
                        <Grid container justify={'flex-end'}>
                            <ExportForm
                                fileName='PaceReport'
                                headers={headers}
                                excelHeaders={excelHeaders}
                                showPrint={true}
                                getExportValues={getExportValues}
                                landscape={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Table
                                showSearch={true}
                                search={true}
                                filtering={true}
                                columns={[
                                    {title: 'Patient ID', field: 'patient_id', width: 200,},
                                    {title: 'First Name', field: 'first_name', width: 100},
                                    {title: 'Last Name', field: 'last_name', width: 100},
                                    {title: 'Medical Record', field: 'medical_record', width: 200},
                                    {title: 'Organization', field: 'hospice_name', width: 200},
                                    {
                                        title: 'Completed Date', field: 'last_login_date', width: 200,
                                        render: rowData => <React.Fragment>
                                            <div>{rowData.delivery_completed_date && moment(rowData.delivery_completed_date).format("MM/DD/YYYY")}</div>
                                            <div>{(!rowData.delivery_completed_date && rowData.delivery_preffered_date) && moment(rowData.delivery_preffered_date).format("MM/DD/YYYY")}</div>
                                            <div>{rowData.exchange_completed_date && moment(rowData.exchange_completed_date).format("MM/DD/YYYY")}</div>
                                        </React.Fragment>
                                    },
                                    {
                                        title: 'Order ID', field: 'delivery_order_id', width: 200,
                                        render: rowData => <React.Fragment>
                                            <div>{rowData.delivery_order_id && "D100" + rowData.delivery_order_id}</div>
                                            <div>{rowData.pickup_order_id && "P200" + rowData.pickup_order_id}</div>
                                            <div>{rowData.exchange_order_id && "S300" + rowData.exchange_order_id}</div>
                                        </React.Fragment>
                                    },
                                    {
                                        title: 'Auth No', field: 'delivery_auth_no', width: 200,
                                        render: rowData => <React.Fragment>
                                            <div>{rowData.delivery_auth_no && 'Delivery Auth#: ' + rowData.delivery_auth_no}</div>
                                            <div>{rowData.exchange_auth_no && 'Exchange Auth#:' + rowData.exchange_auth_no}</div>
                                            <div>{rowData.pickup_auth_no && 'Pickup Auth#: ' + rowData.pickup_auth_no}</div>
                                        </React.Fragment>
                                    },
                                    {title: 'Item Name', field: 'name', width: 200},
                                    {
                                        title: 'Diagnosis Codes', field: 'delivery_auth_no', width: 200,
                                        render: rowData => <React.Fragment>
                                            {rowData.icd10_code_and_description.map(diagnosis =>
                                                <div>{diagnosis && diagnosis}</div>)}
                                        </React.Fragment>
                                    },
                                ]}
                                items={data}
                                options={{
                                    search: true,
                                    showTitle: false
                                }}
                                backGroundColor={"rgba(237, 242, 244, 0.73)"}
                                textColor={"rgba(46, 50, 54, 1)"}
                            />
                        </Grid>
                    </Grid>

                )}
                {userType === 'Admin' && (
                    <Grid container className={classes.padding24}>
                        <Grid item xs={12} className={classes.textAlignLeft}>Select Organization</Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable
                                isSearchable
                                value={selectedOrg}
                                name="color"
                                options={sortedList}
                                styles={selectStyles}
                                onChange={(e) => setSelectedOrg(e)}
                            />
                        </Grid>
                        <Grid item container sm={8} xs={12} justify={"flex-end"} className={classes.paddingR8}>
                            <Grid item className={classes.paddingL16}>
                                <Button variant={'contained'} color={'primary'} disabled={selectedOrg === null}
                                        onClick={() => getUsersReport(selectedOrg.value)}
                                >Generate Report</Button>
                            </Grid>
                            <Grid item className={classes.paddingL16}>
                                <Button variant={'outlined'} color={'primary'}
                                        onClick={() => clearData()}
                                >Clear Filters</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.paddingT24}>
                            <Grid container justify={'flex-end'}>
                                <ExportForm
                                    fileName='PaceReport'
                                    headers={headers}
                                    excelHeaders={excelHeaders}
                                    showPrint={true}
                                    getExportValues={getExportValues}
                                    landscape={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Table
                                    showSearch={true}
                                    search={true}
                                    filtering={true}
                                    columns={[
                                        {title: 'Patient ID', field: 'patient_id', width: 200},
                                        {title: 'First Name', field: 'first_name', width: 100},
                                        {title: 'Last Name', field: 'last_name', width: 100},
                                        {title: 'Medical Record', field: 'medical_record', width: 200},
                                        {title: 'Organization', field: 'hospice_name', width: 200},
                                        {
                                            title: 'Completed Date', field: 'last_login_date', width: 200,
                                            render: rowData => <React.Fragment>
                                                <div>{rowData.delivery_completed_date && moment(rowData.delivery_completed_date).format("MM/DD/YYYY")}</div>
                                                <div>{(!rowData.delivery_completed_date && rowData.delivery_preffered_date) && moment(rowData.delivery_preffered_date).format("MM/DD/YYYY")}</div>
                                                <div>{rowData.exchange_completed_date && moment(rowData.exchange_completed_date).format("MM/DD/YYYY")}</div>
                                            </React.Fragment>
                                        },
                                        {
                                            title: 'Order ID', field: 'delivery_order_id', width: 200,
                                            render: rowData => <React.Fragment>
                                                <div>{rowData.delivery_order_id && "D100" + rowData.delivery_order_id}</div>
                                                <div>{rowData.pickup_order_id && "P200" + rowData.pickup_order_id}</div>
                                                <div>{rowData.exchange_order_id && "S300" + rowData.exchange_order_id}</div>
                                            </React.Fragment>
                                        },
                                        {
                                            title: 'Auth No', field: 'delivery_auth_no', width: 200,
                                            render: rowData => <React.Fragment>
                                                <div>{rowData.delivery_auth_no && 'Delivery Auth#: ' + rowData.delivery_auth_no}</div>
                                                <div>{rowData.exchange_auth_no && 'Exchange Auth#:' + rowData.exchange_auth_no}</div>
                                                <div>{rowData.pickup_auth_no && 'Pickup Auth#: ' + rowData.pickup_auth_no}</div>
                                            </React.Fragment>
                                        },
                                        {title: 'Item Name', field: 'name', width: 200},
                                        {
                                            title: 'Diagnosis Codes', field: 'delivery_auth_no', width: 200,
                                            render: rowData => <React.Fragment>
                                                {rowData.icd10_code_and_description.map(diagnosis =>
                                                    <div>{diagnosis && diagnosis}</div>)}
                                            </React.Fragment>
                                        },
                                    ]}
                                    items={data}
                                    options={{
                                        search: true,
                                        showTitle: false
                                    }}
                                    backGroundColor={"rgba(237, 242, 244, 0.73)"}
                                    textColor={"rgba(46, 50, 54, 1)"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
        </Grid>
    )
}
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    header: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 24,
        paddingTop: 24,
        paddingLeft: 24
    },
    topContainer: {
        margin: 32,
        padding: 24,
        backgroundColor: 'white',
        borderRadius: 4
    },
    noPaceOrg: {
        textAlign: 'left',
        fontWeight: 500,
        fontSize: 20,
        paddingTop: 8,
        paddingLeft: 24,
        color: 'orange'
    },
    paddingR8: {paddingRight: 8},
    paddingL16: {paddingLeft: 16},
    paddingT24: {paddingTop: 24},
    padding24: {padding: 24},
    textAlignLeft: {textAlign: "left"}
}));
