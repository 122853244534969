import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import {TextField, Tooltip} from "@material-ui/core";
import AssetSelect from "../../../../common/assetSelect/assetSelect";
import _ from "lodash";
import moment from "moment";
import {getTokenInformation} from "../../../../common/utils/utils";
import {utilsTokenIdentifiers} from "../../../../constants/inputs/values";
import {PriorityHighRounded} from "@material-ui/icons";
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";


const ItemsTable = props => {
    const classes = useStyles();
    const history = useHistory();
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const userType = getTokenInformation(history, utilsTokenIdentifiers.userType);
    return (
        <Grid container className={classes.wrapper}>
            <Grid container className={classes.container} justify={"space-between"}>
                <Grid item xs={12} style={{display: 'flex', textAlign: 'left'}}>
                    <Grid xs={1} item className={classes.checkbox} style={{flexShrink: 0}}/>
                    <Grid xs={8} item className={classes.row}>Item Name</Grid>
                    <Grid xs={6} item className={classes.row} style={{minWidth: 200}}>Asset #</Grid>
                    <Grid xs={4} item className={classes.row}>Qty</Grid>
                    <Grid xs={6} item className={classes.row}>Clinical Setting</Grid>
                    <Grid xs={6} item className={classes.row}>Lot #</Grid>
                    <Grid xs={4} item className={classes.row}>Delivery Date</Grid>
                    <Grid xs={4} item className={classes.row}>Pickup Date</Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.container} justify={"space-between"}>
                {props.items && (
                    props.items.map((item, index) => {
                        let includes = _.findIndex(props.selectedItems, {'special_item_id': item.special_item_id}) !== -1;
                        return (
                            <Grid key={index} container justify={"space-between"} className={classes.rows}>
                                <Grid item xs={12} style={{display: 'flex', textAlign: 'left'}}>
                                    <Grid xs={1} item className={classes.checkbox} style={{flexShrink: 0}} onClick={() => {
                                        userType === 'Hospice' && item?.asset?.trim().length > 0 && setSelectedIndex(prevState => prevState === index ? -1 : index);
                                    }}>
                                        <Checkbox
                                            color="primary"
                                            disabled={userType === 'Hospice' && item?.asset?.trim().length > 0}
                                            onChange={(e) => props.handleCheckboxChange(item, index, e.target.checked)}
                                            checked={includes}
                                        />
                                        {userType === 'Hospice' && item?.asset?.trim().length > 0 &&
                                            <Tooltip open={selectedIndex === index} placement={isMobile ? "bottom" : "top-end"}
                                                     title={"This item already has an asset assigned. Please contact DME for removal."}><PriorityHighRounded
                                                color={"secondary"}/></Tooltip>}
                                    </Grid>
                                    <Grid xs={8} item className={classes.row}>{item.name}</Grid>
                                    <Grid xs={6} item className={classes.row} style={{minWidth: 200}}>
                                        {props.userType === 'DME' ? (
                                            <div style={{width: '100%'}}>
                                                <AssetSelect
                                                    item={item}
                                                    hasAsset={item.asset ? true : false}
                                                    order={props.order}
                                                    setOrder={props.setOrder}
                                                />
                                            </div>
                                        ) : item.asset}
                                    </Grid>
                                    <Grid xs={4} item className={classes.row}>{item.quantity}</Grid>
                                    <Grid xs={6} item className={classes.row}>
                                        <div style={{textAlign: 'left', wordBreak: 'break-word'}}>
                                            {item.min_range !== null && (
                                                <React.Fragment>
                                                    <div> {`${item.liters_per_minute === true ? 'Liters Per Minute' : 'Air PSI'}`} </div>
                                                    <div> {`Min: ${item.min_range}`} </div>
                                                    <div> {`Max: ${item.max_range}`} </div>
                                                </React.Fragment>
                                            )}
                                            <div>
                                                {item.iscontinous === true && `With Continuous oxygen therapy`}
                                                {item.iscontinous === false && `With PRN oxygen therapy`}
                                            </div>
                                            {item.humidifier_for_compressor !== null && (
                                                <div> {`Humidifier bottle: ${item.humidifier_for_compressor}%`} </div>
                                            )}
                                            {item.weight !== null && (
                                                <div> {`Patient Weight: ${item.weight}`} </div>
                                            )}
                                            {item.delivery_method !== null && (
                                                <div> {`Delivery Method: ${item.delivery_method}`} </div>
                                            )}
                                            {item.size_lift && (
                                                <div> {`Size: ${item.size_lift}`} </div>
                                            )}
                                            {item.service_note && (
                                                <div> {`Service Note: ${item.service_note_text}`} </div>
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid xs={6} item className={classes.row}>
                                        {props.userType === 'DME' ? (
                                            item.asset && (
                                                <div style={{width: '100%'}}>
                                                    <TextField
                                                        placeholder="Add lot number"
                                                        size="small"
                                                        variant="outlined"
                                                        value={item.lot_no && item.lot_no}
                                                        onChange={(e) => {
                                                            props.selectAssetHandle(e, index, "lot", "name", item.asset, false, item.special_item_id);
                                                            props.setCurrentSpecialId(item.special_item_id);
                                                        }
                                                        }
                                                        onBlur={(e) => {
                                                            props.selectAssetHandle(e, index, "lot", "name", item.asset, true, item.special_item_id);
                                                            props.setCurrentSpecialId(item.special_item_id);
                                                        }}
                                                    />
                                                    {props.invalidLotNo && props.invalidLotNo.length > 0 && props.invalidLotNo.includes(item.special_item_id) && (
                                                        <React.Fragment>
                                                            <p style={{
                                                                color: "red",
                                                                margin: 0,
                                                                paddingTop: '5px'
                                                            }}>Invalid lot no.</p>
                                                            <p style={{color: "red", margin: 0}}>Only numbers allowed.</p>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            )
                                        ) : item.inventory ? item.inventory.lot : ''}
                                    </Grid>
                                    <Grid xs={4} item
                                          className={classes.row}>{props.order.completed_date && moment(props.order.completed_date).format('L')}</Grid>
                                    <Grid xs={4} item className={classes.row}>
                                        {item.pickup_completed_date && moment(item.pickup_completed_date).format('L')}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                )}
            </Grid>
        </Grid>
    )
};

export default ItemsTable;

const useStyles = makeStyles((theme) => ({
    wrapper: {
        paddingTop: 16,
        maxWidth: 'calc(100vw - 32px)'
    },
    container: {
        color: '#2E3236',
        fontSize: 13,
        fontWeight: 'bold',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        backgroundColor: '#EDF2F4B9',
        maxWidth: 1428,
        width: '101%',
        minWidth: 800,
        margin: -1,
    },
    row: {
        width: '100%',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        padding: 16,
        fontSize: '0.875rem',
    },
    rows: {
        paddingTop: 8,
        paddingBottom: 8,
        borderBottom: '1px solid #E0E0E0',
        backgroundColor: '#fff',
        fontWeight: 600,
        fontSize: 14,
    },
    checkbox: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        padding: 2,
        fontSize: '0.875rem',
    }
}));
