import NavBar from "../navbar/navbar";
import {MiniDrawer} from "../sidenav/sidenav";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import SideDrawer from "../sideDrawer/sideDrawer";
import {Hidden} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {Redirect} from "react-router-dom";
import { useLocation } from 'react-router-dom';

export const NavigationWrapper = props => {
    const [drawerState, setDrawerState] = useState(false);
    const isLogged = useSelector(state => state.isLogged);
    const [open, setOpen ] = useState(false);
    const location = useLocation();
    const [impersonateDialogOpen, setImpersonateDialogOpen] = useState(false);
    const [impersonateDmeIds, setImpersonateDmeIds] = useState([]);

    function checkExcludedRoutes() {
        if (location.pathname.substring(0, 13) === "/order/reject"){
            return false
        }
        if (location.pathname.substring(0, 14) === "/order/approve"){
            return false
        }
        if (location.pathname.substring(0, 17) === "/order/technician"){
            return false
        }
        return true
    }
    return (
        <React.Fragment>
            {!isLogged && checkExcludedRoutes() && <Redirect to='/login' />}
            { props.showNavBar && (checkExcludedRoutes() === true)  ? (
                <Grid container id='children'>
                    <Grid item xs={12} style={{position:'fixed', paddingTop:0, margin:0, zIndex:301}}>
                        <NavBar
                            setDrawerState={setDrawerState}
                            impersonateDialogOpen={impersonateDialogOpen}
                            setImpersonateDialogOpen={setImpersonateDialogOpen}
                            impersonateDmeIds={impersonateDmeIds}
                            setImpersonateDmeIds={setImpersonateDmeIds}
                        />
                    </Grid>
                    <Grid item xs={12} style={{paddingTop:60}}>
                        <MiniDrawer open={open} setOpen={setOpen}>
                            {props.children}
                        </MiniDrawer>
                    </Grid>
                    <Hidden mdUp>
                        <SideDrawer
                            open={drawerState}
                            setDrawerState={setDrawerState}
                            setImpersonateDialogOpen={setImpersonateDialogOpen}
                            impersonateDmeIds={impersonateDmeIds}
                        />
                    </Hidden>
                </Grid>
            ) : (
                <React.Fragment>
                    {props.children}
                </React.Fragment>
            )}
        </React.Fragment>
    )
};
