import React, {useEffect, useState} from 'react';
import {Drawer, IconButton, InputLabel, Select, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { use100vh } from 'react-div-100vh'

export function FiltersDrawer(props){
    const height = use100vh();
    const {getPatients,
        clearFilters,
        filterOptions,
        open, setOpen,
        searchType,
        setSearchType,
        applyFilters,
    } = props;
    const classes = useStyles();
    const [input, setInput] = useState('');

    function search(){
        applyFilters();
        setOpen(false)
    }

    useEffect(() => {
        props.handleInputChange(input)
    }, [input]);
    return (
        <Drawer className={classes.root} anchor={'right'} open={open}
                transitionDuration={400}
        >
            <Grid container alignContent={"space-between"} alignItems={'space-between'} style={{height:'100%'}}>
                <Grid container>
                    <Grid container style={{padding:16, fontSize:24, fontWeight:"bold", borderBottom:'1px solid lightgray', backgroundColor:'whitesmoke'}} justify={"center"} alignContent={'center'} alignItems={'center'}>
                        <IconButton onClick={()=> setOpen(false)} style={{position:'absolute', top:4, left:4}}>
                            <ArrowBackIosIcon className={classes.icon} />
                        </IconButton>
                        Filter Search Results
                    </Grid>
                    <Grid container className={classes.root} justify={'space-between'} alignContent={'center'} alignItems={'center'}>
                        <Grid item className={classes.p2}>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="outlined-age-native-simple">Search Type</InputLabel>
                                <Select
                                    native

                                    value={searchType}
                                    onChange={(e)=> setSearchType(e.target.value)}
                                    label="Search Type"
                                    inputProps={{
                                        name: 'age',
                                        id: 'outlined-age-native-simple',
                                    }}
                                    style={{height:40, minWidth:200}}
                                >
                                    <option aria-label="None" value={0}>General Search</option>
                                    <option value={1}>By Order ID</option>
                                    <option value={2}>By Medical Record</option>
                                    <option value={3}>By Lot</option>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.searchBox}>
                        <TextField
                            fullWidth
                            id="input-with-icon-textfield"
                            placeholder={'Search by first name, last name, address...'}
                            variant={'outlined'}
                            size={'small'}
                            value={input}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon className={classes.searchIcon}/>
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(e) => setInput(e.target.value)}
                        />
                    </Grid>
                    <Grid container className={classes.filterTitle}>Filter by</Grid>
                    <Grid container style={{maxHeight:`calc(${height}px - 360px)`, overflow:'scroll'}}>
                        {
                            filterOptions.map((filter, index)=> {
                                return (
                                    <Grid container className={classes.filterBox} key={index}>
                                        <FormControl variant="outlined"  style={{width:'100%'}}>
                                            <InputLabel htmlFor="outlined-age-native-simple">{filter.placeholder}</InputLabel>
                                            <Select
                                                native
                                                label={filter.placeholder}
                                                inputProps={{
                                                    name: 'age',
                                                    id: 'outlined-age-native-simple',
                                                }}
                                                style={{height:40, width:'100%'}}
                                                value={(filter.value === "All" || filter.value === undefined) ? "1" : filter.value}
                                                options={filter.options}
                                                onChange={(event) => {
                                                    const obj = {
                                                        target: {
                                                            value:event.target.value,
                                                            name: filter.name,
                                                        }
                                                    };
                                                    props.handleFilterUpdate(obj);
                                                }}
                                            >
                                                <option aria-label="None" value="1">Select {filter.placeholder}</option>
                                                { filter.options.map((option, i)=> (
                                                    <option key={i} value={option.value}>{option.label}</option>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid container className={classes.bottom}>
                            <Button fullWidth color={'primary'} variant={'outlined'} className={classes.clearButton} onClick={() => {
                                clearFilters();
                                setInput('')
                            }}>Clear Filters</Button>
                            <Button fullWidth color={'primary'} variant={'contained'} className={classes.submitButton} onClick={search}>Apply Filters</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Drawer>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop:8,
       width:'100vw',
    },
    mr1: {
       marginRight:8
    },
    p1: {
        padding:8
    },
    p2: {
        padding:16
    },
    searchBox:{
        padding:'0px 16px'
    },
    filterTitle: {
        paddingLeft:16,
        fontSize:20,
        fontWeight:"bold",
        paddingTop:16
    },
    filterBox:{
        paddingTop:8,
        paddingLeft:16,
        paddingRight:16,
        paddingBottom:16
    },
    icon:{
      fontSize:34,
        paddingLeft:8,
      color:'#00223D'
    },
    fabRight: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
    fabLeft: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
    bottom: {
        // position: 'absolute',
        bottom: 0,
        display:'flex',
    },
    submitButton: {
        height:64,
        fontSize:20,
        borderRadius:0
    },
    clearButton: {
        height:50,
        fontSize:20,
        borderRadius:0,
        marginBottom:12
    },
    searchIcon: {
        color:'gray'
    }
}));
