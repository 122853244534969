import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Checkbox, CircularProgress, ListItem} from "@material-ui/core";
import Select from 'react-select';
import _ from "lodash";
import Button from "@material-ui/core/Button";
import {checkTokenAvailability, getTokenInformation} from "../utils/utils";
import {logout} from "../../redux/actions";
import {endpoints} from "../../constants/endpoints";
import {useDispatch} from "react-redux";
import jwt from "jwt-decode";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Backdrop from "@material-ui/core/Backdrop";
import {utilsTokenIdentifiers} from "../../constants/inputs/values";

const customStyles = {
    control: base => ({
        ...base,
        minHeight: 52,
    }),
    menu: base => ({
        ...base,
        textAlign: 'left'
    })
};

export default function ReportsHeader({ needFacility = false, generateReport, clearData}) {
    const dispatch = useDispatch();
    const history = useHistory();
    const mobile = useMediaQuery('(max-width:600px)');
    const classes = useStyles();
    const [contractedProviders, setContractedProviders] = useState([]);
    const [selectedValues, setSelectedValues] = useState(null)
    const [selectedDme, setSelectedDme] = useState([])
    const token = JSON.parse(localStorage.getItem('key'));
    const userData = jwt(token.accessToken);
    const [isLoading, setIsLoading] = useState(false)
    const [orgData, setOrgData] = useState(null);
    const [type, setType] = useState(null)
    const [selectAll, setSelectAll] = useState(true);

    const getData = () => {
        setIsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();

        if (userData.payload.hospice_organization_id === null) {
            fetch(endpoints.getHospicesByDme + userData.payload.dme_organization_id, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let data = _.cloneDeep(response.data);
                    let finalArr=[];
                    data.map(hospice => {
                        let regionsArr = [];
                        let facilityArr = [];
                        hospice.regions.map(region => {
                            regionsArr.push({
                                label:region.region_name,
                                value: region.region_id
                            })
                        });
                        hospice.facilities[0] && hospice.facilities[0].map(facility => {
                            facilityArr.push({
                                label:facility.name,
                                value: facility.facility_id
                            })
                        });
                        finalArr.push({
                            facilities:facilityArr,
                            regions: regionsArr,
                            value: hospice.hospice_id,
                            label: hospice.hospiceName
                        })
                    })
                    setOrgData(finalArr);
                    setSelectedValues(finalArr);
                    setSelectedDme([{value:userData.payload.dme_organization_id,
                        label:userData.payload.dme_name}])
                    setIsLoading(false);
                })
                .catch(err =>  setIsLoading(false))
            setType("DME")
        } else {
            fetch(`${endpoints.getHospiceRegions}${userData.payload.hospice_organization_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let data = _.cloneDeep(response.data);
                    let newArr = [];
                    data.map(region => {
                        newArr.push({
                            label:region.name,
                            value: region.region_id
                        })
                    });
                    setOrgData(prevState => {
                        return {...prevState,
                            hospiceName: userData.payload.hospice_name,
                            hospice_id: userData.payload.hospice_organization_id,
                            regions: newArr
                        }
                    });
                    setSelectedValues( prevState => {
                        return {...prevState,
                            hospiceName: userData.payload.hospice_name,
                            hospice_id: userData.payload.hospice_organization_id,
                            regions: newArr
                        }
                    })
                    setIsLoading(false);
                })
                .catch(err =>  setIsLoading(false))
            if (needFacility){
                fetch(`${endpoints.getHospiceFacilities}${userData.payload.hospice_organization_id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                    },
                })
                    .then(response => response.json())
                    .then(response => {
                        let data = _.cloneDeep(response.data);
                        let newArr = [];
                        data.map(facility => {
                            newArr.push({
                                label:facility.name,
                                value: facility.facility_id
                            })
                        });
                        setOrgData(prevState => {
                            return {...prevState,  facilities: newArr}
                        });
                        setSelectedValues( prevState => {
                            return {...prevState,
                                facilities: newArr
                            }
                        })
                        setIsLoading(false);
                    })
                    .catch(err =>  setIsLoading(false))
            }
        setType("Hospice")
        }
    };
    const clearFilters = () => {
        clearData && clearData();
        if(type === 'Hospice') {
            setSelectedDme([]);
            setSelectedValues(prevState => {
                return {
                    ...prevState,
                    regions: []
                }
            })
        }
        if(type === 'DME'){
            setSelectedValues([]);
        }
    };

    const getContractedProviders = () => {
        if (userData.payload.hospice_organization_id !== null) {
            fetch(`${endpoints.getHospiceContractedProviders}${userData.payload.hospice_organization_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenInformation(utilsTokenIdentifiers.accessToken)
                }
            })
                .then(response => response.json())
                .then(response => {
                    let object = [];
                    for (let i = 0; i < response.data.length; i++) {
                        object.push({value: response.data[i].dme_id, label: response.data[i].dme_name})
                    }
                    setContractedProviders(object);
                })
                .catch(err => {})
        }
    };
    function processData(){
        let finalObj = {
            dme_id: selectedDme[0] && selectedDme[0].value,
            hospices_ids: []
        }
        if (type === 'DME'){
            selectedValues.map(hospice => {
                let regions = [];
                let facilities = [];
                hospice.regions && hospice.regions.map(region => {
                        regions.push({region_id: region.value, region_name: region.label})
                    })
                hospice.facilities && hospice.facilities.map(facility => {
                        facilities.push({facility_id: facility.value, name: facility.label})
                    })
                finalObj.hospices_ids.push({
                    hospice_id: hospice.value,
                    hospice_name: hospice.label,
                    regions: regions,
                    facilities: [facilities],
                })
            })
        } else if (type === "Hospice") {
            let regions = [];
            let facilities = [];
            selectedValues.regions && selectedValues.regions.map(region => {
                regions.push({region_id: region.value, region_name:region.label})
            })
            selectedValues.facilities && selectedValues.facilities.map(facility => {
                facilities.push({facility_id: facility.value, name:facility.label})
            })
            finalObj.hospices_ids.push({
                hospice_id: selectedValues.hospice_id,
                hospice_name: selectedValues.hospice_name,
                regions: regions,
                facilities: [facilities],
            })
            finalObj.dme_id = selectedDme.value ? selectedDme.value : contractedProviders.length === 1 ? contractedProviders[0].value : null;
        }
        generateReport(finalObj)
    }

    useEffect(()=> {
        getData();
        getContractedProviders()
    },[])

    if (type === null){
        return <Grid container justify={'center'} alignItems={'center'} alignContent={'center'} style={{height:500}}>
            <CircularProgress />
        </Grid>
    }
    if (type === "Hospice") {
        return (
            <Grid container justify={"space-between"}>
                <Grid item container xs={12}>
                    {contractedProviders && contractedProviders.length > 1 &&(
                        <Grid item sm={needFacility ? 3 : 4} className={classes.select} style={{display:'flex'}}>
                            {!isLoading && <span>Select Provider</span>}
                        </Grid>
                    )}
                <Hidden xsDown>
                    {orgData && orgData.regions && orgData.regions.length !== 0 && (
                        <Grid item xs={needFacility ? 3 : 4} className={classes.select} style={{paddingLeft: needFacility ? 40 : 8}}>
                            <span>Select Region</span>
                        </Grid>
                    )}
                    {needFacility && orgData && orgData.facilities && orgData.facilities.length !== 0  && (
                        <Grid item xs={needFacility ? 3 : 4} className={classes.select} style={{paddingLeft:80}}>
                            <span>Select Facility</span>
                        </Grid>
                    )}

                </Hidden>
                </Grid>
                    <Grid item xs={12} sm={needFacility ? 10 : 8}>
                        <Grid container spacing={2}>
                            {contractedProviders && contractedProviders.length > 1 && (
                                <Grid item sm={needFacility ? 4 : 6} xs={12} style={{zIndex:100}}>
                                    <Select
                                        placeholder='Select Provider'
                                        options={contractedProviders}
                                        value={selectedDme && selectedDme}
                                        onChange={(e)=> setSelectedDme(e)}
                                    />
                                </Grid>
                            )}
                            {!isLoading && (
                                <Grid item sm={needFacility ? 4 : 6} xs={12} style={{zIndex:99}}>
                                    <Select
                                        isMulti
                                        options={orgData && orgData.regions}
                                        value={selectedValues.regions}
                                        onChange={(e)=> setSelectedValues(prevState =>{
                                            return {...prevState, regions:e}
                                        })}
                                    />
                                </Grid>
                            )}
                            {needFacility && (
                                <Grid item sm={4} xs={12} style={{zIndex:98}}>
                                    <Select
                                        isMulti
                                        options={orgData && orgData.facilities}
                                        value={selectedValues && selectedValues.facilities}
                                        onChange={(e)=> setSelectedValues(prevState =>{
                                            return {...prevState, facilities:e}
                                        })}
                                    />
                                </Grid>
                            )}

                        </Grid>
                    </Grid>
                {!isLoading && (<Grid item sm={2} xs={12} container style={{paddingRight: 16}}>
                    <Grid item xs={12}
                          style={{paddingTop: 4, paddingLeft: 8}}>
                        <Button fullWidth variant={'contained'} color={'primary'} onClick={processData}>Generate Report</Button>
                        <Grid item xs={12}
                              style={{paddingTop: 8}}>
                            <Button fullWidth variant={'outlined'} color={'primary'} onClick={clearFilters}>Clear
                                Filters</Button>
                        </Grid>
                    </Grid>

                </Grid>)}
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        )
    }
    if (type === "DME") {
        return (
            <Grid item container xs={12}>
                <Grid item xs={selectedValues && selectedValues.length === 0 ? 12 : 4} sm={selectedValues && selectedValues.length === 0 ? 12 : 3} className={classes.select4}>
                    {!isLoading && <span>Select organization</span> }
                </Grid>
                <Hidden xsDown>
                    {selectedValues && selectedValues.length !== 0 && (
                        <Grid item xs={3} className={classes.select3}>
                            Select Region
                        </Grid>
                    )}
                </Hidden>
                {selectedValues && selectedValues.length !== 0 && (
                    <Grid item xs={3} className={classes.select2}>
                        {needFacility && !mobile && 'Select Facility'}
                    </Grid>
                )}
                <Grid item container xs={12} justify={"space-between"}>
                <Grid item sm={9} xs={12}
                      // xs={selectedValues && selectedValues.length === 0 ? 6 : 8} sm={selectedValues && selectedValues.length === 0 ? 4 : needFacility ? 12 : 8}
                >
                    <Grid item  sm={4} xs={12} style={{paddingLeft: 8,  marginBottom: 4, marginTop: 4,}}>
                        <div className={classes.item} style={{display: "flex", padding: 16}}>
                            <Checkbox
                                checked={selectAll}
                                style={{ height: 20 }}
                                color="primary"
                                onChange={() => {
                                    setSelectAll(prevState => !prevState)
                                    if(getTokenInformation(history, utilsTokenIdentifiers.payload).type === "DME") {
                                        selectAll === false ? setSelectedValues(orgData) : setSelectedValues([]);
                                    }
                                    if(getTokenInformation(history, utilsTokenIdentifiers.payload).type === "Hospice"){
                                        selectAll === false ? setSelectedValues(orgData) : setSelectedValues(prevState => {
                                            return {
                                                ...prevState,
                                                regions: []
                                            }
                                        })
                                    }
                                }}
                            />
                                <span
                                    style={{display: "flex", paddingLeft: 8, textAlign: "left" }}
                                >
                                  Select All
                                </span>
                        </div>
                    </Grid>
                    {orgData && orgData.map((hospice, index) => {
                        let isChecked = selectedValues && selectedValues.findIndex(hos => hos.value === hospice.value) > -1;
                        return (
                            <Grid container key={index}>
                                <Grid item
                                      // xs={selectedValues && selectedValues.length !== 0 ? 12 : needFacility ? 4 : 6}
                                    sm={4} xs={12}
                                       style={{paddingLeft: 8,  marginBottom: 4,
                                           marginTop: 4,}}>
                                    <ListItem dense button
                                              className={classes.item}
                                              style={{height: '100%'}}
                                              onClick={(e) => {
                                                  let clone = _.cloneDeep(selectedValues);
                                                  let index = selectedValues.findIndex(e => e.value === hospice.value);
                                                  if (index === -1){
                                                      clone.push(hospice)
                                                  } else {
                                                      clone.splice(index, 1);
                                                  }
                                                  orgData.length === clone.length ? setSelectAll(true) : setSelectAll(false);
                                                  setSelectedValues(clone)
                                              }}>
                                        <Checkbox
                                            checked={isChecked}
                                            disableRipple
                                            color={'primary'}
                                            onChange={(e) => {
                                                let clone = _.cloneDeep(selectedValues);
                                                let index = selectedValues.findIndex(e => e.value === hospice.value);
                                                if (index === -1){
                                                    clone.push(hospice)
                                                } else {
                                                    clone.splice(index, 1);
                                                }
                                              setSelectedValues(clone)
                                            }}
                                        />
                                        {hospice.label}
                                    </ListItem>
                                </Grid>
                                {isChecked && function () {
                                    let hospiceIndex = orgData.findIndex(hos => hos.value === hospice.value)
                                    let index = selectedValues.findIndex(e => e.value === hospice.value);
                                    return (<Grid item  sm={needFacility ? 4 : 8}  xs={12} style={{paddingLeft: 8, marginBottom: 4, marginTop: 4, zIndex:100 - index}}>
                                        <Select
                                            isMulti
                                            options={orgData[hospiceIndex].regions}
                                            styles={customStyles}
                                            value={selectedValues[index].regions}
                                            onChange={(e)=> {
                                                let clone = _.cloneDeep(selectedValues);
                                                clone[index].regions = e;
                                                setSelectedValues(clone)
                                            }}
                                        />
                                    </Grid>
                                    )
                                }()
                                }
                                {isChecked && needFacility && function () {
                                    let hospiceIndex = orgData.findIndex(hos => hos.value === hospice.value)
                                    let index = selectedValues.findIndex(e => e.value === hospice.value);
                                    return (<Grid item sm={4} xs={12}
                                                  style={{paddingLeft: 8, marginBottom: 4, marginTop: 4, zIndex:98}}>
                                        <Select
                                            isMulti
                                            options={orgData[hospiceIndex].facilities}
                                            styles={customStyles}
                                            value={selectedValues[index].facilities}
                                            onChange={(e)=> {
                                                let clone = _.cloneDeep(selectedValues);
                                                clone[index].facilities = e;
                                                setSelectedValues(clone)
                                            }}
                                        />
                                    </Grid>
                                    )
                                }()}
                            </Grid>
                        )
                    })}
                </Grid>

                {!isLoading && (<Grid item sm={2} xs={12} container>
                    <Grid item xs={12}
                          style={{paddingTop: 4, paddingLeft: 8}}>
                            <Button fullWidth variant={'contained'} color={'primary'} onClick={processData}>Generate Report</Button>
                        <Grid item xs={12}
                              style={{paddingTop: 8}}>
                            <Button fullWidth variant={'outlined'} color={'primary'} onClick={clearFilters}>Clear
                                Filters</Button>
                        </Grid>
                    </Grid>

                </Grid>)}
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
                </Grid>
            </Grid>
        )
    }
}

const useStyles = makeStyles((theme) => ({
    select: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignContent: "flex-start",
        alignItems: "flex-start",
        textAlign:'left',
    },
    select2: {
        paddingLeft:8,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignContent: "center",
        alignItems: "center",
        textAlign:'left',
    },
    select3: {
        paddingLeft:8,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignContent: "center",
        alignItems: "center",
        textAlign:'left',
    },
    select4: {
        paddingLeft:8,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignContent: "center",
        alignItems: "center",
        textAlign:'left',
        width:'90%'
    },
    item: {
        background: 'white',
        borderRadius: 4,
        border: '1px solid lightgrey'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

}));
