import React, {forwardRef, useEffect, useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import CreateOrder from "../../createOrder/createOrder";
import RequestPickup from "../../requestPickup/requestPickup";
import RequestExchange from "../../requestExchange/requestExchange";
import {componentTitles} from "../../../constants/names/titles";
import {getMessage} from "../../../constants/messages/messages";
import {messageTypes} from "../../../constants/types/messageTypes";
import MaterialTable, {MTableToolbar} from "material-table";
import {tableColumnTitles} from "../../../constants/names/columnTitles";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import {endpoints} from "../../../constants/endpoints";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Select from "react-select";
import {checkTokenAvailability, formatNumber} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import EditCustomIcon from "../../../assets/edit-button.svg";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import {ArrowDownward} from "@material-ui/icons";
import {patientDataStyle} from "./patientData.style";
import {UserCounter} from "../../../common/audit/PageUserCounter";
import CheckInventory from "../../../common/checkInventory/checkInventory";
import {checkPermissions} from "../../../common/permissions/checkPermissions";
import {permissions} from "../../../constants/permissions/permissions";
import {checkFeatures} from "../../../common/checkFeatures/checkFeatures";
import {orderReasons} from "../../../constants/inputs/values";
import {tooltips} from "../../../constants/inputs/tooltips";
import {CustomPackageOrders} from "../../orders/customPackageOrders/CustomPackageOrders";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {IconAlertActions, IconAlerts, IconAlertTypes} from "../../../common/alerts/IconAlerts/IconAlerts";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CartIcon from "../../../assets/shopping-cart.svg";
import ToolIcon from "../../../assets/tool.svg";
import {findIndex} from "lodash"
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import {printProfile} from "./printHelper";
import {NewRequest} from "../../../common/newRequest/newRequest";
import {isMobile} from "react-device-detect";
import AddressHistory from './addressHistory';
import InventoryQuickView from "./quickViewInventory";
import {InfectiousDiseasesComponent} from "./components/infectiousDiseasesComponent/InfectiousDiseasesComponent";

export default function PatientData(props) {
    // const isMobile = true;
    const classes = useStyles();
    const data = props.patientInfo;
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const name = data.mi ? data.first_name + " " + data.mi  + " " + data.last_name : data.first_name + " " + data.last_name;
    const address = data.address1 + ", " + data.address2 + ", " + data.zip + ", " + data.city + ", " + data.state;
    const [isArchived, setIsArchived] = useState(data.archived);
    const [statusOptions, setStatusOptions] = useState([
        {value: "Active", label: "Active"},
        {value: "Inactive", label: "Inactive"},
        {value: "Expired", label: "Expired"},
        {value: "Discharged", label: "Discharged"},
        {value: "Revoked", label: "Revoked"},
    ]);
    const endpointToTrack = `/api/patients/${data.patient_id}`;
    const endpointsToIgnore = [`api/patients/notes/${data.patient_id}`, `api/patients/caregivers/${data.patient_id}`, `api/patients/diseases/${data.patient_id}`];
    const [activeItems, setActiveItems] = useState([]);
    const [allItems, setAllItems] = useState([]);
    const [portalFeature, setPortalFeature] = useState(false);
    const [autoPickupTrigger, setAutoPickupTrigger] = useState(false);
    const [autoPickupReason, setAutoPickupReason] = useState("");
    const [previewModal, setPreviewModal] = useState(false);
    const [activePreview, setActivePreview] = useState('');
    const [itemResourceLoading, setItemResourceLoading] = useState(false);
    const [resourcesList, setResourcesList] = useState([]);
    const [showIcdCodes,setShowIcdCodes] = useState(false);
    const [historyAddress, setHistoryAddress] = useState([]);
    const [quickViewItems,setQuickViewItems] = useState([]);

    const notesSection = React.createRef();
    const infectiousDiseasesSection = React.createRef();

    const getActiveItems = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        //TODO not sure what is the diff between getAllItemsByPatient and getAllItemsByPatient
        fetch(`${endpoints.getActiveItemsByPatient}${props.patient_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => response.json())
            .then(response => {
                //category, name, quantity
                setActiveItems(response.data);
                let itemsView = [];
                for (let it of response.data) {
                   itemsView.push({
                       category: it.category,
                       name: it.name,
                       quantity: Number(it.quantity),
                   })

                }
                itemsView.sort((a, b) => a.category.localeCompare(b.category));
                const totalizedItemView = [];
                itemsView.forEach(item => {
                    let index = findIndex(totalizedItemView, {category: item.category, name: item.name});
                    if(index === -1){
                        totalizedItemView.push({...item});
                    }
                    if(index > -1){
                        totalizedItemView[index].quantity += item.quantity;
                    }
                })
                setQuickViewItems(totalizedItemView)
            }).catch(err => {
            console.log(err, 'error');
            enqueueSnackbar("Something happened. Contact support.", {
                persist: false,
                variant: "error",
            });
        });
    };
    const getAllItems = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getAllItemsByPatient}${props.patient_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => response.json())
            .then(response => {
                setAllItems(response.data)
            }).catch(err => {
            console.log(err, 'error');
            enqueueSnackbar("Something happened. Contact support.", {
                persist: false,
                variant: "error",
            });
        });
    };

    const getHistoryAddress = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.patientHistoryAddress}${props.patient_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => response.json())
            .then(response => {
                setHistoryAddress(response.data)
            }).catch(err => {
            console.log(err, 'error');
            enqueueSnackbar("Something happened. Contact support.", {
                persist: false,
                variant: "error",
            });
        });
    };

    const updateArchivedStatus = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.updateArchivePatient}${props.patient_id}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({archive: !isArchived})
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === "success") {
                    setIsArchived(!isArchived)
                }
                enqueueSnackbar("Patient updated with success", {
                    persist: false,
                    variant: "success",
                });
            }).catch(err => {
            console.log(err, 'error');
            enqueueSnackbar("Something happened. Contact support.", {
                persist: false,
                variant: "error",
            });
        });
    };
    const updatePatientStatus = (event) => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.updatePatientStatus}${props.patient_id}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({status: event.value})
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === "success") {
                    props.setPatientInfo({...props.patientInfo, ["status"]: event.value});
                    if(event.value === orderReasons.expired || event.value === orderReasons.revoked || event.value === orderReasons.discharged){
                        setAutoPickupReason(event.value);
                        setAutoPickupTrigger(true);
                    } else if(event.value === "Active"){
                        setAutoPickupTrigger(false);
                    }
                    enqueueSnackbar("Patient status updated with success", {
                        persist: false,
                        variant: "success",
                    });
                }
            }).catch(err => {
            enqueueSnackbar("Something happened. Contact support.", {
                persist: false,
                variant: "error",
            });
        });
    };

    useEffect(() => {
        if (activeItems.length === 0) {
            getActiveItems()
        }
        if (allItems.length === 0) {
            getAllItems()
        }
        if(props.patientInfo?.ispace) {
            statusOptions.push({value: "Disenrolled", label: "Disenrolled"});
        }
    }, []);
    useEffect(() => {
        getHistoryAddress();
    }, []);
    useEffect(()=> {
        (async()=> {
            let response = await checkFeatures('CaregiverPortal','Hospice', props.patientInfo.hospice_organization_id);
        setPortalFeature(response);})();
    },[]);

    const openDeliveryOrder = (rowData) => {
        window.open(`/order/delivery/${rowData.delivery_order_id}`, "_blank")

    };
    const openPickupOrder = (rowData) => {
        window.open(`/order/pickup/${rowData.pickup_order_id}`, "_blank")
    };

    const openExchangeOrder = (rowData) => {
        window.open(`/order/exchange/${rowData.exchange_order_id}`, "_blank")
    };
    const orderColumns = [
        {
            title: tableColumnTitles.orderType, field: 'type', width: 100, sorting: false,
            render: rowData => <div>
                <span>{rowData.delivery_order_id && "Delivery"}</span>
                <span>{rowData.pickup_order_id && "Pickup"}</span>
                <span>{rowData.exchange_order_id && "Exchange"}</span>
            </div>
        },
        {
            title: tableColumnTitles.orderId, field: 'orderId', width: 100, sorting: false,
            render: rowData => <div>
                <span>{rowData.delivery_order_id && "D100" + rowData.delivery_order_id}</span>
                <span>{rowData.pickup_order_id && "P200" + rowData.pickup_order_id}</span>
                <span>{rowData.exchange_order_id && "S300" + rowData.exchange_order_id}</span>
            </div>
        },
        {
            title: tableColumnTitles.priority, field: 'priority_code', width: 100,
        },
        {
            title: tableColumnTitles.orderStatus, field: 'status', width: 100, sorting: false,
            headerStyle: {
                textAlign: 'center',
                padding: 0,
                margin: 0,
            },
            render: rowData => <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {
                    (rowData.status === 'Open') && (
                        <React.Fragment>
                            <Button disabled style={{
                                background: "rgba(123, 153, 181, 1)",
                                width: 150,
                                color: "white",
                                cursor: 'initial',
                                border: 'none'
                            }} variant='outlined' size='small'>
                                OPEN
                            </Button>
                        </React.Fragment>
                    )}
                {
                    (rowData.status === 'Processing') && (
                        <React.Fragment>
                            <Button disabled variant='outlined' style={{
                                background: "rgba(206, 193, 41, 1)",
                                width: 150,
                                color: "white",
                                cursor: 'initial',
                                border: 'none'
                            }} size='small'
                            >
                                PROCESSING
                            </Button>
                        </React.Fragment>

                    )
                }
                {
                    (rowData.status === 'Completed') && (
                        <Tooltip title={tooltips.completed}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: "rgba(17, 124, 112, 1)",
                                width: 150,
                                height: 30,
                                borderRadius: 4,
                                color: "white",
                                border: 'none'
                            }}>
                                <span>COMPLETED</span>
                            </div>
                        </Tooltip>
                    )
                }
                {
                    (rowData.status === 'Pending') && (
                        <Tooltip title={tooltips.pending}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: "rgba(206, 193, 41, 1)",
                                width: 150,
                                height: 30,
                                borderRadius: 4,
                                color: "white",
                                border: 'none'
                            }}>
                                <span>PENDING</span>
                            </div>
                        </Tooltip>
                    )
                }
                {
                    (rowData.status === 'Canceled') && (
                        <Tooltip title={tooltips.completed}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: "rgba(205, 29, 50, 1)",
                                width: 150,
                                height: 30,
                                borderRadius: 4,
                                color: "white",
                                border: 'none'
                            }}>
                                <span>CANCELED</span>
                            </div>
                        </Tooltip>
                    )
                }
                {
                    (rowData.status === 'Rejected') && (
                        <Tooltip title={tooltips.completed}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: "rgba(205, 29, 50, 1)",
                                width: 150,
                                height: 30,
                                borderRadius: 4,
                                color: "white",
                                border: 'none'
                            }}>
                                <span>REJECTED</span>
                            </div>
                        </Tooltip>
                    )
                }
            </div>
        },


        {
            title: "Created Date", field: 'created_date', width: 150, defaultSort: 'desc', searchable: true,
            headerStyle: {
                textAlign: 'center'
            },
            render: rowData => <div style={{
                display: "flex",
                justifyContent: "center"
            }}> {moment(rowData.created_date).format(" MMM DD, YYYY")}  </div>
        },
        {title: tableColumnTitles.organization, field: 'order_id', hidden: true, searchable: true},
        {title: tableColumnTitles.organization, field: 'type', hidden: true, searchable: true},
        {title: tableColumnTitles.organization, field: 'orderId', hidden: true, searchable: true},
        {title: tableColumnTitles.organization, field: 'formattedCreatedDate', hidden: true, searchable: true},
        {title: tableColumnTitles.organization, field: 'formattedPreferredDate', hidden: true, searchable: true},
        {
            title: "Action", field: 'toorder', width: 150, searchable: false,
            headerStyle: {
                textAlign: 'center'
            },
            render: rowData => <div style={{
                display: "flex",
                justifyContent: "center"
            }}> <Button color={"primary"} variant={"outlined"} onClick={ ()=> {
                switch(rowData.type){
                    case "delivery":
                        openDeliveryOrder(rowData);
                        break;
                    case "pickup":
                        openPickupOrder(rowData);
                        break;
                    case "exchange":
                        openExchangeOrder(rowData);
                        break;
                }
            }}>
                OPEN ORDER
            </Button>  </div>
        },
    ];

    function getItemResources(id) {
        setItemResourceLoading(true);
        setPreviewModal(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        (async () => {
            const token = JSON.parse(localStorage.getItem('key'));
            const promise = await fetch(`${endpoints.getAllItemResources}/${id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + token.accessToken
                }
            });
            const response = await promise.json();
            for (let i=0; i < response.data.length; i++){
                if (response.data[i].type !== 'video'){
                    let url = await previewExisting(response.data[i].file_name)
                    response.data[i].url = url;
                }
            }
            setResourcesList(response.data);
            setItemResourceLoading(false);
        })();
    }
    const columnsActive = [
        {title: tableColumnTitles.item, field: 'name', width: 80,
            render: rowData => {
            return (
                <div onClick={(e)=>getItemResources(rowData.item_id)} style={{cursor:'pointer', color:'blue', textDecoration:'underline'}}>
                    {rowData.name}
                </div>
            )
            }
        },
        {title: tableColumnTitles.asset, field: 'asset', width: 80, searchable: false},
        {title: tableColumnTitles.lot, field: 'lot_no', width: 80},
        {title: tableColumnTitles.quantity, field: 'quantity', width: 80},
        {title: tableColumnTitles.class, field: 'contract_type', width: 80},
        {
            title: tableColumnTitles.delivery, field: 'delivery_completed_date', width: 40,
            render: rowData => {
                if (isNaN(rowData.delivery) || rowData.delivery === undefined) {
                    return <div>
                        <Tooltip
                            title={`Open order ${rowData.delivery_order_id ? 'D100' + rowData.delivery_order_id : "S300" + rowData.exchange_order_id}`}>
                            <Button
                                disabled={props.portal}
                                color="primary"
                                variant='outlined'
                                size='small'
                                onClick={() => {
                                    rowData.delivery_order_id && openDeliveryOrder(rowData);
                                    rowData.exchange_order_id && openExchangeOrder(rowData);
                                }}
                            >
                                {rowData.delivery_order_id ? 'D100' + rowData.delivery_order_id : "S300" + rowData.exchange_order_id}
                            </Button>
                        </Tooltip>

                        {rowData.delivery_completed_date !== null && rowData.exchange_type !== "Reclaimed" && <div style={{paddingTop: 4}}>
                            {moment(rowData.delivery_completed_date).format('LL')}
                        </div>}

                        {rowData.exchange_completed_date !== null && <div style={{paddingTop: 4}}>
                            {moment(rowData.exchange_completed_date).format('LL')}
                        </div>}
                    </div>
                } else {
                    return <Link>
                        {rowData.delivery}
                    </Link>
                }
            }
        },
        {
            title: tableColumnTitles.pickup, field: 'pickup', width: 80,
            render: rowData => {
                if (rowData.pickup_status === 'Processing') {
                    return <div>
                        In Progress
                    </div>
                } else if (rowData.exchange_status === 'Processing' && rowData.exchange_type==='Reclaimed'){
                    return <div>
                        In Progress
                    </div>
                }
            }
        },
        {
            title: tableColumnTitles.status, field: 'status', width: 80,
            render: rowData => {
                if (rowData.pickup_status === 'Completed') {
                    return <div>
                        Picked Up
                    </div>
                } else {
                    return <div>
                        Delivered
                    </div>
                }
            }
        },
    ];
    const columnsAll = [
        {title: tableColumnTitles.item, field: 'name', width: 40},
        {title: tableColumnTitles.asset, field: 'asset', width: 40, searchable: false},
        {title: tableColumnTitles.lot, field: 'lot_no', width: 40},
        {title: tableColumnTitles.quantity, field: 'quantity', width: 20},
        {title: tableColumnTitles.class, field: 'class', width: 20},
        {
            title: tableColumnTitles.delivery, field: 'delivery_completed_date', width: 40,
            render: rowData => {
                if (isNaN(rowData.delivery) || rowData.delivery === undefined) {
                    return <div>
                        <Tooltip
                            title={`Open order ${rowData.delivery_order_id ? 'D100' + rowData.delivery_order_id : "S300" + rowData.exchange_order_id}`}>
                            <Button
                                disabled={props.portal}
                                color="primary"
                                variant='outlined'
                                size='small'
                                onClick={() => {
                                    if (rowData.delivery_order_id){
                                        openDeliveryOrder(rowData)
                                    } else {
                                        openExchangeOrder(rowData)
                                    }
                                }}
                            >
                                {rowData.delivery_order_id ? 'D100' + rowData.delivery_order_id : "S300" + rowData.exchange_order_id}
                            </Button>
                        </Tooltip>

                        {rowData.delivery_completed_date !== null && rowData.exchange_type !== "Reclaimed" && <div style={{paddingTop: 4}}>
                            {moment(rowData.delivery_completed_date).format('LL')}
                        </div>}

                        {rowData.exchange_completed_date !== null && <div style={{paddingTop: 4}}>
                            {moment(rowData.exchange_completed_date).format('LL')}
                        </div>}
                    </div>
                } else {
                    return <Link>
                        {rowData.delivery}
                    </Link>
                }
            }
        },
        {
            title: tableColumnTitles.pickup, field: 'pickup', width: 40,
            render: rowData => {
                if (rowData.pickup_status === 'Processing') {
                    return <div>
                        <Tooltip title={`Open order ${"P200" + rowData.pickup_order_id}`}>
                            <Button disabled={props.portal} color="primary" variant='outlined' size='small'
                                    onClick={() => openPickupOrder(rowData)}
                            >
                                {"P200" + rowData.pickup_order_id}
                            </Button>
                        </Tooltip>
                        <div style={{paddingTop: 4}}>
                            Status: Processing
                        </div>
                    </div>
                } else if (rowData.pickup_status === 'Open') {
                    return <div>
                        <Tooltip title={`Open order ${"P200" + rowData.pickup_order_id}`}>
                            <Button disabled={props.portal} color="primary" variant='outlined' size='small'
                                    onClick={() => openPickupOrder(rowData)}
                            >
                                {"P200" + rowData.pickup_order_id}
                            </Button>
                        </Tooltip>
                        <div style={{paddingTop: 4}}>
                            Status: Created
                        </div>
                    </div>
                } else if (rowData.pickup_status === 'Completed') {
                    return <span>
                        <Tooltip title={`Open order ${"P200" +rowData.pickup_order_id}`}>
                            <Button disabled={props.portal} color="primary" variant='outlined' size='small'
                                    onClick={() => openPickupOrder(rowData)}>{"P200" + rowData.pickup_order_id}</Button>
                        </Tooltip>
                        {rowData.pickup_completed_date !== null && <div style={{paddingTop: 4}}>
                            {moment(rowData.pickup_completed_date).format('LL')}
                        </div>
                        }
                    </span>
                } else if (rowData.exchange_status === 'Completed' && rowData.exchange_type === 'Reclaimed') {
                    return <span>
                        <Tooltip title={`Open order ${"S300" +rowData.exchange_order_id}`}>
                            <Button disabled={props.portal} color="primary" variant='outlined' size='small'
                                    onClick={() => openExchangeOrder(rowData)}>{"S300" + rowData.exchange_order_id}</Button>
                        </Tooltip>
                        {rowData.exchange_completed_date !== null && <div style={{paddingTop: 4}}>
                            {moment(rowData.exchange_completed_date).format('LL')}
                        </div>
                        }
                    </span>
                }
            }
        },
        {
            title: tableColumnTitles.status, field: 'status', width: 40,
            render: rowData => {
                if (rowData.pickup_status === 'Completed') {
                    return <div>
                        Picked Up
                    </div>
                } else if (rowData.exchange_status === 'Completed' && rowData.exchange_type === 'Reclaimed'){
                    return <div>
                        Picked Up
                    </div>
                } else {
                    return <div>
                        Delivered
                    </div>
                }
            }
        },
    ];
    const previewExisting = async (file) => {
        try {
            const token = JSON.parse(localStorage.getItem("key"));
            const promise = await fetch(endpoints.getUploadItemResource, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": "Bearer " + token.accessToken,
                },
                body: JSON.stringify({
                    fileName: file
                })
            });
            const result = await promise.json();
            return result.data
        } catch (error) {
            // handle error
        }


    };
    useEffect(()=> {
        async function check(){
            let res = await checkFeatures('ICD10Codes','Hospice', props.patientInfo.hospice_organization_id);
            setShowIcdCodes(res);
        }
        check();

    }, [props.patientInfo.hospice_organization_id])


    if (props.patientInfo) {
        return (
            <Grid container className={classes.container} id={'print'}>
                <Grid item xs={12} className={classes.flexStart}>
                    <Typography className={classes.h1} style={{width:'100%'}}>
                        PATIENT PROFILE
                    </Typography>
                    <Grid container justify={'flex-end'} alignContent={'flex-end'} alignItems={'flex-end'} style={{paddingBottom:8}} spacing={1}>
                        <Grid item>
                            <InventoryQuickView items={quickViewItems}/>
                        </Grid>
                        <Grid item>
                        <Button
                            data-html2canvas-ignore="true"
                            variant={'outlined'}
                            color={'primary'}
                            onClick={()=> printProfile(activeItems,allItems,props.patientOrders, props.patientInfo.patient_notes)}
                            startIcon={<PrintOutlinedIcon style={{marginRight:8}}/>}
                            className={classes.buttonGrey}
                        >
                            Print
                        </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justify={"flex-start"} alignItems={"flex-start"} direction={"row"}
                      className={classes.secondContainer}>
                    <Grid item xs={12} className={classes.top}>
                        <div className={classes.topBox}>
                            <Grid container>
                                <Grid item>
                                <Typography className={classes.name}>
                                    {`${props.patientInfo.first_name} ${props.patientInfo.mi ? props.patientInfo.mi : ""} ${props.patientInfo.last_name}`}
                                </Typography>
                                </Grid>
                                <Grid item>
                                <Typography className={classes.patientId}>
                                    {`#${props.patient_id}`}
                                </Typography>
                                </Grid>
                                {!props.portal && checkPermissions([permissions.update_patients]) && (
                                    <Grid item data-html2canvas-ignore="true">
                                        <Tooltip title={'Edit patient'}>
                                            <Button
                                                size="small"
                                                style={{cursor: 'pointer'}}
                                                onClick={() => {
                                                    history.push({pathname: '/patients/edit', patient_id: props.patient_id})
                                                }}
                                            >
                                                <img
                                                    src={EditCustomIcon}
                                                    alt="logo"
                                                    width='22'
                                                    height='22'
                                                    style={{cursor: 'pointer'}}
                                                />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}
                                {!props.portal && props.patientInfo && props.patientInfo.infectious_protocol && props.patientInfo.infectious_protocol.length > 0 && <Grid item data-html2canvas-ignore="true">
                                    <IconAlerts iconType={IconAlertTypes.disease}
                                                data={props.patientInfo.infectious_protocol}/>
                                </Grid>}
                                {!props.portal && props.patientInfo && props.patientInfo.patient_notes && props.patientInfo.patient_notes.length > 0 && <Grid item data-html2canvas-ignore="true">
                                    <IconAlerts iconType={IconAlertTypes.notes} action={IconAlertActions.anchor}
                                                data={[notesSection]}/>
                                </Grid>}
                                {!props.portal && props.patientInfo && props.patientInfo.prescription_required && props.patientInfo.prescription_required === true && <Grid item data-html2canvas-ignore="true">
                                    <IconAlerts iconType={IconAlertTypes.prescription} action={IconAlertActions.anchor}
                                                data={props.patientInfo.prescription_required}/>
                                </Grid>}
                            </Grid>
                            <div className={classes.flex}>
                                <Typography className={classes.medicalRecord}>
                                    {`Medical Record: #${props.patientInfo.medical_record}`}
                                </Typography>
                            </div>
                            <div className={classes.flex}>
                                <Typography className={classes.medicalRecord}>
                                    {`Created By: ${props.patientInfo.user_creator} on ${moment(props.patientInfo.created_date).format('MM/DD/YYYY')}`}
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} className={classes.patientInfo}>
                        <Grid container>
                            <Grid item xs={12} className={classes.flex}>
                                <Typography className={classes.sectionTitle}>Patient Information</Typography>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <DisabledInput label={props.patientInfo.is_organization_type === true ? "Organization" : 'Hospice'} value={props.patientInfo.hospice_name}
                                                   width={'190px'}/>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <DisabledInput label='Nurse' value={props.patientInfo.nurse_name} width={'190px'}/>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <DisabledInput label='Care Setting' value={props.patientInfo.care_setting === "All" ? "-" : props.patientInfo.care_setting}
                                                   width={'190px'}/>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <DisabledInput label='Facility'
                                                   value={props.patientInfo.care_setting === "Facility" ? (props.patientInfo.facility_name ? props.patientInfo.facility_name : "-")
                                                       : "-"  }
                                                   width={'190px'}/>
                                </Grid>
                                <Grid item lg={4} md={6} sm={6} xs={12}>
                                    <DisabledInput label='Phone Number'
                                                   value={props.patientInfo.phone_number !== null ? formatNumber(props.patientInfo.phone_number) : "-"}
                                                   width={'190px'}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} style={{padding: 16, display: 'flex', paddingBottom: 12}}>
                        <Grid container>
                            <Grid item xs={12} style={{display: 'flex'}}>
                                <Typography style={{fontWeight: 600, fontSize: 18, color: "#1A1818",}}>Caregiver
                                    Information</Typography>
                            </Grid>
                            {props.caregivers && props.caregivers.map((caregiver, index) => {
                                return (
                                    <Grid container spacing={1} key={index}>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <DisabledInput label='Name' value={caregiver.caregiver_first_name + " " + caregiver.caregiver_last_name}
                                                           width={'100%'}/>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <DisabledInput label='Phone Number' value={formatNumber(caregiver.caregiver_phone_number)}
                                                           width={'100%'}/>
                                        </Grid>
                                        <Grid item lg={4} md={6} sm={6} xs={12}>
                                            <DisabledInput label='Relation' value={caregiver.caregiver_relation}
                                                           width={'100%'}/>
                                        </Grid>
                                        { checkPermissions([permissions.portal_create_careviger_account])
                                        && portalFeature === true &&
                                        <Grid item xs={12} style={{display:'flex'}} data-html2canvas-ignore="true">
                                            {caregiver.has_account ? (<span style={{paddingLeft:8, paddingBottom:12}}>{caregiver.caregiver_first_name + " " + caregiver.caregiver_last_name} has already portal account</span>) : (
                                            <Button
                                                variant='outlined'
                                                color='primary'
                                                onClick={()=> {
                                                    history.push({
                                                        pathname: '/admin/portaluser',
                                                        state: {  hospice_organization_id:props.patientInfo.hospice_organization_id,
                                                            region_id:props.patientInfo.region_id,
                                                            team_id:props.patientInfo.team_id,
                                                            first_name:caregiver.caregiver_first_name,
                                                            last_name: caregiver.caregiver_last_name,
                                                            type: `Caregiver${index === 0 ? 'Primary' : ''}${index === 1 ? 'Secondary' : ''}${index === 2 ? 'Tertiary' : ''}`,
                                                            phone_number: caregiver.caregiver_phone_number,
                                                            patient_id: props.patientInfo.patient_id,
                                                            caregiver_id:caregiver.caregiver_id,
                                                        }
                                                    })
                                                }}
                                            >Create portal access</Button>)}
                                        </Grid>
                                        }

                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} style={{padding: 16, display: 'flex', paddingBottom: 12}}>
                        <Grid container>
                            <Grid item xs={12} style={{display: 'flex'}}>
                                <Typography style={{fontWeight: 600, fontSize: 18, color: "#1A1818",}}>Patient
                                    Demographics</Typography>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item lg={3} md={5} sm={6} xs={12}>
                                    <DisabledInput label='Birth Date'
                                                   value={moment(props.patientInfo.date_of_birth).format("L")}
                                                   width={'100%'}/>
                                </Grid>
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                    <DisabledInput label='Sex' value={props.patientInfo.gender} width={'100%'}/>
                                </Grid>
                                <Grid item lg={2} md={4} sm={6} xs={12}>
                                    <DisabledInput label='Height' value={props.patientInfo.patient_height_in_feet}
                                                   width={'100%'}/>
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    <DisabledInput label='Weight' value={props.patientInfo.patient_weight}
                                                   width={'100%'}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12} style={{padding: 16, display: 'flex', paddingBottom: 12, flexDirection: 'column'}}>
                        <Grid container>
                            <Grid item xs={12} style={{display: 'flex'}}>
                                <Typography
                                    style={{fontWeight: 600, fontSize: 18, color: "#1A1818",}}>Address</Typography>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item lg={5} md={6} sm={6} xs={12}>
                                    <DisabledInput label='Address'
                                                   value={`${props.patientInfo.address1} ${props.patientInfo.address2 ? props.patientInfo.address2 : ""}`}
                                                   width={'100%'}/>
                                </Grid>
                                <Grid item lg={3} md={6} sm={6} xs={12}>
                                    <DisabledInput label='City' value={props.patientInfo.city} width={'100%'}/>
                                </Grid>
                                <Grid item lg={2} md={6} sm={6} xs={12}>
                                    <DisabledInput label='State' value={props.patientInfo.state} width={'100%'}/>
                                </Grid>
                                <Grid item lg={2} md={6} sm={6} xs={12}>
                                    <DisabledInput label='ZIP' value={props.patientInfo.zip} width={'100%'}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container style={{marginTop: 5}}>
                            <AddressHistory historyAddress={historyAddress} />
                        </Grid>
                    </Grid>
                    {!props.portal && (
                        <Grid item lg={2} md={3} sm={6} xs={6} style={{padding: 16, display: 'flex', paddingBottom: 12}} >
                        <Grid container ref={notesSection}> {/*ref must be here because the nav will overlap the notes section if the ref is placed on the notes section*/}
                            <Grid item xs={12} style={{display: 'flex'}}>
                                <Typography style={{fontWeight: 600, fontSize: 18, color: "#1A1818",}}>Change
                                    Status</Typography>
                            </Grid>
                            <Grid container spacing={1}>
                                <Grid item sm={9} xs={9}
                                      style={{zIndex: 10}}
                                >
                                    <Select
                                        placeholder="Patient Status"
                                        value={{value: data.status, label: data.status}}
                                        options={statusOptions}
                                        onChange={(event => updatePatientStatus(event))}
                                        name="patient_status"
                                    />
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>
                    )}
                    {/*<Grid item lg={2} md={3} sm={6} xs={6} style={{padding: 16, display: 'flex', paddingBottom: 12}}>*/}
                    {/*    {!props.portal && (*/}
                    {/*    <Grid container>*/}
                    {/*        <Grid item xs={12} style={{display: 'flex'}}>*/}
                    {/*            <Typography*/}
                    {/*                style={{fontWeight: 600, fontSize: 18, color: "#1A1818",}}>Archived</Typography>*/}
                    {/*        </Grid>*/}
                    {/*        <Grid container spacing={1}>*/}
                    {/*            <Grid item xs={12} style={{display: 'flex', paddingLeft: 0, alignItems: 'center'}}>*/}
                    {/*                <FormControl component="fieldset">*/}
                    {/*                    <FormGroup>*/}
                    {/*                        <FormControlLabel style={{marginRight: 0}} control={*/}
                    {/*                            <Switch checked={isArchived}*/}
                    {/*                                    onChange={() => updateArchivedStatus()}*/}
                    {/*                                    name="status"*/}
                    {/*                                    color="primary"*/}
                    {/*                            />}*/}
                    {/*                        />*/}
                    {/*                    </FormGroup>*/}
                    {/*                </FormControl>*/}
                    {/*                <FormHelperText>*/}
                    {/*                    {getMessage(messageTypes.archivedPatient, isArchived)}*/}
                    {/*                </FormHelperText>*/}
                    {/*            </Grid>*/}
                    {/*        </Grid>*/}
                    {/*    </Grid>*/}
                    {/*    )}*/}
                    {/*</Grid>*/}
                    <Grid item xs={12}></Grid>
                    {!props.portal && (
                        <Grid item sm={6} xs={12} style={{paddingLeft:16}} ref={infectiousDiseasesSection}> {/*ref must be here because the nav will overlap the diseases section if the ref is placed on the diseases section*/}
                            <AccordionCSS data-html2canvas-ignore="true">
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                    className={classes.header}
                                >
                                    <Typography className={classes.heading}>Patient Notes</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{paddingLeft: 0, paddingRight: 0}}>
                                    <Grid container>
                                        {props.patientInfo?.patient_notes ? props.patientInfo.patient_notes.map((note, index)=> {
                                            return (
                                                <Grid item xs={12} key={index} className={classes.notesSection}>
                                                        <div style={{color:'#2E3236', wordBreak: 'break-word'}} className={classes.bold}>{note.description}</div>
                                                        <div style={{
                                                            fontStyle: 'italic',
                                                            color: "#5D6770"
                                                        }}>{note.first_name + " " + note.last_name + ", " + moment(note.created_date).utc().format('LLL')}</div>
                                                </Grid>
                                            )
                                        }): <div className={classes.status}>
                                            <span className={classes.bold}>Patient does not have notes.</span>
                                        </div>}
                                    </Grid>
                                </AccordionDetails>
                            </AccordionCSS>
                        </Grid>
                    )}
                    <Grid item xs={12} data-html2canvas-ignore="true">
                        { checkPermissions([permissions.portal_create_patient_account]) && portalFeature === true &&
                        <Grid item xs={12} style={{display:'flex', padding:16}}>
                            {props.patientInfo.has_account ? (<span style={{paddingLeft:8, paddingBottom:12}}>{props.patientInfo.first_name + " " + props.patientInfo.last_name} has already portal account</span>) : (
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={()=> {
                                        history.push({
                                            pathname: '/admin/portaluser',
                                            state: {  hospice_organization_id:props.patientInfo.hospice_organization_id,
                                                region_id:props.patientInfo.region_id,
                                                team_id:props.patientInfo.team_id,
                                                first_name:props.patientInfo.first_name,
                                                last_name: props.patientInfo.last_name,
                                                type: "Patient",
                                                phone_number: props.patientInfo.phone_number,
                                                patient_id:props.patientInfo.patient_id
                                            }
                                        })
                                    }}
                                >Create Patient Portal Account</Button>)}
                        </Grid>
                        }
                    </Grid>
                    {
                        !props.portal === true && (
                            <InfectiousDiseasesComponent
                                 patientId={data.patient_id}
                            />
                        )
                    }
                    {showIcdCodes && props.patientInfo.icd_codes.length > 0 && (
                        <Grid container style={{maxWidth:570, paddingLeft:16}}>
                            <Grid item xs={12} style={{fontSize:18, fontWeight:600, textAlign:'left', paddingTop:16, paddingLeft:8}}>ICD 10 Codes</Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    {props.patientInfo.icd_codes.map((item, idx) =>{
                                        return (
                                            <Grid item xs={12} style={{display:'flex',justifyContent:'space-between',  alignItems:'center', alignContent:'center', background:'whitesmoke', borderRadius:4, margin:4,marginTop:idx === 0 ? 8 : 0, marginBottom:8, padding:8, paddingTop:8}} key={idx}>
                                                <Grid item style={{fontSize:15, paddingLeft:8, textAlign:'left'}}>
                                                    {item.label}
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {!props.portal && checkPermissions([permissions.create_orders]) && (
                    <Grid item sm={12} xs={12} style={{padding: 16, display: 'flex', paddingBottom: 80}} data-html2canvas-ignore="true">
                        <Grid container justify="flex-end" align='center' spacing={1}
                              style={{paddingBottom: 10, paddingTop: 8}}>
                            <Grid item>
                                {(data.status === "Active" || ([11, 15].includes(Number(data.hospice_organization_id)))) ?
                                    <CustomPackageOrders hospiceId={data.hospice_organization_id} hospiceRegionId={data.region_id} patientId={data.patient_id} patientData={data} getPatientOrders={props.getPatientOrders}/>
                                    :
                                    <Button
                                        variant="contained"
                                        disabled
                                        style={{
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Custom DME Package
                                    </Button>
                                }
                            </Grid>
                            {/*<Grid item>*/}
                            {/*    <PackageOrders hospiceId={data.hospice_organization_id} hospiceRegionId={data.region_id} patientId={data.patient_id} patientData={data} getPatientOrders={props.getPatientOrders}/>*/}
                            {/*</Grid>*/}
                            {isMobile ? (
                                <Grid item>
                                    <NewRequest
                                        user={data}
                                        getPatientOrders={props.getPatientOrders}
                                        isPace={props.patientInfo?.ispace}
                                        address={address}
                                        name={name}
                                        autoPickupTrigger={autoPickupTrigger}
                                        autoPickupReason={autoPickupReason}
                                    />
                                </Grid>
                            ) : (
                                <React.Fragment>
                                    <Grid item>
                                        {(data.status === "Active" || ([11, 15].includes(Number(data.hospice_organization_id)))) ?
                                            <CreateOrder renderButton={true} openOrder={false} user={data} getPatientOrders={props.getPatientOrders} isPace={props.patientInfo?.ispace}/>
                                            :
                                            <Button
                                                variant="contained"
                                                disabled
                                                size="small"
                                                startIcon={<img
                                                    src={CartIcon}
                                                    alt="logo"
                                                    width='16'
                                                    height='15'
                                                    // style={{marginLeft: '-2px'}}
                                                />}
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: 'bold',
                                                    height: '40px',
                                                    // width: '136px',
                                                }}
                                            >
                                                NEW DELIVERY
                                            </Button>
                                        }
                                    </Grid>
                                    <CheckInventory patient_id={data.patient_id} width={true}>
                                        <Grid item>

                                            <RequestPickup
                                                openOrder={false}
                                                showPickup={true}
                                                disabled={(data.status === "Active" || ([11, 15].includes(Number(data.hospice_organization_id))))}
                                                name={name}
                                                address={address}
                                                patient_id={data.patient_id}
                                                getPatientOrders={props.getPatientOrders}
                                            />
                                            <RequestPickup
                                                openOrder={false}
                                                showPickup={false}
                                                name={name}
                                                autoTrigger={autoPickupTrigger}
                                                address={address}
                                                reason={autoPickupReason}
                                                patient_id={data.patient_id}
                                                getPatientOrders={props.getPatientOrders}
                                            />
                                        </Grid>
                                        <Grid item>
                                            {(data.status === "Active" || ([11, 15].includes(Number(data.hospice_organization_id)))) ?
                                                <RequestExchange
                                                    openOrder={false}
                                                    name={name}
                                                    address={address}
                                                    patientId={data.patient_id}
                                                    hospice_id={data.hospice_organization_id}
                                                    region_id={data.region_id}
                                                    getPatientOrders={props.getPatientOrders}
                                                />
                                                :
                                                <Button
                                                    variant="contained"
                                                    disabled
                                                    size="small"
                                                    startIcon={<img
                                                        src={ToolIcon}
                                                        alt="logo"
                                                        width='16'
                                                        height='15'
                                                    />}
                                                    style={{
                                                        fontSize: 12,
                                                        fontWeight: 'bold',
                                                        height: '40px',
                                                        width: '160px',
                                                    }}
                                                >
                                                    NEW EXCHANGE
                                                </Button>
                                            }
                                        </Grid>
                                    </CheckInventory>
                                </React.Fragment>

                            )}
                        </Grid>

                    </Grid> )}
                </Grid>
                <Dialog open={previewModal} fullWidth maxWidth={'md'} onClose={()=> {
                    setPreviewModal(false);
                    setActivePreview('')
                }}>
                    <Grid container style={{paddingLeft:16, paddingRight:16, minHeight:240}} justify={'center'} alignItems={'center'} alignContent={'center'}>
                        {itemResourceLoading ? <CircularProgress /> : (
                            resourcesList.length === 0 ? 'No resources found' : (
                                resourcesList.map(resource => {
                                    if (resource.type === 'video'){
                                            let videoId = resource.video_url.split("/");
                                            videoId = videoId[videoId.length-1];
                                        return (
                                            <Grid container className="container" >
                                                <div style={{paddingTop:16, paddingBottom:8, fontWeight:'bold', fontSize:16}}>{resource.title}</div>
                                                <div style={{
                                                    position: 'relative',
                                                    overflow: 'hidden',
                                                    width: '100%',
                                                    paddingTop: '56.25%'
                                                }}>
                                                    <iframe className="responsive-iframe" src={`https://player.vimeo.com/video/${videoId}`} style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        left: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                            frameBorder="0" allow="autoplay; fullscreen" allowFullScreen />
                                                </div>
                                                <div style={{paddingTop:8, paddingBottom:16, color:'gray',fontSize:16}}>{resource.description}</div>


                                            </Grid>

                                        )
                                    }
                                    if (resource.type === 'image'){
                                        return (
                                            <Grid container>
                                                <Grid xs={12}>
                                                    <div style={{paddingTop:16, paddingBottom:8, fontWeight:'bold', fontSize:16}}>{resource.title}</div>

                                                    <img src={resource.url} style={{maxWidth: '100%',
                                                        height: 'auto'}} />
                                                    <div style={{
                                                        paddingTop:8,
                                                        paddingBottom:16,
                                                        color:'gray',
                                                        fontSize:16
                                                    }}>
                                                        {resource.description}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                     if (resource.type === 'document'){
                                         return (
                                             <Grid container>
                                                 <Grid xs={12}>
                                                     <div style={{paddingTop:16, paddingBottom:8, fontWeight:'bold', fontSize:16}}>{resource.title}</div>
                                                     <div>
                                                         <span style={{paddingRight:8, fontStyle: 'italic'}}>{resource.file_name}</span>
                                                         <a href={resource.url} target="_blank" style={{textDecoration:'none', marginRight:8}}>
                                                             <Button variant={'outlined'} color={'primary'} size={'small'}
                                                                     startIcon={<VisibilityIcon />}
                                                             >
                                                                 Preview file
                                                             </Button>
                                                         </a>
                                                         <a href={resource.url} download style={{textDecoration:'none'}}>
                                                             <Button variant={'outlined'} color={'primary'} size={'small'}
                                                                     startIcon={<CloudDownloadIcon/>}
                                                             >
                                                                 Download file
                                                             </Button>

                                                         </a>
                                                     </div>
                                                     <div style={{
                                                         paddingTop:8,
                                                         paddingBottom:16,
                                                         color:'gray',
                                                         fontSize:16
                                                     }}>
                                                         {resource.description}
                                                     </div>
                                                 </Grid>
                                             </Grid>
                                         )
                                     }
                                })
                            )
                        )}
                    </Grid>
                </Dialog>
                <Grid item xs={12}
                      style={{
                          paddingTop: 12, width: '50vw',
                      }}
                >
                    <Grid data-html2canvas-ignore="true" className={'general_table'} item style={{overflowX: 'scroll', border: '1px solid #F0F0F0'}}>
                        <MaterialTable
                                title={componentTitles.currentDmeInventory}
                                columns={columnsActive}
                                data={activeItems}
                                component={{
                                    Container: props => <Paper {...props} elevation={0}/>
                                }}
                                components={{
                                    Toolbar: props => (
                                        <div style={{backgroundColor: '#F2F6F7', fontWeight: 'bold'}}>
                                            <MTableToolbar {...props} />
                                        </div>

                                    ),
                                    Container: props => <Paper {...props} elevation={0}/>,
                                    FilterRow: () => <tr/>,
                                }}
                                icons={{
                                    Filter: () => <tr/>,
                                    SortArrow: forwardRef((props, ref) => <ArrowDownward
                                        style={{color: "#77A6BC"}} {...props} ref={ref}/>),
                                }}
                                // isLoading={isLoading}
                                options={{
                                    paging:false,
                                    maxBodyHeight: 320,
                                    rowStyle: {backgroundColor: '#FFF'},
                                    searchText: '',
                                    emptyRowsWhenPaging: false,
                                    toolbar: true,
                                    filterCellStyle: {
                                        visibility: "hidden", width: '0px'
                                    },
                                    showTitle: true,
                                    filtering: true,
                                    showEmptyDataSourceMessage: true,
                                    headerStyle: {
                                        backgroundColor: '#FFF',
                                        color: '#000000',
                                        height: 20,
                                        fontWeight: 'bold',
                                        '&:hover': {color: '#FFF'}
                                    },
                                }}
                            />
                    </Grid>
                </Grid>
                {!props.portal && (<Grid item xs={12} data-html2canvas-ignore="true"
                      style={{
                          paddingTop: 12, width: '50vw',
                      }}
                >
                    <Grid className={'general_table'} item style={{overflowX: 'scroll', border: '1px solid #F0F0F0'}}>
                        {/*<div style={{width:2000, height:100, backgroundColor:'grey'}}>*/}

                        {/*</div>*/}
                        <MaterialTable
                            title={"Patient Orders"}
                            columns={orderColumns}
                            data={props.patientOrders}
                            component={{
                                Container: props => <Paper {...props} elevation={0}/>
                            }}
                            components={{
                                Toolbar: props => (
                                    <div style={{backgroundColor: '#F2F6F7', fontWeight: 'bold'}}>
                                        <MTableToolbar {...props} />
                                    </div>

                                ),
                                Container: props => <Paper {...props} elevation={0}/>,
                                FilterRow: () => <tr/>,
                            }}
                            icons={{
                                Filter: () => <tr/>,
                                SortArrow: forwardRef((props, ref) => <ArrowDownward
                                    style={{color: "#77A6BC"}} {...props} ref={ref}/>),
                            }}
                            // isLoading={isLoading}
                            options={{
                                paging:false,
                                maxBodyHeight:340,
                                rowStyle: {backgroundColor: '#FFF'},
                                searchText: '',
                                emptyRowsWhenPaging: false,
                                toolbar: true,
                                filterCellStyle: {
                                    visibility: "hidden", width: '0px'
                                },
                                showTitle: true,
                                filtering: true,
                                showEmptyDataSourceMessage: true,
                                headerStyle: {
                                    backgroundColor: '#FFF',
                                    color: '#000000',
                                    height: 20,
                                    fontWeight: 'bold',
                                    '&:hover': {color: '#FFF'}
                                },
                            }}
                        />
                    </Grid>
                </Grid>)}

                <Grid item xs={12}
                      style={{
                          paddingTop: 48, width: '50vw',
                          // display:'flex', justifyContent:"center"
                      }}
                      data-html2canvas-ignore="true"
                >
                    <Grid className={'general_table'} item style={{overflowX: 'scroll', border: '1px solid #F0F0F0'}}>
                        <MaterialTable
                            title={componentTitles.transactionalHistory}
                            columns={columnsAll}
                            data={allItems}
                            component={{
                                Container: props => <Paper {...props} elevation={0}/>
                            }}
                            components={{
                                Toolbar: props => (
                                    <div style={{backgroundColor: '#F2F6F7', fontWeight: 'bold'}}>
                                        <MTableToolbar {...props} />
                                    </div>
                                ),
                                Container: props => <Paper {...props} elevation={0}/>,
                                FilterRow: () => <tr/>,
                            }}
                            icons={{
                                Filter: () => <tr/>,
                                SortArrow: forwardRef((props, ref) => <ArrowDownward
                                    style={{color: "#77A6BC"}} {...props} ref={ref}/>),
                            }}
                            // isLoading={isLoading}
                            options={{
                                paging:false,
                                maxBodyHeight:320,
                                rowStyle: {backgroundColor: '#FFF'},
                                searchText: '',
                                emptyRowsWhenPaging: false,
                                toolbar: true,
                                filterCellStyle: {
                                    visibility: "hidden", width: '0px'
                                },
                                // searchFieldStyle: {
                                //     visibility:"hidden", width:'0px'
                                // },
                                showTitle: true,
                                filtering: true,
                                showEmptyDataSourceMessage: true,
                                headerStyle: {
                                    backgroundColor: '#FFF',
                                    color: '#000000',
                                    height: 20,
                                    fontWeight: 'bold',
                                    '&:hover': {color: '#FFF'}
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <UserCounter endpoint={endpointToTrack} timeOfRender={moment().subtract(15, "minutes")} ignoreEndpoints={endpointsToIgnore}/>
            </Grid>
        );
    } else {
        return (
            <div className='loadingContainer'>
                <h1 className='loading'>
                    {getMessage(messageTypes.noPatientData, props.patient_id)}
                </h1>
            </div>
        );
    }
}

const DisabledInput = (props) => {
    return (
        <div>
            <div style={{
                marginBottom: -9, zIndex: 2,
                position: 'relative',
                width: props.width ? props.width : '100%',
                marginLeft: '5px',
                display: 'flex',
                justifyContent: 'flex-start'
            }}>
                <span
                    style={{
                        backgroundColor: 'white',
                        paddingLeft: 6,
                        paddingRight: 6,
                        marginLeft: 2,
                        fontSize: 12,
                        color: '#979797'
                    }}
                >
                    {props.label}
                </span>
            </div>

            <div
                style={{
                    height: 34,
                    // width:200,
                    zIndex: 1,
                    display: 'flex',
                    fontSize: 14,
                    justifyContent: 'flex-start',
                    border: '1px solid #F0F0F0',
                    borderRadius: '2px',
                    paddingTop: 10,
                    paddingLeft: 12,
                    paddingRight: 12,
                    lineHeight: '19px',
                    paddingBottom: 3,
                }}
            >
                {props?.value?.length > 20 ?
                    <Tooltip title={props.value}>
                        <span style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",}}
                        >{props.value}</span>
                    </Tooltip> : <span style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",}}
                    >{props.value}</span>
                }


            </div>
        </div>
    )
};

const AccordionCSS = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const useStyles = makeStyles((theme) => (patientDataStyle(theme)));
