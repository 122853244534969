import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import PatientDemographics from './PatientDemographics';
import CaregiverInformation from './CaregiverInformation';
import Other from './Finalize';
import {useDispatch, useSelector} from 'react-redux';
import './add-new-patient.css'
import {useHistory} from "react-router-dom";
import CancelActionAlert from "../../../common/alerts/cancelActionAlert/cancelActionAlert";
import {alertLevel, cancellingAlertFlags} from "../../../constants/alert/alertFlags";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {patientRoutes} from "../../../constants/routes/patients";
import {endpoints} from "../../../constants/endpoints";
import {sectionTitles} from "../../../constants/names/titles";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import {getMessage} from "../../../constants/messages/messages";
import {messageTypes} from "../../../constants/types/messageTypes";
import {tooltips} from "../../../constants/inputs/tooltips";
import {UserCounter} from "../../../common/audit/PageUserCounter";
import moment from "moment";
import {getNurses} from "../../../common/regions/RegionHelper";
import jwt from "jwt-decode";
import _ from "lodash";
import {
    createPatient, getDmes,
    getHospiceFacilities,
    getHospiceOrganizations,
    getHospiceRegions,
    getHospiceTeams, getMissionFieldMessages,
    getPatientData,
    updatePatientCaregivers,
    updatePatientDemographics,
    updatePatientDiseasesAndIcdCodes,
    updatePatientPrescription,
    updatePatientNotes
} from "./patientHelper";
import {status} from "../../../constants/status/status";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import {AlertDialog} from "../../../common/alerts/generalAlert/generalAlert";
import {buttonTypes} from "../../../constants/alert/buttonTypes";
import {alertTitles} from "../../../constants/alert/alertTitle";
import {alertMessages, snackbarMessages} from "../../../constants/alert/alertMessages";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useSnackbar} from "notistack";
import {notifySnack} from "../../../common/notifications/apiStatusNotification";
import {checkFeatures} from "../../../common/checkFeatures/checkFeatures";
import {checkPermissions} from "../../../common/permissions/checkPermissions";
import {permissions} from "../../../constants/permissions/permissions";
import {PortalAccounts} from "./PortalAccounts";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
            maxWidth: 1200,
            marginLeft: 'auto',
            marginRight: 'auto'
        },
    },
    paper: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        display: "flex",
        width: '100%',
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        width: '100%',
        marginTop: theme.spacing(2),
        fontWeight: "bold",
    },
}));

export default function AddNewPatient(props) {

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const userData = jwt(JSON.parse(localStorage.getItem("key")).accessToken).payload;
    const token = JSON.parse(localStorage.getItem('key'));
    const edit = props ? props.location.patient_id : "";
    let history = useHistory();
    const dispatch = useDispatch();
    const isLogged = useSelector(state => state.isLogged);
    const classes = useStyles();
    const [possibleDuplicates, setPossibleDuplicates] = useState([]);
    const [activeStep, setActiveStep] = React.useState(0);
    const [redirectAlert, setRedirectAlert] = useState(false);
    const [placeOrder, setPlaceOrder] = useState(false);
    const [firstStep, setFirstStep] = useState(false);
    const [secondStep, setSecondStep] = useState(false);
    const [entered, setEntered] = useState(false);
    const [hardValidation, setHardValidation] = useState(false);
    const [showPossibleDuplicates, setShowPossibleDuplicates] = useState(false);
    const [patientId, setPatientId] = useState();
    const [showThirdCaregiver, setShowThirdCaregiver] = useState(false);
    const [hospiceData, setHospiceData] = useState([]);
    const [hospiceList, setHospiceList] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const [regionList, setRegionList] = useState([]);
    const [nurses, setNurses] = useState([]);
    const [facilitiesData, setFacilitiesData] = useState([]);
    const [teamsData, setTeamsData] = useState([]);
    const [nursesOptions, setNursesOptions] = useState([]);
    const [wasSuccessful, setWasSuccessful] = useState();
    const [invalidMedicalRecord, setInvalidMedicalRecord] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [initialLoading, setInitialLoading] = useState(true);
    const [portalAccess, setPortalAccess] = useState(false);
    const [showIcdCodes, setShowIcdCodes] = useState(false);
    const [dmeProvidersList, setDmeProvidersList] = useState([]);
    const [dmeData, setDmeData] = useState([]);
    const [selectedDme, setSelectedDme] = useState(userData.dme_organization_id);
    const [validContract, setValidContract] = useState(false);
    const [emptyNote,setEmptyNote] = useState(false);
    const [medicalRecordFeature, setMedicalRecordFeature] = useState(false);

    const [steps, setSteps] = useState([
        sectionTitles.patientDemographics,
        sectionTitles.caregiverInformation,
        sectionTitles.patientDocumentation
    ]);
    const [validType, setValidType] = useState({
        medical_record: true, first_name: true, last_name: true, MI: true, zip: true,
        city: true, state: true, patient_weight: true, patient_height_in_feet: true,
        patient_height_in_inches: true, caregiver_full_name_primary: true, caregiver_phone_number_primary: true,
        caregiver_first_name_primary: true, caregiver_last_name_primary: true,
    });
    const endpointToTrack = `/api/patients/${edit}`;
    const endpointsToIgnore = [`api/patients/notes/${edit}`, `api/patients/caregivers/${edit}`, `api/patients/diseases/${edit}`];
    const [zipStatus, setZipStatus] = useState(0);
    const [patient, setPatient] = React.useState({
        medical_record: '',
        first_name: '',
        last_name: '',
        MI: '',
        phone_number: '',
        care_setting: 'All',
        team_id: null,
        facility: 'All',
        date_of_birth: null,
        gender: 'All',
        patient_weight: '',
        hospice_organization_id: userData.hospice_organization_id ? userData.hospice_organization_id : null,
        region_id: (userData.region_id && userData.hospice_organization_id) ? userData.region_id : null,
        patient_height_in_feet: '',
        patient_height_in_inches: '',
        infectious_disease_protocol: {
            contact: false,
            droplet: false,
            respiratory: false,
            covid_19: false,
            other: false,
            other_disease: ''
        },
        prescription_required: false,
        icd_codes: [],
        note: [],
        patient_address1: '',
        patient_address2: '',
        zip: '',
        city: '',
        state: '',
        caregiver_full_name_primary: '',
        caregiver_first_name_primary: '',
        caregiver_last_name_primary: '',
        caregiver_phone_number_primary: '',
        caregiver_relation_primary: 'All',
        caregiver_full_name_second: '',
        caregiver_first_name_second: '',
        caregiver_last_name_second: '',
        caregiver_phone_number_second: '',
        caregiver_relation_second: 'All',
        caregiver_full_name_third: '',
        caregiver_phone_number_third: '',
        caregiver_relation_third: 'All',
        nurse_id: null,
    });

    useEffect(() => {
        if (patient && patient.first_name && patient.last_name && patient.city && patient.state &&
            validType.medical_record &&
            patient.first_name.length &&
            patient.first_name.trim().length &&
            validType.first_name &&
            validType.MI &&
            patient.last_name.length &&
            patient.last_name.trim().length &&
            validType.last_name &&
            patient.patient_address1.trim().length &&
            patient.city.length &&
            patient.city.trim().length &&
            validType.city &&
            patient.state.length &&
            patient.state.trim().length &&
            validType.state &&
            patient.zip &&
            validType.zip &&
            patient.phone_number.length === 10 &&
            patient.gender !== 'All' &&
            patient.patient_weight &&
            validType.patient_weight &&
            patient.patient_height_in_feet &&
            validType.patient_height_in_feet &&
            validType.patient_height_in_inches &&
            patient.date_of_birth !== null &&
            (validContract || userData.type === 'Hospice' || edit) &&
            patient.hospice_organization_id !== null &&
            invalidMedicalRecord === false &&
            (patient.patient_weight !== "0" &&
                patient.patient_height_in_feet !== "0" &&
                patient.patient_height_in_inches !== "-1")
        ) {
            setFirstStep(true);
        } else {
            setFirstStep(false);
        }
        if (patient.caregiver_first_name_primary.trim() && patient.caregiver_last_name_primary.trim() && validType.caregiver_first_name_primary && validType.caregiver_last_name_primary &&
            patient.caregiver_phone_number_primary && validType.caregiver_phone_number_primary &&
            patient.caregiver_relation_primary !== 'All'
        ) {
            setSecondStep(true);
        } else {
            setSecondStep(false);
        }
        if (patient && patient.first_name && patient.last_name && patient.city && patient.state &&
           ( patient.first_name.length > 0 ||
            patient.last_name.length > 0 ||
            patient.patient_address1.trim().length > 0 ||
            patient.city.length > 0 ||
            patient.state.length > 0 ||
            patient.zip.length > 0 ||
            patient.phone_number.length ||
            patient.team_id !== null ||
            patient.gender !== 'All' ||
            patient.patient_weight.length > 0 ||
            patient.patient_height_in_feet.length > 0 ||
            patient.patient_height_in_inches.length > -1)
        ) {
            setEntered(true);
        }
    }, [patient, invalidMedicalRecord, validContract]);
    const [missingValues, setMissingValues] = React.useState({
        medical_record: null, first_name: null, last_name: null, MI: null,
        phone_number: null, care_setting: null, team_id: null, facility: null,
        date_of_birth: '', gender: null, patient_weight: null, patient_height_in_feet: null,
        patient_height_in_inches: null, infectious_disease_protocol: '', prescription_required: null, note: null,
        patient_address1: null, patient_address2: '', zip: null, city: null, state: null,
        // caregiver_full_name_primary: null,
        caregiver_first_name_primary: null,
        caregiver_last_name_primary: null,
        caregiver_first_name_secondary: null,
        caregiver_last_name_secondary: null,
        caregiver_first_name_third: null,
        caregiver_last_name_third: null,
        caregiver_phone_number_primary: null,
        caregiver_relation_primary: null, caregiver_full_name_second: null,
        caregiver_phone_number_second: null, caregiver_relation_second: null,
        hospice_organization_id: null, region_id: null,
        // nurse_id: null
    });

    const [initialNotes, setInitialNotes] = useState(patient.note.length);
    const [additionalNotes, setAdditionalNotes] = useState(0);
    const [currentNote, setCurrentNote] = useState('');

    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        props.location.patient_id && getPatientData(props.location.patient_id, token, patient, setPatient, setMissingValues, setInitialNotes, setShowThirdCaregiver, setPatientId, initialNotes, enqueueSnackbar) // EDIT
        if (userData.hospice_organization_id === null && userData.role_id !== 6) {
            getHospiceOrganizations(setHospiceData, setHospiceList, token, enqueueSnackbar, selectedDme);
        }
        getDmes(setDmeProvidersList, setDmeData);
        setMissingValues({
            first_name: patient.first_name.length === 0,
            last_name: patient.last_name.length === 0,
            patient_address1: patient.patient_address1.length === 0,
            city: patient.city.length === 0,
            state: patient.state.length === 0,
            zip: patient.zip.length === 0,
            phone_number: patient.phone_number.length === 0,
            gender: patient.gender === 'All',
            patient_weight: patient.patient_weight.length === 0,
            patient_height_in_feet: patient.patient_height_in_feet.length === 0,
            date_of_birth: patient.date_of_birth === null,
            hospice_organization_id: patient.hospice_organization_id === null,
        })
    }, []);

    const autoPopulateAddress = () => {
        const action = key => (
            <React.Fragment>
                <Button onClick={() => {
                    confirmAddressPopulation();
                    closeSnackbar(key);
                }}>
                    Yes
                </Button>
                <Button onClick={() => {
                    closeSnackbar(key)
                }}>
                    No
                </Button>
            </React.Fragment>
        );

        enqueueSnackbar(snackbarMessages.autopopulatePatientAddress, {
            variant: 'info',
            // autoHideDuration: 3000,
            action,
        });
    };

    const confirmAddressPopulation = async () => {
        if (patient.hospice_organization_id !== null) {
            const promise = await fetch(endpoints.getHospiceAddress + patient.hospice_organization_id, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            });
            notifySnack(enqueueSnackbar, promise.status, snackbarMessages.somethingHappenedWithPatientAddress, false);
            const response = await promise.json();
            if (response.status === "success") {
                let {address1, address2, zip, city, state} = response.data[0];
                setPatient({
                    ...patient,
                    patient_address1: address1,
                    patient_address2: address2,
                    zip: zip,
                    city: city,
                    state: state,
                });
                setMissingValues({
                    ...missingValues,
                    patient_address1: address1.length === 0,
                    city: city.length === 0,
                    state: state.length === 0,
                    zip: zip.length === 0,
                    first_name: patient.first_name.length === 0,
                    last_name: patient.last_name.length === 0,
                    phone_number: patient.phone_number.length === 0,
                    gender: patient.gender === 'All',
                    patient_weight: patient.patient_weight.length === 0,
                    patient_height_in_feet: patient.patient_height_in_feet.length === 0,
                    date_of_birth: patient.date_of_birth === null,
                })
            }
        }
    };

    useEffect(() => {
        closeSnackbar();
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        if (patient.hospice_organization_id !== null) {
            !edit && autoPopulateAddress();
            getHospiceOrganizations(setHospiceData, setHospiceList, token, enqueueSnackbar, selectedDme);
            getNurses(setNurses, setNursesOptions, patient.hospice_organization_id, enqueueSnackbar);
            getHospiceRegions(setRegionData, setRegionList, patient.hospice_organization_id, token, enqueueSnackbar, selectedDme);
            getHospiceFacilities(setFacilitiesData, patient.hospice_organization_id, token, enqueueSnackbar);
            getHospiceTeams(setTeamsData, patient.hospice_organization_id, token, enqueueSnackbar);
        }
    }, [patient.hospice_organization_id]);

    useEffect(()=>{
      let valid = false;
        let withoutRegion = regionList.filter(region =>  region.label === "Contract Without Region");
        let withRegion =  regionList.filter(region =>  region.value === patient.region_id);
        if (selectedDme && patient.hospice_organization_id){
            if ( withoutRegion.length === 1 || withRegion.length ===1 ){
                valid = true;
            }
        }
       setValidContract(valid);
    },[selectedDme,patient.hospice_organization_id, patient.region_id, regionList]);

    const hardValidationCheck = () => {
        if (patient.medical_record !== '' && patient.last_name !== '') {
            softValidationCheck();
            let input = {
                input: {
                    last_name: patient.last_name,
                    medical_record: patient.medical_record
                }
            };
            (async () => {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
            })();
            fetch(`${endpoints.patients_hard_check}`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify(input)
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    if (response.status === 'success') {
                        setHardValidation(true);
                        setPossibleDuplicates(response.data);
                        setShowPossibleDuplicates(true);
                    } else {
                        setHardValidation(false);
                        setPossibleDuplicates([]);
                    }
                })
                .catch(err => {
                    console.log('error', err);
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                })
        }
    };
    const softValidationCheck = () => {
        if (!hardValidation) {
            if (patient.last_name !== '' && patient.patient_address1 !== '' && patient.zip !== '') {
                let input = {
                    input: {
                        last_name: patient.last_name,
                        patient_address1: patient.patient_address1,
                        zip: patient.zip,
                    }
                };
                (async () => {
                    const availableToken = await checkTokenAvailability();
                    if (!availableToken) {
                        dispatch(logout());
                        history.push('/login');
                    }
                })();
                fetch(endpoints.patients_soft_check, {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                    body: JSON.stringify(input)
                })
                    .then(response => {
                        return response.json()
                    })
                    .then(response => {
                        if (response.status === 'success') {
                            setPossibleDuplicates(response.data);
                            setShowPossibleDuplicates(true);
                        } else {
                            setHardValidation(false);
                            setPossibleDuplicates([]);
                        }
                    })
                    .catch(err => {
                        console.log('error', err);
                        enqueueSnackbar("Something happened. Contact support.", {
                            persist: false,
                            variant: "error",
                        });
                    })
            }
        }
    };
    const showDrawer = () => {
        setShowPossibleDuplicates(true);
    };

    useEffect(() => {
        if (!hardValidation) {
            setPossibleDuplicates([]);
        }
    }, [hardValidation]);

    const getZipRelatedData = async (payload) => {
        setPatient({...patient, zip: payload});
        if (payload.length === 5 && patient.city.length < 1 && patient.state.length < 1) {
            const target = endpoints.zipCode + payload;
            await (async () => {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
            })();
            const getZipPromise = await fetch(target, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            });
            const getZipResponse = await getZipPromise.json();
            if (getZipResponse.status === "success") {
                setPatient(prevState => {
                    return {...prevState, city: getZipResponse.city, state: getZipResponse.state};
                });
                setMissingValues({...missingValues, city: false, state: false});
                setZipStatus(0);
            } else {
                setPatient({...patient, zip: payload});
                if (getZipResponse.error.includes("ZERO RESULTS")) {
                    setZipStatus(1);
                } else {
                    setZipStatus(2);
                }
            }
        } else {
            setPatient({...patient, zip: payload});
        }
    };
    const checkDuplicateMedicalRecord = async (e) => {
        if(e.target.value){
            const promise = await fetch(endpoints.checkMedicalRecordExists + e.target.value, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                }

            });
            if (promise.status === status.notfound) {
                setInvalidMedicalRecord(false);
            } else {
                setInvalidMedicalRecord(true);
                enqueueSnackbar("This medical record is already is use.", {
                    persist: false,
                    variant: "info",
                });
            }
        }
    };

    function handleInput(e, type) {
        setPatient({...patient, [e.target.name]: e.target.value});
        if (type) {
            setValidType({...validType, [e.target.name]: true});
        } else {
            setValidType({...validType, [e.target.name]: false});
        }
        if (e.target.value.length < 1) {
            setMissingValues({...missingValues, [e.target.name]: true});
        } else {
            setMissingValues({...missingValues, [e.target.name]: false});
        }
    }

    function handleRequiredInput(e, type) {
        setPatient({...patient, [e.target.name]: e.target.value});
        if (type) {
            setValidType({...validType, [e.target.name]: true});
        } else {
            setValidType({...validType, [e.target.name]: false});
        }
        if (e.target.value.trim().length < 1) {
            setMissingValues({...missingValues, [e.target.name]: true});
        } else {
            setMissingValues({...missingValues, [e.target.name]: false});
        }
    }

    function handlePatientPhoneInput(e) {
        setPatient({...patient, phone_number: e});
        if (e.length !== 10) {
            setMissingValues({...missingValues, phone_number: true});
        } else {
            setMissingValues({...missingValues, phone_number: false});
        }
    }

    function handleCareGiverPhoneInput(e) {
        setPatient({...patient, [e.name]: e.value});
        if (e.value.length !== 10) {
            setMissingValues({...missingValues, [e.name]: true});
        } else {
            setMissingValues({...missingValues, [e.name]: false});
        }
    }

    function handleSelectInput(e) {
        if (e.target.name === 'care_setting' && e.target.value !== 'Facility'){
            setPatient({...patient, [e.target.name]: e.target.value, facility: null});
        } else {
            setPatient({...patient, [e.target.name]: e.target.value});
        }
    }

    function addIcdCode(payload) {
        setPatient({...patient, icd_codes: [...patient.icd_codes, payload]});
    }
    function removeIcdCode(id) {
        let index = _.findIndex(patient.icd_codes, { "icd_code_id": id })
        if (index >= -1){
            let codes = _.cloneDeep(patient.icd_codes);
            codes.splice(index, 1);
            setPatient({...patient, icd_codes: codes});
        }
        // setPatient({...patient, [e.target.name]: e.target.value});
    }

    function handleSelectRequiredInput(e) {
        if((e.target.name === 'team_id' && e.target.value === 'All') || (e.target.name === 'facility' && e.target.value === 'All')){
            setPatient({...patient,[e.target.name] : null})
        } else {
            setPatient({...patient, [e.target.name]: e.target.value});
        }

        if (e.target.value === 'All') {
            setMissingValues({...missingValues, [e.target.name]: true});
        } else {
            setMissingValues({...missingValues, [e.target.name]: false});
        }
    }

    const handleRSInput = event => {
        setPatient({...patient, [event.name]: event.value});
        setMissingValues({...missingValues, [event.name]: false});
    };

    function handleZipInput(e, type) {
        if(e.length<=5){
            getZipRelatedData(e);
        }
        if (e < 1) {
            setMissingValues({...missingValues, zip: true});
        } else {
            setMissingValues({...missingValues, zip: false});
        }
        if (type) {
            setValidType({...validType, zip: true});
        } else {
            setValidType({...validType, zip: false});
        }
    }

    function handleBirthdayInput(e) {
        if (e !== '') {
            setPatient({...patient, date_of_birth: e})
        } else {
            setPatient({...patient, date_of_birth: null});
        }
        if (e === null) {
            setMissingValues({...missingValues, date_of_birth: true});
        } else {
            setMissingValues({...missingValues, date_of_birth: false});
        }
    }

    const handleInfectiousChange = e => {
        const {name, value, checked} = e.target;
        if (name === 'other_disease') {
            setPatient(prevState => ({
                    ...prevState,
                    infectious_disease_protocol: {
                        ...patient.infectious_disease_protocol,
                        [name]: value.toString()
                    }
                })
            );
        } else {
            setPatient(prevState => ({
                ...prevState,
                infectious_disease_protocol: {
                    ...patient.infectious_disease_protocol,
                    [name]: checked
                }
            }));
        }
    };

    const handlePrescriptionChange = e => {
        const {name, checked} = e.target;
        setPatient(prevState => ({
            ...prevState,
            prescription_required: checked
        }))
    }

    const handleNoteInput = (e, position) => {
        let newArr = [...patient.note];
        newArr[position] = e.target.value;
        setPatient({...patient, note: newArr});
    };
    const addNote = () => {
        if(currentNote && currentNote.trim().length){
            setEmptyNote(false);
            if (patient.note[initialNotes + additionalNotes]) {
                setAdditionalNotes(additionalNotes + 1);
                setCurrentNote('');
            }
        } else {
            setEmptyNote(true);
        }
    };
    const handleNoteDelete = (idx) => {
        let newArr = [...patient.note];
        if (idx + 1 > -1) {
            newArr.splice(idx + 1, 1);
        }
        setPatient({...patient, note: newArr});
        setAdditionalNotes(additionalNotes - 1);
    };
    const handleActiveNoteDelete = (idx) => {
        let newArr = [...patient.note];
        if (idx + 1 > -1) {
            newArr.splice(idx + 1, 1);
        }
        setPatient({...patient, note: newArr});
    };
    useEffect(()=>{
        getHospiceOrganizations(setHospiceData, setHospiceList, token, enqueueSnackbar, selectedDme);
    },[selectedDme]);

    useEffect(() => {
        async function fetchData() {
            let response = await checkFeatures('CaregiverPortal','Hospice', patient.hospice_organization_id);
            setPortalAccess(response);
            if (response === true && checkPermissions([permissions.portal_create_patient_account])){
                let clonedSteps = _.cloneDeep(steps);
                clonedSteps.splice(2, 0,  sectionTitles.portalAccounts);
                setSteps(clonedSteps);
            }
            setInitialLoading(false);
        }
        if (edit && edit !== '' && patient.hospice_organization_id && steps.length === 3){
            fetchData();
        }
    }, [patient]);

    useEffect(()=> {
        async function check(){
            let res = await checkFeatures('ICD10Codes','Hospice', patient.hospice_organization_id);
            setShowIcdCodes(res);
        }
        check();
        async function checkMR(){
            let res = await checkFeatures('Medical Record Required','Hospice', patient.hospice_organization_id);
            setMedicalRecordFeature(res);
        }
        checkMR();
    }, [patient.hospice_organization_id])

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <PatientDemographics
                    softValidationCheck={softValidationCheck}
                    hardValidation={hardValidation}
                    setHardValidation={setHardValidation}
                    showPossibleDuplicates={showPossibleDuplicates}
                    setShowPossibleDuplicates={setShowPossibleDuplicates}
                    hardValidationCheck={hardValidationCheck}
                    possibleDuplicates={possibleDuplicates}
                    validType={validType}
                    handleZipInput={handleZipInput}
                    handleBirthdayInput={handleBirthdayInput}
                    handlePatientPhoneInput={handlePatientPhoneInput}
                    handleRequiredInput={handleRequiredInput}
                    handleInput={handleInput}
                    handleSelectRequiredInput={handleSelectRequiredInput}
                    missingValues={missingValues}
                    patient={patient}
                    nurses={nurses}
                    handleRSInput={handleRSInput}
                    nursesOptions={nursesOptions}
                    hospiceData={hospiceData}
                    hospiceList={hospiceList}
                    regionData={regionData}
                    regionList={regionList}
                    facilitiesData={facilitiesData}
                    teamsData={teamsData}
                    edit={edit}
                    handleSave={handleSave}
                    zipStatus={zipStatus}
                    checkDuplicateMedicalRecord={checkDuplicateMedicalRecord}
                    invalidMedicalRecord={invalidMedicalRecord}
                    medicalRecordFeature={medicalRecordFeature}
                    handleSelectInput={handleSelectInput}
                    dmeProvidersList={dmeProvidersList}
                    dmeData={dmeData}
                    setSelectedDme={setSelectedDme}
                    selectedDme={selectedDme}
                    validContract={validContract}
                />;
            case 1:
                return <CaregiverInformation
                    validType={validType}
                    handleCareGiverPhoneInput={handleCareGiverPhoneInput}
                    handleInput={handleInput}
                    handleRequiredInput={handleRequiredInput}
                    handleSelectInput={handleSelectInput}
                    handleSelectRequiredInput={handleSelectRequiredInput}
                    missingValues={missingValues}
                    patient={patient}
                    showThirdCaregiver={showThirdCaregiver}
                    setShowThirdCaregiver={setShowThirdCaregiver}
                    edit={edit}
                    handleSave={handleSave}
                />;
            case 2:
                if (edit && portalAccess && checkPermissions([permissions.portal_create_patient_account])){
                    return <PortalAccounts
                        patient={patient}
                    />
                }
                return <Other
                    addNote={addNote}
                    currentNote={currentNote}
                    emptyNote={emptyNote}
                    setCurrentNote={setCurrentNote}
                    handleNoteDelete={handleNoteDelete}
                    handleActiveNoteDelete={handleActiveNoteDelete}
                    handleInput={handleInput}
                    missingValues={missingValues}
                    patient={patient}
                    handleInfectiousChange={handleInfectiousChange}
                    handlePrescriptionChange={handlePrescriptionChange}
                    initialNotes={initialNotes}
                    handleNoteInput={handleNoteInput}
                    additionalNotes={additionalNotes}
                    edit={edit}
                    handleSave={handleSave}
                    addIcdCode={addIcdCode}
                    removeIcdCode={removeIcdCode}
                    showIcdCodes={showIcdCodes}
                />;
            case 3:
                return <Other
                    addNote={addNote}
                    currentNote={currentNote}
                    emptyNote={emptyNote}
                    setCurrentNote={setCurrentNote}
                    handleNoteDelete={handleNoteDelete}
                    handleActiveNoteDelete={handleActiveNoteDelete}
                    handleInput={handleInput}
                    missingValues={missingValues}
                    patient={patient}
                    handleInfectiousChange={handleInfectiousChange}
                    handlePrescriptionChange={handlePrescriptionChange}
                    initialNotes={initialNotes}
                    handleNoteInput={handleNoteInput}
                    additionalNotes={additionalNotes}
                    edit={edit}
                    handleSave={handleSave}
                    addIcdCode={addIcdCode}
                    removeIcdCode={removeIcdCode}
                    showIcdCodes={showIcdCodes}
                />;
            default:
                throw new Error('Unknown step');
        }
    }
    
    const handleSave = () => {
        switch (activeStep) {
            case 0:
                updatePatientDemographics(patient, token, userData, setPatientId, props.location.patient_id, setWasSuccessful, setIsLoading, enqueueSnackbar);
                break;
            case 1:
                updatePatientCaregivers(patient, token, props.location.patient_id, setWasSuccessful, setIsLoading, enqueueSnackbar);
                break;
            case 2:
                if (patient.note.length > initialNotes) {
                    updatePatientNotes(patient, token, props.location.patient_id, initialNotes, userData, setWasSuccessful, setIsLoading, enqueueSnackbar);
                }
                updatePatientDiseasesAndIcdCodes(patient, token, props.location.patient_id, setWasSuccessful, setIsLoading, enqueueSnackbar);
                updatePatientPrescription(patient, token, props.location.patient_id, setWasSuccessful, setIsLoading, enqueueSnackbar);
                break;
            default:
                console.log("[Error]: This step is not defined!")
        }
    };

    const handleNext = () => {
        if (activeStep === 0) {
            setMissingValues({
                first_name: patient.first_name.length === 0,
                last_name: patient.last_name.length === 0,
                medical_record: patient.medical_record.length === 0,
                patient_address1: patient.patient_address1.length === 0,
                city: patient.city.length === 0,
                state: patient.state.length === 0,
                zip: patient.zip.length === 0,
                phone_number: patient.phone_number.length === 0,
                gender: patient.gender === 'All',
                patient_weight: patient.patient_weight.length === 0,
                patient_height_in_feet: patient.patient_height_in_feet.length === 0,
                date_of_birth: patient.date_of_birth === null,
                hospice_organization_id: patient.hospice_organization_id === null,
            })
        }
        if (firstStep && activeStep === 0) {
            setActiveStep(activeStep + 1);
            setMissingValues({
                caregiver_relation_primary: patient.caregiver_relation_primary === "All",
                caregiver_phone_number_primary: patient.caregiver_phone_number_primary.length === 0,
                caregiver_first_name_primary: patient.caregiver_first_name_primary.length === 0,
                caregiver_last_name_primary: patient.caregiver_last_name_primary.length === 0,
            });
        }
        if (activeStep === 1) {
            setMissingValues({
                caregiver_relation_primary: patient.caregiver_relation_primary === "All",
                caregiver_phone_number_primary: patient.caregiver_phone_number_primary.length === 0,
                caregiver_first_name_primary: patient.caregiver_first_name_primary.length === 0,
                caregiver_last_name_primary: patient.caregiver_last_name_primary.length === 0,
            });
            if (secondStep) {
                setActiveStep(activeStep + 1);
            }
        }
        if (activeStep === 2) {
            if(currentNote.trim().length === currentNote.length || currentNote.trim().length){
                setEmptyNote(false);
                setActiveStep(activeStep + 1);
            }else{
                setEmptyNote(true);
            }
        }
        if (activeStep === 2) {
            if (edit) {
                (async () => {
                    const availableToken = await checkTokenAvailability();
                    if (!availableToken) {
                        dispatch(logout());
                        history.push('/login');
                    }
                })();
                if (edit && portalAccess && checkPermissions([permissions.portal_create_patient_account])){
                    setActiveStep(activeStep +1)
                } else {
                    if(currentNote.trim().length === currentNote.length || currentNote.trim().length){
                        setEmptyNote(false);
                        updatePatientDemographics(patient, token, userData, setPatientId, props.location.patient_id, setWasSuccessful, setIsLoading, enqueueSnackbar);
                        updatePatientNotes(patient, token, props.location.patient_id, initialNotes, userData, setWasSuccessful, setIsLoading, enqueueSnackbar);
                        updatePatientDiseasesAndIcdCodes(patient, token, props.location.patient_id, setWasSuccessful, setIsLoading, enqueueSnackbar);
                        updatePatientPrescription(patient, token, props.location.patient_id, setWasSuccessful, setIsLoading, enqueueSnackbar);
                        updatePatientCaregivers(patient, token, props.location.patient_id, setWasSuccessful, setIsLoading, enqueueSnackbar);
                        setTimeout(() => {
                            history.push(`/patients/patientProfile/${props.location.patient_id}`)
                        }, 2000);
                    }
                }

            } else {
                let clone = _.cloneDeep(patient);
                if (clone.team_id === null) {
                    clone.team_id= null
                }
                createPatient(clone, token, userData, setPatientId, setWasSuccessful, setIsLoading, enqueueSnackbar, history);
            }
        }
        if (activeStep === 3){
            (async () => {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
            })();
            updatePatientDemographics(patient, token, userData, setPatientId, props.location.patient_id, setWasSuccessful, setIsLoading, enqueueSnackbar);
            updatePatientNotes(patient, token, props.location.patient_id, initialNotes, userData, setWasSuccessful, setIsLoading, enqueueSnackbar);
            updatePatientDiseasesAndIcdCodes(patient, token, props.location.patient_id, setWasSuccessful, setIsLoading, enqueueSnackbar);
            updatePatientPrescription(patient, token, props.location.patient_id, setWasSuccessful, setIsLoading, enqueueSnackbar);
            updatePatientCaregivers(patient, token, props.location.patient_id, setWasSuccessful, setIsLoading, enqueueSnackbar);
            setTimeout(() => {
                history.push(`/patients/patientProfile/${props.location.patient_id}`)
            }, 2000);
        }
        if (activeStep === steps.length - 1) {
            setRedirectAlert(true);
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleClose = () => {
        setRedirectAlert(false);
        history.push(patientRoutes.patientProfile + patientId);
    };

    const handleConfirm = () => {
        setPlaceOrder(true);
        setRedirectAlert(false);
    };

    return (
        <Grid container>
            {(isLogged) ? <div style={{ width: '100%'}}>
                <main className={classes.layout}>
                    <Typography component="h1" variant="h5" align="center" style={{
                        justifyContent: "flex-start",
                        textAlign: 'left',
                        color: '#00223D',
                        fontSize: 28,
                        fontWeight: "bold",
                        lineHeight: '38px',
                        borderBottom: '1px solid #B2D1D9',
                        paddingTop: 80,
                        textTransform: "uppercase",
                    }}>
                        {edit ? sectionTitles.editPatient : sectionTitles.addNewPatient}
                    </Typography>
                    <Paper className={classes.paper}>
                        {edit && initialLoading === true ? (
                            //if its edit:
                            <React.Fragment>
                                {initialLoading === true && (
                                    <Grid container justify='center' alignItems='center' alignContent='center' style={{height:600}}>
                                    <CircularProgress />
                                    </Grid>
                                ) }
                            </React.Fragment>
                            ) : ( <React.Fragment>
                        <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel>
                            {steps.map((label, index) => (
                                <Step key={label} onClick={() => {
                                    if (edit) {
                                        setActiveStep(index)
                                    }
                                }}>
                                    <StepLabel style={edit ? {cursor: "pointer",
                                        pointerEvents: "all !important",}: {}}>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <React.Fragment>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    {isLoading ? (
                                        <div style={{display: "flex", justifyContent: 'center'}}>
                                            <CircularProgress/>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <Typography variant="h5" gutterBottom>
                                                {(wasSuccessful === true && edit) && 'Success'}
                                                {(wasSuccessful === true && !edit) && 'Success'}
                                                {!wasSuccessful === true && edit && 'Unable to edit'}
                                                {!wasSuccessful === true && !edit && 'Unable to register'}
                                            </Typography>
                                            <Typography variant="subtitle1">
                                                {(wasSuccessful === true && edit) && 'Patient has been updated.'}
                                                {wasSuccessful === true && !edit && 'Patient has been created.'}
                                                {!wasSuccessful === true && !edit && 'Please try again'}
                                            </Typography>
                                            <Dialog
                                                open={false}
                                                onClose={handleClose}
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                            >
                                                <DialogTitle id="alert-dialog-title">Alert</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="alert-dialog-description">
                                                        {getMessage(messageTypes.patientOrder)}
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose} variant="contained" color="secondary">
                                                        {buttonText.no}
                                                    </Button>
                                                    <Button onClick={handleConfirm} variant="contained" color="primary">
                                                        {buttonText.placeOrder}
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {getStepContent(activeStep)}
                                    <Grid  container justify={'flex-end'} className={classes.buttons} spacing={1}>
                                        {edit && activeStep < 2 &&
                                            <Grid item md={1} sm={2} xs={12}>
                                                <AlertDialog
                                                    confirmAction={handleSave}
                                                    type={buttonTypes.standardOutlinedPrimary}
                                                    title={alertTitles.savePatientDemographics}
                                                    content={activeStep === 0 ? alertMessages.savePatientDemographics : alertMessages.savePatientCaregivers}
                                                    denyActionText={buttonText.negative}
                                                    confirmActionText={buttonText.proceed}
                                                    buttonText={buttonText.save}
                                                    buttonCSS={classes.button}
                                                />
                                            </Grid>
                                       }
                                        {(activeStep === 0 && possibleDuplicates.length > 0) && (
                                            <Grid item md={3} sm={4} xs={12}>
                                            <Button
                                                variant={firstStep === true || false ? "contained" : 'outlined'}
                                                color="secondary"
                                                onClick={showDrawer}
                                                className={classes.button}
                                            >
                                                {buttonText.possibleDuplicates}
                                            </Button>
                                            </Grid>
                                        )}
                                        {activeStep === 0 && (
                                            <Grid item md={1} sm={2} xs={12}>
                                            <Tooltip
                                                title={firstStep === true || false ? '' : tooltips.pleaseFillInRequired}
                                                arrow placement="bottom-end">
                                                {firstStep === true ? <Button
                                                    variant={firstStep === true || false ? "contained" : 'outlined'}
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                    id='next'
                                                >
                                                    {activeStep === steps.length - 1 ? (edit ? buttonText.updatePatient : buttonText.createPatient) : buttonText.next}
                                                </Button> :
                                                    <AlertDialog
                                                        buttonText={buttonText.next}
                                                        type={buttonTypes.standardOutlinedPrimary}
                                                        content={getMissionFieldMessages(missingValues, activeStep, validContract, regionList, edit)}
                                                        buttonCSS={classes.button}
                                                        denyActionText={buttonText.back}
                                                    />
                                                }
                                            </Tooltip>
                                            </Grid>
                                        )}
                                        {activeStep === 1 && (
                                            <Grid item md={1} sm={2} xs={12}>
                                            <Tooltip
                                                title={secondStep === true || false ? '' : tooltips.pleaseFillInRequired}
                                                arrow placement="bottom-end">
                                                {secondStep === true ? <Button
                                                    variant={secondStep === true || false ? "contained" : 'outlined'}
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                    id='next'
                                                    style={{
                                                        paddingTop: 10,
                                                        paddingBottom: 10,
                                                        paddingLeft: 12,
                                                        paddingRight: 12,
                                                        height: 40
                                                    }}
                                                >
                                                    {activeStep === steps.length - 1 ? (edit ? buttonText.updatePatient : buttonText.createPatient) : buttonText.next}
                                                </Button> :
                                                    <AlertDialog
                                                        buttonText={buttonText.next}
                                                        type={buttonTypes.standardOutlinedPrimary}
                                                        content={getMissionFieldMessages(missingValues, activeStep, true, regionList, edit)}
                                                        buttonCSS={classes.button}
                                                        denyActionText={buttonText.back}
                                                    />
                                                }
                                            </Tooltip>
                                            </Grid>
                                        )}
                                        {activeStep === 2 && (
                                            <Grid item md={2} sm={2} xs={12}>
                                            <Tooltip
                                                title={secondStep === true || false ? '' : tooltips.pleaseFillInRequired}
                                                arrow placement="bottom-end">
                                                <Button
                                                    variant={secondStep === true || false ? "contained" : 'outlined'}
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}
                                                    id='next'
                                                    style={{
                                                        paddingTop: 10,
                                                        paddingBottom: 10,
                                                        paddingLeft: 12,
                                                        paddingRight: 12
                                                    }}
                                                >
                                                    {activeStep === steps.length - 1 ? (edit ? buttonText.updatePatient : buttonText.createPatient) : buttonText.next}
                                                </Button>
                                            </Tooltip>
                                            </Grid>
                                        )}
                                        {activeStep === 3 && (
                                            <Grid item md={2} sm={2} xs={12}>
                                                <Tooltip
                                                    title={secondStep === true || false ? '' : tooltips.pleaseFillInRequired}
                                                    arrow placement="bottom-end">
                                                    <Button
                                                        variant={secondStep === true || false ? "contained" : 'outlined'}
                                                        color="primary"
                                                        onClick={handleNext}
                                                        className={classes.button}
                                                        id='next'
                                                        style={{
                                                            paddingTop: 10,
                                                            paddingBottom: 10,
                                                            paddingLeft: 12,
                                                            paddingRight: 12
                                                        }}
                                                    >
                                                        {activeStep === steps.length - 1 ? (edit ? buttonText.updatePatient : buttonText.createPatient) : buttonText.next}
                                                    </Button>
                                                </Tooltip>
                                            </Grid>
                                        )}
                                        {activeStep !== 0 && (
                                            <Grid item md={1} sm={2} xs={12}>
                                            <Button onClick={handleBack} className={classes.button} variant={"outlined"}
                                                    color={"primary"}>
                                                {buttonText.back}
                                            </Button>
                                            </Grid>
                                        )}
                                                    {entered ?
                                                        <Grid item md={1} sm={2} xs={12}>
                                                            <CancelActionAlert
                                                                flag={cancellingAlertFlags.newPatient}
                                                                CSS={classes.button}
                                                                alertLevel={alertLevel.warn}
                                                                shouldGoBack={true}
                                                            />
                                                        </Grid> : <Grid item md={1} sm={2} xs={12}>
                                                            <Button
                                                                variant={'outlined'}
                                                                color="primary"
                                                                onClick={() => {
                                                                    history.push('/patients/all')
                                                                }} className={classes.button}>
                                                                {buttonText.cancel}
                                                            </Button>
                                                        </Grid>
                                                    }
                                    </Grid>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                            </React.Fragment>)}
                    </Paper>
                </main>
                {edit && <UserCounter endpoint={endpointToTrack} timeOfRender={moment().subtract(15, "minutes")}
                                      ignoreEndpoints={endpointsToIgnore}/>}
            </div> : ''}
        </Grid>
    );
}
