import React, {useEffect, useState} from 'react';
import './inventory.css';
import {useDispatch, useSelector} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {inventoryStyle} from "../inventory/inventoryStyle";
import MaterialTable from "material-table";
import ViewAssets from "./viewAssets/viewAssets";
import {Collapse, TextField} from "@material-ui/core";
import {endpoints} from "../../constants/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import jwt from 'jwt-decode';
import {sectionTitles, strings} from "../../constants/names/titles"
import {buttonText} from "../../constants/alert/alertButtonTexts";
import {checkTokenAvailability} from "../../common/utils/utils";
import {logout} from "../../redux/actions";
import {checkPermissions} from "../../common/permissions/checkPermissions";
import {permissions} from "../../constants/permissions/permissions";

export default function Inventory() {
    const data = JSON.parse(localStorage.getItem('key'));
    const dispatch = useDispatch();
    const user = jwt(data.accessToken);
    const classes = useStyles();
    const isLogged = useSelector(state => state.isLogged);
    let history = useHistory();
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshTableFlag, setRefreshTableFlag] = useState(false);
    const ref = React.createRef();

    const [search, setSearch] = React.useState({
        input: '',
        asset: '',
        warehouse: '',
        name: ''
    });
    const dmeID = {
        id: Number(user.payload.dme_organization_id)
    };
    const refreshTable = () => {
        if(ref?.current) {
            const {data} = ref.current.dataManager
            const newData = data.map(row => ({...row, tableData: {}}));
            setItems(newData);
        }
    }
    useEffect(() => {
       refreshTable();
    },[refreshTableFlag])
    const handleSearch = () => {
        refreshTable();
        setIsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getInventoryItemByAsset, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                asset: String(search.input)
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data !== []) {
                    setSearch({
                        ...search,
                        warehouse: response.data[0] ? response.data[0].warehouse_name : "No Data Found",
                        name: response.data[0] ? response.data[0].item_name : "No Data Found",
                        asset: search.input
                    })
                }
                setIsLoading(false)
            })
            .catch(err => console.log('error', err))
    };

    function getItems() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getInventoryItems + dmeID.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setItems(response.data);
                setIsLoading(false);
            })
            .catch(err => console.log('error', err))
    }

    useEffect(() => {
        getItems()
    }, []);

    return (
        <div>
            {(isLogged) ? <React.Fragment>
                <Grid container justify='space-between' className={classes.inventory}>
                    <Grid item>
                        <Typography component={'span'} variant="h6">
                            {sectionTitles.viewInventory}
                        </Typography>
                    </Grid>
                    <Grid item>
                        {checkPermissions([permissions.create_inventory]) &&
                        <Grid container spacing={1} justify={"space-between"}>
                            <Grid item>
                                <Button
                                    variant='outlined'
                                    onClick={() => {
                                        history.push(`/inventory`)
                                    }}
                                >
                                    {buttonText.backToInventory}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant='outlined'
                                    onClick={() => {
                                        history.push(`/inventory/export`)
                                    }}
                                >
                                    {buttonText.exportData}
                                </Button>
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid container className={classes.inventory} justify="center" alignContent='center'
                      alignItems='center'>
                    <span style={{paddingRight: 20}}>{strings.searchByAssetNo}</span>
                    <TextField
                        style={{paddingRight: 10}}
                        size={'small'}
                        value={search.input}
                        variant={'outlined'}
                        onChange={(e) => setSearch({...search, input: e.target.value})}/>
                    <Button variant='outlined' color='primary' onClick={() => {
                        search.input !== "" && handleSearch()
                    }}>{buttonText.search}</Button>
                    <Button variant='outlined' style={{marginLeft: 10}} onClick={() => {
                        setSearch({name: '', asset: "", warehouse: "", input: ""})
                        setRefreshTableFlag(prevState => !prevState)
                    }}>{buttonText.clear}</Button>

                </Grid>
                <Grid container className={classes.inventory}>
                    <div style={{width: 1200, justifyContent: 'center'}}>
                        {isLoading ? <CircularProgress/> : (
                            <MaterialTable
                                title="View Items"
                                tableRef={ref}
                                options={{
                                    filtering: true,
                                }}
                                columns={[
                                    {
                                        title: 'Item Name',
                                        field: 'item_name',
                                        filterPlaceholder: 'Name',
                                        defaultFilter: search.name
                                    },
                                    {
                                        title: 'Warehouse',
                                        field: 'warehouse_name',
                                        filterPlaceholder: 'Warehouse',
                                        defaultFilter: search.warehouse
                                    },
                                    {title: 'On Hand', field: 'active', filtering: false},
                                    {title: 'In Use', field: 'inuse', filtering: false},
                                    {
                                        title: 'Total', field: 'total', filtering: false,
                                        render: rowData => <div>
                                            {Number(rowData.active) + Number(rowData.inuse)}
                                        </div>
                                    }
                                ]}
                                data={items}
                                onRowClick={(event, rowData, togglePanel) => togglePanel()}
                                detailPanel={[
                                    {
                                        isFreeAction: true,
                                        tooltip: 'View items',
                                        render: rowData => {


                                            return (
                                                <div style={{
                                                    paddingRight: 8,
                                                    paddingLeft: 8
                                                }}>
                                                    <ViewAssets rowData={rowData} id={dmeID.id}
                                                                searchValue={search.asset}/>
                                                </div>
                                            )
                                        },
                                    },
                                ]}
                            />
                        )}
                    </div>
                </Grid>
            </React.Fragment> : ''}
        </div>
    )
}

const useStyles = makeStyles((theme) => (inventoryStyle));
