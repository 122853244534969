import React, {useEffect, useState} from "react";
import {getAllDmes, getAllFeatuers, getAllHospices} from "../helpers/dataGetter";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {componentTitles} from "../../../constants/names/titles";
import {makeStyles} from "@material-ui/core/styles";
import {featureEnablerStyle} from "./featureEnabler.style";
import Select from "react-select";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {Table} from "../../../common/table/table";
import {tableColumnTitles} from "../../../constants/names/columnTitles";
import Switch from "@material-ui/core/Switch";
import {labels} from "../../../constants/inputs/labels";
import InputLabel from "@material-ui/core/InputLabel";
import {endpoints} from "../../../constants/endpoints";
import {endpointStatus} from "../../../constants/messages/endpointStatus";
import _ from "lodash";
import {useSnackbar} from "notistack";
import {notifySnack} from "../../../common/notifications/apiStatusNotification";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";


export const FeatureEnabler = (props) => {

    const [organizationType, setOrganizationType] = useState("");
    const [hospiceOrganizations, setHospiceOrganizations] = useState("");
    const [dmeOrganizations, setDmeOrganizations] = useState("");
    const [organization, setOrganization] = useState("");
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [allFeatures, setAllFeatures] = useState([]);
    const [organizationFeatures, setOrganizationFeatures] = useState([]);
    const data = JSON.parse(localStorage.getItem('key'));
    const {enqueueSnackbar} = useSnackbar();
    const [open, setOpen] = useState();
    const [targetData, setTargetData] = useState({});

    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        getAllHospices(setHospiceOrganizations);
        getAllDmes(setDmeOrganizations);
        getAllFeatuers(setAllFeatures);


    }, [])

    useEffect(() => {
        setIsLoading(true);
        if(organizationType === "Dme" && organization !== "") {
            getOrganizationFeatures({dme_id: organization.value, hospice_id: null});
        } else if(organizationType === "Hospice" && organization !== "") {
            getOrganizationFeatures({hospice_id: organization.value, dme_id: null});
        }
        setIsLoading(false);
    }, [organization])

    const changeFeatureStatus = (data) => {
        if(_.findIndex(organizationFeatures, {"feature_id": data.feature_id}) === -1){
            if(organizationType === "Dme" && organization !== "") {
                createOrUpdateFeature({dme_id: organization.value, feature_id: data.feature_id, status: true});
            } else if(organizationType === "Hospice" && organization !== "") {
                createOrUpdateFeature({hospice_id: organization.value, feature_id: data.feature_id, status: true});
            }
        }  else {
            if(organizationType === "Dme" && organization !== "") {
                createOrUpdateFeature({dme_id: organization.value, feature_id: data.feature_id, status: !organizationFeatures[_.findIndex(organizationFeatures, {"feature_id": data.feature_id})].isactive});
            } else if(organizationType === "Hospice" && organization !== "") {
                createOrUpdateFeature({hospice_id: organization.value, feature_id: data.feature_id, status: !organizationFeatures[_.findIndex(organizationFeatures, {"feature_id": data.feature_id})].isactive});
            }
        }
        setOpen(false);
    }

    const createOrUpdateFeature = async (payload) => {
        setIsLoading(true);
        const promise = await fetch(endpoints.updateCreateFeatureMapping, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            },
            body: JSON.stringify(payload)
        });
        const response = await promise.json();
        if (response.status === endpointStatus.success) {
            if(organizationType === "Dme" && organization !== "") {
                getOrganizationFeatures({dme_id: organization.value, hospice_id: null});
            } else if(organizationType === "Hospice" && organization !== "") {
                getOrganizationFeatures({hospice_id: organization.value, dme_id: null});
            }
            enqueueSnackbar(`${allFeatures[_.findIndex(allFeatures, {"feature_id": payload.feature_id})].name} was ${payload.status === true ? "ENABLED" : "DISABLED"} successfully.`, {
                persist: false,
                variant: "success",
            })
        } else {
            console.log("[Error]: Error encountered while updating feature status!");
            enqueueSnackbar(`Something happened while ${payload.status === true ? "ENABLING" : "DISABLING"} ${allFeatures[_.findIndex(allFeatures, {"feature_id": payload.feature_id})].name}.`, {
                persist: false,
                variant: "warning",
            })
        }
        setIsLoading(false);
    }
    const getOrganizationFeatures = async (payload) => {
        setIsLoading(true);
        const promise = await fetch(endpoints.getOrganizationFeatures, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            },
            body: JSON.stringify(payload)
        });
        const response = await promise.json();
        if (response.status === endpointStatus.success) {
            setOrganizationFeatures(response.data);
        } else {
            console.log("[Error]: Error encountered while getting organization features!");
        }
        setIsLoading(false);
    }

    const columns = [
        {
            title: tableColumnTitles.featureId, field: "feature_id", width: 30,
            headerStyle: {
                textAlign: 'center'
            },
            render: rowData => <div
                style={{display: "flex", justifyContent: "center", alignItems: "center"}}> {rowData.feature_id} </div>
        },
        {
            title: tableColumnTitles.featureName, field: "name", width: 150,
        },
        {title: tableColumnTitles.description, field: "description", width: 300},
        {
            title: tableColumnTitles.operation, field: "isActive", width: 30,
            headerStyle: {
                textAlign: 'center'
            },
            render: rowData => <Grid item style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                {organization !== "" ? <Switch
                    disabled={isLoading}
                    color={"primary"}
                    checked={_.findIndex(organizationFeatures, {"feature_id": rowData.feature_id}) === -1 ? false : organizationFeatures[_.findIndex(organizationFeatures, {"feature_id": rowData.feature_id})].isactive}
                    onClick={(e) => {
                        setTargetData(rowData);
                        setOpen(true);
                    }}
                /> : <div/>}
            </Grid>
        },
    ]

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Typography className={classes.h1}>
                    {componentTitles.featureEnabler}
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.topPadding} style={{zIndex: 30}}>
                <Grid container spacing={4} direction={"row"}>
                    <Grid item xs={12} sm={8} md={6} lg={2}>
                        <InputLabel style={{display:"flex"}}>{labels.organizationType}</InputLabel>
                        <Select
                            options={[
                                {value: "Dme", label: "DME Providers"},
                                {value: "Hospice", label: "Hospice Providers"}
                            ]}
                            onChange={(e) => {
                                setOrganizationType(e.value);
                                setOrganization("");
                            }}
                            isLoading={hospiceOrganizations.length === 0 && dmeOrganizations.length === 0}
                            disabled={hospiceOrganizations.length === 0 && dmeOrganizations.length === 0}
                        />
                    </Grid>
                    {organizationType !== "" && <Grid item xs={12} sm={8} md={6} lg={2}>
                        <InputLabel style={{display:"flex"}}>{organizationType === "Dme" ? labels.dmeProvider : labels.hospiceProvider}</InputLabel>
                        <Select
                            options={
                                organizationType === "Dme" ? dmeOrganizations.map(item => {
                                    return {label: item.dme_name, value: item.dme_organization_id}
                                }) : hospiceOrganizations.length > 0 ? hospiceOrganizations.map(item => {
                                    return {label: item.hospice_name, value: item.hospice_organization_id}}) : []

                            }
                            onChange={(e) => setOrganization(e)}
                            value={organization && organization}
                        />
                    </Grid>}
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.topPadding}>
                <Table
                    search={true}
                    filtering={true}
                    columns={columns}
                    items={organizationType !== "" ? allFeatures.filter(feature => feature.type === organizationType) : []}
                    backGroundColor={"rgba(237, 242, 244, 0.73)"}
                    textColor={"rgba(46, 50, 54, 1)"}
                    isLoading={isLoading}
                />
            </Grid>
            <Dialog open={open}>
                <DialogTitle>
                    Warning
                </DialogTitle>
                <DialogContent>
                    <Typography variant={"h6"}>
                        You are about to {_.findIndex(organizationFeatures, {"feature_id": targetData.feature_id}) === -1 ? "ENABLE" : "DISABLE"} "{targetData.name}" feature. Are you
                        sure?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpen(false)} color={"secondary"}>
                        No, cancel
                    </Button>
                    <Button onClick={() => changeFeatureStatus(targetData)} color={"primary"}>
                        Yes, continue
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}
const useStyles = makeStyles((theme) => (featureEnablerStyle(theme)));