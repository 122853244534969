import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Table} from '../../../../common/table/table';
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import {TabSearchBar} from "../../../../common/search/tabSearch/TabSearch";
import {setStates} from "../../../../common/search/tabSearch/TabSearchHelper";
import ExportForm from "../../../../common/exportForm/exportForm";
import {endpoints} from "../../../../constants/endpoints";
import Typography from "@material-ui/core/Typography";

export const DmeResearch = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const [sort, setSort] = useState(["","","", "", "","","","", "", "","","","", "", "", ]);
    const [page, setPage ] = useState(0);
    const [hospices, setHospices] = useState([]);
    const [activeRow, setActiveRow] =  useState('');
    const [modalLoading, setModalLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [stateOptions, setStateOptions] = useState([]);
    const [hospiceDetails, setHospiceDetails] =useState({result:[]});
    const [exportValues, setExportValues] = useState([]);

    const [dmeUsersFiltersValue, setDmeUsersFiltersValue] = useState({
        input: '',
        states: '',
    });
    const filterOptions = [
        { name: "state", placeholder: "Filter by State", options: stateOptions, value: dmeUsersFiltersValue.state, isSelect: true, isClearable: true},
    ];

    const handleInputChange = (value) => {
            setDmeUsersFiltersValue({...dmeUsersFiltersValue, input:value})
    };
    function handleFilterUpdate(event){
        setDmeUsersFiltersValue({...dmeUsersFiltersValue, [event.target.name]: event.target.value})
    }
    const clearFilters=()=>{
        setDmeUsersFiltersValue({
            input:'',
            states:'',
        });
    };

    const getHospices = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if(!availableToken){
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.dmeResearch,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setHospices(response.data);
            })
            .catch(err => console.log('error', err))
    };
    useEffect(()=> {
        getHospices();
        setStates(setStateOptions);
    },[]);

    const getData = (providerId) => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if(!availableToken){
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.dmeResearchById + providerId,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setModalLoading(false);
                setHospiceDetails(response);

                let final = [];
                response.result.map(year => {
                            let newArr = [];
                            newArr.push(activeRow.hospiceName);
                            newArr.push(year.year === 2121 ? 2021 : year.year); // this happens because the data in elastic has year 2121 that should be mapped to 2021
                            newArr.push('$' + year.expenses);
                            newArr.push(year.days);
                            newArr.push(year.cppd === 'NaN' ? '$0' : '$' + year.cppd);
                            newArr.push(Math.floor(year.days / 365));
                            final.push(newArr)
                        });
                setExportValues(final);
            })
            .catch(err => console.log('error', err))
    };

    useEffect(()=> {
        if (activeRow.hospiceId){
            getData(activeRow.hospiceId);
        }
    }, [activeRow]);

    let columns = [{title: 'Provider ID', field: "provider_number", width: 112, defaultSort:sort[0]},
        {title: 'Provider Name', field: "hospice_name", width: 300, defaultSort:sort[1]},
        {title: 'Address', field: "street_address", width: 300,defaultSort:sort[2],
            render: rowData => <React.Fragment>
                <div>{`${rowData.street_address} ${rowData.po_box}`}</div>
            </React.Fragment>
        },
        {title: 'City', field: "city", width: 20, defaultSort:sort[3]},
        {title: 'State', field: "state", width: 20, defaultFilter:dmeUsersFiltersValue.state, defaultSort:sort[4]},
        {title: 'Zip', field: "zip_code", width: 20, defaultSort:sort[5]},
        {title:'all', field: "values", hidden: true, defaultFilter:dmeUsersFiltersValue.input, defaultSort:sort[6]},
        {title: '', field: "", width: 60, defaultSort:sort[7],
            render: rowData => <React.Fragment>
                <Button variant='outlined' color='primary'
                        onClick={()=> {
                            setActiveRow({
                                hospiceId: rowData.provider_number,
                                hospiceName: rowData.hospice_name,
                            });
                            setModalLoading(true);
                            setShowModal(true);
                        }}
                >Details</Button>
            </React.Fragment>
        }
    ]

    const handleClose = () => {
        setShowModal(false);
    };

    const formatMoney = (number) => {
        let formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        let res = formatter.format(number);
        return res;
    };

    const headers = [
        {text: "Name", style: 'tableHeader'},
        {text: "Year", style: 'tableHeader'},
        {text: "DME Expenses", style: 'tableHeader'},
        {text: "Census Days", style: 'tableHeader'},
        {text: "CPPD", style: 'tableHeader'},
        {text: "ACD", style: 'tableHeader'},
    ];
    const excelHeaders = [
        "Name",
        "Year",
        "DME_Expenses",
        "Census_Days",
        "CPPD",
        "ACD",
    ];
    const onOrderChange = (colId, ord) =>{
        let sortClone;
        if (colId !== -1) {
            sortClone = ["","","", "", "","","","", "", "","","","", "", "", ];
            sortClone[colId] = ord;
        }
        else {
            sortClone = ["","","", "", "","","","", "", "","","","", "", "", ];
        }
        setSort(sortClone);
    };
    const onChangePage=(newPage) => {
        setPage(newPage);
    }
    return (
        <Grid container justify='center'>
            <Grid item xs={12} style={{paddingTop:24, paddingBottom:24, width:'100%', display:'flex', justifyContent:'flex-start', maxWidth:1121}}>
                <Typography variant={'h4'} style={{fontSize:20}}> DME Research</Typography>
            </Grid>
            <div style={{ width:'100%', display:'flex', justifyContent:'center', maxWidth:1121}}>
                <TabSearchBar
                    filtersValue={dmeUsersFiltersValue}
                    handleInputChange={handleInputChange}
                    clearFilters={clearFilters}
                    filterOptions={filterOptions}
                    handleFilterUpdate={handleFilterUpdate}
                />
            </div>

                <Table options={{
                    showTitle:false,
                    search:false
                }}
                        pageSize={5}
                       search={false}
                       filtering={true}
                       columns={columns}
                       items={hospices}
                       // searchText={'451651'}
                       rowClick={false}
                       // customText={"Choose a status to retrieve orders"}
                       backGroundColor={"rgba(237, 242, 244, 0.73)"}
                       textColor={"rgba(46, 50, 54, 1)"}
                       onOrderChange={onOrderChange}
                       onChangePage={onChangePage}
                />


            <Dialog
                open={showModal}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    {modalLoading ? <Grid container style={{minHeight:140, minWidth:170, display:'flex', justifyContent:'center', alignItems:'center', alignContent:'center'}}> <CircularProgress /> </Grid>: (
                            <Grid container style={{textAlign:'center', color:'black', fontWeight:600}}>
                                <Grid item xs={2}>
                                    Year
                                </Grid>
                                <Grid item xs={3}>
                                    DME Expenses
                                </Grid>
                                <Grid item xs={3}>
                                    Census Days
                                </Grid>
                                <Grid item xs={2}>
                                    CPPD
                                </Grid>
                                <Grid item xs={2}>
                                    ADC
                                </Grid>
                            {hospiceDetails && hospiceDetails.result && hospiceDetails.result.map((result, index) => {
                                return(
                                    <Grid container  style={{fontWeight:400, paddingTop:4}} key={index}>
                                        <Grid item xs={2}>
                                            {result.year}
                                        </Grid>
                                        <Grid item xs={3}>
                                            {result.expenses === 0 ? 'No record' : `${formatMoney(result.expenses)}` }
                                        </Grid>
                                        <Grid item xs={3}>
                                            {result.days === 0 ? 'No record' : `${result.days}` }
                                        </Grid>
                                        <Grid item xs={2}>
                                            {(result.cppd === "0.00" || result.cppd === "NaN") ? 'No record' : `${formatMoney(result.cppd)}` }
                                        </Grid>
                                        <Grid item xs={2}>
                                            {result.days === 0 ? 'No record' : `${Math.floor(result.days / 365)}` }
                                        </Grid>
                                    </Grid>)
                            }
                                )}
                                <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end', paddingTop:36}}>
                                    <ExportForm name={false} showPrint={true} headers={headers} excelHeaders={excelHeaders} exportValues={exportValues} fileName={activeRow.hospiceName}/>
                                </Grid>
                            </Grid>
                    )}
                </DialogContent>
            </Dialog>

        </Grid>

    )
};
