import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import {endpoints} from "../../constants/endpoints";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import {getTokenInformation} from "../utils/utils";
import {utilsTokenIdentifiers} from "../../constants/inputs/values";
import {useHistory} from "react-router-dom";

export const DispatchNotificationToSelectedTechnician = ({order, enqueueSnackbar, addHistory, orderId, orderType, orderIdentifier}) => {

    const [enableDispatch, setEnableDispatch] = useState(false);
    const history = useHistory()

    const notify = async () => {
        const payload = {
            dmeId: order.dme_organization_id,
            order_id_full: orderIdentifier + orderId,
            order_id: orderId,
            order_type: orderType,
            full_name: order.technician_name,
        }
        const {first_name, last_name} = getTokenInformation(history, utilsTokenIdentifiers.payload)
        const promise = await fetch(endpoints.dispatchNotificationToTechnician, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            },
            body: JSON.stringify(payload)
        });
        let response = await promise.json();
        if (response.status === "success") {
            let description = "Dispatched notification to: ";
            description += order.technician_name +  ", ";
            description += `by ${first_name} ${last_name}`
            await addHistory(orderId, description);
            enqueueSnackbar(description, {variant: "success"});
            history.push(`/refresh/order/${orderType.toLowerCase()}/${orderId}`)
        } else {
            enqueueSnackbar("Could not notify technician", {variant: "warning"});
        }
    }

    return (
        <div>
            <Button
                disabled={(order.technician_name === null) ? enableDispatch === false : enableDispatch === true}
                variant={"contained"}
                color={"primary"}
                onClick={notify}
                endIcon={<ArrowRightAltIcon style={{fontSize: 28}}/>}
            >
                Dispatch To Technician
            </Button>
        </div>
    )
}