export const alertTitles = {
    warn: "Warning!",
    error: "Error!",
    alert: "Alert!",
    outOfFormulary: "Item Requires Approval",
    addNewAsset: "Add item to inventory",
    updateItemStatus: "Update Item Status",
    changeStatus: "Change Order Status",
    savePatientDemographics: "Save Patient Demographics",
    saveHospiceData: "Save Hospice Data",
    saveDmeData: "Save Dme Data",
    totalPickup: "Total Pickup Request",
    itemizedPickup: "Itemized Pickup Request",
    selectDateRange: "Please select a date range to get older orders"
};