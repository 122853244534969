import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import 'date-fns';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Select from "react-select";
import {Accordion, Checkbox, CircularProgress, ListItem, Tooltip} from "@material-ui/core";
import {checkEmail, checkUsername, getPossibleUsernames} from "../createUserHelper";
import {labels} from "../../../constants/inputs/labels";
import {placeholders} from "../../../constants/inputs/paceholders";
import {getValidationMessage} from "../../../common/InputField/validationMessages/InputFieldErrorMessage";
import _ from "lodash";
import {sectionTitles} from "../../../constants/names/titles";
import {getMessage} from "../../../constants/messages/messages";
import {messageTypes} from "../../../constants/types/messageTypes";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {endpoints} from "../../../constants/endpoints";
import {errorMessages} from "../../../constants/messages/responseMessages";
import {useSnackbar} from "notistack";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {BRDisplayQuarterTable} from "../../reports/businessReview/components/BRDisplayTable";
import {makeStyles} from "@material-ui/core/styles";

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100,
        textAlign: 'left'
    })
};

export default function GeneralInformations(props) {
    const {enqueueSnackbar} = useSnackbar();
    const {
        validType, rolesList, regionList, handleTitleSelect, handleStatus, handleMFA,
        handleTeamSelect, handleRoleSelect, handleRegionSelect, handleInputChange,
        user, dmeList, handleOrganizationSelect, missingValues, possibleEmail,
        setPossibleEmail, validEmail, setValidEmail, restrictedDomains,
        validUsername, setValidUsername, isEdit, warehouses, warehouseIds,
        handleWarehouseInput, dmeData, regionData, teamOptions, teamData,
        checkAvailability, deleteUser, tokenData, handleDmeImpersonate, impersonateDmeIds,
        contractedHospiceData,
    } = props;
    const [open, setOpen] = useState(false);
    const [phone, setPhone] = useState(true);
    const [openDrawer,setOpenDrawer] = useState(false);

    const classes = useStyles();


    const requestPasswordReset = async () => {
        const promise = await fetch(endpoints.requestPassword, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({support: {
                username:user.username,
                email:user.email
                }})
        });

        const response = await promise.json();
        if (response.status === "success") {
            enqueueSnackbar('An email to reset the password was sent to the user.', {
                variant: 'success',
                autoHideDuration: 4000,
                anchorOrigin: {vertical: "bottom", horizontal: "left"}
            });
        } else {
            enqueueSnackbar('Unable to reset password', {
                variant: 'warning',
                autoHideDuration: 4000,
                anchorOrigin: {vertical: "bottom", horizontal: "left"}
            });
        }
    };

    const sortedDmeList = dmeList.sort((a, b) => a.label?.localeCompare(b.label));
    React.useEffect(() => {
       if(possibleEmail.length > 1 && user.first_name && user.last_name) setOpenDrawer(true);
    },[possibleEmail])

    if (dmeData.length > 0) {
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    {sectionTitles.generalInformation}
                </Typography>
                <Grid container>
                    <Grid container spacing={3} className={classes.item}>
                        <Grid item xs={12} sm={6}>
                            <Select
                                isDisabled={props.isEdit}
                                styles={selectStyles}
                                isSearchable
                                fullWidth
                                options={sortedDmeList}
                                placeholder={placeholders.selectProvider}
                                value={user.organization_id === '' || (isEdit && dmeData === []) ? null : [{
                                    label: dmeData[_.findIndex(dmeData, {"dme_organization_id": user.organization_id})] ? dmeData[_.findIndex(dmeData, {"dme_organization_id": user.organization_id})].dme_name : placeholders.selectProvider,
                                    value: user.organization_id
                                }]}
                                onChange={event => {
                                    handleOrganizationSelect(event)
                                }}
                            />
                        </Grid>
                        <Grid container>
                        <Grid item xs={6} sm={3}>
                            <FormControl component="fieldset">
                                <FormLabel>{sectionTitles.userStatus}</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch color="primary"
                                                         checked={user.status}
                                                         onChange={handleStatus}
                                        />}
                                    />
                                </FormGroup>
                                <FormHelperText>{getMessage(messageTypes.userStatus, user.status)}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormControl component="fieldset">
                                <FormLabel>{sectionTitles.userMFA}</FormLabel>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch color="primary"
                                                         checked={user.two_factor_enabled}
                                                         onChange={handleMFA}
                                        />}
                                    />
                                </FormGroup>
                                <FormHelperText>{getMessage(messageTypes.userMFA, user.two_factor_enabled)}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            {isEdit &&
                                <FormControl component="fieldset">
                                    <FormLabel>{sectionTitles.deleteUserStatus}</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Switch color="primary"
                                                             checked={user.deleted}
                                                             onChange={() => setOpen(true)}
                                            />}
                                        />
                                    </FormGroup>
                                </FormControl>
                            }
                        </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className={classes.item}>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={labels.first_name}
                                id={`first_name`}
                                name="first_name"
                                fullWidth
                                placeholder={placeholders.addFirstName}
                                value={user.first_name}
                                variant="outlined"
                                size="small"
                                onChange={handleInputChange}
                                onBlur={() => getPossibleUsernames(setPossibleEmail, user.first_name, user.last_name)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                label={labels.last_name}
                                id={`last_name`}
                                name="last_name"
                                fullWidth
                                placeholder={placeholders.addLastName}
                                value={user.last_name}
                                variant="outlined"
                                size="small"
                                onChange={handleInputChange}
                                onBlur={() => getPossibleUsernames(setPossibleEmail, user.first_name, user.last_name)}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className={classes.item}>
                        <Grid item xs={12} sm={6} md={4}>
                                <React.Fragment>
                                    <TextField
                                        label={labels.email}
                                        id={`email`}
                                        name="email"
                                        fullWidth
                                        placeholder={placeholders.email}
                                        value={user.email}
                                        variant="outlined"
                                        size="small"
                                        onChange={handleInputChange}
                                        onBlur={(event) => {
                                            checkEmail(event.target.value, restrictedDomains, setValidEmail);
                                            checkAvailability("email", user.email);
                                        }}
                                    />
                                    {
                                        user.email.trim() !== "" && getValidationMessage(validEmail)
                                    }
                                </React.Fragment>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                                <React.Fragment>
                                    <TextField
                                        fullWidth
                                        variant={"outlined"}
                                        size={"small"}
                                        id={"username"}
                                        label={labels.username}
                                        name={"username"}
                                        value={user.username}
                                        onChange={handleInputChange}
                                        placeholder={placeholders.username}
                                        onBlur={() => {
                                            checkUsername(user.username, setValidUsername);
                                            if(user.username.trim() !== ""){
                                                checkAvailability("username", user.username);
                                            }
                                        }}
                                        disabled={isEdit}
                                    />
                                    {
                                        user.username.trim() !== "" && getValidationMessage(validUsername)
                                    }
                                </React.Fragment>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className={classes.item} alignItems={'flex-end'} alignContent={'flex-end'}>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel style={{textAlign: 'left'}}>{labels.requiredPhone}</InputLabel>
                            <PhoneInput
                                containerStyle={{display: 'flex',width:'100%',marginTop:'5px'}}
                                inputStyle={{paddingLeft: '10px'}}
                                name="phone"
                                country={'us'}
                                placeholder={placeholders.phone}
                                disableCountryCode={true}
                                onlyCountries={['us']}
                                value={user.phone}
                                onChange={(e) => {
                                    if (e.length === 10) {
                                        setPhone(true);
                                    }
                                    let event = {
                                        target: {
                                            name: "phone",
                                            value: e
                                        }
                                    };
                                    handleInputChange(event)
                                }}
                                onBlur={(e) => {
                                    if (e.target.value.length === 14) {
                                        setPhone(true)
                                    } else {
                                        setPhone(false)
                                    }
                                }}
                                isValid={phone}
                            />
                            <FormHelperText style={{
                                color: 'red',
                                textAlign: 'left'
                            }}>{!phone && errorMessages.invalid_phone}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4} style={{display: 'flex'}}>
                            { isEdit &&
                                <Button
                                    onClick={requestPasswordReset}
                                    variant={"contained"}
                                    style={{width:'100%'}}
                                    color={"primary"}
                                >
                                    {buttonText.sendPasswordResetUser}
                                </Button>
                            }
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className={classes.item}>
                        <Grid item xs={12} sm={6} md={4}>
                            {regionData.length > 0 && <Select
                                styles={selectStyles}
                                isSearchable
                                fullWidth
                                options={regionList}
                                placeholder={placeholders.selectRegion}
                                value={user.region === null ? null : [{
                                    label: regionData[_.findIndex(regionData, {"region_id": user.region})].name,
                                    value: user.region
                                }]}
                                onChange={event => {
                                    handleRegionSelect(event)
                                }}
                            />}
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            {teamData.length > 0 && <Select
                                styles={selectStyles}
                                isSearchable
                                fullWidth
                                options={teamOptions}
                                placeholder={placeholders.selectTeam}
                                value={user.team === null ? null : [{
                                    label: teamData[_.findIndex(teamData, {"team_id": user.team})].team_name,
                                    value: teamData[_.findIndex(teamData, {"team_id": user.team})].team_name
                                }]}
                                onChange={event => {
                                    handleTeamSelect(event)
                                }}
                            />}
                        </Grid>
                    </Grid>


                    <Grid container spacing={3} className={classes.item}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Select
                                styles={selectStyles}
                                isSearchable
                                fullWidth
                                options={[
                                    {value: 'Mr', label: 'Mr'},
                                    {value: 'Mrs', label: 'Mrs'},
                                ]}
                                placeholder={placeholders.selectTitle}
                                value={user.title === '' ? null : [{
                                    label: user.title,
                                    value: user.title
                                }]}
                                onChange={event => {
                                    handleTitleSelect(event)
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                            {rolesList.length > 0 && <Select
                                styles={selectStyles}
                                isSearchable
                                fullWidth
                                options={rolesList}
                                placeholder={placeholders.selectRole}
                                value={((user.role_id === '' && rolesList.length > 0) || rolesList[Number(user.role_id) - 1] === undefined) ? null : [{
                                    label: rolesList[rolesList.findIndex(e => user.role_id === e.value)]?.label,
                                    value: user.role_id
                                }]}
                                onChange={event => {
                                    handleRoleSelect(event)
                                }}
                            />}
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className={classes.item}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Typography variant={"h6"} style={{paddingLeft: 25}}>
                                {sectionTitles.selectUserWarehouses}
                            </Typography>
                            {
                                warehouses.length === 1 ? <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={true}
                                                disabled={true}
                                                name={warehouses[0].name}
                                                onChange={handleWarehouseInput}
                                            />
                                        }
                                        label={warehouses[0].name}
                                    />
                                </Grid> : warehouses.map((item, index) => {
                                    return (
                                        <Grid item key={index}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            _.includes(
                                                                warehouseIds,
                                                                warehouses[_.findIndex(warehouses, {"name": item.name})].warehouse_id)}
                                                        name={item.name}
                                                        onChange={handleWarehouseInput}
                                                    />
                                                }
                                                label={item.name}
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className={classes.item}>
                        <Grid item xs={12} sm={6} md={6}>
                            {["@capstone.com", "@caption.care", "@vspartners.us"].some(substring=>tokenData.email.includes(substring)) && <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography variant={"h6"} style={{paddingLeft: 8, textAlign:'left'}}>
                                        {sectionTitles.addDmeImpersonation}
                                    </Typography>
                                </AccordionSummary>

                                <AccordionDetails>
                                    <Grid container direction={"column"} justify={"flex-start"} alignItems={"flex-start"}
                                          spacing={3}>

                                        {
                                            dmeData.length === 1 ? <Grid item>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={true}
                                                            disabled={true}
                                                            name={dmeData[0].dme_name}
                                                            onChange={handleDmeImpersonate}
                                                        />
                                                    }
                                                    label={dmeData[0].dme_name}
                                                />
                                            </Grid> : dmeData.map((item, index) => {
                                                if (Number(item.dme_organization_id) !== Number(tokenData.dme_organization_id)) {
                                                    return (
                                                        <Grid container direction={"column"} key={index} style={{ padding:8}}>
                                                            <Grid container style={{border:'1px solid lightgrey', borderRadius:4, padding:8}}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={impersonateDmeIds.some(dme => dme.dme_organization_id === item.dme_organization_id)}
                                                                            name={item.dme_name}
                                                                            onChange={handleDmeImpersonate}
                                                                        />
                                                                    }
                                                                    label={item.dme_name}
                                                                />
                                                                <Grid container style={{padding:8}}>
                                                                    <Grid item xs={12} style={{textAlign:'left', fontWeight:'bold'}}>Warehouses:</Grid>
                                                                    {item.warehouses && item.warehouses.map(warehouse => (
                                                                            <Grid item>
                                                                                {warehouse}
                                                                            </Grid>
                                                                        )
                                                                    )}
                                                                    {item.warehouses && item.warehouses.length === 0 &&  <Grid item>
                                                                        -
                                                                    </Grid>}
                                                                </Grid>


                                                                <Grid container style={{padding:8, paddingTop:16}}>
                                                                    <Grid item xs={12} style={{textAlign:'left', fontWeight:'bold'}}>Hospices:</Grid>
                                                                    <ul>
                                                                        {
                                                                            _.findIndex(contractedHospiceData, {"dme_id": item.dme_organization_id}) > -1 ? contractedHospiceData[_.findIndex(contractedHospiceData, {"dme_id": item.dme_organization_id})].hospices.map((item, index) => {
                                                                                return <li key={index} style={{display: "flex"}}>
                                                                                    {item.hospice_name}
                                                                                </li>
                                                                            }) : "-"
                                                                        }
                                                                    </ul>
                                                                </Grid>
                                                            </Grid>

                                                        </Grid>
                                                    )
                                                }
                                            })
                                        }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>}
                        </Grid>
                    </Grid>
                </Grid>


                {!isEdit && possibleEmail.length>1 && (
                    <React.Fragment key='bottom'>
                        <SwipeableDrawer
                            anchor='bottom'
                            open={openDrawer}
                            onClose={() => setOpenDrawer(false)}
                        >
                            <ul style={{listStyle: 'none', margin:0,padding:0}}>
                                {possibleEmail.map((possibleUsername,idx) => idx === 0 ? (
                                    <div key={idx} style={{display: 'flex', alignItems: 'center', paddingLeft: 40, paddingTop:20}}>
                                        <span style={{fontSize: 16,fontWeight: 600}}>{possibleUsername}</span>
                                        <IconButton style={{marginLeft:10}} onClick={(e) => {e.preventDefault(); setOpenDrawer(false)}}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </div>
                                ) : (
                                    <ListItem button key={idx} style={{paddingLeft: 48, display: 'flex', alignItems: 'center'}}
                                              onClick={(e) => {
                                                  e.preventDefault();
                                                  const event = {
                                                      target: {
                                                          name: "username",
                                                          value: possibleUsername
                                                      }
                                                  }
                                                  handleInputChange(event);
                                                  checkUsername(event.target.value, setValidUsername);
                                                  checkAvailability("username", event.target.value);
                                                  setOpenDrawer(false);
                                              }}>
                                        <span>{possibleUsername}</span>
                                        <IconButton
                                            style={{marginLeft: 5}}>
                                            <CheckIcon style={{fill: 'green'}}/>
                                        </IconButton>
                                    </ListItem>
                                ))}
                            </ul>
                        </SwipeableDrawer>
                    </React.Fragment>
                )}

                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {getMessage(messageTypes.deleteUser)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} variant={"contained"} color={"primary"} autoFocus>
                            {buttonText.negative}
                        </Button>
                        <Button color={"secondary"} variant={"contained"} onClick={() => {
                            deleteUser();
                            setOpen(false);
                        }}>
                            {buttonText.proceed}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    } else {
        return <CircularProgress/>
    }
}

const useStyles = makeStyles((theme) => ({
    item: {
        padding: '15px',
        [theme.breakpoints.down('xs')]: {
            padding: '10px'
        },
    },
    userStatus: {
        paddingTop: '10px'
    }
}));
