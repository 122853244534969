import {endpoints} from "../../../constants/endpoints";
import {errorMessages} from "../../../constants/messages/responseMessages";
import {notifySnack} from "../../../common/notifications/apiStatusNotification";
import {snackbarMessages} from "../../../constants/alert/alertMessages";
import jwt from "jwt-decode";
import {checkTokenAvailability, getTokenInformation} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import { PanTool } from "@material-ui/icons";
import {patientBaseDiseases, utilsTokenIdentifiers} from "../../../constants/inputs/values";
import {status} from "../../../constants/status/status"

export const getHospiceOrganizations = (dataSetter, hospiceSetter, token, enqueueSnackbar, dmeId) => {
    if(dmeId === null) {
        fetch(endpoints.getAllHospiceIds, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        }).then(response => {
            notifySnack(enqueueSnackbar, response.status);
            return response.json();
        }).then(response => {
            let object = [];
            for (let i = 0; i < response.data.length; i++) {
                object.push({value: response.data[i].hospice_organization_id, label: response.data[i].hospice_name})
            }
            dataSetter(response.data);
            hospiceSetter(object);

        }).catch(err => {
            console.log('error', err);
            enqueueSnackbar("Something happened. Contact support.", {
                persist: false,
                variant: "error",
            });
        });
    }
    if(dmeId !== null) {
        fetch(`${endpoints.getContractedHospices}${dmeId}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].hospice_id, label: response.data[i].hospice_name})
                }
                dataSetter(response.data);
                hospiceSetter(object);

            })
            .catch(err => {
                console.log('error', err);
            })
    }
};
export const getDmes = (listSetter, dataSetter) => {
    const token = JSON.parse(localStorage.getItem('key'));
    fetch(endpoints.getDmeIds, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    })
        .then(response => {
            return response.json()
        })
        .then(response => {
            let object = [];
            for (let i = 0; i < response.data.length; i++) {
                object.push({value: response.data[i].dme_organization_id, label: response.data[i].dme_name})
            }
            dataSetter(response.data);
            listSetter(object);
        })
        .catch(err => console.log('error', err))
}

export const getHospiceRegions = (dataSetter, regionSetter, hospice_id, token, enqueueSnackbar, dmeId) => {
    if(dmeId === null) {
        fetch(endpoints.getHospiceRegions + hospice_id, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        }).then(response => {
            notifySnack(enqueueSnackbar, response.status);
            return response.json();
        }).then(response => {
            let object = [];
            for (let i = 0; i < response.data.length; i++) {
                object.push({value: response.data[i].region_id, label: response.data[i].name})
            }
            dataSetter(response.data);
            regionSetter(object);

        }).catch(err => {
            console.log('error', err);
            enqueueSnackbar("Something happened. Contact support.", {
                persist: false,
                variant: "error",
            });
        })
    }
    if(dmeId !== null){
        fetch(`${endpoints.getContractedHospices}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                dmeId:dmeId,
                hospiceId: hospice_id,
            })
        }).then(response => {
            return response.json()
        })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (response.data[i].hospice_region_id){
                        object.push({value: response.data[i].hospice_region_id, label: response.data[i].name})
                    }
                    else {
                        object.unshift({value:"", label: 'Contract Without Region'})
                    }
                }
                dataSetter(response.data);
                regionSetter(object);
            })
            .catch(err => {
                console.log('error', err)
            })
    }
};

export const getHospiceFacilities = (dataSetter, hospice_id, token, enqueueSnackbar) => {
    fetch(endpoints.getHospiceFacilities + hospice_id, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    }).then(response => {
        notifySnack(enqueueSnackbar, response.status);
        return response.json();
    }).then(response => {
        dataSetter(response.data);

    }).catch(err => {
        console.log('error', err);
        enqueueSnackbar("Something happened. Contact support.", {
            persist: false,
            variant: "error",
        });
    });
};

export const getHospiceTeams = (dataSetter, hospice_id, token, enqueueSnackbar) => {
    fetch(endpoints.getHospiceTeams + hospice_id, {
        method: 'get',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
    }).then(response => {
        //notifySnack(enqueueSnackbar, response.status);
        return response.json()
    }).then(response => {
        dataSetter(response.data);

    }).catch(err => {
        console.log('error', err);
        /*enqueueSnackbar("Something happened. Contact support.", {
            persist: false,
            variant: "error",
        });*/
    });
};

export const makeCaregiverObject = (patient) => {
    let caregivers = [{
        caregiver_first_name: patient.caregiver_first_name_primary,
        caregiver_last_name: patient.caregiver_last_name_primary,
        caregiver_phone_number: patient.caregiver_phone_number_primary,
        caregiver_relation: patient.caregiver_relation_primary,
        type: "first"
    }];
    if (patient.caregiver_first_name_second !== "" && patient.caregiver_last_name_second !== "" && patient.caregiver_phone_number_second !== "" && patient.caregiver_relation_second !== "All") {
        caregivers.push({
            caregiver_first_name: patient.caregiver_first_name_second,
            caregiver_last_name: patient.caregiver_last_name_second,
            caregiver_phone_number: patient.caregiver_phone_number_second,
            caregiver_relation: patient.caregiver_relation_second,
            type: "second",
        });
    }
    if (patient.caregiver_first_name_third !== "" && patient.caregiver_last_name_third !== "" && patient.caregiver_phone_number_third !== "" && patient.caregiver_relation_third !== "All") {
        caregivers.push({
            caregiver_first_name: patient.caregiver_first_name_third,
            caregiver_last_name: patient.caregiver_last_name_third,
            caregiver_phone_number: patient.caregiver_phone_number_third,
            caregiver_relation: patient.caregiver_relation_third,
            type: "third",
        });
    }
    return caregivers;
};

export const makeAddressObject = (patient) => {
    const address = {
        address1: patient.patient_address1,
        address2: patient.patient_address2,
        zip: patient.zip,
        city: patient.city,
        state: patient.state,
    };
    return address;
};
export const makeDiseaseObject = (patient) => {
    let diseases = [];
    for (const item of Object.entries(patient.infectious_disease_protocol)) {
        if (item[1] === true && item[1] !== "") {
            if (item[1] !== "" && item[0] === patientBaseDiseases.other) {
                diseases.push(patient.infectious_disease_protocol.other_disease);
            } else {
                diseases.push(item[0]);
            }
        }
    }
    return diseases;
};

export const makePrescription = (patient) => {
    const prescription_required = patient.prescription_required;
    return prescription_required;
}

export const updatePatientDemographics = (patient, token, userData, setPatientId, patientId, setWasSuccessful, setIsLoading, enqueueSnackbar) => {
    const address = makeAddressObject(patient);
    fetch(`${endpoints.patients}${patientId}`, {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify({
            ...patient,
            address: address,
            user_id: userData.user_id,
            facility_id: patient.facility,
        })
    })
        .then(response => {
            notifySnack(enqueueSnackbar, response.status, snackbarMessages.updatePatientDemographics, true);
            setIsLoading(false);
            if (response.status === 200 || response.status === 201) {
                setWasSuccessful(true);
            } else {
                setWasSuccessful(false);
                console.log("Error when updating patient", response);
            }
        })
        .catch(err => {
            console.log('error', err);
            enqueueSnackbar("Something happened. Contact support.", {
                persist: false,
                variant: "error",
            });
        });
};

export const updatePatientNotes = (patient, token, patientId, initialNotes, userData, setWasSuccessful, setIsLoading, enqueueSnackbar) => {
    setIsLoading(true);
    let newNotes = patient.note.splice(initialNotes, patient.note.length);
    if (newNotes[newNotes.length - 1] === '') {
        newNotes.splice(newNotes.length - 1, 1);
    }

    let noteArray = [];
    for (const item of newNotes) {
        noteArray.push({description: item, user_id: userData.user_id});
    }
    fetch(`${endpoints.patientNotes}${patientId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify(noteArray)
    }).then(res => {
        notifySnack(enqueueSnackbar, res.status, snackbarMessages.updatePatientNotes, true);
        setIsLoading(false);
        if (res.status !== 201) {
            setWasSuccessful(false);
            console.log("Error when creating patient notes.")
        }
        setWasSuccessful(true);
    }).catch(err => {
        console.log('error', err);
        enqueueSnackbar("Something happened. Contact support.", {
            persist: false,
            variant: "error",
        });
    });
};

export const updatePatientDiseasesAndIcdCodes = (patient, token, patientId, setWasSuccessful, setIsLoading, enqueueSnackbar) => {
    setIsLoading(true);
    const diseases = makeDiseaseObject(patient, patientId);
    fetch(`${endpoints.patientDiseases}${patientId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify({diseases: diseases, icd_codes: patient.icd_codes, updateFromEditPatientFlag: true})
    }).then(res => {
        notifySnack(enqueueSnackbar, res.status, snackbarMessages.updatePatientDiseases, true);
        setIsLoading(false);
        if (res.status !== 201) {
            setWasSuccessful(false);
        }
        setWasSuccessful(true);
    }).catch(err => {
        enqueueSnackbar("Something happened. Contact support.", {
            persist: false,
            variant: "error",
        });
    });
};

export const getPatientDiseaseData = async (patientId, setIsLoading, enqueueSnackbar, history) => {
    setIsLoading(true);
    try {
        const diseaseCall = await fetch(endpoints.patientDiseases + patientId, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            }
        })
        if (diseaseCall.status === status.success) {
            const promiseData = await diseaseCall.json();
            setIsLoading(false);
            return promiseData.data;
        }
        return [];
    } catch (error){
        enqueueSnackbar("Something happened while retrieving diseases. Contact support.", {
            persist: false,
            variant: "warn",
        });
        return [];
    }
}

export const updatePatientDisease = async (history, patientId, diseases, enqueueSnackbar, setIsLoading) => {
    setIsLoading(true);
    try {
        const diseaseCall = await fetch(`${endpoints.patientDiseases}${patientId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            },
            body: JSON.stringify({diseases: diseases, updateFromEditPatientFlag: false})
        })

        if(diseaseCall.status === 201){
            setIsLoading(false);
            enqueueSnackbar(snackbarMessages.updatePatientDiseases, {
                persist: false,
                variant: "success",
            });
        }
    } catch (e){
        enqueueSnackbar("Something happened while updating diseases. Contact support.", {
            persist: false,
            variant: "warn",
        });
    }
}

export const updatePatientPrescription = (patient, token, patientId, setWasSuccessful, setIsLoading, enqueueSnackbar) => {
    setIsLoading(true);
    const prescription = patient.prescription_required;
    fetch(`${endpoints.patientPrescription}${patientId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify({prescription_required: prescription, icd_codes: patient.icd_codes})
    }).then(res => {
        notifySnack(enqueueSnackbar, res.status, snackbarMessages.updatePatientPrescription, true);
        setIsLoading(false);
        if (res.status !== 201) {
            setWasSuccessful(false);
        }
        setWasSuccessful(true);
    }).catch(err => {
        console.log('error', err);
        enqueueSnackbar("Something happened. Contact support.", {
            persist: false,
            variant: "error",
        });
    });
};

export const updatePatientCaregivers = (patient, token, patientId, setWasSuccessful, setIsLoading, enqueueSnackbar) => {
    setIsLoading(true);
    const caregivers = makeCaregiverObject(patient, token);
    fetch(`${endpoints.patientCaregivers}${patientId}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify({caregivers: caregivers})
    }).then(res => {
        notifySnack(enqueueSnackbar, res.status, snackbarMessages.updatePatientCaregivers, true);
        setIsLoading(false);
        if (res.status !== 201) {
            setWasSuccessful(false);
            console.log("Error when updating patient caregivers.")
        }
        setWasSuccessful(true);
    }).catch(err => {
        console.log('error', err);
        enqueueSnackbar("Something happened. Contact support.", {
            persist: false,
            variant: "error",
        });
    });
};

export const createPatient = (patient, token, userData, setPatientId, setWasSuccessful, setIsLoading, enqueueSnackbar, history) => {
    setIsLoading(true);
    let noteArray = [];
    const address = makeAddressObject(patient);
    const caregivers = makeCaregiverObject(patient);
    const diseases = makeDiseaseObject(patient);
    const prescription_required = makePrescription(patient)

    for (const item of patient.note) {
        noteArray.push({description: item, user_id: userData.user_id})
    }
    fetch(endpoints.patients, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token.accessToken
        },
        body: JSON.stringify({
            ...patient,
            caregivers: caregivers,
            address: address,
            diseases: diseases,
            prescription_required: prescription_required,
            user_id: userData.user_id,
            notes: noteArray,
        })
    })
        .then(response => {
            if (response.status === 201) {
                setIsLoading(false);
                setWasSuccessful(true);
                return response.json();
            } else {
                setIsLoading(false);
                setWasSuccessful(false);
            }
        })
        .then(response => {
            const {data} = response;
            setPatientId(data);
            setTimeout(() => {
                history.push(`/patients/patientProfile/${data}`)
            }, 2000);
        })
        .catch(err => {
            console.log('error', err);
            enqueueSnackbar("Something happened. Contact support.", {
                persist: false,
                variant: "error",
            });
        });
};

export const getPatientData = async (patientId, token, patient, setPatient, setMissingValues, setInitialNotes, setShowThirdCaregiver, initialNotes, enqueueSnackbar) => {
    if (patientId) {
        try {

            const patientPromise = await fetch(endpoints.getPatientData + patientId, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            });
            notifySnack(enqueueSnackbar, patientPromise.status);
            const patientData = await patientPromise.json();

            const notePromise = await fetch(`${endpoints.patientNotes}${patientId}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            });
            const noteData = await notePromise.json();
            notifySnack(enqueueSnackbar, noteData.status);
            setInitialNotes(noteData.data.length);

            const diseasePromise = await fetch(`${endpoints.patientDiseases}${patientId}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            });
            notifySnack(enqueueSnackbar, diseasePromise.status);
            const diseaseData = await diseasePromise.json();
            let infectious_disease_protocol = {...patient.infectious_disease_protocol};
            for (const item of diseaseData.data) {
                if (Object.keys(infectious_disease_protocol).includes(item.name)) {
                    infectious_disease_protocol[item.name] = true;
                } else {
                    infectious_disease_protocol.other = true;
                    infectious_disease_protocol.other_disease = item.name;
                }
            }
            const prescriptionPromise = await fetch(`${endpoints.patientPrescription}${patientId}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            });
            notifySnack(enqueueSnackbar, prescriptionPromise.status);
            const prescriptionData = await prescriptionPromise.json();
            setPatient(prevState => ({
                ...prevState,
               prescription_required: prescriptionData.data[0].prescription_required
            }));
            const caregiverPromise = await fetch(`${endpoints.patientCaregivers}${patientId}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            });
            notifySnack(enqueueSnackbar, caregiverPromise.status);
            const caregiverData = await caregiverPromise.json();
            for (const item of caregiverData.data) {
                switch (item.type) {
                    case "first":
                        setPatient(prevState => ({
                            ...prevState,
                            caregiver_first_name_primary: item.caregiver_first_name,
                            caregiver_last_name_primary: item.caregiver_last_name,
                            caregiver_phone_number_primary: item.caregiver_phone_number,
                            caregiver_relation_primary: item.caregiver_relation,
                            caregiver_first_id:item.caregiver_id,
                            caregiver_first_has_account:item.has_account,
                        }));
                        break;
                    case "second":
                        setPatient(prevState => ({
                            ...prevState,
                            caregiver_first_name_second: item.caregiver_first_name ?? "",
                            caregiver_last_name_second: item.caregiver_last_name ?? "",
                            caregiver_phone_number_second: item.caregiver_phone_number ?? "",
                            caregiver_relation_second: item.caregiver_relation ?? "All",
                            caregiver_second_id:item.caregiver_id,
                            caregiver_second_has_account:item.has_account,
                        }));
                        break;
                    case "third":
                        setPatient(prevState => ({
                            ...prevState,
                            caregiver_first_name_third: item.caregiver_first_name ?? "",
                            caregiver_last_name_third: item.caregiver_last_name ?? "",
                            caregiver_relation_third: item.caregiver_relation ?? "All",
                            caregiver_phone_number_third: item.caregiver_phone_number ?? "",
                            caregiver_third_id:item.caregiver_id,
                            caregiver_third_has_account:item.has_account,
                        }));
                        if (item.caregiver_full_name !== "" || item.caregiver_phone_number !== "" || item.caregiver_relation !== "All") {
                            setShowThirdCaregiver(true);
                        }
                        break;
                }
            }
            setPatient(prevState => ({
                ...prevState,
                first_name: patientData.data.first_name,
                last_name: patientData.data.last_name,
                MI: patientData.data.mi,
                medical_record: patientData.data.medical_record,
                phone_number: patientData.data.phone_number,
                care_setting: patientData.data.care_setting,
                team_id: patientData.data.team_id,
                facility: patientData.data.facility_id,
                date_of_birth: patientData.data.date_of_birth,
                gender: patientData.data.gender,
                patient_weight: patientData.data.patient_weight,
                patient_height_in_feet: Number(patientData.data.patient_height_in_feet),
                patient_height_in_inches: Number(patientData.data.patient_height_in_inches),
                infectious_disease_protocol: infectious_disease_protocol,
                // prescription_required: patientData.prescription_required,
                patient_address1: patientData.data.address1,
                patient_address2: patientData.data.address2,
                zip: Number(patientData.data.zip),
                city: patientData.data.city,
                state: patientData.data.state,
                nurse_id: Number(patientData.data.nurse_id),
                region_id: Number(patientData.data.region_id),
                hospice_organization_id: Number(patientData.data.hospice_organization_id),
                note: noteData.data,
                icd_codes: patientData.data.icd_codes
            }));
            setMissingValues(prevState => ({
                ...prevState,
                medical_record: false, first_name: false, last_name: false, MI: false,
                phone_number: false, care_setting: false, team_id: false, facility: false,
                date_of_birth: false, gender: false, patient_weight: false, patient_height_in_feet: false,
                patient_height_in_inches: false, infectious_disease_protocol: false, prescription_required: false, note: false,
                patient_address1: false, patient_address2: '', zip: false, city: false, state: false,
                caregiver_full_name_primary: false, caregiver_phone_number_primary: false,
                caregiver_relation_primary: false, caregiver_full_name_second: false,
                caregiver_phone_number_second: false, caregiver_relation_second: false, nurse_id: false,
                hospice_organization_id: false, region_id: false
            }))

        } catch (e) {
            console.log(`[Error] Encountered when getting patient data with stack: ${e.stack}`)
        }
    }
};
export const getZipErrorMessage = (zipStatus) => {
    switch (zipStatus) {
        case 0:
            return null;
        case 1:
            return errorMessages.zero_results;
        case 2:
            return errorMessages.error_occurred;
        default:
            return null;
    }
};

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const getMissionFieldMessages = (missingValues, activeStep, validContract, regionList, edit) => {
    const targetFields = activeStep === 0 ? ["first_name", "last_name", "patient_address1", "city", "state", "zip", "phone_number", "gender", "patient_weight", "patient_height_in_feet", "date_of_birth", "hospice_organization_id"] : ["caregiver_first_name_primary", "caregiver_last_name_primary", "caregiver_phone_number_primary", "caregiver_relation_primary"];
    let missingMessages = [];
    let keys = Object.keys(missingValues);
    for(const key of keys){
        if(targetFields.includes(key) && missingValues[key] === true){
            let formattedText= " ";
            if(key.includes("_")){
                let split = key.split("_");
                for (const item of split){
                    formattedText = formattedText + capitalize(item) + " ";
                }
            } else {
                formattedText += capitalize(key) + " ";
            }
            missingMessages.push(`Field "${formattedText}" is mandatory in order to proceed.`);
        }
    }
    if (validContract === false && regionList.length > 0 && !edit){
        missingMessages.push(`Field "Region" is mandatory in order to proceed.`);
    }
    return missingMessages;
}
