export const supportStyles = (theme) => {
    return {
        rootContainer: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: 48,
                paddingRight: 20,
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: 48,
                paddingRight: 10,
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 16,
                paddingRight: 16,
            },
        },
        root: {
            paddingTop: 48,
        },
        columnComponent: {
            // display:'flex',
            // justifyContent:'flex-start'
        },
        // issueTextField: {
        //     minWidth: 200,
        //     width: "90%",
        //     maxWidth: 500,
        // },
        titleContainer: {
            border: '1px solid #D5D9DC',
            backgroundColor: 'white',
            textAlign: 'left',
            minHeight: 600,
        },
        textColor: {
            color: "#4F86A0",
            "&:hover": {
                color: "grey"
            },
            fontSize: 14,
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'flex-start',
            textTransform: 'none'
        },
        border: {
            border: '1px solid #D5D9DC'
        },
        gridContainer: {
            backgroundColor: 'white',
            textAlign: 'left',
        },
        supportContainer: {
            backgroundColor: 'white',
            paddingBottom: 37,
            height: 300,
            paddingRight: 51,
            maxWidth: 1800,
        },
        serviceContainer: {
            backgroundColor: 'white',
            paddingBottom: 37,
            height: 148,
            paddingRight: 51,
            maxWidth: 1800,
        },
        url: {
            paddingTop: 36,
            paddingLeft: 49,
        },
        url2: {
            paddingTop: 24,
            paddingLeft: 49,
        },
        inputContainer: {
            paddingLeft: '36px',
        },
        serviceFormContainer: {
            paddingLeft: '36px',
            paddingTop: 48,
            width: '60%',
            backgroundColor: 'white',
            border: '1px solid #D5D9DC',
        },
        title: {
            paddingLeft: 50,
            paddingTop: 17,
            paddingBottom: 10,
            fontSize: 24,
            fontWeight: 'bold',
            color: "#00223D"
        },
        textItem: {
            width: '90%',
            paddingRight: 48,

        },
        links: {
            fontSize: 14,
            fontWeight: 'bold',
            color: "#4F86A0",
            textAlign: "left",
            "&:hover": {
                color: '#004175'
            }
        },
        flexStart: {
            justifyContent: 'flex-start'
        },
        h1: {
            textAlign: 'left',
            color: '#00223D',
            fontSize: 28,
            fontWeight: "bold",
            lineHeight: '38px',
            borderBottom: '1px solid #B2D1D9',
            marginBottom: 8
        },
        box: {
            height: 295,
            paddingBottom: 50,
            paddingRight: 12,
            maxWidth: 890,
            backgroundColor: '#FFF',
            border: '1px solid #D5D9DC',
        },
        h3: {
            fontSize: 24,
            lineHeight: '32px',
            fontWeight: 600,
            color: '#00223D',
            textAlign: 'left',
            paddingTop: 38,
            paddingLeft: 48
        },
        addRoute: {
            display: 'flex',
            marginLeft: 48,
            marginTop: 36,
        },
        h4: {
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 600,
            color: '#4F86A0',
            textAlign: 'left',
            paddingTop: 4,
            paddingLeft: 17
        },
        phone: {
            display: 'flex',
            marginLeft: 48,
            marginTop: 8
        },
        phoneText: {
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 400,
            color: '#4E6071',
            textAlign: 'left',
            paddingLeft: 43
        },
        emailContainer: {
            display: 'flex',
            marginLeft: 48,
            marginTop: 24,
        },
        email: {
            cursor: 'pointer'
        },
        emailText: {
            cursor: 'pointer',
            fontSize: 14,
            lineHeight: '20px',
            fontWeight: 600,
            color: '#4F86A0',
            textAlign: 'left',
            paddingLeft: 17,
            paddingTop: 3
        },
        supportEmail: {
            display: 'flex',
            marginLeft: 48,
            marginTop: 24
        },
        recent: {
            fontSize: 16,
            lineHeight: '20px',
            fontWeight: 600,
            color: '#686868',
            textAlign: 'left',
            paddingTop: 23,
            paddingLeft: 48
        },
        recentContainer: {
            display: 'flex',
        },
        recentItem: {
            cursor: 'pointer',
            fontSize: 14,
            color: '#3B748F',
            lineHeight: '20px',
            fontWeight: 600,
            textAlign: 'left',
            paddingTop: 10,
            paddingLeft: 48
        },
        recentSpacing: {
            cursor: 'pointer',
            fontSize: 14,
            color: '#3B748F',
            lineHeight: '20px',
            fontWeight: 600,
            textAlign: 'left',
            paddingTop: 10,
            paddingLeft: 24
        },
        item: {
            height: 300,
            padding: 30
        },
        text: {
            fontSize: 28,
            fontWeight: 'bold',
            padding: 20,
        },
        paper: {
            height: 140,
            width: 100,
        },
        background: {
            backgroundPosition: 'right bottom',
            height: '100%'
        },
        view: {
            display: 'flex',
            marginLeft: 48,
            marginTop: 36,
        },
        cursor: {
            cursor: "pointer"
        }
    }
};
