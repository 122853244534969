import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {endpoints} from "../../../constants/endpoints";
import ExportForm from "../../../common/exportForm/exportForm";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Table} from "../../../common/table/table";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReportsHeader from "../../../common/reportsHeader/reportsHeader";
import {ActiveRentalStyle} from "./ActiveRental.style";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Select from "react-select";
import jwt from 'jwt-decode';

export const SpecificActiveRental = props => {
    const isLogged = useSelector(state => state.isLogged);
    const classes = ActiveRentalStyle();
    const token = JSON.parse(localStorage.getItem('key'));
    const [results, setResults] = useState([]);
    const [exportValues, setExportValues] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [masterItems, setMasterItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [placeholderValue, setPlaceHolderValue] = useState([]);
    const [teamOptions, setTeamOptions] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState();
    const [selectedCareSetting, setSelectedCareSetting] = useState();
    const [selectedCategory, setSelectedCategory] = useState();
    const [categoriesList, setCategoriesList] = useState([]);
    const careSettingList = [
        {label: 'Patient Home', value: "Patient Home"},
        {label: "Facility", value: "Facility"},
        {label: "Inpatient unit", value: "Inpatient unit"}]

    const selectStyles = {
        menu: base => ({
            ...base,
            zIndex: 200,
            textAlign: 'left'
        })
    };
    const getCategoriesList = () => {
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getCategoriesMasterItems}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data.length > 0) {
                    let object = [];
                    for (let i = 0; i < response.data.length; i++) {
                        object.push({value: response.data[i].category, label: response.data[i].category})
                    }
                    const sortedCategoriesList = object.sort((a, b) => a.label.localeCompare(b.label));
                    setCategoriesList(sortedCategoriesList);
                } else {
                    setCategoriesList([]);
                }
            })
            .catch(err => console.log('error', err))
    };

    const generateReport = (payload) => {
        setIsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        let labelsArray = [];
        for (const item of selectedItems) {
            labelsArray.push(item.label)
        }
        let bodyPayload = {...payload, report_type: "FilteredActiveUtilization", master_items: labelsArray}
        if (selectedTeam?.value) {
            bodyPayload['team_name'] = selectedTeam.value;
        }
        if (selectedCategory?.value) {
            bodyPayload['item_category'] = selectedCategory.value;
        }
        if (selectedCareSetting?.value) {
            bodyPayload['care_setting'] = selectedCareSetting.value;
        }

        fetch(endpoints.getActiveRental, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(bodyPayload)
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setResults(response.data);
                setIsLoading(false);
            })
            .catch(err => {
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
                setIsLoading(false);
            })
    };
    const getAllMasterListItems = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        (async () => {
            const token = JSON.parse(localStorage.getItem('key'));
            const promise = await fetch(endpoints.getAllMasterItems, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + token.accessToken
                }
            });
            const response = await promise.json();
            let arr = [];
            response.data.map(item => {
                arr.push({
                    value: item.item_id,
                    label: item.name
                })
            });
            setMasterItems(arr);
        })();
    }
    const clearData = () => {
        setResults([]);
        setSelectedCareSetting(null);
        setSelectedTeam(null);
        setSelectedCategory(null);
        setSelectedItems([])
    };

    useEffect(() => {
        if (results !== undefined) {
            let final = [];
            results.map(value => {
                let newArr = [];
                newArr.push(value.hospice_name);
                newArr.push(value.patient_id);
                newArr.push(value.first_name);
                newArr.push(value.last_name);
                newArr.push(value.medical_record);
                newArr.push(value.item_name);
                newArr.push(new Date(value.delivery_date));
                newArr.push(value.team_name);
                newArr.push(value.care_setting);
                newArr.push(value.category);
                newArr.push(value.address1);
                newArr.push(value.address2);
                newArr.push(value.city);
                newArr.push(value.state);
                newArr.push(value.zip);
                newArr.push(value.facility_name);
                newArr.push(value.asset);
                final.push(newArr)
            });
            setExportValues(final);
        }
    }, [results]);
    useEffect(() => {
        getAllMasterListItems();
        getCategoriesList();
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        const decoded = jwt(token.accessToken);
        let type = decoded.payload.type;
        let endpoint;
        if (type === "Hospice") {
            endpoint = `${endpoints.getHospiceTeams}${decoded.payload.hospice_organization_id}`
        }
        if (type === "DME" && decoded.payload.role_id !== 6) {
            endpoint = `${endpoints.getAllHospiceTeamsByDmeId}${decoded.payload.dme_organization_id}`
        }
        if (decoded.payload.role_id === 6) {
            endpoint = `${endpoints.getAllHospicesAllTeams}`
        }
        fetch(endpoint, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let result = [];
                response.data.map(team => {
                    result.push({label: team.team_name, value: team.team_name})
                });
                setTeamOptions(result)
            })
            .catch(err => {
                console.log(err, 'error');
            })
    }, []);

    const headers = [
        {text: "Organization_Name", style: 'tableHeader'},
        {text: "Patient ID", style: 'tableHeader'},
        {text: "Patient First Name", style: 'tableHeader'},
        {text: "Patient Last Name", style: 'tableHeader'},
        {text: "Medical Record Number", style: 'tableHeader'},
        {text: "Item Name", style: 'tableHeader'},
        {text: "Delivery Date", style: 'tableHeader'},
        {text: "Team Name", style: 'tableHeader'},
        {text: "Care Setting", style: 'tableHeader'},
        {text: "Category", style: 'tableHeader'},
        {text: "Address1", style: 'tableHeader'},
        {text: "Address2", style: 'tableHeader'},
        {text: "City", style: 'tableHeader'},
        {text: "State", style: 'tableHeader'},
        {text: "Zip", style: 'tableHeader'},
        {text: "Facility Name", style: 'tableHeader'},
        {text: "Item Asset", style: 'tableHeader'},
    ];
    const excelHeaders = [
        "Organization_Name",
        "Patient_ID",
        "Patient_First_Name",
        "Patient_Last_Name",
        "Medical_Record_Number",
        "Item_Name",
        "Delivery_Date",
        "Team_Name",
        "Care_Setting",
        "Category",
        "Address1",
        "Address2",
        "City",
        "State",
        "Zip",
        "Facility_Name",
        "Item_Asset",
    ];
    return (
        <div className={classes.div}>
            {(isLogged) &&
                <Grid container className={classes.container}>
                    <Grid item xs={12}>
                        <Typography className={classes.maintitle}>Active Utilization Report</Typography>
                    </Grid>
                    <Grid item xs={12}
                          style={{fontSize: 20, fontWeight: 500, textAlign: 'left', paddingTop: 16, paddingLeft: 8}}>Master
                        List Items</Grid>
                    <Grid item xs={12} style={{paddingLeft: 6, paddingRight: 6, paddingBottom: 4}}>
                        <Select
                            placeholder={'Select Item*'}
                            options={masterItems.filter(master => !selectedItems.includes(master))}
                            styles={selectStyles}
                            onChange={(e) => {
                                setSelectedItems([...selectedItems, e]);
                                setPlaceHolderValue([]);
                            }}
                            value={placeholderValue}
                        />
                    </Grid>

                    <Grid item xs={12}
                          style={{fontSize: 20, fontWeight: 500, textAlign: 'left', paddingTop: 16, paddingLeft: 8}}>Selected
                        Items</Grid>
                    <Grid item xs={12} container justify={"flex-start"} alignContent={"flex-start"}
                          alignItems={"flex-start"}
                          style={{height: 160, overflowY: "scroll", background: "white", borderRadius: 4}}>
                        {selectedItems?.length > 0 ? selectedItems.map((item, idx) => {
                            return (
                                <Grid item xs={12} style={{
                                    maxHeight: 50,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    background: 'whitesmoke',
                                    borderRadius: 4,
                                    margin: 4,
                                    marginTop: idx === 0 ? 8 : 0,
                                    marginBottom: 8,
                                    padding: 8,
                                    paddingTop: 8
                                }} key={idx}>
                                    <Grid item style={{fontSize: 15, paddingLeft: 8, textAlign: 'left'}}>
                                        {item.label}
                                    </Grid>
                                    <Grid item>
                                        <IconButton size={"small"} onClick={() => {
                                            setSelectedItems([...selectedItems.filter(target => target.value !== item.value)]);
                                        }}>
                                            <HighlightOffIcon color={'primary'}/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )
                        }) : <Grid item xs={12} style={{
                            maxHeight: 50,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            alignContent: 'center',
                            background: 'whitesmoke',
                            borderRadius: 4,
                            margin: 4,
                            marginTop: 8,
                            marginBottom: 8,
                            padding: 8,
                            paddingTop: 8
                        }}>
                            <Grid item style={{fontSize: 15, paddingLeft: 8, textAlign: 'left'}}>
                                No Items Selected
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                    <Grid item container xs={12} spacing={2} style={{marginTop: 8, marginBottom: 8}}>
                        <Grid item xs={4}><Select
                            placeholder={'All Teams'}
                            options={teamOptions}
                            styles={selectStyles}
                            value={selectedTeam}
                            isClearable
                            onChange={(e) => {
                                setSelectedTeam(e);
                                e && setSelectedTeam(e);
                            }}
                        /></Grid>
                        <Grid item xs={4}><Select
                            placeholder={'All Care Settings'}
                            styles={selectStyles}
                            options={careSettingList}
                            value={selectedCareSetting}
                            isClearable
                            onChange={(e) => {
                                setSelectedCareSetting(e);
                                e && setSelectedCareSetting(e);
                            }}
                        />
                        </Grid>
                        <Grid item xs={4}><Select
                            placeholder={'All Categories'}
                            styles={selectStyles}
                            options={categoriesList}
                            value={selectedCategory}
                            isClearable
                            onChange={(e) => {
                                setSelectedCategory(e);
                                e && setSelectedCategory(e);

                            }}
                        />
                        </Grid>
                    </Grid>
                    <ReportsHeader generateReport={generateReport} clearData={clearData}/>
                    <Grid container className={classes.select}>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 20,
                            marginBottom: 12
                        }}>
                            <ExportForm
                                fileName='Active Utilization Report'
                                exportValues={exportValues}
                                headers={headers}
                                excelHeaders={excelHeaders}
                                showPrint={true}
                                landscape={true}
                            />
                        </div>
                        <Grid item xs={12} style={{overflow: 'hidden'}}>
                            <Table
                                showSearch={true}
                                search={true}
                                filtering={true}
                                columns={[{title: 'Organization', field: 'hospice_name'},
                                    {title: 'Patient ID', field: 'patient_id'},
                                    {title: 'First Name', field: 'first_name'},
                                    {title: 'Last Name', field: 'last_name'},
                                    {title: 'Medical Record Number', field: 'medical_record'},
                                    {title: 'Item Name', field: 'item_name'},
                                    {title: 'Delivery date', field: 'delivery_date'},
                                    {title: 'Team Name', field: 'team_name'},
                                    {title: 'Care Setting', field: 'care_setting'},
                                    {title: 'Category', field: 'category'},
                                    {title: 'Address1', field: 'address1'},
                                    {title: 'Address2', field: 'address2'},
                                    {title: 'City', field: 'city'},
                                    {title: 'State', field: 'state'},
                                    {title: 'Zip', field: 'zip'},
                                    {title: 'Facility Name', field: 'facility_name'},
                                    {title: 'Item Asset', field: 'asset'},
                                ]}
                                items={results}
                                backGroundColor={"rgba(237, 242, 244, 0.73)"}
                                textColor={"rgba(46, 50, 54, 1)"}
                            />
                        </Grid>
                    </Grid>
                    <Backdrop className={classes.backdrop} open={isLoading}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </Grid>
            }
        </div>
    )
};
