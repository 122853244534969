import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {dashboardStyle} from "../dashboard/dashboard.style";
import {useHistory} from "react-router-dom";
import PersonIcon from '@material-ui/icons/Person';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddBoxIcon from '@material-ui/icons/AddBox';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DescriptionIcon from '@material-ui/icons/Description';

export const InventoryDashboard = () => {
    const classes = useStyles();
    const history = useHistory();

    const iconMapper = (iconName) => {
        switch(iconName){
            case "PersonIcon": return <PersonIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "LocalHospitalIcon": return <LocalHospitalIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "SupervisorAccountIcon": return <SupervisorAccountIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "VerifiedUserIcon": return <VerifiedUserIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "AssignmentIcon": return <AssignmentIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "AddBoxIcon": return <AddBoxIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "FlashOnIcon": return <FlashOnIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "DescriptionIcon": return <DescriptionIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
        }
    }

    return (
        <Grid container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.h3} style={{padding: '15px 48px'}}>Inventory Dashboard</Typography>
                </Grid>
            </Grid>

            <Grid container style={{paddingLeft:16, paddingRight:16}}>
                {items.map((item,idx) => (
                    item.list.length >0 && <Grid item xs={12} sm={6} md={4} style={{padding:'5px', position: 'relative'}}>
                        <div
                            className={classes.box}
                            style={{borderBottom: idx % 2 === 0 ? '4px solid #4F86A0' : '4px solid #A3C7D2', minHeight: '420px'}}>
                            <Typography className={classes.h3} style={{display: 'flex', alignItems: 'center'}}>
                                {iconMapper(item.icon)}
                                <span style={{paddingLeft: '10px'}}>{item.title}</span>
                            </Typography>
                            {item.list.map((listItem,idx) => (
                                <div key={idx} style={{display:'flex', marginLeft: '48px', marginTop: '20px',fontSize: '24px',cursor: 'pointer'}} onClick={() => listItem.path && history.push(listItem.path)}>
                                    <Typography className={classes.h4}>{listItem.name}</Typography>
                                </div>
                            ))}
                        </div>
                    </Grid>
                ))}

            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles((theme) => (dashboardStyle(theme)));

//constants
const items = [
    {
        title: "Inventory",
        icon: "AddBoxIcon",
        list: [
            {
                name: "View Inventory Items",
                path: '/inventory/view'
            },{
                name: "Add Inventory Items",
                path: '/inventory/new'
            },
            {
                name: "Edit Existing Inventory Items",
                path: '/inventory/edit'
            },
            {
                name: "Add Item Lots",
                path: '/inventory/lots'
            },
            {
                name: "Print Inventory Form",
                path: '/inventory/print'
            },
            {
                name: "Add Inventory Form",
                path: '/inventory/add'
            },
        ]
    },
    {
        title: "Cleaning and Maintenance",
        icon: "AssignmentIcon",
        list: [
            {
                name: "Add Cleaning/Maintenance",
                path: '/inventory/cleaning/maintenance'
            },
        ]
    },
]
