import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import {labels} from "../../../../constants/inputs/labels";
import FormHelperText from "@material-ui/core/FormHelperText";
import {errorMessages, successMessages} from "../../../../constants/messages/responseMessages";
import Button from "@material-ui/core/Button";
import {buttonText} from "../../../../constants/alert/alertButtonTexts";
import {endpoints} from "../../../../constants/endpoints";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {forgotStyle} from "./forgotStyle";

export const ForgotEmailComponent = (props) => {

    const classes = useStyles();
    const token = JSON.parse(localStorage.getItem('key'));
    const [phone, setPhone] = useState(true);
    const [successMessage, setSuccessMessage] = useState("none");
    const [data, setData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
    });

    const [missing, setMissing] = useState({
        firstName: false,
        lastName: false,
        phone: false,
        email: false,
    });


    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setData({...data, [name]: value});
        value.length > 0 ? setMissing({...missing, [name]: false}) : setMissing({...missing, [name]: true});
    };

    const requestUsername = async () => {
        const promise = await fetch(endpoints.requestUsername, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({support: {...data, name: data.firstName + " " + data.lastName}})
        });

        const response = await promise.json();
        if (response.status === "success") {
            setSuccessMessage("success");
        } else {
            setSuccessMessage("error");
        }
    };
    return (
        <Grid container className={classes.container}>
            <Grid item xs={12} spacing={3} className={classes.paddingBottom}>
                <CssTextField
                    variant={"outlined"}
                    label={labels.first_name}
                    required={true}
                    name={"firstName"}
                    value={data.firstName}
                    error={missing.firstName}
                    onBlur={handleInputChange}
                    onChange={handleInputChange}
                    size={'small'}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} spacing={3} className={classes.paddingBottom}>
                <CssTextField
                    variant={"outlined"}
                    label={labels.last_name}
                    value={data.lastName}
                    required={true}
                    error={missing.lastName}
                    name={"lastName"}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    size={'small'}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} spacing={3} className={classes.paddingBottom}>
                <CssTextField
                    variant={"outlined"}
                    label={labels.phone}
                    value={data.phone}
                    required={true}
                    error={missing.phone}
                    name={"phone"}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    size={'small'}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            {/*<Grid item md={4} sm={4} xs={10} spacing={3} className={classes.paddingBottom}>*/}
            {/*    <InputLabel style={{*/}
            {/*        fontSize: '0.8em',*/}
            {/*        paddingBottom: '10px',*/}
            {/*        textAlign: 'left'*/}
            {/*    }}>{labels.requiredPhone}</InputLabel>*/}
            {/*    <PhoneInput*/}
            {/*        country={'us'}*/}
            {/*        placeholder={placeholders.phone}*/}
            {/*        disableCountryCode={true}*/}
            {/*        value={data.phone}*/}
            {/*        name={"phone"}*/}
            {/*        onlyCountries={['us']}*/}
            {/*        onChange={(e) => {*/}
            {/*            if (e.length === 10) {*/}
            {/*                setPhone(true)*/}
            {/*            }*/}
            {/*            handleInputChange({*/}
            {/*                target: {*/}
            {/*                    value: e,*/}
            {/*                    name: "phone"*/}
            {/*                }*/}
            {/*            })*/}
            {/*        }}*/}
            {/*        inputProps={{*/}
            {/*            label: 'test',*/}
            {/*            required: true,*/}
            {/*        }}*/}
            {/*        onBlur={(e) => {*/}
            {/*            if (e.target.value.length === 14) {*/}
            {/*                setPhone(true)*/}
            {/*            } else {*/}
            {/*                setPhone(false)*/}
            {/*            }*/}
            {/*        }}*/}
            {/*        isValid={phone}*/}
            {/*    />*/}
            {/*    <FormHelperText style={{*/}
            {/*        color: 'red',*/}
            {/*        textAlign: 'left'*/}
            {/*    }}>{!phone && errorMessages.invalid_phone}</FormHelperText>*/}
            {/*</Grid>*/}
            <Grid xs={12} spacing={3} className={classes.paddingBottom}>
                <CssTextField
                    variant={"outlined"}
                    required={true}
                    label={labels.email}
                    name={"email"}
                    value={data.email}
                    error={missing.email}
                    onChange={handleInputChange}
                    onBlur={handleInputChange}
                    size={'small'}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                />
            </Grid>
            <Grid item xs={12} className={classes.buttonContainer}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={requestUsername}
                    className={classes.button}
                    disabled={data.email.length === 0 || data.firstName.length === 0 || data.lastName.length === 0 || data.phone.length === 0}
                >
                    {buttonText.requestUsername}
                </Button>
            </Grid>
            {successMessage === "error" && <FormHelperText style={{
                color: 'red',
                textAlign: 'center',
                fontSize: 20,
            }}>{errorMessages.mailingError}</FormHelperText>}
            {successMessage === "success" && <FormHelperText style={{
                color: 'green',
                textAlign: 'center',
                fontSize: 20,
            }}>{successMessages.mailingSuccess}</FormHelperText>}
        </Grid>
    )
};

const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#8C8B8B',
        },
        '& label.Mui-focused': {
            color: '#93BBC9',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#93BBC9',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#93BBC9',
            },
            '&:hover fieldset': {
                borderColor: '#93BBC9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#93BBC9',
            },
        },
    },
})(TextField);

const useStyles = makeStyles((theme) => (forgotStyle));
