import {makeStyles} from "@material-ui/core/styles";

export const ActiveRentalStyle = makeStyles((theme) => ({
    div: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignContent: "center",
        alignItems: "center"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        paddingTop: 12,
        paddingRight:8
        // maxWidth: 1200,
    },
    title: {
        textAlign: "left",
        padding: 12,
        paddingBottom: 0,
        width: 200
    },
    maintitle: {
        textAlign: "left",
        padding: 12,
    },
    select: {
        padding: 12,
        display: "flex",
        justifyContent: "flex-end"

    },
    org: {
        border: '1px solid lightgrey',
        borderRadius: 4,
        marginRight: 12,
        // height: 40,
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        padding: "9px 0px",
    },
    region: {
        border: '1px solid lightgrey',
        height: 40,
        borderRadius: 4,
        marginBottom: 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
    }
}));
