import React, {useState} from 'react';
import Button from "@material-ui/core/Button";
import {Dialog, IconButton} from "@material-ui/core";
import NewCreateOrder from "../../pages/createOrder/newCreateOrder";
import Grid from "@material-ui/core/Grid";
import RequestPickup from "../../pages/requestPickup/requestPickup";
import CheckInventory from "../checkInventory/checkInventory";
import RequestExchange from "../../pages/requestExchange/requestExchange";
import ToolIcon from "../../assets/tool.svg";
import {Person, PlusOne} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import {useHistory} from "react-router-dom";

export function NewRequest({user, getPatientOrders, isPace, address, name,autoPickupTrigger,autoPickupReason, fullWidth, hasItems}){
    const [open, setOpen] = useState(false);
    const history = useHistory();
    function closeMobileModal(){
        setOpen(false);
    }
    return (
        <React.Fragment>
            <Button onClick={()=> setOpen(true)} variant={'contained'}
                    fullWidth={fullWidth}
                    style={{
                        backgroundColor: fullWidth ? '#00223D' :'#4F879F',
                        color:'white',
                        textTransform:'uppercase',
                        fontWeight:'bold',
                        maxWidth:420
                    }}
            ><AddIcon style={{marginRight:4}}/> New Request</Button>
            <Dialog open={open} onClose={()=>setOpen(false)}>
                <Grid container direction={'column'} alignItems={'center'} style={{padding:16}}>
                    <Grid container justify={'space-between'} alignContent={'center'} alignItems={'center'} style={{fontSize:16, fontWeight:'bold', paddingBottom:8, textAlign:'center'}}>
                        <span style={{paddingLeft:16}}>Select Order Type</span>
                        <IconButton onClick={()=> closeMobileModal()}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                    {hasItems && user.status !== 'Active' ? (
                        <RequestPickup
                            openOrder={false}
                            showPickup={true}
                            name={name}
                            address={address}
                            patient_id={user.patient_id}
                            getPatientOrders={getPatientOrders}
                            closeMobileModal={closeMobileModal}
                            mobileButton={true}
                        />
                    ) : (
                        <React.Fragment>
                            <Grid item style={{padding:16}}>
                                <NewCreateOrder
                                    user={user}
                                    getPatientOrders={getPatientOrders}
                                    isPace={isPace}
                                    closeMobileModal={closeMobileModal}
                                    mobileButton={true}
                                />
                            </Grid>
                            <CheckInventory patient_id={user.patient_id} width={true}>
                                <Grid item style={{padding:32, paddingTop:16}}>
                                    <RequestPickup
                                        openOrder={false}
                                        showPickup={true}
                                        disabled={(user.status === "Active" || ([11, 15].includes(Number(user.hospice_organization_id))))}
                                        name={name}
                                        address={address}
                                        patient_id={user.patient_id}
                                        getPatientOrders={getPatientOrders}
                                        closeMobileModal={closeMobileModal}
                                        mobileButton={true}
                                    />
                                    <RequestPickup
                                        openOrder={false}
                                        showPickup={false}
                                        name={name}
                                        autoTrigger={autoPickupTrigger}
                                        address={address}
                                        reason={autoPickupReason}
                                        patient_id={user.patient_id}
                                        getPatientOrders={getPatientOrders}
                                    />
                                </Grid>
                                <Grid item style={{paddingBottom:16}}>
                                    {(user.status === "Active" || ([11, 15].includes(Number(user.hospice_organization_id)))) ?
                                        <RequestExchange
                                            openOrder={false}
                                            name={name}
                                            address={address}
                                            patientId={user.patient_id}
                                            hospice_id={user.hospice_organization_id}
                                            region_id={user.region_id}
                                            getPatientOrders={getPatientOrders}
                                            closeMobileModal={closeMobileModal}
                                            mobileButton={true}
                                        />
                                        :
                                        <Button
                                            variant="contained"
                                            disabled
                                            size="small"
                                            startIcon={<img
                                                src={ToolIcon}
                                                alt="logo"
                                                width='16'
                                                height='15'
                                            />}
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 'bold',
                                                width: 200,
                                                height: 60
                                            }}
                                        >
                                            NEW EXCHANGE
                                        </Button>
                                    }
                                </Grid>
                            </CheckInventory>
                            <Grid item xs={12} style={{paddingTop: 16}}>
                                <Button
                                    variant="contained"
                                    size="small"
                                    startIcon={<Person
                                        alt="logo"
                                        width='16'
                                        height='15'
                                    />}
                                    onClick={() => history.push({
                                        pathname: `/patients/patientProfile/${user.patient_id}`,
                                        patient_id: user.patient_id
                                    })}
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        width: 200,
                                        height: 60
                                    }}
                                >
                                    GO TO PROFILE
                                </Button>
                            </Grid>
                        </React.Fragment>
                    )}

                </Grid>

            </Dialog>
        </React.Fragment>
    )
}
