import React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {parseTimeWithUserTimezone} from "../../../../common/timeHandler/timeHandler";
import {maxInputLength} from "../../../../constants/inputs/values";

export const OrderNotes = ({order, setIsUnsavedNote, setCurrentNote, currentNote, setShowWarning, showWarning, addOrderNote}) => {
    const classes = useStyles();

    return (
        <div style={{paddingBottom: '8px'}}>
            <div className={classes.notes}>
                {order.notes && (
                    <div>
                        {order.notes.map(note => {
                            return (
                                <React.Fragment>
                                    <div className={classes.noteContainer}>
                                        <div>{note.description}</div>
                                        <div className={classes.note}>
                                            {`${note.first_name} ${note.last_name}, ${parseTimeWithUserTimezone(note.created_date)}`}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}
                    </div>
                )}
                <TextField
                    placeholder="Some additional notes can be put here..."
                    label='Add additional note'
                    multiline
                    name='note'
                    rows={2}
                    fullWidth
                    rowsMax={9}
                    variant={'outlined'}
                    value={currentNote}
                    className={classes.noteInput}
                    onChange={(e) => {
                        setCurrentNote(e.target.value);
                        if (e.target.value !== "") {
                            setIsUnsavedNote(true);
                        } else {
                            setIsUnsavedNote(false);
                            setShowWarning(false);
                        }
                    }}
                    onBlur={(e) => {
                        if (currentNote !== "") {
                            setShowWarning(true);
                        } else {
                            setShowWarning(false);
                        }
                    }}
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{maxLength: maxInputLength.L1000}}
                    helperText={currentNote && `${currentNote.length < maxInputLength.L1000 ? currentNote.length.toString() + `/${maxInputLength.L1000}` : currentNote.length.toString() + `/${maxInputLength.L1000} You have reached the character limit for one note.`}`}
                    error={currentNote && currentNote.length === maxInputLength.L1000}
                />{showWarning && (
                <p style={{textAlign: 'left', color: '#FF0000'}}>You may have forgotten to submit a note.</p>)}
                <div className={classes.button}>
                    <Button
                        disabled={currentNote === ''}
                        variant={"contained"}
                        color={"primary"}
                        onClick={addOrderNote}
                        style={{fontWeight: 'bold', fontSize: 12, textColor: 'white'}}
                        onMouseDown={(e) => e.preventDefault()}
                    >ADD NOTE</Button>
                </div>
            </div>
        </div>
    )
};
const useStyles = makeStyles((theme) => ({
    noteSection: {
        padding: "16px 24px",
        fontSize: 20,
    },
    notes: {
        paddingTop: 12,
    },
    noteContainer: {
        display: 'flex',
        backgroundColor: '#F9F9F9',
        borderRadius: 2,
        padding: 16,
        marginBottom: 12,
        justifyContent: 'space-between',
        fontSize: 14,
        color: '#2E3236'
    },
    note: {
        fontStyle: 'italic',
        color: "#5D6770",
    },
    button: {
        alignContent: 'right',
        justifyContent: 'flex-end',
        display: 'flex',
        paddingTop: '10px',
        width: '100%',
        paddingBottom: 24
    }
}));
