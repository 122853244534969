import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";
import {makeStyles} from "@material-ui/core/styles";
import {BulkStyle} from "../bulkUpload.style";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {checkTokenAvailability} from "../../../../../common/utils/utils";
import {logout} from "../../../../../redux/actions";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {endpoints} from "../../../../../constants/endpoints";
import jwt from 'jwt-decode';
import XLSX from "xlsx";
import _ from "lodash";
import {useSnackbar} from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";

export const BulkMedicalRecordUpload = ({}) => {

    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const selectRef = React.useRef();
    const fileRef = React.useRef();
    const token = JSON.parse(localStorage.getItem('key'));
    const [hospiceData, setHospiceData] = useState([]);
    const [patients, setPatients] = useState([]);
    const [selectedHospice, setSelectedHospice] = useState();
    const [loadingHospices, setLoadingHospices] = useState(true);
    const [loadingDocument, setLoadingDocument] = useState(false);
    const userData = jwt(token.accessToken).payload;
    const excelHeaders = [
        "Patient_Id",
        "First_Name",
        "Last_Name",
        "Old_Medical_Record",
        "New_Medical_Record"
    ];

    const getHospicePatients = async (hospiceObj) => {
        setLoadingDocument(true);
        setPatients([]);
        if (hospiceObj !== null) {
            const promise = await fetch(`${endpoints.getAllActivePatientsHospiceView}${hospiceObj.value}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            });
            const response = await promise.json();
            if (response.status === "success") {
                setPatients(response.data);
            }
            setLoadingDocument(false);
        }
    };

    const prepareDocument = () => {
        let exportData = [excelHeaders];
        for (const patient of patients) {
            exportData.push([patient.patient_id, patient.first_name, patient.last_name, patient.medical_record])
        }

        const ws = XLSX.utils.aoa_to_sheet(exportData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Hospice_MRN_Upload");
        XLSX.writeFile(wb, `Hospice_${selectedHospice.label}_MRN_Upload.xlsx`)
    };

    const handleUpload = async (e) => {
        let uploadData = [];
        let validPatients = [];
        let invalidPatients = [];
        let files = e.target.files, f = files[0];
        let reader = new FileReader();
        reader.onload = async function (e) {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, {type: 'array'});

            const wsname = workbook.SheetNames[0];
            const ws = workbook.Sheets[wsname];

            const dataParse = XLSX.utils.sheet_to_json(ws, {header: 1});
            dataParse.map((row, i) => {
                if (i > 0) {
                    uploadData.push({
                        patientId: row[0],
                        firstName: row[1],
                        lastLame: row[2],
                        oldMedicalRecord: row[3],
                        newMedicalRecord: row[4],
                    })
                }
            });
            for(const patient of uploadData){
                    if(_.findIndex(patients, {"patient_id": patient.patientId}) !== -1){
                        validPatients.push(patient);
                    }
                    if(_.findIndex(patients, {"patient_id": patient.patientId}) === -1){
                        invalidPatients.push({
                            ...patient,
                            type: "PatientNotBelongingToHospice"
                        });
                    }
            }
            const promise = await fetch(`${endpoints.medicalRecordBulk}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    patients: validPatients,
                    invalidPatients: invalidPatients,
                    hospiceId: selectedHospice.value,
                    email: userData.email,
                })
            });
            const response = await promise.json();
            if (response.status === "success") {
                enqueueSnackbar("Updated information successfully.", {variant: "success"});
            } else {
                enqueueSnackbar("Something happened while updating information", {variant: "warning"});
            }

        };
        reader.readAsArrayBuffer(f);


        setPatients([]);
        selectRef.current.select.clearValue();
        fileRef.current.value = '';
        setLoadingDocument(false);
    };

    useEffect(() => {
        (async () => {
            if (token !== null) {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
                const promise = await fetch(`${endpoints.getHospicesByDme}${userData.dme_organization_id}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                });
                const response = await promise.json();
                if (response.status === "success") {
                    setHospiceData(response.data);
                }
                setLoadingHospices(false);
            }
        })();
    }, []);

    return (
        <Grid container alignItems={"flex-end"} justify={"flex-start"} alignContent={"flex-end"}
            style={{padding:24, maxWidth:1000, background:'white', marginTop:64, borderRadius:4}}
        >
            <Grid item xs={12}>
                <Typography variant={"h6"} style={{fontWeight:'bold', paddingBottom:16, textAlign:'left'}}>
                    Medical Record Bulk Upload
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}
                  className={classes.selectInput}>
                <Select
                    isCearable
                    ref={selectRef}
                    isLoading={loadingHospices}
                    options={hospiceData.map(item => {
                        return {label: item.hospiceName, value: item.hospice_id}
                    })}
                    onChange={e => {
                        getHospicePatients(e);
                        setSelectedHospice(e);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={6} style={{display:'flex'}}>
                <Button variant="outlined" component="span" className={classes.button} disabled={patients.length === 0}
                        onClick={() => prepareDocument()}>
                    <GetAppIcon className={classes.icon}/>
                    {loadingDocument ? <React.Fragment> <span style={{paddingRight:8}}>Download Template</span> <CircularProgress color={"grey"} size={24}/></React.Fragment> : "Download Template"}
                </Button>
                <input
                    style={{display: 'none'}}
                    id="raised-button-file"
                    type="file"
                    onChange={handleUpload}
                    ref={fileRef}
                />
                <label htmlFor="raised-button-file">
                    <Button variant="outlined" component="span" className={classes.button}
                            disabled={patients.length === 0}>
                        <CloudUploadIcon className={classes.icon}/>
                        Upload Template
                    </Button>
                </label>
            </Grid>
        </Grid>
    )
};

const useStyles = makeStyles((theme) => BulkStyle(theme));
