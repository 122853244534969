import React from 'react';
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import FormHelperText from "@material-ui/core/FormHelperText";
import {titles} from "../../../constants/inputs/tableColumnTitles";
import moment from 'moment';

export default function ExchangeMobileUi(props){
    const {patientInventory, handleInput }= props

    return <Grid container style={{paddingBottom:16}}>
                        <Grid container justify={'space-between'} style={{background:'#F2F5F7', padding:'12px 16px', borderRadius:4}}>
                            <Grid item xs={8} style={{fontSize:12, fontWeight:'bold'}}>
                                Item
                            </Grid>
                            <Grid item style={{fontSize:12, fontWeight:'bold', minWidth:100, textAlign:'left'}}>
                                Exchange
                            </Grid>
                        </Grid>
                        <Grid container style={{maxHeight:460, overflowY:'scroll'}} >
                            {patientInventory.map(item => {
                                return (
                                    <Grid container justify={'space-between'} alignItems={'center'} alignContent={'center'} style={{background:'#F9FAFC', padding:'10px 16px', borderBottom:'1px solid #E0E0E0'}}>
                                        <Grid item xs={6} style={{fontSize:12, fontWeight:'bold'}}>
                                            <Grid container>
                                                <Grid item xs={12} style={{paddingBottom:4}}>
                                                    {item.name ? item.name : item.item_name} <span style={{color:'gray'}}>
                                                    {item.asset ? `#${item.asset}` : ""}
                                            </span>
                                                </Grid>

                                                <Grid item xs={12} style={{color:'gray'}}>
                                                    <span style={{fontWeight:400}}>Provider:</span> {item.delivery_dme_name ? item.delivery_dme_name : item.exchange_dme_name}
                                                </Grid>
                                                <Grid item xs={12} style={{color:'gray'}}>
                                                    <span style={{fontWeight:400}}>Delivery Date:</span>   {moment(item.delivery_completed_date ? item.delivery_completed_date : item.exchange_completed_date).format('L')}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item style={{fontSize:12, fontWeight:'bold', minWidth:100,alignItems:'center', alignContent:'center', textAlign:'center', display:'flex'}}>
                                            <Switch checked={item.pickup}
                                                    disabled={!props.dmeId === item.dmeId}
                                                    onChange={e => {
                                                        const obj = {
                                                            target: {
                                                                ...e,
                                                                name: "pickup",
                                                                findItem: {
                                                                    ...item,
                                                                },
                                                                value: !item.pickup,

                                                            }
                                                        };
                                                        handleInput(obj);
                                                    }}
                                                    name="status"
                                                    color="primary"
                                            />

                                            <FormHelperText>
                                                {item.pickup && titles.pickup}
                                            </FormHelperText>
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
}

