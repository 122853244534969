import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
    Stepper,
    Step,
    StepLabel,
    StepContent,

  } from '@material-ui/core';
  import moment from "moment";

export default function AddressHistory({ historyAddress = [] }) {
  const [showModal, setShowModal] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  return (
    <React.Fragment>
      <Button
        variant="outlined"
        color="primary"
        style={{ height: 36 }}
        onClick={() => setShowModal(true)}
      >
        Address history
      </Button>
      <Dialog
        open={showModal}
        onClose={() => setShowModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        fullWidth
      >
        <DialogContent>
          <Grid
            container
            justify={"space-between"}
            alignContent={"center"}
            alignItems={"center"}
          >
            <Grid item style={{ fontSize: 24, fontWeight: "bold" }}>
              Address history
            </Grid>
          </Grid>
          {historyAddress.length > 0 ? (
            <Stepper activeStep={activeStep} orientation="vertical">
              {historyAddress.map((step, index) => (
                <Step
                  key={step.address_id}
                  onClick={() => setActiveStep(index)}
                >
                  <StepLabel
                    style={{ cursor: "pointer" }}
                    optional={
                      <Typography variant="caption">
                        Added by {step.username}
                      </Typography>
                    }
                  >
                    {moment(step.start_date).utc().format("LLL")} -{" "}
                    {step.end_date !== null
                      ? moment(step.end_date).utc().format("LLL")
                      : "present"}
                  </StepLabel>
                  <StepContent>
                    <Typography>Address 1: {step.address1}</Typography>
                    <Typography>
                      Address 2: {step.address2 ? step.address2 : "-"}
                    </Typography>
                    <Typography>Zip: {step.zip}</Typography>
                    <Typography>City: {step.city}</Typography>
                    <Typography>State: {step.state}</Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          ) : (
            <Typography>No addresses</Typography>
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
