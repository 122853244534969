import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Fab, Tooltip} from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import {makeStyles} from '@material-ui/core/styles';
import {FiltersDrawer} from "./filtersDrawer";

export function AllOrdersMobileView(props) {
    const {
        getPatients,
        filtersValue,
        handleDeleteCriteria,
        handleInputKeyup,
        handleInputChange,
        clearFilters,
        filterOptions,
        searchPatients,
        handleFilterUpdate,
        placeholder,
        buttonText,
        searchType,
        setSearchType,
        setRowsPerPage,
        applyFilters,
    } = props;
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    return (
        <Grid container>
            <Grid container style={{
                fontSize: 24,
                paddingTop: 16,
                paddingLeft: 4,
                marginLeft: 12,
                marginRight: 12,
                paddingBottom: 0,
                borderBottom: '1px solid #B2D1D9',
                fontWeight: 'bold'
            }}>
                Orders
            </Grid>
            <Grid container style={{padding: 8, gap: 12, maxHeight: 'calc(100vh - 128px)', overflow: 'scroll'}}>
                {props.children}
            </Grid>
            <Tooltip title={'Apply Filters'}>
                <Fab
                    className={classes.fabRight}
                    color={"primary"}
                    onClick={() => setOpen(true)}
                >
                    <FilterListIcon/>
                </Fab>
            </Tooltip>
            <FiltersDrawer
                open={open}
                setOpen={setOpen}
                getPatients={getPatients}
                filtersValue={filtersValue}
                handleDeleteCriteria={handleDeleteCriteria}
                handleInputKeyup={handleInputKeyup}
                handleInputChange={handleInputChange}
                clearFilters={clearFilters}
                filterOptions={filterOptions}
                searchPatients={searchPatients}
                handleFilterUpdate={handleFilterUpdate}
                placeholder={placeholder}
                buttonText={buttonText}
                searchType={searchType}
                setSearchType={setSearchType}
                setRowsPerPage={setRowsPerPage}
                applyFilters={applyFilters}
            />
        </Grid>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
        position: 'relative',
        minHeight: 200,
    },
    fabRight: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        boxShadow: '0 2px 4px 0 hsl(207, 26%, 15%)',
    },
    fabLeft: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
}));
