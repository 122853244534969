import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {CustomInputField} from "../../../../common/InputField/InputField";
import {validationTypes} from "../../../../constants/validations/validationTypes";
import InputLabel from "@material-ui/core/InputLabel";
import PhoneInput from "react-phone-input-2";
import FormHelperText from "@material-ui/core/FormHelperText";
import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {ContactForm} from "../../../../common/forms/ContactForm/SimpleParameterFrom";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import {FormControlLabel} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import {labels} from "../../../../constants/inputs/labels";
import {getMessage} from "../../../../constants/messages/messages";
import {messageTypes} from "../../../../constants/types/messageTypes";
import {sectionTitles} from "../../../../constants/names/titles";
import {tooltips} from "../../../../constants/inputs/tooltips";
import {placeholders} from "../../../../constants/inputs/paceholders";
import {endpoints} from "../../../../constants/endpoints";
import {organizations} from "../../../../constants/types/role";
import {HospiceDetailsStyle, HospiceProviderStyle} from "../HospiceProviderIndex.style";
import {inputType} from "../../../../constants/types/inputType";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import BootstrapInput from "../../../../common/bootstrapInput/bootstrapInput";
import MenuItem from "@material-ui/core/MenuItem";
import moment from "moment";

export const HospiceGeneralInformations = (props) => {

    const {
        handleInput, hospiceId, hospice, missingValues, validType, setMissingValues,
    } = props;
    const [duplicate, setDuplicate] = useState(false);
    const classes = useStyles();
    const [phone, setPhone] = useState(true);
    const [phone2, setPhone2] = useState(true);

    return (
        <React.Fragment>
            <Typography variant="h6">
                Hospice Details
            </Typography>
            <Grid container spacing={3} style={{display: "flex", justifyContent: 'center', alignContent: 'center'}}>
                <Grid item md={10} sm={12}>
                    <Grid item sm={12}>
                        <Grid item sm={12} className={classes.statusSection}>
                            <Grid container spacing={2}>
                                <Grid item md={4} sm={6} xs={12}>
                                    <div style={{display:'flex', paddingTop:16}}>
                                        <TextField
                                            id={"hospice_name"}
                                            name={"hospice_name"}
                                            helperText={missingValues.hospice_name && tooltips.isRequired}
                                            error={missingValues.hospice_name}
                                            label={labels.req_organization_name}
                                            fullWidth
                                            size={'small'}
                                            variant={'outlined'}
                                            value={hospice.hospice_name}
                                            onChange={e => {
                                                handleInput({
                                                    ...e,
                                                    inputEventType: inputType.required
                                                });
                                                setDuplicate(false);
                                                setMissingValues({...missingValues, hospice_name: false})
                                            }}
                                            onBlur={event => {
                                                (async () => {
                                                    if (event.target.value.length > 0) {
                                                        const token = JSON.parse(localStorage.getItem('key'));
                                                        const target = endpoints.hospice_organizations + event.target.value;
                                                        const getNamePromise = await fetch(target, {
                                                            headers: {
                                                                'Content-Type': 'application/json',
                                                                'Authorization': 'Bearer ' + token.accessToken
                                                            },
                                                        });
                                                        const getNameResponse = await getNamePromise.json();
                                                        if (getNameResponse.status === 'success') {
                                                            setDuplicate(true)
                                                        } else {
                                                            setDuplicate(false)
                                                        }
                                                    } else {
                                                        setMissingValues({...missingValues, hospice_name: true})
                                                    }
                                                })()
                                                handleInput({
                                                    ...event,
                                                    inputEventType: inputType.required
                                                });
                                            }}
                                        />
                                        <FormHelperText
                                            style={{color: 'red', textAlign: 'left'}}
                                        >
                                            {(duplicate) && (
                                                tooltips.nameAlreadyExists)
                                            }
                                        </FormHelperText>
                                        <div style={{width:80, paddingTop:8, textAlign:'right'}}>{hospiceId !== "" && `ID: #${hospiceId}`}</div>
                                    </div>

                                </Grid>
                                <Grid item md={8} sm={12} xs={12}>
                                    <Grid container justify={'flex-end'}>
                                        <Grid item style={{maxWidth:160}}>
                                            <FormControl component="fieldset" style={{paddingLeft:16}}>
                                                <FormLabel component="legend" style={{textAlign:'left'}}>
                                                    {sectionTitles.hospiceStatus}
                                                </FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel control={
                                                        <Switch checked={hospice.status}
                                                                onChange={e => {
                                                                    handleInput({
                                                                        ...e,
                                                                        inputEventType: inputType.standard
                                                                    });
                                                                }}
                                                                name="providerStatus"
                                                                color="primary"
                                                        />}
                                                    />
                                                </FormGroup>
                                                <FormHelperText>
                                                    {getMessage(messageTypes.hospiceStatus, {
                                                        hospice_name: hospice.hospice_name,
                                                        status: hospice.status
                                                    })}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Grid item style={{maxWidth:160}}>
                                            <FormControl component="fieldset" style={{paddingLeft:16}}>
                                                <FormLabel component="legend" style={{textAlign:'left'}}>
                                                    {sectionTitles.hospiceProfit}
                                                </FormLabel>
                                                <FormGroup>
                                                    <FormControlLabel control={
                                                        <Switch checked={hospice.profit}
                                                                onChange={e => {
                                                                    handleInput({
                                                                        ...e,
                                                                        inputEventType: inputType.standard
                                                                    });
                                                                }}
                                                                name="profit"
                                                                color="primary"
                                                        />}
                                                    />
                                                </FormGroup>
                                                <FormHelperText>
                                                    {getMessage(messageTypes.hospiceProfit, {
                                                        hospice_name: hospice.hospice_name,
                                                        status: hospice.profit
                                                    })}
                                                </FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item sm={12} className={classes.statusSection} style={{display:'flex', alignContent:'flex-end', alignItems:'flex-end'}}>
                            <Grid container>
                                <Grid item md={4} sm={6} xs={12} style={{paddingRight:16, display:'flex', alignContent:'flex-end', alignItems:'flex-end'}}>
                                    <CustomInputField
                                        isRequired={true}
                                        isMissing={missingValues.fiscal_year}
                                        name={"fiscal_year"}
                                        label={labels.fiscal_year}
                                        value={hospice.fiscal_year}
                                        onChange={e => {
                                            handleInput({
                                                ...e,
                                                inputEventType: inputType.required
                                            });
                                        }}
                                        onBlur={e => {
                                            handleInput({
                                                ...e,
                                                inputEventType: inputType.required
                                            });
                                        }}
                                        isValid={true}
                                        size={'small'}
                                    />
                                </Grid>
                                <Grid item sm={8} xs={12}>
                                    <Grid container>
                                        <Grid lg={6} md={7} sm={10} xs={7} style={{paddingTop:16}}>
                                            <InputLabel style={{
                                                fontSize: '0.8em',
                                                paddingBottom: '4px',
                                                textAlign: 'left'
                                            }}>{labels.requiredPhone}</InputLabel>
                                            <PhoneInput
                                                name="phone_number"
                                                label='test'
                                                country={'us'}
                                                disableCountryCode={true}
                                                placeholder={placeholders.phone}
                                                onlyCountries={['us']}
                                                value={hospice.phone ? hospice.phone : ""}
                                                onChange={(e) => {
                                                    if (e.length === 10) {
                                                        setPhone(true)
                                                    }
                                                    handleInput({
                                                        target:{
                                                            name: "phone",
                                                            value: e,
                                                            level: "primary",
                                                        },
                                                        inputEventType: inputType.required
                                                    });
                                                }}
                                                inputProps={{
                                                    label: 'test',
                                                    required: true,
                                                    id:'primary_phone'
                                                }}
                                                onBlur={(e) => {
                                                    if (e.target.value.length === 14) {
                                                        setPhone(true);
                                                    } else {
                                                        setPhone(false);
                                                    }
                                                }}
                                                isValid={phone}
                                            />
                                            <FormHelperText style={{
                                                color: 'red',
                                                textAlign: 'left'
                                            }}>{!phone && tooltips.invalidPhone}</FormHelperText>
                                            <FormHelperText style={{
                                                color: 'red',
                                                textAlign: 'left'
                                            }}>{missingValues.phone && tooltips.isRequired}</FormHelperText>
                                        </Grid>
                                        <Grid item style={{width:12}} />

                                        <Grid lg={5} md={7} sm={10} xs={7} style={{paddingTop:16}}>
                                            <InputLabel style={{
                                                fontSize: '0.8em',
                                                paddingBottom: '4px',
                                                textAlign: 'left'
                                            }}>{labels.secondaryPhone}</InputLabel>
                                            <PhoneInput
                                                name="phone_number2"
                                                label='test2'
                                                country={'us'}
                                                disableCountryCode={true}
                                                placeholder={placeholders.phone}
                                                onlyCountries={['us']}
                                                value={hospice.second_phone ? hospice.second_phone : ""}
                                                onChange={(e) => {
                                                    if (e.length === 10) {
                                                        setPhone(true)
                                                    }
                                                    handleInput({
                                                        target:{
                                                            name: "second_phone",
                                                            value: e,
                                                            level: "primary",
                                                        },
                                                        inputEventType: inputType.standard
                                                    });
                                                }}
                                                inputProps={{
                                                    label: 'test',
                                                    required: true,
                                                    id: 'secondary_phone'
                                                }}
                                                onBlur={(e) => {
                                                    if (e.target.value.length === 14) {
                                                        setPhone2(true)
                                                    } else {
                                                        setPhone2(false)
                                                    }
                                                }}
                                                isValid={phone2}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item sm={9} xs={7} className={classes.item}>


                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{paddingBottom:16}}>
                        <Grid container spacing={2} alignContent={'flex-end'} alignItems={'flex-end'}>
                            <Grid item lg={3} md={4} sm={6} xs={6}>
                                <CustomInputField
                                    isRequired={true}
                                    isMissing={missingValues.address1}
                                    name={"address1"}
                                    label={labels.address1}
                                    value={hospice.address1}
                                    onChange={e => {
                                        handleInput({
                                            ...e,
                                            inputEventType: inputType.required
                                        });
                                    }}
                                    onBlur={(e) => {
                                        handleInput({
                                            ...e,
                                            inputEventType: inputType.required
                                        });
                                    }}
                                    isValid={true}
                                    size={'small'}
                                />
                            </Grid>
                            <Grid item lg={2} md={3} sm={6} xs={6}>
                                <CustomInputField
                                    isRequired={false}
                                    name={"address2"}
                                    label={labels.address2}
                                    value={hospice.address2}
                                    onChange={e => {
                                        handleInput({
                                            ...e,
                                            inputEventType: inputType.standard
                                        });
                                    }}
                                    onBlur={(e) => {
                                        handleInput({
                                            ...e,
                                            inputEventType: inputType.standard
                                        });
                                    }}
                                    isValid={true}
                                    size={'small'}
                                />
                            </Grid>
                            <Grid item lg={3} md={3} sm={6} xs={6}>
                                <CustomInputField
                                    isRequired={true}
                                    isMissing={missingValues.city}
                                    validationType={validationTypes.onlyLetters}
                                    name={'city'}
                                    label={labels.city}
                                    value={hospice.city}
                                    onChange={e => {
                                        handleInput({
                                            ...e,
                                            inputEventType: inputType.required
                                        });
                                    }}
                                    onBlur={(e) => {
                                        handleInput({
                                            ...e,
                                            inputEventType: inputType.required
                                        });
                                    }}
                                    isValid={validType.city}
                                    size={'small'}
                                />
                            </Grid>
                            <Grid item lg={2} md={2} sm={6} xs={6}>
                                <CustomInputField
                                    isRequired={true}
                                    isMissing={missingValues.state}
                                    validationType={validationTypes.onlyLetters}
                                    name={'state'}
                                    label={labels.state}
                                    value={hospice.state}
                                    onChange={e => {
                                        handleInput({
                                            ...e,
                                            inputEventType: inputType.required
                                        });
                                        setDuplicate(false);
                                        setMissingValues({...missingValues, dme_name: false})
                                    }}
                                    onBlur={handleInput}
                                    isValid={validType.state}
                                    size={'small'}
                                />
                            </Grid>
                            <Grid item lg={2} md={3} sm={6} xs={6} style={{display:'flex'}}>
                                <div  style={{paddingRight:4}}>
                                    <TextField
                                        style={{width: '100%'}}
                                        helperText={missingValues.zip && tooltips.isRequired}
                                        error={missingValues.zip}
                                        required
                                        id="zip"
                                        name="zip"
                                        label={labels.zip}
                                        value={hospice.zip}
                                        variant={'outlined'}
                                        size={'small'}
                                        autoComplete="billing address-level2"
                                        onChange={(e) => {
                                            if (/^[0-9]*$/.test(e.target.value)) {
                                                handleInput({
                                                    ...e,
                                                    inputEventType: inputType.required
                                                });
                                            } else {
                                                handleInput({
                                                    ...e,
                                                    inputEventType: inputType.required
                                                });
                                            }
                                        }}
                                        onBlur={(e) => {
                                            if (/^[0-9]*$/.test(e.target.value)) {
                                                handleInput({
                                                    ...e,
                                                    inputEventType: inputType.required
                                                });
                                            } else {
                                                handleInput({
                                                    ...e,
                                                    inputEventType: inputType.required
                                                });
                                            }
                                        }}
                                    />
                                    <FormHelperText
                                        style={{color: 'red', textAlign: 'left'}}
                                    >
                                        {(!missingValues.zip && !validType.zip) && (
                                            tooltips.zipOnlyNumbers)}
                                    </FormHelperText>
                                </div>
                                <Tooltip title={tooltips.zip_autocomplete} arrow
                                         placement="top-end">
                                    <InfoOutlinedIcon style={{marginTop: '8px'}}/>
                                </Tooltip>
                            </Grid>
                            {/*<Grid item xs={12} />*/}
                            <Grid item md={3} sm={6} xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container>
                                        <KeyboardTimePicker
                                            margin="dense"
                                            id="time-start-hour"
                                            label="Start Hour:"
                                            style={{width:'100%'}}
                                            inputVariant={'outlined'}
                                            name={'start_hour'}
                                            ampm={false}
                                            value={hospice.start_hour ? `2020/02/02 ${hospice.start_hour}` : null}
                                            onChange={e => {
                                                let hour =  moment(e).format("HH:mm")
                                                handleInput({
                                                    target: {
                                                        name: "start_hour",
                                                        value: hour,
                                                    },
                                                    inputEventType: inputType.standard
                                                });
                                            }}
                                            onBlur={(e) => {
                                                let hour =  moment(e).format("HH:mm")
                                                handleInput({
                                                    target: {
                                                        name: "start_hour",
                                                        value: hour,
                                                    },
                                                    inputEventType: inputType.standard
                                                });
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={3} sm={6} xs={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid container>
                                        <KeyboardTimePicker
                                            margin="dense"
                                            id="time-end-hour"
                                            label="End Hour:"
                                            inputVariant={'outlined'}
                                            style={{width:'100%'}}
                                            value={hospice.end_hour ? `2020/02/02 ${hospice.end_hour}` : null}
                                            onChange={e => {
                                                let hour =  moment(e).format("HH:mm")
                                                handleInput({
                                                    target: {
                                                        name: "end_hour",
                                                        value: hour,
                                                    },
                                                    inputEventType: inputType.standard
                                                });
                                            }}
                                            onBlur={(e) => {
                                                let hour =  moment(e).format("HH:mm")
                                                handleInput({
                                                    target: {
                                                        name: "end_hour",
                                                        value: hour,
                                                    },
                                                    inputEventType: inputType.standard
                                                });
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={3} sm={6} xs={6} style={{display:'flex'}}>
                                <FormControl style={{width:'100%', textAlign:'left'}}>
                                    {/*<InputLabel style={{padding:0, margin:0}}>Time Zone:</InputLabel>*/}
                                    <Select
                                        style={{ paddingBottom:4}}
                                        labelId="timezone-select-label"
                                        id="timezone_select"
                                        name="timezone"
                                        input={<BootstrapInput/>}
                                        value={hospice.timezone ? hospice.timezone : 'All'}
                                        onChange={(e)=> {
                                            handleInput({
                                               ...e,
                                                inputEventType: inputType.standard
                                            });
                                        }}
                                    >
                                        <MenuItem value="All"><em> Select Timezone</em></MenuItem>
                                        <MenuItem value={`Hawaii Time [UTC-10]`}>Hawaii Time [UTC-10]</MenuItem>
                                        <MenuItem value={`Alaska Time [UTC-9]`}>Alaska Time [UTC-9]</MenuItem>
                                        <MenuItem value={`Pacific Time [UTC-8]`}>Pacific Time [UTC-8]</MenuItem>
                                        <MenuItem value={`Mountain Time [UTC-7]`}>Mountain Time [UTC-7]</MenuItem>
                                        <MenuItem value={`Central Time [UTC-6]`}>Central Time [UTC-6]</MenuItem>
                                        <MenuItem value={`Eastern Time [UTC-5]`}>Eastern Time [UTC-5]</MenuItem>
                                    </Select>
                                </FormControl>

                            </Grid>

                        </Grid>
                        <Grid container style={{maxWidth:1000, fontWeight:500, fontSize:20, paddingTop:16,marginTop:8,paddingBottom:8, borderTop:'1px solid whitesmoke'}}>
                            License / Permit Info
                        </Grid>
                        <Grid container style={{maxWidth:1000}} spacing={2}>
                            <Grid item md={4} sm={6} xs={6}>
                                <TextField
                                    name="hospice_medical_director"
                                    label={placeholders.hospiceMedicalDirector}
                                    placeholder={placeholders.hospiceMedicalDirector}
                                    value={hospice.hospice_medical_director}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onChange={e => {
                                        handleInput({
                                            ...e,
                                            inputEventType: inputType.required
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item md={4} sm={6} xs={6}>
                                <TextField
                                    name="hospice_pharmacy"
                                    label={placeholders.hospicePharmacy}
                                    placeholder={placeholders.hospicePharmacy}
                                    value={hospice.hospice_pharmacy}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onChange={e => {
                                        handleInput({
                                            ...e,
                                            inputEventType: inputType.required
                                        });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

const useStyles = makeStyles((theme) => (HospiceDetailsStyle(theme)));
