export const patientDataStyle = (theme) => {
    return {
        container: {
            [theme.breakpoints.up('md')]: {
                paddingLeft: 48,
                paddingRight: 20,
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: 48,
                paddingRight: 10,
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 16,
                paddingRight: 16,
            },
        },
        flexStart: {
            display:'flex',
            justifyContent: 'space-between',
            borderBottom: '1px solid #B2D1D9',
        },
        buttonGrey: {
            color: "#00223D",
            fontSize: 12,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            backgroundColor: '#FFF',
            "&:hover": {
                background: "#EBEBEB"
            },
            border: '1px solid #00223D',
            paddingTop:8
        },
        h1: {
            paddingTop: 58,
            textAlign: 'left',
            color: '#00223D',
            fontSize: 28,
            fontWeight: "bold",
            lineHeight: '38px',
        },
        root: {
            flexGrow: 1,
            maxWidth: 1920,
            height: '90vh',
            justifyContent: 'flex-start',
            alignContent: 'flex-start',
            [theme.breakpoints.up('md')]: {
                paddingLeft: 48,
                paddingRight: 20,
            },
            [theme.breakpoints.down('md')]: {
                paddingLeft: 48,
                paddingRight: 10,
            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 16,
                paddingRight: 16,
            },

        },
        secondContainer: {
            background: "white",
            border:'1px solid #F0F0F0',
            borderTop:'none',
        },
        top: {
            width:'100%', padding:16
        },
        topBox: {
            width:'100%',
            backgroundColor:'rgba(213, 217, 220, 0.16)',
            borderRadius:2,
            paddingLeft:12,
            paddingTop:9,
            paddingBottom:9
        },
        name: {
            fontSize:24,
            fontWeight:'bold',
            color:'#3B748F'
        },
        patientId: {
            fontSize:24,
            fontWeight:'bold',
            color:'#3B748F',
            paddingLeft:24
        },
        editContainer: {
            paddingLeft:0,
            height:12,
            width:4
        },
        flex: {
            display:'flex'
        },
        medicalRecord:{
            fontSize:11,
            color:'#979797',
            paddingTop:2
        },
        patientInfo: {
            padding:16,
            display:'flex',
            paddingBottom:12
        },
        sectionTitle: {
            fontWeight:600,
            fontSize:18,
            color:"#1A1818",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        header: {
            backgroundColor:'rgba(213, 217, 220, 0.16)',

        },
        status: {
            padding: 8,
            display:'flex',
            fontSize:15
        },
        bold: {
            fontWeight: "bold",
        },
        notesSection: {
            display: 'flex',
            backgroundColor: '#F9F9F9',
            justifyContent: 'space-between',
            width: "100%",
            textAlign: "left",
            marginBottom: 8,
            padding: 16,
        },
    }};
