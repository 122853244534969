import React, {useCallback, useState} from 'react';
import Input from './Input';
import jwt from 'jwt-decode';
import {useDispatch} from 'react-redux';
import {login} from '../../redux/actions';
import {useHistory} from "react-router-dom";
import SimpleLogo from "../../assets/logo_caption@2x.png";
import Grid from "@material-ui/core/Grid";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {loginStyle} from "./log-in.style";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

export default function OneTimePassword(props) {
    const [verificationCode, setVerificationCode] = useState('');
    const [invalidCode, setInvalidCode] = useState(false);
    //const qrImage = JSON.parse(localStorage.getItem('key'));
    const dispatch = useDispatch();
    let history = useHistory();
    const classes = useStyles();

    async function verifyOtp(code) {
        const res = await fetch('/verify/otp', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({code}),
        });
        return true;
    }

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            const user = jwt(props.location.response.accessToken);
            dispatch(login(user.payload.username));
            localStorage.setItem('key', JSON.stringify(props.location.response));
            const result = await verifyOtp(verificationCode);
            if (result){
                props.location.propsLogin.setShowNav(true);
                history.push('/dashboard');
            }
            setInvalidCode(true);
        },
        [verificationCode]
    );

    return (
        <div>
            <Grid item xs={12}>
                <img
                    src={SimpleLogo}
                    alt="logo"
                    className={classes.simpleLogo}
                />
            </Grid>
            {props.location.response.qr && (
                <div>
                    <Typography>Scan the QR code on your authenticator app</Typography>
                    <img src={`${props.location.response.qr}`}/>
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <Typography>
                    Please enter a code generated by your authenticator application in the field below
                </Typography>
                <CssTextField
                    autoFocus
                    id={'verificationCode'}
                    variant={'outlined'}
                    label={'Code'}
                    size={'small'}
                    InputLabelProps={{
                        shrink: true,
                        style: {letterSpacing: 0.39,}
                    }}
                    inputProps={{style: {fontSize: 14}}}
                    style={{
                        width: '100%',
                        marginTop: 22,
                    }}
                    onChange={(e) => setVerificationCode(e.target.value)}
                />
                <div className={classes.buttonContainer}>
                    <Button
                        id='loginButton'
                        variant='contained'
                        color='primary'
                        className={classes.button}
                        type="submit"
                        style={{width: 140}}
                    >
                        SUBMIT CODE
                    </Button>
                </div>
                {invalidCode && <p>Invalid verification code</p>}
            </form>
        </div>
    );
}
const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#8C8B8B',
        },
        '& label.Mui-focused': {
            color: '#93BBC9',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#93BBC9',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#93BBC9',
            },
            '&:hover fieldset': {
                borderColor: '#93BBC9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#93BBC9',
            },
        },
    },
})(TextField);
const useStyles = makeStyles((theme) => (loginStyle));
