import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {labels} from "../../../../../constants/inputs/labels";
import {CircularProgress, TextField} from "@material-ui/core";
import {patientBaseDiseases} from "../../../../../constants/inputs/values";
import {getPatientDiseaseData, updatePatientDisease} from "../../../add-new-patient/patientHelper";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {sectionTitles} from "../../../../../constants/names/titles";
import {CustomInfectiousDiseasesCheckbox, InfectiousDiseasesStyles} from "./InfectiousDiseasesComponents.style";
import {makeStyles} from '@material-ui/core/styles';

export const InfectiousDiseasesComponent = ({patientId}) => {

    const [enableOtherDisease, setEnableOtherDisease] = useState(false);
    const [otherDisease, setOtherDisease] = useState("");
    const [infectiousDiseasesArray, setInfectiousDiseasesArray] = useState([]);
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const styleClasses = useStyles();

    const getDiseaseStringArray = (diseaseCallResponse) => {
        let diseaseStringArray = [];
        diseaseCallResponse.map(item => {
            if (item.name !== patientBaseDiseases.droplet && item.name !== patientBaseDiseases.respiratory && item.name !== patientBaseDiseases.contact && item.name !== patientBaseDiseases.covid_19) {
                setEnableOtherDisease(true);
                setOtherDisease(item.name);
            } else {
                diseaseStringArray.push(item.name);
            }
        });
        return diseaseStringArray;
    }

    const handleDiseaseInput = (e) => {
        const {type, value, checked, name} = e.target;
        let diseases;
        switch (type) {
            case "checkbox":
                if (name === patientBaseDiseases.other) {
                    setEnableOtherDisease(prevState => !prevState);
                    if (!checked) {
                        diseases = [...infectiousDiseasesArray];
                        setOtherDisease("");
                        handleDiseaseUpdate(diseases);
                    }
                } else {
                    if (!checked) {
                        diseases = infectiousDiseasesArray.filter(item => item !== name);
                    }
                    if (checked) {
                        diseases = [...infectiousDiseasesArray, name];
                    }
                    if (enableOtherDisease) {
                        diseases.push(otherDisease);
                    }
                    setInfectiousDiseasesArray(diseases);
                    handleDiseaseUpdate(diseases);
                }
                break;
            case "text":
                if (e.type === "blur") {
                    diseases = [...infectiousDiseasesArray];
                    if (enableOtherDisease) {
                        diseases.push(otherDisease);
                    }
                    handleDiseaseUpdate(diseases)
                }
                if (e.type === "change") {
                    setOtherDisease(value);
                }
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        (async () => {
            const diseaseResponseData = await getPatientDiseaseData(patientId, setIsLoading, enqueueSnackbar, history);
            setInfectiousDiseasesArray(getDiseaseStringArray(diseaseResponseData))
        })();
    }, []);

    const handleDiseaseUpdate = async (diseaseList) => {
        await updatePatientDisease(history, patientId, diseaseList, enqueueSnackbar, setIsLoading);
        const diseaseResponseData = await getPatientDiseaseData(patientId, setIsLoading, enqueueSnackbar, history);
        setInfectiousDiseasesArray(getDiseaseStringArray(diseaseResponseData))
    }

    return <Grid item container sm={7} xs={12} classes={styleClasses.mainContainer} style={{padding: 16, display: "flex", paddingBottom: 12}}>

        <Grid item xs={12} style={{display: "flex"}}>
            <Typography style={{
                fontWeight: 600,
                fontSize: 18,
                color: "#1A1818",
            }}>{sectionTitles.infectiousDiseasePrecautions}</Typography>
        </Grid>
        {!isLoading ? <Grid item container xs={12}>
            <Grid container spacing={1}>
                <Grid item>
                    <FormControlLabel
                        control={
                            <CustomInfectiousDiseasesCheckbox
                                checked={infectiousDiseasesArray.some(disease => disease === patientBaseDiseases.contact)}
                                onClick={handleDiseaseInput}
                                name={patientBaseDiseases.contact}
                                color="primary"
                            />
                        }
                        label={labels.contact}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <CustomInfectiousDiseasesCheckbox
                                checked={infectiousDiseasesArray.some(disease => disease === patientBaseDiseases.droplet)}
                                onClick={handleDiseaseInput}
                                name={patientBaseDiseases.droplet}
                                color="primary"
                            />
                        }
                        label={labels.droplet}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <CustomInfectiousDiseasesCheckbox
                                checked={infectiousDiseasesArray.some(disease => disease === patientBaseDiseases.respiratory)}
                                onClick={handleDiseaseInput}
                                name={patientBaseDiseases.respiratory}
                                color="primary"
                            />
                        }
                        label={labels.respiratory}
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <CustomInfectiousDiseasesCheckbox
                                checked={infectiousDiseasesArray.some(disease => disease === patientBaseDiseases.covid_19)}
                                onClick={handleDiseaseInput}
                                name={patientBaseDiseases.covid_19}
                                color="primary"
                            />
                        }
                        label={labels.covid19}
                    />
                </Grid>
                <Grid item style={{
                    display: "flex",
                    paddingLeft: 0,
                    paddingRight: "24px",
                    alignItems: "center"
                }}>
                    <CustomInfectiousDiseasesCheckbox
                        checked={enableOtherDisease}
                        onClick={handleDiseaseInput}
                        name="other"
                        color="primary"
                    />
                    <Typography>{labels.other}</Typography>
                </Grid>
            </Grid>
            {
                enableOtherDisease === true && <Grid item md={4} sm={6} xs={12} style={{display: "flex"}}>
                    <TextField label={labels.otherDisease}
                               variant={"outlined"}
                               size={"small"}
                               onChange={handleDiseaseInput}
                               onBlur={handleDiseaseInput}
                               value={otherDisease}
                               defaultValue={otherDisease}
                               width={"220px"}/>
                </Grid>
            }
        </Grid> : <CircularProgress color={"primary"}/>}
    </Grid>
}

const useStyles = makeStyles((theme) => {InfectiousDiseasesStyles(theme)})