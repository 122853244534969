import React, {useEffect, useState} from "react";
import {retrieveAllHospiceIds, retrieveAllSurveyData, retrieveAllSurveyUsers} from "./SurveyResponseHelper";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {Table} from "../../../common/table/table";
import {Button, Typography} from "@material-ui/core";
import moment from "moment";
import {endpoints} from "../../../constants/endpoints";
import {endpointStatus} from "../../../constants/messages/endpointStatus";
import Link from "@material-ui/core/Link";

export const SurveyResponses = ({}) => {
    const [surveyList, setSurveyList] = useState([{label: "Survey 1", value: 0}, {
        label: "Survey 2",
        value: 1
    }, {label: "Survey 3", value: 2}]);
    const [surveyTypeList, setSurveyTypeList] = useState([{label: "Delivery", value: "Delivery"}]);
    const data = JSON.parse(localStorage.getItem('key'));
    const [hospiceList, setHospiceList] = useState([]);
    const [surveyUsers, setSurveyUsers] = useState([]);
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment());
    const [selectedSurvey, setSelectedSurvey] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [selectedHospice, setSelectedHospice] = useState("");
    const [responses, setResponses] = useState([]);
    const [userResponse, setUserResponse] = useState({});
    const [columns, setColumns] = useState([
        {
            title: "ID", field: "id", width: 150, editable: "never",
        },
        {
            title: "First Name", field: "first_name", width: 150, editable: "never",
        },
        {
            title: "Last Name", field: "last_name", width: 150, editable: "never",
        },
        {
            title: "Role", field: "role", width: 150, editable: "never",
        },
        {
            title: "Survey Responses", field: "sentDate", width: 150, editable: "never",
            headerStyle: {textAlign: 'center'},
            render: rowData => (
                <Grid item style={{display: "flex", justifyContent: "center"}}>
                    {rowData.surveys.map(item => {return <Link onClick={() => retrieveUserResponse(rowData.id, item.survey_id)}> {item.survey_id} </Link>})}
                </Grid>)
        },

    ]);

    const retrieveUserResponse = async (userId, surveyId) => {
        const promise = await fetch(endpoints.getAllSurveyUserResponse + userId + "/" + surveyId , {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            },
        });
        const response = await promise.json();
        if (response.status === endpointStatus.success) {
            setUserResponse(response.data);
        } else {
            console.log("[Error]: Error encountered while getting all surveys!");
        }
    }
    const retrieveSurveyUsers = async () => {
        const payload = {
            surveyId: 1,
            hospiceId: 4,
            surveyType: "Delivery",
            startDate: moment().subtract("1","d"),
            endDate: moment().add("1","d")
        }
        const promise = await fetch(endpoints.getAllSurveyUserIds, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            },
            body: JSON.stringify(payload)
        });
        const response = await promise.json();
        if (response.status === endpointStatus.success) {
            setSurveyUsers(response.data);
        } else {
            console.log("[Error]: Error encountered while getting all surveys!");
        }
    }
    useEffect(() => {
        retrieveAllHospiceIds(setHospiceList);
        // retrieveAllSurveyData(setSurveyList);
    }, [])

    return (
        <Grid container style={{paddingLeft: 40, paddingRight: 40}} direction={"column"} spacing={4}>
            <Grid item xs={12} style={{paddingLeft: 40, paddingRight: 40}}>
                <Typography variant={"h6"}>
                    Survey Responses
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={2} style={{zIndex: 40}}>
                        <Select
                            options={[
                                {
                                    label:'Test Survey',
                                    value:'Test Survey'
                                },
                            ]}
                            placeholder={"Select Survey"}
                            onChange={e => setSelectedSurvey(e.value)}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2} style={{zIndex: 40}}>
                        <Select
                            options={surveyTypeList}
                            placeholder={"Select Type"}
                            onChange={e => setSelectedType(e.value)}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2} style={{zIndex: 40}}>
                        <Select
                            options={hospiceList.map(item => {
                                return {label: item.hospice_name, value: item.hospice_organization_id}
                            })}
                            placeholder={"Select Hospice / Organization"}
                            onChange={e => setSelectedHospice(e.value)}
                        />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="none"
                                id="start-date-picker"
                                label="Start Date"
                                value={startDate}
                                onChange={e => setStartDate(e)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="none"
                                id="end-date-picker"
                                label="End Date"
                                value={endDate}
                                onChange={e => setEndDate(e)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} lg={2}>
                        <Grid item style={{display: "flex", justifyContent: "center"}}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => {retrieveSurveyUsers()}}
                            >
                                View Response
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Table
                    columns={columns}
                    items={surveyUsers}
                />
            </Grid>
        </Grid>
    )

}
