import React, {useEffect, useState} from "react";
import {Typography} from "@material-ui/core";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import {BRStyles} from "../BusinessReview";
import {dollarCurrencyFormatter} from "../../../../common/utils/utils";

export const BRDisplayOutlierTable = ({values}) => {

    const classes = BRStyles();
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
            <Typography style={{textAlign: "left", fontWeight: "bold"}}>
                Patient Billed Based On Quantity
            </Typography>
            <table style={{textAlign: "left"}}>
                <tbody>
                <tr>
                    <th className={classes.tdHeaderCell}>Item</th>
                    <th className={classes.tdHeaderCell}>Patient Name</th>
                    <th className={classes.tdHeaderCell}>Monthly Cost</th>
                    <th className={classes.tdHeaderCell}>Month</th>
                </tr>
                {values.extendedQuantityData.extendedCostInformation.map((item, index) => {
                    return <tr className={classes.tdDataCell} key={index}>
                        <td className={classes.tdDataCell}>{item.billedItem}</td>
                        <td className={classes.tdDataCell}>{item.fullName}</td>
                        <td className={classes.tdDataCell}>{dollarCurrencyFormatter(Number(item.cppd) * Number(item.totalDays))}</td>
                        <td className={classes.tdDataCell}>{item.month}</td>
                    </tr>
                })}
                </tbody>
            </table>
            </Grid>
            <Grid item xs={12}>
            <Typography style={{textAlign: "left", fontWeight: "bold"}}>
                Patient Billed Based On Risk
            </Typography>
            <table style={{textAlign: "left"}}>
                <tbody>
                <tr>
                    <th className={classes.tdHeaderCell}>Item</th>
                    <th className={classes.tdHeaderCell}>Patient Name</th>
                    <th className={classes.tdHeaderCell}>Monthly Cost</th>
                    <th className={classes.tdHeaderCell}>Month</th>
                </tr>
                {values.extendedRiskData.extendedData.map((item, itemIndex) => {
                    if(item.billedPatients.length > 0){
                        return item.billedPatients.map((patient, patientIndex) => {
                            return <tr key={item.itemName+itemIndex+patientIndex}>
                                <td className={classes.tdDataCell}>{item.itemName}</td>
                                <td className={classes.tdDataCell}>{patient.fullName}</td>
                                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(patient.totalPrice)}</td>
                                <td className={classes.tdDataCell}>{item.month}</td>
                            </tr>
                        })
                    }
                })}
                </tbody>
            </table>
            </Grid>
            <Grid item xs={12}>
            <Typography style={{textAlign: "left", fontWeight: "bold"}}>
                Patient Billed Based On No Risk No Quantity
            </Typography>
            <table style={{textAlign: "left"}}>
                <tbody>
                <tr>
                    <th className={classes.tdHeaderCell}>Item</th>
                    <th className={classes.tdHeaderCell}>Patient Name</th>
                    <th className={classes.tdHeaderCell}>Monthly Cost</th>
                    <th className={classes.tdHeaderCell}>Delivery Date</th>
                    <th className={classes.tdHeaderCell}>Pickup Date</th>
                    <th className={classes.tdHeaderCell}>Days Of Service In Quarter</th>
                </tr>
                {values.extendedDirectBillData.map((item, index) => {
                    return <tr key ={item.itemName+index}>
                        <td className={classes.tdDataCell}>{item.itemName}</td>
                        <td className={classes.tdDataCell}>{item.fullName}</td>
                        <td className={classes.tdDataCell}>{dollarCurrencyFormatter(item.totalCost)}</td>
                        <td className={classes.tdDataCell}>{moment(item.itemDeliveryDate).format("YYYY-MM-DD")}</td>
                        <td className={classes.tdDataCell}>{item.itemPickupDate && moment(item.itemPickupDate).format("YYYY-MM-DD")}</td>
                        <td className={classes.tdDataCell}>{item.daysOfServiceInQuarter}</td>
                    </tr>
                })}
                </tbody>
            </table>
            </Grid>
        </Grid>
    )
}