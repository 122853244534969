import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import {Dialog} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import {getMessage} from "../../constants/messages/messages";
import {messageTypes} from "../../constants/types/messageTypes";
import CircularProgress from "@material-ui/core/CircularProgress";
import {labels} from "../../constants/inputs/labels";
import CustomizedSlider from "./orderItems/selectItems/orderItem/slider/slider";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FormHelperText from "@material-ui/core/FormHelperText";
import {endpoints} from "../../constants/endpoints";
import HelpSign from "../../assets/alert-triangle.svg";
import {alertTitles} from "../../constants/alert/alertTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {alertMessages} from "../../constants/alert/alertMessages";
import DialogActions from "@material-ui/core/DialogActions";
import {buttonText} from "../../constants/alert/alertButtonTexts";

export default function NewAddItemModal(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [qty, setQty] = useState(1);
    const [loading, setLoading] = useState(true);
    const [clinicalSettings, setClinicalSettings] = useState([]);
    const [weight, setWeight] = useState(props.patientInfo.patient_weight);
    const [showApproval, setShowApproval] = useState(false);
    const [invalid, setInvalid] = useState(true);
    const [border, setBorder] = useState('');

    let item = props.item;

    const [itemValues, setItemValues] = React.useState({});

    useEffect(() => {
        setItemValues(prevState => ({...prevState, quantity: qty}))
    }, [qty]);

    useEffect(() => {
        let obj = {};
        obj = {
            item_id: item.item_id,
            approval: item.approval,
            prescription_required: item.prescription_required,
            price: item.supplemental_price,
            name: item.name,
            special_item_info: {delivery_method: null, liters_per_minute: item.liters_per_minute},
            quantity: 1,
            type: item.type,
        };
        if (item.max_quantity_per_item !== null) {
            obj.special_item_info.max_quantity_per_item = item.max_quantity_per_item;
        }
        setItemValues(obj)
    }, [props.item]);


    const getClinicalSettings = async () => {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(`${endpoints.masterItemsClinicalSettingsById}${item.item_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        setClinicalSettings(response.data);
        setLoading(false);
    };

    useEffect(() => {
        if (open && clinicalSettings.length === 0) {
            getClinicalSettings()
        }
    }, [open]);


    function checkValid(clinicalSettings) {
        if (
            (clinicalSettings.some(item => item.service_note === true) && (itemValues.special_item_info.service_note_text ? itemValues.special_item_info.service_note_text.length === 0 : true)) ||
            (clinicalSettings.some(item => item.iscontinuous === true) && itemValues.special_item_info?.service_note_text?.isContinuous) ||
            (clinicalSettings.some(item => item.delivery_method === true) && itemValues.special_item_info?.delivery_method === null)
        ) {
            setInvalid(true)
        } else {
            setInvalid(false)
        }
    }

    function checkDeliveryMethod(clinicalSettings) {
        if (clinicalSettings.some(item => item.delivery_method === true) && itemValues.special_item_info?.delivery_method === null)
            setBorder('1px solid red');
        else {
            setBorder('');
        }
    }

    useEffect(() => {
        checkValid(clinicalSettings);
        checkDeliveryMethod(clinicalSettings);
    }, [itemValues, item, clinicalSettings]);


    return (
        <React.Fragment>
            <Button variant={'outlined'} color={'primary'}
                    style={{paddingTop: 4, paddingBottom: 4, paddingLeft: 12, paddingRight: 12, height: 36}}
                    onClick={() => {
                        if (item.approval) {
                            setShowApproval(true)
                        } else {
                            setOpen(true)
                        }
                    }}
            >Add to order</Button>
            <Dialog
                maxWidth={'xl'}
                fullWidth
                classes={{
                    paperFullWidth: classes.paperFullWidth,
                    paperScrollPaper: classes.paperScrollPaper,
                }}
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>
                    <div style={{fontSize: 16, fontWeight: 'bold'}}>
                        {getMessage(messageTypes.addToCart, props.item.name)}
                    </div>
                </DialogTitle>
                <div className={classes.dialogContainer}>
                    {
                        loading ? (
                            <div style={{
                                padding: 36,
                                display: 'flex',
                                justifyContent: 'center',
                                alignContent: 'center',
                                alignItems: 'center'
                            }}>
                                <CircularProgress/>
                            </div>
                        ) : (
                            <React.Fragment>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    {props.item.image === '.jpeg' ? (
                                        <img src={`${process.env.REACT_APP_BUCKET_URL}placeholder.png`} height="180"/>
                                    ) : (
                                        <img src={`${process.env.REACT_APP_BUCKET_URL}${props.item.image}`}
                                             height="180"/>
                                    )}
                                </div>
                                <div className={classes.description}>
                                    {item.description}
                                </div>
                                {clinicalSettings.length !== 0 && clinicalSettings.map(setting => {
                                    return (
                                        <React.Fragment>
                                            {
                                                setting.air_psi === true && (
                                                    <div style={{paddingLeft: 8, paddingRight: 8, width: '100%'}}>
                                                        <div className={classes.range}>
                                                            <h4 className={classes.rangeLabel}>{labels.oxygen_select}</h4>
                                                        </div>
                                                        {setting.min_range !== null && (
                                                            <CustomizedSlider
                                                                min_range={setting.min_range ? parseInt(setting.min_range) : 0}
                                                                max_range={setting.max_range ? parseInt(setting.max_range) : 0}
                                                                itemValues={itemValues}
                                                                setItemValues={setItemValues}
                                                                noCss={true}
                                                            />
                                                        )}
                                                    </div>
                                                )
                                            }
                                            {
                                                setting.liters_per_minute === true && (
                                                    <div style={{
                                                        paddingLeft: 8,
                                                        paddingTop: 16,
                                                        paddingRight: 8,
                                                        width: '100%'
                                                    }}>
                                                        <div className={classes.range}>
                                                            <h4 className={classes.rangeLabel}>{labels.liters_per_minute_select}</h4>
                                                        </div>
                                                        {setting.min_range !== null && (
                                                            <CustomizedSlider
                                                                min_range={setting.min_range ? parseInt(setting.min_range) : 0}
                                                                max_range={setting.max_range ? parseInt(setting.max_range) : 0}
                                                                itemValues={itemValues}
                                                                setItemValues={setItemValues}
                                                            />
                                                        )}
                                                    </div>
                                                )
                                            }
                                            {
                                                setting.iscontinuous === true && (
                                                    <React.Fragment>
                                                        <div style={{
                                                            paddingLeft: 8,
                                                            paddingTop: 16,
                                                            paddingBottom: 16,
                                                            display: 'flex',
                                                            width: '100%',
                                                            flexDirection: 'row',
                                                            alignContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                            <h4 style={{
                                                                marginBottom: 8,
                                                                marginTop: 8,
                                                                paddingRight: 8
                                                            }}>{labels.continuous_oxygen_therapy}</h4>
                                                            <RadioGroup
                                                                row
                                                                aria-label="position"
                                                                name="continuous"
                                                                defaultValue={itemValues.special_item_info && itemValues.special_item_info.isContinuous}

                                                            >
                                                                <FormControlLabel
                                                                    checked={itemValues.special_item_info && itemValues.special_item_info.isContinuous}
                                                                    value={true}
                                                                    control={<Radio color="primary"/>}
                                                                    label="Continous"
                                                                    labelPlacement="end"
                                                                    onChange={(e) => {
                                                                        let obj = {...itemValues.special_item_info};
                                                                        obj = {...obj, isContinuous: true};
                                                                        setItemValues({
                                                                            ...itemValues,
                                                                            special_item_info: obj
                                                                        })
                                                                    }}
                                                                />
                                                                <FormControlLabel
                                                                    checked={itemValues.special_item_info && !itemValues.special_item_info.isContinuous}
                                                                    value={false}
                                                                    control={<Radio color="primary"/>}
                                                                    label="PRN"
                                                                    labelPlacement="end"
                                                                    onChange={(e) => {
                                                                        let obj = {...itemValues.special_item_info};
                                                                        obj = {...obj, isContinuous: false};
                                                                        setItemValues({
                                                                            ...itemValues,
                                                                            special_item_info: obj
                                                                        })
                                                                    }}
                                                                />
                                                            </RadioGroup>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                            {
                                                setting.delivery_method === true && (
                                                    <React.Fragment>
                                                        <div style={{
                                                            paddingLeft: 8,
                                                            paddingTop: 16,
                                                            paddingBottom: 8,
                                                            display: 'flex',
                                                            width: '100%',
                                                            flexDirection: 'row',
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            alignContent: 'center',
                                                            border: border
                                                        }}>
                                                            <h4 style={{
                                                                marginBottom: 8,
                                                                marginTop: 8,
                                                                paddingRight: 8
                                                            }}>{labels.delivery_method}</h4>
                                                            <div>
                                                                <RadioGroup
                                                                    row
                                                                    aria-label="position"
                                                                    name="continuous"
                                                                    defaultValue={itemValues.special_item_info && itemValues.special_item_info.delivery_method}
                                                                >
                                                                    <FormControlLabel
                                                                        checked={itemValues.special_item_info && itemValues.special_item_info.delivery_method === "Nasal Cannula"}
                                                                        value={true}
                                                                        control={<Radio color="primary"/>}
                                                                        label="Nasal Cannula"
                                                                        labelPlacement="end"
                                                                        onChange={(e) => {
                                                                            let obj = {...itemValues.special_item_info};
                                                                            obj = {
                                                                                ...obj,
                                                                                delivery_method: "Nasal Cannula"
                                                                            };
                                                                            setItemValues({
                                                                                ...itemValues,
                                                                                special_item_info: obj
                                                                            })
                                                                        }}
                                                                    />
                                                                    <FormControlLabel
                                                                        checked={itemValues.special_item_info && itemValues.special_item_info.delivery_method === 'Mask'}
                                                                        value={false}
                                                                        control={<Radio color="primary"/>}
                                                                        label="Mask"
                                                                        labelPlacement="end"
                                                                        onChange={(e) => {
                                                                            let obj = {...itemValues.special_item_info};
                                                                            obj = {...obj, delivery_method: 'Mask'};
                                                                            setItemValues({
                                                                                ...itemValues,
                                                                                special_item_info: obj
                                                                            })
                                                                        }}
                                                                    />
                                                                </RadioGroup>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                            {
                                                setting.humidifier_for_compressor === true && (
                                                    <React.Fragment>
                                                        <div style={{
                                                            paddingLeft: 8,
                                                            display: 'flex',
                                                            width: '100%',
                                                            alignItems: 'center',
                                                            alignContent: 'center'
                                                        }}>
                                                            <h4 className={classes.humidifierTitle} style={{
                                                                marginBottom: 8,
                                                                marginTop: 8,
                                                                paddingRight: 8
                                                            }}>
                                                                {labels.humidifier_bottle}
                                                            </h4>
                                                            <div className={classes.humidifierInput}>
                                                                <TextField
                                                                    inputProps={{
                                                                        min: 0,
                                                                        style: {textAlign: 'center'}
                                                                    }}
                                                                    variant="outlined"
                                                                    size={'small'}
                                                                    onChange={(e) => {
                                                                        let obj = {...itemValues.special_item_info};
                                                                        obj = {
                                                                            ...obj,
                                                                            humidifier_for_compressor: Number(e.target.value)
                                                                        };
                                                                        setItemValues({
                                                                            ...itemValues,
                                                                            special_item_info: obj
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                            {
                                                setting.weight === true && (
                                                    <React.Fragment>
                                                        <div className={classes.box}>
                                                            <h4 style={{
                                                                marginBottom: 8,
                                                                marginTop: 8,
                                                                paddingRight: 8
                                                            }}>
                                                                {labels.patientWeight}
                                                            </h4>
                                                            <div style={{width: 70}}>
                                                                <TextField
                                                                    value={weight}
                                                                    defaultValue={props.patientInfo.patient_weight}
                                                                    inputProps={{min: 0, style: {textAlign: 'center'}}}
                                                                    variant="outlined"
                                                                    size={'small'}
                                                                    onChange={(e) => {
                                                                        let obj = {...itemValues.special_item_info};
                                                                        obj = {...obj, weight: Number(e.target.value)};
                                                                        setItemValues({
                                                                            ...itemValues,
                                                                            special_item_info: obj
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                            {
                                                setting.size_lift === true && (
                                                    <React.Fragment>
                                                        <div className={classes.box}>
                                                            <h4 style={{
                                                                marginBottom: 8,
                                                                marginTop: 8,
                                                                paddingRight: 8
                                                            }}>
                                                                {labels.lift_size}
                                                            </h4>
                                                            <div style={{width: 300, display: 'flex'}}>
                                                                <RadioGroup
                                                                    row
                                                                    aria-label="position"
                                                                    name="position"
                                                                    defaultValue="Medium"
                                                                    onChange={(e) => {
                                                                        let obj = {...itemValues.special_item_info};
                                                                        obj = {...obj, size_lift: e.target.value};
                                                                        setItemValues({
                                                                            ...itemValues,
                                                                            special_item_info: obj
                                                                        })
                                                                    }}
                                                                >
                                                                    <FormControlLabel
                                                                        value="Medium"
                                                                        control={<Radio color="primary"/>}
                                                                        label="Medium"
                                                                        labelPlacement="end"
                                                                    />
                                                                    <FormControlLabel
                                                                        value="Large"
                                                                        control={<Radio color="primary"/>}
                                                                        label="Large"
                                                                        labelPlacement="end"
                                                                    />
                                                                    <FormControlLabel
                                                                        value="X-Large"
                                                                        control={<Radio color="primary"/>}
                                                                        label="X-Large"
                                                                        labelPlacement="end"
                                                                    />
                                                                </RadioGroup>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            }
                                            {
                                                setting.service_note === true && (
                                                    <React.Fragment>
                                                        <Grid container
                                                              style={{padding: 8, paddingTop: 24, paddingBottom: 16}}>
                                                            <h4 style={{
                                                                marginBottom: 8,
                                                                marginTop: 8,
                                                                paddingRight: 8
                                                            }}>{labels.serviceNote}</h4>
                                                            <TextField
                                                                value={itemValues.special_item_info.service_note_text}
                                                                error={itemValues.special_item_info.service_note_text ? itemValues.special_item_info?.service_note_text?.length === 0 : true}
                                                                placeholder={'Enter service note...'}
                                                                variant="outlined"
                                                                fullWidth
                                                                multiline
                                                                size={'small'}
                                                                onChange={(e) => {
                                                                    let obj = {...itemValues.special_item_info};
                                                                    obj = {...obj, service_note_text: e.target.value};
                                                                    if (e.target.value !== "") {
                                                                        obj = {...obj, service_note: true}
                                                                    } else {
                                                                        obj = {...obj, service_note: false}
                                                                    }
                                                                    setItemValues({
                                                                        ...itemValues,
                                                                        special_item_info: obj
                                                                    })
                                                                }}
                                                            />
                                                        </Grid>
                                                    </React.Fragment>
                                                )
                                            }
                                        </React.Fragment>
                                    )
                                })}
                                <Grid container className={classes.quantityContainer}>
                                    <div className={classes.quantity}>
                                        <IconButton
                                            onClick={() => {
                                                if (qty > 1) {
                                                    setQty(qty - 1)
                                                }
                                            }}
                                        >
                                            <RemoveCircleOutlineIcon/>
                                        </IconButton>
                                        <div style={{width: '70px'}}>
                                            <TextField
                                                disabled
                                                id="item_qty"
                                                label="Qty"
                                                value={qty}
                                                inputProps={{min: 0, style: {textAlign: 'center'}}}
                                                variant="outlined"
                                                size={'small'}
                                                onChange={(e) => setQty(e.target.value)}
                                            />
                                        </div>
                                        <IconButton
                                            disabled={itemValues.special_item_info && qty === itemValues.special_item_info.max_quantity_per_item}
                                            onClick={() => {
                                                if (qty <= itemValues.special_item_info.max_quantity_per_item) {
                                                    setQty(qty + 1)
                                                }
                                            }}
                                        >
                                            <AddCircleOutlineIcon/>
                                        </IconButton>
                                    </div>
                                    <FormHelperText
                                        style={{color: 'red', textAlign: 'left', paddingLeft: 10}}
                                    >
                                        {(itemValues.special_item_info && qty === itemValues.special_item_info.max_quantity_per_item) && (
                                            `Max. quantity reached`)
                                        }
                                    </FormHelperText>
                                </Grid>
                            </React.Fragment>
                        )
                    }
                    {checkValid}
                    {checkDeliveryMethod}
                    <Grid container justify={'flex-end'}>
                        <div style={{display: 'flex', padding: '20px'}}>
                            <Button variant="outlined" color="black"
                                    onClick={() => setOpen(false)}
                                    style={{
                                        marginTop: '24px',
                                        marginLeft: '8px',
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold'
                                    }}
                            >
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary"
                                    disabled={invalid}
                                    onClick={() => {
                                        if (props.handleInput) {
                                            let itemValuesClone = {...props.itemValues};
                                            let single_item = true;
                                            clinicalSettings.map(setting => {
                                                if (setting.main_title === "Single Item") {
                                                    single_item = setting.single_item
                                                }
                                            });
                                            itemValuesClone.single_item = single_item;
                                            const obj = {
                                                target: {
                                                    itemValues: itemValuesClone,
                                                    item: item,
                                                    name: "addItem",
                                                }
                                            };
                                            props.handleInput(obj);
                                        } else {
                                            const values = {...props.order};
                                            let obj = {...itemValues};
                                            if (itemValues.approval) {
                                                values.approval = true;
                                            }
                                            let single_item = true;
                                            clinicalSettings.map(setting => {
                                                if (setting.main_title === "Single Item") {
                                                    single_item = setting.single_item
                                                }
                                            });
                                            obj.single_item = single_item;
                                            values.items.push(obj);
                                            props.setOrder(values);
                                        }
                                        setOpen(false)
                                    }}
                                    style={{
                                        marginTop: '24px',
                                        marginLeft: '8px',
                                        textTransform: 'uppercase',
                                        fontWeight: 'bold'
                                    }}
                            >
                                Add to order
                            </Button>
                        </div>
                    </Grid>
                </div>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={'xs'}
                open={showApproval}
                onClose={() => setShowApproval(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{display: "flex", justifyContent: 'center', paddingTop: 24}}>


                    <img
                        src={HelpSign}
                        alt="logo"
                        width='32'
                    />
                </div>
                <DialogTitle id="alert-dialog-title" style={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: "#1A1818",
                    textAlign: 'center'
                }}>{alertTitles.outOfFormulary}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{fontSize: 14, color: "#1A1818"}}>
                        {alertMessages.outOfFormulary}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Button onClick={() => {
                                setOpen(true);
                                setShowApproval(false);
                            }}
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    style={{textTransform: 'none', fontSize: 12, fontWeight: 'bold'}}>

                                {buttonText.outOfFormulary}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button onClick={() => setShowApproval(false)}
                                    variant="outlined"
                                    color="primary"
                                    autoFocus
                                    fullWidth
                                    style={{textTransform: 'none', fontSize: 12, fontWeight: 'bold', height: 40}}>
                                {buttonText.cancel}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    paperFullWidth: {
        width: 'calc(100% - 24px)',
        minHeight: 328,
    },
    paperScrollPaper: {
        maxHeight: 'calc(100% - 24px)'
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: "bold",
        fontSize: 20,
        borderBottom: '1px solid #B2D1D9'
    },
    subtitle: {
        fontWeight: "bold",
        fontSize: 18,
    },
    description: {
        color: 'grey',
        fontWeight: 500,
        fontSize: 16,
    },
    box: {
        maxHeight: '25vh',
        height: 70,
        paddingLeft: 8,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600,
        width: '100%'
    },
    formControl: {
        width: '100%',
        paddingRight: 8, paddingLeft: 8,
        marginBottom: 16,
    },
    dialogContainer: {
        minWidth: 320,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    range: {
        paddingTop: '8px',
        width: '100%',
    },
    rangeLabel: {
        paddingBottom: 24,
        margin: 0
    },
    selectors: {
        paddingLeft: '10%',
    },
    humidifierInput: {
        width: 50,
    },
    quantityContainer: {
        paddingTop: '20px',
    },
    quantity: {
        display: "flex",
        alignItems: 'center',
        width: '100%'
    }
}));
