export const alertMessages = {
    cancelNewPatient: 'If you proceed with cancelling, all progress will be lost. Do you wish to continue with cancel request?',
    cancelEditPatient: 'If you proceed with cancelling, all progress will be lost. Do you wish to continue with cancel request?',
    alertNotDefined: 'Alert not defined.',
    clearWarning: "Are you sure you want to clear all items?",
    cancelConfirmation:'If you proceed with cancelling, all progress will be lost. Do you wish to continue with cancel request?',
    validationsTotalPassed: "You have selected to pick up all items. Do you wish to continue? ",
    validationsItemizedPassed: "You have selected to pick up items. Do you wish to continue? ",
    cancelPickup: "This will cancel your pickup order. Do you wish to cancel ?",
    missingReason: "Missing Reason for Pickup.",
    missingPriority: "Missing Priority for Pickup.",
    validItems: "All fields have passed validation. Proceed with adding the items ?",
    invalidItems: "Some of items you wish to add have did not pass validation. " +
        "Check for red fields and make sure that all items have Item Name and Warehouse selected.",
    reasonTamper: "Items can be kept only for Discharge or Revoke reasons.",
    outOfFormulary: "This item requires approval before the order can be sent to the DME provider. Are you sure you want to add it?",
    assetNotFound: "The Asset Number you entered does not match any item in your inventory.",
    assetQuestion: "Do you want to add this item and asset number to your inventory?",
    itemsWillBeAdded: "The introduced items will be added.",
    changeItemStatus: "Change item status:",
    changeNewOrderStatus: "You have selected to mark this order as Processing. Continue with Processing the Order?",
    changeProcessingOrderStatus: "You have selected to mark this order as Completed. Continue with Completing the Order?",
    savePatientDemographics: "Do you want to edit only the patient demographics?",
    savePatientCaregivers: "Do you want to edit only the patient caregivers?",
    cancelOrder: "You have selected to mark this order as Canceled. Do you want to continue?",
    saveHospiceDetails: "Do you want to edit only the hospice details?",
    saveHospiceRegions: "Do you want to edit only the hospice regions?",
    saveDmeDetails: "Do you want to edit only the dme details?",
    saveDmeRegions: "Do you want to edit only the dme regions?",
};

export const snackbarMessages = {
    generalUnsuccessfulStatus: "Something happened, action did not complete.",
    deactivatedOrDeletedUser: "Your request has been successfully sent",
    invalidUsernamePassword: "Invalid username or password.",
    resourceCreatedSuccessfully: "Resource create successfully.",
    resourceAlreadyExists: "Resource already exists.",
    resourceUpdatedSuccessfully: "Resource updated successfully.",
    resourceDeletedSuccessfully: "Resource deleted successfully.",
    actionCompletedSuccessfully: "Action completed successfully.",
    contactSupport: "Something happened. Contact support.",
    updatePatientDemographics: "Updated patient demographics successfully.",
    updatePatientNotes: "Updated patient notes successfully.",
    updatePatientDiseases: "Updated patient diseases successfully.",
    updatePatientPrescription: "Updated patient prescription successfully.",
    updatePatientCaregivers: "Updated patient caregivers successfully.",
    autopopulatePatientAddress: "Complete patient address with hospice address?",
    somethingHappenedWithPatientAddress: "Something happened while getting address data.",
    updateHospiceDetails: "Updated hospice details successfully.",
    updateHospiceRegions: "Updated hospice resources successfully.",
    updateHospiceLinks: "Updated hospice links successfully.",
    updateDmeDetails: "Updated dme details successfully.",
    updateDmeRegions: "Updated dme resources successfully.",
    updateDmeLinks: "Updated dme contacts successfully.",
    updateOrderCompletionDate: "Something happened while updating completion date.",
    updateOrderPreferredDate: "Something happened while updating preferred date.",
    addedOrderTechnician: "Technician added to this delivery.",
    orderItemAssetAdded: "Item asset added to the selected warehouse.",
    orderItemReleased: "Item asset released.",
    orderItemLotAdded: "Item lot added to the selected warehouse.",
    orderNoteAdded: "Note added to order.",
    orderItemAdded: "Item added to the order.",
    orderStatusChanged: "Order status has been changed.",
    orderItemRemoved: "Order item removed.",
    orderCreated: "Order Created",
    userDeleted: "User was deleted!",
    hospiceInformationRetrievalError: "Something happened while retrieving hospice information."
}