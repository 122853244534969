import React, {useEffect, useState} from "react";
import {useDispatch} from 'react-redux';
import {useHistory} from "react-router-dom";
import jwt from "jwt-decode";
import {logout} from "../../redux/actions";
import {checkTokenAvailability, getTokenInformation} from "../../common/utils/utils";
import {getRestrictedDomains} from "../admin/createUserHelper";
import {checkEmail, getPossibleUsernames} from "../admin/createUserHelper";
import {getValidationMessage} from "../../common/InputField/validationMessages/InputFieldErrorMessage";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Check from "@material-ui/icons/Check";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MessageOutlinedIcon from "@material-ui/icons/MessageOutlined";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {endpoints} from "../../constants/endpoints";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {IconButton, ListItem} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import {checkPermissions} from "../../common/permissions/checkPermissions";
import {permissions} from "../../constants/permissions/permissions";
import FormHelperText from "@material-ui/core/FormHelperText";
import {utilsTokenIdentifiers} from "../../constants/inputs/values";

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)"
    },
    active: {
        "& $line": {
            borderColor: "#4F86A0"
        }
    },
    completed: {
        "& $line": {
            borderColor: "#4F86A0"
        }
    },
    line: {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1
    }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: "#eaeaf0",
        display: "flex",
        height: 22,
        alignItems: "center"
    },
    active: {
        color: "#4F86A0"
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor"
    },
    completed: {
        color: "#4F86A0",
        zIndex: 1,
        fontSize: 18
    }
});

function getSteps() {
    return ["Select your request type", "Fill the data", "Send your request"];
}

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active
            })}
        >
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                <div className={classes.circle} />
            )}
        </div>
    );
}

export default function Requests() {
    const dispatch = useDispatch();
    const history = useHistory();

    //
    const [openDrawer,setOpenDrawer] = useState(false);
    const [validEmail,setValidEmail] = useState({});
    const [possibleEmail,setPossibleEmail] = useState([]);
    const [restrictedDomains,setRestrictedDomains] = useState([]);

    const [reqType, setReqType] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    //userinfo
    const [user, setUser] = useState({
        first_name: '',
        last_name: '',
        email: '',
        username: '',
        organization: '',
        telephone: '',
        team: '',
        role: '',
    });
    const [userErrors,setUserErrors] = useState({});
    const [userType,setUserType] = useState("");

    //user payload
    const [payload,setPayload] = useState({});

    //
    const [rolesList, setRolesList] = useState([]);

    const handleNext = () => {
        if(activeStep === 1){
            if(checkUserErrors() === true){
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setUserErrors({});

            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        const values = {...user};
        if (name === "first_name") {
            values.first_name = value;
        } else if (name === "last_name") {
            values.last_name = value;
        } else if (name === "email" && !value.includes(" ")) {
            values.email = value;
        } else if (name === "username" && !value.includes(" ")) {
            values.username = value
        } else if (name === "organization"){
            values.organization = value
        } else if (name === 'role'){
            values.role = value;
        } else if(name === 'telephone'){
            values.telephone = value;
        } else if(name === 'team'){
            values.team = value;
        }
        setUser(values);
    };

    const handleChange = (event) => {
        setReqType(event.target.value);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setUser({
            first_name: '',
            last_name: '',
            email: '',
            username: '',
            organization: '',
            telephone: '',
            team: '',
            role: ''
        })
    };

    const handleChangeUserType = (event) => {
        setUserType(event.target.value);
    }

    const handleRoleChange = (event) => {
        setUser({
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            username: user.username,
            organization: user.organization,
            telephone: user.telephone,
            team: user.team,
            role: event.target.value,
        });
    }

    function getHospiceRoles() {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.hospice_roles + "Hospice", {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].role_id, label: response.data[i].name})
                }
                setRolesList(object);
            })
            .catch(err => console.log('error', err))
    }

    function getDMERoles() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.dme_roles, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].role_id, label: response.data[i].name})
                }
                setRolesList(object);
            })
            .catch(err => console.log('error', err))
    }

    const checkUserErrors = () => {
        let valueErrors = {}
        if(!user.first_name.trim()) valueErrors.first_name = true;
        if(!user.last_name.trim()) valueErrors.last_name = true;
        if(!user.email.trim()) valueErrors.email = true;
        if(reqType === "Create new account" && !user.telephone.trim()) valueErrors.telephone = true;
        if(!userType) valueErrors.userType = true;
        if(reqType === "Create new account" && !user.role) valueErrors.role = true;
        if(!user.organization) valueErrors.organization = true;

        setUserErrors(valueErrors);

        return Object.keys(valueErrors).every(key => !valueErrors[key]);
    }

    const sendEmailToSupport = () => {
        if(reqType === 'Create new account'){
            fetch(`${endpoints.requestUserCreation}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                },
                body: JSON.stringify({
                    data:{
                        from: payload.username,
                        fromEmail: payload.email,
                        userType: userType,
                        organization: user.organization,
                        role: user.role,
                        name: `${user.first_name} ${user.last_name}`,
                        email: user.email,
                        telephone: user.telephone,
                        team: user.team,
                        preferredUsername: user.username
                    }
                })
            })
                .then(response => {
                    if (response.status === 200) {
                        console.log(response.data);
                        console.log("Success")
                    }
                })
                .catch(err => console.log('error', err));
        } else if(reqType === 'Deactivate account'){
            fetch(`${endpoints.requestUserDeactivation}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                },
                body: JSON.stringify({
                    data:{
                        from: payload.username,
                        fromEmail: payload.email,
                        userType: userType,
                        organization: user.organization,
                        name: `${user.first_name} ${user.last_name}`,
                        email: user.email,
                    }
                })
            })
                .then(response => {
                    if (response.status === 200) {
                        console.log(response.data);
                        console.log("Success")
                    }
                })
                .catch(err => console.log('error', err));
        }
    }

    React.useEffect(() => {
        let token = localStorage.getItem('key');
        if (JSON.parse(token) !== null) {
            token = JSON.parse(token);
            let user = jwt(token.accessToken);
            setPayload(user.payload);
        }
    }, [reqType]);


    React.useEffect(() => {
        if(payload){
            getRestrictedDomains(setRestrictedDomains);
            if(payload.role_name === 'Corporate/Super Admin'){

            } else if(payload.type === 'DME'){
                setUserType("DME")
            } else if(payload.type === 'Hospice'){
                setUserType("Hospice")
            }
        }
    },[payload, payload.type]);

    React.useEffect(() => {
        if(userType === 'Hospice') getHospiceRoles();
        else if(userType === 'DME') getDMERoles();
    },[userType])

    React.useEffect(() => {
        if(possibleEmail.length > 1 && user.first_name && user.last_name) setOpenDrawer(true);
    },[possibleEmail])

    React.useEffect(() => {
        if(activeStep === 2){
            sendEmailToSupport();
        }

        setUserErrors({});
    },[activeStep])

    return (
            <div style={{paddingTop: '30px'}}>
                <Stepper
                    style={{backgroundColor: '#f9fafc'}}
                    alternativeLabel
                    activeStep={activeStep}
                    connector={<QontoConnector />}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {activeStep === 0 && (
                    <FormControl style={{ width: "80%", paddingTop: '10px', marginTop: '40px'}}>
                        <InputLabel>
                            Request types:
                        </InputLabel>
                        <Select
                            value={reqType}
                            onChange={handleChange}
                            style={{ textAlign: "left" }}
                        >
                            {checkPermissions([permissions.request_create]) && (
                                <MenuItem value={"Create new account"}>Create new account</MenuItem>
                            )}

                            {checkPermissions([permissions.request_deactivate]) && (
                                <MenuItem value={"Deactivate account"}>Deactivate account</MenuItem>
                            )}
                        </Select>
                    </FormControl>  
                )}

                {activeStep === 1 && reqType === "Create new account" && (
                    <form style={{paddingTop: '30px'}}>
                        <Grid container>
                            <Grid container>
                                <Grid item xs={1} sm={3}></Grid>
                                {payload && payload.role_name === 'Corporate/Super Admin' && (
                                    <Grid item xs={10} sm={6}>
                                        <FormControl style={{ width: "80%", marginTop: '40px'}}>
                                            <InputLabel>
                                                User type*:
                                            </InputLabel>
                                            <Select
                                                value={userType}
                                                onChange={handleChangeUserType}
                                                style={{ textAlign: "left", padding: '5px'}}
                                            >
                                                <MenuItem value={"Hospice"}>Hospice</MenuItem>
                                                <MenuItem value={"DME"}>DME</MenuItem>
                                            </Select>
                                            {
                                                userErrors.userType && (
                                                    <FormHelperText style={{color: 'red', paddingLeft: '25px'}}>
                                                        User type must not be empty!
                                                    </FormHelperText>
                                                )
                                            }
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item xs={1} sm={3}></Grid>
                            </Grid>

                            <Grid container spacing={3} style={{paddingTop: '50px'}}>
                                <Grid item xs={12} sm={6}>

                                    {payload && userType === 'Hospice' && (
                                        <FormControl style={{width: '80%'}}>
                                            <InputLabel>
                                                Role*:
                                            </InputLabel>
                                            <Select
                                                value={user.role}
                                                onChange={handleRoleChange}
                                                style={{ textAlign: "left", padding: '5px'}}
                                            >
                                                {rolesList && rolesList.map((el,idx) => (
                                                    <MenuItem key={idx} value={el.label}>{el.label}</MenuItem>
                                                ))}
                                            </Select>
                                            {
                                                userErrors.role && (
                                                    <FormHelperText style={{color: 'red', paddingLeft: '10px'}}>
                                                        Role must not be empty!
                                                    </FormHelperText>
                                                )
                                            }
                                        </FormControl>
                                    )}

                                    {payload && userType === 'DME' && (
                                        <FormControl style={{width: '80%'}}>
                                            <InputLabel>
                                                Role*:
                                            </InputLabel>
                                            <Select
                                                value={user.role}
                                                onChange={handleRoleChange}
                                                style={{ textAlign: "left", padding: '5px'}}
                                            >
                                                {rolesList && rolesList.map((el,idx) => (
                                                    <MenuItem key={idx} value={el.label}>{el.label}</MenuItem>
                                                ))}
                                            </Select>
                                            {
                                                userErrors.role && (
                                                    <FormHelperText style={{color: 'red', paddingLeft: '10px'}}>
                                                        Role must not be empty!
                                                    </FormHelperText>
                                                )
                                            }
                                        </FormControl>
                                    )}
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    {payload && userType === 'Hospice' && (
                                        <React.Fragment>
                                            <TextField
                                                fullWidth
                                                id={`organization`}
                                                name="organization"
                                                value={user.organization}
                                                onChange={handleInputChange}
                                                label="Hospice Organization*"
                                                variant="outlined"
                                            />
                                            {
                                                userErrors.organization && (
                                                    <FormHelperText style={{color: 'red', paddingLeft: '25px'}}>
                                                        Organization must not be empty!
                                                    </FormHelperText>
                                                )
                                            }
                                        </React.Fragment>
                                    )}

                                    {payload && userType === 'DME' && (
                                        <React.Fragment>
                                            <TextField
                                                fullWidth
                                                id={`organization`}
                                                name="organization"
                                                value={user.organization}
                                                onChange={handleInputChange}
                                                label="DME Organization*"
                                                variant="outlined"
                                            />
                                            {
                                                userErrors.organization && (
                                                    <FormHelperText style={{color: 'red', paddingLeft: '25px'}}>
                                                        Organization must not be empty!
                                                    </FormHelperText>
                                                )
                                            }
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container style={{paddingTop: '35px'}}>
                                <Grid item xs={12} sm={6} style={{padding: '15px'}}>
                                    <TextField
                                        fullWidth
                                        id={`first_name`}
                                        name="first_name"
                                        value={user.first_name}
                                        onChange={handleInputChange}
                                        label="First Name*"
                                        variant="outlined"
                                        onBlur={() => getPossibleUsernames(setPossibleEmail, user.first_name, user.last_name)}
                                    />
                                    {
                                        userErrors.first_name && (
                                            <FormHelperText style={{color: 'red', paddingLeft: '10px'}}>
                                                First Name must not be empty!
                                            </FormHelperText>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} style={{padding: '15px'}}>
                                    <TextField
                                        fullWidth
                                        id={`last_name`}
                                        name="last_name"
                                        value={user.last_name}
                                        onChange={handleInputChange}
                                        label="Last Name*"
                                        variant="outlined"
                                        onBlur={() => getPossibleUsernames(setPossibleEmail, user.first_name, user.last_name)}
                                    />
                                    {
                                        userErrors.last_name && (
                                            <FormHelperText style={{color: 'red', paddingLeft: '10px'}}>
                                                Last Name must not be empty!
                                            </FormHelperText>
                                        )
                                    }
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12} sm={6} style={{padding: '15px'}}>
                                    <TextField
                                        fullWidth
                                        id={`username`}
                                        name="username"
                                        label="Username"
                                        value={user.username}
                                        variant="outlined"
                                        onChange={handleInputChange}
                                    />
                                    {
                                        user.username.trim() !== "" && <span style={{color: '#D97706', width:'50%',paddingTop: '5px'}}>This is just a preference, the given username might not be the one you chose</span>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} style={{padding: '15px'}}>
                                    <TextField
                                        fullWidth
                                        id={`email`}
                                        name="email"
                                        label="Email*"
                                        value={user.email}
                                        variant="outlined"
                                        onChange={handleInputChange}
                                        onBlur={(event) => {
                                            checkEmail(event.target.value, restrictedDomains, setValidEmail);
                                        }}
                                    />
                                    {
                                        user.email.trim() !== "" && getValidationMessage(validEmail)
                                    }
                                    {
                                        userErrors.email && (
                                            <FormHelperText style={{color: 'red', paddingLeft: '10px'}}>
                                                Email must not be empty!
                                            </FormHelperText>
                                        )
                                    }
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={12} sm={6} style={{padding: '15px'}}>
                                    <TextField
                                        fullWidth
                                        id={`telephone`}
                                        name="telephone"
                                        label="Telephone*"
                                        value={user.telephone}
                                        variant="outlined"
                                        onChange={handleInputChange}
                                    />
                                    {
                                        userErrors.telephone && (
                                            <FormHelperText style={{color: 'red', paddingLeft: '10px'}}>
                                                Telephone must not be empty!
                                            </FormHelperText>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} style={{padding: '15px'}}>
                                    <TextField
                                        fullWidth
                                        id={`team`}
                                        name="team"
                                        label="Team"
                                        value={user.team}
                                        variant="outlined"
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container style={{ paddingTop: "30px" }}></Grid>
                        </Grid>
                    </form>
                )}


                {activeStep === 1 && reqType === 'Deactivate account' && (
                    <form style={{paddingTop: '30px'}}>
                        <Grid container>
                            <Grid container style={{paddingTop: '30px'}} spacing={3}>
                                {payload && payload.role_name === 'Corporate/Super Admin' && (
                                    <Grid item xs={12} sm={6}>
                                        <FormControl style={{ width: "80%"}}>
                                            <InputLabel>
                                                User type*:
                                            </InputLabel>
                                            <Select
                                                value={userType}
                                                onChange={handleChangeUserType}
                                                style={{ textAlign: "left", padding: '5px'}}
                                            >
                                                <MenuItem value={"Hospice"}>Hospice</MenuItem>
                                                <MenuItem value={"DME"}>DME</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {
                                            userErrors.userType && (
                                                <FormHelperText style={{color: 'red', paddingLeft: '25px'}}>
                                                    User type must not be empty!
                                                </FormHelperText>
                                            )
                                        }
                                    </Grid>
                                )}
                                <Grid item xs={12} sm={6}>
                                    {payload && userType === 'Hospice' && (
                                        <React.Fragment>
                                            <TextField
                                                fullWidth
                                                id={`organization`}
                                                name="organization"
                                                value={user.organization}
                                                onChange={handleInputChange}
                                                label="Hospice Organization*"
                                                variant="outlined"
                                            />
                                            {
                                                userErrors.organization && (
                                                    <FormHelperText style={{color: 'red', paddingLeft: '25px'}}>
                                                        Organization must not be empty!
                                                    </FormHelperText>
                                                )
                                            }
                                        </React.Fragment>
                                    )}

                                    {payload && userType === 'DME' && (
                                        <React.Fragment>
                                            <TextField
                                                fullWidth
                                                id={`organization`}
                                                name="organization"
                                                value={user.organization}
                                                onChange={handleInputChange}
                                                label="DME Organization*"
                                                variant="outlined"
                                            />
                                            {
                                                userErrors.organization && (
                                                    <FormHelperText style={{color: 'red', paddingLeft: '25px'}}>
                                                        Organization must not be empty!
                                                    </FormHelperText>
                                                )
                                            }
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container style={{paddingTop: '15px'}} spacing={3}>
                                <Grid item xs={12} sm={6} style={{padding: '15px'}}>
                                    <TextField
                                        fullWidth
                                        id={`first_name`}
                                        name="first_name"
                                        value={user.first_name}
                                        onChange={handleInputChange}
                                        label="First Name*"
                                        variant="outlined"
                                    />
                                    {
                                        userErrors.first_name && (
                                            <FormHelperText style={{color: 'red', paddingLeft: '10px'}}>
                                                First Name must not be empty!
                                            </FormHelperText>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={12} sm={6} style={{padding: '15px'}}>
                                    <TextField
                                        fullWidth
                                        id={`last_name`}
                                        name="last_name"
                                        value={user.last_name}
                                        onChange={handleInputChange}
                                        label="Last Name*"
                                        variant="outlined"
                                    />
                                    {
                                        userErrors.last_name && (
                                            <FormHelperText style={{color: 'red', paddingLeft: '10px'}}>
                                                Last Name must not be empty!
                                            </FormHelperText>
                                        )
                                    }
                                </Grid>
                            </Grid>

                            <Grid container style={{ paddingTop: "15px" }} spacing={3}>
                                <Grid item xs={12} style={{padding: '15px'}}>
                                    <TextField
                                        fullWidth
                                        id={`email`}
                                        name="email"
                                        label="Email*"
                                        value={user.email}
                                        variant="outlined"
                                        onChange={handleInputChange}
                                        onBlur={(event) => {
                                            checkEmail(event.target.value, restrictedDomains, setValidEmail);
                                        }}
                                    />
                                    {
                                        user.email.trim() !== "" && getValidationMessage(validEmail)
                                    }
                                    {
                                        userErrors.email && (
                                            <FormHelperText style={{color: 'red', paddingLeft: '10px'}}>
                                                Email must not be empty!
                                            </FormHelperText>
                                        )
                                    }
                                </Grid>
                            </Grid>

                            <Grid container style={{ paddingTop: "30px" }}></Grid>
                        </Grid>
                    </form>
                )}

                {possibleEmail.length > 1 && (
                    <React.Fragment key='bottom'>
                        <SwipeableDrawer
                            anchor='bottom'
                            open={openDrawer}
                            onClose={() => setOpenDrawer(false)}
                        >
                            <ul style={{listStyle: 'none', margin:0,padding:0}}>
                                {possibleEmail.map((possibleUsername,idx) => idx === 0 ? (
                                    <div key={idx} style={{display: 'flex', alignItems: 'center', paddingLeft: 40, paddingTop:20}}>
                                        <span style={{fontSize: 16,fontWeight: 600}}>Preferred username:</span>
                                        <IconButton style={{marginLeft:10}} onClick={(e) => {e.preventDefault(); setOpenDrawer(false)}}>
                                            <CloseIcon/>
                                        </IconButton>
                                    </div>
                                ) : (
                                    <ListItem button key={idx} style={{paddingLeft: 48, display: 'flex', alignItems: 'center'}}
                                              onClick={(e) => {
                                                  e.preventDefault();
                                                  const event = {
                                                      target: {
                                                          name: "username",
                                                          value: possibleUsername
                                                      }
                                                  }
                                                  handleInputChange(event);
                                                  setOpenDrawer(false);
                                              }}>
                                        <span>{possibleUsername}</span>
                                        <IconButton
                                            style={{marginLeft: 5}}>
                                            <CheckIcon style={{fill: 'green'}}/>
                                        </IconButton>
                                    </ListItem>
                                ))}
                            </ul>
                        </SwipeableDrawer>
                    </React.Fragment>
                )}


                {activeStep === 2 && (
                    <Grid container direction={"column"} style={{paddingTop: '40px'}}>
                        <Grid container>
                            <Grid item xs={1} sm={4}></Grid>
                            <Grid item xs={10} sm={4}>
                                <MessageOutlinedIcon style={{fontSize: 96, color: '#4F86A0'}}/>
                            </Grid>
                            <Grid item xs={1} sm={4}></Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={1} sm={3}></Grid>
                            <Grid item xs={10} sm={6}>
                                <Typography style={{textAlign: 'center',paddingTop: 8, fontWeight: 700, fontSize: 20,color: '#00223D'}}>Thank you for submitting your request!.</Typography>
                            </Grid>
                            <Grid item xs={1} sm={3}></Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={1} sm={3}></Grid>
                            <Grid item xs={10} sm={6}>
                                <Typography style={{textAlign: 'center',color: "#4B5563"}}>Your message has been sent and a member of our support team will answer your request within the next business day.</Typography>
                            </Grid>
                            <Grid item xs={1} sm={3}></Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid container style={{ paddingTop: "15px" }}>
                    {activeStep !== 0 && activeStep !== 2 ? (
                        <Grid item xs={12} sm={6} style={{padding: '10px'}}>
                            <Button
                                onClick={handleBack}
                                style={{
                                    backgroundColor: "#efefef",
                                    width: "80%",
                                    fontWeight: 500,
                                    padding: "10px"
                                }}
                            >
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                    <ArrowBackIcon/>
                                    <span>Back</span>
                                </div>
                            </Button>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={6} style={{padding: '10px'}}></Grid>
                    )}
                    {reqType !== "" && activeStep !== 2 && (
                        <Grid item xs={12} sm={6} style={{padding: '10px'}}>
                            <Button
                                onClick={handleNext}
                                // disabled={
                                //     reqType === 'Create new account' ?
                                //         !(userType && user.role && user.first_name && user.last_name && user.email && user.username && user.organization && validEmail)
                                //         :
                                //         !(userType && user.first_name && user.last_name && user.email && validEmail)
                                // }
                                style={{
                                    backgroundColor: "#00223D",
                                    color: "#ffffff",
                                    width: "80%",
                                    fontWeight: 500,
                                    padding: "10px"
                                }}
                            >
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px'}}>
                                    <span>Next</span>
                                    <ArrowForwardIcon/>
                                </div>
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </div>
    );
}
