import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import {DialogActions} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const quickViewStyles = makeStyles(() => ({
    tdHeaderCell: {
        padding: 8,
        border: "1px solid black",
        background: "lightgray"
    },
    tdDataCell: {
        padding: 8,
        border: "1px solid black"
    }
}));

export default function InventoryQuickView({items = []}) {
    const [showModal, setShowModal] = useState(false);
    const classes = quickViewStyles();
    
    const group = items.reduce((arr, item) => {
        arr[item.category] = [...(arr[item.category] || []), item];
        return arr;
    }, {})
    const tableContent = Object.keys(group).map((cat) => {
        const rows = group[cat].map((item, i) => {
            const category = i === 0 ? <td className={classes.tdDataCell} rowspan={group[cat].length + 1}>{cat}</td> : null;
            return (
                <tr>
                    {category}
                    <td className={classes.tdDataCell}>{item.name}</td>
                    <td className={classes.tdDataCell}>{item.quantity}</td>
                </tr>
            );
        })
    
        return <tbody>{rows}</tbody>
    })
    return (
        <React.Fragment>
            <Button variant='outlined' color='primary' style={{height: 36}}
                    onClick={() => setShowModal(true)}
            >
               Quick Inventory View
            </Button>
            <Dialog
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'sm'}
                fullWidth
            >
                <DialogContent>
                    <Grid container justify={"space-between"} alignContent={"center"} alignItems={"center"}>
                        <Grid item style={{fontSize:24, fontWeight:'bold'}}>
                            Quick Inventory View
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container alignContent={"flex-start"} justify={"flex-start"} style={{minWidth: 320}}>
                            <table style={{textAlign: "left"}}>
                                <thead>
                                <tr>
                                    <th className={classes.tdHeaderCell}>Category</th>
                                    <th className={classes.tdHeaderCell}>Item</th>
                                    <th className={classes.tdHeaderCell}>Quantity</th>
                                </tr>
                                </thead>
                                {tableContent}
                            </table>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"} onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
