import React, {useEffect} from 'react';
import jwt from "jwt-decode";
import {endpoints} from "../../constants/endpoints";
import {useHistory} from "react-router-dom";
import {login} from "../../redux/actions";
import {routes} from "../../constants/routes/routes";
import {useDispatch} from "react-redux";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function LoginWithTempPass(props) {
    const history = useHistory();
    const dispatch = useDispatch();

    let tempPass = (new URLSearchParams(window.location.search)).get("tempPass");
    let username = (new URLSearchParams(window.location.search)).get("username");

    const getUserInfo = () => {
        let user = {
            username: username && username.toString().trim(),
            password: tempPass && tempPass.toString().trim(),
        };
        fetch(`${endpoints.login}`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(user)
            }
        )
            .then(response => {
                return response.json();
            }).then(response => {
            const user = jwt(response.accessToken);
            dispatch(login(user.payload.username));
            localStorage.setItem('key', JSON.stringify(response));
            props.setShowNav(true);
            if (user.payload.temporary_password) {
                history.push({
                    pathname: `${routes.password_change}`,
                    temporary_password: true
                })
            }
        })
            .catch(err => {
                console.log(err.stack);
            });
    };

    useEffect(() => {
        getUserInfo();
    }, []);

    return (
        <Grid>
        </Grid>
    )
}
