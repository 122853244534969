import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import PatientsHeader from "../../../patients/view-all-patients/patientsHeader/patientsHeader";
import {endpoints} from "../../../../constants/endpoints";
import {routes} from "../../../../constants/routes/routes";
import {logout, setCriteriaDmeUsers} from "../../../../redux/actions";
import {TabSearchBar} from "../../../../common/search/tabSearch/TabSearch";
import {Table} from '../../../../common/table/table';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {useHistory} from "react-router-dom";
import {searchDmeUsers, setDmeUserOrganizations, setRoles} from "../../../../common/search/tabSearch/TabSearchHelper";
import {tableColumnTitles} from "../../../../constants/names/columnTitles";
import {tooltips} from "../../../../constants/inputs/tooltips";
import {componentTitles} from "../../../../constants/names/titles";
import {buttonText} from "../../../../constants/alert/alertButtonTexts";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {userTypes} from "../../../../constants/types/userTypes";
import ExportForm from "../../../../common/exportForm/exportForm";

export default function DmeUsers() {
    let history = useHistory();
    const dispatch = useDispatch();
    const [stringStatus, setStringStatus] = useState('');
    const [dmeUsers, setDmeUsers] = useState([]);
    const searchValues = useSelector(state => state.SearchCriteriaDmeUsers);
    const [exportValues, setExportValues] = useState([]);
    const tableRef = useRef();
    const [dmeUsersFiltersValue, setDmeUsersFiltersValue] = useState({
        input: '',
        criteria: ['All'],
        role: undefined,
        organization: undefined,
        isActiveUser: true,
    });

    const [roleOptions, setRoleOptions] = useState([]);
    const [orgOptions, setOrgOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const filterOptions = [
        {name: "role", placeholder: "Role", options: roleOptions, value: dmeUsersFiltersValue.role, isSelect: true},
        {
            name: "organization",
            placeholder: "Organization",
            options: orgOptions,
            value: dmeUsersFiltersValue.organization,
            isSelect: true
        },
        {
            name: "isActiveUser",
            value: dmeUsersFiltersValue.isActiveUser,
            positive: "Active Users",
            negative: "Inactive Users"
        },
    ];

    const handleDeleteCriteria = (chip) => {
        setDmeUsersFiltersValue({
            ...dmeUsersFiltersValue,
            criteria: dmeUsersFiltersValue.criteria.filter(c => chip !== c)
        });
    };

    function handleFilterUpdate(event) {
        setDmeUsersFiltersValue({...dmeUsersFiltersValue, [event.target.name]: event.target.value})
    }

    const handleInputKeyup = (event) => {
        if (event.keyCode === 13 && dmeUsersFiltersValue.input) {
            if (!dmeUsersFiltersValue.criteria.includes(dmeUsersFiltersValue.input)) {
                if (dmeUsersFiltersValue.criteria[0] === ('All')) {
                    setDmeUsersFiltersValue({
                        ...dmeUsersFiltersValue,
                        criteria: [dmeUsersFiltersValue.input],
                        input: ''
                    });
                    searchDmeUsers(dmeUsersFiltersValue, setDmeUsers, setIsLoading);
                } else {
                    setDmeUsersFiltersValue({
                        ...dmeUsersFiltersValue,
                        criteria: [...dmeUsersFiltersValue.criteria, dmeUsersFiltersValue.input],
                        input: ''
                    });
                    searchDmeUsers(dmeUsersFiltersValue, setDmeUsers, setIsLoading);
                }
            }
        }
    };
    const handleInputChange = (value) => {
        setDmeUsersFiltersValue({...dmeUsersFiltersValue, input: value})
    };

    const clearFilters = () => {
        setDmeUsersFiltersValue({
            input: '',
            criteria: ['All'],
            role: undefined,
            organization: undefined,
            isActiveUser: true,
        });
        dispatch(setCriteriaDmeUsers(dmeUsersFiltersValue));
    };
    useEffect(() => {
        //Call query function
        dispatch(setCriteriaDmeUsers(dmeUsersFiltersValue));
    }, [dmeUsersFiltersValue]);

    function getDmeUsers() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${process.env.REACT_APP_DEV_API_URL}/dme/users`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setDmeUsers(response.data);
            })
            .catch(err => console.log('error'))
    }

    const getExportValues = () => {
        if (tableRef?.current?.state?.data !== undefined) {
            let final = [];
            tableRef.current.state.data.map(user => {
                let newArr = [];
                newArr.push(user.first_name + " " + user.last_name);
                let permissions = '';
                user.permissions.map(permission => {
                    permissions += permission + "\n";
                })
                newArr.push(permissions);
                final.push(newArr)
            });
            return final;
        }
    };

    useEffect(() => {
        // getDmeUsers();
        setRoles(setRoleOptions, endpoints.dme_roles, userTypes.dme);
        setDmeUserOrganizations(setOrgOptions);
        searchDmeUsers(dmeUsersFiltersValue, setDmeUsers, setIsLoading);
    }, []);

    const columns = [
        {
            title: tableColumnTitles.edit, field: 'dme_user_id', width: 80, sorting: false,
            render: rowData => <div>
                <Tooltip placement="top" title={tooltips.editUser}>
                    <IconButton
                        onClick={() => {
                            history.push({pathname: `${routes.dme_user_edit}${rowData.user_id}`, id: rowData.user_id})
                        }}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        },
        {
            title: 'User ID', field: 'user_id', width: 110, defaultSort: 'desc'
        },
        {
            title: tableColumnTitles.name, field: 'first_name', width: 200,
            render: rowData => <div> {rowData.first_name + ' ' + rowData.last_name} </div>
        },
        {
            title: tableColumnTitles.username, field: 'username', width: 200,
            render: rowData => <div> {rowData.username} </div>
        },
        {
            title: tableColumnTitles.userGroup, field: 'name', width: 200,
            render: rowData => <div> {rowData.name} </div>
        },
        {
            title: tableColumnTitles.emailAddress,
            field: 'dme_name',
            hidden: true,
            defaultFilter: dmeUsersFiltersValue.organization
        },
        {
            title: tableColumnTitles.emailAddress,
            field: 'search',
            hidden: true,
            defaultFilter: dmeUsersFiltersValue.input
        },
        {title: tableColumnTitles.emailAddress, field: 'stringStatus', hidden: true, defaultFilter: stringStatus},
        {
            title: tableColumnTitles.emailAddress,
            field: 'name',
            hidden: true,
            defaultFilter: dmeUsersFiltersValue.role,
            customFilterAndSearch: (term, rowData) => term === rowData.name
        },
        {title: tableColumnTitles.emailAddress, field: 'email', width: 200},
        {title: tableColumnTitles.phoneNumber, field: 'phone', width: 220},
        {
            title: tableColumnTitles.status, field: 'status', width: 140,
            render: rowData => <div style={{marginLeft: '-40px', textAlign: 'center'}}>
                {rowData.status ? (
                    <span className={'active_status'}> Active </span>
                ) : (
                    <span className={'inactive_status'}> Inactive </span>
                )}
            </div>
        },
        {
            title: tableColumnTitles.dmeOrganization, field: 'organization_name', width: 340,
            render: rowData => <div>{rowData.dme_name && (
                <div className={'organization_row'}>
                    {rowData.dme_name}
                </div>
            )}
            </div>
        },
    ];

    const headers = [
        {text: "User Name", style: 'tableHeader'},
        {text: "Permissions", style: 'tableHeader'},
    ];
    const excelHeaders = [
        "User Name",
        "Permission",
    ];

    useEffect(() => {
        if (dmeUsersFiltersValue.isActiveUser === true) {
            setStringStatus('true')
        } else if (dmeUsersFiltersValue.isActiveUser === false) {
            setStringStatus('false')
        }
    }, [dmeUsersFiltersValue.isActiveUser]);
    return (
        <React.Fragment>
            <Grid container
                  direction="column"
                  justifyContent="space-around"
                  style={{paddingLeft:16, paddingRight:16}}>
                <Grid item xs>
                    <PatientsHeader buttonText={buttonText.dmeUser}
                                    path={'/dmeuser/new'}
                                    title={componentTitles.dmeUsers}
                    />
                </Grid>
                <Grid item xs>
                    <TabSearchBar
                        filtersValue={dmeUsersFiltersValue}
                        handleDeleteCriteria={handleDeleteCriteria}
                        handleInputKeyup={handleInputKeyup}
                        handleInputChange={handleInputChange}
                        clearFilters={clearFilters}
                        filterOptions={filterOptions}
                        handleFilterUpdate={handleFilterUpdate}
                    />
                </Grid>
                <Grid container
                      direction="column"
                      justify="flex-end"
                      justifyContent="space-around"
                      alignItems="flex-end"
                      style={{paddingBottom:16}}>
                    <Grid item xs>
                        <ExportForm
                            landscape={true}
                            fileName='DME User Permission'
                            exportValues={exportValues}
                            getExportValues={getExportValues}
                            showPrint={false}
                            headers={headers}
                            excelHeaders={excelHeaders}
                        />
                    </Grid>
                </Grid>
                <Table tableRef={tableRef} columns={columns} items={dmeUsers}/>
            </Grid>
        </React.Fragment>
    );
}
