import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import _ from "lodash";
import {endpoints} from "../../../constants/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import moment from 'moment';

export default function ItemAuditTrail(props) {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [values, setValues] = useState([]);

    const handleClose = () => {
        setShowModal(false)
    };

    const getData = async (asset) => {
          const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(`${endpoints.getItemAudit}${asset}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        let res = [];
        response.data.map(item => {
            if (item.exchange_order_id){
                if (item.delivery_order_id){
                    let clone = _.cloneDeep(item);
                    let clone2 = _.cloneDeep(item);
                    clone.exchange_order_id = null;
                    clone2.delivery_order_id = null;
                    res.push(clone);
                    res.push(clone2);
                } else if (item.pickup_order_id) {
                    let clone = _.cloneDeep(item);
                    let clone2 = _.cloneDeep(item);
                    clone.pickup_order_id = null;
                    clone2.exchange_order_id = null;
                    res.push(clone);
                    res.push(clone2);
                }
            } else if (item.pickup_order_id){
                let clone = _.cloneDeep(item);
                let clone2 = _.cloneDeep(item);
                clone.pickup_order_id = null;
                clone2.delivery_order_id = null;
                res.push(clone);
                res.push(clone2);
            } else {
                res.push(item);
            }
        });
        setValues(res);
        setLoading(false);
    };
    useEffect(() => {
        if (values.length === 0 && showModal) {
            getData(props.asset);
        }
    }, [showModal]);

    useEffect(() => {
        if (values.length > 0 && showModal) {
            setLoading(false);
        }
    }, [values]);


    return (
        <React.Fragment>
            <Button variant='outlined' color='primary'
                    onClick={() => setShowModal(true)}
            >
               Audit Trail
            </Button>
            <Dialog
                open={showModal}
                onClose={() => setShowModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth={'md'}
                fullWidth
            >
                <DialogContent>
                    <Grid container justify={"space-between"} alignContent={"center"} alignItems={"center"}>
                        <Grid item style={{fontSize:24, fontWeight:'bold'}}>
                            Audit Trail
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {!loading ? (
                            <React.Fragment>
                                <Grid container style={{minWidth:320}}>
                                    <Grid item xs={3}>
                                        <h4>Date</h4>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <h4>Action</h4>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <h4>Patient</h4>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <h4>Medical Record</h4>
                                    </Grid>
                                </Grid>
                                {values.map((value, key) => <React.Fragment key={key}>
                                    <Grid container style={{fontWeight:400, padding:8,border:'1px solid lightgrey', marginBottom:8, borderRadius:4}}>
                                        <Grid item xs={3}>
                                            <div>
                                                {value.delivery_completed && value.delivery_order_id && moment(value.delivery_completed).format("L")}
                                                {value.pickup_completed && value.pickup_order_id && moment(value.pickup_completed).format("L")}
                                                {value.exchange_completed && value.exchange_order_id && moment(value.exchange_completed).format("L")}
                                                {
                                                    ((value.delivery_completed === null && value.delivery_order_id) ||
                                                    (value.exchange_completed === null && value.exchange_order_id) ||
                                                    (value.pickup_completed === null && value.pickup_order_id)) &&
                                                    'In progress'}
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div>
                                                {value.delivery_order_id && "Delivery"}
                                                {value.pickup_order_id && "Pickup"}
                                                {value.exchange_order_id && "Exchange"}
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div>
                                                {value.delivery_first_name && value.delivery_order_id && `${value.delivery_first_name} ${value.delivery_last_name}`}
                                                {value.pickup_first_name && value.pickup_order_id && `${value.pickup_first_name} ${value.pickup_last_name}`}
                                                {value.exchange_first_name && value.exchange_order_id && `${value.exchange_first_name} ${value.exchange_last_name}`}
                                            </div>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <div>
                                                {value.delivery_medical_record && value.delivery_order_id && value.delivery_medical_record}
                                                {value.pickup_medical_record && value.pickup_order_id && value.pickup_medical_record}
                                                {value.exchange_medical_record && value.exchange_order_id && value.exchange_medical_record}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>)}
                            </React.Fragment>
                        ) :
                        <div style={{
                            height: 430,
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                        }}>
                            <CircularProgress/>
                        </div>}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}
