import React, {createRef, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Backdrop, Checkbox, FormControlLabel, makeStyles, TableCell} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {sectionTitles} from "../../../constants/names/titles";
import FormControl from "@material-ui/core/FormControl";
import {setStates} from "../../../common/search/tabSearch/TabSearchHelper";
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {checkTokenAvailability, getTokenInformation, getYearRange} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {endpoints} from "../../../constants/endpoints";
import {getAllHospices} from "../../utils/helpers/dataGetter";
import Button from "@material-ui/core/Button";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {tableColumnTitles} from "../../../constants/names/columnTitles";
import {utilsTokenIdentifiers} from "../../../constants/inputs/values";
import {findIndex, cloneDeep} from "lodash";
import Typography from "@material-ui/core/Typography";

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100,
        textAlign: 'left'
    })
};
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    pageHeader: {
        textAlign: 'left',
        color: '#00223D',
        fontSize: 26,
        fontWeight: "bold",
        lineHeight: '38px',
        borderBottom: '1px solid #B2D1D9',
        marginBottom: 16,
        textTransform: "uppercase",
        paddingTop: 40
    },
    pageSubHeader: {
        textAlign: 'left',
        color: '#00223D',
        fontSize: 20,
        fontWeight: "bold",
        lineHeight: '28px',
        borderBottom: '1px solid #B2D1D9',
        marginBottom: 16,
        textTransform: "uppercase",
        paddingTop: 40
    },
});

export const GeographicReport = ({}) => {
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const years = getYearRange('01-01-2015');
    const [loading, setLoading] = useState(false);
    const [stateOptions, setStateOptions] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [enableNationalSummary, setEnableNationalSummary] = useState(false);
    const [hospiceList, setHospiceList] = useState([]);
    const [selectedHospiceOrg, setSelectedHospiceOrg] = useState(null);
    const [reportData, setReportData] = useState({});
    const yearSelectRef = React.createRef();
    const hospiceSelectRef = React.createRef();

    const clearFilters = () => {
        setReportData({});
        setSelectedHospiceOrg(null);
        setSelectedYear(null);
        setSelectedStates([]);
        setEnableNationalSummary(false);
        yearSelectRef.current.select.clearValue();
        hospiceSelectRef.current.select.clearValue();
    }

    const generateReport = async () => {
        setLoading(true);
        setReportData({});
        const generateReportCall = await fetch(endpoints.geographicReport, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            },
            body: JSON.stringify({
                hospiceId: selectedHospiceOrg,
                selectedStates: selectedStates,
                enableNationalSummary: enableNationalSummary,
                year: selectedYear
            })
        })
        const generateReportResponse = await generateReportCall.json();
        if (generateReportResponse.status === "success") {
            let reportClone = cloneDeep(generateReportResponse.data);

            selectedStates.map(item => findIndex(reportClone.stateData, {"state_code" : item.value}) === -1 && reportClone.stateData.push({"state_code": item.value}))
            setReportData(reportClone);

        } else {
            enqueueSnackbar("Something happened while generating the report. Please contact support", {variant: "warning"})
        }
        setLoading(false);
    };

    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
            await getAllHospices(setHospiceList);
        })();
        setStates(setStateOptions);
    }, []);

    return <Grid container style={{padding: 32}}>
        <Grid container style={{backgroundColor: 'white', borderRadius: 4, padding: 16}} spacing={2}>
            <Grid item xs={12} className={classes.pageHeader}>
                {sectionTitles.geographicReport}
            </Grid>
            <Grid item xs={2} justify={"flex-start"} alignContent={"flex-start"}>
                <Select
                    ref={yearSelectRef}
                    placeholder={'Select Year*'}
                    styles={selectStyles}
                    options={years.map(year => {
                        return {value: year, label: year}
                    })}
                    onChange={(e) =>{
                        if(e) {
                            setSelectedYear(e.value);
                            setReportData({});
                        }
                    }}
                />
            </Grid>
            <Grid item container justify={"flex-start"} alignContent={"flex-start"} style={{width: 210}}>
                <FormControl component="fieldset">
                    <FormControlLabel
                        checked={enableNationalSummary}
                        control={<Checkbox color="primary"/>}
                        label="National (Summary)"
                        onClick={() => {
                            setEnableNationalSummary(prevState => !prevState);
                            setReportData({});
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={2} style={{paddingLeft: 6, paddingRight: 6, paddingBottom: 4}}>
                <Select
                    ref={hospiceSelectRef}
                    placeholder={'Select Hospice*'}
                    options={hospiceList.map(hospice => {
                        return {value: hospice.hospice_organization_id, label: hospice.hospice_name}
                    })}
                    onChange={(e) => {
                        setSelectedHospiceOrg(e.value);
                        setReportData({});
                    }}
                    styles={selectStyles}
                />
            </Grid>
            <Grid item xs={2} style={{paddingLeft: 6, paddingRight: 6, paddingBottom: 4}}>
                <Select
                    placeholder={'Select State'}
                    options={stateOptions.filter(state => !selectedStates.includes(state))}
                    styles={selectStyles}
                    onChange={(e) => {
                        setSelectedStates([...selectedStates, e]);
                        setReportData({})
                    }}
                    value={[]}
                />
            </Grid>
            <Grid item style={{paddingLeft: 16}}>
                <Button variant={'contained'} color={'primary'} onClick={generateReport}
                        disabled={selectedHospiceOrg === null || selectedYear === null || loading}
                >Generate Report</Button>
            </Grid>
            <Grid item style={{paddingLeft: 16}}>
                <Button variant={'outlined'} onClick={clearFilters}>Clear Filters</Button>
            </Grid>

            <Grid item xs={12}
                  style={{
                      fontSize: 20,
                      fontWeight: 500,
                      textAlign: 'left',
                      paddingTop: 16,
                      paddingLeft: 8
                  }}>{sectionTitles.selectedStates}</Grid>
            <Grid item xs={12} container justify={"flex-start"} alignContent={"flex-start"} alignItems={"flex-start"}
                  style={{height: 160, overflowY: "scroll", background: "white", borderRadius: 4}}>
                {selectedStates?.length > 0 ? selectedStates.map((item, idx) => {
                    return (
                        <Grid item xs={12} style={{
                            maxHeight: 50,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            alignContent: 'center',
                            background: 'whitesmoke',
                            borderRadius: 4,
                            margin: 4,
                            marginTop: idx === 0 ? 8 : 0,
                            marginBottom: 8,
                            padding: 8,
                            paddingTop: 8
                        }} key={idx}>
                            <Grid item style={{fontSize: 15, paddingLeft: 8, textAlign: 'left'}}>
                                {item.label}
                            </Grid>
                            <Grid item>
                                <IconButton size={"small"} onClick={() => {
                                    setSelectedStates([...selectedStates.filter(target => target.value !== item.value)]);
                                }}>
                                    <HighlightOffIcon color={'primary'}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    )
                }) : <Grid item xs={12} style={{
                    maxHeight: 50,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignContent: 'center',
                    background: 'whitesmoke',
                    borderRadius: 4,
                    margin: 4,
                    marginTop: 8,
                    marginBottom: 8,
                    padding: 8,
                    paddingTop: 8
                }}>
                    <Grid item style={{fontSize: 15, paddingLeft: 8, textAlign: 'left'}}>
                        {sectionTitles.noSelectedStates}
                    </Grid>
                </Grid>
                }
            </Grid>
            {Object.keys(reportData).length > 0 && <Grid item xs={12}>
                <Typography variant={"h6"} className={classes.pageSubHeader}>
                    {sectionTitles.captionData}
                </Typography>
            </Grid>}
            {Object.keys(reportData).length > 0 && <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell width={150}>{tableColumnTitles.organizationName}</TableCell>
                                <TableCell align="left" width={100}>{tableColumnTitles.year}</TableCell>
                                <TableCell align="left" width={150}>{tableColumnTitles.totalPatientDays}</TableCell>
                                <TableCell align="left" width={150}>{tableColumnTitles.averageDailyCensus}</TableCell>
                                <TableCell align="left" width={150}>{tableColumnTitles.totalDMEExpense}</TableCell>
                                <TableCell align="left"
                                           width={170}>{tableColumnTitles.totalCostPerPatientDay}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row" width={150}>
                                    {reportData?.organizationData?.hospice_name ? reportData?.organizationData?.hospice_name : hospiceList[findIndex(hospiceList,{"hospice_organization_id": selectedHospiceOrg})].hospice_name}
                                </TableCell>
                                <TableCell align="left" width={100}>{reportData?.organizationData?.year ? reportData?.organizationData?.year : selectedYear}</TableCell>
                                <TableCell align="left"
                                           width={150}>{reportData?.organizationData?.total_patient_days ? reportData?.organizationData?.total_patient_days : "0.00"}</TableCell>
                                <TableCell align="left"
                                           width={150}>{reportData?.organizationData?.average_daily_census ? reportData?.organizationData?.average_daily_census : "0.00"}</TableCell>
                                <TableCell align="left"
                                           width={150}>{reportData?.organizationData?.dme_expenses ? reportData?.organizationData?.dme_expenses : "0.00"}</TableCell>
                                <TableCell align="left"
                                           width={170}>{reportData?.organizationData?.cost_per_patient_days ? reportData?.organizationData?.cost_per_patient_days : "0.00"}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>}
            {Object.keys(reportData).length > 0 && enableNationalSummary && <Grid item xs={12}>
                <Typography variant={"h6"} className={classes.pageSubHeader}>
                    {sectionTitles.nationalData}
                </Typography>
            </Grid>}
            {Object.keys(reportData).length > 0 && enableNationalSummary && <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell width={150}>{tableColumnTitles.nationaData}</TableCell>
                                <TableCell align="left" width={100}>{tableColumnTitles.year}</TableCell>
                                <TableCell align="left" width={150}>{tableColumnTitles.uniqueProvider}</TableCell>
                                <TableCell align="left"
                                           width={150}>{tableColumnTitles.providersReportingData}</TableCell>
                                <TableCell align="left" width={150}>{tableColumnTitles.averageDailyCensus}</TableCell>
                                <TableCell align="left" width={150}>{tableColumnTitles.averageDMEExpense}</TableCell>
                                <TableCell align="left"
                                           width={170}>{tableColumnTitles.averageCostPerPatientDay}</TableCell>
                                <TableCell align="left"
                                           width={150}>{tableColumnTitles.highCostPerPatientDay}</TableCell>
                                <TableCell align="left"
                                           width={150}>{tableColumnTitles.lowCostPerPatientDay}</TableCell>
                                <TableCell align="left"
                                           width={150}>{tableColumnTitles.medianCostPerPatientDay}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                <TableRow key={"national"}>
                                    <TableCell component="th" scope="row" width={150}>
                                        {tableColumnTitles.nationaData}
                                    </TableCell>
                                    <TableCell align="left" width={100}
                                               >{reportData?.nationalData[0]?.year ? reportData?.nationalData[0]?.year : selectedYear}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{reportData?.nationalData[0]?.unique_providers ? reportData?.nationalData[0]?.unique_providers : "0.00"}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{reportData?.nationalData[0]?.reporting_providers ? reportData?.nationalData[0]?.reporting_providers : "0.00"}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{reportData?.nationalData[0]?.average_daily_census ? reportData?.nationalData[0]?.average_daily_census : "0.00"}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{reportData?.nationalData[0]?.average_expense ? reportData?.nationalData[0]?.average_expense : "0.00"}</TableCell>
                                    <TableCell align="left" width={170}
                                               >{reportData?.nationalData[0]?.average_cppd ? reportData?.nationalData[0]?.average_cppd : "0.00"}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{reportData?.nationalData[0]?.max_cppd ? reportData?.nationalData[0]?.max_cppd : "0.00"}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{reportData?.nationalData[0]?.min_cppd ? reportData?.nationalData[0]?.min_cppd : "0.00"}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{reportData?.nationalData[0]?.median_cppd ? reportData?.nationalData[0]?.median_cppd : "0.00"}</TableCell>
                                </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>}
            {Object.keys(reportData).length > 0 && selectedStates.length > 0&& <Grid item xs={12}>
                <Typography variant={"h6"} className={classes.pageSubHeader}>
                    {sectionTitles.stateData}
                </Typography>
            </Grid>}
            {Object.keys(reportData).length > 0 && selectedStates.length > 0 && <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell width={150}>{tableColumnTitles.stateData}</TableCell>
                                <TableCell align="left" width={100}>{tableColumnTitles.year}</TableCell>
                                <TableCell align="left" width={150}>{tableColumnTitles.uniqueProvider}</TableCell>
                                <TableCell align="left"
                                           width={150}>{tableColumnTitles.providersReportingData}</TableCell>
                                <TableCell align="left" width={150}>{tableColumnTitles.averageDailyCensus}</TableCell>
                                <TableCell align="left" width={150}>{tableColumnTitles.averageDMEExpense}</TableCell>
                                <TableCell align="left"
                                           width={170}>{tableColumnTitles.averageCostPerPatientDay}</TableCell>
                                <TableCell align="left"
                                           width={150}>{tableColumnTitles.highCostPerPatientDay}</TableCell>
                                <TableCell align="left"
                                           width={150}>{tableColumnTitles.lowCostPerPatientDay}</TableCell>
                                <TableCell align="left"
                                           width={150}>{tableColumnTitles.medianCostPerPatientDay}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reportData?.stateData?.map((row, index) => (
                                <TableRow key={row.state_code + index}>
                                    <TableCell component="th" scope="row" width={150}>
                                        {row.state_code}
                                    </TableCell>
                                    <TableCell align="left" width={100}
                                               >{row.year ? row.year : selectedYear}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{row.unique_providers ? row.unique_providers : "0.00"}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{row.reporting_providers ? row.reporting_providers : "0.00"}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{row.average_daily_census ? row.average_daily_census : "0.00"}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{row.average_expense ? row.average_expense : "0.00"}</TableCell>
                                    <TableCell align="left" width={170}
                                               >{row.average_cppd ? row.average_cppd : "0.00"}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{row.max_cppd ? row.max_cppd : "0.00"}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{row.min_cppd ? row.min_cppd : "0.00"}</TableCell>
                                    <TableCell align="left" width={150}
                                               >{row.median_cppd ? row.median_cppd : "0.00"}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>}
        </Grid>
    </Grid>

}
