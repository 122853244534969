import {parseTimeWithUserTimezone} from "../../../common/timeHandler/timeHandler";
import moment from "moment";
import html2canvas from "html2canvas";
import {cloneDeep} from "lodash";
import pdfMake from "pdfmake/build/pdfmake";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function printProfile(activeItems, allItems, patientOrders, patient_notes){
        const currentInventory = [
            [
                {
                    text: 'Item',
                    fillColor: '#F2F2F2',
                    alignment: 'center',
                    border: [false, true, false, true],
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Asset',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Lot',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Qty',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Class',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Delivery',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Pickup',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Status',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
            ]
        ];
        const notes = [
            [
                {
                    text: 'Date Added',
                    fillColor: '#F2F2F2',
                    alignment: 'center',
                    border: [false, true, false, true],
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Entered By',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Comments',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
            ]
        ];




        const patientOrdersList = [
            [
                {
                    text: 'Order Type',
                    fillColor: '#F2F2F2',
                    alignment: 'center',
                    border: [false, true, false, true],
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Order ID',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Priority',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Order Status',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Created Date',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
            ]
        ];

        const transactionalHistory = [
            [
                {
                    text: 'Item',
                    fillColor: '#F2F2F2',
                    alignment: 'center',
                    border: [false, true, false, true],
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Asset',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Lot',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Qty',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Class',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Delivery',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Pickup',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                {
                    text: 'Status',
                    border: [true, true, false, true],
                    alignment: 'center',
                    fillColor: '#F2F2F2',
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
            ]
        ];
        if (patient_notes.length > 0){
            patient_notes.map(note => {
                notes.push([{
                    text: `${parseTimeWithUserTimezone(note.created_date)}`,
                    fillColor: '#FFF',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                    {
                        text: `${note.first_name + " " + note.last_name}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${note.description}`,
                        border: [true, true, false, true],
                        alignment: 'left',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    }])
            })
        }
        if (activeItems.length > 0){
            activeItems.map(item => {
                let deliveryDate = "";
                let pickupDate = "";
                if (isNaN(item.delivery) || item.delivery === undefined) {
                    if (item.delivery_completed_date !== null && item.exchange_type !== "Reclaimed") {
                        deliveryDate = moment(item.delivery_completed_date).format('LL')
                    } else if (item.exchange_completed_date !== null){
                        deliveryDate = moment(item.exchange_completed_date).format('LL')
                    }
                }else {
                    deliveryDate= item.delivery
                }
                if (item.pickup_status === 'Completed') {
                    pickupDate = moment(item.pickup_completed_date).format('LL')
                } else if (item.exchange_status === 'Completed' && item.exchange_type === 'Reclaimed') {
                    pickupDate  = moment(item.exchange_completed_date).format('LL')
                }
                currentInventory.push([{
                    text: `${item.name ? item.name : item.item_name}`,
                    fillColor: '#FFF',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                    {
                        text: `${item.asset ? item.asset : ''}`,
                        border: [true, true, false, true],
                        alignment: 'left',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${item.lot_no ? item.lot_no : ""}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${item.quantity ? item.quantity : ""}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${item.type ? item.type : ""}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${deliveryDate}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${pickupDate}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${item.item_status ? item.item_status : ''}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    }
                ])
            })
        }

        if (allItems.length > 0){
            allItems.map(item => {
                let deliveryDate = "";
                let pickupDate = "";
                if (isNaN(item.delivery) || item.delivery === undefined) {
                    if (item.delivery_completed_date !== null && item.exchange_type !== "Reclaimed") {
                        deliveryDate = moment(item.delivery_completed_date).format('LL')
                    } else if (item.exchange_completed_date !== null){
                        deliveryDate = moment(item.exchange_completed_date).format('LL')
                    }
                }else {
                    deliveryDate= item.delivery
                }
                if (item.pickup_status === 'Completed') {
                    pickupDate = moment(item.pickup_completed_date).format('LL')
                } else if (item.exchange_status === 'Completed' && item.exchange_type === 'Reclaimed') {
                    pickupDate  = moment(item.exchange_completed_date).format('LL')
                }
                transactionalHistory.push([{
                    text: `${item.name ? item.name : item.item_name}`,
                    fillColor: '#FFF',
                    alignment: 'left',
                    border: [false, true, false, true],
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                    {
                        text: `${item.asset ? item.asset : ''}`,
                        border: [true, true, false, true],
                        alignment: 'left',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${item.lot_no ? item.lot_no : ""}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${item.quantity ? item.quantity : ""}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${item.type ? item.type : ""}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${deliveryDate}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${pickupDate}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${item.item_status ? item.item_status : ''}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    }
                ])
            })
        }

        if (patientOrders.length > 0){
            patientOrders.map(item => {
                patientOrdersList.push([{
                    text: `${item.delivery_order_id ? 'Delivery' : item.pickup_order_id ? 'Pickup' : "Exchange"}`,
                    fillColor: '#FFF',
                    alignment: 'center',
                    border: [false, true, false, true],
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    textTransform: 'uppercase',
                },
                    {
                        text: `${item.delivery_order_id ? `D100${item.delivery_order_id}` : item.pickup_order_id ? `P200${item.pickup_order_id}` : `S300${item.exchange_order_id}`}`,
                        border: [true, true, false, true],
                        alignment: 'left',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${item.priority_code ? item.priority_code : ""}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${item.status ? item.status : ""}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                    {
                        text: `${item.type ? capitalizeFirstLetter(item.type) : ""}`,
                        border: [true, true, false, true],
                        alignment: 'center',
                        fillColor: '#FFF',
                        fontSize: 8,
                        margin: [0, 0, 0, 0],
                        textTransform: 'uppercase',
                    },
                ])
            })
        }


        html2canvas(document.getElementById('print')).then((canvas) => {
            let data = canvas.toDataURL();

            let docDefinition = {
                content: [{
                    image: data,
                    width: 570,

                },
                    {margin: [ 19, 14, 0, 0 ],
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function (i, node) {
                                return 1;
                            },
                            vLineWidth: function (i, node) {
                                return 1;
                            },
                            hLineColor: function (i, node) {
                                if (i === 1 || i === 0) {
                                    return '#bfdde8';
                                }
                                return '#eaeaea';
                            },
                            vLineColor: function (i, node) {
                                return '#eaeaea';
                            },
                            hLineStyle: function (i, node) {
                                return null;
                            },
                            paddingLeft: function (i, node) {
                                return 10;
                            },
                            paddingRight: function (i, node) {
                                return 10;
                            },
                            paddingTop: function (i, node) {
                                return 2;
                            },
                            paddingBottom: function (i, node) {
                                return 0;
                            },
                            fillColor: function (rowIndex, node, columnIndex) {
                                return '#333';
                            },
                        },
                        table: {
                            headerRows: 1,
                            widths: [520],
                            heights: ['*', 100],
                            alignment: 'center',
                            body: [
                                [
                                    {
                                        text: 'Patient Notes',
                                        fillColor: '#eeeeee',
                                        fontSize: 10,
                                        alignment: 'left',
                                        border: [false, false, false, false],
                                        margin: [228, 0, 0, 0],
                                        textTransform: 'uppercase',
                                    },

                                ]
                            ]
                        },

                    },
                    {margin: [ 19, 0, 0, 0 ],
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function (i, node) {
                                return 1;
                            },
                            vLineWidth: function (i, node) {
                                return 1;
                            },
                            hLineColor: function (i, node) {
                                if (i === 1 || i === 0) {
                                    return '#F2F2F2';
                                }
                                return '#eaeaea';
                            },
                            vLineColor: function (i, node) {
                                return '#eaeaea';
                            },
                            hLineStyle: function (i, node) {
                                // if (i === 0 || i === node.table.body.length) {
                                return null;
                                //}
                            },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            paddingLeft: function (i, node) {
                                return 10;
                            },
                            paddingRight: function (i, node) {
                                return 10;
                            },
                            paddingTop: function (i, node) {
                                return 2;
                            },
                            paddingBottom: function (i, node) {
                                return 2;
                            },
                            fillColor: function (rowIndex, node, columnIndex) {
                                return '#fff';
                            },
                        },
                        table: {
                            headerRows: 1,
                            widths: [100, 90, 288,],
                            body: cloneDeep(notes)
                        },

                    },
                    {margin: [ 19, 14, 0, 0 ],
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function (i, node) {
                                return 1;
                            },
                            vLineWidth: function (i, node) {
                                return 1;
                            },
                            hLineColor: function (i, node) {
                                if (i === 1 || i === 0) {
                                    return '#bfdde8';
                                }
                                return '#eaeaea';
                            },
                            vLineColor: function (i, node) {
                                return '#eaeaea';
                            },
                            hLineStyle: function (i, node) {
                                return null;
                            },
                            paddingLeft: function (i, node) {
                                return 10;
                            },
                            paddingRight: function (i, node) {
                                return 10;
                            },
                            paddingTop: function (i, node) {
                                return 2;
                            },
                            paddingBottom: function (i, node) {
                                return 0;
                            },
                            fillColor: function (rowIndex, node, columnIndex) {
                                return '#333';
                            },
                        },
                        table: {
                            headerRows: 1,
                            widths: [520],
                            heights: ['*', 100],
                            alignment: 'center',
                            body: [
                                [
                                    {
                                        text: 'Current Inventory',
                                        fillColor: '#eeeeee',
                                        fontSize: 10,
                                        alignment: 'left',
                                        border: [false, false, false, false],
                                        margin: [228, 0, 0, 0],
                                        textTransform: 'uppercase',
                                    },

                                ]
                            ]
                        },

                    },
                    {margin: [ 19, 0, 0, 0 ],
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function (i, node) {
                                return 1;
                            },
                            vLineWidth: function (i, node) {
                                return 1;
                            },
                            hLineColor: function (i, node) {
                                if (i === 1 || i === 0) {
                                    return '#F2F2F2';
                                }
                                return '#eaeaea';
                            },
                            vLineColor: function (i, node) {
                                return '#eaeaea';
                            },
                            hLineStyle: function (i, node) {
                                // if (i === 0 || i === node.table.body.length) {
                                return null;
                                //}
                            },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            paddingLeft: function (i, node) {
                                return 10;
                            },
                            paddingRight: function (i, node) {
                                return 10;
                            },
                            paddingTop: function (i, node) {
                                return 2;
                            },
                            paddingBottom: function (i, node) {
                                return 2;
                            },
                            fillColor: function (rowIndex, node, columnIndex) {
                                return '#fff';
                            },
                        },
                        table: {
                            headerRows: 1,
                            widths: [83,40,30,40,20,60,60,40],
                            body: currentInventory
                        },
                    },
                    {margin: [ 19, 14, 0, 0 ],
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function (i, node) {
                                return 1;
                            },
                            vLineWidth: function (i, node) {
                                return 1;
                            },
                            hLineColor: function (i, node) {
                                if (i === 1 || i === 0) {
                                    return '#bfdde8';
                                }
                                return '#eaeaea';
                            },
                            vLineColor: function (i, node) {
                                return '#eaeaea';
                            },
                            hLineStyle: function (i, node) {
                                // if (i === 0 || i === node.table.body.length) {
                                return null;
                                //}
                            },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            paddingLeft: function (i, node) {
                                return 10;
                            },
                            paddingRight: function (i, node) {
                                return 10;
                            },
                            paddingTop: function (i, node) {
                                return 2;
                            },
                            paddingBottom: function (i, node) {
                                return 0;
                            },
                            fillColor: function (rowIndex, node, columnIndex) {
                                return '#333';
                            },
                        },
                        table: {
                            headerRows: 1,
                            widths: [520],
                            heights: ['*', 100],
                            alignment: 'center',
                            body: [
                                [
                                    {
                                        text: 'Patient Orders',
                                        fillColor: '#eeeeee',
                                        fontSize: 10,
                                        alignment: 'left',
                                        border: [false, false, false, false],
                                        margin: [228, 0, 0, 0],
                                        textTransform: 'uppercase',
                                    },

                                ]
                            ]
                        },

                    },
                    {margin: [ 19, 0, 0, 0 ],
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function (i, node) {
                                return 1;
                            },
                            vLineWidth: function (i, node) {
                                return 1;
                            },
                            hLineColor: function (i, node) {
                                if (i === 1 || i === 0) {
                                    return '#F2F2F2';
                                }
                                return '#eaeaea';
                            },
                            vLineColor: function (i, node) {
                                return '#eaeaea';
                            },
                            hLineStyle: function (i, node) {
                                // if (i === 0 || i === node.table.body.length) {
                                return null;
                                //}
                            },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            paddingLeft: function (i, node) {
                                return 10;
                            },
                            paddingRight: function (i, node) {
                                return 10;
                            },
                            paddingTop: function (i, node) {
                                return 2;
                            },
                            paddingBottom: function (i, node) {
                                return 2;
                            },
                            fillColor: function (rowIndex, node, columnIndex) {
                                return '#fff';
                            },
                        },
                        table: {
                            headerRows: 1,
                            widths: [80,80,80,80,116],
                            body: patientOrdersList
                        },
                    },
                    {margin: [ 19, 14, 0, 0 ],
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function (i, node) {
                                return 1;
                            },
                            vLineWidth: function (i, node) {
                                return 1;
                            },
                            hLineColor: function (i, node) {
                                if (i === 1 || i === 0) {
                                    return '#bfdde8';
                                }
                                return '#eaeaea';
                            },
                            vLineColor: function (i, node) {
                                return '#eaeaea';
                            },
                            hLineStyle: function (i, node) {
                                // if (i === 0 || i === node.table.body.length) {
                                return null;
                                //}
                            },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            paddingLeft: function (i, node) {
                                return 10;
                            },
                            paddingRight: function (i, node) {
                                return 10;
                            },
                            paddingTop: function (i, node) {
                                return 2;
                            },
                            paddingBottom: function (i, node) {
                                return 0;
                            },
                            fillColor: function (rowIndex, node, columnIndex) {
                                return '#333';
                            },
                        },
                        table: {
                            headerRows: 1,
                            widths: [520],
                            heights: ['*', 100],
                            alignment: 'center',
                            body: [
                                [
                                    {
                                        text: 'Transactional History',
                                        fillColor: '#eeeeee',
                                        fontSize: 10,
                                        alignment: 'left',
                                        border: [false, false, false, false],
                                        margin: [228, 0, 0, 0],
                                        textTransform: 'uppercase',
                                    },

                                ]
                            ]
                        },

                    },
                    {margin: [ 19, 0, 0, 0 ],
                        layout: {
                            defaultBorder: false,
                            hLineWidth: function (i, node) {
                                return 1;
                            },
                            vLineWidth: function (i, node) {
                                return 1;
                            },
                            hLineColor: function (i, node) {
                                if (i === 1 || i === 0) {
                                    return '#F2F2F2';
                                }
                                return '#eaeaea';
                            },
                            vLineColor: function (i, node) {
                                return '#eaeaea';
                            },
                            hLineStyle: function (i, node) {
                                // if (i === 0 || i === node.table.body.length) {
                                return null;
                                //}
                            },
                            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
                            paddingLeft: function (i, node) {
                                return 10;
                            },
                            paddingRight: function (i, node) {
                                return 10;
                            },
                            paddingTop: function (i, node) {
                                return 2;
                            },
                            paddingBottom: function (i, node) {
                                return 2;
                            },
                            fillColor: function (rowIndex, node, columnIndex) {
                                return '#fff';
                            },
                        },
                        table: {
                            headerRows: 1,
                            widths: [83,40,30,40,20,65,65,30],
                            body: transactionalHistory
                        },
                    },
                ],
                pageMargins: [ 7, 7, 7, 7 ],
                pageSize: 'A4',
            };
            pdfMake.createPdf(docDefinition).open()
            // .download("Score_Details.pdf");

        })
}
