import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {getTokenInformation} from "../../../common/utils/utils";
import {endpoints} from "../../../constants/endpoints";
import Button from "@material-ui/core/Button";
import {Backdrop} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import {utilsTokenIdentifiers} from "../../../constants/inputs/values";
import {useHistory} from "react-router-dom";
import ExportForm from "../../../common/exportForm/exportForm";
import {Table} from "../../../common/table/table";
import moment from "moment";

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100,
        textAlign: 'left'
    })
};

export const DiseasesReport = () => {

    const history = useHistory();
    const [data, setData] = useState([]);
    const [list, setList] = useState([]);
    const sortedList = list.sort((a, b) => a.label.localeCompare(b.label));
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [exportValues, setExportValues] = useState([]);
    useEffect(() => {
        getContractedHospiceOrganizations()
    }, [])


    const getContractedHospiceOrganizations = async () => {
        const {dme_organization_id} = getTokenInformation(history, utilsTokenIdentifiers.payload);
        const promise = await fetch(endpoints.getContractedHospicesByDmeIds, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            },
            method: 'POST',
            body: JSON.stringify({
                dmeIds: [dme_organization_id]
            })
        });
        const response = await promise.json();
        if (response.status === "success") {
            let arr = [];
            response.data[0].hospices.map(org => {
                arr.push({
                    label: org.hospice_name,
                    value: org.hospice_id
                })
            })
            setList(arr);

        }
        setLoading(false)
    }

    const getData = async () => {
        const promise = await fetch(endpoints.diseaseReports + selectedOrg.value, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            },
        })
        const response = await promise.json();
        if (response.status === "success") {
            setData(response.data);
        }
    }
    const clearData = () => {
        setData([]);
        setSelectedOrg(null);
    }

    const headers = [
        {text: "Patient Id", style: 'tableHeader'},
        {text: "First Name", style: 'tableHeader'},
        {text: "Last Name", style: 'tableHeader'},
        {text: "Medical Record", style: 'tableHeader'},
        {text: "Address", style: 'tableHeader'},
        {text: "Diseases", style: 'tableHeader'},
    ];
    const excelHeaders = [
        "Patient Id",
        "First Name",
        "Last Name",
        "Medical Record",
        "Address",
        "Diseases",
    ];

    useEffect(() => {
        if (data !== undefined) {
            let final = [];
            data.map(value => {
                let newArr = [];
                newArr.push(value.patient_id);
                newArr.push(value.patient_first_name);
                newArr.push(value.patient_last_name);
                newArr.push(value.patient_medical_record);
                newArr.push(value.address1 + " " + value.address2 + "," + value.city + " " +  value.state + " " +  value.zip);
                let str = "";
                value.diseases.map(item => str = str + " " + item + ",");
                newArr.push(str);
                final.push(newArr)
            });
            setExportValues(final);
        }
    }, [data]);

    const [loading, setLoading] = useState(false);
    return <Grid container style={{padding: 32}}>
        <Grid container style={{backgroundColor: 'white', borderRadius: 4}}>
            <Backdrop open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Grid container
                  style={{textAlign: 'left', fontWeight: 'bold', fontSize: 24, paddingTop: 24, paddingLeft: 24}}>
                Disease Report
            </Grid>
            <Grid item md={2} sm={4} xs={12} style={{padding: 16}}>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable
                    isSearchable
                    value={selectedOrg}
                    name="color"
                    options={sortedList}
                    styles={selectStyles}
                    onChange={(e) => setSelectedOrg(e)}
                />
            </Grid>
            <Grid item style={{paddingTop: 16, paddingLeft: 8}}>
                <Button variant={'contained'} color={'primary'} onClick={getData}
                        disabled={!selectedOrg}>Generate Report</Button>
            </Grid>
            <Grid item style={{paddingTop: 16, paddingLeft: 8}}>
                <Button variant={'outlined'} color={'primary'} onClick={clearData}>Clear Filters</Button>
            </Grid>
        </Grid>
        {data.length > 0 &&
            <Grid item container xs={12}>
                <Grid item xs={12} style={{display:"flex", justifyContent: "flex-end"}}>
                    <ExportForm
                        fileName='Diseases Report'
                        exportValues={exportValues}
                        headers={headers}
                        excelHeaders={excelHeaders}
                        showPrint={true}
                        landscape={true}
                    />
                </Grid>
                <Grid item xs={12} style={{overflow: 'hidden', maxWidth: 'calc(100vw - 24px)'}}>
                    <Table
                        showSearch={true}
                        search={true}
                        filtering={true}
                        columns={[
                            {title: 'Patient ID', field: 'patient_id'},
                            {title: 'First Name', field: 'patient_first_name'},
                            {title: 'Last Name', field: 'patient_last_name'},
                            {title: 'Medical Record', field: 'patient_medical_record'},
                            {
                                title: 'Patient Address', field: 'address',
                                render: rowData =>
                                    <div>{rowData.address1 + " " + rowData.address2 + "," + rowData.city + " " + rowData.state + " " + rowData.zip}</div>
                            },
                            {
                                title: 'Diseases', field: 'diseases',
                                render: rowData => {
                                    let str = "";
                                    rowData.diseases.map(item => str = str + " " + item + ",");
                                    return <div>{str}</div>
                                }
                            },
                        ]}
                        items={data}
                        backGroundColor={"rgba(237, 242, 244, 0.73)"}
                        textColor={"rgba(46, 50, 54, 1)"}
                    />
                </Grid>
            </Grid>
        }
    </Grid>

}