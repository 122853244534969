import React from 'react'
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import Typography from "@material-ui/core/Typography";

export const ConfirmPerformChanges = ({
                                              open = false,
                                              alertTitle = "Change Organization Status",
                                              alertContent = "Are you sure you want to change the organization status?",
                                              handleCancel,
                                              handleConfirm,
                                              cancelActionButtonTitle = buttonText.camelCaseCancel,
                                              confirmActionButtonTitle = buttonText.proceed
                                          }) => {
    return <Dialog open={open}>
        <DialogTitle>
            {alertTitle}
        </DialogTitle>
        <DialogContent>
            <Typography variant={"h6"}>
                {alertContent}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button autoFocus
                    variant={"outlined"}
                    onClick={() => {handleCancel && handleCancel();
            }}>
                {cancelActionButtonTitle}
            </Button>
            <Button
                variant={"outlined"}
                onClick={() => {handleConfirm && handleConfirm();
            }}>
                {confirmActionButtonTitle}
            </Button>
        </DialogActions>
    </Dialog>
}