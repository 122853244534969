import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import {AddCircleOutline, AddOutlined, Clear, Search} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {Backdrop, CircularProgress, Dialog, Radio, TextField, Tooltip} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {placeholders} from "../../constants/inputs/paceholders";
import Select from "react-select";
import InputAdornment from "@material-ui/core/InputAdornment";
import {checkTokenAvailability, getTokenInformation} from "../utils/utils";
import {logout} from "../../redux/actions";
import {endpoints} from "../../constants/endpoints";
import {useDispatch} from "react-redux";
import jwt from "jwt-decode";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {notifySnack} from "../notifications/apiStatusNotification";
import {snackbarMessages} from "../../constants/alert/alertMessages";
import {makeStyles} from "@material-ui/core/styles";
import _ from "lodash";
import {utilsTokenIdentifiers} from "../../constants/inputs/values";

export default function AssetSelect({hasAsset=true, item, order, setOrder}){
    const [showModal, setShowModal] = useState(false);
    const [selectedAsset,setSelectedAsset] = useState(item.asset ? item.asset : null);
    const [selectedInventoryId,setSelectedInventoryId] = useState(item.inventory_item_id ? item.inventory_item_id : null);
    const [showForm, setShowForm] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [selectedWarehouse, setSelectedWarehouse] = useState([]);
    const [newAsset, setNewAsset] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [warehouseList, setWarehouseList] = useState([]);
    const [assets, setAssets]= useState([]);

    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const classes = useStyles();

    let currentUser = localStorage.getItem('key');
    currentUser = JSON.parse(currentUser);
    currentUser = currentUser ? jwt(currentUser.accessToken) : null;


    const getAvailableWarehouses = () => {
        setLoading(true);
        if (currentUser.payload.type === "DME"){
            const token = JSON.parse(localStorage.getItem('key'));
            fetch(`${endpoints.getUserWarehouses}${currentUser.payload.user_id}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let x =[];
                    response.data.map(warehouse =>  x.push({label:warehouse.name, value:warehouse.warehouse_id}));
                    if (response.data.length ===1){
                        setSelectedWarehouse([{label:response.data[0].name, value:response.data[0].warehouse_id}])
                    }
                    setWarehouseList(x)
                    getAvailableAssets(x);
                })
                .catch(err => {
                    console.log(err, 'error');
                    enqueueSnackbar("There are no warehouses associated with this account.", {
                        persist: false,
                        variant: "warning",
                    });
                })
        }
    };


    const getAvailableAssets = (warehouse_ids) => {
        setLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.availableAssets, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                dme_organization_id: order.dme_organization_id,
                item_name: item.name,
                special_item_id: item.special_item_id,
                warehouse_ids: warehouse_ids,
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.status === 'success') {
                    if (item.asset){
                        setAssets([item,...response.data])
                    } else {
                        setAssets(response.data)
                    }
                }
                setLoading(false)
            })
            .catch(err => console.log(err,'error'))
    };


    const addToInventory = () => {
        setLoadingSave(true)
        const token = JSON.parse(localStorage.getItem('key'));
        let new_invetory_item_id;
        fetch(endpoints.addInventory, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body:JSON.stringify({items:[{
                    warehouse_id: selectedWarehouse[0].value,
                    warehouse_name: selectedWarehouse[0].name,
                    dme_organization_id:Number(order.dme_organization_id),
                    status:true,
                    item_status: 'Available',
                    old_inventory_id : item.inventory_item_id,
                    asset: newAsset && newAsset.trim(),
                    item_name: item.name
                }]})
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                new_invetory_item_id = response.data;
                if (response.status === 'success'){
                    fetch(endpoints.addInventoryItemsToOrder, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token.accessToken
                        },
                        body:JSON.stringify({inventory_item_id:response.data, special_item_id: item.special_item_id, old_inventory_item_id: item.inventory_item_id})
                    })
                        .then(response => {
                            setLoadingSave(false);
                            setShowModal(false);
                            setSelectedWarehouse([])
                            let index;
                            if(order.items){
                               index = order.items.findIndex(i => i.special_item_id === item.special_item_id)
                               let orderClone = _.cloneDeep(order);
                               orderClone.items[index].asset = newAsset;
                               setOrder(orderClone)
                            } else {
                                if(order.pickupItems.findIndex(i => i.special_item_id === item.special_item_id) > -1){
                                    index = order.pickupItems.findIndex(i => i.special_item_id === item.special_item_id)
                                    let orderClone = _.cloneDeep(order);
                                    orderClone.pickupItems[index].asset = newAsset;
                                    setOrder(orderClone)
                                } else {
                                    index = order.deliveredItems.findIndex(i => i.special_item_id === item.special_item_id)
                                    let orderClone = _.cloneDeep(order);
                                    orderClone.deliveredItems[index].asset = newAsset;
                                    setOrder(orderClone)
                                }
                            }
                            notifySnack(enqueueSnackbar, response.status, snackbarMessages.orderItemAssetAdded, true);
                            return response.json();
                        })
                }
                history.push(`/refresh`)
            })
            .catch(err => {
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
        return new_invetory_item_id;
    };
    // TODO: need to check if needed
    // const getContractedProvidersByHospiceId = async (hospiceId, enqueueSnackbar) => {
    //     const token = JSON.parse(localStorage.getItem('key'));
    //     const promise = await fetch(`${endpoints.getHospiceContractedProviders}${hospiceId}`, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + token.accessToken
    //         }
    //     })
    //     const response = await promise.json();
    //     let object = [];
    //     if(response.status === "success") {
    //         for (let i = 0; i < response.data.length; i++) {
    //             object.push({value: response.data[i].dme_id, label: response.data[i].dme_name})
    //         }
    //     }
    //     if(promise.status !== 200){
    //         enqueueSnackbar("Something happened. Contact support.", {
    //             persist: false,
    //             variant: "error",
    //         });
    //     }
    //     return object;
    // }

    function updateAsset(){
        setLoadingSave(true)
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.updateItemsAssetToOrder, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body:JSON.stringify({
                new_inventory_item_id: selectedInventoryId,
                old_inventory_item_id: item.inventory_item_id,
                special_item_id: item.special_item_id
            })
        })
            .then(response => {
                notifySnack(enqueueSnackbar, response.status, snackbarMessages.orderItemAssetAdded, true);
                return response.json();
            })
            .then(response => {
                setLoadingSave(false)
                if (response.status === 'success'){
                    // let index = order.items.findIndex(i => i.special_item_id === item.special_item_id)
                    // let orderClone = _.cloneDeep(order);
                    // orderClone.items[index].asset = selectedAsset;
                    // setOrder(orderClone)
                    // setSelectedInventoryId(null);
                    let index;
                    if(order.items){
                        index = order.items.findIndex(i => i.special_item_id === item.special_item_id)
                        let orderClone = _.cloneDeep(order);
                        orderClone.items[index].asset = selectedAsset;
                        setOrder(orderClone)
                    } else {
                        if(order.pickupItems.findIndex(i => i.special_item_id === item.special_item_id) > -1){
                            index = order.pickupItems.findIndex(i => i.special_item_id === item.special_item_id)
                            let orderClone = _.cloneDeep(order);
                            orderClone.pickupItems[index].asset = selectedAsset;
                            setOrder(orderClone)
                        } else {
                            index = order.deliveredItems.findIndex(i => i.special_item_id === item.special_item_id)
                            let orderClone = _.cloneDeep(order);
                            orderClone.deliveredItems[index].asset = selectedAsset;
                            setOrder(orderClone)
                        }
                    }
                }
                history.push(`/refresh`)

        setShowModal(false)
            })
            .catch(err => {
                    console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    }

    const handleReleaseAsset = (item) => {
        setLoading(true);
        fetch(endpoints.updateItemsAssetToOrder, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            },
            body:JSON.stringify({
                new_inventory_item_id: null,
                old_inventory_item_id: item.inventory_item_id,
                special_item_id: item.special_item_id
            })
        })
            .then(response => {
                notifySnack(enqueueSnackbar, response.status, snackbarMessages.orderItemReleased, true);
            })
        setLoading(false);
        history.push('/refresh')
    }

    useEffect(() => {
        if (assets.length === 0 && showModal) {
            Number(order.dme_organization_id) === Number(currentUser.payload.dme_organization_id) && getAvailableWarehouses();
        }
    }, [showModal]);
    // TODO: CHECK case with null item quantity
    return <React.Fragment>
        <div className={classes.wrapper}>
            {Number(item.quantity) <= 1  && <Tooltip title={item.asset}>
                <span className={classes.textOverflow}>{item.asset ? item.asset : 'No asset assigned'}</span>
            </Tooltip>}
            {Number(item.quantity) > 1 && item.asset && <Tooltip title={"Assets cannot be added to items with quantity."}>
                <Button color={"primary"} variant={"outlined"} onClick={() => handleReleaseAsset(item)}>
                    Release Asset
                </Button>
            </Tooltip>}
            {Number(item.quantity) <= 1 && order.status !== 'Canceled' && <IconButton
                onClick={() => setShowModal(true)}
            >
                {hasAsset ? (
                    <Tooltip title={'Edit Asset'}>
                        <EditIcon color={'primary'}/>
                    </Tooltip>
                ) : (
                    <Tooltip title={'Assign Asset'}>
                        <AddCircleOutline color={'primary'}/>
                    </Tooltip>
                )}
            </IconButton>}
        </div>
        <Backdrop className={classes.backdrop} open={loadingSave}>
            <CircularProgress color="inherit"/>
        </Backdrop>
        <Dialog open={showModal} onClose={()=> setShowModal(false)}>
            <Grid container className={classes.padding}>
                <div className={classes.title}>{hasAsset ? 'Edit Asset' : 'Add Asset'} for {item.name}</div>
                <Grid container justify={'space-between'} spacing={1}>
                    <Grid item xs={6}>
                        <Button variant={'contained'} color={'primary'} startIcon={<AddOutlined />}
                                onClick={()=> {
                                    setNewAsset(searchText);
                                    setShowForm(!showForm);
                                }}
                        >
                            Add New Asset
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            placeholder={'Search asset'}
                            variant={'outlined'}
                            size={'small'}
                            value={searchText}
                            onChange={(e)=> setSearchText(e.target.value)}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start" className={classes.p0}>
                                        <Search className={classes.gray} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton size={'small'} onClick={()=> setSearchText('')}>
                                            <Clear className={classes.gray} />

                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                {showForm && (
                    <Grid container className={classes.pt} alignItems={'flex-end'} alignContent={'flex-end'} spacing={1}>
                        <Grid item sm={4} xs={6}>
                            <TextField
                                placeholder={'Add asset'}
                                variant={'outlined'}
                                size={'small'}
                                label={'Asset'}
                                fullWidth
                                value={newAsset}
                                onChange={(e)=> setNewAsset(e.target.value)}
                            />
                        </Grid>
                        <Grid item sm={5} xs={6}>
                            <Select
                                fullWidth
                                autoWidth
                                placeholder={placeholders.selectWarehouse}
                                onChange={(e) => setSelectedWarehouse([e])}
                                options={warehouseList}
                                value={selectedWarehouse}
                            />
                        </Grid>
                        <Grid item sm={3} xs={12}>
                            <Button fullWidth variant={'contained'} color={'primary'}
                                    disabled={newAsset.length === 0 || selectedWarehouse.length === 0 || assets.filter(asset => asset.asset === newAsset).length !== 0}
                            onClick={()=> {
                                setShowForm(false);
                                setSelectedAsset(newAsset);
                                let new_inventory_item_id = addToInventory();
                                setSelectedInventoryId(new_inventory_item_id);
                                setAssets([{
                                    asset: newAsset,
                                    inventory_item_id: selectedInventoryId,
                                    new_inventory_item_id: new_inventory_item_id,
                                    name: item.name,
                                    warehouse_name: selectedWarehouse[0].label
                                }, ...assets]);
                            }}
                            >Add Item</Button>
                        </Grid>
                    </Grid>
                )}
                    <div className={classes.subtitle}>Available Assets: {assets.length}</div>
                    <Grid container className={classes.container}>
                        {loading ? <Grid container  className={classes.minH} direction={'column'} justify={'center'} alignItems={'center'} alignContent={'center'}>
                            <CircularProgress color={'primary'} size={30}/>
                            <div className={classes.loading}>Loading...</div>
                        </Grid> : (
                            <React.Fragment>
                                {/*TODO: Create a message function for cleaner code*/}
                                {Number(order.dme_organization_id) !== Number(currentUser.payload.dme_organization_id) ? <div className={classes.info}>No access to these DME inventory</div> : assets.length === 0 && <div className={classes.info}>No assets available</div>}
                                {assets.length > 0 && assets.filter(e => e?.asset?.includes(searchText)).length === 0 && <div className={classes.results}>
                                    No available assets found
                                </div>}
                                {assets.length > 0 && assets.filter(e => e?.asset?.includes(searchText)).map((asset, i)=> {
                                        let selected = selectedAsset === asset.asset; // TODO: Change logic to work on inventory_asset_id due to asset name uniqueness issues
                                        return (
                                            <Grid container alignContent={'center'} alignItems={'center'} className={classes.row} key={i}
                                                  style={{
                                                      backgroundColor: selected ? 'rgba(235,246,255,0.70)' : '',
                                                      border: selected ? '1px solid #00223d' : '1px solid white',
                                                  }}
                                                  onClick={()=> {
                                                      setSelectedAsset(asset.asset);
                                                      setSelectedInventoryId(asset.inventory_item_id);
                                                  }}
                                            >
                                                <Grid item xs={1}>
                                                    <Radio
                                                        checked={selected}
                                                        value="a"
                                                        name="radio-button-demo"
                                                        inputProps={{ 'aria-label': 'A' }}
                                                        color={'primary'}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {asset.asset}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {asset.warehouse_name}
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                )}
                            </React.Fragment>
                        )}
                </Grid>

                <Grid container justify={'flex-end'} style={{paddingTop:8}}>
                    <Button variant={'outlined'} color={'secondary'} style={{marginRight:8}} onClick={()=> {
                        setShowModal(false);
                        setShowForm(false);
                        setSearchText('');
                        setNewAsset("");
                        setAssets([]);
                        setSelectedWarehouse([]);
                        setSelectedInventoryId(null);
                    }}>Cancel</Button>
                    <Button variant={'contained'} color={'primary'} disabled={(showForm) || loadingSave || !selectedAsset} onClick={updateAsset}>Save</Button>
                </Grid>

            </Grid>

        </Dialog>
    </React.Fragment>
}


const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: '#fff',
    },
    wrapper: {
        maxWidth:200,
        display:'flex',
        alignContent:'center',
        alignItems:'center'
    },
    textOverflow: {
        whiteSpace: 'nowrap',
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    padding: {
        padding:'8px 16px'
    },
    title: {
        fontSize:24,
        fontWeight:'bold',
        paddingBottom:16,
        paddingTop:8
    },
    p0: {
        padding:0
    },
    gray: {
        color:'gray'
    },
    pt: {
        paddingTop:16,
        paddingBottom:8
    },
    subtitle: {
        paddingTop:16,
        fontWeight:'bold'
    },
    container: {
        maxHeight:360,
        overflow:'scroll',
        padding:4,
        border:'1px solid whitesmoke',
        borderRadius:4,
        marginTop:4
    },
    minH: {
        minHeight:200
    },
    loading: {
        paddingTop:8,
        color:'grey'
    },
    info: {
        padding:32,
        textAlign:'center',
        width:'100%'
    },
    results: {
        padding:16,
        textAlign:'center',
        width:'100%'
    },
    row: {
        maxHeight:'100%',
        borderRadius:4,
        cursor: "pointer"
    }

}));
