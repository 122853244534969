import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MaterialTable from "material-table";
import {reportTypes} from "../../../../constants/types/reportTypes";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import moment from "moment";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {BRDisplayQuarterTable} from "./BRDisplayTable";
import {BRDisplayYearTable} from "./BRDisplayYearTable";
import {BRDisplayOutlierTable} from "./BROutlierTable";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer, Cell
} from "recharts";
import {BRDisplayLostItemsTable} from "./BRLostItemsTable";
import CircularProgress from "@material-ui/core/CircularProgress";
import {graphBarColors, graphColorSwitch} from "../BusinessReviewHelper";
import {sectionTitles} from "../../../../constants/names/titles";
import {BRBarGraphAccordion} from "./BRBarGraphAccordion";
import {placeholders} from "../../../../constants/inputs/paceholders";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import {exportPDF} from "../../../reports/businessReview/components/printBusinessReview";

const selectStyles = {
    menu: base => ({
        ...base,
        textAlign: 'left'
    })
};

export const BRDmeView = ({
                              selectedHospice,
                              handleInput,
                              dmeReference,
                              hospiceOptions = [],
                              reportType,
                              startDate = new Date(),
                              tableData = {},
                              getReport,
                              isLoading,
                              hospiceLogo
                          }) => {

    const sortedHospiceOptions = hospiceOptions.map((item) => {
        return {value: item.hospice_organization_id || item.hospice_id, label: item.hospice_name}
    }).sort((a, b) => a.label.localeCompare(b.label));

    return (
        <Grid container spacing={1} style={{padding: 16}}>
            <Grid item xs={12} container justify={"center"} alignContent={"center"} alignItems={"center"} spacing={1}>
                <Grid item xs={12} sm={10} md={8} lg={2}>
                    <Select
                        ref={dmeReference}
                        placeholder="Select Hospice"
                        isLoading={hospiceOptions.length === 0}
                        isDisabled={isLoading}
                        options={sortedHospiceOptions}
                        onChange={(e) => {
                            handleInput({
                                ...e,
                                name: "selectHospice",
                            })
                        }}
                        styles={selectStyles}
                    />
                </Grid>
                <Grid item xs={12} sm={10} md={8} lg={2}>
                    <Select
                        placeholder="Select Report Type"
                        options={reportTypes.all.map(item => {
                            return {value: item, label: item}
                        })}
                        isDisabled={isLoading}
                        onChange={(e) => {
                            handleInput({
                                ...e,
                                name: "reportType",
                            })
                        }}
                        value={{value: reportType, label: reportType}}
                        styles={selectStyles}
                    />
                </Grid>
                <Grid item xs={12} sm={10} md={8} lg={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            openTo="year"
                            views={["year", "month"]}
                            label="Start Month"
                            value={startDate}
                            disabled={isLoading}
                            helperText={reportType === reportTypes.quarterly ? placeholders.quarterStartMonth : placeholders.fiscalYearStart}
                            onChange={(e) => {
                                handleInput({
                                    value: e,
                                    name: "startDate",
                                })
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                {reportType === reportTypes.quarterly && <Grid item xs={12} sm={10} md={8} lg={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            openTo="year"
                            views={["year", "month"]}
                            label="End Month"
                            value={moment(startDate).add(2, "month")}
                            disabled
                            helperText={reportType === reportTypes.quarterly ? placeholders.quarterEndMonth : placeholders.fiscalYearEnd}
                            onChange={(e) => {
                                handleInput({
                                    value: e,
                                    name: "startDate",
                                })
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>}
                <Grid item xs={12} sm={10} md={8} lg={2}>
                    <Button variant={"contained"}
                            color={"primary"}
                            disabled={isLoading || selectedHospice === -1}
                            onClick={(e) => getReport()}
                    >
                        Generate Report {isLoading === true && <CircularProgress style={{color: 'white'}} size={24}/>}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color={"primary"}
                        variant="contained"
                        startIcon={<PrintOutlinedIcon/>}
                        onClick={() => {
                            exportPDF(hospiceLogo, reportType, tableData)
                        }}
                    >
                        Print Report
                    </Button>
                </Grid>
            </Grid>
            <Grid item container xs={12} spacing={1}>
                {tableData?.patientDayComponent?.q1 && <Grid item xs={12}><Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrQuarter1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayQuarterTable values={tableData.patientDayComponent.q1}/>
                    </AccordionDetails>
                </Accordion>
                </Grid>
                }
                {reportType === reportTypes.yearly && tableData?.patientDayComponent?.q2 &&
                <Grid item xs={12}><Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrQuarter2}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayQuarterTable values={tableData.patientDayComponent.q2}/>
                    </AccordionDetails>
                </Accordion>
                </Grid>}
                {reportType === reportTypes.yearly && tableData?.patientDayComponent?.q3 &&
                <Grid item xs={12}><Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrQuarter3}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayQuarterTable values={tableData.patientDayComponent.q3}/>
                    </AccordionDetails>
                </Accordion>
                </Grid>}
                {reportType === reportTypes.yearly && tableData?.patientDayComponent?.q4 &&
                <Grid item xs={12}><Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrQuarter4}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayQuarterTable values={tableData.patientDayComponent.q4}/>
                    </AccordionDetails>
                </Accordion>
                </Grid>}
            </Grid>
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.patientDayComponent && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrYear}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayYearTable values={tableData.patientDayComponent}/>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            <Grid item xs={12}>
                {tableData?.outlierSpendComponent?.q1 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrOutlierQ1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayOutlierTable values={tableData.outlierSpendComponent.q1}/>
                    </AccordionDetails>
                </Accordion>}
            </Grid>
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.outlierSpendComponent?.q2 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrOutlierQ2}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayOutlierTable values={tableData.outlierSpendComponent.q2}/>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.outlierSpendComponent?.q3 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrOutlierQ3}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayOutlierTable values={tableData.outlierSpendComponent.q3}/>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.outlierSpendComponent?.q4 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrOutlierQ4}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <BRDisplayOutlierTable values={tableData.outlierSpendComponent.q4}/>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            <Grid item xs={12}>
                {tableData?.topTenItemsComponent?.q1 && <BRBarGraphAccordion
                    accordionTitle={sectionTitles.qbrTop10ItemsQ1}
                    graphData={tableData?.topTenItemsComponent?.q1}
                    graphHighlightColor={graphBarColors.topOofItem}
                    graphDefaultColor={graphBarColors.default}
                />}
            </Grid>
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.topTenItemsComponent?.q2 && <BRBarGraphAccordion
                    accordionTitle={sectionTitles.qbrTop10ItemsQ2}
                    graphData={tableData?.topTenItemsComponent?.q2}
                    graphHighlightColor={graphBarColors.topOofItem}
                    graphDefaultColor={graphBarColors.default}
                />}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.topTenItemsComponent?.q3 && <BRBarGraphAccordion
                    accordionTitle={sectionTitles.qbrTop10ItemsQ3}
                    graphData={tableData?.topTenItemsComponent?.q3}
                    graphHighlightColor={graphBarColors.topOofItem}
                    graphDefaultColor={graphBarColors.default}
                />}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.topTenItemsComponent?.q4 && <BRBarGraphAccordion
                    accordionTitle={sectionTitles.qbrTop10ItemsQ4}
                    graphData={tableData?.topTenItemsComponent?.q4}
                    graphHighlightColor={graphBarColors.topOofItem}
                    graphDefaultColor={graphBarColors.default}
                />}
            </Grid>}
            <Grid item xs={12}>
                {tableData?.lowTenItemsComponent?.q1 && <BRBarGraphAccordion
                    accordionTitle={sectionTitles.qbrLowest10ItemsQ1}
                    graphData={tableData?.lowTenItemsComponent?.q1}
                    graphHighlightColor={graphBarColors.lowOofItem}
                    graphDefaultColor={graphBarColors.default}
                />}
            </Grid>
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.lowTenItemsComponent?.q2 && <BRBarGraphAccordion
                    accordionTitle={sectionTitles.qbrLowest10ItemsQ2}
                    graphData={tableData?.lowTenItemsComponent?.q2}
                    graphHighlightColor={graphBarColors.lowOofItem}
                    graphDefaultColor={graphBarColors.default}
                />}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.lowTenItemsComponent?.q3 && <BRBarGraphAccordion
                    accordionTitle={sectionTitles.qbrLowest10ItemsQ3}
                    graphData={tableData?.lowTenItemsComponent?.q3}
                    graphHighlightColor={graphBarColors.lowOofItem}
                    graphDefaultColor={graphBarColors.default}
                />}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.lowTenItemsComponent?.q4 && <BRBarGraphAccordion
                    accordionTitle={sectionTitles.qbrLowest10ItemsQ4}
                    graphData={tableData?.lowTenItemsComponent?.q4}
                    graphHighlightColor={graphBarColors.lowOofItem}
                    graphDefaultColor={graphBarColors.default}
                />}
            </Grid>}
            <Grid item xs={12}>
                {tableData?.lostItemsComponent?.q1 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrLostItemsQ1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResponsiveContainer width={"100%"} height={500}>
                            <BRDisplayLostItemsTable values={tableData.lostItemsComponent.q1}/>
                        </ResponsiveContainer>
                    </AccordionDetails>
                </Accordion>}
            </Grid>
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.lostItemsComponent?.q2 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrLostItemsQ2}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResponsiveContainer width={"100%"} height={500}>
                            <BRDisplayLostItemsTable values={tableData.lostItemsComponent.q2}/>
                        </ResponsiveContainer>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.lostItemsComponent?.q3 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrLostItemsQ3}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResponsiveContainer width={"100%"} height={500}>
                            <BRDisplayLostItemsTable values={tableData.lostItemsComponent.q3}/>
                        </ResponsiveContainer>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
            {reportType === reportTypes.yearly && <Grid item xs={12}>
                {tableData?.lostItemsComponent?.q4 && <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>{sectionTitles.qbrLostItemsQ4}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ResponsiveContainer width={"100%"} height={500}>
                            <BRDisplayLostItemsTable values={tableData.lostItemsComponent.q4}/>
                        </ResponsiveContainer>
                    </AccordionDetails>
                </Accordion>}
            </Grid>}
        </Grid>
    )
}