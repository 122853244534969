import moment from "moment";

export const calculateTime = (startDate, endDate) => {
    let value = '';
    let unitOfMeasure;
    let startTime = moment(startDate);
    let endTime = moment(endDate);
    if (startTime.diff(endTime, 'seconds') < 60) {
        value = startTime.diff(endTime, 'seconds');
        unitOfMeasure = moment().diff(endTime, 'seconds') === 1 ? 'second' : 'seconds';
    } else if (startTime.diff(endTime, 'minutes') < 60) {
        value = startTime.diff(endTime, 'minutes');
        unitOfMeasure = startTime.diff(endTime, 'minutes') === 1 ? 'minute' : 'minutes';
    } else if (startTime.diff(endTime, 'hours') < 24) {
        value = startTime.diff(endTime, 'hours');
        unitOfMeasure = startTime.diff(endTime, 'hours') === 1 ? 'hour' : 'hours';
    } else {
        value = startTime.diff(endTime, 'days');
        unitOfMeasure = startTime.diff(endTime, 'days') === 1 ? 'day' : 'days';
    }
    return `${value} ${unitOfMeasure}`
}