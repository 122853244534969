import React, {useEffect, useState} from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useDispatch, useSelector} from "react-redux";
import {ActiveRentalStyle} from "./ActiveRental.style";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {endpoints} from "../../../constants/endpoints";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ReportsHeader from "../../../common/reportsHeader/reportsHeader";
import ExportForm from "../../../common/exportForm/exportForm";
import {Table} from "../../../common/table/table";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";

export const ActiveRentalSummary = ({}) => {
    let mobile = useMediaQuery('(max-width:960px)');
    const isLogged = useSelector(state => state.isLogged);
    const classes = ActiveRentalStyle();
    const token = JSON.parse(localStorage.getItem('key'));
    const [results, setResults] = useState([]);
    const [exportValues, setExportValues] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    const generateReport = (payload) => {
        setIsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        fetch(endpoints.getActiveRental, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                ...payload,
                report_type: "FullActiveSummary",
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setResults(response.data);
                setIsLoading(false);
            })
            .catch(err => {
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
                setIsLoading(false);
            })
    };

    const clearData = () => {
        setResults([]);
    };
    useEffect(() => {
        if (results !== undefined) {
            let final = [];
            let totalItems = 0;
            results.map(value => {
                let newArr = [];
                totalItems += Number(value.count);
                newArr.push(value.hospice_name);
                newArr.push(value.item_name);
                newArr.push(value.count);
                final.push(newArr)
            });
            final.push(["All", "Total", totalItems]);
            setExportValues(final);
        }
    }, [results]);
    const headers = [
        {text: "Hospice", style: 'tableHeader'},
        {text: "Item Name", style: 'tableHeader'},
        {text: "Count", style: 'tableHeader'},
    ];
    const excelHeaders = [
        "Hospice",
        "Item_Name",
        "Count"
    ];
    return (
        <div className={classes.div}>
            {(isLogged) &&
                <Grid container className={classes.container}>
                    <Grid item xs={12}>
                    <Typography variant={"h6"} className={classes.maintitle}>
                        Active Utilization Summary
                    </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ReportsHeader generateReport={generateReport} clearData={clearData}/>
                    </Grid>

                    <Grid item xs={12} container className={classes.select}>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: 20,
                            marginBottom: 12
                        }}>
                            <ExportForm
                                fileName='Active Utilization Summary'
                                exportValues={exportValues}
                                headers={headers}
                                excelHeaders={excelHeaders}
                                showPrint={true}
                                landscape={true}
                            />
                        </div>
                        <Grid container
                              style={{overflow: 'hidden', maxWidth: `calc(100vw - ${mobile ? '32px' : "80px"})`}}
                        >
                            <MaterialTable
                                components={{
                                    Container: props => <Paper {...props} elevation={0}/>,
                                    Pagination: props => <TablePagination {...props} style={{
                                        backgroundColor: "rgba(237, 242, 244, 0.73)",
                                        display: "flex"
                                    }}/>,
                                }}
                                options={{
                                    showTitle: false,
                                    emptyRowsWhenPaging: false,
                                    pageSize: 10,
                                    rowStyle: { backgroundColor: '#F9FAFC'},
                                    headerStyle: {
                                        backgroundColor: "rgba(237, 242, 244, 0.73)",
                                        color: "rgba(46, 50, 54, 1)",
                                    },
                                }}
                                style={{width: '100%'}}
                                columns={[
                                    {title: 'Hospice', field: 'hospice_name', width: 250},
                                    {title: 'Item Name', field: 'item_name', width: 200},
                                    {title: 'Count', field: 'count', width: 200},
                                ]}
                                data={results}
                            />
                        </Grid>
                    </Grid>
                    <Backdrop className={classes.backdrop} open={isLoading}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </Grid>
            }
        </div>
    )
};