import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import 'date-fns';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from "@material-ui/core/Tooltip";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {sectionTitles} from "../../../../constants/names/titles";
import {tooltips} from "../../../../constants/inputs/tooltips";
import {placeholders} from "../../../../constants/inputs/paceholders";
import {endpoints} from "../../../../constants/endpoints";
import {getMessage} from "../../../../constants/messages/messages";
import {messageTypes} from "../../../../constants/types/messageTypes";
import {CustomInputField} from "../../../../common/InputField/InputField";
import {validationTypes} from "../../../../constants/validations/validationTypes";
import {labels} from "../../../../constants/inputs/labels";
import {inputType} from "../../../../constants/types/inputType";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Backdrop from "@material-ui/core/Backdrop";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export const GeneralInformation = (props) => {
    const {validType, dme, missingValues, setMissingValues, handleInput, loading} = props;
    const [phone, setPhone] = useState(true);
    const [phone2, setPhone2] = useState(true);
    const [validName, setValidName] = useState(true);
    const classes = useStyles();
    return (
        <React.Fragment>
            <Typography variant="h6">
                {sectionTitles.generalInformation}
            </Typography>
            <Grid container
                  // spacing={3}
                style={{maxWidth:1000}}
            >
                <Grid item xs={12}>
                    <Grid container spacing={2} justify={'space-between'} alignContent={'flex-end'} alignItems={'flex-end'} style={{paddingBottom:8}}>
                        <Grid item md={4} xs={6}>
                            <div style={{textAlign:'right', paddingBottom:8, color:'grey'}}>{props.dmeId && props.dmeId !== 'new' && `Organization ID: #${props.dmeId}`}</div>
                            <TextField
                                helperText={missingValues.dme_name && tooltips.isRequired}
                                error={missingValues.dme_name}
                                name="dme_name"
                                label={placeholders.organizationName}
                                placeholder={placeholders.organizationName}
                                value={dme.dme_name}
                                variant="outlined"
                                size="small"
                                fullWidth
                                onChange={e => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                    setValidName(true);
                                    setMissingValues({...missingValues, dme_name: false})
                                }}
                                onBlur={e => {
                                     if (e.target.value !== '') {
                                         (async () => {
                                             const target = endpoints.dme_organizations + e.target.value;
                                             const token = JSON.parse(localStorage.getItem('key'));
                                             const getNamePromise = await fetch(target, {
                                                 method: "GET",
                                                 headers: {
                                                     'Content-Type': 'application/json',
                                                     'Authorization': 'Bearer ' + token.accessToken
                                                 },
                                             });
                                             const getNameResponse = await getNamePromise.json();
                                             if (getNameResponse.status !== 'success') {
                                                 setValidName(true)
                                             } else {
                                                 setValidName(false)
                                             }
                                         })()
                                     } else if (e.target.value.length < 1) {
                                         setMissingValues({...missingValues, dme_name: true});
                                         setValidName(true)
                                     }
                                 }}
                            />
                             <FormHelperText
                                 style={{color: 'red', textAlign: 'left'}}
                             >
                                 {(!validName) && tooltips.nameAlreadyExists}
                             </FormHelperText>
                        </Grid>
                        <Grid item xs={6} style={{display:'flex', justifyContent:'flex-end'}}>
                            <div>
                                <FormControl component="fieldset">
                                    <FormLabel style={{textAlign: 'left'}}>Provider Status</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={<Switch color="primary"
                                                             checked={dme.status}
                                                             name={"providerStatus"}
                                                             onChange={(e) => {
                                                                 handleInput({
                                                                     ...e,
                                                                     inputEventType: inputType.standard
                                                                 });
                                                             }}
                                            />}
                                        />
                                    </FormGroup>
                                    <FormHelperText>{getMessage(messageTypes.dmeStatus, {
                                        name: dme.dme_name,
                                        status: dme.status
                                    })}</FormHelperText>
                                </FormControl>
                            </div>
                        </Grid>

                    </Grid>
                    <Grid container spacing={2} alignContent={'flex-start'} alignItems={'flex-start'}>
                        <Grid item md={3} sm={6} xs={12}>
                            <CustomInputField
                                size={'small'}
                                isRequired={true}
                                isMissing={missingValues.address1}
                                name={'address1'}
                                label={'Address 1'}
                                value={dme.address1}
                                onChange={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                                onBlur={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                                isValid={true}
                            />
                        </Grid>
                        <Grid item md={3} sm={6} xs={12}>
                            <CustomInputField
                                size={'small'}
                                isRequired={false}
                                isMissing={missingValues.address}
                                name={'address2'}
                                label={'Address 2'}
                                value={dme.address2}
                                onChange={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.standard
                                    });
                                }}
                                onBlur={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.standard
                                    });
                                }}
                                isValid={true}
                            />
                        </Grid>
                        <Grid item md={2} sm={4} xs={6}>
                            <CustomInputField
                                size={'small'}
                                isRequired={true}
                                isMissing={missingValues.city}
                                validationType={validationTypes.onlyLetters}
                                name={'city'}
                                label={'City'}
                                value={dme.city}
                                onChange={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                                onBlur={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                                isValid={validType.city}
                            />
                        </Grid>
                        <Grid item md={2} sm={4} xs={6} style={{display:'flex'}}>
                            <CustomInputField
                                size={'small'}
                                isRequired={true}
                                isMissing={missingValues.state}
                                validationType={validationTypes.onlyLetters}
                                name={'state'}
                                label={'State'}
                                value={dme.state}
                                onChange={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                                onBlur={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                                isValid={validType.state}
                            />
                        </Grid>
                        <Grid item md={2} sm={4} xs={6} style={{display:'flex', alignContent:'center', alignItems:'center'}}>
                            <TextField
                                size={'small'}
                                style={{width: '100%'}}
                                helperText={missingValues.zip && tooltips.isRequired}
                                error={missingValues.zip}
                                required
                                id="zip"
                                name="zip"
                                label="Zip"
                                value={dme.zip}
                                variant={'outlined'}
                                autoComplete="billing address-level2"
                                onChange={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                                onBlur={(e) => {
                                    handleInput({
                                        ...e,
                                        inputEventType: inputType.required
                                    });
                                }}
                            />
                            <Tooltip title={tooltips.zip_autocomplete} arrow
                                     placement="top-end">
                                <InfoOutlinedIcon style={{marginLeft: '6px'}}/>
                            </Tooltip>
                            <FormHelperText
                                style={{color: 'red', textAlign: 'left'}}
                            >
                                {(!missingValues.zip && !validType.zip) && tooltips.zipOnlyNumbers}
                            </FormHelperText>
                        </Grid>
                    {/*</Grid>*/}
                    {/*<Grid container spacing={2} alignContent={'flex-start'} alignItems={'flex-start'}>*/}
                        <Grid item md={3} sm={4} xs={6} style={{display:'flex', flexDirection:'column', alignItems:'flex-start', alignContent:'flex-start', justifyContent:'flex-start'}}>
                            <InputLabel style={{fontSize: '0.8em', paddingBottom: '4px', textAlign: 'left'}}>
                                {labels.requiredPhone}
                            </InputLabel>
                            <PhoneInput
                                name="phone_number"
                                label='test'
                                country={'us'}
                                disableCountryCode={true}
                                placeholder={placeholders.phone}
                                onlyCountries={['us']}
                                value={dme.phone ? dme.phone : ""}
                                inputStyle={{paddingLeft:12, margin:0, width:'100%',}}
                                containerStyle={{width: '100%',}}
                                onChange={(e) => {
                                    if (e.length === 10) {
                                        setPhone(true)
                                    }
                                    handleInput({
                                        target:{
                                            name: "phone",
                                            value: e,
                                            level: "primary",
                                        },
                                        inputEventType: inputType.required
                                    });
                                }}
                                inputProps={{
                                    label: 'test',
                                    required: true,
                                    id: 'primary-phone'
                                }}
                                onBlur={(e) => {
                                    if (e.target.value.length === 14) {
                                        setPhone(true)
                                    } else {
                                        setPhone(false)
                                    }
                                }}
                                isValid={phone}
                            />
                            <FormHelperText style={{
                                color: 'red',
                                textAlign: 'left'
                            }}>{!phone && tooltips.invalidPhone}</FormHelperText>
                            <FormHelperText style={{
                                color: 'red',
                                textAlign: 'left'
                            }}>{missingValues.phone && tooltips.isRequired}</FormHelperText>
                        </Grid>
                        <Grid item md={3} sm={4} xs={6}>
                            <InputLabel style={{fontSize: '0.8em', paddingBottom: '4px', textAlign: 'left'}}>
                                {labels.secondaryPhone}
                            </InputLabel>
                            <PhoneInput
                                name="phone_number"
                                label='test'
                                country={'us'}
                                disableCountryCode={true}
                                placeholder={placeholders.phone}
                                onlyCountries={['us']}
                                value={dme.second_phone ? dme.second_phone : ""}
                                inputStyle={{paddingLeft:12, margin:0, width:'100%',}}
                                containerStyle={{width: '100%',}}
                                onChange={(e) => {
                                    if (e.length === 10) {
                                        setPhone2(true)
                                    }
                                    handleInput({
                                        target:{
                                            name: "second_phone",
                                            value: e,
                                            level: "secondary"
                                        },
                                        inputEventType: inputType.standard
                                    });
                                }}
                                inputProps={{
                                    label: 'test',
                                    required: true,
                                    id: 'secondary-phone'
                                }}
                                onBlur={(e) => {
                                    if (e.target.value.length === 14) {
                                        setPhone2(true)
                                    } else {
                                        setPhone2(false)
                                    }
                                }}
                                isValid={phone2}
                            />
                            <FormHelperText style={{
                                color: 'red',
                                textAlign: 'left'
                            }}>{!phone2 && tooltips.invalidPhone}</FormHelperText>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>

            <Grid container style={{maxWidth:1000, fontWeight:500, fontSize:20, paddingTop:16,marginTop:8, paddingBottom:8, borderTop:'1px solid whitesmoke'}}>
                Oxygen Supplier
            </Grid>

            <Grid container style={{maxWidth:1000}} spacing={2} alignContent={'flex-start'} alignItems={'flex-start'}>
                <Grid item md={3} sm={4} xs={6}>
                    <TextField
                        name="supplier_name"
                        label={placeholders.supplierName}
                        placeholder={placeholders.supplierName}
                        value={dme.supplier_name}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.required
                            });
                        }}
                    />
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                    <CustomInputField
                        size={'small'}
                        name={'oxygen_address1'}
                        label={'Address 1'}
                        value={dme.oxygen_address1}
                        onChange={(e) => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.required
                            });
                        }}
                        onBlur={(e) => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.standard
                            });
                        }}
                        isValid={true}
                    />
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                    <CustomInputField
                        size={'small'}
                        name={'oxygen_address2'}
                        label={'Address 2'}
                        value={dme.oxygen_address2}
                        onChange={(e) => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.required
                            });
                        }}
                        onBlur={(e) => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.standard
                            });
                        }}
                        isValid={true}
                    />
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                    <CustomInputField
                        size={'small'}
                        isRequired={true}
                        validationType={validationTypes.onlyLetters}
                        name={'oxygen_city'}
                        label={'City'}
                        value={dme.oxygen_city}
                        onChange={(e) => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.required
                            });
                        }}
                        onBlur={(e) => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.required
                            });
                        }}
                        isValid={true}
                    />
                </Grid>
                <Grid item md={2} sm={4} xs={6} style={{display:'flex'}}>
                    <CustomInputField
                        size={'small'}
                        isRequired={true}
                        validationType={validationTypes.onlyLetters}
                        name={'oxygen_state'}
                        label={'State'}
                        value={dme.oxygen_state}
                        onChange={(e) => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.required
                            });
                        }}
                        onBlur={(e) => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.required
                            });
                        }}
                        isValid={true}
                    />
                </Grid>
                <Grid item md={2} sm={4} xs={6} style={{display:'flex', alignContent:'center', alignItems:'center'}}>
                    <TextField
                        size={'small'}
                        style={{width: '100%'}}
                        required
                        id="zip"
                        name="oxygen_zip"
                        label="Zip"
                        value={dme.oxygen_zip}
                        variant={'outlined'}
                        autoComplete="billing address-level2"
                        onChange={(e) => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.required
                            });
                        }}
                        onBlur={(e) => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.required
                            });
                        }}
                    />
                    <Tooltip title={tooltips.zip_autocomplete} arrow
                             placement="top-end">
                        <InfoOutlinedIcon style={{marginLeft: '6px'}}/>
                    </Tooltip>
                </Grid>
                <Grid item md={3} sm={4} xs={6} style={{marginBottom:0}}>
                    <InputLabel style={{fontSize: '0.8em', paddingBottom: '4px', textAlign: 'left'}}>
                        {labels.phone}
                    </InputLabel>
                    <PhoneInput
                        name="oxygen_phone"
                        label='test'
                        country={'us'}
                        disableCountryCode={true}
                        placeholder={placeholders.phone}
                        onlyCountries={['us']}
                        value={dme.oxygen_phone ? dme.oxygen_phone : ""}
                        inputStyle={{paddingLeft:12, margin:0, width:'100%',}}
                        containerStyle={{width: '100%',}}
                        onChange={(e) => {
                            handleInput({
                                target:{
                                    name: "oxygen_phone",
                                    value: e,
                                    level: "secondary"
                                },
                                inputEventType: inputType.required
                            });
                        }}
                        inputProps={{
                            label: 'test',
                            required: true,
                            id: 'oxygen_phone'
                        }}
                    />
                    <FormHelperText style={{
                        color: 'red',
                        textAlign: 'left'
                    }}>{!phone2 && tooltips.invalidPhone}</FormHelperText>
                </Grid>
            </Grid>
            <Grid container style={{maxWidth:1000, fontWeight:500, fontSize:20, paddingTop:16,marginTop:8, borderTop:'1px solid whitesmoke'}}>
                License / Permit Info
            </Grid>

            <Grid container style={{maxWidth:1000}} spacing={2}>
                <Grid item md={3} sm={4} xs={6}>
                    <TextField
                        name="dme_retail"
                        label={placeholders.dmeRetail}
                        placeholder={placeholders.dmeRetail}
                        value={dme.dme_retail}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.required
                            });
                        }}
                    />
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                    <TextField
                        name="dme_wholesale"
                        label={placeholders.dmeWholesale}
                        placeholder={placeholders.dmeWholesale}
                        value={dme.dme_wholesale}
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={e => {
                            handleInput({
                                ...e,
                                inputEventType: inputType.required
                            });
                        }}
                    />
                </Grid>
            </Grid>
                   <Backdrop className={classes.backdrop} open={loading} >
                       <CircularProgress color="inherit" />
                   </Backdrop>
        </React.Fragment>
    );
}
