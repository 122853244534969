import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import {sectionTitles} from "../../../../constants/names/titles";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {graphBarColors, graphColorSwitch} from "../BusinessReviewHelper";
import Accordion from "@material-ui/core/Accordion";
import React from "react";

export const BRBarGraphAccordion = ({graphContainerWidth = "100%", graphContainerHeight = 500, accordionTitle = "Title", graphData =[], graphHighlightColor = graphBarColors.topOofItem, graphDefaultColor = graphBarColors.default}) => {
    return <Accordion>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel2a-content"
            id="panel2a-header"
        >
            <Typography>{accordionTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <ResponsiveContainer width={graphContainerWidth} height={graphContainerHeight}>
                <BarChart
                    width={1000}
                    height={500}
                    data={graphData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    <Bar dataKey="totalItems" fill="#000000">
                        {graphData.map((entry, index) => (graphColorSwitch(entry, index, graphHighlightColor, graphDefaultColor)))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </AccordionDetails>
    </Accordion>
}