import React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Checkbox} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {getMessage} from "../../../../../constants/messages/messages";
import {messageTypes} from "../../../../../constants/types/messageTypes";
import {parseTimeWithUserTimezone} from '../../../../../common/timeHandler/timeHandler';
import {maxInputLength} from "../../../../../constants/inputs/values";

export const OrderNotes = ({notes, setNotes, notifyHospice, handleInput, setIsUnsavedNote, showError, currentNote, setCurrentNote, setShowWarning, showWarning, addOrderNote}) => {

    return (
        <div style={{paddingBottom: '20px'}}>
                <div style={{padding: "16px 0px 0px", fontSize: 20, fontWeight:'bold', color:'#00223D'}}>Notes for order:</div>
                <div style={{padding: "8px 0px"}}>
                    {notes && (
                        <div>
                            {notes.map((note, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div style={{
                                            display: 'flex',
                                            border: ' solid 1px #CCCCCC',
                                            backgroundColor: '#F2F2F2',
                                            borderRadius: 4,
                                            padding: 16,
                                            marginBottom: 12,
                                            justifyContent: 'space-between'
                                        }}>

                                            <div>{note.description}</div>
                                            <div style={{
                                                fontStyle: 'italic',
                                                color: "#B6B6B6"
                                            }}>{note.creator + ", " + parseTimeWithUserTimezone(note.created_date)}</div>
                                        </div>
                                    </React.Fragment>

                                )
                            })
                            }
                        </div>
                    )}

                    <TextField
                        placeholder="Some additional notes can be put here..."
                        label='Add additional note'
                        variant={'outlined'}
                        fullWidth
                        multiline
                        name='note'
                        rows={2}
                        rowsMax={9}
                        value={currentNote}
                        onChange={(e) => {
                            setCurrentNote(e.target.value);
                            if(e.target.value !== "") {
                                setIsUnsavedNote(true);
                            } else {
                                setIsUnsavedNote(false);
                                setShowWarning(false);
                            }
                        }}
                        onBlur={(e)=> {
                            if (currentNote !== "") {
                                setShowWarning(true);
                            } else {
                                setShowWarning(false);
                            }
                        }}
                        inputProps={{maxLength: maxInputLength.L1000}}
                        helperText={currentNote && `${currentNote.length < maxInputLength.L1000 ? currentNote.length.toString() + `/${maxInputLength.L1000}`: currentNote.length.toString() + `/${maxInputLength.L1000} You have reached the character limit for one note.` }`}
                        error={currentNote && currentNote.length === maxInputLength.L1000}
                    /> {showWarning && (<p style={{textAlign: 'left', color: "#FF0000"}}>You may have forgotten to submit a note.</p>)}
                    <div style={{
                        alignContent: 'right',
                        justifyContent: 'flex-end',
                        display: 'flex',
                        paddingTop: '10px',
                        width: '100%'
                    }}>
                        {/*<Grid item style={{
                            alignContent: 'right',
                            justifyContent: 'flex-end',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '10px',
                        }}>
                            <Checkbox color={"primary"}
                                      checked={notifyHospice}
                                      name={"notifyHospice"}
                                      onChange={(event) => {
                                          const obj = {
                                              ...event,
                                              target: {
                                                  ...event.target,
                                                  name: "notifyHospice",
                                              }
                                          };
                                          handleInput(obj)
                                      }}
                            />
                            <Typography style={{fontSize:14}}>{getMessage(messageTypes.notice, "Hospice")}</Typography>
                        </Grid>*/}
                        <Button
                            disabled={!currentNote || currentNote === ''}
                            variant={"contained"}
                            color={"primary"}
                            onClick={addOrderNote}
                            onMouseDown={ (e) => e.preventDefault()  }
                            style={{minWidth:104}}
                        >Add Note</Button>
                    </div>

                </div>
        </div>
    )
};
