import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import {TextField, Tooltip} from "@material-ui/core";
import AssetSelect from "../../../../common/assetSelect/assetSelect";
import _ from "lodash";
import moment from "moment";
import Button from "@material-ui/core/Button";
import {buttonText} from "../../../../constants/alert/alertButtonTexts";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {alertTitles} from "../../../../constants/alert/alertTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {alertMessages} from "../../../../constants/alert/alertMessages";
import Select from "react-select";
import {itemStatus} from "../../../../constants/status/status";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {checkTokenAvailability, getTokenInformation} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {endpoints} from "../../../../constants/endpoints";
import {useSnackbar} from "notistack";
import {utilsTokenIdentifiers} from "../../../../constants/inputs/values";
import {PriorityHighRounded} from "@material-ui/icons";
import {isMobile} from "react-device-detect";


const ItemsTable = props => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const [statusValue, setStatusValue] = useState("");
    const [comment, setComment] = useState("");
    const [currentItem, setCurrentItem] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const {order, setOrder} = props;
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const userType = getTokenInformation(history, utilsTokenIdentifiers.userType);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const handlerChangeItemStatus = async () => {
        setLoading(true);
        await (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem("key"));
        const promise = await fetch(`${endpoints.updateItemStatus}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    itemStatus: statusValue.value,
                    itemId: currentItem[0].inventory_item_id,
                    specialItemId: currentItem[0].special_item_id,
                    comment: comment,
                    status:false
                })
            }
        );
        const response = await promise.json();
        let reason = statusValue.value;
        if (response.status === 'success'){
            props.items[currentItem[1]].item_status = statusValue.label;
            setStatusValue([{label:'',value:""}]);
            setOpenModal(false);
            enqueueSnackbar("Status updated with success.", {
                persist: false,
                variant: "success",
            });
            const sendEmailNotifications = await fetch(`${endpoints.sendLostEmailNotifications}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                    body: JSON.stringify({
                        item_name: currentItem[0].item_name,
                        comments: comment ? comment : 'No information',
                        order_id: `S300${props.orderId}`,
                        order_type:'exchange',
                        reason: reason,
                        patient_full_name: `${order.first_name} ${order.last_name}`,
                        care_setting: order.care_setting ? order.care_setting : 'No information',
                        asset: currentItem[0].asset,
                        hospice_id: order.hospice_organization_id,
                        delivery_date: moment(currentItem[0].completed_date).format('L'),
                        dme_id: order.dme_organization_id
                    })
                }
            );
            await sendEmailNotifications.json();
        } else {
            enqueueSnackbar("Unable to update item status.", {
                persist: false,
                variant: "error",
            });
        }
        setLoading(false)
    };
    return (
        <Grid container className={classes.wrapper}>
            <Grid container className={classes.container} justify={"space-between"}>
                <Grid item xs={12} style={{display: 'flex', textAlign: 'left'}}>
                    <Grid xs={1} item className={classes.checkbox} style={{flexShrink: 0}}/>
                    <Grid xs={8} item className={classes.row}>Item name</Grid>
                    <Grid xs={6} item className={classes.row} style={{minWidth:200}}>Asset #</Grid>
                    <Grid xs={4} item className={classes.row}>Qty</Grid>
                    <Grid xs={6} item className={classes.row}>Clinical setting</Grid>
                    <Grid xs={6} item className={classes.row} style={{minWidth:140}}>Lot #</Grid>
                    <Grid xs={4} item className={classes.row}>Delivery date</Grid>
                    <Grid xs={4} item className={classes.row}>Pickup date</Grid>
                    <Grid xs={6} item className={classes.row}>Item Status</Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.container} justify={"space-between"}>
            {props.items && (
                props.items.map((item, index) => {
                    let includes = _.findIndex(props.selectedItems, {'special_item_id': item.special_item_id}) !== -1;
                    return (
                        <Grid key={index} container justify={"space-between"} className={classes.rows}>
                            <Grid item xs={12} style={{display: 'flex', textAlign: 'left'}}>
                                <Grid xs={1} item className={classes.checkbox} style={{flexShrink: 0}} onClick={() => {
                                    userType === 'Hospice' && item?.asset?.trim().length > 0 && setSelectedIndex(prevState => prevState === index ? -1 : index);
                                }}>
                                    <Checkbox
                                        color="primary"
                                        disabled={userType === 'Hospice' && item?.asset?.trim().length > 0}
                                        onChange={(e) => props.handleCheckboxChange(item, index, e.target.checked)}
                                        checked={includes}
                                    />
                                    {userType === 'Hospice' && item?.asset?.trim().length > 0 &&
                                        <Tooltip open={selectedIndex === index} placement={isMobile ? "bottom" : "top-end"}
                                                 title={"This item already has an asset assigned. Please contact DME for removal."}><PriorityHighRounded
                                            color={"secondary"}/></Tooltip>}
                                </Grid>
                                <Grid xs={8} item className={classes.row}>{item.name ? item.name : item.item_name}</Grid>
                                <Grid xs={6} item className={classes.row} style={{minWidth:200}}>
                                    {props.userType === 'DME' ? (
                                        <div style={{width: '100%'}}>
                                            <AssetSelect
                                                item={item}
                                                hasAsset={item.asset ? true : false}
                                                order={props.order}
                                                setOrder={props.setOrder}
                                            />
                                        </div>
                                    ) : item.asset }
                                </Grid>
                                <Grid xs={4} item className={classes.row}>{item.quantity ? item.quantity : 1}</Grid>
                                <Grid xs={6} item className={classes.row}>
                                    <div style={{textAlign: 'left'}}>
                                        {item.min_range && (
                                            <React.Fragment>
                                                <div> {`${item.liters_per_minute === true ? 'Liters Per Minute': 'Air PSI'}`} </div>
                                                <div> {`Min: ${item.min_range}`} </div>
                                                <div> {`Max: ${item.max_range}`} </div>
                                            </React.Fragment>
                                        )}
                                        {item.iscontinous === true && (
                                            <div> {`With continuous oxygen therapy`} </div>
                                        )}
                                        {item.humidifier_for_compressor && (
                                            <div> {`Humidifier bottle: ${item.humidifier_for_compressor}%`} </div>
                                        )}
                                        {item.weight  && (
                                            <div> {`Patient Weight: ${item.weight}`} </div>
                                        )}
                                        {item.size_lift && (
                                            <div> {`Size: ${item.size_lift}`} </div>
                                        )}
                                        {item.service_note && (
                                            <div> {`Service Note: ${item.service_note_text}`} </div>
                                        )}
                                    </div>
                                </Grid>
                                <Grid xs={6} item className={classes.row} style={{minWidth:140}}>
                                    {props.userType === 'DME' ? (
                                        item.asset && (
                                            <div style={{width: '100%'}}>
                                                <TextField
                                                    placeholder="Add lot number"
                                                    size="small"
                                                    variant="outlined"
                                                    value={item.lot_no && item.lot_no}
                                                    onChange={(e) => {
                                                        props.selectAssetHandle(e, index, "lot", "name", item.asset, false, item.special_item_id, props.type);
                                                        props.setCurrentSpecialId(item.special_item_id);
                                                    }

                                                    }
                                                    onBlur={(e) => {
                                                        props.selectAssetHandle(e, index, "lot", "name", item.asset, true, item.special_item_id,  props.type);
                                                        props.setCurrentSpecialId(item.special_item_id);
                                                    }}
                                                />
                                                {props.invalidLotNo && props.invalidLotNo.length > 0 && props.invalidLotNo.includes(item.special_item_id) && (
                                                    <React.Fragment>
                                                        <p style={{color: "red", margin: 0, paddingTop: '5px'}}>Invalid lot no.</p>
                                                        <p style={{color: "red", margin: 0}}>Only numbers allowed.</p>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        )
                                    ) : item.inventory ? item.inventory.lot : ''}
                                </Grid>
                                <Grid xs={4} item
                                      className={classes.row}>{props.order.completed_date && moment(props.order.completed_date).format('L')}</Grid>
                                <Grid xs={4} item className={classes.row}>{item?.pickup_completed_date && moment(item?.pickup_completed_date).format('L')}</Grid>
                                <Grid xs={6} item className={classes.row}>
                                    <div style={{width: "100%"}}>
                                        {(item.item_status || item.asset) ? ((item.item_status || item.asset) &&
                                            <Button variant={"outlined"} size={"small"} onClick={() => {
                                                setOpenModal(true);
                                                setCurrentItem([item, index]);
                                            }}>{buttonText.itemStatus}</Button> ) :
                                            <div>
                                                <Button variant={"outlined"} size={"small"} disabled>{buttonText.itemStatus}</Button>
                                                <div style={{color:'grey', fontWeight:'bold', textAlign:'left', fontSize:13}}>
                                                    You need to create or assign an asset before updating item status
                                                </div>
                                            </div>

                                        }
                                    </div>
                                </Grid>
                            </Grid>

                        </Grid>
                    )
                })
            )}
            </Grid>
            <Dialog
                open={openModal}
                onClose={()=>setOpenModal(false)}
            >
                <DialogTitle>{alertTitles.updateItemStatus}</DialogTitle>
                <DialogContent className={classes.dialog}>
                    <div className={classes.messages}>{alertMessages.changeItemStatus}</div>
                    <div className={classes.warehouseSelect} style={{zIndex:300}}>
                        <Select
                            options={itemStatus}
                            value={currentItem.status && (currentItem.status === "" ? itemStatus[0] : [{
                                value: currentItem.status,
                                label: currentItem.status
                            }])}
                            onChange={(e)=> setStatusValue(e)}
                        />
                    </div>
                    <div style={{paddingTop: 8}}>
                        <CssTextField
                            id={"Note"}
                            name={"Note"}
                            placeholder={'Comments'}
                            values={comment}
                            variant={"outlined"}
                            multiline
                            rows={7}
                            onChange={(e) => setComment(e.target.value)}
                            size='small'
                            fullWidth
                            required
                            InputLabelProps={{
                                shrink: true,
                                style: {letterSpacing: 0.39,}
                            }}
                            inputProps={{style: {fontSize: 14}}}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" disabled={loading} onClick={() => {
                        setOpenModal(false)
                        setComment("");
                    }} color="secondary">
                        {buttonText.cancel}
                    </Button>
                    <Button variant="contained" color="primary" disabled={loading} onClick={() => {
                        handlerChangeItemStatus()
                        setComment("");
                    }}>
                        {buttonText.yes}
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
};
const CssTextField = withStyles({
    root: {
        '& label': {
            color: '#8C8B8B',
        },
        '& label.Mui-focused': {
            color: '#93BBC9',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#93BBC9',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#93BBC9',
            },
            '&:hover fieldset': {
                borderColor: '#93BBC9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#93BBC9',
            },
        },
    },
})(TextField);

export default ItemsTable;


const useStyles = makeStyles((theme) => ({
    wrapper: {
        // borderRadius: 4,
        //         // border: '1px solid #E0E0E0'
        paddingTop:16,
        // overflowX:'auto',
        maxWidth:'calc(100vw - 32px)'
    },
    container: {
        // height: 96,
        color: '#2E3236',
        fontSize: 13,
        fontWeight:'bold',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        backgroundColor: '#EDF2F4B9',
        maxWidth: 1428,
        width: '101%',
        minWidth: 800,
        margin: -1,
    },
    row: {
        width: '100%',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        padding: 16,
        fontSize: '0.875rem',
    },
    rows: {
        paddingTop: 8,
        paddingBottom: 8,
        borderBottom: '1px solid #E0E0E0',
        backgroundColor:'#fff',
        fontWeight:600,
        fontSize:14
    },
    checkbox: {
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        padding: 2,
        fontSize: '0.875rem',
    },
    dialog: {
        minHeight:260,
        minWidth:280
    }
}));
