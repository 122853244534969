import React, {useEffect, useState} from 'react';
import './reports.css';
import {useSelector} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {Redirect, useHistory} from "react-router-dom";
import PlusSign from "../../assets/Icon_material-add-circle-outline.svg";
import jwt from "jwt-decode";
import Typography from "@material-ui/core/Typography";
import ListSign from "../../assets/Icon_material-format-list-bulleted.svg";
import EmailSign from "../../assets/e-mail.svg";
import HelpSign from "../../assets/help.svg";
import MapIcon from '@material-ui/icons/Map';
import {dashboardStyle} from "../dashboard/dashboard.style";
import FrSign from "../../assets/icon_financial-reports.svg";
import PatientSign from "../../assets/patient_selected_full.svg";
import TrSign from "../../assets/icon_transaction-reports.svg";
import LocationCityIcon from '@material-ui/icons/LocationCity';
import {checkPermissions} from "../../common/permissions/checkPermissions";
import {permissions} from "../../constants/permissions/permissions";

import PersonIcon from '@material-ui/icons/Person';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddBoxIcon from '@material-ui/icons/AddBox';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DescriptionIcon from '@material-ui/icons/Description';
import {userTypes} from "../../constants/types/userTypes";
import {SvgIcon} from "@material-ui/core";
import {getTokenInformation} from "../../common/utils/utils";
import {utilsTokenIdentifiers} from "../../constants/inputs/values";

export default function Reports() {
    const classes = useStyles();
    const history = useHistory();
    const [routes, setRoutes] = useState([]);

    const iconMapper = (iconName) => {
        switch (iconName) {
            case "PersonIcon":
                return <PersonIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "LocalHospitalIcon":
                return <LocalHospitalIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "SupervisorAccountIcon":
                return <SupervisorAccountIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "VerifiedUserIcon":
                return <VerifiedUserIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "AssignmentIcon":
                return <AssignmentIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "AddBoxIcon":
                return <AddBoxIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "FlashOnIcon":
                return <FlashOnIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "DescriptionIcon":
                return <DescriptionIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "TrSign":
                return <img
                    src={TrSign}
                    alt="logo"
                    width='30'
                    style={{color: '#4F86A0'}}
                />
            case "FrSign":
                return <img
                    src={FrSign}
                    alt="logo"
                    width='30'
                />
            case "PatientSign":
                return <img
                    src={PatientSign}
                    alt="logo"
                    width='30'
                />
        }
    }

    useEffect(() => {
        setRoutes(generateRoutes());
    },[])

    return (
        <React.Fragment>
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <Typography className={classes.h3} style={{padding: '15px 48px'}}>Reports Dashboard</Typography>
                </Grid>

                {routes.map((item, idx) => (
                    item.list.length > 0 &&
                    <Grid item xs={12} sm={6} md={4} style={{padding: '5px', position: 'relative'}}>
                        <div
                            className={classes.box}
                            style={{
                                borderBottom: idx % 2 === 0 ? '4px solid #4F86A0' : '4px solid #A3C7D2',
                                minHeight: '420px'
                            }}>
                            <Typography className={classes.h3} style={{display: 'flex', alignItems: 'center', whiteSpace: "initial", height: 100}}>
                                {iconMapper(item.icon)}
                                <span style={{paddingLeft: '10px'}}>{item.title}</span>
                            </Typography>
                            {item.list.map((listItem, idx) => (
                                <div key={idx} style={{
                                    display: 'flex',
                                    marginLeft: '48px',
                                    marginTop: '20px',
                                    fontSize: '24px',
                                    cursor: 'pointer'
                                }} onClick={() => listItem.path && history.push(listItem.path)}>
                                    <Typography className={classes.h4}>{listItem.name}</Typography>
                                </div>
                            ))}
                        </div>
                    </Grid>
                ))}

            </Grid>
        </React.Fragment>
    )
}

const formRouteList = (permissionXRouteList) => {
    let routeList = [];
    for (const pair of permissionXRouteList) {
        if (checkPermissions([pair.permission], pair.type)) {
            routeList.push(pair.routeData);
        }
    }
    return routeList;
}
//constants
const generateRoutes = () => {
    const items = [
        {
            title: "Financial Reports",
            icon: "FrSign",
            list: formRouteList([
                {
                    permission: permissions.billing_report,
                    routeData: {
                        name: "Generate Billing Summary Report",
                        path: '/reports/create/billingreport'
                    }
                }
            ])
        },
        {
            title: "Utilization Reports",
            icon: "TrSign",
            list: formRouteList([
                {
                    permission: permissions.active_rental_report,
                    routeData: {
                        name: "Full Active Utilization Report",
                        path: '/reports/create/activerental/full'
                    }
                },
                {
                    permission: permissions.active_rental_report,
                    routeData: {
                        name: "Specific Active Utilization Report",
                        path: '/reports/create/activerental/specific'
                    }
                },
                {
                    permission: permissions.active_rental_report,
                    routeData: {
                        name: "Active Utilization Summary",
                        path: '/reports/create/activerental/summary'
                    }
                },
            ])
        },
        {
            title: "Items Reports",
            icon: "TrSign",
            list: formRouteList([
                {
                    permission: permissions.reports_lost_item,
                    routeData: {
                        name: "Lost Item Report",
                        path: '/reports/create/lostitems'
                    }
                },
            ])
        },
        {
            title: "OOF Reports",
            icon: "TrSign",
            list: formRouteList([
                {
                    permission: permissions.oof_report,
                    routeData: {
                        name: "Generate OOF Report",
                        path: '/reports/create/oofreport'
                    }
                },
            ])
        },
        {
            title: "User Reports",
            icon: "PatientSign",
            list: formRouteList([
                {
                    permission: permissions.reports_users,
                    routeData: {
                        name: "Active Hospice Users",
                        path: '/reports/create/users/hospice'
                    }
                },
                {
                    permission: permissions.reports_users,
                    type: userTypes.dme,
                    routeData: {
                        name: "Active DME Users",
                        path: '/reports/create/users/dme'
                    }
                },
            ])
        },
        {
            title: "User Custom Operation Reports",
            icon: "PatientSign",
            list: formRouteList([
                {
                    permission: permissions.reports_users,
                    routeData: {
                        name: "Hospice Users",
                        path: '/reports/custom/audit/hospice'
                    }
                },
                {
                    permission: permissions.reports_users,
                    type: userTypes.dme,
                    routeData: {
                        name: "DME Users",
                        path: '/reports/custom/audit/dme'
                    }
                },
            ])
        },
        {
            title: "Order Placement Reports",
            icon: "PatientSign",
            list: formRouteList([
                {
                    permission: permissions.reports_order_placement,
                    routeData: {
                        name: "Ordering Report",
                        path: '/reports/create/users/nurseorderreport'
                    }
                },
            ])
        },
        {
            title: "Patient Reports",
            icon: "TrSign",
            list: formRouteList([
                {
                    permission: permissions.reports_facility,
                    routeData: {
                        name: "Generate Facility Report",
                        path: '/reports/create/facilityReport'
                    }
                },
                {
                    permission: permissions.technician_activity,
                    type: userTypes.dme,
                    routeData: {
                        name: "Technician Activity Report",
                        path: '/reports/technician/activity'
                    }
                },
                {
                    permission: permissions.active_rental_report,
                    routeData: {
                        name: "Inactive Patient Report",
                        path: '/reports/create/activerental/inactivePatients'
                    }
                },
                {
                    permission: permissions.reports_diseases,
                    routeData: {
                        name: "Infectious Disease Report",
                        path: '/reports/create/dieseaseReport'
                    }
                },
            ])
        },
        {
            title: "Business Intelligence",
            icon: "TrSign",
            list: formRouteList([
                {
                    permission: permissions.reports_business_review,
                    routeData: {
                        name: "Business Review",
                        path: '/reports/create/businessReview'
                    }
                },
                {
                    permission: permissions.read_admin, //TODO: Change with permission when created
                    routeData: {
                        name: "Geographic Compare",
                        path: '/reports/create/geographicReport'
                    }
                }, {
                    permission: permissions.read_admin, //TODO: Change with permission when created
                    routeData: {
                        name: "Length of Stay",
                        path: '/reports/create/lengthOfStay'
                    }
                },
            ])
        },
        {
            title: "Pace",
            icon: "TrSign",
            list: formRouteList([
                {
                    permission: permissions.reports_pace,
                    routeData: {
                        name: "Generate Pace Report",
                        path: '/reports/create/pacereport'
                    }
                },
            ])
        },
    ]
    return items;
}

const useStyles = makeStyles((theme) => (dashboardStyle(theme)));