import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {tableColumnTitles} from "../../../constants/names/columnTitles";
import Grid from "@material-ui/core/Grid";
import {componentTitles} from "../../../constants/names/titles";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import {endpoints} from "../../../constants/endpoints";
import {status} from "../../../constants/status/status";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import jwt from "jwt-decode";
import {notifySnack} from "../../../common/notifications/apiStatusNotification";
import {snackbarMessages} from "../../../constants/alert/alertMessages";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        padding: '9px 18px'
    },
    icon: {
        paddingRight: '5px',
        fontSize: '1.5em'
    },
    root: {
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '50px'
    },
    title: {
        fontWeight: "normal"
    },
    container: {
        padding: 24,
        paddingTop: 36
    }
}));

export default function InventoryEditExistingItem() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const data = JSON.parse(localStorage.getItem('key'));
    const user = jwt(data.accessToken);


    function generateItemNames(){
        let obj = {}
        if (items && items.length > 0){
            items.map(item => {
                if (item.item_name !== ''){
                    obj[item.item_name] = item.item_name
                }
            })
        }
        return obj
    }

    function generateManufacturerNames(){
        let obj = {}
        if (items && items.length > 0){
            items.map(item => {
                if (item.name !== ''){
                    obj[item.name] = item.name
                }
            })
        }
        return obj
    }

    const dmeID = {
        id: Number(user.payload.dme_organization_id)
    };

    const [isLoading, setIsLoading] = useState(true);
    const [items, setItems] = React.useState([]);

    const columns = [
        {title: tableColumnTitles.itemName, field: 'item_name', width: 300,
            lookup: generateItemNames()
        },
        {title: tableColumnTitles.dmeWarehouses, field: 'warehouse_name', width: 500, editable: 'never'},
        {title: tableColumnTitles.manufacturer, field: 'name', width: 140,  lookup: generateManufacturerNames()},
        {
            title: tableColumnTitles.assetNumber, field: 'asset', width: 150,

        editComponent: props => (
        <TextField
            type="text"
            error={(items.some(item => item.asset === props.rowData.asset) && items[props.rowData?.tableData?.id]?.asset !== props.value && props.value !== '')}

            helperText={(items.some(item => item.asset === props.rowData.asset) && items[props.rowData?.tableData?.id]?.asset !== props.value && props.value !== '') ? "Duplicate Value" : null}
            value={props.value ? props.value : ""}
            onChange={(e) => {
                props.onChange(e.target.value);
            }}
        />)
        },
        {title: tableColumnTitles.serialNumber, field: 'serial_id', width: 200},
        {title: tableColumnTitles.price, field: 'price', width: 140},
        {title: tableColumnTitles.status, field: 'item_status', width: 140, editable: 'never'},
        {
            title: tableColumnTitles.maintenance,
            field: 'maintenance',
            width: 140,
            lookup: {'None Specified': 'None Specified', '90 Days': '90 Days', '12 Months': "12 Months"}
        }
    ];


    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        (async () => {
            fetch(endpoints.getInventoryItemsForEdit + dmeID.id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.accessToken
                }
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    setItems(response.data);
                    setIsLoading(false);
                })
                .catch(err => console.log('error', err))
        })()
    }, []);
    return (
        <React.Fragment>
            <Grid container justify={"center"} alignItems={"center"} direction={"column"} className={classes.container}>
                <Grid item style={{width: '100%'}}>
                    {!isLoading && (
                        <MaterialTable
                            title={componentTitles.inventoryEditItems}
                            columns={columns}
                            data={items}
                            isLoading={isLoading}
                            editable={{
                                onRowUpdate: (newData, oldData) =>

                                    new Promise((resolve, reject) => {
                                        (async () => {
                                            const availableToken = await checkTokenAvailability();
                                            if (!availableToken) {
                                                dispatch(logout());
                                                history.push('/login');
                                            }
                                        })();
                                        const token = JSON.parse(localStorage.getItem('key'));

                                        console.log(oldData, "old");
                                        console.log(newData, "new");

                                        if (
                                            oldData.item_name !== newData.item_name ||
                                            oldData.asset !== newData.asset ||
                                            oldData.manufacturer !== newData.manufacturer ||
                                            oldData.serial_id !== newData.serial_id ||
                                            oldData.price !== newData.price ||
                                            oldData.maintenance !== newData.maintenance
                                        ) {
                                            fetch(endpoints.addInventory, {
                                                method: 'post',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': 'Bearer ' + token.accessToken
                                                },
                                                body: JSON.stringify({
                                                    items: [
                                                        {
                                                            asset: newData.asset,
                                                            item_name: newData.item_name,
                                                            maintenance: newData.maintenance,
                                                            manufacturer_id: newData.manufacturer_id,
                                                            serial_id: newData.serial_id,
                                                            warehouse_id: newData.warehouse_id,
                                                            item_status: "Available",
                                                            price: newData.price,
                                                            status: true,
                                                            dme_organization_id: newData.dme_organization_id
                                                        }
                                                    ]
                                                })
                                            }).then(res => {
                                                if (res.status === status.created) {
                                                    resolve();
                                                    fetch(endpoints.inventoryModified, {
                                                        method: 'post',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Authorization': 'Bearer ' + token.accessToken
                                                        },
                                                        body: JSON.stringify({
                                                            inventory_item_id: oldData.inventory_item_id
                                                        })
                                                    }).then(res => {
                                                        console.log(res.status)
                                                    })
                                                    if (oldData) {
                                                        setItems((prevState) => {
                                                            const data = [...prevState];
                                                            data[data.indexOf(oldData)] = newData;
                                                            return data;
                                                        });
                                                    }
                                                } else {
                                                    reject();
                                                }
                                            }).catch(err => {
                                                console.log(err, 'error');
                                                enqueueSnackbar("Something happened. Contact support.", {
                                                    persist: false,
                                                    variant: "error",
                                                });
                                            })
                                        }
                                    }),
                            }}
                        />
                    )}

                </Grid>
            </Grid>
        </React.Fragment>
    );
}
