import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import jwt from "jwt-decode";
import MaterialTable from "material-table";
import {endpoints} from "../../../constants/endpoints";
import {Backdrop} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import Select from 'react-select';
import Button from "@material-ui/core/Button";
import ExportForm from "../../../common/exportForm/exportForm";
import {Table} from "../../../common/table/table";
import {parseTimeWithUserTimezone} from "../../../common/timeHandler/timeHandler";

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100,
        textAlign: 'left'
    })
};

export default function UsersCustomAuditReport(props){
    const [userType, setUserType] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [list, setList] = useState([]);
    const [reportType, setReportType] = useState(null);
    const classes = useStyles();
    const sortedList = list.sort((a, b) => a.label.localeCompare(b.label));

    useEffect(()=> {
        const token = JSON.parse(localStorage.getItem('key'))
        if (token && token.accessToken){
            let user = jwt(token.accessToken);
             if (user.payload.type === "Hospice"){
                 setUserType('Hospice');
                 getUsersReport(null, user.payload.hospice_organization_id)

             } else if (user.payload.type === "DME"){
                 if (user.payload.role_id === 6){
                     setUserType('Admin');
                     if (window.location.pathname === '/reports/custom/audit/dme'){
                         getAllDmeOrganizations()
                         setReportType('DME')
                     } else {
                         getAllHospiceOrganizations()
                         setReportType('Hospice')
                     }
                 } else {
                     setUserType('DME');
                     getUsersReport(user.payload.dme_organization_id,null )
                 }
             }
        }
    },[])

   async function getUsersReport(dme_id, hospice_id){
        setLoading(true)
        const token = JSON.parse(localStorage.getItem("key"));
        const promise = await fetch(endpoints.getAuditCustomUsersReport, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            method: "POST",
            body: JSON.stringify({
                hospice_id:hospice_id,
                dme_id: dme_id
            })
        });
        const response = await promise.json();

       if(response.status === "success"){
            setData(response.data)
        }
       setLoading(false);
    }

    async function getAllDmeOrganizations(){

        const token = JSON.parse(localStorage.getItem("key"));
        const promise = await fetch(endpoints.getDmeIds, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        if(response.status === "success"){
            let arr = [];
            response.data.map(org => {
                arr.push({
                    label:org.dme_name,
                    value:org.dme_organization_id
                })
            })
            setList(arr);
        }
        setLoading(false)
    }

    async function getAllHospiceOrganizations(){

        const token = JSON.parse(localStorage.getItem("key"));
        const promise = await fetch(endpoints.getAllHospiceIds, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        if(response.status === "success"){
            let arr = [];
            response.data.map(org => {
                arr.push({
                    label:org.hospice_name,
                    value:org.hospice_organization_id
                })
            })
            setList(arr);

        }
        setLoading(false)
    }
    const getExportValues = () => {
        let arr = []
        data.map(user => {
            let newArr = [];
                //newArr.push(user.created_date);
                newArr.push(user.operation);
                newArr.push(user.user_id);
                newArr.push(user.action);
                newArr.push(user.resourceids);
                arr.push(newArr)
            });
        return arr
    }
    const clearData = () => {
        setData([]);
        setSelectedOrg(null);
    };

    const headers = [
        {text: "Created Date", style: 'tableHeader'},
        {text: "Operation", style: 'tableHeader'},
        {text: "User Id", style: 'tableHeader'},
        {text: "Action", style: 'tableHeader'},
        {text: "Resource ids", style: 'tableHeader'},
    ];

    const excelHeaders = [
        "Created_Date",
        "Operation",
        "User_id",
        "Action",
        "Resource_ids",
    ];

    return (
        <Grid container className={classes.topContainer}>
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
                <Grid container className={classes.header}>
                    Users Report
                </Grid>
                {userType === 'Hospice' && (
                    <Grid container className={classes.padding24} justify={'center'}>
                        <Grid container justify={'flex-end'}>
                            <ExportForm
                                fileName='UserCustomAuditReport'
                                headers={headers}
                                excelHeaders={excelHeaders}
                                showPrint={true}
                                getExportValues={getExportValues}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.paddingT24}>
                            <Table
                                search={true}
                                filtering={true}
                                showSearch={true}
                                columns={[
                                    { title: 'Operation', field: 'operation', width:300 },
                                    { title: 'User Id', field: 'user_id', width:300},
                                    { title: 'Action', field: 'action', width:300},
                                    { title: 'View Resources', field: 'resourceids', width:300,
                                        cellStyle: {
                                            width: 300,
                                            maxWidth: 300,
                                            whitespace: "wrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        },
                                        render: rowData => <span>{rowData.resourceids}</span> // TODO: check to see if we still need render
                                    },

                                    { title: 'Created Date', field: 'created_date',width:300,
                                        render: rowData => <span>
                                        {rowData.created_date && parseTimeWithUserTimezone(rowData.created_date)}</span>},
                                ]}
                                items={data}
                                options={{
                                    search: true,
                                    showTitle: false
                                }}
                                backGroundColor={"rgba(237, 242, 244, 0.73)"}
                                textColor={"rgba(46, 50, 54, 1)"}
                            />
                        </Grid>
                    </Grid>
                )}
                {userType === 'DME' && (
                    <Grid container className={classes.padding24} justify={'center'}>
                        <Grid container justify={'flex-end'}>
                            <ExportForm
                                fileName='UserCustomAuditReport'
                                headers={headers}
                                excelHeaders={excelHeaders}
                                showPrint={true}
                                getExportValues={getExportValues}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.paddingT24}>
                            <Table
                                search={true}
                                filtering={true}
                                showSearch={true}
                                columns={[
                                    { title: 'Operation', field: 'operation', width:300 },
                                    { title: 'User Id', field: 'user_id', width:300},
                                    { title: 'Action', field: 'action', width:300},
                                    { title: 'View Resources', field: 'resourceids', width:300,
                                        cellStyle: {
                                            width: 300,
                                            maxWidth: 300,
                                            whitespace: "wrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis"
                                        },
                                        render: rowData => <span>{rowData.resourceids}</span>
                                    },
                                    { title: 'Created Date', field: 'created_date',width:300,
                                        render: rowData => <span>
                                        {rowData.created_date && parseTimeWithUserTimezone(rowData.created_date)}</span>},
                                ]}
                                items={data}
                                options={{
                                    search: true,
                                    showTitle: false
                                }}
                                backGroundColor={"rgba(237, 242, 244, 0.73)"}
                                textColor={"rgba(46, 50, 54, 1)"}
                            />
                        </Grid>
                    </Grid>

                )}
                {userType === 'Admin' && (
                    <Grid container className={classes.padding24} justify={"space-between"}>
                        <Grid item xs={12} className={classes.textAlignLeft}>Select Organization</Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable
                                isSearchable
                                value={selectedOrg}
                                name="color"
                                options={sortedList}
                                styles={selectStyles}
                                onChange={(e)=> setSelectedOrg(e)}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12} container justify={"flex-end"} className={classes.paddingR8}>
                        <Grid item styleclassName={classes.paddingL16}>
                            <Button variant={'contained'} color={'primary'} disabled={selectedOrg === null}
                            onClick={()=> {
                                if (reportType === "DME"){
                                    getUsersReport(selectedOrg.value, null)
                                } else {
                                    getUsersReport(null, selectedOrg.value)
                                }
                            }}
                            >Generate Report</Button>
                        </Grid>
                        <Grid item className={classes.paddingL16}>
                            <Button variant={'outlined'} color={'primary'}
                            onClick={()=> {
                                clearData();
                            }}
                            >Clear Filters</Button>
                        </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.paddingT24}>
                            <Grid container justify={'flex-end'}>
                                <ExportForm
                                    fileName='UserCustomAuditReport'
                                    headers={headers}
                                    excelHeaders={excelHeaders}
                                    showPrint={true}
                                    getExportValues={getExportValues}

                                />
                            </Grid>
                            <Grid item xs={12} className={classes.paddingT24}>
                                <Table
                                    search={true}
                                    filtering={true}
                                    showSearch={true}
                                    columns={[
                                        { title: 'Operation', field: 'operation', width:300 },
                                        { title: 'User Id', field: 'user_id', width:300},
                                        { title: 'Action', field: 'action', width:300},
                                        { title: 'View Resources', field: 'resourceids', width:300,
                                            cellStyle: {
                                                width: 300,
                                                maxWidth: 300,
                                                whitespace: "wrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                            },
                                            render: rowData => <span>{rowData.resourceids}</span>
                                        },
                                        { title: 'Created Date', field: 'created_date',width:300,
                                            render: rowData => <span>
                                        {rowData.created_date && parseTimeWithUserTimezone(rowData.created_date)}</span>},
                                    ]}
                                    items={data}
                                    options={{
                                        search: true,
                                        showTitle: false
                                    }}
                                    backGroundColor={"rgba(237, 242, 244, 0.73)"}
                                    textColor={"rgba(46, 50, 54, 1)"}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
    )
}
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    header: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: 24,
        paddingTop: 24,
        paddingLeft: 24
    },
    topContainer: {
        margin: 32,
        padding: 24,
        backgroundColor: 'white',
        borderRadius: 4
    },
    paddingR8: {paddingRight: 8},
    paddingL16: {paddingLeft: 16},
    paddingT24: {paddingTop: 24},
    padding24: {padding: 24},
    textAlignLeft: {textAlign: "left"}
}));
