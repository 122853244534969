import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {placeholders} from "../../../../constants/inputs/paceholders";
import Tooltip from "@material-ui/core/Tooltip";
import {tooltips} from "../../../../constants/inputs/tooltips";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import {labels} from "../../../../constants/inputs/labels";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {organizations} from "../../../../constants/types/role";
import Button from "@material-ui/core/Button";
import {buttonText} from "../../../../constants/alert/alertButtonTexts";
import {inputType} from "../../../../constants/types/inputType";
import InputLabel from "@material-ui/core/InputLabel";
import PhoneInput from "react-phone-input-2";
import FormHelperText from "@material-ui/core/FormHelperText";
import _ from "lodash";
import {checkUsersForThisEmail} from "../../../../common/utils/utils";
import {getMessage} from "../../../../constants/messages/messages";
import {messageTypes} from "../../../../constants/types/messageTypes";
import CreatableSelect from 'react-select/creatable';
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

export const Region = ({
                           handleInput,
                           regionData,
                           regionList,
                           isNewRegion,
                           newRegionTeam,
                           newRegionWarehouse,
                           loading,
                           enableWarehouses,
                           value,
                           setValue
                       }) => {
    const [phone, setPhone] = useState(true);
    const [phone2, setPhone2] = useState(true);
    const [onCallPhone, setOnCallPhone] = useState(true);
    const [showEmailWarning, setShowEmailWarning] = useState(false);
    const [emailFound, setEmailFound] = useState(false);
    return <Grid container spacing={3}>
        <Grid container justify={"flex-start"} direction={"row"} spacing={3}>
            <Grid item md={4} sm={12} xs={12} style={{zIndex: 30, paddingTop: 12}}>
                <CreatableSelect
                    isSearchable
                    name={"regionName"}
                    options={regionList}
                    value={regionData.region_id !== "" ? regionList[_.findIndex(regionList, {"value": regionData.region_id})] : ""}
                    loading={regionList.length === 0}
                    isDisabled={isNewRegion === false}
                    placeholder={placeholders.selectRegion}
                    onChange={(e) => {
                        handleInput({
                            ...e,
                            target: {
                                name: "regionName"
                            },
                            type: "change",
                            inputEventType: inputType.standard
                        });
                    }}
                    onBlur={(e) => {
                        handleInput({
                            ...e,
                            target: {
                                name: "regionName"
                            },
                            inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                        });
                    }}
                />
            </Grid>
            <Grid item xs={3} style={{paddingTop: 0}}>
                <InputLabel style={{fontSize: '0.8em', paddingBottom: '4px', textAlign: 'left'}}>
                    {labels.regionPhoneNumber}
                </InputLabel>
                <PhoneInput
                    name="regionPhoneNumber"
                    label='test'
                    country={'us'}
                    disableCountryCode={true}
                    placeholder={placeholders.phone}
                    onlyCountries={['us']}
                    disabled={loading}
                    value={regionData.region_phone}
                    onChange={(e) => {
                        if (e.length === 10) {
                            setPhone(true)
                        }
                        handleInput({
                            target: {
                                name: "regionPhoneNumber",
                                value: e,
                            },
                            type: "change",
                            inputEventType: inputType.standard
                        });
                    }}
                    inputProps={{
                        label: 'test',
                        required: true,
                        id: 'primary-phone'
                    }}
                    onBlur={(e) => {
                        if (e.target.value.length === 14) {
                            setPhone(true);
                            handleInput({
                                value: e,
                                target: {
                                    name: "regionPhoneNumber",
                                    id: regionData.region_id
                                },
                                type: "blur",
                                inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                            });
                        } else {
                            setPhone(false)
                        }
                    }}
                    isValid={phone}
                />
                <FormHelperText style={{
                    color: 'red',
                    textAlign: 'left'
                }}>{!phone && tooltips.invalidPhone}</FormHelperText>
            </Grid>
        </Grid>
        <Grid container justify={enableWarehouses === true ? "space-evenly" : "flex-start"}>
            <Grid item xs={6} style={{paddingTop: 12, paddingLeft: 12}}>
                <Typography>Teams:</Typography>
                <Tooltip placement="top" title={tooltips.add_team}>
                    <IconButton aria-label="add"
                                name={"addNewTeam"}
                                disabled={loading || newRegionTeam === ""}
                                onClick={e => {
                                    handleInput({
                                        ...e,
                                        target: {name: "addNewTeam"},
                                        inputEventType: isNewRegion ? inputType.standard : inputType.buttonAction
                                    });
                                }}
                    >
                        <AddCircleOutlineIcon name={"addNewTeam"}/>
                    </IconButton>
                </Tooltip>
                <TextField
                    name="newTeam"
                    placeholder={placeholders.add_team}
                    value={newRegionTeam}
                    variant="outlined"
                    disabled={loading}
                    size="small"
                    onChange={e => {
                        handleInput({
                            ...e,
                            inputEventType: inputType.standard
                        });
                    }}
                />
                <IconButton aria-label="delete" name={"clearNewTeam"}
                            onClick={e => {
                                handleInput({
                                    ...e,
                                    target: {name: "clearNewTeam"},
                                    inputEventType: inputType.standard
                                });
                            }}
                >
                    <DeleteIcon/>
                </IconButton>
                {regionData.teams.length > 0 && regionData.teams.map((team, index) => {
                    return <Grid item key={index}
                                 style={{display: 'flex', justifyContent: 'flex-start', paddingLeft: 12}}
                    >

                        <TextField
                            style={{paddingLeft: 12}}
                            name="team"
                            disabled={loading}
                            value={regionData.teams[index].team_name ? regionData.teams[index].team_name : regionData.teams[index]}
                            placeholder={placeholders.add_team}
                            variant="outlined"
                            size="small"
                            onChange={e => {
                                handleInput({
                                    ...e,
                                    position: index,
                                    inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                                });
                            }}
                            onBlur={e => {
                                handleInput({
                                    ...e,
                                    position: index,
                                    inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                                });
                            }}
                        />
                        <IconButton aria-label="delete"
                                    name={"deleteTeam"}
                                    disabled={loading}
                                    onClick={e => {
                                        handleInput({
                                            ...e,
                                            position: index,
                                            target: {name: "deleteTeam"},
                                            inputEventType: isNewRegion === true ? inputType.standard : inputType.buttonAction
                                        });
                                    }}
                        >
                            <DeleteIcon/>
                        </IconButton>
                        <div style={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        }}>
                            {team.team_id ? `ID: ${team.team_id}` : ""}
                        </div>
                    </Grid>
                })}
            </Grid>
            {enableWarehouses === true && <Grid item xs={6} style={{paddingTop: 12}}>
                <Typography>Warehouses:</Typography>
                <Tooltip placement="top" title={tooltips.add_warehouse}>
                    <IconButton name={"addNewWarehouse"}
                                disabled={loading || newRegionWarehouse === ""}
                                onClick={e => {
                                    handleInput({
                                        ...e,
                                        target: {name: "addNewWarehouse"},
                                        inputEventType: isNewRegion === true ? inputType.standard : inputType.buttonAction
                                    });
                                }}
                    >
                        <AddCircleOutlineIcon/>
                    </IconButton>
                </Tooltip>
                <TextField
                    name="newWarehouse"
                    disabled={loading}
                    placeholder={tooltips.add_warehouse}
                    value={newRegionWarehouse}
                    variant="outlined"
                    size="small"
                    onChange={e => {
                        handleInput({
                            ...e,
                            inputEventType: inputType.standard
                        });
                    }}
                />
                <IconButton aria-label="delete" name={"clearNewWarehouse"}
                            onClick={e => {
                                handleInput({
                                    ...e,
                                    target: {name: "clearNewWarehouse"},
                                    inputEventType: inputType.standard
                                });
                            }}
                >
                    <DeleteIcon/>
                </IconButton>
                {regionData.warehouses.length > 0 && regionData.warehouses.map((warehouse, index) => {
                    return <Grid item key={index} style={{display: 'flex'}}>
                        <TextField
                            style={{paddingLeft: 48}}
                            disabled={loading}
                            name="warehouse"
                            placeholder={tooltips.add_warehouse}
                            value={regionData.warehouses[index].name ? regionData.warehouses[index].name : regionData.warehouses[index]}
                            variant="outlined"
                            size="small"
                            onChange={e => {
                                handleInput({
                                    ...e,
                                    position: index,
                                    inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                                });
                            }}
                            onBlur={e => {
                                handleInput({
                                    ...e,
                                    position: index,
                                    inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                                });
                            }}
                        />
                        <IconButton aria-label="delete"
                                    name={"deleteWarehouse"}
                                    disabled={loading}
                                    onClick={e => {
                                        handleInput({
                                            ...e,
                                            position: index,
                                            target: {name: "deleteWarehouse"},
                                            inputEventType: isNewRegion === true ? inputType.standard : inputType.buttonAction
                                        });
                                    }}
                        >
                            <DeleteIcon/>
                        </IconButton>
                        <div style={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                        }}>
                            {regionData.warehouses[index].warehouse_id ? `ID: ${regionData.warehouses[index].warehouse_id}` : ""}
                        </div>
                    </Grid>
                })}
            </Grid>}
        </Grid>
        <Grid container justify={"flex-start"} alignItems={"flex-start"} spacing={3}
              style={{paddingLeft: 10, paddingTop: 12}}>
            <Grid item xs={12} style={{display: "flex", paddingBottom: 0}}>
                <Typography style={{display: "flex", fontSize: 20}}>
                    {labels.region_address}
                </Typography>
            </Grid>
            <Grid item xs={5} style={{paddingTop: 4}}>
                <TextField
                    name="region_address1"
                    disabled={loading}
                    label={labels.address1}
                    fullWidth
                    placeholder={placeholders.addRegionAddress}
                    value={regionData.address_region[0].address1}
                    variant="outlined"
                    size="small"
                    onChange={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: inputType.standard
                        });
                    }}
                    onBlur={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                        });
                    }}
                />
            </Grid>
            <Grid item xs={5} style={{paddingTop: 4}}>
                <TextField
                    name="region_address2"
                    disabled={loading}
                    fullWidth
                    label={labels.address2}
                    placeholder={placeholders.addRegionAddress}
                    value={regionData.address_region[0].address2}
                    variant="outlined"
                    size="small"
                    onChange={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: inputType.standard
                        });
                    }}
                    onBlur={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                        });
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    name="region_city"
                    disabled={loading}
                    label={labels.city}
                    fullWidth
                    placeholder={placeholders.addRegionCity}
                    value={regionData.address_region[0].city}
                    variant="outlined"
                    size="small"
                    onChange={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: inputType.standard
                        });
                    }}
                    onBlur={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                        });
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    name="region_state"
                    disabled={loading}
                    label={labels.state}
                    fullWidth
                    placeholder={placeholders.addRegionState}
                    value={regionData.address_region[0].state}
                    variant="outlined"
                    size="small"
                    onChange={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: inputType.standard
                        });
                    }}
                    onBlur={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                        });
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    name="region_zip"
                    disabled={loading}
                    label={labels.zip}
                    placeholder={placeholders.addRegionZip}
                    value={regionData.address_region[0].zip}
                    variant="outlined"
                    size="small"
                    onChange={e => {
                        if (e.target.value.length < 6) {
                            handleInput({
                                ...e,
                                position: 0,
                                inputEventType: inputType.standard
                            });
                        }
                    }}
                    onBlur={e => {
                        if (e.target.value.length < 6) {
                            handleInput({
                                ...e,
                                position: 0,
                                inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                            });
                        }
                    }}
                />
                <Tooltip title={tooltips.zip_autocomplete}
                         arrow
                         placement="top-end">
                    <InfoOutlinedIcon style={{marginTop: '8px', paddingLeft: '8px'}}/>
                </Tooltip>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} style={{display: "flex", paddingBottom: 0}}>
                <Typography style={{display: "flex", fontSize: 20}}>
                    Region Billing Contact
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    name="billingFirstName"
                    disabled={loading}
                    label={labels.first_name}
                    fullWidth
                    placeholder={placeholders.first_name}
                    value={regionData.billing_contact_region[0].first_name}
                    variant="outlined"
                    size="small"
                    onChange={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                        });
                    }}
                    onBlur={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                        });
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    name="billingLastName"
                    disabled={loading}
                    label={labels.last_name}
                    fullWidth
                    placeholder={placeholders.last_name}
                    value={regionData.billing_contact_region[0].last_name}
                    variant="outlined"
                    size="small"
                    onChange={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                        });
                    }}
                    onBlur={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                        });
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    name="billingEmail"
                    disabled={loading}
                    fullWidth
                    label={labels.email}
                    placeholder={placeholders.email}
                    value={regionData.billing_contact_region[0].email}
                    variant="outlined"
                    size="small"
                    onChange={e => {
                        handleInput({
                            ...e,
                            position: 0,
                            inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                        });
                    }}
                    onBlur={(event) => {
                        if (event.target.value === "") {
                            setShowEmailWarning(false);
                        } else {
                            setShowEmailWarning(true);
                        }
                        let result;
                        (async () => {
                            if (event.target.value !== "") {
                                result = await checkUsersForThisEmail(event.target.value, organizations.dme.role.manager, organizations.dme.type)
                                setEmailFound(result);
                            }
                        })()
                        handleInput({
                            ...event,
                            position: 0,
                            inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                        });
                    }}
                />
                {showEmailWarning && (emailFound ? <FormHelperText
                        style={{color: 'green', textAlign: 'left'}}
                    >
                        {getMessage(messageTypes.emailFound)}
                    </FormHelperText> :
                    <FormHelperText
                        style={{color: 'red', textAlign: 'left'}}
                    >
                        {getMessage(messageTypes.emailNotFound)}
                    </FormHelperText>)}
            </Grid>
            <Grid item sm={4}>
                <InputLabel style={{fontSize: '0.8em', paddingBottom: '4px', textAlign: 'left'}}>
                    {labels.phone}
                </InputLabel>
                <PhoneInput
                    name={"billingRegionPhone"}
                    label={labels.phone}
                    country={'us'}
                    disabled={loading}
                    disableCountryCode={true}
                    onlyCountries={['us']}
                    value={regionData.billing_contact_region[0].phone}
                    placeholder={placeholders.phone}
                    onChange={(e) => {
                        if (e.length === 10) {
                            setPhone(true)
                        }
                        handleInput({
                            target: {
                                name: "billingRegionPhone",
                                value: e,
                            },
                            position: 0,
                            type: "change",
                            inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                        })
                    }
                    }
                    inputProps={{
                        label: 'test',
                        required: true,
                        id: 'region-billing-phone'
                    }}
                    onBlur={(e) => {
                        if (e.target.value.length === 14) {
                            setPhone2(true);
                            handleInput({
                                value: e,
                                target: {
                                    name: "billingRegionPhone",
                                    id: regionData.region_id
                                },
                                type: "blur",
                                inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                            });
                        } else {
                            setPhone2(false)
                        }
                    }}
                    isValid={phone2}
                />
            </Grid>
        </Grid>
        {enableWarehouses === true && (
            <Grid container spacing={3}>
                <Grid item xs={12} style={{display: "flex", paddingBottom: 0}}>
                    <Typography style={{display: "flex", fontSize: 20}}>
                        On Call Information
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                            <KeyboardTimePicker
                                margin="dense"
                                id="region-start-hour"
                                label="Start Hour:"
                                value={regionData.onCallStart === "" ? null : regionData.onCallStart}
                                onChange={(e) => {
                                    handleInput({
                                        target: {
                                            name: "onCallStart",
                                            value: e,
                                        },
                                        type: "change",
                                        inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                                    })
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                            <KeyboardTimePicker
                                margin="dense"
                                id="region-end-hour"
                                label="End Hour:"
                                value={regionData.onCallEnd === "" ? null : regionData.onCallEnd}
                                onChange={(e) => {
                                    handleInput({
                                        target: {
                                            name: "onCallEnd",
                                            value: e,
                                        },
                                        type: "change",
                                        inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                                    })
                                }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item sm={4}>
                    <InputLabel style={{fontSize: '0.8em', paddingBottom: '4px', textAlign: 'left'}}>
                        {labels.phone}
                    </InputLabel>
                    <PhoneInput
                        name={"onCallPhone"}
                        label={labels.phone}
                        country={'us'}
                        disabled={loading}
                        disableCountryCode={true}
                        onlyCountries={['us']}
                        value={regionData.onCallPhone}
                        placeholder={placeholders.phone}
                        onChange={(e) => {
                            if (e.length === 10) {
                                setPhone(true)
                            }
                            handleInput({
                                target: {
                                    name: "onCallPhone",
                                    value: e,
                                },
                                type: "change",
                                inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                            })
                        }
                        }
                        inputProps={{
                            label: 'test',
                            required: true,
                            id: 'on-call-phone'
                        }}
                        onBlur={(e) => {
                            if (e.target.value.length === 14 || e.target.value.length === 0) {
                                setOnCallPhone(true);
                                handleInput({
                                    value: e,
                                    target: {
                                        name: "onCallPhone",
                                        id: regionData.region_id
                                    },
                                    type: "blur",
                                    inputEventType: isNewRegion === true ? inputType.standard : inputType.editOnBlur
                                });
                            } else {
                                setOnCallPhone(false)
                            }
                        }}
                        isValid={onCallPhone}
                    />
                </Grid>
            </Grid>
        )}

        {isNewRegion &&
        <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end", alignItems: "flex-end"}}>
            <Button color={"primary"}
                    variant={"contained"}
                    onClick={e => {
                        handleInput({
                            ...e,
                            target: {name: "saveRegion"},
                            inputEventType: inputType.buttonAction
                        });
                    }}>
                {buttonText.saveNewRegion}
            </Button>
        </Grid>}
    </Grid>
}
