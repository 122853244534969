import {Cell} from "recharts";
import React from "react";
import {itemTypes} from "../../../constants/types/ItemTypes";

export const graphColorSwitch = (currentEntry, index, highlightColor, defaultColor) => {
    switch (currentEntry.type){
        case itemTypes.oof:
        case itemTypes.pi:
        case itemTypes.lf:
            return <Cell key={`cell-${index}`} fill={highlightColor}/>
        default:
            return <Cell key={`cell-${index}`} fill={defaultColor}/>

    }
}

export const graphBarColors = {
    lowOofItem: "#148703",
    topOofItem: "#a60202",
    default: "#8884d8"
}