import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CustomizedSlider from "../slider/slider";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from "@material-ui/core/FormHelperText";
import {getMessage} from "../../../../../../constants/messages/messages";
import {messageTypes} from "../../../../../../constants/types/messageTypes";
import {labels} from "../../../../../../constants/inputs/labels";
import {endpoints} from "../../../../../../constants/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";
import _ from "lodash";
import {maxInputLength} from "../../../../../../constants/inputs/values";

export const SelectItemModal = (props) => {
    const classes = useStyles();
    const [clinicalSettings, setClinicalSettings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [humidifier, setHumidifier] = useState(0);
    const {user, onClose, open, item, order, setOrder, itemValues} = props;
    const [weight, setWeight] = useState(user.patient_weight);
    const [continuous, setContinuous] = useState(false);
    const [deliveryMethod, setDeliveryMethod] = useState('Nasal Cannula');
    const [qty, setQty] = useState(1);
    const [invalid, setInvalid] = useState(true);
    const [border, setBorder] = useState('');

    useEffect(() => {
        props.setItemValues({...props.itemValues, quantity: qty})
    }, [qty]);

    useEffect(() => {
        let weight = false;
        if (clinicalSettings.length !== 0) {
            clinicalSettings.map(setting => {
                    if (setting.weight === true) {
                        weight = true;
                    }
                }
            )
        }
        let itemValuesClone = _.cloneDeep(props.itemValues);
        if (weight === true) {
            itemValuesClone.weight = user.patient_weight
        }
        if (clinicalSettings.some(item => item.liters_per_minute === true)) {
            itemValuesClone.liters_per_minute = true;
        }
        props.setItemValues(itemValuesClone);
    }, [clinicalSettings]);


    const handleClose = () => {
        onClose();
    };

    const getClinicalSettings = async () => {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(`${endpoints.masterItemsClinicalSettingsById}${item.item_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        setClinicalSettings(response.data);
        setLoading(false);
    };

    useEffect(() => {
        if (open && clinicalSettings.length === 0) {
            getClinicalSettings()
        }
    }, [open]);

    function checkValid(clinicalSettings) {
        if (
            (clinicalSettings.some(item => item.service_note === true) && itemValues.special_item_info?.service_note_text?.length === 0) ||
            (clinicalSettings.some(item => item.iscontinuous === true) && itemValues.special_item_info?.service_note_text?.isContinuous) ||
            (clinicalSettings.some(item => item.delivery_method === true) && itemValues.special_item_info?.delivery_method === null)
        ) {
            setInvalid(true)
        } else {
            setInvalid(false)
        }
    }

    function checkDeliveryMethod(clinicalSettings) {
        if (clinicalSettings.some(item => item.delivery_method === true) && itemValues.special_item_info?.delivery_method === null)
            setBorder('1px solid red');
        else {
            setBorder('');
        }
    }

    useEffect(() => {
        checkValid(clinicalSettings);
        checkDeliveryMethod(clinicalSettings);
    }, [itemValues, item]);

    return (
        <Dialog
            onClose={handleClose}
            open={open}>
            <DialogTitle>
                {getMessage(messageTypes.addToCart, props.item.name)}
            </DialogTitle>
            <div className={classes.dialogContainer}>
                {
                    loading ? (
                        <div style={{
                            padding: 36,
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center'
                        }}>
                            <CircularProgress/>
                        </div>
                    ) : (
                        <React.Fragment>
                            <div>
                                {props.item.image === '.jpeg' ? (
                                    <img src={`${process.env.REACT_APP_BUCKET_URL}placeholder.png`} height="180"/>
                                ) : (
                                    <img src={`${process.env.REACT_APP_BUCKET_URL}${item.image}`} height="180"/>
                                )}
                            </div>
                            <h3 style={{margin: 8}}>{item.name}</h3>
                            <div className={classes.description}>
                                {item.description}
                            </div>
                            {clinicalSettings.length !== 0 && clinicalSettings.map(setting => {
                                return (
                                    <React.Fragment>
                                        {
                                            setting.air_psi === true && (
                                                <React.Fragment>
                                                    <div className={classes.range}>
                                                        <h4 className={classes.rangeLabel}>{labels.oxygen_select}</h4>
                                                    </div>
                                                    {setting.min_range !== null && (
                                                        <CustomizedSlider
                                                            min_range={setting.min_range ? parseInt(setting.min_range) : 0}
                                                            max_range={setting.max_range ? parseInt(setting.max_range) : 0}
                                                            itemValues={props.itemValues}
                                                            setItemValues={props.setItemValues}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            setting.liters_per_minute === true && (
                                                <React.Fragment>
                                                    <div className={classes.range}>
                                                        <h4 className={classes.rangeLabel}>{labels.liters_per_minute_select}</h4>
                                                    </div>
                                                    {setting.min_range !== null && (
                                                        <CustomizedSlider
                                                            min_range={setting.min_range ? parseInt(setting.min_range) : 0}
                                                            max_range={setting.max_range ? parseInt(setting.max_range) : 0}
                                                            itemValues={props.itemValues}
                                                            setItemValues={props.setItemValues}
                                                        />
                                                    )}
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            setting.iscontinuous === true && (
                                                <React.Fragment>
                                                    <div style={{
                                                        paddingLeft: 48,
                                                        paddingTop: 12,
                                                        display: 'flex',
                                                        width: '100%',
                                                        flexDirection: 'row',
                                                        alignContent: 'center',
                                                        alignItems: 'center'
                                                    }}>
                                                        <h4 style={{
                                                            marginBottom: 8,
                                                            marginTop: 8,
                                                            paddingRight: 8
                                                        }}>{labels.continuous_oxygen_therapy}</h4>
                                                        <RadioGroup
                                                            row
                                                            aria-label="position"
                                                            name="continuous"
                                                            defaultValue={props.itemValues.special_item_info && props.itemValues.special_item_info.isContinuous}

                                                        >
                                                            <FormControlLabel
                                                                checked={props.itemValues.special_item_info && props.itemValues.special_item_info.isContinuous}
                                                                value={true}
                                                                control={<Radio color="primary"/>}
                                                                label="Continous"
                                                                labelPlacement="end"
                                                                onChange={(e) => {
                                                                    setContinuous(!continuous);
                                                                    let obj = {...props.itemValues.special_item_info};
                                                                    obj = {...obj, isContinuous: true};
                                                                    props.setItemValues({
                                                                        ...props.itemValues,
                                                                        special_item_info: obj
                                                                    })
                                                                }}
                                                            />
                                                            <FormControlLabel
                                                                checked={props.itemValues.special_item_info && !props.itemValues.special_item_info.isContinuous}
                                                                value={false}
                                                                control={<Radio color="primary"/>}
                                                                label="PRN"
                                                                labelPlacement="end"
                                                                onChange={(e) => {
                                                                    setContinuous(!continuous);
                                                                    let obj = {...props.itemValues.special_item_info};
                                                                    obj = {...obj, isContinuous: false};
                                                                    props.setItemValues({
                                                                        ...props.itemValues,
                                                                        special_item_info: obj
                                                                    })
                                                                }}
                                                            />
                                                        </RadioGroup>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            setting.delivery_method === true && (
                                                <React.Fragment>
                                                    <div style={{
                                                        paddingLeft: 48,
                                                        paddingTop: 7,
                                                        marginTop: 10,
                                                        display: 'flex',
                                                        width: '100%',
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        alignContent: 'center',
                                                        border: border
                                                    }}>
                                                        <h4 style={{
                                                            marginBottom: 8,
                                                            marginTop: 8,
                                                            paddingRight: 8
                                                        }}>{labels.delivery_method}</h4>
                                                        <div>
                                                            <RadioGroup
                                                                row
                                                                aria-label="position"
                                                                name="continuous"
                                                                defaultValue={props.itemValues.special_item_info && props.itemValues.special_item_info.delivery_method}
                                                            >
                                                                <FormControlLabel
                                                                    checked={props.itemValues.special_item_info && props.itemValues.special_item_info.delivery_method === "Nasal Cannula"}
                                                                    value={true}
                                                                    control={<Radio color="primary"/>}
                                                                    label="Nasal Cannula"
                                                                    labelPlacement="end"
                                                                    onChange={(e) => {
                                                                        setDeliveryMethod(!deliveryMethod);
                                                                        let obj = {...props.itemValues.special_item_info};
                                                                        obj = {
                                                                            ...obj,
                                                                            delivery_method: "Nasal Cannula"
                                                                        };
                                                                        props.setItemValues({
                                                                            ...props.itemValues,
                                                                            special_item_info: obj
                                                                        })
                                                                    }}
                                                                />
                                                                <FormControlLabel
                                                                    checked={props.itemValues.special_item_info && props.itemValues.special_item_info.delivery_method === 'Mask'}
                                                                    value={false}
                                                                    control={<Radio color="primary"/>}
                                                                    label="Mask"
                                                                    labelPlacement="end"
                                                                    onChange={(e) => {
                                                                        setDeliveryMethod(!deliveryMethod);
                                                                        let obj = {...props.itemValues.special_item_info};
                                                                        obj = {...obj, delivery_method: 'Mask'};
                                                                        props.setItemValues({
                                                                            ...props.itemValues,
                                                                            special_item_info: obj
                                                                        })
                                                                    }}
                                                                />
                                                            </RadioGroup>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            setting.humidifier_for_compressor === true && (
                                                <React.Fragment>
                                                    <div style={{
                                                        paddingLeft: 48,
                                                        display: 'flex',
                                                        width: '100%',
                                                        alignItems: 'center',
                                                        alignContent: 'center'
                                                    }}>


                                                        <h4 className={classes.humidifierTitle}
                                                            style={{marginBottom: 8, marginTop: 8, paddingRight: 8}}>
                                                            {labels.humidifier_bottle}
                                                        </h4>
                                                        <div className={classes.humidifierInput}>
                                                            <TextField
                                                                required
                                                                value={humidifier}
                                                                inputProps={{
                                                                    min: 0,
                                                                    style: {textAlign: 'center'}
                                                                }}
                                                                variant="outlined"
                                                                size={'small'}
                                                                onChange={(e) => {
                                                                    let obj = {...props.itemValues.special_item_info};
                                                                    obj = {
                                                                        ...obj,
                                                                        humidifier_for_compressor: Number(e.target.value)
                                                                    };
                                                                    props.setItemValues({
                                                                        ...props.itemValues,
                                                                        special_item_info: obj
                                                                    });
                                                                    setHumidifier(Number(e.target.value));
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )

                                        }
                                        {
                                            setting.weight === true && (
                                                <React.Fragment>
                                                    <div className={classes.box}>
                                                        <h4 style={{marginBottom: 8, marginTop: 8, paddingRight: 8}}>
                                                            {labels.patientWeight}
                                                        </h4>
                                                        <div style={{width: 70}}>
                                                            <TextField
                                                                value={weight}
                                                                defaultValue={user.patient_weight}
                                                                inputProps={{min: 0, style: {textAlign: 'center'}}}
                                                                variant="outlined"
                                                                size={'small'}
                                                                onChange={(e) => {
                                                                    let obj = {...props.itemValues.special_item_info};
                                                                    obj = {...obj, weight: Number(e.target.value)};
                                                                    props.setItemValues({
                                                                        ...props.itemValues,
                                                                        special_item_info: obj
                                                                    });
                                                                    setWeight(Number(e.target.value))
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )

                                        }
                                        {
                                            setting.size_lift === true && (
                                                <React.Fragment>
                                                    <div className={classes.box}>
                                                        <h4 style={{marginBottom: 8, marginTop: 8, paddingRight: 8}}>
                                                            {labels.lift_size}
                                                        </h4>
                                                        <div style={{width: 300, display: 'flex'}}>
                                                            <RadioGroup
                                                                row
                                                                aria-label="position"
                                                                name="position"
                                                                defaultValue="Medium"
                                                                onChange={(e) => {
                                                                    let obj = {...props.itemValues.special_item_info};
                                                                    obj = {...obj, size_lift: e.target.value};
                                                                    props.setItemValues({
                                                                        ...props.itemValues,
                                                                        special_item_info: obj
                                                                    })
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    value="Medium"
                                                                    control={<Radio color="primary"/>}
                                                                    label="Medium"
                                                                    labelPlacement="end"
                                                                />
                                                                <FormControlLabel
                                                                    value="Large"
                                                                    control={<Radio color="primary"/>}
                                                                    label="Large"
                                                                    labelPlacement="end"
                                                                />
                                                                <FormControlLabel
                                                                    value="X-Large"
                                                                    control={<Radio color="primary"/>}
                                                                    label="X-Large"
                                                                    labelPlacement="end"
                                                                />
                                                            </RadioGroup>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )

                                        }
                                        {
                                            setting.service_note === true && (
                                                <React.Fragment>
                                                    <Grid container
                                                          style={{padding: 42, paddingTop: 24, paddingBottom: 16}}>
                                                        <h4 style={{
                                                            marginBottom: 8,
                                                            marginTop: 8,
                                                            paddingRight: 8
                                                        }}>{labels.serviceNote}</h4>
                                                        <TextField
                                                            inputProps={{maxLength: maxInputLength.L255}}
                                                            helperText={itemValues.special_item_info?.service_note_text && `${itemValues.special_item_info?.service_note_text.length < maxInputLength.L255 ? itemValues.special_item_info?.service_note_text.length.toString() + `/${maxInputLength.L255}` : itemValues.special_item_info?.service_note_text.length.toString() + `/${maxInputLength.L255} You have reached the character limit.`}`}
                                                            error={itemValues.special_item_info?.service_note_text?.length === 0 || itemValues.special_item_info?.service_note_text?.length === maxInputLength.L255}
                                                            required
                                                            value={props.itemValues.special_item_info.service_note_text}
                                                            placeholder={'Enter service note...'}
                                                            variant="outlined"
                                                            fullWidth
                                                            multiline
                                                            rows={3}
                                                            size={'small'}
                                                            onChange={(e) => {
                                                                let obj = {...props.itemValues.special_item_info};
                                                                obj = {...obj, service_note_text: e.target.value};
                                                                if (e.target.value !== "") {
                                                                    obj = {...obj, service_note: true}
                                                                } else {
                                                                    obj = {...obj, service_note: false}
                                                                }
                                                                props.setItemValues({
                                                                    ...props.itemValues,
                                                                    special_item_info: obj
                                                                })
                                                            }}
                                                        />
                                                    </Grid>
                                                </React.Fragment>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            })}
                            <Grid container className={classes.quantityContainer}>
                                <div className={classes.quantity}>
                                    <IconButton
                                        onClick={() => {
                                            if (qty > 1) {
                                                setQty(qty - 1)
                                            }
                                        }}
                                    >
                                        <RemoveCircleOutlineIcon/>
                                    </IconButton>
                                    <div style={{width: '70px'}}>
                                        <TextField
                                            disabled
                                            id="item_qty"
                                            label="Qty"
                                            value={qty}
                                            inputProps={{min: 0, style: {textAlign: 'center'}}}
                                            variant="outlined"
                                            size={'small'}
                                            onChange={(e) => setQty(e.target.value)}
                                        />
                                    </div>
                                    <IconButton
                                        disabled={itemValues.special_item_info && qty === itemValues.special_item_info.max_quantity_per_item}
                                        onClick={() => {
                                            if (qty <= itemValues.special_item_info.max_quantity_per_item) {
                                                setQty(qty + 1)
                                            }
                                        }}
                                    >
                                        <AddCircleOutlineIcon/>
                                    </IconButton>
                                </div>
                                <FormHelperText
                                    style={{color: 'red', textAlign: 'left', paddingLeft: 10}}
                                >
                                    {(itemValues.special_item_info && qty === itemValues.special_item_info.max_quantity_per_item) && (
                                        `Max. quantity reached`)
                                    }
                                </FormHelperText>
                            </Grid>
                        </React.Fragment>
                    )
                }
                <Grid container justify={'flex-end'}>
                    <div style={{display: 'flex', padding: '20px'}}>
                        <Button variant="outlined" color="black"
                                onClick={handleClose}
                                style={{
                                    marginTop: '24px',
                                    marginLeft: '8px',
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold'
                                }}
                        >
                            Cancel
                        </Button>
                        {checkValid}
                        {checkDeliveryMethod}
                        <Button variant="contained" color="primary"
                                disabled={loading || invalid}
                                onClick={() => {
                                    if (props.handleInput) {
                                        let itemValuesClone = {...itemValues};
                                        let single_item = true;
                                        clinicalSettings.map(setting => {
                                            if (setting.main_title === "Single Item") {
                                                single_item = setting.single_item
                                            }
                                        });
                                        itemValuesClone.single_item = single_item;
                                        const obj = {
                                            target: {
                                                itemValues: itemValuesClone,
                                                item: item,
                                                name: "addItem",
                                            }
                                        };
                                        props.handleInput(obj);
                                    } else {
                                        const values = {...order};
                                        let obj = {...itemValues};
                                        if (itemValues.approval) {
                                            values.approval = true;
                                        }
                                        let single_item = true;
                                        clinicalSettings.map(setting => {
                                            if (setting.main_title === "Single Item") {
                                                single_item = setting.single_item
                                            }
                                        });
                                        obj.single_item = single_item;
                                        values.items.push(obj);
                                        setOrder(values);
                                    }
                                    handleClose();
                                }}
                                style={{
                                    marginTop: '24px',
                                    marginLeft: '8px',
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold'
                                }}
                        >
                            Add to order
                        </Button>
                    </div>
                </Grid>
            </div>
        </Dialog>
    );
};

SelectItemModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};
const useStyles = makeStyles({
    dialogContainer: {
        minWidth: 320,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    description: {
        width: '75%',
        textAlign: 'justify',
        color: '#7E7D7D',
    },
    range: {
        paddingTop: '8px',
        width: '100%',
    },
    rangeLabel: {
        paddingLeft: 48,
        paddingBottom: '30px',
        margin: 0
    },
    selectors: {
        paddingLeft: '10%',
    },
    box: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        alignContent: 'center',
        paddingLeft: 48,
        paddingTop: 12
    },
    humidifierInput: {
        width: 50,
    },
    quantityContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '10%',
        paddingTop: '20px',
    },
    quantity: {
        display: "flex",
        alignItems: 'center',
    },
});
