import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import {tooltips} from "../../../../constants/inputs/tooltips";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {endpoints} from "../../../../constants/endpoints";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";

export default function DeletePatient({rowData}) {
    const dispatch = useDispatch();
    const history=useHistory();
    const [openModal, setOpenModal] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const deletePatient = () => {
        setOpenModal(false);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getPatientData}${rowData.patient_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                if (response.status === 204) {
                    enqueueSnackbar('Patient deleted with success', {
                        variant: 'success',
                        autoHideDuration: 4000,
                        anchorOrigin: {vertical: "bottom", horizontal: "left"}
                    });

                }
                history.push('/refresh/patients/all');
            })
            .catch(err => {
                enqueueSnackbar('Unable to delete patient', {
                    variant: 'error',
                    autoHideDuration: 4000,
                    anchorOrigin: {vertical: "bottom", horizontal: "left"}
                });
                console.log('error', err)
            })
    };
    return (
        <React.Fragment>
            <Tooltip title={tooltips.deletePatient}>
                <IconButton onClick={()=> setOpenModal(true)}>
                    <DeleteForeverIcon color={'primary'} style={{color: "rgba(0,34,61,0.75)",}}/>
                </IconButton>
            </Tooltip>
            <Dialog open={openModal} onClose={()=> setOpenModal(false)}>
                <DialogTitle>
                    Warning!
                </DialogTitle>
                <DialogContent>
                    <Grid conatainer>
                        <Typography style={{fontWeight: 800, color:'red', padding:16}}>Are you sure that you want to delete this patient? Order history will remain available, but you will not be able to find any data related to this patient</Typography>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justify={'flex-end'} spacing={2}>
                        <Grid item>
                            <Button variant={'outlined'} color={'primary'} style={{fontWeight: 800}} onClick={()=>setOpenModal(false)}>No, return</Button>
                        </Grid>
                       <Grid item>
                           <Button variant={'contained'} color={'secondary'} style={{fontWeight: 800}} onClick={()=> deletePatient()}>Yes</Button>
                       </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
