import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import {endpoints} from "../../../../../constants/endpoints";
import {checkTokenAvailability, getTokenInformation} from "../../../../../common/utils/utils";
import {logout} from "../../../../../redux/actions";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import moment from "moment";
import {buttonText} from "../../../../../constants/alert/alertButtonTexts";
import {months, utilsTokenIdentifiers} from "../../../../../constants/inputs/values";
import {useSnackbar} from "notistack";
import {snackbarMessages} from "../../../../../constants/alert/alertMessages";
import {findIndex} from "lodash";
import {validators} from "../../../../../constants/validations/validators";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        padding: 16,
    }
}));

export default function PatientDays(props) {
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const classes = useStyles();
    const [yearsLookup, setYearsLookup] = useState({});
    const [monthLookup, setMonthLookup] = useState({});
    const [hospiceDaysData, setHospiceDaysData] = useState([]);
    const getHospiceDays = async () => {
        try {
            const hospiceDaysCall = await fetch(`${endpoints.hospiceDays}${props.hospiceDetails.hospice_organization_id}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                },
            })
            const hospiceDaysResponse = await hospiceDaysCall.json();
            hospiceDaysResponse.status === "success" && setHospiceDaysData(hospiceDaysResponse.data);
        } catch (err) {
            enqueueSnackbar(snackbarMessages.hospiceInformationRetrievalError, {variant: "warning"});
        } finally {
            setIsLoading(false);
        }

    };
    useEffect(() => {
        getHospiceDays();
        const startDate = moment("01-01-2020");
        const currentDate = moment();
        let yearObj = {};
        while (startDate < currentDate) {
            yearObj[Number(startDate.get("year"))] = startDate.get("year");
            startDate.add(1, "year");
        }
        let monthObj = {};
        months.map(month => {
            monthObj = {...monthObj, [month]: month}
        })
        setMonthLookup(monthObj);
        setYearsLookup(yearObj);
    }, []);

    const columnsTable = [
        {
            title: 'Hospice Name',
            field: 'hospiceName',
            grouping: false,
            editable: 'never',
            render: rowData => props.hospiceDetails.hospice_name ? props.hospiceDetails.hospice_name : ""
        },
        {
            title: 'Address',
            field: 'address',
            grouping: false,
            editable: 'never',
            render: rowData => `${props.hospiceDetails.address1} ${props.hospiceDetails.address2}\n ${props.hospiceDetails.city}, ${props.hospiceDetails.state} ${props.hospiceDetails.zip}`
        },
        {
            title: 'Month', field: 'month',
            lookup: monthLookup,
        },
        {title: 'Year', field: 'year', lookup: yearsLookup},
        {
            title: 'Patient Days',
            field: 'days',
            type: 'numeric',
            grouping: false,
        },
    ]

    return (
        <Grid container className={classes.mainContainer}>
                <Grid item xs={12}>
                    <MaterialTable
                        title="Patient Days"
                        columns={columnsTable}
                        editable={{
                            onRowAdd: newData =>
                                new Promise(async (resolve, reject) => {
                                    setIsLoading(true);
                                    if (findIndex(hospiceDaysData, {month: newData.month, year: newData.year}) > -1) {
                                        enqueueSnackbar(snackbarMessages.resourceAlreadyExists, {variant: "info"});
                                        return reject("Entry already exists for entered data");
                                    }
                                    if (!newData.year) {
                                        enqueueSnackbar("Missing year. Clear grouping and add year.", {variant: "info"});
                                        return reject("Missing year. Clear grouping and add year.");
                                    }
                                    if (!validators.digitValidator.test(newData.days)) {
                                        enqueueSnackbar("Days value must be a number.", {variant: "info"});
                                        return reject("Days value must be a number.");
                                    }
                                    try {
                                        if (newData.days) {
                                            const createDaysEntryCall = await fetch(`${endpoints.hospiceDays}`, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                                                },
                                                body: JSON.stringify({
                                                    hospice_organization_id: props.hospiceDetails.hospice_organization_id,
                                                    edit: false,
                                                    month: newData.month,
                                                    year: newData.year,
                                                    days: Number(newData.days)
                                                })
                                            });
                                            const createDaysEntryData = await createDaysEntryCall.json();
                                            createDaysEntryData.status === "successful" && enqueueSnackbar(snackbarMessages.resourceCreatedSuccessfully, {variant: "success"});
                                            createDaysEntryData.status === "error" && enqueueSnackbar(snackbarMessages.generalUnsuccessfulStatus, {variant: "warning"});
                                            await getHospiceDays();
                                            resolve()
                                        } else {
                                            enqueueSnackbar("Missing days value", {variant: "info"});
                                            reject("Missing days value.");
                                        }
                                    } catch (err) {
                                        enqueueSnackbar(snackbarMessages.generalUnsuccessfulStatus, {variant: "warning"});
                                        reject();
                                    } finally {
                                        setIsLoading(false);
                                    }
                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise(async (resolve, reject) => {
                                    if (findIndex(oldData, {month: newData.month, year: newData.year}) > -1) {
                                        enqueueSnackbar(snackbarMessages.resourceAlreadyExists, {variant: "info"});
                                        return reject("Entry already exists for entered data");
                                    }
                                    if (!newData.year) {
                                        enqueueSnackbar("Missing year. Clear grouping and add year.", {variant: "info"});
                                        return reject("Missing year. Clear grouping and add year.");
                                    }
                                    if (!validators.digitValidator.test(newData.days)) {
                                        enqueueSnackbar("Days value must be a number.", {variant: "info"});
                                        return reject("Days value must be a number.");
                                    }
                                    setIsLoading(true);
                                    try {
                                        if (newData.days) {
                                            const createDaysEntryCall = await fetch(`${endpoints.hospiceDays}`, {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                    'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                                                },
                                                body: JSON.stringify({
                                                    hospice_organization_id: props.hospiceDetails.hospice_organization_id,
                                                    edit: true,
                                                    month: newData.month,
                                                    year: newData.year,
                                                    days: Number(newData.days)
                                                })
                                            });
                                            const createDaysEntryData = await createDaysEntryCall.json();
                                            createDaysEntryData.status === "successful" && enqueueSnackbar(snackbarMessages.resourceUpdatedSuccessfully, {variant: "success"}) && resolve(snackbarMessages.resourceUpdatedSuccessfully);
                                            createDaysEntryData.status === "error" && enqueueSnackbar(snackbarMessages.generalUnsuccessfulStatus, {variant: "warning"}) && resolve(snackbarMessages.generalUnsuccessfulStatus);
                                            await getHospiceDays();
                                            resolve();
                                        } else {
                                            enqueueSnackbar("Missing days value", {variant: "info"});
                                            reject("Missing days value.");
                                        }
                                    } catch (err) {
                                        reject();
                                        enqueueSnackbar(snackbarMessages.generalUnsuccessfulStatus, {variant: "warning"});
                                    } finally {
                                        setIsLoading(false);
                                    }
                                }),
                        }}
                        data={hospiceDaysData}
                        options={{
                            grouping: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 25, {label: 'All', value: hospiceDaysData ? hospiceDaysData.length : 0}]
                        }}
                    />
                </Grid>
                <Grid item container xs={12} justify={"flex-end"} alignItems={"flex-end"}>
                    <Grid item>
                        <Button
                            variant={"contained"}
                            color="primary"
                            onClick={() => props.setOpen(false)}
                            disabled={isLoading}
                            className={classes.button}
                        >
                            {buttonText.close}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
    )
}