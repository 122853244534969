import React from "react";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {sectionTitles} from "../../../constants/names/titles";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {ForgotEmailComponent} from "./components/ForgotEmail";
import {ForgotPasswordComponent} from "./components/ForgotPassword";
import PoweredLogo from "../../../assets/powered_by_capstone@2x.png";
import {makeStyles} from "@material-ui/core/styles";
import {forgotStyle} from "./components/forgotStyle";
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";

export const ForgotCredentials = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState('email');
    props.setShowNav(false);
    const history = useHistory();

    const handleChange = (event) => {
        setActiveStep(event.target.value);
    };

    const getStepContent = (step) => {
        switch (step) {
            case "email":
                return <ForgotEmailComponent/>;
            case "password":
                return <ForgotPasswordComponent/>;
            default:
                console.log(`Step ---> ${step} <--- not found.`);
                return null;
        }
    };

    return (
        <Grid container className={classes.container}>
            <Grid item xs={12}>
                <Typography variant={"h6"} className={classes.title}>
                    {sectionTitles.capstoneHMESupport}
                </Typography>
            </Grid>
            <Grid item xs={12} style={{paddingBottom:16, display:'flex'}}>
               <Button variant={'outlined'} color={'primary'}
               onClick={()=> history.push('/')}
               >Back to Home</Button>
            </Grid>
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-start', paddingBottom: 63}}>
                <div>
                    <RadioGroup aria-label="credentials" name="credentials" value={activeStep} onChange={handleChange}>
                        <FormControlLabel value="email" control={<Radio color={"primary"}/>} label="Forgot my Username"
                                          style={{
                                              color: '#686868', fontSize: 14, fontWeight: 'bold',
                                          }}/>
                        <FormControlLabel value="password" control={<Radio color={"primary"}/>}
                                          label="I need to Reset My Password"/>
                    </RadioGroup>
                </div>
            </Grid>
            <Grid item xs={12}>
                {getStepContent(activeStep)}
            </Grid>
            <Grid item xs={12}>
                <img src={PoweredLogo}
                     alt=""
                     name='admin'
                     width='110'
                     className={classes.poweredLogo}
                />
            </Grid>
        </Grid>
    )
};
const useStyles = makeStyles((theme) => (forgotStyle));
