import React, {useEffect, useState} from 'react';
import {endpoints} from "../../../../constants/endpoints";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from "@material-ui/core/Dialog";
import _ from "lodash";

const FileUpload = ({files, setFiles, existing, markFileAsRemoved, setIsLoading}) => {
    const [file, setFile] = useState(null);
    const [image, setImage] = useState(null);
    const [showPreview, setShowPreview] = useState(false);
    const [showPreviewExisting, setShowPreviewExisting] = useState(false);
    const [currentFile, setCurrentFile] = useState("");
    const [previewExistingFile, setPreviewExistingFile] = useState("");
    const [existingFiles, setExistingFiles] = useState([]);
    const [fileType, setFileType] = useState('');

    useEffect(()=>{
        if(existing){
            setExistingFiles(existing)
        }
    },[existing]);

    const uploadImages = (event) => {
        setIsLoading && setIsLoading(true);
        let newArr = _.cloneDeep(files);
        if (event.target.files[0] !== undefined) {
            newArr.push(event.target.files[0]);
            setFiles(newArr);
        }
    };

    const removeFile = (index) => {
        let clone = _.cloneDeep(files);
        clone.splice(index, 1);
        setFiles(clone);
    };

    const previewExisting = async (file) => {
        try {
            let name = file.file_name.split(".");
            let fileName = `${name[0]}`;
            const token = JSON.parse(localStorage.getItem("key"));
            const promise = await fetch(endpoints.fileGetOnNotification, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": "Bearer " + token.accessToken,
                },
                body: JSON.stringify({
                    fileName: fileName,
                    fileId: file.file_id
                })
            });
            const result = await promise.json();
            setImage(result.data);
            setPreviewExistingFile(result.data);
            setShowPreviewExisting(true)
        } catch (error) {
            // handle error
        }
    };

    async function downloadFile(file) {
        try {
            let name = file.file_name.split(".");
            let fileName = `${name[0]}`;
            const token = JSON.parse(localStorage.getItem("key"));
            const promise = await fetch(endpoints.fileGetOnNotification, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": "Bearer " + token.accessToken,
                },
                body: JSON.stringify({
                    fileName: fileName,
                    fileId: file.file_id
                })
            });
            const result = await promise.json();
            function downloadURI(uri, original_name) {
                let link = document.createElement("a");
                link.setAttribute('download', original_name);
                link.setAttribute('target', "_blank");
                link.href = uri;
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
            downloadURI(result.data, file.file_name);
        } catch (error) {
            // handle error
        }
    }

    return (
        <Grid container style={{paddingTop:16, paddingBottom:8}}>
            <iframe id="my_iframe" style={{display:"none"}}></iframe>
            <Grid item xs={12} style={{fontSize:24, fontWeight:'bold', color:'#00223D', textAlign:'left'}}>Files</Grid>
            <Grid item xs={12}>
                <Grid container>
                    {existingFiles?.map((file, index) => {
                        let fileType = file.file_name.split(".");
                        let fileExtension = fileType[fileType.length-1];
                        let showPreview = false;
                        let previewExtensions = ['png', 'jpg', 'jpeg', 'pdf'];
                        if (previewExtensions.includes(fileExtension)){
                            showPreview = true
                        }
                        return (
                            <Grid item xs={12} style={{paddingTop:16}}>
                                <Grid container justify={'space-between'} alignContent={'center'} alignItems={"center"}
                                      style={{borderRadius:4, padding:8, backgroundColor:'#F8F9F9'}}>
                                    <Grid item>{file.file_name}</Grid>
                                    <Grid item style={{display:'flex', alignContent:'center', alignItems:'center'}}>
                                        { showPreview ? (
                                            <Button variant={'outlined'} color={'primary'} style={{height:36}}
                                                    disabled={!showPreview}
                                                    onClick={(e)=>{
                                                        previewExisting(file);
                                                        setFileType(fileExtension);
                                                    }}
                                            >Preview</Button>
                                        ) : (
                                            <div style={{color:'grey', }}>
                                                Preview not available
                                            </div>
                                        )}

                                        <div style={{width:8}}></div>
                                        <Button variant={'outlined'} color={'primary'} style={{height:36}}
                                                onClick={(e)=>{
                                                    downloadFile(file)
                                                }}
                                        >Download</Button>
                                        <div style={{paddingLeft:8}}>
                                            <IconButton onClick={() => markFileAsRemoved && markFileAsRemoved(file.file_id, file.file_name)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                    {files.map((file, index) => {
                        return (
                            <Grid item xs={12} style={{paddingTop:16}}>
                                <Grid container justify={'space-between'} alignContent={'center'} alignItems={"center"}
                                      style={{borderRadius:4, padding:8, backgroundColor:'#F8F9F9'}}>
                                    <Grid item>{file.name}</Grid>
                                    <Grid item style={{display:'flex', alignContent:'center', alignItems:'center'}}>
                                        <Button variant={'outlined'} color={'primary'} style={{height:36}}
                                        onClick={(e)=>{
                                            setCurrentFile(file);
                                            setShowPreview(true)
                                        }}
                                                disabled={file.type?.substring(0,5) !== 'image'}
                                        >Preview</Button>
                                        <div style={{paddingLeft:8}}>
                                            <IconButton onClick={() => removeFile(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            <input
                style={{display: 'none'}}
                id="raised-button-file"
                type="file"
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.jpg,.jpeg,.png,.csv,.xls,.xlsx, .rtf"
                onChange={(e)=>uploadImages(e)}
            />
            <Grid item xs={12} style={{paddingTop:8, textAlign:'left'}}>
                <label htmlFor="raised-button-file">
                    <Button variant="outlined" component="span" color={'primary'}
                            // className={classes.button}
                    >
                        <CloudUploadIcon
                            fontSize={'small'}
                            style={{paddingRight:4}}
                        />
                        Upload File
                    </Button>
                </label>
            </Grid>
            <Dialog open={showPreview} onClose={()=> setShowPreview(false)} fullWidth maxWidth={'xl'}>
                <Grid container style={{maxWidth:1000}}>
                    <img src={currentFile !== "" ? URL?.createObjectURL(currentFile) : ""} width={'100%'} style={{maxWidth:1000}}/>
                </Grid>
            </Dialog>

            {/*//dialog for existing*/}
            <Dialog open={showPreviewExisting} onClose={()=> setShowPreviewExisting(false)} fullWidth maxWidth={'xl'}>
                <Grid container>
                    { fileType === 'pdf' ?
                        (
                            <iframe src={previewExistingFile} width={'100%'} height={'1000px'}/>
                        ) : (
                            <img src={previewExistingFile}  width={'100%'}/>
                        )
                    }
                </Grid>
            </Dialog>
        </Grid>
    );
};

export default FileUpload;
