import React, {useState} from "react";
import {Table} from './reviewTable/table';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Grid from "@material-ui/core/Grid";
import {OrderNotes} from "./orderNotes/orderNotes";
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import Select from 'react-select'
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FileUpload from "../../../orders/fileUploadOrders/FileIpload";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import FormControl from "@material-ui/core/FormControl";
import {InputLabel} from "@material-ui/core";
import MSelect from '@material-ui/core/Select';

export const OrderReview = ({items, exchangeItems, setExchangeCart, notes, setNotes, patientInventory, handleRemove, handleClearAll, handleInput, priority, preferredDate, notifyHospice, authorization_pace_no, setAuthorization_pace_no, showAuthorization, files, setFiles, setIsUnsavedNote, showError, currentNote, setCurrentNote, setShowWarning, showWarning, addOrderNote, isMobile}) => {
    const columns = [
        {
            title: '', field: 'in_formulary', width: 20, sorting: false,
            render: rowData => <div>
                {rowData.type === 'PD' ?
                    <CheckCircleIcon style={{color: 'green'}}/> :
                    <LocalOfferIcon style={{color: 'red'}}/>
                }
            </div>
        },
        {title: 'Item Name', field: 'name', width: 600},
        {
            title: 'Qty', field: 'quantity', width: 260,
            render: rowData => <span>{rowData.quantity}</span>
        },
        {
            title: 'Action', field: 'action', width: 320,
        },
        {
            title: 'Clinical Settings', field: 'clinical_input', width: 320,
            render: rowData => <div style={{textAlign: 'left'}}>
                {rowData.special_item_info && <div>
                    {rowData.special_item_info.min_range && (
                        <React.Fragment>
                            <div> {`Min. ${rowData.special_item_info.min_range}`} </div>
                            <div> {`Max. ${rowData.special_item_info.max_range}`} </div>
                        </React.Fragment>
                    )}
                    {rowData.special_item_info.isContinuous && (
                        <div> {`With continuous oxygen therapy`} </div>
                    )}
                    {rowData.special_item_info.humidifier_for_compressor && (
                        <div> {`Humidifier bottle: ${rowData.special_item_info.humidifier_for_compressor}%`} </div>
                    )}
                    {rowData.special_item_info.weight && (
                        <div> {`Patient Weight: ${rowData.special_item_info.weight}`} </div>
                    )}
                    {rowData.special_item_info.size_lift && (
                        <div> {`Size: ${rowData.special_item_info.size_lift}`} </div>
                    )}
                </div>}
            </div>
        },
        {
            title: 'Authorization', field: 'in_formulary', width: 20,
            render: rowData => <div style={{marginLeft: '-40px', textAlign: 'center'}}>
                {rowData.approval ? (
                    <span className={'inactive_status'}> Yes </span>
                ) : (
                    <span className={'active_status'}> No </span>
                )}
            </div>
        },
        {
            title: 'Remove', field: 'remove', width: 20, sorting: false,
            render: rowData => <div>
                <Tooltip placement="top" title="Remove item">
                    <IconButton
                        onClick={(event) => {
                            const obj = {
                                ...event,
                                target:{
                                    value: rowData
                                }
                            };
                            handleRemove(obj);
                        }}
                    >
                        <DeleteIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        },
    ];
    const matches = useMediaQuery(theme => theme.breakpoints.between('md', 'lg'));

    return (
        <div className={'order_container'}
             style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%'}}>
            <Grid container justify={"flex-start"} alignItems={"center"} style={{maxWidth: 1200, paddingBottom: '8px'}} spacing={2}>
                <Grid item xs={12} sm={6} md={3} lg={2} style={{paddingTop: 20, zIndex: 2}}>
                    {isMobile ? (
                            <FormControl variant="outlined" style={{width:'100%',
                                marginBottom:16}}>
                                <InputLabel htmlFor="outlined-age-native-simple">Priority</InputLabel>

                                <MSelect
                                    native
                                    autoWidth
                                    // value={state.age}
                                    // onChange={handleChange}
                                    label="Priority"
                                    inputProps={{
                                        name: 'age',
                                        id: 'outlined-age-native-simple',
                                    }}
                                    // style={{width:200}}
                                    style={{height:40}}
                                >
                                    <option value={'Routine'}>Routine</option>
                                    <option value={'Priority'}>Priority</option>
                                </MSelect>
                            </FormControl>
                    ) : (
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder={'Select...'}
                            value={priority !== '' && [{value:priority, label:priority}]}
                            options={[{label:"Routine", value:"Routine"},{label:"Priority", value:"Priority"}]}
                            onChange={(e)=> {
                                let obj = {target: {name:'priority_code',value:e.label}};
                                handleInput(obj)
                            }}
                        />
                    )}

                </Grid>
                <Grid item xs={'12'} sm={6} md={3} lg={2} style={{marginBottom: 4}}>
                    {isMobile ? (
                        <TextField
                            label={'Preferred Date'}
                            size={"small"}
                            fullWidth
                            variant={'outlined'}
                            type='date' style={{backgroundColor:'white', padding:2}}
                            onChange={(e)=>{
                                let obj = {target: {name:'preferredDate',value:e.target.value}}
                                handleInput(obj)
                            }}
                            value={preferredDate}
                            InputLabelProps={{ shrink: true }}
                            KeyboardButtonProps={{'aria-label': 'change date',}}
                        />
                        ) : (
                    <div className={'picker'} style={{paddingBottom:8}}>
                        <div className={'date_label'}>Preferred date:</div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justify="space-around">
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    size={'small'}
                                    fullWidth
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-inline"
                                    placeholder={'MM/dd/yyyy'}
                                    value={preferredDate}
                                    onChange={(e)=>{
                                        let obj = {target: {name:'preferredDate',value:e}}
                                        handleInput(obj)
                                    }}
                                    KeyboardButtonProps={{'aria-label': 'change date',}}
                                    style={{margin: 0}}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>)}
                </Grid>
                {showAuthorization === true && (
                    <Grid item xs={12} sm={6} md={3} lg={2} style={matches ? {display:'flex', alignItems:'flex-start', paddingBottom: 0} : {display:'flex', alignItems:'flex-start'}}>
                        <TextField
                            fullWidth
                            placeholder={'Authorization number'}
                            label={'Authorization number'}
                            variant={'outlined'}
                            size={'small'}
                            value={authorization_pace_no}
                            onChange={(e)=>{
                                setAuthorization_pace_no(e.target.value)
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Table columns={columns} items={exchangeItems} order={exchangeItems} setOrder={setExchangeCart} handleClearAll={handleClearAll} backGroundColor={'#F2F6F7'} textColor={"#2E3236"}
                   isMobile={isMobile} handleRemove={handleRemove}
            />
            <FileUpload files={files} setFiles={setFiles}/>
            <OrderNotes notes={notes} setNotes={setNotes} handleInput={handleInput} notifyHospice={notifyHospice} setIsUnsavedNote={setIsUnsavedNote} showError={showError} currentNote={currentNote} setCurrentNote={setCurrentNote} setShowWarning={setShowWarning} showWarning={showWarning} addOrderNote={addOrderNote} />
        </div>
    )
};
