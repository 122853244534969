import React, {useEffect, useRef, useState} from 'react';
import './orders.css';
import {useDispatch, useSelector} from 'react-redux';
import Grid from "@material-ui/core/Grid";
import {buttonText} from "../../constants/alert/alertButtonTexts";
import {componentTitles} from "../../constants/names/titles";
import {TabSearchBar} from "../../common/search/tabSearch/TabSearch";
import {Table} from "../../common/table/table";
import {useHistory} from "react-router-dom";
import {searchTechnicians, setDmeUserOrganizations,} from "../../common/search/tabSearch/TabSearchHelper";
import {logout, setCriteriaOrders} from "../../redux/actions";
import {endpoints} from "../../constants/endpoints";
import {tableColumnTitles} from "../../constants/names/columnTitles";
import Tooltip from "@material-ui/core/Tooltip";
import {tooltips} from "../../constants/inputs/tooltips";
import jwt from "jwt-decode";
import moment from "moment";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import {alertTitles} from "../../constants/alert/alertTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {alertMessages} from "../../constants/alert/alertMessages";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import {deliveryOrderStyle} from "./deliveryOrder/deliveryOrderStyle";
import _ from "lodash";
import {checkTokenAvailability} from "../../common/utils/utils";
import {checkPermissions} from "../../common/permissions/checkPermissions";
import {permissions} from "../../constants/permissions/permissions";
import Typography from "@material-ui/core/Typography";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {useSnackbar} from "notistack";
import ExportForm from "../../common/exportForm/exportForm";
import {AutoSizer, List} from 'react-virtualized';
import {isMobile} from "react-device-detect";
import {AllOrdersMobileView} from "./mobileView/mobileView";
import {CircularProgress} from '@material-ui/core';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateRangePicker} from 'react-date-range';
import {subDays} from 'date-fns';
import DateRangeIcon from '@material-ui/icons/DateRange';

export default function Orders() {
    let history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const {accessToken} = JSON.parse(localStorage.getItem("key"));
    const userData = jwt(accessToken);
    const isLogged = useSelector(state => state.isLogged);
    const [show, setShow] = useState(true);
    const [showActionModal, setShowActionModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const tableRef = useRef(null);
    const [open, setOpen] = React.useState(false);

    const [dateSelection, setDateSelection] = useState([
        {
            startDate: subDays(new Date(), 30),
            endDate: new Date(),
            key: "selection"
        }
    ]);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const actionModalCloseDateSelection = () => {
        setOpen(false)
    };

    const [dateStart, setDateStart] = useState(moment().subtract(30, 'days').format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'));

    const handleOnChange = ranges => {
        const { selection } = ranges;
        setDateEnd(moment(selection.endDate).format('YYYY-MM-DD'))
        setDateStart(moment(selection.startDate).format('YYYY-MM-DD'))
        setDateSelection([selection]);
    };

    function applyDateRangeSelection(ordersStartDate, ordersEndDate) {
        if (userData.payload.hospice_organization_id !== null) {
            getContractedProviders(ordersStartDate, ordersEndDate);
        } else {
            getOrders([userData.payload.dme_organization_id], ordersStartDate, ordersEndDate);
        }
        setOpen(false)
    }

    let searchValues = {
        status: undefined,
        type: undefined,
        criteria: undefined,
        technician: undefined,
        priority: undefined,
        role: undefined,
        organization: undefined,
        isActiveUser: undefined,
    };

    let rawFilters = localStorage.getItem("order-filters");
    if (rawFilters) {
        let values = JSON.parse(rawFilters);
        searchValues.status = values.status ? values.status : undefined;
        searchValues.type = values.type ? values.type : undefined;
        searchValues.criteria = values.criteria ? values.criteria : undefined;
        searchValues.technician = values.technician ? values.technician : undefined;
        searchValues.priority = values.priority ? values.priority : undefined;
        searchValues.role = values.role ? values.role : undefined;
        searchValues.organization = values.organization ? values.organization : undefined;
    }
    const [dmeUsersFiltersValue, setDmeUsersFiltersValue] = useState(searchValues);
    const [orders, setOrders] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [orgOptions, setOrgOptions] = useState([]);
    const [orderStatus, setOrderStatus] = useState('');
    const [allOrders, setAllOrders] = useState([]);
    const priorityOptions = [
        {label: 'All Priorities', value: "All Priorities"},
        {label: 'Priority', value: "Priority"},
        {label: 'Routine', value: "Routine"}];
    const typeOptions = [
        {label: 'All Types', value: "All Types"},
        {label: 'Delivery', value: "Delivery"},
        {label: 'Exchange', value: "Exchange"},
        {label: 'Pickup', value: "Pickup"}];
    const statusOptions = [
        {label: 'All Statuses', value: "All Statuses"},
        {label: 'Canceled', value: "Canceled"},
        {label: 'Completed', value: "Completed"},
        {label: 'Open', value: "Open"},
        {label: 'Pending', value: "Pending"},
        {label: 'Processing', value: "Processing"},
        {label: 'Rejected', value: "Rejected"}];
    const [technicianOptions, setTechnicianOptions] = useState([]);
    const [mobileOrders, setMobileOrders] = useState([]);
    let filterOptions = [
        {
            name: "status",
            placeholder: "Status",
            options: statusOptions,
            value: dmeUsersFiltersValue.status,
            isSelect: true,
            isClearable: true
        },
        {   name: "type",
            placeholder: "Type",
            options: typeOptions,
            value: dmeUsersFiltersValue.type,
            isSelect: true
        },
        {
            name: "priority",
            placeholder: "Priority",
            options: priorityOptions,
            value: dmeUsersFiltersValue.priority,
            isSelect: true
        },
        {
            name: "technician",
            placeholder: "DME Technicians",
            options: technicianOptions,
            value: dmeUsersFiltersValue.technician,
            isSelect: true
        },
    ];
    //TODO it will fail this if we will reverse order of filters, refactor
    if (userData.payload.type === "Hospice") {
        filterOptions.splice(3, 1)
    }

    const handleDeleteCriteria = (chip) => {
        setDmeUsersFiltersValue({
            ...dmeUsersFiltersValue,
            criteria: dmeUsersFiltersValue.criteria.filter(c => chip !== c)
        });
    };

    useEffect(() => {
        if (isMobile) {
            handleRetroactiveFilterSearch()
        }
    }, []);

    function applyFilters() {
        setMobileOrders(handleRetroactiveFilterSearch({target: {name: "retroactive"}}))
    }

    const handleRetroactiveFilterSearch = (event, data = {}) => {
        let ordersClone = Object.keys(data).length === 0 ? _.cloneDeep(orders) : _.cloneDeep(data);
        Object.keys(dmeUsersFiltersValue).forEach(key => {
            if (["status", "type", "technician", "priority"].includes(key) && key !== event?.target?.name && dmeUsersFiltersValue[key] !== undefined) {
                if (!dmeUsersFiltersValue[key].toLowerCase().includes("all")) {
                    if (key === "priority") {
                        ordersClone = ordersClone.filter(item => item["priority_code"] === dmeUsersFiltersValue[key]);
                    }
                    if (key === "technician") {
                        ordersClone = ordersClone.filter(item => item["technician_name"] === dmeUsersFiltersValue[key]);
                    }
                    if (key !== "priority" && key !== "technician") {
                        ordersClone = ordersClone.filter(item => item[key] === dmeUsersFiltersValue[key]);
                    }
                }
            }
        });
        if (dmeUsersFiltersValue.input && dmeUsersFiltersValue.input !== "") {
            ordersClone = ordersClone.filter(item => {
                if (item["order_id"] === dmeUsersFiltersValue.input ||
                    item.first_name.includes(dmeUsersFiltersValue.input) ||
                    item.last_name.includes(dmeUsersFiltersValue.input) ||
                    item.address1.includes(dmeUsersFiltersValue.input)
                ) {
                    return true
                } else {
                    return false
                }
            });
        }

        let result = ordersClone.sort(function (a, b) {
            let c = new Date(a.requested_date);
            let d = new Date(b.requested_date);
            return d - c;
        });
        return result;
    };

    function handleFilterUpdate(event) {
        localStorage.setItem('order-filters', JSON.stringify({
            ...dmeUsersFiltersValue,
            [event.target.name]: event.target.value
        }));
        setDmeUsersFiltersValue({...dmeUsersFiltersValue, [event.target.name]: event.target.value})
    }

    const getExportValues = () => {
        let finalArr = [];
        tableRef.current.state.data.map((el) => {
            let newArr = [];
            newArr.push(el.type);
            newArr.push(el.order_id);
            newArr.push(el.priority_code);
            newArr.push(el.first_name);
            newArr.push(el.last_name);
            newArr.push(el.address1);
            newArr.push(el.address2);
            newArr.push(el.city);
            newArr.push(el.zip);
            newArr.push(el.status);
            newArr.push(new Date(moment(el.requested_date).format("LL")));
            newArr.push(el.hospice_name);
            newArr.push(el.technician_name ? el.technician_name : "");
            newArr.push(el.name ? el.name : "");
            finalArr.push(newArr);
        });

        return finalArr;
    };


    const headers = [
        {text: "Order Type", style: 'tableHeader'},
        {text: "Order ID", style: 'tableHeader'},
        {text: "Priority", style: 'tableHeader'},
        {text: "First Name", style: 'tableHeader'},
        {text: "Last Name", style: 'tableHeader'},
        {text: "Address1", style: 'tableHeader'},
        {text: "Address2", style: 'tableHeader'},
        {text: "City", style: 'tableHeader'},
        {text: "Zip", style: 'tableHeader'},
        {text: "Status", style: 'tableHeader'},
        {text: "Requested Date", style: 'tableHeader'},
        {text: "Organization", style: 'tableHeader'},
        {text: "Technician", style: 'tableHeader'},
        {text: "Facility", style: 'tableHeader'},
    ];
    const excelHeaders = [
        "Order_Type",
        "Order_ID",
        "Priority",
        "First Name",
        "Last_Name",
        "Address_1",
        "Address_2",
        "City",
        "Postal_Code",
        "Status",
        "Requested_Date",
        "Organization",
        "Technician",
        "Facility"
    ];
    const handleInputChange = (value) => {
        if (value && value.length < 2 && dmeUsersFiltersValue.input === "") {
            setDmeUsersFiltersValue({
                input: value,
                criteria: ['All'],
                role: "All",
                organization: "All",
                isActiveUser: true,
            })
        } else {
            setDmeUsersFiltersValue({...dmeUsersFiltersValue, input: value})
        }
    };

    const clearFilters = () => {
        if (isMobile) {
            setMobileOrders(orders);
        }
        setDmeUsersFiltersValue({
            input: '',
            criteria: ['All'],
            role: "All",
            organization: "All",
            isActiveUser: true,
        });
        dispatch(setCriteriaOrders(dmeUsersFiltersValue));
        localStorage.setItem('order-filters', JSON.stringify({
            input: '',
            criteria: ['All'],
            role: "All",
            organization: "All",
            isActiveUser: true,
        }));
        setShow(false)

    };
    useEffect(() => {
        //Call query function
        dispatch(setCriteriaOrders(dmeUsersFiltersValue));
        setAllOrders(orders);
    }, [dmeUsersFiltersValue, orders]);
    useEffect(() => {
        setShow(true)
    }, [show]);


    const getOrders = (params, ordersStartDate , ordersEndDate) => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getAllOrders, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({dme_organization_ids: params, startDate: ordersStartDate ? ordersStartDate : dateStart, endDate: ordersEndDate ? ordersEndDate : dateEnd})
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                response.data.map(order => {
                    if (order.delivery_order_id) {
                        order.order_id = "D100" + order.delivery_order_id
                    } else if (order.pickup_order_id) {
                        order.order_id = "P200" + order.pickup_order_id
                    } else if (order.exchange_order_id) {
                        order.order_id = "S300" + order.exchange_order_id
                    }
                });
                setOrders(response.data);
                setMobileOrders(handleRetroactiveFilterSearch({target: {name: "retroactive"}}, response.data))
                setLoading(false)
            })
            .catch(err => {
                setLoading(false);
            })
    };

    const getContractedProviders = (ordersStartDate, ordersEndDate) => {
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getHospiceContractedProviders}${userData.payload.hospice_organization_id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data.length === 1) {
                    getOrders([response.data[0].dme_id], ordersStartDate, ordersEndDate);
                } else {
                    let arr = [];
                    response.data.map(item => {
                        arr.push(item.dme_id);
                    });
                    getOrders([arr], ordersStartDate, ordersEndDate);
                }
            })
            .catch(err => {
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };
    useEffect(() => {
        setDmeUserOrganizations(setOrgOptions);
        if (userData.payload.type !== "Hospice") {
            searchTechnicians(setTechnicianOptions);
        }

        if (userData.payload.hospice_organization_id !== null) {
            getContractedProviders();
        } else {
            if (orders.length === 0) {
                getOrders([userData.payload.dme_organization_id]);
            }
        }
    }, []);

    const actionModalCloseHandler = () => {
        setShowActionModal(false)
    };
    const addDeliveryHistory = (order_id, description) => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.addDeliveryOrderHistory, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                history: [{
                    description: description,
                }],
                deliveryOrderId: order_id
            })
        })
            .then(response => {
                return response.json()
            })

            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };
    const addPickupHistory = (order_id, description) => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.addPickupOrderHistory, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                history: [{
                    description: description,
                }],
                pickupOrderId: order_id
            })
        })
            .then(response => {
                return response.json()
            })

            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };
    const addExchangeHistory = (order_id, description) => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.addExchangeOrderHistory, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                history: [{
                    description: description,
                }],
                exchangeOrderId: order_id
            })
        })
            .then(response => {
                return response.json()
            })

            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };
    const changeOrderStatus = () => {
        let userType = localStorage.getItem('key');
        userType = JSON.parse(userType);
        let user = jwt(userType.accessToken);
        if (orderStatus.type === "Delivery") {
            const status = {
                delivery_order_id: orderStatus.delivery_order_id,
                status: orderStatus.status
            };
            const token = JSON.parse(localStorage.getItem('key'));
            (async () => {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
            })();
            fetch(endpoints.updateDeliveryOrderStatus, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify(status)
            })
                .then(response => {
                    if (response.status === 200) {
                        setShowActionModal(false);
                        let orderClone = _.cloneDeep(orders);
                        let index = _.findIndex(orderClone, {delivery_order_id: orderStatus.delivery_order_id});
                        orderClone[index].status = orderStatus.status;
                        setOrders(orderClone);
                        addDeliveryHistory(orderStatus.delivery_order_id, `Status Changed: ${user.payload.first_name} ${user.payload.last_name} changed order status to ${orderStatus.status} on ${moment(new Date()).utc().format("LLL")}`);
                        enqueueSnackbar("Status updated with success.", {
                            persist: false,
                            variant: "success",
                        });
                    }
                })
                .catch(err => console.log('error', err))
        } else if (orderStatus.type === "Pickup") {
            const status = {
                pickup_order_id: orderStatus.pickup_order_id,
                status: orderStatus.status
            };
            const token = JSON.parse(localStorage.getItem('key'));
            (async () => {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
            })();
            fetch(endpoints.updatePickupOrderStatus, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify(status)
            })
                .then(response => {
                    if (response.status === 200) {
                        setShowActionModal(false);
                        let orderClone = _.cloneDeep(orders);
                        let index = _.findIndex(orderClone, {pickup_order_id: orderStatus.pickup_order_id});
                        orderClone[index].status = orderStatus.status;
                        setOrders(orderClone)
                        addPickupHistory(orderStatus.pickup_order_id, `Status Changed: ${user.payload.first_name} ${user.payload.last_name} changed order status to ${orderStatus.status} on ${moment(new Date()).utc().format("LLL")}`);
                        enqueueSnackbar("Status updated with success.", {
                            persist: false,
                            variant: "success",
                        });
                    }
                })
                .catch(err => console.log('error', err))
        } else if (orderStatus.type === "Exchange") {
            const status = {
                exchange_order_id: orderStatus.exchange_order_id,
                status: orderStatus.status
            };
            const token = JSON.parse(localStorage.getItem('key'));
            (async () => {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
            })();
            fetch(endpoints.updateExchangeOrderStatus, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify(status)
            })
                .then(response => {
                    if (response.status === 200) {
                        setShowActionModal(false);
                        let orderClone = _.cloneDeep(orders);
                        let index = _.findIndex(orderClone, {exchange_order_id: orderStatus.exchange_order_id});
                        orderClone[index].status = orderStatus.status;
                        setOrders(orderClone)
                        addExchangeHistory(orderStatus.exchange_order_id, `Status Changed: ${user.payload.first_name} ${user.payload.last_name} changed order status to ${orderStatus.status} on ${moment(new Date()).utc().format("LLL")}`);
                        enqueueSnackbar("Status updated with success.", {
                            persist: false,
                            variant: "success",
                        });
                    }
                })
                .catch(err => console.log('error', err))
        }
        setShow(false)
    };

    const columns = [
        {
            title: tableColumnTitles.orderType,
            field: 'type',
            width: 100,
            sorting: false,
            defaultFilter: dmeUsersFiltersValue.type === "All Types" ? undefined : dmeUsersFiltersValue.type,
            headerStyle: {
                textAlign: 'left',
                fontWeight: 'bold',
            },
        },
        {
            title: tableColumnTitles.orderId,
            field: 'delivery_order_id',
            grouping: false,
            width: 160,
            sorting: false,
            disableClick: true,
            headerStyle: {
                textAlign: 'left',
            },
            render: rowData => <div>
                {rowData.delivery_order_id &&
                <div style={{display: 'flex'}}>
                    <span
                        style={{cursor: 'pointer'}}
                        onClick={() => history.push(`order/delivery/${rowData.delivery_order_id}`)}
                    >
                        {rowData.delivery_order_id && "D100" + rowData.delivery_order_id}
                    </span>
                    <Tooltip title={'Open in new tab'}>
                        <a href={`order/delivery/${rowData.delivery_order_id}`} target="_blank"
                           rel="noopener noreferrer">
                            <OpenInNewIcon color={'primary'} style={{fontSize: 18}}/>
                        </a>
                    </Tooltip>
                </div>}

                {rowData.pickup_order_id &&
                <div style={{display: 'flex'}}>
                <span
                    style={{cursor: 'pointer'}}
                    onClick={() => history.push(`order/pickup/${rowData.pickup_order_id}`)}
                >
                    P200{rowData.pickup_order_id}
                </span>
                    <Tooltip title={'Open in new tab'}>
                        <a href={`order/pickup/${rowData.pickup_order_id}`} target="_blank" rel="noopener noreferrer">
                            <OpenInNewIcon color={'primary'} style={{fontSize: 18}}/>
                        </a>
                    </Tooltip>
                </div>}

                {rowData.exchange_order_id &&
                <div style={{display: 'flex'}}>
                <span
                    style={{cursor: 'pointer'}}
                    onClick={() => history.push(`order/exchange/${rowData.exchange_order_id}`)}
                >
                    S300{rowData.exchange_order_id}
                </span>
                    <Tooltip title={'Open in new tab'}>
                        <a href={`order/exchange/${rowData.exchange_order_id}`} target="_blank"
                           rel="noopener noreferrer">
                            <OpenInNewIcon color={'primary'} style={{fontSize: 18}}/>
                        </a>
                    </Tooltip>
                </div>}

            </div>
        },
        {
            title: tableColumnTitles.priority,
            field: 'priority_code',
            width: 100,
            defaultFilter: dmeUsersFiltersValue.priority === "All Priorities" ? undefined : dmeUsersFiltersValue.priority,
            headerStyle: {
                textAlign: 'left',
                fontWeight: 'bold'
            },
        },
        {
            title: tableColumnTitles.patient, field: 'last_name', width: 300, grouping: false,
            headerStyle: {
                textAlign: 'left'
            },
            render: rowData => <div>
                <span>{`${rowData.first_name} ${rowData.last_name}`}</span><br/>
                <span style={{
                    fontSize: 12,
                    color: 'rgba(117, 117, 117, 1)'
                }}>{`${rowData.address1} ${rowData.address2}, ${rowData.city}, ${rowData.state}, ${rowData.zip}`}</span>
            </div>
        },
        {
            title: tableColumnTitles.orderStatus,
            field: 'status',
            width: 100,
            defaultFilter: dmeUsersFiltersValue.status === "All Statuses" ? undefined : dmeUsersFiltersValue.status,
            headerStyle: {
                textAlign: 'left',
                fontWeight: 'bold',
                margin: 0,
            },
            cellStyle: {
                textAlign: "left"
            }
        },
        {
            title: tableColumnTitles.requestedDate,
            field: `requested_date`,
            grouping: false,
            width: 150,
            defaultSort: 'desc',
            headerStyle: {
                textAlign: 'left',
            },
            render: rowData => <div style={{
                display: "flex",
                grouping: false,
            }}> {moment(rowData.requested_date).format(" MMM DD, YYYY")}  </div>
        },
        {
            title: tableColumnTitles.organization, field: 'hospice_name', grouping: false, width: 100,
            headerStyle: {
                textAlign: 'left',
            },
            render: rowData => <div style={{display: "flex"}}> {rowData.hospice_name}  </div>
        },
        {
            title: tableColumnTitles.organization,
            field: 'order_id',
            hidden: true,
            searchable: true,
            defaultFilter: dmeUsersFiltersValue.input
        },
        {title: tableColumnTitles.organization, field: 'last_name', hidden: true, searchable: true},
        {title: tableColumnTitles.organization, field: 'zip', hidden: true, searchable: true},
        {
            title: tableColumnTitles.action,
            field: 'technician_name',
            grouping: false,
            width: 50,
            defaultFilter: dmeUsersFiltersValue.technician === "All Technicians" ? undefined : dmeUsersFiltersValue.technician,
            disableClick: true,
            sorting: false,
            headerStyle: {
                textAlign: 'left',
            },
            render: rowData => <div style={{display: 'flex'}}>
                {
                    (rowData.status === 'Open') && (
                        <React.Fragment>
                            {
                                (checkPermissions([permissions.update_orders], "DME")) ? (
                                    <Tooltip title={tooltips.processOrder}>
                                        <Button style={{
                                            background: "rgba(123, 153, 181, 1)",
                                            width: 150,
                                            color: "white",
                                            border: 'none'
                                        }} variant='outlined' size='small' onClick={(e) => {
                                            e.preventDefault();
                                            if (checkPermissions([permissions.update_orders], "DME")) {
                                                setOrderStatus({
                                                    status: 'Processing',
                                                    type: rowData.type,
                                                    delivery_order_id: rowData.delivery_order_id,
                                                    pickup_order_id: rowData.pickup_order_id,
                                                    exchange_order_id: rowData.exchange_order_id,
                                                });
                                                setShowActionModal(true)
                                            }
                                        }}>
                                            OPEN
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Button disabled style={{
                                        background: "rgba(123, 153, 181, 1)",
                                        width: 150,
                                        color: "white",
                                        cursor: 'initial',
                                        border: 'none'
                                    }} variant='outlined' size='small'>
                                        OPEN
                                    </Button>
                                )
                            }

                        </React.Fragment>
                    )}
                {
                    (rowData.status === 'Processing') && (
                        <React.Fragment>
                            {
                                checkPermissions([permissions.update_orders], "DME") ? (
                                    <Tooltip title={tooltips.completeOrder}>
                                        <Button variant='outlined' style={{
                                            background: "rgba(206, 193, 41, 1)",
                                            width: 150,
                                            color: "white",
                                            border: 'none'
                                        }} size='small' onClick={(e) => {
                                            e.preventDefault();
                                            setOrderStatus({
                                                status: 'Completed',
                                                type: rowData.type,
                                                delivery_order_id: rowData.delivery_order_id,
                                                pickup_order_id: rowData.pickup_order_id,
                                                exchange_order_id: rowData.exchange_order_id,
                                            });
                                            setShowActionModal(true)
                                        }}
                                        >
                                            PROCESSING
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Button disabled variant='outlined' style={{
                                        background: "rgba(206, 193, 41, 1)",
                                        width: 150,
                                        color: "white",
                                        cursor: 'initial',
                                        border: 'none'
                                    }} size='small'
                                    >
                                        PROCESSING
                                    </Button>
                                )
                            }

                        </React.Fragment>

                    )
                }
                {
                    (rowData.status === 'Completed') && (
                        <Tooltip title={tooltips.completed}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: "rgba(17, 124, 112, 1)",
                                width: 150,
                                height: 30,
                                borderRadius: 4,
                                color: "white",
                                border: 'none'
                            }}>
                                <span>COMPLETED</span>
                            </div>
                        </Tooltip>
                    )
                }
                {
                    (rowData.status === 'Pending') && (
                        <Tooltip title={tooltips.pending}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: "rgba(206, 193, 41, 1)",
                                width: 150,
                                height: 30,
                                borderRadius: 4,
                                color: "white",
                                border: 'none'
                            }}>
                                <span>PENDING</span>
                            </div>
                        </Tooltip>
                    )
                }
                {
                    (rowData.status === 'Canceled') && (
                        <Tooltip title={tooltips.canceled}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: "rgba(205, 29, 50, 1)",
                                width: 150,
                                height: 30,
                                borderRadius: 4,
                                color: "white",
                                border: 'none'
                            }}>
                                <span>CANCELED</span>
                            </div>
                        </Tooltip>
                    )
                }
                {
                    (rowData.status === 'Rejected') && (
                        <Tooltip title={tooltips.rejected}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: "rgba(205, 29, 50, 1)",
                                width: 150,
                                height: 30,
                                borderRadius: 4,
                                color: "white",
                                border: 'none'
                            }}>
                                <span>REJECTED</span>
                            </div>
                        </Tooltip>
                    )
                }
            </div>
        },
    ];

    function generatePatientName(order) {
        return order.first_name + " " + order.last_name;
    }

    function generatePatientAddress(order) {
        // return order.address1 + " " + order.address2 ? order.address2 : '' + order.city + " " + order.state + ", " + order.zip;
        return order.address1 + " " + order.address2 + order.city + " " + order.state + ", " + order.zip;
    }

    const hasPrintPermissions = checkPermissions([permissions.print_order]);
    const hasDownloadPermissions = checkPermissions([permissions.download_order]);

    function rowRenderer({key, index, style}) {
        return (
            <div
                key={key}
                style={{...style, padding: 8}}
            >
                <Grid container style={{
                    backgroundColor: 'white',
                    borderRadius: 4,
                    padding: 8,
                    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.1)',
                }}>
                    <Grid container justify='space-between' alignContent='center' alignItems='center'>
                        <div style={{
                            fontWeight: 'bold',
                            paddingLeft: 8,
                            fontSize: 16,
                            color: '#315363',
                            display: 'flex'
                        }}>
                            <div>
                                {mobileOrders[index].delivery_order_id && <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    justifyContent: 'center'
                                }}>
                    <span
                        style={{cursor: 'pointer'}}
                        onClick={() => history.push(`order/delivery/${mobileOrders[index].delivery_order_id}`)}
                    >
                        {mobileOrders[index].delivery_order_id && "D100" + mobileOrders[index].delivery_order_id}
                    </span>
                                    <Tooltip title={'Open in new tab'}>
                                        <a href={`order/delivery/${mobileOrders[index].delivery_order_id}`}
                                           target="_blank" rel="noopener noreferrer">
                                            <OpenInNewIcon color={'primary'}
                                                           style={{fontSize: 18, marginLeft: 4, marginRight: 4}}/>
                                        </a>
                                    </Tooltip>
                                </div>}

                                {mobileOrders[index].pickup_order_id && <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    justifyContent: 'center'
                                }}>
                <span
                    style={{cursor: 'pointer'}}
                    onClick={() => history.push(`order/pickup/${mobileOrders[index].pickup_order_id}`)}
                >
                    P200{mobileOrders[index].pickup_order_id}
                </span>
                                    <Tooltip title={'Open in new tab'}>
                                        <a href={`order/pickup/${mobileOrders[index].pickup_order_id}`} target="_blank"
                                           rel="noopener noreferrer">
                                            <OpenInNewIcon color={'primary'}
                                                           style={{fontSize: 18, marginLeft: 4, marginRight: 4}}/>
                                        </a>
                                    </Tooltip>
                                </div>}

                                {mobileOrders[index].exchange_order_id && <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignContent: 'center',
                                    justifyContent: 'center'
                                }}>
                <span
                    style={{cursor: 'pointer'}}
                    onClick={() => history.push(`order/exchange/${mobileOrders[index].exchange_order_id}`)}
                >
                    S300{mobileOrders[index].exchange_order_id}
                </span>
                                    <Tooltip title={'Open in new tab'}>
                                        <a href={`order/exchange/${mobileOrders[index].exchange_order_id}`}
                                           target="_blank" rel="noopener noreferrer">
                                            <OpenInNewIcon color={'primary'}
                                                           style={{fontSize: 18, marginLeft: 4, marginRight: 4}}/>
                                        </a>
                                    </Tooltip>
                                </div>}</div>
                        </div>

                        {(mobileOrders[index].status === 'Open') && (
                            <React.Fragment>
                                {
                                    (checkPermissions([permissions.update_orders], "DME")) ? (
                                        <Tooltip title={tooltips.processOrder}>
                                            <Button style={{
                                                background: "rgba(123, 153, 181, 1)",
                                                width: 120,
                                                color: "white",
                                                border: 'none'
                                            }} variant='outlined' size='small' onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (checkPermissions([permissions.update_orders], "DME")) {
                                                    setOrderStatus({
                                                        status: 'Processing',
                                                        type: mobileOrders[index].type,
                                                        delivery_order_id: mobileOrders[index].delivery_order_id,
                                                        pickup_order_id: mobileOrders[index].pickup_order_id,
                                                        exchange_order_id: mobileOrders[index].exchange_order_id,
                                                    });
                                                    setShowActionModal(true)
                                                }
                                            }}>
                                                OPEN
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <Button disabled style={{
                                            background: "rgba(123, 153, 181, 1)",
                                            width: 120,
                                            color: "white",
                                            cursor: 'initial',
                                            border: 'none'
                                        }} variant='outlined' size='small'>
                                            OPEN
                                        </Button>
                                    )
                                }

                            </React.Fragment>
                        )}
                        {
                            (mobileOrders[index].status === 'Processing') && (
                                <React.Fragment>
                                    {
                                        checkPermissions([permissions.update_orders], "DME") ? (
                                            <Tooltip title={tooltips.completeOrder}>
                                                <Button variant='outlined' style={{
                                                    background: "rgba(206, 193, 41, 1)",
                                                    width: 120,
                                                    color: "white",
                                                    border: 'none'
                                                }} size='small' onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation()
                                                    setOrderStatus({
                                                        status: 'Completed',
                                                        type: mobileOrders[index].type,
                                                        delivery_order_id: mobileOrders[index].delivery_order_id,
                                                        pickup_order_id: mobileOrders[index].pickup_order_id,
                                                        exchange_order_id: mobileOrders[index].exchange_order_id,
                                                    });
                                                    setShowActionModal(true)
                                                }}
                                                >
                                                    PROCESSING
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            <Button disabled variant='outlined' style={{
                                                background: "rgba(206, 193, 41, 1)",
                                                width: 120,
                                                color: "white",
                                                cursor: 'initial',
                                                border: 'none'
                                            }} size='small'
                                            >
                                                PROCESSING
                                            </Button>
                                        )
                                    }

                                </React.Fragment>

                            )
                        }
                        {
                            (mobileOrders[index].status === 'Completed') && (
                                <Tooltip title={tooltips.completed}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        background: "rgba(17, 124, 112, 1)",
                                        width: 120,
                                        height: 30,
                                        borderRadius: 4,
                                        color: "white",
                                        border: 'none'
                                    }}>
                                        <span>COMPLETED</span>
                                    </div>
                                </Tooltip>
                            )
                        }
                        {
                            (mobileOrders[index].status === 'Pending') && (
                                <Tooltip title={tooltips.pending}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        background: "rgba(206, 193, 41, 1)",
                                        width: 120,
                                        height: 30,
                                        borderRadius: 4,
                                        color: "white",
                                        border: 'none'
                                    }}>
                                        <span>PENDING</span>
                                    </div>
                                </Tooltip>
                            )
                        }
                        {
                            (mobileOrders[index].status === 'Canceled') && (
                                <Tooltip title={tooltips.canceled}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        background: "rgba(205, 29, 50, 1)",
                                        width: 120,
                                        height: 30,
                                        borderRadius: 4,
                                        color: "white",
                                        border: 'none'
                                    }}>
                                        <span>CANCELED</span>
                                    </div>
                                </Tooltip>
                            )
                        }
                        {
                            (mobileOrders[index].status === 'Rejected') && (
                                <Tooltip title={tooltips.rejected}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        background: "rgba(205, 29, 50, 1)",
                                        width: 120,
                                        height: 30,
                                        borderRadius: 4,
                                        color: "white",
                                        border: 'none'
                                    }}>
                                        <span>REJECTED</span>
                                    </div>
                                </Tooltip>
                            )
                        }
                    </Grid>
                    <Grid container style={{marginTop: 8, borderRadius: 4, backgroundColor: '#F8F8F9', padding: 8}}
                          justify='space-between'>
                        <div style={{color: '#838C8C'}}>Patient</div>
                        <div style={{
                            fontWeight: 'bold',
                            color: '#3B758E'
                        }}>{generatePatientName(mobileOrders[index])}</div>
                    </Grid>
                    <Grid container
                          style={{marginTop: 8, borderRadius: 4, padding: '0 8px', marginBottom: 4, height: 40}}
                          justify='space-between' alignContent='center' alignItems='center'>
                        <Grid item xs={3} style={{color: '#808989', textAlign: 'left'}}>Address</Grid>
                        <Grid item xs={9} style={{
                            fontWeight: 'bold',
                            color: '#6B7280',
                            fontSize: 13,
                            textAlign: 'right'
                        }}>{generatePatientAddress(mobileOrders[index])}</Grid>
                    </Grid>
                    <Grid container style={{marginTop: 8, borderRadius: 4, backgroundColor: '#F8F8F9', padding: 8}}
                          justify='space-between'>
                        <div style={{color: '#838C8C'}}>Order Type</div>
                        <div style={{fontWeight: 'bold', color: '#315363'}}>{mobileOrders[index].type} </div>
                    </Grid>
                    <Grid container style={{marginTop: 8, borderRadius: 4, padding: '0 8px'}} justify='space-between'>
                        <div style={{color: '#808989'}}>Priority</div>
                        <div style={{fontWeight: 'bold', color: '#6B7280'}}><span style={{
                            color: '#838C8C',
                            fontWeight: 'normal',
                            fontSize: 14
                        }}>{moment(mobileOrders[index].requested_date).format("L")}</span> - {mobileOrders[index].priority_code}
                        </div>
                    </Grid>
                    {/* {mobileOrders[index].delivery_order_id ? "D100" + mobileOrders[index].delivery_order_id : ""} {mobileOrders[index].pickup_order_id ? "P200" + mobileOrders[index].pickup_order_id : ""} {mobileOrders[index].exchange_order_id ? "S300" + mobileOrders[index].exchange_order_id : ""} {mobileOrders[index].status} {mobileOrders[index].name} {mobileOrders[index].first_name + " " + mobileOrders[index].last_name} */}
                </Grid>
            </div>
        )
    }

    if (isMobile) {
        return <React.Fragment>
            {loading ? (
                <Grid container justify='center' alignContent='center' alignItems='center' style={{minHeight: '80vh'}}>
                    <CircularProgress/>
                </Grid>
            ) : (
                <React.Fragment>
                    <AllOrdersMobileView
                        filterOptions={filterOptions}
                        handleInputChange={handleInputChange}
                        handleFilterUpdate={handleFilterUpdate}
                        clearFilters={clearFilters}
                        applyFilters={applyFilters}
                    >
                        {mobileOrders.length === 0 ? (
                            <div style={{textAlign: 'center', padding: 36, width: '100%'}}>No orders found</div>
                        ) : (

                            <Grid item xs={12} style={{height: 'calc(100vh - 128px)'}}>
                                <AutoSizer>
                                    {({width, height}) => (
                                        <List
                                            height={height}
                                            width={width}
                                            rowCount={mobileOrders.length}
                                            rowHeight={224}
                                            rowRenderer={rowRenderer}
                                            overscanRowCount={10}
                                        />
                                    )}
                                </AutoSizer>
                            </Grid>

                        )}
                    </AllOrdersMobileView>

                    <Dialog
                        open={showActionModal}
                        onClose={actionModalCloseHandler}
                    >
                        <DialogTitle>{alertTitles.changeStatus}</DialogTitle>
                        <DialogContent className={classes.dialog}>
                            {
                                (orderStatus.status === 'Processing') ? (
                                    <div className={classes.messages}>{alertMessages.changeNewOrderStatus}</div>
                                ) : (
                                    <div className={classes.messages}>{alertMessages.changeProcessingOrderStatus}</div>
                                )
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={actionModalCloseHandler} variant="outlined" color="secondary">
                                {buttonText.cancel}
                            </Button>
                            <Button
                                onClick={changeOrderStatus}
                                variant="contained" color="primary">
                                {buttonText.yes}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            )}
        </React.Fragment>
    }

    return (
        <React.Fragment>
            {(isLogged) ?
                <React.Fragment>
                    <Grid container className={classes.root} style={{maxWidth: "100%"}}>
                        <Grid item xs={12}>
                            <Typography className={classes.h1}>
                                {componentTitles.orders}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TabSearchBar
                                filtersValue={dmeUsersFiltersValue}
                                handleDeleteCriteria={handleDeleteCriteria}
                                handleInputChange={handleInputChange}
                                clearFilters={clearFilters}
                                filterOptions={filterOptions}
                                handleFilterUpdate={handleFilterUpdate}
                                searchPatients={() => {
                                    setDmeUsersFiltersValue({
                                        input: dmeUsersFiltersValue.input,
                                        criteria: ['All'],
                                        role: "All",
                                        organization: "All",
                                        isActiveUser: true,
                                    });

                                    setShow(false)
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} style={{display: "flex", justifyContent: "flex-start", paddingBottom: 16}}>
                            {(checkPermissions([permissions.create_orders])) && (
                                <Button
                                    className={classes.button}
                                    //fullWidth
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={() => {
                                        history.push(`/patients/all`)
                                    }}
                                >
                                    Add New {buttonText.order}
                                </Button>)
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.dateRangeSelection}>
                                Please select a date range in order to see older orders.
                                Your current selection is: {moment(dateStart).format('MM/DD/YYYY')} - {moment(dateEnd).format('MM/DD/YYYY')}
                            </Typography>
                        </Grid>
                        <Button
                            className={classes.button}
                            variant={'contained'}
                            color={'primary'}
                            startIcon={<DateRangeIcon/>}
                            style={{cursor: 'pointer', width: '147px'}}
                            onClick={handleClickOpen}
                        >
                            Select Range
                        </Button>
                        <Button
                            className={classes.button}
                            variant={'contained'}
                            color={'primary'}
                            startIcon={<DateRangeIcon/>}
                            style={{cursor: 'pointer', width: '147px'}}
                            onClick={() => {
                                let orderStartDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
                                let orderEndDate = moment().format('YYYY-MM-DD')
                                setDateStart(orderStartDate);
                                setDateEnd(orderEndDate);
                                applyDateRangeSelection(orderStartDate, orderEndDate)
                            }}
                        >
                            Clear Range
                        </Button>
                            <Dialog
                                open={open}
                                onClose={actionModalCloseDateSelection}
                            >
                                <DialogTitle>{alertTitles.selectDateRange}</DialogTitle>
                                <DialogContent >
                                    <DateRangePicker
                                        minDate={new Date(2019,0)}
                                        maxDate={new Date(2034, 12, 31)}
                                        onChange={handleOnChange}
                                        showSelectionPreview={true}
                                        moveRangeOnFirstSelection={false}
                                        months={1}
                                        ranges={dateSelection}
                                        direction="horizontal"
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={actionModalCloseDateSelection} variant="outlined" color="secondary">
                                        {buttonText.cancelSelection}
                                    </Button>
                                    <Button
                                        onClick={() => applyDateRangeSelection()}
                                        variant="contained" color="primary">
                                        {buttonText.applySelection}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            {!loading && (hasPrintPermissions || hasDownloadPermissions) && (
                                <ExportForm
                                    landscape={true}
                                    fileName='Orders'
                                    getExportValues={getExportValues}
                                    showDownload={hasDownloadPermissions}
                                    showPrint={hasPrintPermissions}
                                    headers={headers}
                                    excelHeaders={excelHeaders}
                                />
                            )}
                        </Grid>
                        {show && !isMobile && (
                            <Grid item xs={12} style={{paddingTop: 12, width: '50vw'}}>
                                <Table
                                    tableRef={tableRef}
                                    isLoading={loading && dmeUsersFiltersValue.status}
                                    search={true}
                                    filtering={true}
                                    columns={columns}
                                    items={allOrders}
                                    searchText={dmeUsersFiltersValue.input}
                                    rowClick={true}
                                    //customText={"Choose a status to retrieve orders"}
                                    backGroundColor={"rgba(237, 242, 244, 0.73)"}
                                    textColor={"rgba(46, 50, 54, 1)"}
                                />
                            </Grid>
                        )}
                        {
                            isMobile && mobileOrders.length > 0 && <Grid item xs={12}>
                                <AutoSizer>
                                    {({width, height}) => (
                                        <List
                                            height={200}
                                            width={width}
                                            rowCount={mobileOrders.length}
                                            rowHeight={20}
                                            rowRenderer={rowRenderer}
                                            overscanRowCount={10}
                                        />
                                    )}
                                </AutoSizer>
                            </Grid>
                        }
                    </Grid>
                    <Dialog
                        open={showActionModal}
                        onClose={actionModalCloseHandler}
                    >
                        <DialogTitle>{alertTitles.changeStatus}</DialogTitle>
                        <DialogContent className={classes.dialog}>
                            {
                                (orderStatus.status === 'Processing') ? (
                                    <div className={classes.messages}>{alertMessages.changeNewOrderStatus}</div>
                                ) : (
                                    <div className={classes.messages}>{alertMessages.changeProcessingOrderStatus}</div>
                                )
                            }
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={actionModalCloseHandler} variant="outlined" color="secondary">
                                {buttonText.cancel}
                            </Button>
                            <Button
                                onClick={changeOrderStatus}
                                variant="contained" color="primary">
                                {buttonText.yes}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </React.Fragment> : ''}
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => (deliveryOrderStyle(theme)));
