import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import Grid from "@material-ui/core/Grid";
import {labels} from "../../../../constants/inputs/labels";
import Link from "@material-ui/core/Link";
import {checkPermissions} from "../../../../common/permissions/checkPermissions";
import {permissions} from "../../../../constants/permissions/permissions";
import Tooltip from "@material-ui/core/Tooltip";
import {tooltips} from "../../../../constants/inputs/tooltips";
import Button from "@material-ui/core/Button";
import EditIcon from "../../../../assets/edit-button.svg";
import PatientWithEquipment from "../../../../assets/patient_has_order_1.svg";
import DeletePatient from "../deletePatient/deletePatient";
import CreateOrder from "../../../createOrder/createOrder";
import CheckInventory from "../../../../common/checkInventory/checkInventory";
import RequestPickup from "../../../requestPickup/requestPickup";
import RequestExchange from "../../../requestExchange/requestExchange";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableHead from "@material-ui/core/TableHead";
import withStyles from "@material-ui/core/styles/withStyles";
import {tableColumnTitles} from "../../../../constants/names/columnTitles";
import CartIcon from "../../../../assets/shopping-cart.svg";
import TruckIcon from "../../../../assets/truck.svg";
import ToolIcon from "../../../../assets/tool.svg";
import {IconAlertActions, IconAlerts, IconAlertTypes} from "../../../../common/alerts/IconAlerts/IconAlerts";
import {Dialog, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {HighlightOff, Person} from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Typography from "@material-ui/core/Typography";
import {generateStatusStyles} from "../patientCard/PatientCard";


const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const {count, page, rowsPerPage, onChangePage} = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                // onClick={handleLastPageButtonClick}
                // disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles({
    table: {
        minWidth: 500,
    },
    searchHeaderTextField: {
        background: "white"
    },
    deliveryButton: {
        color: '#FFFFFF',
        backgroundColor: '#315464',
        fontSize: 12,
        fontWeight: 'bold',
        border: 'none',
        width: '270px',
        height: '40px',
        "&:hover": {
            backgroundColor: '#416e82'
        }
    },
    exchangeButton: {
        color: '#FFFFFF',
        backgroundColor: '#84B4C2',
        fontSize: 12,
        width: '270px',
        height: '40px',
        "&:hover": {
            backgroundColor: '#BBD3DE'
        },
        border: 'none',
    },
    pickupButton: {
        color: '#FFFFFF',
        backgroundColor: '#4F86A0',
        border: 'none',
        fontSize: 12,
        fontWeight: 'bold',
        width: '270px',
        height: '40px',
        paddingTop: 8,
        paddingBottom: 8,
        "&:hover": {
            backgroundColor: '#77A6BC'
        }
    },
    disabledDeliveryButton: {
        fontSize: 12,
        fontWeight: 'bold',
        width: '270px',
        height: '40px'
    },
    disabledPickupButton: {
        fontSize: 12,
        fontWeight: 'bold',
        width: '270px',
        height: '40px'
    },
    disabledExchangeButton: {
        fontSize: 12,
        fontWeight: 'bold',
        width: '270px',
        height: '40px'
    },
    paddingTop8: {
        "&.MuiGrid-item": {
            paddingTop: 8
        }
    },
    paddingBottom8: {
        "&.MuiGrid-item": {
            paddingBottom: 8
        }
    },
    padding0: {
        "&.MuiGrid-item": {
            padding: 0
        }
    },
    standardButton: {
        fontSize: 12,
        fontWeight: 'bold',
        height: '40px',
        width: '270px',
    },
    ActionsDialogTitle: {
        "&.MuiTypography-h6": {
            fontWeight: 700
        }
    },
    fontSize14: {
        "&.MuiGrid-item": {
            fontSize: 14
        },
        "&.MuiTypography-overline": {
            fontSize: 14
        }
    },
    patientActionSectionHeader: {color: 'grey'},
    fontWeight700: {
        "&.MuiTypography-body1": {fontWeight: 600}
    },
});

export default function NewPatientsTable({
                                             enableSearchHeader = false,
                                             statusSearch = "",
                                             patientSearch = "",
                                             addressSearch = "",
                                             phoneSearch = "",
                                             handleSearch,
                                             ...props
                                         }) {
    const {page, setPage, rowsPerPage, setRowsPerPage, searchType} = props;
    const history = useHistory();
    const classes = useStyles2();
    const [selectedPatientData, setSelectedPatientData] = useState({});
    const [openActions, setOpenActions] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        if (searchType === 3) {
            props.searchByLot(rowsPerPage, newPage);
        } else {
            props.getPatients(rowsPerPage, newPage);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

        if (searchType === 3) {
            props.searchByLot(parseInt(event.target.value, 10), 0);
        } else {
            props.getPatients(parseInt(event.target.value, 10), 0);
        }
    };

    const handleOnKeyDown = (e) => {
        if (e.keyCode === 13) {
            props.getPatients(10, 0, 0)
        }
    }

    const handleActionsClose = (e) => {
        setOpenActions(false);
        setSelectedPatientData({});
    }

    const handleActionsOpen = (e, row) => {
        setSelectedPatientData(row);
        setOpenActions(true);
    }

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="center">Alerts</StyledTableCell>
                        <StyledTableCell>Actions</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell align="left">Patient</StyledTableCell>
                        <StyledTableCell align="left">Address</StyledTableCell>
                        <StyledTableCell align="left">Phone Number</StyledTableCell>
                        <StyledTableCell align="center">
                            {(checkPermissions([permissions.update_patients])) ? tableColumnTitles.edit : ""}
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                {enableSearchHeader && <TableHead>
                    <TableRow>
                        <StyledTableCell align="center">
                        </StyledTableCell>
                        <StyledTableCell align="left" style={{minWidth: 100}}/>
                        <StyledTableCell align="left">
                            <TextField
                                style={{minWidth: "140px"}}
                                label={"Status Search"}
                                variant={"outlined"}
                                size="small"
                                name={"statusSearch"}
                                value={statusSearch}
                                InputProps={{className: classes.searchHeaderTextField}}
                                onChange={handleSearch}
                                onKeyDown={handleOnKeyDown}
                            />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <TextField
                                style={{minWidth: "140px"}}
                                label={"Patient Search"}
                                variant={"outlined"}
                                size="small"
                                name={"patientSearch"}
                                value={patientSearch}
                                InputProps={{className: classes.searchHeaderTextField}}
                                onChange={handleSearch}
                                onKeyDown={handleOnKeyDown}
                            />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <TextField
                                style={{minWidth: "250px"}}
                                label={"Address Search"}
                                variant={"outlined"}
                                size="small"
                                name={"addressSearch"}
                                value={addressSearch}
                                InputProps={{className: classes.searchHeaderTextField}}
                                onChange={handleSearch}
                                onKeyDown={handleOnKeyDown}
                            />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                            <TextField
                                style={{minWidth: "150px", maxWidth: "200px"}}
                                label={"Phone Search"}
                                variant={"outlined"}
                                size={"small"}
                                name={"phoneSearch"}
                                value={phoneSearch}
                                onChange={handleSearch}
                                InputProps={{className: classes.searchHeaderTextField}}
                                fullWidth
                                onKeyDown={handleOnKeyDown}
                            />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                        </StyledTableCell>
                    </TableRow>
                </TableHead>}
                <TableBody>
                    {!props.loading && props.rows && props.rows.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={6}>
                                <Grid container
                                      style={{minHeight: 200}}
                                      justify={'center'} alignItems={'center'} alignContent={'center'}
                                      direction={'column'}>
                                    <Grid container justify={'center'} alignItems={'center'} alignContent={'center'}
                                          style={{padding: 16}}>
                                        {'No results found'}
                                    </Grid>
                                </Grid>
                            </TableCell>
                        </TableRow>

                    )}
                    {props.loading ? <TableRow>
                        <TableCell colSpan={6}>
                            <Grid container
                                  style={{minHeight: 200}}
                                  justify={'center'} alignItems={'center'} alignContent={'center'} direction={'column'}>
                                <CircularProgress/>
                                <Grid container justify={'center'} alignItems={'center'} alignContent={'center'}
                                      style={{padding: 16}}>
                                    {'Retrieving patients...'}
                                </Grid>
                            </Grid>
                        </TableCell>
                    </TableRow> : (
                        <React.Fragment>
                            {
                                props.rows && props.rows.length > 0 && props.rows.map((row) => (
                                    <TableRow key={row.patient_id}>
                                        <TableCell style={{paddingTop: 12, paddingBottom: 12}}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: "flex-start"
                                            }}>
                                                {row.status !== "Active" &&
                                                    <CheckInventory patient_id={row.patient_id} width={true}>

                                                        {(row.status !== "Active" || ([11, 15].includes(Number(row.hospice_organization_id)))) &&
                                                            <Grid item data-html2canvas-ignore="true">
                                                                <IconAlerts iconType={IconAlertTypes.patientFlag}
                                                                            action={IconAlertActions.anchor}
                                                                            data={row.status}/>
                                                            </Grid>}
                                                    </CheckInventory>
                                                }
                                            </div>
                                        </TableCell>
                                        <TableCell style={{width: 160, paddingTop: 12, paddingBottom: 12}}>
                                            <Grid item>
                                                {row.patient_count_items > 0?
                                                        <Button
                                                            size="large"
                                                            onClick={(e) => handleActionsOpen(e, row)}
                                                        >
                                                            <img
                                                                src={PatientWithEquipment}
                                                                alt="logo"
                                                                width='40px'
                                                                height='40px'
                                                            />
                                                        </Button>
                                                :
                                                <IconButton color={"primary"} onClick={(e) => handleActionsOpen(e, row)}>
                                                    <AddCircleIcon style={{width: '40px', height: '40px'}}/>
                                                </IconButton>
                                                }
                                            </Grid>
                                        </TableCell>
                                        <TableCell style={{width: 160, paddingTop: 12, paddingBottom: 12}}>
                                            {row.status}
                                        </TableCell>
                                        <TableCell style={{paddingTop: 12, paddingBottom: 12}}>
                                            <div style={{cursor: "pointer", hover: {background: "grey"}, fontSize: 15}}
                                                 onClick={() => {
                                                     history.push({
                                                         pathname: `/patients/patientProfile/${row.patient_id}`,
                                                         patient_id: row.patient_id
                                                     })
                                                 }}>
                                                    <span
                                                        style={row.status === "Active" ? {} : {color: 'rgb(169,169,169)'}}>{`${row.last_name}, ${row.first_name} ${row.mi ? row.mi : ""}`}</span><br/>
                                                <span style={row.status === 'Active' ? {
                                                    fontSize: 12,
                                                    color: 'rgba(117, 117, 117, 1)'
                                                } : {
                                                    fontSize: 12,
                                                    color: 'rgb(169,169,169)'
                                                }}>{`${labels.medical_record}: #${row.medical_record}`}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{paddingTop: 12, paddingBottom: 12}}>
                                            <Grid container justify={"center"} alignItems={"flex-start"}
                                                  direction={"column"}>
                                                <Grid item>
                                                    <Link
                                                        component="button"
                                                        variant="body2"
                                                        // onClick={handleOnClick}
                                                    >
                                                        <span>{`${row.address1} ${row.address2 ? row.address2 : ""}, ${row.city}, ${row.state}, ${row.zip}`}</span>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell style={{paddingTop: 12, paddingBottom: 12}}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: "flex-start"
                                            }}>
                                                <span>{props.formatPhoneNumber(row.phone_number)}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell style={{paddingTop: 12, paddingBottom: 12}}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: "center",
                                                alignContent: 'center',
                                                justifyContent: "center"
                                            }}>
                                                {checkPermissions([permissions.update_patients]) && (
                                                    <Tooltip title={tooltips.editPatient}>
                                                        <Button
                                                            size="large"
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => {
                                                                history.push({
                                                                    pathname: '/patients/edit',
                                                                    patient_id: row.patient_id
                                                                })
                                                            }}
                                                        >
                                                            <img
                                                                src={EditIcon}
                                                                alt="logo"
                                                                width='22'
                                                                height='22'
                                                                style={{cursor: 'pointer'}}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                                {checkPermissions([permissions.delete_patients], "DME") && (
                                                    <DeletePatient rowData={row}/>
                                                )}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}

                        </React.Fragment>
                    )}
                </TableBody>
            </Table>
            <StyledPagination
                style={{display: "flex", justifyContent: 'flex-end'}}
                rowsPerPageOptions={[10, 25, {label: 'All', value: -1}]}
                colSpan={3}
                count={-1}
                component="div"
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                    inputProps: {'aria-label': 'rows per page'},
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
            />
            <Dialog open={openActions} onClose={(e) => handleActionsClose(e)}>
                <DialogTitle>
                    <Typography variant={"h6"} classes={{root: classes.ActionsDialogTitle}}> Patient
                        Actions </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container justify={"center"} alignItems={"center"} spacing={1} style={{width: 270}}>
                        <Grid item xs={12} alignContent={'flex-start'} alignItems={'flex-start'}>
                            <Typography className={classes.fontSize14}><span
                                style={{color: 'gray'}}>Patient Name:</span> {selectedPatientData.first_name ? selectedPatientData.first_name : ""} {selectedPatientData.mi ? selectedPatientData.mi : ""} {selectedPatientData.last_name ? selectedPatientData.last_name : ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} alignContent={'flex-start'} alignItems={'flex-start'}>
                            <Typography className={classes.fontSize14}><span
                                style={{color: 'gray'}}>Address:</span> {`${selectedPatientData.address1 ? selectedPatientData.address1 : ""} ${selectedPatientData.address2 ? selectedPatientData.address2 : ""} ${selectedPatientData.city ? selectedPatientData.city : ""} ${selectedPatientData.state ? selectedPatientData.state : ""} ${selectedPatientData.zip ? selectedPatientData.zip : ""}`}
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} justify={'space-between'} direction={'column'}
                              alignItems={'flex-start'} classes={{root: classes.fontSize14}}>
                            <Typography variant={"overline"} classes={{root: classes.fontSize14}}><span
                                style={{color: 'gray'}}>MRN:</span> #{selectedPatientData.medical_record ? selectedPatientData.medical_record : ""}
                            </Typography>

                            <Typography classes={{root: classes.fontWeight700}}><span
                                style={generateStatusStyles(selectedPatientData.status)}>{selectedPatientData.status ? selectedPatientData.status : ""}</span></Typography>

                        </Grid>
                        <Grid item xs={12} classes={{root: classes.paddingBottom8}}>
                            {(selectedPatientData.status === "Active" || ([11, 15].includes(Number(selectedPatientData.hospice_organization_id)))) ?
                                <CreateOrder renderButton={true} user={selectedPatientData}
                                             isPace={selectedPatientData?.ispace}
                                             buttonStyleClass={classes.deliveryButton}/>
                                :
                                <Button
                                    variant="contained"
                                    disabled
                                    size="small"
                                    className={classes.disabledDeliveryButton}
                                    startIcon={<img
                                        src={CartIcon}
                                        alt="logo"
                                        width='16'
                                        height='15'
                                    />}
                                >
                                    NEW DELIVERY
                                </Button>
                            }
                        </Grid>
                        <Grid item xs={12} container spacing={1} classes={{root: classes.padding0}}>
                            <CheckInventory patient_id={selectedPatientData.patient_id}>
                                <Grid item xs={12}>
                                    {(selectedPatientData.status === "Active" || ([11, 15].includes(Number(selectedPatientData.hospice_organization_id)))) ?
                                        <RequestPickup showPickup={true} openOrder={false}
                                                       name={selectedPatientData.mi ? selectedPatientData.first_name + " " + selectedPatientData.mi + " " + selectedPatientData.last_name : selectedPatientData.first_name + " " + selectedPatientData.last_name}
                                                       address={selectedPatientData.address2 ? selectedPatientData.address1 + " " + selectedPatientData.address2 : selectedPatientData.address1}
                                                       patient_id={selectedPatientData.patient_id}
                                                       hospice_id={selectedPatientData.hospice_organization_id}
                                                       buttonStyleClass={classes.pickupButton}
                                        />
                                        :
                                        <Button
                                            variant="contained"
                                            disabled
                                            size="small"
                                            className={classes.disabledPickupButton}
                                            startIcon={<img
                                                src={TruckIcon}
                                                alt="logo"
                                                width='16'
                                                height='15'
                                            />}
                                        >
                                            NEW PICKUP
                                        </Button>

                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {(selectedPatientData.status === "Active" || ([11, 15].includes(Number(selectedPatientData.hospice_organization_id)))) ?
                                        <RequestExchange
                                            name={selectedPatientData.mi ? selectedPatientData.first_name + " " + selectedPatientData.mi + " " + selectedPatientData.last_name : selectedPatientData.first_name + " " + selectedPatientData.last_name}
                                            address={selectedPatientData.address2 ? selectedPatientData.address1 + " " + selectedPatientData.address2 : selectedPatientData.address1}
                                            patientId={selectedPatientData.patient_id}
                                            hospice_id={selectedPatientData.hospice_organization_id}
                                            region_id={selectedPatientData.region_id}
                                            buttonStyleClass={classes.exchangeButton}
                                        />
                                        :
                                        <Button
                                            variant="contained"
                                            disabled
                                            size="small"
                                            className={classes.disabledExchangeButton}
                                            startIcon={<img
                                                src={ToolIcon}
                                                alt="logo"
                                                width='16'
                                                height='15'
                                            />}
                                        >
                                            NEW EXCHANGE
                                        </Button>
                                    }
                                </Grid>
                            </CheckInventory>
                        </Grid>
                        <Grid item xs={12} classes={{root: classes.paddingTop8}}>
                            <Button
                                variant="contained"
                                size="small"
                                startIcon={<Person
                                    alt="logo"
                                    width='16'
                                    height='15'
                                />}
                                onClick={() => history.push(`/patients/patientProfile/${selectedPatientData.patient_id}`)}
                                className={classes.standardButton}
                            >
                                GO TO PROFILE
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                size="small"
                                color={"secondary"}
                                startIcon={<HighlightOff
                                    alt="logo"
                                    width='16'
                                    height='15'
                                />}
                                onClick={(e) => handleActionsClose(e)}
                                className={classes.standardButton}
                            >
                                CLOSE
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </TableContainer>
    );
}
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgba(237, 242, 244, 0.73)",
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
        padding: 8
    },
    root: {
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingBottom: 15
        },
    },
}))(TableCell);

const StyledPagination = withStyles({
    root: {
        '& .MuiSelect-select': {
            textAlignLast: 'center',
        }
    }
})(TablePagination);