import React, {PureComponent, useEffect, useState} from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';



export default function PieChartComponent(props){
    const [data, setData] = useState(parseData(props.data));
    function parseData(data){
        let arr = [];
        if (data.delivery?.total > 0){
            arr.push({
                name:'Delivery',
                value: data.delivery.percent,
                total: data.delivery.total,
                color: '#FFC558'
            })
        }
        if (data.pickup?.total > 0){
            arr.push({
                name:'Pickup',
                value: data.pickup.percent,
                total: data.pickup.total,
                color: '#8883D8'
            })
        }
        if (data.exchange?.total > 0){
            arr.push({
                name:'Exchange',
                value: data.exchange.percent,
                total: data.exchange.total,
                color:  '#81CA9C'
            })
        }
        return arr;
    }

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text style={{fontSize:16, fontWeight:'bold'}} x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${data[index].name} - ${data[index].total} (${(percent * 100).toFixed(0)}%)`}
            </text>
        );
    };

        return (
                <PieChart width={510} height={510}>
                    <Pie
                        data={parseData(props.data)}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={250}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                </PieChart>
        );
    }

