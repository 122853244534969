import React, {useCallback, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { endpoints } from "../../../constants/endpoints";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import ExportForm from "../../../common/exportForm/exportForm";
import Select from "react-select";
import moment from "moment";
import {checkTokenAvailability, getTokenInformation} from "../../../common/utils/utils";
import { logout } from "../../../redux/actions";
import { useHistory, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Table } from "../../../common/table/table";
import jwt from "jwt-decode";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Tooltip } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {totalSpentTimeValues} from "../../../constants/types/reportTypes"
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Legend
} from "recharts";
import {Tooltip as RTooltip}  from  "recharts";
import {sectionTitles} from "../../../constants/names/titles";
import {utilsTokenIdentifiers} from "../../../constants/inputs/values";

const selectStyles = {
  menu: (base) => ({
    ...base,
    textAlign: "left",
  }),
};

export const BillingReport = (props) => {
  const location = useLocation();
  const oofReport = location.pathname === "/reports/create/oofreport";
  const isLogged = useSelector((state) => state.isLogged);
  const [contractedProvidersList, setContractedProvidersList] = useState([]);
  const [contractedProviders, setContractedProviders] = useState([]);
  const classes = useStyles();
  const userData = jwt(
    JSON.parse(localStorage.getItem("key")).accessToken
  ).payload;
  const [isLoading, setIsLoading] = useState(true);
  const [years, setYears] = useState([]);
  const [values, setValues] = useState({
    dme_id: userData.dme_organization_id,
    hospices_ids: [],
    oofReport: oofReport,
    month: null,
    year: null,
    spendTotalInfo: false,
  });
  const [orgData, setOrgData] = useState([]);
  const [results, setResults] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [exportValues, setExportValues] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  let token = localStorage.getItem("key");
  let currentUser = JSON.parse(token);
  let user = jwt(currentUser.accessToken);
  const yearRef = React.createRef();
  const monthRef = React.createRef();
  const [currentMonthTotal, setCurrentMonthTotal] = useState(0);
  const [previousMonthTotal, setPreviousMonthTotal] = useState(0);
  const [ytdOOF, setYtdOOF] = useState(0);
  const [categoryData, setCategoryData] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [expandedTotalCost, setExpandedTotalCost] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#d90115",
    "#fad5ff",
    "#f09b72",
    "#95d059",
    "#a2707b",
    "#edf201",
    "#12b36b",
    "#376394",
    "#26f961",
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    data,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const getContractedProviders = () => {
    if (userData.hospice_organization_id !== null) {
      const data = JSON.parse(localStorage.getItem("key"));
      fetch(
        `${endpoints.getHospiceContractedProviders}${userData.hospice_organization_id}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getTokenInformation(history, utilsTokenIdentifiers.accessToken),
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          let object = [];
          for (let i = 0; i < response.data.length; i++) {
            object.push({
              value: response.data[i].dme_id,
              label: response.data[i].dme_name,
            });
          }
          setContractedProviders(response.data);
          setContractedProvidersList(object);
          if (response.data.length === 1) {
            setValues((prevState) => {
              return { ...prevState, dme_id: response.data[0].dme_id };
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err, "error");
          enqueueSnackbar("Something happened. Contact support.", {
            persist: false,
            variant: "error",
          });
          setIsLoading(false);
        });
    }
  };

  const getData = () => {
    setIsLoading(true);
    (async () => {
      const availableToken = await checkTokenAvailability();
      if (!availableToken) {
        dispatch(logout());
        history.push("/login");
      }
    })();
    const token = JSON.parse(localStorage.getItem("key"));
    const userData = jwt(token.accessToken);
    if (userData.payload.hospice_organization_id === null) {
      fetch(
        `${endpoints.getHospicesByDme}${userData.payload.dme_organization_id}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getTokenInformation(history, utilsTokenIdentifiers.accessToken),
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          setOrgData(response.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err, "error");
          enqueueSnackbar("Something happened. Contact support.", {
            persist: false,
            variant: "error",
          });
          setIsLoading(false);
        });
    } else {
      fetch(
        `${endpoints.getHospiceRegions}${userData.payload.hospice_organization_id}`,
        {
          method: "get",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getTokenInformation(history, utilsTokenIdentifiers.accessToken),
          },
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          let data = _.cloneDeep(response.data);
          let newArr = [];
          data.map((region) => {
            newArr.push({
              region_name: region.name,
              region_id: region.region_id,
            });
          });
          setOrgData([
            {
              hospiceName: userData.payload.hospice_name,
              hospice_id: userData.payload.hospice_organization_id,
              regions: newArr,
            },
          ]);
          setValues({
            ...values,
            hospices_ids: [
              {
                hospiceName: userData.payload.hospice_name,
                hospice_id: userData.payload.hospice_organization_id,
                regions: newArr,
              },
            ],
          });
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err, "error");
          enqueueSnackbar("Something happened. Contact support.", {
            persist: false,
            variant: "error",
          });
          setIsLoading(false);
        });
    }
  };
  const clearFilters = () => {
    monthRef.current.select.clearValue();
    yearRef.current.select.clearValue();
    setResults([]);
    if (userData.type === "DME") {
      setValues({
        dme_id: userData.dme_organization_id,
        hospices_ids: [],
        oofReport: oofReport,
        month: null,
        year: null,
      });
      setCurrentMonthTotal(0);
      setPreviousMonthTotal(0);
      setYtdOOF(0)
    }
    if (userData.type === "Hospice") {
      setValues({
        dme_id: null,
        hospices_ids: [
          {
            hospiceName: userData.hospice_name,
            hospice_id: userData.hospice_organization_id,
            regions: [],
          },
        ],
        oofReport: oofReport,
        month: null,
        year: null,
      });
      setCurrentMonthTotal(0);
      setPreviousMonthTotal(0);
      setYtdOOF(0)
    }
  };

  useEffect(() => {
    if (orgData !== undefined && orgData.length === 0) {
      getData();
      getContractedProviders();
    }
  }, [values.dme_id]);

  function getYears() {
    let arr = [];
    let date = new Date();
    let n = date.getFullYear();
    for (let i = 2020; i <= n; i++) {
      arr.push({
        value: i,
        label: i,
      });
    }
    setYears(arr);
  }
  useEffect(() => {
    getYears();
  }, []);

  const generateData = (results) => {
    const categoryMap = new Map();
    const itemMap = new Map();
    const categoryArray = [];
    const itemArray = [];

    results.forEach((item) => {
      const itemKey = item.category;
      const itemSubKey = item.name;
      if (item.lineTotal) {
        if (categoryMap.has(itemKey)) {
          categoryMap.set(itemKey, Number(categoryMap.get(itemKey)) + Number(item.lineTotal));
        } else {
          categoryMap.set(itemKey, Number(item.lineTotal));
        }
        if (itemMap.has(`${itemSubKey}`)) {
          itemMap.set(`${itemSubKey}`, Number(itemMap.get(`${itemSubKey}`)) + Number(item.lineTotal));
        } else {
          itemMap.set(`${itemSubKey}`, Number(item.lineTotal));
        }
      }
    });

    categoryMap.forEach((value, key) => (categoryArray.push({name: key, value: value})));
    itemMap.forEach((value, key) => (itemArray.push({name: key, value: value})));
    setCategoryData(categoryArray);
    setItemData(itemArray);
  };

  const generateSpentTotalData = (time) => {
    setIsLoading(true);
    (async () => {
      const availableToken = await checkTokenAvailability();
      if (!availableToken) {
        dispatch(logout());
        history.push("/login");
      }
    })();
    const token = JSON.parse(localStorage.getItem("key"));
    fetch(endpoints.getBillingReport, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenInformation(history, utilsTokenIdentifiers.accessToken),
      },
      body: JSON.stringify({...values, spendTotalInfo: true, time: time}),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        switch (time) {
          case totalSpentTimeValues.currentMonthTotal:
            setCurrentMonthTotal(response.data.currentMonthTotal);
            break;
          case totalSpentTimeValues.previousMonthTotal:
            setPreviousMonthTotal(response.data.previousMonthTotal);
            break;
          case totalSpentTimeValues.YTD:
            setYtdOOF(response.data.YTD);
            break;
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        enqueueSnackbar("Something happened. Contact support.", {
          persist: false,
          variant: "error",
        });
      });
  };

  const handleSelectAll = (values) => {
    let checkSelectAll = true;
    if(values.length === orgData.length){
      for(const item of values){
        if(item === undefined){
          checkSelectAll = false;
        }
        if(item && Object.keys(item).length === 0){
          checkSelectAll = false
        }
      }
    }
    if(values.length !== orgData.length){
      checkSelectAll = false;
    }
    setSelectAll(checkSelectAll);
  }

  const generateReport = () => {
    setIsLoading(true);
    setExpandedTotalCost(false);
    setCurrentMonthTotal(0);
    setPreviousMonthTotal(0);
    setYtdOOF(0);
    (async () => {
      const availableToken = await checkTokenAvailability();
      if (!availableToken) {
        dispatch(logout());
        history.push("/login");
      }
    })();
    const token = JSON.parse(localStorage.getItem("key"));
    fetch(endpoints.getBillingReport, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getTokenInformation(history, utilsTokenIdentifiers.accessToken),
      },
      body: JSON.stringify(values),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {;
        setResults(response.data);
        generateData(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        enqueueSnackbar("Something happened. Contact support.", {
          persist: false,
          variant: "error",
        });
      });
  };
  useEffect(() => {
    if (results !== undefined) {
      let final = [];
      results.map((value) => {
        let newArr = [];
        newArr.push(value.hospice_name);
        newArr.push(value.team_name);
        newArr.push(value.facility_name);
        newArr.push(value.patient_id);
        newArr.push(value.first_name);
        newArr.push(value.last_name);
        newArr.push(value.medical_record);
        newArr.push(value.name);
        newArr.push(
          value.supplemental_price !== null ? value.supplemental_price : 0
        );
        newArr.push(new Date(moment(value.delivery_date).format("YYYY-MM-DD")));
        newArr.push(
          value.pickup_date
            ? new Date(moment(value.pickup_date).format("YYYY-MM-DD"))
            : ""
        );
        newArr.push(value.dos);
        newArr.push(value.quantity);
        newArr.push(value.extendedQty);
        newArr.push(value.rf);
        newArr.push(value.pf);
        newArr.push(value.lineSTotal);
        newArr.push(value.salesTax);
        newArr.push(value.lineTotal);
        newArr.push(
          value.delivery_order_id !== null
            ? "D100" + value.delivery_order_id
            : null
        );
        newArr.push(
          value.pickup_order_id !== null ? "P200" + value.pickup_order_id : null
        );
        newArr.push(
          value.exchange_order_id !== null
            ? "S300" + value.exchange_order_id
            : null
        );
        final.push(newArr);
      });
      setExportValues(final);
    }
  }, [results]);
  const headers = [
    { text: "Organization_Name", style: "tableHeader" },
    { text: "Team", style: "tableHeader" },
    { text: "Facility", style: "tableHeader" },
    { text: "Patient ID", style: "tableHeader" },
    { text: "Patient First Name", style: "tableHeader" },
    { text: "Patient Last Name", style: "tableHeader" },
    { text: "Medical Record Number", style: "tableHeader" },
    { text: "Item Name", style: "tableHeader" },
    { text: "Item Price", style: "tableHeader" },
    { text: "Delivery Date", style: "tableHeader" },
    { text: "Pickup Date", style: "tableHeader" },
  ];
  const excelHeaders = [
    "Organization_Name",
    "Team",
    "Facility",
    "Patient_ID",
    "Patient_First_Name",
    "Patient_Last_Name",
    "Medical_Record_Number",
    "Item_Name",
    "Item_Price",
    "Delivery_Date",
    "Pickup_Date",
    "DOS",
    "QTY",
    "Extended_Qty",
    "Rental_Fee",
    "Purchase_Fee",
    "Line_STotal",
    "Sales_Tax",
    "Line_Total",
    "Delivery_Order_Id",
    "Pickup_Order_Id",
    "Exchange_Order_Id",
  ];
  const columns = [
    { title: "Organization", field: "hospice_name", align: "center" },
    {
      title: "Team",
      field: "team_name",
      align: "center",
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Facility",
      field: "facility_name",
      align: "center",
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Patient ID",
      field: "patient_id",
      align: "center",
      headerStyle: {
        textAlign: "center",
      },
    },
    { title: "Last Name", field: "last_name" },
    { title: "First Name", field: "first_name" },
    {
      title: "Medical Record Number",
      field: "medical_record",
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Item Name",
      field: "name",
      headerStyle: {
        textAlign: "center",
      },
    },
    {
      title: "Item Price",
      field: "supplemental_price",
      headerStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div>
          {rowData.supplemental_price === null
            ? "0"
            : rowData.supplemental_price}
        </div>
      ),
    },
    {
      title: "Delivery Date",
      field: "delivery_date",
      align: "center",
      headerStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div>{moment(rowData.delivery_date).format("YYYY-MM-DD")}</div>
      ),
    },
    {
      title: "Pickup Date",
      field: "pickup_date",
      align: "center",
      headerStyle: {
        textAlign: "center",
      },
      render: (rowData) => (
        <div>
          {rowData.pickup_date &&
            moment(rowData.pickup_date).format("YYYY-MM-DD")}
        </div>
      ),
    },
  ];

  const totalSpentComponent = (value) => {
    if(value === 0) {
      return <CircularProgress
          style={{ width: 20, height: 20 }}
          color="inherit"
          spacing={3}
      />
    }
  return (
      <Typography>
        {value > 0 ? (
            ` $ ${value.toFixed(2)}`
        ) : "No Data"}
      </Typography>
  )
  }

  return (
    <div className={classes.div}>
      {isLogged && (
        <Grid container className={classes.container}>
          <Typography className={classes.maintitle}>
            {oofReport ? "OOF Report" : "Billing Summary Report"}
          </Typography>
          <Grid container className={classes.select} justify={"space-between"}>
            <Grid
              item
              xs={12}
              md={user.payload.type === "DME" ? 5 : 3}
              sm={user.payload.type === "DME" ? 8 : 5}
            >
              <Grid container style={{ display: "flex" }}>
                {user.payload.type === "DME" && (
                  <Grid item xs={6}>
                    <Typography className={classes.title}>
                      Select organization
                    </Typography>
                  </Grid>
                )}
                {values.hospices_ids.length > 0 && (
                  <Grid item xs={6}>
                    <Typography className={classes.title}>
                      Select region
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item xs={user.payload.type === "DME" ? 6 : 12}>
                <div className={classes.org} style={{display: "flex", paddingLeft: 8,}}>
                                <span
                                    style={{display: "flex", paddingLeft: 8, textAlign: "left" }}
                                >
                                  Select All
                                </span>
                  <Checkbox
                      checked={selectAll}
                      style={{ height: 20 }}
                      color="primary"
                      onChange={() => {
                        setSelectAll(prevState => !prevState)
                        if(user.payload.type === "DME") {
                          selectAll === false ? setValues({...values, hospices_ids: orgData}) : setValues({
                            ...values,
                            hospices_ids: [].fill({}, orgData.length)
                          })
                        }
                        if(user.payload.type === "Hospice"){
                          selectAll === false ? setValues({...values, hospices_ids: orgData}) : setValues({
                            ...values,
                            hospices_ids: [{
                              hospiceName: userData.hospice_name,
                              hospice_id: userData.hospice_organization_id,
                              regions: [],
                            }],
                          })
                        }
                      }}
                  />
                </div>
              </Grid>
              <div
                style={{
                  width: user.payload.type === "DME" ? "100%" : "100%",

                }}
              >
                {orgData !== undefined &&
                  orgData.map((org, i) => {
                    return (
                      <React.Fragment>
                        {user.payload.type === "DME" ? (
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", padding: "4px 4px 4px 0px" }}
                          >
                            <Grid item xs={6}>
                              <div className={classes.org}>
                                <span
                                  style={{paddingLeft: 8, textAlign: "left" }}
                                >
                                  {org.hospiceName}
                                </span>
                                <Checkbox
                                  checked={
                                    _.findIndex(values.hospices_ids, {
                                      hospiceName: orgData[i].hospiceName,
                                    }) > -1
                                  }
                                  style={{ height: 20 }}
                                  color="primary"
                                  onChange={() => {
                                    let a = _.cloneDeep(values);
                                    let index = _.findIndex(
                                      values.hospices_ids,
                                      { hospiceName: orgData[i].hospiceName }
                                    );
                                    if (index > -1) {
                                      a.hospices_ids[index] = {};
                                    } else {
                                      a.hospices_ids[i] = orgData[i];
                                    }
                                    handleSelectAll(a.hospices_ids);
                                    setValues(a);
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              {orgData[i].regions.map((reg, index) => {
                                return (
                                  <React.Fragment>
                                    {_.findIndex(values.hospices_ids, {
                                      hospiceName: orgData[i].hospiceName,
                                    }) !== -1 && (
                                      <div className={classes.region}>
                                        <span
                                          style={{
                                            paddingLeft: 12,
                                            textAlign: "left",
                                          }}
                                        >
                                          {reg.region_name}
                                        </span>
                                        <Checkbox
                                          checked={
                                            values.hospices_ids[i] !==
                                              undefined &&
                                            _.findLastIndex(
                                              values.hospices_ids[i].regions,
                                              {
                                                region_name:
                                                  orgData[i].regions[index]
                                                    .region_name,
                                              }
                                            ) > -1
                                          }
                                          style={{ height: 20 }}
                                          color="primary"
                                          onChange={() => {
                                            let a = _.cloneDeep(values);
                                            let indexHospice = _.findIndex(
                                              values.hospices_ids,
                                              {
                                                hospiceName:
                                                  orgData[i].hospiceName,
                                              }
                                            );
                                            let indexRegion = _.findIndex(
                                              values.hospices_ids[i].regions,
                                              {
                                                region_name:
                                                  orgData[i].regions[index]
                                                    .region_name,
                                              }
                                            );
                                            if (indexRegion > -1) {
                                              a.hospices_ids[
                                                indexHospice
                                              ].regions.splice(indexRegion, 1);
                                            } else {
                                              a.hospices_ids[
                                                indexHospice
                                              ].regions.push(reg);
                                            }
                                            setValues(a);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", padding: "8px 12px 8px 0px" }}
                          >
                            <Grid item xs={12}>
                              {orgData[i].regions.map((reg, index) => {
                                return (
                                  <React.Fragment>
                                    {_.findIndex(values.hospices_ids, {
                                      hospiceName: orgData[i].hospiceName,
                                    }) !== -1 && (
                                      <div className={classes.region}>
                                        <span style={{ paddingLeft: 12 }}>
                                          {reg.region_name}
                                        </span>
                                        <Checkbox
                                          checked={
                                            values.hospices_ids[i] !==
                                              undefined &&
                                            _.findLastIndex(
                                              values.hospices_ids[i].regions,
                                              {
                                                region_name:
                                                  orgData[i].regions[index]
                                                    .region_name,
                                              }
                                            ) > -1
                                          }
                                          style={{ height: 20 }}
                                          color="primary"
                                          onChange={() => {
                                            let a = _.cloneDeep(values);
                                            let indexHospice = _.findIndex(
                                              values.hospices_ids,
                                              {
                                                hospiceName:
                                                  orgData[i].hospiceName,
                                              }
                                            );
                                            let indexRegion = _.findIndex(
                                              values.hospices_ids[i].regions,
                                              {
                                                region_name:
                                                  orgData[i].regions[index]
                                                    .region_name,
                                              }
                                            );
                                            if (indexRegion > -1) {
                                              a.hospices_ids[
                                                indexHospice
                                              ].regions.splice(indexRegion, 1);
                                            } else {
                                              a.hospices_ids[
                                                indexHospice
                                              ].regions.push(reg);
                                            }
                                            setValues(a);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </Grid>
                          </Grid>
                        )}
                      </React.Fragment>
                    );
                  })}
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              md={user.payload.type === "DME" ? 4 : 6}
              sm={user.payload.type === "DME" ? 4 : 7}
              style={{
                paddingLeft: "8px",
                paddingTop: "40px",
                paddingRight: "8px",
              }}
            >
              <Grid container spacing={2}>
                {userData.hospice_organization_id !== null && (
                  <Grid
                    item
                    xs={6}
                    md={user.payload.type === "DME" ? 6 : 4}
                    sm={12}
                    style={{ zIndex: 302 }}
                  >
                    <Select
                      className={classes.z}
                      classNamePrefix="select"
                      placeholder="Select provider..."
                      name="color"
                      value={
                        contractedProvidersList.findIndex(
                          (provider) => provider.value === values.dme_id
                        ) > -1 && values.dme_id
                          ? contractedProvidersList[
                              contractedProvidersList.findIndex(
                                (provider) => provider.value === values.dme_id
                              )
                            ]
                          : null
                      }
                      onChange={(e) => {
                        setValues({ ...values, dme_id: e.value });
                      }}
                      options={contractedProvidersList}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={6}
                  md={user.payload.type === "DME" ? 6 : 4}
                  sm={12}
                >
                  <Select
                    ref={monthRef}
                    className={classes.z}
                    classNamePrefix="select"
                    placeholder="Select month..."
                    name="color"
                    onChange={(e) => {
                      e && setValues({ ...values, month: e.value });
                    }}
                    options={[
                      { value: "01", label: "January" },
                      { value: "02", label: "February" },
                      { value: "03", label: "March" },
                      { value: "04", label: "April" },
                      { value: "05", label: "May" },
                      { value: "06", label: "June" },
                      { value: "07", label: "July" },
                      { value: "08", label: "August" },
                      { value: "09", label: "September" },
                      { value: 10, label: "October" },
                      { value: 11, label: "November" },
                      { value: 12, label: "December" },
                    ]}
                    styles={selectStyles}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={user.payload.type === "DME" ? 6 : 4}
                  sm={12}
                >
                  <Select
                    ref={yearRef}
                    className={classes.z}
                    classNamePrefix="select"
                    placeholder="Select year..."
                    onChange={(e) => {
                      e && setValues({ ...values, year: e.value });
                    }}
                    name="color"
                    options={years}
                    styles={selectStyles}
                  />
                </Grid>
                <Tooltip
                  title={
                    !(
                      values?.month &&
                      values?.year &&
                      values?.hospices_ids.length > 0
                    )
                      ? "Make sure Hospices, Month and Year fields are filled."
                      : ""
                  }
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color={"primary"}
                      fullWidth
                      disabled={
                        !(
                          values?.month &&
                          values?.year &&
                          values?.hospices_ids.length > 0
                        )
                      }
                      onClick={generateReport}
                    >
                      Generate Report
                    </Button>
                  </Grid>
                </Tooltip>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color={"primary"}
                    fullWidth
                    onClick={clearFilters}
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {results.length > 0 &&

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={user.payload.type === "DME" ? 4 : 6}
              sm={user.payload.type === "DME" ? 4 : 7}
            >
              <Accordion expanded={expandedTotalCost} onClick={() => setExpandedTotalCost(prevState => !prevState)}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={(e) => {

                    currentMonthTotal === 0 && expandedTotalCost === false && generateSpentTotalData(totalSpentTimeValues.currentMonthTotal);
                    previousMonthTotal === 0 && expandedTotalCost === false && generateSpentTotalData(totalSpentTimeValues.previousMonthTotal);
                    ytdOOF === 0 && expandedTotalCost === false && generateSpentTotalData(totalSpentTimeValues.YTD);
                  }}
                >
                  <Typography className={classes.heading}>
                    {sectionTitles.oofSpendTotal}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: "column" }}>
                  <Typography>{sectionTitles.currentSelectedMonthTotal}</Typography>
                  {totalSpentComponent(currentMonthTotal)}
                  <Typography>Previous Month Total:</Typography>
                  {totalSpentComponent(previousMonthTotal)}
                  <Typography>YTD OOF Spend:</Typography>
                  {totalSpentComponent(ytdOOF)}
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid
                item
                xs={12}
            >
              <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Item Breakdown
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant={"h6"}>
                        Category-Item Chart
                      </Typography>
                      <PieChart width={1000} height={500}>
                        <Legend
                            layout="vertical"
                            align="right"
                            verticalAlign="top"
                            height={36}
                            // content={renderLegend}
                            payload={
                              [...categoryData, ...itemData].map(
                                  (item, index) => {
                                    const itemKeys = [];
                                    const catKeys = [];
                                    categoryData.forEach(item => catKeys.push(item.name));
                                    itemData.forEach(item => itemKeys.push(item.name));
                                    return {
                                      id: item.name,
                                      type: "square",
                                      value: catKeys.includes(item.name) ? `Category - ${item.name} ($${item.value.toFixed(2)})` : `Item - ${item.name} ($${item.value.toFixed(2)})`,
                                      color: catKeys.includes(item.name) ? COLORS[index % COLORS.length] : COLORS[(index - catKeys.length) % COLORS.length],
                                    }
                                  }
                              )
                            }
                        />
                        <Pie
                            isAnimationActive={false}
                            data={categoryData}
                            dataKey="value"
                            cx={400}
                            cy={200}
                            outerRadius={60}
                            labelLine={false}
                            label={renderCustomizedLabel}
                            // fill="#8884d8"
                        >
                          {categoryData.map((entry, index) => (
                              <Cell
                                  key={`cell-${index}`}
                                  fill={
                                    index < COLORS.length
                                        ? COLORS[index]
                                        : Math.floor(Math.random() * 16777215).toString(
                                        16
                                        )
                                  }
                              />
                          ))}
                        </Pie>
                        <RTooltip/>
                        <Pie
                            isAnimationActive={false}
                            data={itemData}
                            dataKey="value"
                            cx={400}
                            cy={200}
                            innerRadius={70}
                            outerRadius={90}
                            fill={"#8884d8"}
                            label={({
                                      cx,
                                      cy,
                                      midAngle,
                                      innerRadius,
                                      outerRadius,
                                      value,
                                      index,
                                    }) => {
                              const RADIAN = Math.PI / 180;
                              // eslint-disable-next-line
                              const radius =
                                  25 + innerRadius + (outerRadius - innerRadius);
                              // eslint-disable-next-line
                              const x = cx + radius * Math.cos(-midAngle * RADIAN);
                              // eslint-disable-next-line
                              const y = cy + radius * Math.sin(-midAngle * RADIAN);

                              return (
                                  <text
                                      x={x}
                                      y={y}
                                      fill={
                                        index < COLORS.length
                                            ? COLORS[index]
                                            : Math.floor(Math.random() * 16777215).toString(
                                            16
                                            )
                                      }
                                      textAnchor={x > cx ? "start" : "end"}
                                      dominantBaseline="central"
                                  >
                                    {itemData[index].name} ${value.toFixed(2)}
                                  </text>
                              );
                            }}
                        >
                          {itemData.map((entry, index) => (
                              <Cell
                                  key={`cell-${index}`}
                                  fill={
                                    index < COLORS.length
                                        ? COLORS[index]
                                        : Math.floor(Math.random() * 16777215).toString(
                                        16
                                        )
                                  }
                              />
                          ))}
                        </Pie>
                      </PieChart>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={"h6"}>
                        Item Chart
                      </Typography>
                      <PieChart width={1000} height={500}>
                        <Legend
                            layout="vertical"
                            align="right"
                            verticalAlign="top"
                            height={36}
                            // content={renderLegend}
                            payload={
                              itemData.map(
                                  (item, index) => {
                                    return {
                                      id: item.name,
                                      type: "square",
                                      value: `${item.name} ($${item.value.toFixed(2)})`,
                                      color: COLORS[index % COLORS.length]
                                    }
                                  }
                              )
                            }
                        />
                        <RTooltip/>
                        <Pie
                            isAnimationActive={false}
                            data={itemData}
                            dataKey="value"
                            cx={400}
                            cy={200}
                            outerRadius={100}
                            fill={"#8884d8"}
                            label={({
                                      cx,
                                      cy,
                                      midAngle,
                                      innerRadius,
                                      outerRadius,
                                      value,
                                      index,
                                    }) => {
                              const RADIAN = Math.PI / 180;
                              // eslint-disable-next-line
                              const radius =
                                  25 + innerRadius + (outerRadius - innerRadius);
                              // eslint-disable-next-line
                              const x = cx + radius * Math.cos(-midAngle * RADIAN);
                              // eslint-disable-next-line
                              const y = cy + radius * Math.sin(-midAngle * RADIAN);

                              return (
                                  <text
                                      x={x}
                                      y={y}
                                      fill={
                                        index < COLORS.length
                                            ? COLORS[index]
                                            : Math.floor(Math.random() * 16777215).toString(
                                            16
                                            )
                                      }
                                      textAnchor={x > cx ? "start" : "end"}
                                      dominantBaseline="central"
                                  >
                                    {itemData[index].name} ${value.toFixed(2)}
                                  </text>
                              );
                            }}
                        >
                          {itemData.map((entry, index) => (
                              <Cell
                                  key={`cell-${index}`}
                                  fill={
                                    index < COLORS.length
                                        ? COLORS[index]
                                        : Math.floor(Math.random() * 16777215).toString(
                                        16
                                        )
                                  }
                              />
                          ))}
                        </Pie>
                      </PieChart>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>}

          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 20,
              marginBottom: 12,
            }}
          >
            <ExportForm
              fileName={
                oofReport
                  ? `OOF_Rep_${moment().format("MMDDYYYY_HHMM")}.xlsx`
                  : `DME_FinRep_${moment().format("MMDDYYYY_HHMM")}.xlsx`
              }
              exportValues={exportValues}
              headers={headers}
              excelHeaders={excelHeaders}
              showPrint={false}
            />
          </Grid>
          <Grid container style={{ maxWidth: "100vw" }}>
            <Table
                showSearch={true}
              search={true}
              filtering={true}
              columns={columns}
              items={results}
              backGroundColor={"rgba(237, 242, 244, 0.73)"}
              textColor={"rgba(46, 50, 54, 1)"}
            />
          </Grid>

          <Backdrop className={classes.backdrop} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  div: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignContent: "center",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  root: {
    flexGrow: 1,
    // maxWidth: 1920,
    height: "90vh",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 48,
      paddingRight: 20,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 48,
      paddingRight: 10,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  h1: {
    paddingTop: 58,
    textAlign: "left",
    color: "#00223D",
    fontSize: 28,
    fontWeight: "bold",
    lineHeight: "38px",
    borderBottom: "1px solid #B2D1D9",
    marginBottom: 4,
    textTransform: "uppercase",
  },
  container: {
    paddingTop: 12,
    maxWidth: 1200,
  },
  title: {
    textAlign: "left",
    padding: 12,
    paddingBottom: 0,
    width: 200,
  },
  maintitle: {
    textAlign: "left",
    padding: 12,
    paddingBottom: 0,
    fontWeight: "bold",
    fontSize: 20,
  },
  select: {
    // paddingTop: 12,
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "left"
  },
  z: {
    zIndex: 300,
  },
  org: {
    border: "1px solid lightgrey",
    borderRadius: 4,
    marginRight: 12,
    // height: 40,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "9px 0px",
  },
  region: {
    border: "1px solid lightgrey",
    height: 40,
    borderRadius: 4,
    marginBottom: 12,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
