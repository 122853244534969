import React, {useEffect, useState} from 'react';
import {CircularProgress, Grid} from "@material-ui/core";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import Select from "react-select";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {endpoints} from "../../../constants/endpoints";
import {func} from "prop-types";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useDispatch} from "react-redux";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {maxInputLength} from "../../../constants/inputs/values";




export default function AddItemDocuments() {
    let history = useHistory();
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);
    const [selectedType, setSelectedType] =useState(null);
    const [selectedItem, setSelectedItem] =useState(null);
    const [videoUrl, setVideoUrl] =useState('');
    const [title, setTitle] =useState('');
    const [description, setDescription] =useState('');
    const [items, setItems] = useState([]);
    const [allItemsLoading, setAllItemsLoading] = useState(true);
    const [resourcesList, setResourcesList] = useState([]);
    const [resourcesLoading, setResourcesLoading] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [activePreview, setActivePreview] = useState('');
    const [activeVideoUrl, setActiveVideoUrl] = useState('');
    const [file, setFile] = useState('');
    const [image, setImage] = useState('');


    function getAllMasterListItems() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        (async () => {
            const token = JSON.parse(localStorage.getItem('key'));
            const promise = await fetch(endpoints.getAllMasterItems, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + token.accessToken
                }
            });
            const response = await promise.json();
            let arr= [];
            response.data.map(item => {
                arr.push({
                    value:item.item_id,
                    label:item.name
                })
            });
            setAllItemsLoading(false);
            setItems(arr);
        })();
    }

    useEffect(() => {
        getAllMasterListItems()
    }, []);

    function createItemResource() {
        setOpenModal(false);
        setResourcesLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        (async () => {
            const token = JSON.parse(localStorage.getItem('key'));
            const promise = await fetch(endpoints.createItemResource, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    items: [{
                        title:title,
                        type:selectedType.value,
                        video_url:videoUrl,
                        file_name:file[0] ? file[0].name : "",
                        item_id:selectedItem.value,
                        description: description,
                    }]
                })

            });
            const response = await promise.json();
            file[0] && await uploadFile(file[0])
            setOpenModal(false);
            getAllItemResources();
           
        })();
    }
    
    function getAllItemResources() {
        setResourcesLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        (async () => {
            const token = JSON.parse(localStorage.getItem('key'));
            const promise = await fetch(`${endpoints.getAllItemResources}/${selectedItem.value}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + token.accessToken
                }
            });
            const response = await promise.json();
            setResourcesList(response.data);
            setResourcesLoading(false);
        })();
    }

    useEffect(()=> {
        selectedItem !== null && getAllItemResources();
    }, [selectedItem]);

    function deleteResource(id){
        setResourcesLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        (async () => {
            const token = JSON.parse(localStorage.getItem('key'));
            const promise = await fetch(`${endpoints.getAllItemResources}/${id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + token.accessToken
                }
            });
            const response = await promise.json();
            getAllItemResources();
        })();
    }

    useEffect(()=> {
        if (activePreview.video_url) {
            let videoId = activePreview.video_url.split("/");
            videoId = videoId[videoId.length - 1];
            setActiveVideoUrl(videoId)
        }
    },[activePreview.video_url]);


    async function uploadFile (file) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.uploadItemResource, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({fileName:  file.name})
        });
        const result1 = await promise1.json();
        try{
            const promiseS3 = await fetch(result1.data, { method: 'PUT', body: file});
        } catch (e) {
            console.log(e.stack);
        }
    }

    const previewExisting = async (file) => {
        try {
            const token = JSON.parse(localStorage.getItem("key"));
            const promise = await fetch(endpoints.getUploadItemResource, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authorization": "Bearer " + token.accessToken,
                },
                body: JSON.stringify({
                    fileName: file
                })
            });
            const result = await promise.json();
            setImage(result.data);
        } catch (error) {
            // handle error
        }


    };


    return (
        <Grid container justify={'center'} style={{padding:24}}>
            <Paper elevation={0} style={{padding:24, width:'100%', maxWidth:1100}}>
                <Grid container>
                    <Grid item xs={12} style={{fontSize:24, fontWeight:'bold', display:'flex', paddingBottom:16}}>
                        Add Item Resources
                    </Grid>
                </Grid>
                <Grid container justify={'space-between'} spacing={2} style={{paddingBottom:12}}>
                    <Grid item md={5} sm={6} xs={12} style={{zIndex:100}}>
                        <div style={{paddingTop:12, textAlign:'left'}}>Item</div>
                        <Select
                            placeholder={'Select item'}
                            options={items}
                            value={selectedItem}
                            onChange={(e)=> setSelectedItem(e)}
                            isLoading={allItemsLoading}
                        />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12} style={{display:'flex', justifyContent:'flex-end', alignItems:'flex-end', alignContent:'flex-end'}}>
                        <Button variant={'outlined'} color={'primary'} onClick={()=> setOpenModal(true)} style={{maxHeight:40}} disabled={selectedItem === null}>Add New Resource</Button>
                    </Grid>
                </Grid>
                    <MaterialTable
                        title=""
                        isLoading={resourcesLoading}
                        columns={[
                            { title: 'Resource', field: 'resource',
                                render: rowData => <React.Fragment>
                                   <span>{rowData.type === 'video' && rowData.video_url}</span>
                                   <span>{(rowData.type === 'image' || rowData.type === 'document') && rowData.file_name}</span>
                                </React.Fragment>,
                            },
                            { title: 'Type', field: 'type', size:20 },
                            { title: 'Title', field: 'title' },
                            {title: 'Description', field: 'description',},
                            { title: 'Actions', field: 'actions', width:240,
                                render: rowData => <Grid container spacing={1}>
                                    <Grid item>
                                        <Button variant={'outlined'} color={'primary'} size={'small'} onClick={()=> {
                                            setPreviewModal(true);
                                            setActivePreview(rowData);
                                            if (rowData.type !== 'video'){
                                                previewExisting(rowData.file_name);
                                            }
                                        }}
                                        startIcon={<VisibilityIcon/>}
                                        >Preview</Button>
                                    </Grid>
                                   <Grid item>
                                       <Button variant={'outlined'} color={'primary'} size={'small'}
                                               startIcon={<DeleteForeverIcon/>}
                                               onClick={()=> deleteResource(rowData.resource_id)} style={{color:'red', borderColor:'red'}}>Delete</Button>
                                   </Grid>
                                </Grid>},
                        ]}
                        data={resourcesList}
                        options={{
                            search: true
                        }}
                    />

                    <Dialog open={openModal} onClose={()=> setOpenModal(false)} maxWidth={'xl'}>
                        <DialogContent>
                            <Grid cotaniner style={{ minWidth:400}}>
                                <Grid item xs={12} style={{fontSize:20, fontWeight:'bold'}}>Add New Resource</Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} style={{zIndex:1000}}>
                                            <div style={{paddingTop:12}}>Type *</div>
                                            <Select
                                                placeholder={'Select type'}
                                                options={[
                                                    {label:'Video', value:'video'},
                                                    {label:'Document', value:'document'},
                                                    {label:'Image', value:'image'},
                                                ]}
                                                value={selectedType}
                                                onChange={(e)=> setSelectedType(e)}

                                            />
                                        </Grid>
                                    </Grid>

                                    {selectedType !== null && selectedType.value === 'video' && (
                                        <Grid item xs={12} style={{paddingTop:16}}>
                                            <TextField
                                                label={'Video url *'}
                                                variant={'outlined'}
                                                size={'small'}
                                                fullWidth
                                                multiline={2}
                                                value={videoUrl}
                                                onChange={(e)=> setVideoUrl(e.target.value)}
                                            />
                                        </Grid>
                                    )}

                                    {selectedType !== null && (selectedType.value === 'document' || selectedType.value === 'image') && (<React.Fragment>
                                        <Grid item xs={12} style={{paddingTop:16}}>
                                            {file === '' ? (
                                                <React.Fragment>
                                                    <input
                                                        // ref={fileRef}
                                                        style={{display: 'none'}}
                                                        id="raised-button-file"
                                                        type="file"
                                                        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.jpg,.jpeg,.png,.csv,.xls,.xlsx, .rtf"
                                                        onChange={(e) => setFile(e.target.files)}
                                                    />
                                                    <label htmlFor="raised-button-file">
                                                        <Button  component="span" fullWidth variant='outlined' color='primary'>
                                                            Select File
                                                        </Button>
                                                    </label>
                                                </React.Fragment>
                                            ) : (
                                                <div>
                                                    <span>{file[0].name}</span>
                                                    <IconButton onClick={()=> setFile('')}>
                                                        <HighlightOffIcon/>
                                                    </IconButton>
                                                </div>

                                            )}

                                        </Grid>

                                        </React.Fragment>
                                    )}
                                    <Grid item xs={12} style={{paddingTop:16}}>
                                        <TextField
                                            label={'Title'}
                                            variant={'outlined'}
                                            size={'small'}
                                            fullWidth
                                            multiline={2}
                                            value={title}
                                            onChange={(e)=> setTitle(e.target.value)}
                                            inputProps={{maxLength: maxInputLength.L255}}
                                            helperText={title && `${title.length < maxInputLength.L255 ? title.length.toString() + `/${maxInputLength.L255}`: title.length.toString() + `/${maxInputLength.L255} You have reached the character limit.` }`}
                                            error={title && title.length === maxInputLength.L255}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{paddingTop:16}}>
                                        <TextField
                                            label={'Description'}
                                            variant={'outlined'}
                                            size={'small'}
                                            fullWidth
                                            multiline
                                            rows={4}
                                            value={description}
                                            onChange={(e)=> setDescription(e.target.value)}
                                            inputProps={{maxLength: maxInputLength.L255}}
                                            helperText={description && `${description.length < maxInputLength.L255 ? description.length.toString() + `/${maxInputLength.L255}`: description.length.toString() + `/${maxInputLength.L255} You have reached the character limit.` }`}
                                            error={description && description.length === maxInputLength.L255}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{paddingTop:16, display:'flex', justifyContent:'flex-end', paddingBottom:8}}>
                                        <div style={{display:'flex'}}>
                                            <Button variant={'outlined'} color={"primary"} onClick={() => {
                                                setOpenModal(false);
                                                setTitle('');
                                                setFile('');
                                                setDescription('');
                                                setSelectedType(null);
                                                setVideoUrl('');
                                            }}
                                            style={{marginRight:8}}
                                            >Cancel</Button>
                                            <Button variant={'contained'} color={"primary"} disabled={selectedType === null} onClick={createItemResource}>Add Resource</Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                <Dialog open={previewModal} fullWidth maxWidth={'md'} onClose={()=> {
                    setPreviewModal(false);
                    setActivePreview('')
                }}>
                        <Grid container style={{paddingLeft:16, paddingRight:16}}>

                            { activePreview.type === 'video' &&  (
                                <Grid container className="container" >
                                    <div style={{paddingTop:16, paddingBottom:8, fontWeight:'bold', fontSize:16}}>{activePreview.title}</div>
                                    <div style={{
                                        position: 'relative',
                                        overflow: 'hidden',
                                        width: '100%',
                                        paddingTop: '56.25%'
                                        }}
                                    >
                                        <iframe
                                            className="responsive-iframe"
                                            src={`https://player.vimeo.com/video/${activeVideoUrl}`}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                width: '100%',
                                                height: '100%'
                                            }}
                                            frameBorder="0"
                                            allow="autoplay; fullscreen" allowFullScreen
                                        />
                                    </div>
                                    <div style={{
                                        paddingTop:8,
                                        paddingBottom:16,
                                        color:'gray',
                                        fontSize:16
                                    }}>
                                        {activePreview.description}
                                    </div>
                                </Grid>)
                            }
                            {activePreview.type === 'image' && (
                                <Grid container>
                                    <Grid xs={12}>
                                            <div style={{paddingTop:16, paddingBottom:8, fontWeight:'bold', fontSize:16}}>{activePreview.title}</div>

                                            <img src={image} style={{maxWidth: '100%',
                                                height: 'auto'}} />
                                            <div style={{
                                                paddingTop:8,
                                                paddingBottom:16,
                                                color:'gray',
                                                fontSize:16
                                            }}>
                                                {activePreview.description}
                                            </div>
                                    </Grid>
                                </Grid>
                            )}
                            {activePreview.type === 'document' && (
                                <Grid container>
                                    <Grid xs={12}>
                                        <div style={{paddingTop:16, paddingBottom:8, fontWeight:'bold', fontSize:16}}>{activePreview.title}</div>
                                        <div>
                                            <span style={{paddingRight:8, fontStyle: 'italic'}}>{activePreview.file_name}</span>
                                            <a href={image} target="_blank" style={{textDecoration:'none', marginRight:8}}>
                                                <Button variant={'outlined'} color={'primary'} size={'small'}
                                                        startIcon={<VisibilityIcon />}
                                                >
                                                    Preview file
                                                </Button>
                                            </a>
                                            <a href={image} download style={{textDecoration:'none'}}>
                                                <Button variant={'outlined'} color={'primary'} size={'small'}
                                                startIcon={<CloudDownloadIcon/>}
                                                >
                                                    Download file
                                                </Button>

                                            </a>
                                        </div>

                                        <div style={{
                                            paddingTop:8,
                                            paddingBottom:16,
                                            color:'gray',
                                            fontSize:16
                                        }}>
                                            {activePreview.description}
                                        </div>
                                    </Grid>
                                </Grid>
                            )}


                        </Grid>

                </Dialog>
            </Paper>
        </Grid>
    )
}
