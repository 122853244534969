export const componentTitles = {
    pickup: "Create New Pickup",
    exchange: "Create New Exchange",
    masterInventory: "Master Item List",
    inventoryEditItems: "Inventory Item List",
    domains: "Restricted Domains List",
    manufacturers: "Manufacturers List",
    nurses: "Nurses List",
    auditTrail: "Audit Trail Report",
    editHospice: "Edit Hospice",
    editDME: "Edit DME",
    newDME: "Add New DME Provider",
    newHospice: "Add New Hospice",
    hospiceUsers: "Hospice Users",
    orders: "Orders",
    dmeUsers: "DME Users",
    currentDmeInventory: "Current DME Inventory",
    transactionalHistory: "Transactional History",
    contracts: "Contracts",
    bulkUpload: "Bulk Upload",
    technicians: "Technicians List",
    featureEnabler: "Organization Features",
    portalUsers: "Portal Users",
    paceHospices: "PACE Organizations",
    hospicesType: "Organization / Hospice Type",
    surveyList:"Survey List",
    hospiceLogo: "Hospice Logo",
    createEditTechnicians: "Create / Edit Technicians",
};

export const sectionTitles = {
    hospiceStatus: "Hospice Status",
    hospiceProfit: "Hospice Profit",
    patientInventory: "Patient Inventory",
    exchangeCart: "Exchange Cart",
    itemsToDeliver: "Items to Deliver",
    itemSuggestions: "Suggestions",
    patientDemographics: "Patient Demographics",
    caregiverInformation: "Caregiver Information",
    finalize: "Finalize",
    patientDocumentation: "Patient Documentation",
    addNewPatient: "Add New Patient",
    editPatient: "Edit a Patient",
    patientProfile: "Patient Profile",
    quickActions: "Quick Actions",
    clinicalNotes: "Clinical Notes",
    infectiousDiseasePrecautions: "Infectious Disease Precautions",
    hospiceDetails: "Hospice Details",
    hospiceResources: "Hospice Resources",
    generalInformation: "General Information",
    regions: "Regions",
    contacts: "Contacts",
    teams: "Teams:",
    userStatus: "User Status",
    userMFA: "User MFA",
    deleteUserStatus: "Delete Status",
    selectUserWarehouses: "Select User Warehouses",
    addNewItems: "Add New Items",
    viewInventory: "View Inventory",
    exportInventory: "Export Inventory",
    viewOxygenLogs: "View Oxygen Logs",
    organizationDetails: "Organization Details",
    configureServiceAgreement: "Configure Service Agreement",
    configureServiceAgreementSecondStep: "Configure Service Agreement Second Step",
    contractReview: "Contract Review",
    editHospiceUser: "Edit Hospice User",
    addNewHospiceUser: "Add New Hospice User",
    addNewPortalUser: "Add New Portal User",
    view_patients: "View All Patients",
    sign_out: "Logout",
    my_profile: "Change Password",
    user_info: "User Information",
    patients: "Patients",
    contactUs: "Contact Us",
    supportDocuments: "Support Documents",
    serviceReportForm: "Service Report Form",
    contractStatus: "Contract Status",
    capstoneHMESupport: "Caption Support Center",
    filters: "Filters:",
    sendMessage: "Send a message",
    address: "Address",
    primaryCaregiver: "Primary Caregiver",
    secondaryCaregiver: "Secondary Caregiver",
    tertiaryCaregiver: "Tertiary Caregiver",
    editPortalUser: "Edit Portal User",
    portalAccounts: "Portal Accounts",
    links: "Links",
    addDmeImpersonation: "Add Dme For Impersonation",
    prescriptionNeeded: "Rx Prescription Needed",
    qbrLowest10ItemsQ1: "Utilization - Lowest 10 Items Q1",
    qbrLowest10ItemsQ2: "Utilization - Lowest 10 Items Q2",
    qbrLowest10ItemsQ3: "Utilization - Lowest 10 Items Q3",
    qbrLowest10ItemsQ4: "Utilization - Lowest 10 Items Q4",
    qbrTop10ItemsQ1: "Utilization - Top 10 Items Q1",
    qbrTop10ItemsQ2: "Utilization - Top 10 Items Q2",
    qbrTop10ItemsQ3: "Utilization - Top 10 Items Q3",
    qbrTop10ItemsQ4: "Utilization - Top 10 Items Q4",
    qbrQuarter1: "DME Spend - Quarter 1",
    qbrQuarter2: "DME Spend - Quarter 2",
    qbrQuarter3: "DME Spend - Quarter 3",
    qbrQuarter4: "DME Spend - Quarter 4",
    qbrOutlierQ1: "DME Spend - Outlier Q1",
    qbrOutlierQ2: "DME Spend - Outlier Q2",
    qbrOutlierQ3: "DME Spend - Outlier Q3",
    qbrOutlierQ4: "DME Spend - Outlier Q4",
    qbrLostItemsQ1: "Lost Items Q1",
    qbrLostItemsQ2: "Lost Items Q2",
    qbrLostItemsQ3: "Lost Items Q3",
    qbrLostItemsQ4: "Lost Items Q4",
    qbrYear: "DME Spend - Year",
    logisticsDashboard: "Logistics Dashboard",
    geographicReport: "Geographic BI Report",
    selectedStates: "Selected States",
    noSelectedStates: "No Selected States",
    currentSelectedMonthTotal: "Current Selected Month Total",
    previousSelectedMonthTotal: "Previous Selected Month Total",
    selectedYearTotal: "Selected Year Total",
    oofSpendTotal:"OOF Spend Total",
    orderNotes: "Order Notes",
    itemName: "Item Name",
    itemAsset: "Item Asset",
    patientInformation: "Patient Information",
    captionData: "Caption Data",
    nationalData: "National Data",
    stateData: "State Data",


};

export const strings = {
    searchByAssetNo: "Search by Asset Number: ",
    success: "Success",
    unableToEdit: "Unable to Edit",
    unableToRegister: "Unable to Register",
    dmeHasBeenUpdated: "DME Has Been Updated",
    hospiceHasBeenUpdated: "Hospice Has Been Updated",
    dmeHasBeenCreated: "DME Has Been Created",
    hospiceHasBeenCreated: "Hospice Has Been Created",
    pleaseTryAgain: "Please Try Again",
    contactUsEmail: "support@capstone.com",
    contactUsPhone: "Phone: 877-555-5555",
    contactUsTime: "8:30 am - 5.30 pm CST",
    helpDoc1: "Creating a New Delivery/Pickup/Exchange",
    helpDoc2: "Adding a Patient",
    helpDoc3: "Ordering Guidelines and Best Practices",
    helpDoc4: "Quick Reference Guide",
    helpDoc5: "Release Notes - August 18",
    emailSent: "Email sent successfully.",
    emailNotSent: "Encountered error when trying to send email. Please try again."

};
