import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {getAlertMessage, getAlertTitle, getRedirectRoute} from "./cancelActionAlertHelper";
import {useHistory} from "react-router-dom";
import {buttonText} from "../../../constants/alert/alertButtonTexts";

export default function AlertDialog(props) {
    const [open, setOpen] = React.useState(false);
    let history = useHistory();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setOpen(false);
        props.shouldGoBack ? history.goBack() : history.push(props.target ? props.target : getRedirectRoute(props.flag));
    };

    const alertTitle = getAlertTitle(props.alertLevel);
    const alertContent = getAlertMessage(props.flag);

    return (
        <div>
            <Button
                className={props.CSS}
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}>
                {buttonText.cancel}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {alertContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary" autoFocus
                            style={{textTransform: 'none'}}>
                        {buttonText.negative}
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary" style={{textTransform: 'none'}}>
                        {buttonText.positive}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
