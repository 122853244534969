import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Redirect, useHistory} from "react-router-dom";
import PatientData from "./patientData";
import {endpoints} from "../../../constants/endpoints";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import Grid from "@material-ui/core/Grid";
import jwt from "jwt-decode";

export default function PatientPage(props) {
    if (props.portal){
        props.setShowNavBar(true)
    }
    const dispatch = useDispatch();
    const history = useHistory();
    const isLogged = useSelector(state => state.isLogged);
    const [data, setData] = useState();
    const [caregivers, setCaregivers] = useState();
    const [dataRetrieved, setDataRetrieved] = useState(false);
    const [patient_id, setPatient_id] =useState('');
    const [patientOrders, setPatientOrders] = useState([]);
    const userData = jwt(JSON.parse(localStorage.getItem("key")).accessToken).payload;

    useEffect( ()=> {
        if (props.patient_id) {
            setPatient_id(props.patient_id)
        } else if (props.location.patient_id) {
            setPatient_id(props.location.patient_id)
        } else if (props.portal){
                const token = JSON.parse(localStorage.getItem('key'));
               fetch(`${endpoints.getPortalId}${userData.user_id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                }).then(response => response.json()).then(res => setPatient_id(res.data))

        }
        else {
            const urlParameters = window.location.href.split('/');
            setPatient_id(urlParameters[urlParameters.length - 1]);
            if (patient_id === "patientPage") {
                setPatient_id(" ... No record ... ")
            }
        }
    },[]);
    const getPatientOrders = async () => {
        const token = JSON.parse(localStorage.getItem('key'));
        const getPatientOrders = await  fetch(endpoints.patientOrders + patient_id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });

        const response = await getPatientOrders.json();
        if(response.status === "success"){
            setPatientOrders(response.data);
        }

    }
    useEffect( () => {


        if(patient_id) {
            getPatientData();
            getPatientOrders();
        }

    }, [patient_id]);

    const getPatientData = () => {
        setDataRetrieved(false);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getPatientData + patient_id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => response.json())
            .then(response => {
                if (response.error && response.error === 'Patient cannot be found'){
                    history.push('/dashboard')
                }
                setData(response.data);
                setDataRetrieved(true);
            });
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('key'));
        if (patient_id){
            fetch(`${endpoints.patientCaregivers}${patient_id}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            }).then(response => {
                return response.json()
            }).then(response => {
                setCaregivers(response.data);
            })
        }
    }, [data, patient_id]);

    useEffect(() => {
        if (caregivers && data) {
            setDataRetrieved(true);
        }
    }, [caregivers, data, patient_id]);


    if (dataRetrieved) {
        return (
            <Grid container >
                {
                    (isLogged) ?
                        <PatientData
                            patientInfo={data}
                            patient_id={patient_id}
                            caregivers={caregivers}
                            setPatientInfo={setData}
                            portal={props.portal}
                            patientOrders={patientOrders}
                            getPatientOrders={getPatientOrders}
                            getPatientData={getPatientData}
                        />
                    :
                        <Redirect to='/login'/>
                }
            </Grid>
        )
    } else {
        return (
            <Grid container justify={'center'} alignItems={'center'} style={{height:'100vh'}}>
                <h1>
                    LOADING DATA ... PLEASE WAIT ...
                </h1>
            </Grid>
        );
    }
}
