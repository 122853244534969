import React from "react";
import {BRStyles} from "../BusinessReview";
import moment from "moment";

export const BRDisplayLostItemsTable = ({values}) => {
    const classes = BRStyles();
    return (
        <table style={{textAlign: "left"}}>
            <thead>
                <tr>
                    <th className={classes.tdHeaderCell}>Pickup Order Id</th>
                    <th className={classes.tdHeaderCell}>Patient Name</th>
                    <th className={classes.tdHeaderCell}>Completed Date</th>
                    <th className={classes.tdHeaderCell}>Item Name</th>
                    <th className={classes.tdHeaderCell}>Location</th>
                    <th className={classes.tdHeaderCell}>Location Description</th>
                </tr>
            </thead>
            <tbody>
            {values.map(item => {
            return (
                <tr>
                    <td className={classes.tdDataCell}>{item.pickup_order_id}</td>
                    <td className={classes.tdDataCell}>{item.first_name + "" + item.last_name}</td>
                    <td className={classes.tdDataCell}>{item.pickup_completed_date ? moment(item.pickup_completed_date).format("YYYY-MM-DD") : "-"}</td>
                    <td className={classes.tdDataCell}>{item.item_name}</td>
                    <td className={classes.tdDataCell}>{item.care_setting ? item.care_setting : "-"}</td>
                    <td className={classes.tdDataCell}>{item.facility_name ? item.facility_name : "-"}</td>
                </tr>
            )})}
            </tbody>
        </table>
    )
}