import React, {useEffect, useRef, useState} from 'react';
import MaterialTable from "material-table";
import {endpoints} from "../../../constants/endpoints";
import _ from "lodash";
import {activeStatus, inactiveStatus, status} from "../../../constants/status/status";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import ItemAuditTrail from "./itemAuditTrail";
import {CircularProgress, Collapse} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const ViewAssets = props => {

    const {rowData} = props;
    const {useState} = React;
    const [items, setItems] = useState([]);
    const tableRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();
    const isMounted = React.useRef(true)
    const [dataArrived, setDataArrived] = useState(false);
    const datas = {
        id: props.id,
        warehouse_id: rowData.warehouse_id,
        item_name: rowData.item_name
    };
    const {enqueueSnackbar} = useSnackbar();

    function getAssets() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getInventoryItemsAssets, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(datas)
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if(isMounted.current) {
                    setItems(response.data)
                    setDataArrived(true);
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    }

    useEffect(() => {
        getAssets();
        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        dataArrived ? <MaterialTable
            localization={{
                header: {
                    actions: "Edit"
                }
            }}
            editable={{
                onRowUpdate: (newData, oldData) =>
                    new Promise(async (resolve, reject) => {
                        const token = JSON.parse(localStorage.getItem("key"));
                        let newStatus = '1';
                        if (newData.item_status === "Lost" || newData.item_status === "Destroyed"){
                            newStatus = '0'
                        }
                        const availableToken = await checkTokenAvailability();
                        if (!availableToken) {
                            dispatch(logout());
                            history.push('/login');
                        }
                        let promise = await fetch(endpoints.getInUseStatus + oldData.inventory_item_id, {
                            method: "GET",
                            headers: {
                                "content-type": "application/json",
                                "authorization": "Bearer " + token.accessToken,
                            },
                        })
                        let response = await promise.json();
                        if (oldData.item_status === "In use" && response.status === "success" && response.data.length > 0) {
                            enqueueSnackbar("This item is delivered to a patient. Perform a pickup on this item in order to change the status.", {
                                variant: "error",
                                persist: false,
                                autoHideDuration: 7000,
                            });
                            resolve();
                        } else {
                        fetch(endpoints.updateItemStatus, {
                            method: "PUT",
                            headers: {
                                "content-type": "application/json",
                                "authorization": "Bearer " + token.accessToken,
                            },
                            body: JSON.stringify({
                                "itemStatus": newData.item_status,
                                "status": newStatus,
                                "itemId": newData.inventory_item_id
                            })
                        }).then(res => {
                            if (res.status === status.success) {
                                resolve();
                                if(isMounted.current) {
                                    setItems((prevState) => {
                                        const index = _.findIndex(prevState, function (o) {
                                            return o === oldData;
                                        });
                                        let data = [...prevState];
                                        data[index] = newData;
                                        data[index].status = newStatus;
                                        return data;
                                    })
                                }
                            } else {
                                reject();
                            }
                        }).catch(err => {
                            console.log(err, 'error');
                            enqueueSnackbar("Something happened. Contact support.", {
                                persist: false,
                                variant: "error",
                            });
                        })
                        }
                    }),
            }}
            title=""
            columns={[
                {title: 'Asset #', field: 'asset', width: 150},
                {
                    title: 'Status', field: 'status', width: 100, lookup: {0: "Inactive", 1: "Active"},
                    render: rowData => <div
                        style={{display: 'flex', justifyContent: 'center', marginLeft: -20, textAlign: 'center'}}>
                        {(rowData.status === true || rowData.status === "1" ) ? (
                            <span className={'active_status'}> Active </span>
                        ) : (
                            <span className={'inactive_status'}> Inactive </span>
                        )}
                    </div>
                },
                {
                    title: 'Add Date', field: 'date', width: 150, editable: "never",
                    render: rowData => <div>
                        {rowData.created_date !== null && rowData.created_date.slice(0, 10)}
                    </div>
                },
                {
                    title: 'Item Status',
                    field: 'item_status',
                    width: 100,
                    lookup: {...activeStatus, ...inactiveStatus},
                    render: rowData => <div
                        style={{display: 'flex', justifyContent: 'center', marginLeft: -20, textAlign: 'center'}}>
                        {rowData.item_status === "Available" ? (
                            <span className={'active_status'}> {rowData.item_status} </span>
                        ) : (
                            <span className={'inactive_status'}> {rowData.item_status} </span>
                        )}
                    </div>
                },
                /*{
                    title: 'Availability', field: 'status', width: 120, editable: "never",
                    render: rowData => <div style={{textAlign: 'center', marginLeft: -20,}}>
                        {rowData.availability ? (
                            <span className={'active_status'}> Available </span>
                        ) : (
                            <span className={'inactive_status'}> Unavailable </span>
                        )}
                    </div>
                },*/
                {
                    title: 'Audit Trail', field: 'status', width: 150, editable: "never",
                    render: rowData => <div style={{textAlign: 'center', marginLeft: -20,}}>
                        {/*<Button variant='outlined' size="small">Audit Trail</Button>*/}
                        <ItemAuditTrail asset={rowData.asset}/>
                    </div>
                },
            ]}
            options={{
                actionsColumnIndex: 4,
                pageSize: items.length < 5 ? items.length : 5,
                headerStyle: {
                    backgroundColor: '#D8D8D8',
                    // color: '#FFF',
                    height: 20,
                    '&:hover': {color: '#FFF'}
                },
                searchText: props.searchValue,
                actionsCellStyle: {
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '12px',
                    width: '100%',
                    marginBottom: '-1px',
                },
            }}
            data={items}

        />: <Grid container alignItems={'center'} style={{padding: 8}} justify={"center"}><CircularProgress color={"primary"}/></Grid>
    )
};

export default ViewAssets;
