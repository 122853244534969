import React, {useEffect, useRef, useState} from 'react';
import { CSSTransition } from 'react-transition-group';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import './newNotificationCenter.css';
import {CircularProgress, IconButton} from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Popover from "@material-ui/core/Popover";
import NotificationTabs from "./notificationTabs/notificationTabs";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";
import {endpoints} from "../../constants/endpoints";
import jwt from "jwt-decode";

export default function NewNotificationCenter(){
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [notifications, setNotifications] = useState([]);
    const [notificationsCount, setNotificationsCount] = useState(0);
    const [processedNotifications, setProcessedNotifications] = useState({
        orders: {
            delivery: [],
            pickup: [],
            exchange: []
        },
        documents: {
            delivery: [],
            pickup: [],
            exchange: []
        }
    });
    const [loadingNotifications, setLoadingNotifications] = useState(true);
    const handleClick = (event) => {
        setLoadingNotifications(true)
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    useEffect(()=> {
        let arr = {
            orders: {
                delivery: [],
                pickup: [],
                exchange: []
            },
            documents: {
                delivery: [],
                pickup: [],
                exchange: []
            }
        }

        let orderNotifications = notifications.filter(notif => notif.event_type === 'new_note')
        let documentNotifications = notifications.filter(notif => notif.event_type === 'upload_prescription')
        arr.orders.delivery = orderNotifications.filter(word => word.resource_type === 'delivery');
        arr.orders.pickup = orderNotifications.filter(word => word.resource_type === 'pickup');
        arr.orders.exchange = orderNotifications.filter(word => word.resource_type === 'exchange');
        arr.documents.delivery = documentNotifications.filter(word => word.resource_type === 'delivery');
        arr.documents.pickup = documentNotifications.filter(word => word.resource_type === 'pickup');
        arr.documents.exchange = documentNotifications.filter(word => word.resource_type === 'exchange');

        setProcessedNotifications(arr);
    }, [notifications]);

    useEffect(()=> {
        let arr = _.cloneDeep(processedNotifications)
        let unreadCount = arr.orders.delivery.filter(el => el.is_read === false).length +
            arr.orders.pickup.filter(el => el.is_read === false).length +
            arr.orders.exchange.filter(el => el.is_read === false).length +
            arr.documents.delivery.filter(el => el.is_read === false).length +
            arr.documents.pickup.filter(el => el.is_read === false).length +
            arr.documents.exchange.filter(el => el.is_read === false).length
        setNotificationsCount(unreadCount);
    },[processedNotifications])


    async function getNotifications() {
        const tokenData = jwt(JSON.parse(localStorage.getItem('key'))?.accessToken)?.payload;
        const token = JSON.parse(localStorage.getItem("key"));
        const promise = await fetch(`${endpoints.getUserNotification}${tokenData.user_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        const response = await promise.json();
        if(response.status === "success"){
            setNotifications(response.data);
            setLoadingNotifications(false);
        }
    }

    async function readAll(ids, status) {
        const tokenData = jwt(JSON.parse(localStorage.getItem('key'))?.accessToken)?.payload;
        const token = JSON.parse(localStorage.getItem("key"));
        const promise = await fetch(endpoints.readAllNotification, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            method: "PUT",
            body: JSON.stringify({
                ids: ids,
                status: status
            })
        });
        const response = await promise.json();
        if(response.status === "success"){
        }
    }
    async function deleteNotifications(ids) {
        const token = JSON.parse(localStorage.getItem("key"));
        const promise = await fetch(endpoints.deleteAllNotification, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            method: "DELETE",
            body: JSON.stringify({
                ids: ids
            })
        });
        const response = await promise.json();
        if(response.status === "success"){
        }
    }

    useEffect(()=> {
        getNotifications()
    }, [open]);

    function handleNotification(category, subcategory, action, index, value) {
        let clone = _.cloneDeep(processedNotifications);
        switch (action) {
            case 'read_all':
                let ids = [];
                clone[category][subcategory].forEach(e => e.is_read = true);
                clone[category][subcategory].forEach(e => {
                    ids.push(e.user_actions_notifications_id)
                });
                setProcessedNotifications(clone);
                readAll(ids, true);
                break;
            case 'delete_all':
                let ids2 = [];
                clone[category][subcategory] = []
                clone[category][subcategory].forEach(e => {
                    ids2.push(e.user_actions_notifications_id)
                });
                deleteNotifications(ids2);
                setProcessedNotifications(clone);
                break;
            case 'read':
                clone[category][subcategory][index].is_read = !value.is_read;
                setProcessedNotifications(clone);
                readAll([clone[category][subcategory][index].user_actions_notifications_id], !value.is_read);
                break;
            case 'delete':
                deleteNotifications([clone[category][subcategory][index].user_actions_notifications_id]);
                clone[category][subcategory].splice(index, 1);
                setProcessedNotifications(clone);
                break;
            default:
        }
    }
    return (
        <div style={{display:'flex', justifyContent:'center', alignContent:'center', alignItems:'center', paddingRight:16}}>
                <IconButton
                    children={<Badge badgeContent={notificationsCount} max={99} variant={"standard"} color={"secondary"}
                                     children={<NotificationsNoneIcon/>}/>}
                    onClick={handleClick}/>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => setOpen(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    elevation={0}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    }}
                >
                    <div>
                        <DropdownMenu
                            loading={loadingNotifications}
                            notifications={processedNotifications}
                            handleNotification={handleNotification}
                            setOpen={setOpen}
                        />
                    </div>
                </Popover>
        </div>
    )
}

function DropdownMenu(props) {
    const [activeMenu, setActiveMenu] = useState('main');
    const [menuHeight, setMenuHeight] = useState(null);
    const dropdownRef = useRef(null);
    const { notifications, handleNotification } = props;

    useEffect(() => {
        setMenuHeight(dropdownRef.current?.firstChild.offsetHeight)
    }, [])

    function calcHeight(el) {
        const height = el.offsetHeight;
        setMenuHeight(height > 470 ? 470 : height);
    }

    function DropdownItem(props) {
        return (
            <a href="#" className="menu-item" onClick={(e) => {
                e.preventDefault()
                props.goToMenu && setActiveMenu(props.goToMenu)
            }}>
                {props.children}
            </a>
        );
    }

    return (
        <div className="dropdown" style={{ minHeight: menuHeight +40, maxHeight:270, overflow:"scroll" }} ref={dropdownRef}>
            <CSSTransition
                in={activeMenu === 'main'}
                timeout={500}
                classNames="menu-primary"
                unmountOnExit
                onEnter={calcHeight}>
                <div className="menu">
                    {props.loading ? <Grid container justify={'center'} alignContent={'center'} alignItem={'center'} style={{minHeight:170}}>
                        <CircularProgress />
                    </Grid> : (
                        <React.Fragment>
                            <DropdownItem
                                goToMenu="orders"
                            >
                            <span style={{color:'black'}}>Orders</span>
                            <Badge
                                badgeContent={notifications.orders.delivery.filter(el => el.is_read === false).length + notifications.orders.pickup.filter(el => el.is_read === false).length + notifications.orders.exchange.filter(el => el.is_read === false).length}
                                color="secondary" style={{paddingLeft:16}}
                            />
                            </DropdownItem>
                            <DropdownItem
                                goToMenu="patients">
                                <span style={{color:'black'}}>Patients</span>
                            </DropdownItem>
                            <DropdownItem
                                goToMenu="alerts">
                                <span style={{color:'black'}}>Alerts</span>
                                <Badge
                                    badgeContent={notifications.documents.delivery.filter(el => el.is_read === false).length + notifications.documents.pickup.filter(el => el.is_read === false).length + notifications.documents.exchange.filter(el => el.is_read === false).length}
                                    color="secondary" style={{paddingLeft:16}}
                                />
                            </DropdownItem>
                        </React.Fragment>
                    )}
                </div>
            </CSSTransition>
            <CSSTransition
                in={activeMenu === 'orders'}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}>
                <div className="menu">
                    <NotificationTabs setActiveMenu={setActiveMenu} notifications={notifications.orders} handleNotification={handleNotification} setOpen={props.setOpen} category={"orders"}/>
                </div>
            </CSSTransition>
            <CSSTransition
                in={activeMenu === 'patients'}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}>
                <div className="menu">
                    <div style={{textAlign:'center'}}>
                        No notifications
                    </div>
                </div>
            </CSSTransition>
            <CSSTransition
                in={activeMenu === 'alerts'}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}>
                <div className="menu">
                    <div style={{textAlign:'center'}}>
                        <NotificationTabs setActiveMenu={setActiveMenu} notifications={notifications.documents} handleNotification={handleNotification} setOpen={props.setOpen} category={"documents"}/>
                    </div>
                </div>
            </CSSTransition>
        </div>
    );
}
