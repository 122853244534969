import React, {forwardRef, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {Table} from "../../../../common/table/table";
import {tableColumnTitles} from "../../../../constants/names/columnTitles";
import {endpoints} from "../../../../constants/endpoints";
import _ from "lodash";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import MaterialTable from "material-table";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import {ArrowDownward} from "@material-ui/icons";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSnackbar} from "notistack";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FileUpload from "../createSystemNotifications/FileUpload";

export default function SendSystemNotifications() {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();

    const [colors, setColors] = useState({
        backgroundColor:'',
        borderColor:""
    });

    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [selectedType, setSelectedType] = useState('all');
    const [previewTable, setPreviewTable] = useState(false);
    const [hospiceList, setHospiceList] = useState([]);
    const [dmeList, setDmeList] = useState([]);
    const [selectedHospices, setSelectedHospices] = useState([]);
    const [selectedDmes, setSelectedDmes] = useState([]);
    const [selectedNotification, setSelectedNotification] = useState({});
    const [activeNotifications, setActiveNotifications] = useState([]);
    const [activeNotificationsList, setActiveNotificationsList] = useState([]);
    const [organizationType, setOrganizationType] = useState([{value:null, label:null}]);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage ] = useState(0);
    const [sort, setSort] = useState(["","","", "", "","","","", "", "","","","", "", "", ]);
    const [triggerNotification, setTriggerNotification] = useState(false);
    const [files, setFiles] = useState([]);
    const [existingFiles, setExistingFiles] = useState([]);
    const [notificationId, setNotificationId] = useState(null);

    function getHospiceOrganizations() {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getAllHospiceIds, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].hospice_organization_id, label: response.data[i].hospice_name})
                }
                // setHospiceData(response.data);
                setHospiceList(object);

            })
            .catch(err => console.log('error', err))
    }

    function getDmes() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getDmeIds, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].dme_organization_id, label: response.data[i].dme_name})
                }
                setDmeList(object);
            })
            .catch(err => console.log('error', err))
    }

    function getSystemNotifications() {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getSystemNotifications}/active`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].system_notification_id, label: response.data[i].name})
                }
                setActiveNotificationsList(object);
                setActiveNotifications(response.data);
            })
            .catch(err => console.log('error', err))
    }

    function getUsers(ids){
        setLoading(true);
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getUsersByOrganizations, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                type: organizationType[0].value,
                organization_ids: ids
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setUsers(response.data);
                setLoading(false);
            })
            .catch(err => console.log('error', err))
    }

    const markFileAsRemoved = async (id) => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.deleteFileIdOnNotification, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                file_id: id
            })
        })
            .then(response => {
                if (response.status === 200) {
                    getNotificationFiles(notificationId);
                    enqueueSnackbar("File deleted with success.", {
                        persist: false,
                        variant: "success",
                    });
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            });
    };

    const uploadFiles = async (notificationId) => {
        for (let i = 0; i < files.length; i++) {
            await createNotificationFilesId(notificationId, files[i]);
        }
        // enqueueSnackbar("File uploaded with success.", {
        //     persist: false,
        //     variant: "success",
        // });
    };

    const createNotificationFilesId = async (notificationId, file) => {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(endpoints.createFileIdOnNotification, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({
                fileName: file.name,
                notificationId: notificationId
            })
        });
        const response = await promise.json();
        let name = response.data[0].file_name.split(".");
        let fileName = `${name[0]}`;
        let fileId = response.data[0].file_id;
        await uploadFile(file, fileName, fileId);
    };

    const uploadFile = async (file, fileName, fileId) => {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(endpoints.fileUploadOnNotification, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({
                fileName: fileName,
                fileId: fileId
            })
        });
        const response = await promise.json();
        try {
            const promiseS3 = await fetch(response.data, {method: 'PUT', body: file});
        } catch (e) {
            console.log(e.stack);
        }
    };

    const getNotificationFiles = async (notificationId) => {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(endpoints.getNotificationFiles + notificationId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            }
        });
        const response = await promise.json();
        setExistingFiles(response.data);
        console.log(response.data)
    }

    useEffect(() => {
        if (notificationId !== null) {
            setExistingFiles([]);
            getNotificationFiles(notificationId);
        }
    }, [notificationId]);

    useEffect(() => {
        if (files.length > 0) {
            uploadFiles(notificationId);
        }
    }, [files]);

    function sendNotifications(){
        setTriggerNotification(true);
        let users =[];
        let hospiceIds = [];
        let dmeIds = [];
        selectedUsers.map(user => users.push(user.user_id));
        selectedHospices.map(hospice => hospiceIds.push(hospice.value));
        selectedDmes.map(dme => dmeIds.push(dme.value));
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.sendSystemNotifications, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                systemNotificationId: selectedNotification.system_notification_id,
                receiverUsers: selectedType === "all" ? "all" : selectedType === 'org' ? { hospice_ids: hospiceIds, dme_ids: dmeIds} : users
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.status === "success"){
                    enqueueSnackbar("System Notification sent with success", {
                        variant: 'success',
                        autoHideDuration: 4000,
                        anchorOrigin: {vertical: "bottom", horizontal: "left"}
                    });
                }
                setTriggerNotification(false)
            })
            .catch(err => {
                setTriggerNotification(false);
            })
    }

    function handleChange(e, payload){
        let clone = _.cloneDeep(selectedUsers);
        let index = _.findIndex(selectedUsers, {"user_id": payload.user_id});
        if (index !== -1){
            clone.splice(index, 1);
        } else {
            clone.push(payload);
        }
        setSelectedUsers(clone);
    }

    useEffect(()=> {
        if (organizationType[0].value === "Hospice"){
            let arr = [];
            selectedHospices.map(hospice => {
                arr.push(hospice.value);
            });
            setSelectedUsers([]);
            getUsers(arr);
        } else if (organizationType[0].value === "DME"){
            let arr = [];
            selectedDmes.map(dme => {
                arr.push(dme.value);
            });
            setSelectedUsers([]);
            getUsers(arr);
        }
    },[selectedHospices, selectedDmes]);

    useEffect(()=> {
        if (selectedNotification.type === "Informational"){
            setColors({
                backgroundColor:'#E4EBF1',
                borderColor:"#7B99B5"
            })
        } else if (selectedNotification.type === "Warning"){
            setColors({
                backgroundColor:'#F6F4DF',
                borderColor:"#CEC129"
            });
        } else if (selectedNotification.type === "Important"){
            setColors({
                backgroundColor:'#F5B8BF',
                borderColor:"#CD1D32"
            });
        }
    },[selectedNotification]);

    useEffect(()=> {
        if ((selectedType === "org") || selectedType === "custom"){
            setSelectedDmes([]);
            setSelectedHospices([]);
        }
    }, [selectedType]);


    useEffect(()=> {
        if((selectedType === 'custom' || selectedType === 'org') && selectedNotification.system_notification_id && (selectedUsers.length > 0 || selectedHospices.length > 0 || selectedDmes.length > 0)) {
            setIsValid(true)
        } else if (selectedType === 'all' && selectedNotification.system_notification_id) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [selectedUsers, selectedNotification, selectedDmes, selectedHospices]);

    useEffect(()=> {
        getHospiceOrganizations();
        getSystemNotifications();
        getDmes();
    },[]);

    const columns = [
        {title: 'User ID', field: 'user_id', width: 140, defaultSort:sort[0]},
        {title: 'Name', field: 'first_name', width: 240, defaultSort:sort[1],
            render: rowData => <div>
                {`${rowData.first_name} ${rowData.last_name}`}
            </div>

        },
        {title: tableColumnTitles.organization, field: organizationType[0].value === "DME" ? "dme_name" : "hospice_name", width: 240, defaultSort:sort[2]},
        {title: 'Role', field: 'name', width: 240, defaultSort:sort[3]},
        {title: 'Select', field: 'state', width: 100, sorting: false,
            render: rowData => <Grid container>
                <Checkbox color={"primary"} checked={_.findIndex(selectedUsers, {"user_id": rowData.user_id}) !== -1} onChange={(e)=> handleChange(e, rowData)}/>
            </Grid>
        },

    ];
    const columnsPreview = [
        {title: 'User ID', field: 'user_id', width: 140},
        {title: 'Name', field: 'first_name', width: 240,
            render: rowData => <div>
                {`${rowData.first_name} ${rowData.last_name}`}
            </div>

        },
        {title: tableColumnTitles.organization, field: organizationType[0].value === "DME" ? "dme_name" : "hospice_name", width: 240},
        {title: 'Role', field: 'name', width: 340},
    ];


    return (
        <Grid container style={{padding:24}}>
            <Grid container style={{padding:24, backgroundColor:'white', borderRadius:4}} spacing={2}>
                <Grid item sm={12} xs={12} style={{display:'flex', alignContent:'flex-end', alignItems:"flex-end"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{display:'flex', padding:12}}>
                            <Typography
                                style={{
                                    fontWeight:'bold', fontSize:24
                                }}
                            >
                                Send System Notification's
                            </Typography>
                        </Grid>
                        <Grid item sm={4} xs={12} style={{textAlign:'left', zIndex:30}}>
                            <div style={{color:'grey'}}>Notification *</div>
                            <Select
                                options={activeNotificationsList}
                                placeholder={'Select Notification *'}
                                onChange={(e)=> {
                                    let index = _.findIndex(activeNotifications, ["system_notification_id", e.value]);
                                    setSelectedNotification({ ...selectedNotification, ...activeNotifications[index], name: e.label, });
                                    setNotificationId(e.value);
                                }}
                                value={selectedNotification.name ? [{label:selectedNotification.name, value:selectedNotification.name}] : null}
                            />
                        </Grid>
                        <Grid item sm={8} xs={12} style={{paddingTop:6}}>
                            <Grid container>
                                {
                                    selectedNotification.name && (
                                        <React.Fragment>
                                            <Grid item xs={12} style={{display:'flex', fontSize:16, fontWeight:'bold'}}>
                                                Preview:
                                            </Grid>
                                            <Grid item xs={12} style={{display:'flex', fontSize:16, fontWeight:'bold'}}>
                                                <Grid container style={{borderRadius:4,
                                                    backgroundColor: colors.backgroundColor,
                                                    border:`2px solid ${colors.borderColor}`,
                                                    paddingTop:24, paddingLeft:24, paddingRight:12,paddingBottom:12, textAlign:"left"}}>
                                                    <Grid item xs={12} style={{fontSize:24, paddingBottom:12}}>
                                                        {selectedNotification.title}
                                                    </Grid>
                                                    <Grid item xs={12} style={{paddingBottom:24}}>
                                                        <Typography
                                                            variant="body1"
                                                            style={{whiteSpace: 'pre-line'}}
                                                        >
                                                            {selectedNotification.message}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container justify={selectedNotification.dismissible === true ? "space-between" : 'flex-end'}>

                                                            <Grid item style={{paddingRight:12}}>
                                                                {selectedNotification.dismissible === true ? (
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                // checked={surveyOptions.is_open}
                                                                                // onChange={(e)=> {
                                                                                //     setSurveyOptions({...surveyOptions, is_open: e.target.checked})
                                                                                // }}
                                                                                name="checkedB"
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label="Do not show anymore"
                                                                    />

                                                                ):(
                                                                    <Button variant={"outlined"} color={'primary'}>I Have Read</Button>
                                                                )}
                                                            </Grid>
                                                            <Grid item>
                                                                <Button variant={"contained"} color={'primary'} >
                                                                    {selectedNotification.dismissible === true ? "Acknowledge" : "Read Later"}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <FileUpload
                                                files={files}
                                                setFiles={setFiles}
                                                existing={existingFiles}
                                                markFileAsRemoved={markFileAsRemoved}
                                            />
                                        </React.Fragment>
                                    )
                                }


                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{display:'flex',justifyContent:'flex-end', alignContent:'flex-end', alignItems:"flex-end"}}>
                            {
                                selectedNotification.name && `Active Period: ${moment(selectedNotification.start_date).format("D MMM YYYY")} - ${moment(selectedNotification.end_date).format("D MMM YYYY")}`
                            }

                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} style={{display:'flex',paddingTop:12, paddingBottom:24}}>
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="gender" name="gender1"
                                    value={selectedType}
                                    onChange={(e)=> setSelectedType(e.target.value)}
                        >
                            <div style={{display:'flex'}}>
                            <FormControlLabel value="all" control={<Radio color={'primary'}/>} label="Send To All" />
                            </div>
                            <Grid container alignItems={'center'} alignContent={'center'} style={{}}>
                                <FormControlLabel value="org" control={<Radio color={'primary'}/>} label="Send To Specific Organizations" />
                                {selectedType === 'org' && (<React.Fragment>
                                        <Grid container spacing={2} style={{paddingBottom:12}}>


                                    <Grid item style={{textAlign:'left', zIndex:21, width:280, minHeight:78}}>
                                        <div style={{color:'grey'}}>Select DME Organizations:</div>
                                        <Select
                                            isMulti
                                            options={dmeList}
                                            placeholder={`Select DME's`}
                                            onChange={(e)=> setSelectedDmes(e)}
                                            value={selectedDmes}
                                        />
                                    </Grid>
                                        <Grid item style={{textAlign:'left', zIndex:20, width:280, minHeight:78,}}>
                                            <div style={{color:'grey'}}>Select Hospice Organizations:</div>
                                            <Select
                                                isMulti
                                                options={hospiceList}
                                                placeholder={'Select Hospices'}
                                                onChange={(e)=> setSelectedHospices(e)}
                                                value={selectedHospices}
                                            />
                                        </Grid>
                                        </Grid>
                                    </React.Fragment>

                                )}

                            </Grid>
                            <div style={{display:'flex'}}>
                            <FormControlLabel value="custom" control={<Radio color={'primary'}/>} label="Send to Custom Users" />
                            </div>
                        </RadioGroup>
                    </FormControl>
                </Grid>

                {
                    selectedType === 'custom' && (
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item md={3} sm={4} xs={6} style={{zIndex:20}}>
                                            <div style={{color:'grey', textAlign:'left'}}>Select Organization Type</div>
                                            <Select
                                                options={
                                                    [
                                                        {value:'DME', label:'DME'},
                                                        {value:'Hospice', label:'Hospice'},
                                                    ]
                                                }
                                                placeholder={'Select Type'}
                                                onChange={(e)=> setOrganizationType([{
                                                    label:e.value,
                                                    value:e.value,
                                                }])}
                                                value={organizationType[0].value ? organizationType : null}
                                            />
                                        </Grid>
                                        <Grid item md={3} sm={4} xs={6} style={{zIndex:20}}>
                                            <div style={{color:'grey', textAlign:'left'}}>Select Organization</div>
                                            <Select
                                                options={organizationType[0].value === 'Hospice' ? hospiceList : (organizationType[0].value === 'DME' ? dmeList : [])}
                                                placeholder={'Select Organization'}
                                                onChange={(e)=> {
                                                    if (organizationType[0].value === 'Hospice'){
                                                        setSelectedHospices([{value:e.value, label:e.label}])
                                                    } else if (organizationType[0].value === 'DME'){
                                                        setSelectedDmes([{value:e.value, label:e.label}])
                                                    }
                                                }}
                                                value={organizationType[0].value === 'Hospice' ? selectedHospices : (organizationType[0].value === 'DME' ? selectedDmes : null)}
                                            />
                                        </Grid>
                                        <Grid item md={6} sm={4} xs={12} style={{display:'flex', justifyContent:'flex-end', alignContent:'flex-end', alignItems:'flex-end'}}>
                                            {
                                                (selectedHospices.length > 0 || selectedDmes.length >0) && (
                                                    <Button variant={"outlined"} color={"primary"} style={{height:40}} onClick={()=> setSelectedUsers(users)}>Select All</Button>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    {(selectedHospices.length > 0 || selectedDmes.length >0) && (
                                        <Grid className={'general_table'} item style={{overflowX: 'scroll'}}>
                                            <MaterialTable
                                                component={{
                                                    Container: props => <Paper {...props} elevation={0}/>
                                                }}
                                                components={{
                                                    Container: props => <Paper {...props} elevation={0}/>,
                                                    Pagination: props => <TablePagination {...props} style={{
                                                        backgroundColor: "rgba(237, 242, 244, 0.73)",
                                                        display: "flex"
                                                    }}/>,
                                                }}
                                                icons={{
                                                    Filter: () => <tr/>,
                                                    SortArrow: forwardRef((props, ref) => <ArrowDownward style={{color: "#77A6BC"}} {...props} ref={ref}/>),
                                                }}
                                                isLoading={loading}
                                                options={{
                                                    initialPage:page,
                                                    rowStyle: {backgroundColor: '#FFF'},
                                                    emptyRowsWhenPaging: false,
                                                    toolbar: true,
                                                    showTitle: false,
                                                    // filtering: true,
                                                    showEmptyDataSourceMessage: users ? users.length === 0 : true,
                                                    pageSize: 5,
                                                    headerStyle: {
                                                        backgroundColor: '#F2F6F7',
                                                        color: "#2E3236",
                                                        height: 20,
                                                        '&:hover': {color: '#FFF'}
                                                    },
                                                }}
                                                columns={columns}
                                                data={users}
                                                onOrderChange={(colId, ord) =>{
                                                    let sortClone;
                                                    if (colId !== -1) {
                                                        sortClone = ["","","", "", "","","","", "", "","","","", "", "", ];
                                                        sortClone[colId] = ord;
                                                    }
                                                    else {
                                                        sortClone = ["","","", "", "","","","", "", "","","","", "", "", ];
                                                    }
                                                    setSort(sortClone);
                                                }}
                                                onChangePage={(newPage) => {
                                                    setPage(newPage);
                                                }}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }

                <Grid item xs={12} style={{display:'flex', justifyContent:'flex-end', paddingTop:12, paddingBottom:24}}>
                    <Grid container justify={'flex-end'} spacing={2}>
                        {
                            selectedType === 'custom' && (selectedHospices.length > 0 || selectedDmes.length > 0) && (
                                <Grid item>
                                    <Button
                                        variant={'outlined'}
                                        color={'primary'}
                                        onClick={()=> setPreviewTable(!previewTable)}
                                    >
                                        Preview Selected Users
                                    </Button>
                                </Grid>
                            )
                        }

                        <Grid item>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                disabled={!isValid}
                                onClick={sendNotifications}
                            >
                                Send Notification
                            </Button>
                        </Grid>
                    </Grid>

                </Grid>
                {previewTable === true && (
                    <Grid item xs={12}>
                        <Table
                            title={"Preview Table With Selected Users"}
                            columns={columnsPreview}
                            items={selectedUsers}
                            isLoading={false}
                            pageSize={10}
                            backGroundColor={'#F2F6F7'}
                            textColor={"#2E3236"}
                            search={true}
                            showSearch={true}
                            filtering={true}
                        />
                    </Grid>
                )}
                <Backdrop className={classes.backdrop} open={triggerNotification} style={{zIndex:500}}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
        </Grid>
    )
}
const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));
