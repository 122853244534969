import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {useHistory} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {componentTitles} from "../../constants/names/titles";
import {exchageStyles} from "./requestExchangeStyles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import PickupComponent from "./components/pickupComponent";
import {endpoints} from "../../constants/endpoints";
import _ from "lodash";
import {titles} from "../../constants/inputs/tableColumnTitles";
import {exchangeActions} from "../../constants/types/actionTypes";
import {OrderReview} from "./components/orderExchangeReview/orderReview";
import moment from "moment";
import jwt from "jwt-decode";
import {useSnackbar} from "notistack";
import ToolIcon from "../../assets/tool.svg";
import {checkPaceHospice} from "../../common/utils/utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import {notifySnack} from "../../common/notifications/apiStatusNotification";
import {snackbarMessages} from "../../constants/alert/alertMessages";
import ExchangeMobileUi from "./mobileUi/exchangeMobileUi";
import {isMobile} from "react-device-detect";

export default function RequestExchange({exchangeId, addMore = false, buttonStyleClass, ...props}) {
    let history = useHistory();
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showAuthorization, setShowAuthorization] = useState(false);
    const classes = exchageStyles();
    const vertical = 'top';
    const horizontal = 'right';
    const [open, setOpen] = useState(props.openOrder ? props.openOrder : false);
    const [value, setValue] = useState('');
    const [patientInventory, setPatientInventory] = useState([]);
    const [exchangeCart, setExchangeCart] = useState([]);
    const [showNotification, setShowNotification] = React.useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const steps = addMore ? ['Select items to exchange'] : ['Select items to exchange', 'Finalize'];
    const [deliveryItems, setDeliveryItems] = useState([]);
    const [notes, setNotes] = useState([]);
    const [preferredDate, setPreferredDate] = useState(moment().format("YYYY-MM-DD"));
    const [priority, setPriority] = useState("Routine");
    const [suggestions, setSuggestions] = useState([]);
    const [notifyHospice, setNotifyHospice] = useState(false);
    const [contractedProviders, setContractedProviders] = useState([]);
    const [dmeId, setDmeId] = useState("");
    const [hospiceId, setHospiceId] = useState("");
    const [regionId, setRegionId] = useState("");
    const [category, setCategory] = useState("");
    const [search, setSearch] = useState("");
    const [contractedProvidersList, setContractedProvidersList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [itemsList, setItemsList] = useState([]);
    const [filteredItemsList, setFilteredItemsList] = useState([]);
    const [hasItems, setHasItems] = useState(true);
    const [hasData, setHasData] = useState(false);
    const [isPace, setIsPace] = useState(false);
    const [authorization_pace_no, setAuthorization_pace_no] = useState('');
    const [isUnsavedNote, setIsUnsavedNote] = useState(false);
    const [showError, setShowError] = useState(false);
    let user = JSON.parse(localStorage.getItem('key'));
    let decoded = jwt(user.accessToken);
    const [currentNote, setCurrentNote] = React.useState("");
    let token = JSON.parse(localStorage.getItem('key'));
    let currentUser = jwt(token.accessToken).payload;
    const [showWarning, setShowWarning] = React.useState(false);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const orderHistory = [{
        description: `Order created: ${decoded.payload.first_name} ${decoded.payload.last_name} created order on ${moment().format('LLL')}`,
        created_date: moment()
    }];

    const getContractedProviders = (id) => {
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getHospiceContractedProviders}${id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let object = [];
                for (let i = 0; i < response.data.length; i++) {
                    object.push({value: response.data[i].dme_id, label: response.data[i].dme_name})
                }
                setContractedProviders(response.data);
                setContractedProvidersList(object);
                if (response.data.length === 1) {
                    setDmeId(response.data[0].dme_id);
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };

    const getCategoriesList = () => {
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getCategoriesMasterItems}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data.length > 0) {
                    let object = [];
                    for (let i = 0; i < response.data.length; i++) {
                        object.push({value: response.data[i].category, label: response.data[i].category})
                    }
                    const sortedCategories = object.sort((a, b) => a.label.localeCompare(b.label));
                    const sortedAllCategories = [{value: "All", label: "All"}, ...sortedCategories];
                    setCategoriesList(sortedAllCategories);
                } else {
                    setCategoriesList([]);
                }
            })
            .catch(err => console.log('error', err))
    };

    useEffect(() => {
        if (decoded.payload.hospice_organization_id !== null) {
            getContractedProviders(decoded.payload.hospice_organization_id);
            setHospiceId(decoded.payload.hospice_organization_id);
            setRegionId(decoded.payload.region_id);
        } else {

            setHospiceId(props.hospice_id);
            setRegionId(props.region_id);
            if (!dmeId){
                setDmeId(decoded.payload.dme_organization_id);
            }

        }
    }, [dmeId]);
    useEffect(() => {

        if (contractedProvidersList.length === 1 && dmeId === '') {
            setDmeId(contractedProvidersList[0].dme_id);
        }
    }, [contractedProvidersList]);
    useEffect(() => {
        if (itemsList.length === 0 && dmeId !== '' && open) {
            const data = JSON.parse(localStorage.getItem('key'));
            let body;
            if (decoded.payload.hospice_organization_id !== null) {
                body = {
                    dme_id: dmeId,
                    hospice_id: hospiceId,
                    hospice_region_id: regionId,
                };
            } else {
                body = {
                    dme_id: dmeId,
                    hospice_id: hospiceId,
                    hospice_region_id: regionId,
                }
            }
            fetch(`${endpoints.getHospiceContractedItems}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + data.accessToken
                    },
                    body: JSON.stringify(body)
                }
            )
                .then(response => {
                    return response.json();
                }).then(response => {
                if (response.data.length > 0) {
                    let clonedItems = _.cloneDeep(response.data);
                    clonedItems.map(item => {
                        if (!item.max_quantity_per_item) {
                            item.max_quantity_per_item = 10;
                        }
                    });
                    setItemsList(clonedItems);
                    setFilteredItemsList(clonedItems);
                } else {
                    setItemsList([]);
                }
            })
                .catch(err => console.log('error', err));
        }
    }, [dmeId, activeStep]);

    useEffect(() => {
        let cloneItemList = _.cloneDeep(itemsList);
        let result = [];
        if (category !== '' && category !== "All" && category !== null) {
            cloneItemList.map(item => {
                if (item.category && _.includes(item.category.toLowerCase(), category.toLowerCase())) {
                    result.push(item)
                }
            })
        } else {
            result = cloneItemList;
        }
        setFilteredItemsList(result)
    }, [category]);
    useEffect(() => {
        if (search !== '') {
            let lorin = _.cloneDeep(itemsList);
            let result = [];
            lorin.map(item => {
                if (_.includes(item.name.toLowerCase(), search.toLowerCase())) {
                    result.push(item)
                } else if (_.includes(item.tags, search.toLowerCase())) {
                    result.push(item)
                }
            });
            setFilteredItemsList(result);
        } else {
            setFilteredItemsList(itemsList);
        }
    }, [search]);

    useEffect(() => {
        if (categoriesList.length === 0 && open) {
            getCategoriesList()
        }
    }, [open]);

    const addOrderNote = () => {
        let final = moment().format('LLL');
        const arr = [...notes];
        let input = {
            user_id: currentUser.user_id,
            creator: `${currentUser.first_name} ${currentUser.last_name}`,
            created_date: final,
            description: currentNote
        };
        arr.push(input);
        setNotes(arr);
        setCurrentNote('');
        setIsUnsavedNote(false);
        setShowWarning(false);
        return arr;
    };

    const submitWithUnsavedNote = () => {
        let note = addOrderNote();
        handleNext(false, note);
    }

    function createEmailNoteTemplate(note) {
        let string = '';
        string += `<br> <strong>Creator:</strong> ${note.first_name} ${note.last_name}`;
        string += `<br> <strong>Created Date:</strong> ${note.created_date}`;
        string += `<br> <strong>Description:</strong> ${note.description}`;
        return string;
    }

    const handleNext = (needNoteValidation, notes) => {
        if (addMore){
            const token = JSON.parse(localStorage.getItem('key'));
            fetch(endpoints.addExchangeOrderItems, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    items: exchangeCart,
                    exchange_order_id: props.user.exchange_order_id,
                })
            })
                .then(response => {
                    if (response.status === 201) {
                        notifySnack(enqueueSnackbar, response.status, snackbarMessages.orderItemAdded, true);
                        let items = exchangeCart.map(function (item) {
                            return item['name'];
                        });
                        const allItems = items.join(', ');
                        props.addHistory(props.user.exchange_order_id, `Items added: ${decoded.payload.first_name} ${decoded.payload.last_name} added ${allItems} on ${moment(new Date()).format("LLL")}`);
                        props.getOrderDetails();
                    }
                })
                .catch(err => console.log('error', err));
            setExchangeCart([]);
            setOpen(false);
            history.push(`/refresh/order/exchange/${user.orderId}`);
        }
        if (!addMore && activeStep === steps.length - 1) {
            if (isUnsavedNote && needNoteValidation) {
                setShowError(true);
            } else {
                setShowError(false);
                setIsLoading(true);
                const data = JSON.parse(localStorage.getItem('key'));
                fetch(endpoints.createExchangeOrder, {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + data.accessToken
                    },
                    body: JSON.stringify({
                        notes,
                        preferredDate,
                        priority,
                        notifyHospice,
                        exchangeCart,
                        patient_id: props.patientId,
                        dme_organization_id: dmeId,
                        user_id: decoded.payload.user_id,
                        history: orderHistory,
                        authorization_pace_no: authorization_pace_no,
                    })
                }).then(res => {
                    return res.json();
                }).then(res => {
                    props.getPatientOrders && props.getPatientOrders();
                    if (exchangeCart.some(item => item.approval === true)) {
                        fetch(`${endpoints.sendApproveOrderMail}`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + data.accessToken
                                },
                                body: JSON.stringify({
                                    orderId: `S300${res.data}`,
                                    items: exchangeCart.filter(item => item.type === 'Delivery'),
                                    orderType: "exchange",
                                    link: `${process.env.REACT_APP_API_URL}order/exchange/${res.data}`,
                                    domain: `${process.env.REACT_APP_API_URL}`,
                                    patient: props.name,
                                    patient_id: props.patientId,
                                    // note: notes[0] ? createEmailNoteTemplate(notes[0]) : (props.orderProps ? (props.orderProps.notes?[0] ? createEmailNoteTemplate(props.orderProps.notes?[0]) : "No notes") : "No notes")
                                    // note: notes[0] ? createEmailNoteTemplate(notes[0]) : "No notes"
                                    note:  "No notes"
                                })
                            }
                        ).then(() => console.log('email sent'))}

                    uploadFiles(res.data)

                });
            }

        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = (event, reason) => {
        setOpen(false);
        if (reason === 'clickaway') {
            return;
        }
        isMobile && props.closeMobileModal()
    };
    const createOrder = () => {
        setShowNotification(true);
        setOpen(false);
    };
    const hideNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowNotification(false);
    };
    const viewOrder = () => {
        history.push(`/regular/refresh/order/exchange/${value}`);
    };
    const getData = async (totalParam) => {
        const promise = await fetch(endpoints.pickupItems + totalParam, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.accessToken
            },
        });
        const response = await promise.json();
        setPatientInventory(response.data);
    };
    const exchangeCartTableColumns = [
        {
            title: titles.item, field: "name", width: 200,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {rowData.name}
                </Typography>
            </Grid>
        },
        {
            title: titles.action, field: "Action", width: 200,
            render: rowData => <Grid item>
                <Typography variant={"subtitle1"}>
                    {rowData.action}
                </Typography>
            </Grid>
        },
    ];
    const handleInput = (event) => {
        const {name} = event.target;
        let values;
        switch (name) {
            case "pickup":
                const {findItem, value} = event.target;
                const pickupIndex = _.findIndex(patientInventory, {
                        'special_item_id': findItem.special_item_id,
                    });
                values = _.cloneDeep(patientInventory);
                values[pickupIndex].pickup = value;
                setDmeId(findItem.dme_id);
                setPatientInventory(values);

                let exchangeIndex;
                values = _.cloneDeep(exchangeCart);
                exchangeIndex = _.findIndex(exchangeCart, {
                    'special_item_id': findItem.special_item_id, "action": exchangeActions.pickup,
                });
                if (exchangeIndex === -1) {
                    values.push({...findItem, "action": exchangeActions.pickup});
                    values.push({...findItem, "action": exchangeActions.deliver, special_item_info: {...findItem}});
                } else {
                    values.splice(exchangeIndex, 2);
                }

                setExchangeCart(values);

                let suggestionIndex;
                values = _.cloneDeep(suggestions);
                suggestionIndex = _.findIndex(suggestions, {"asset": findItem.asset});
                if (suggestionIndex === -1) {
                    values.push({...findItem, checked: false, special_item_info:{} });
                }
                setSuggestions(values);

                break;
            case "addItem":
                values = [...exchangeCart];
                let item = {...event.target.itemValues};
                item = {
                    ...item,
                    hasAsset: event.target.item.hasAsset,
                    in_formulary: event.target.item.in_formulary,
                    action: exchangeActions.deliver
                };
                values.push(item);
                setExchangeCart(values);
                break;
            case "search":
                setSearch(event.target.value);
                break;
            case "category":
                setCategory(event.target.value);
                break;
            case "suggestion":
                const {checked, index} = event.target;
                values = _.cloneDeep(suggestions);
                values[index].checked = checked;
                setSuggestions(values);
                let exchangeClone = _.cloneDeep(exchangeCart);
                if (checked === true) {
                    exchangeClone.push({...values[index], action: exchangeActions.deliver})
                } else {
                    let exchangeIndex = _.findIndex(exchangeCart, {
                        'inventory_item_id': values[index].inventory_item_id,
                        "item_name": values[index].item_name,
                        "checked": true, // maybe this from backend too
                    });
                    if (exchangeIndex > -1) {
                        exchangeClone.splice(exchangeIndex, 1);
                    }
                }
                setExchangeCart(exchangeClone);
                break;
            case "notifyHospice":
                setNotifyHospice(event.target.checked);
                break;
            case "preferredDate":
                setPreferredDate(moment(event.target.value).format("YYYY-MM-DD"));
                break;
            case "priority_code":
                setPriority(event.target.value);
                break;
            default:
        }
    };


    async function uploadFiles (orderId) {
        for (let i=0; i<files.length; i++){
            await createOrderFilesIds(orderId, files[i])
        }
        setSuggestions([]);
        setPatientInventory([]);
        setActiveStep(0);
        setNotes([]);
        setExchangeCart([]);
        setValue(orderId);
        // setShowNotification(true);
        const action = key => (
            <React.Fragment>
                <Button onClick={() => {
                    history.push("/regular/refresh/order/exchange/" + orderId);
                    closeSnackbar(key);
                }}>
                    View Order
                </Button>
                <Button onClick={() => {
                    closeSnackbar(key);
                }}>
                    Close
                </Button>
            </React.Fragment>
        );
        enqueueSnackbar(`Order #S300${orderId} has been created with success.`, {
            variant: 'success',
            autoHideDuration: 10000,
            anchorOrigin: {vertical: "top", horizontal: "right"},
            action,
        });
        setActiveStep(0);
        handleClose();
        setIsLoading(false);
    }
    async function createOrderFilesIds(orderId, file){
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.createFileUploadIdOnOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({
                file_name: file.name,
                orderId: orderId,
                orderType: 'exchange',
            })
        });
        const response = await promise1.json();
        let name = response.data.rows[0].file_name.split(".");
        let fileName = `${response.data.rows[0].file_id}.${name[name.length-1]}`
        await uploadFile(file, fileName)
    }

    async function uploadFile (file, fileName) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.fileUploadOnOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({fileName:  fileName, type:'exchange'})
        });
        const result1 = await promise1.json();
        try{
            const promiseS3 = await fetch(result1.data, { method: 'PUT', body: file});
        } catch (e) {
            console.log(e.stack);
        }
    }


    const handleClearAll = () => {

        let values = _.cloneDeep(patientInventory);
        for (const item of values) {
            item.pickup = false;
        }
        setPatientInventory(values);
        setExchangeCart([]);
        setSuggestions([]);
    };
    const handleRemove = (event) => {
        const {value} = event.target;
        let exchangeIndex;
        let inventoryClone = _.cloneDeep(patientInventory);
        let exchangeClone = _.cloneDeep(exchangeCart);
        if (value.action === exchangeActions.pickup) {

            let inventoryIndex = _.findIndex(inventoryClone, {
                "special_item_id": value.special_item_id
            });
            inventoryClone[inventoryIndex].pickup = !inventoryClone[inventoryIndex].pickup;
            exchangeIndex = _.findIndex(exchangeClone, {
                "special_item_id": value.special_item_id, action: exchangeActions.pickup,
            });
            exchangeClone.splice(exchangeIndex, 2);
            setPatientInventory(inventoryClone);
            setExchangeCart(exchangeClone);
        } else if (value.action === exchangeActions.deliver) {
            let inventoryIndex = _.findIndex(inventoryClone, {
                "special_item_id": value.special_item_id
            });
            inventoryClone[inventoryIndex].pickup = !inventoryClone[inventoryIndex].pickup;
            let exchangeIndex = _.findIndex(exchangeClone, function (item) {
                return item.tableData.id === value.tableData.id;
            });
            if (exchangeIndex > -1) {
                exchangeClone.splice(exchangeIndex-1, 2);
            }
            setPatientInventory(inventoryClone);
            setExchangeCart(exchangeClone);

        } else {
            if (value.special_item_info && Object.keys(value.special_item_info).length > 0) {
                exchangeIndex = _.findIndex(exchangeClone, {
                    "inventory_item_id": value.inventory_item_id,
                    "special_item_info": value.special_item_info
                });
                if (exchangeIndex > -1) {
                    exchangeClone.splice(exchangeIndex, 1);
                }
                setExchangeCart(exchangeClone);

            } else {
                exchangeIndex = _.findIndex(exchangeClone, {
                    "inventory_item_id": value.inventory_item_id,
                });
                if (exchangeIndex > -1) {
                    exchangeClone.splice(exchangeIndex, 1);
                }
                setExchangeCart(exchangeClone);
            }
            let suggestionClone = _.cloneDeep(suggestions);
            let suggestionIndex = _.findIndex(suggestions, {
                "inventory_item_id": value.inventory_item_id,
                "asset": value.asset
            });
            suggestionClone[suggestionIndex].checked = false;
            setSuggestions(suggestionClone);
        }
    };
    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                if (isMobile){
                    return <ExchangeMobileUi
                        patientInventory={patientInventory}
                        handleInput={handleInput}
                    />
                } else {
                    return <PickupComponent
                        patientInventory={patientInventory}
                        handleInput={handleInput}
                        exchangeCartTableColumns={exchangeCartTableColumns}
                        exchangeCart={exchangeCart}
                        dmeId={dmeId}
                    />;
                }
            case 1:
                return <OrderReview
                        exchangeItems={exchangeCart}
                        setExchangeCart={setExchangeCart}
                        notes={notes}
                        setNotes={setNotes}
                        patientInventory={patientInventory}
                        handleRemove={handleRemove}
                        handleInput={handleInput}
                        handleClearAll={handleClearAll}
                        preferredDate={preferredDate}
                        priority={priority}
                        notifyHospice={notifyHospice}
                        authorization_pace_no={authorization_pace_no}
                        setAuthorization_pace_no={setAuthorization_pace_no}
                        showAuthorization={showAuthorization}
                        files={files}
                        setFiles={setFiles}
                        setIsUnsavedNote={setIsUnsavedNote}
                        showError={showError}
                        currentNote={currentNote}
                        setCurrentNote={setCurrentNote}
                        setShowWarning={setShowWarning}
                        showWarning={showWarning}
                        addOrderNote={addOrderNote}
                        isMobile={isMobile}
                    />;
            default:
                return 'Unknown stepIndex';
        }
    };

    useEffect(() => {
        open === true && getData(false + `/${props.patientId}`);
    }, [open]);

    useEffect(()=> {
        async function check() {
            let res = await checkPaceHospice(props.hospice_id);
            setShowAuthorization(res);
            setIsPace(res);
        }
        check();
    }, []);


    useEffect(() => {
        const values = _.cloneDeep(exchangeCart);
        for (let index = 0; index < deliveryItems.length; index++) {
            if (_.findIndex(values, {
                "inventory_item_id": deliveryItems[index].inventory_item_id,
                "item_name": deliveryItems[index].item_name,
            }) === -1) {
                values.push({...deliveryItems[index], "action": exchangeActions.deliver});
            }
        }
        setExchangeCart(values);
    }, [deliveryItems]);
    // const checkPatientInventory = async (patient_id) => {
    //     let state;
    //     const token = JSON.parse(localStorage.getItem('key'));
    //     const promise = await fetch(endpoints.pickupItems + "true" + '/' + patient_id, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': 'Bearer ' + token.accessToken
    //         },
    //     });
    //     let response = await promise.json();
    //     let {data} = response;
    //     if (data && data.length === 0) {
    //         setHasItems(false);
    //     } else {
    //         setHasItems(true);
    //     }
    // };
    // useEffect(() => {
    //     if (props.patientId) {
    //         checkPatientInventory(props.patientId);
    //     }
    // }, [props.patientId]);

    return (
        <React.Fragment>
            {hasItems ? ( addMore ?
                <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<AddIcon style={{fontSize:28}}/>}
                    style={{
                        fontWeight:'bold',
                        border:'1px solid #00223D',
                        fontSize:props.mobileButton ? 16 : 12,
                        width: props.mobileButton ? 200 : props.addMore ? 190 : 120,
                        paddingTop: props.mobileButton ? 16 : 8,
                        paddingBottom: props.mobileButton ? 16 : 8,
                        height: props.mobileButton ? 60 : 40
                    }}
                    onClick={handleClickOpen}
                >
                    {addMore ? "Exchange More" : "NEW EXCHANGE"}
                </Button>
                : <Button
                    variant="outlined"
                    size="small"
                    className={props.mobileButton ? classes.mobileButton : (buttonStyleClass ? buttonStyleClass : classes.button)}
                    startIcon={<img
                        src={ToolIcon}
                        alt="logo"
                        width='16'
                        height='15'
                        // className={classes.simpleLogo}
                        style={{cursor: 'pointer',}}
                    />}
                    onClick={handleClickOpen}
                >
                    {addMore ? "Exchange More" : "NEW EXCHANGE"}
                </Button>
            ) : (<div style={{width: 160}}/>)
            }
            <Dialog
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'xl'}
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
                classes={isMobile ? {
                    paper: classes.paper,
                    paperFullWidth: classes.paperFullWidth,
                    paperScrollPaper: classes.paperScrollPaper,
                } : {}}
            >
                <DialogTitle
                    style={{ padding:  isMobile ? "32px 16px 0px 16px" : '32px 32px 0px 32px' }}
                    id="alert-dialog-title">
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant={"subtitle1"} className={classes.h1}>
                                {componentTitles.exchange}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent
                    style={{ padding: isMobile ? "0px 16px " :'0px 32px' }}
                >
                    <Grid container style={{ display: 'flex', justify: 'flex-start' }}>
                        <Grid item style={{ display: 'flex', flexDirection: 'column', paddingRight: 36, }}>
                            <Typography variant={"subtitle1"} className={classes.h4}>
                                {props.name}
                            </Typography>
                            <Typography variant={"subtitle2"} className={classes.h5}>
                                {props.address}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className={classes.root}>
                        <Stepper activeStep={activeStep} style={{padding:'24px 0 8px 0'}}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div>
                            {activeStep === steps.length ? (
                                "done"
                            ) : (
                                <div>
                                    {getStepContent(activeStep)}
                                </div>
                            )}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions
                    style={{ padding:  isMobile ? "4px 16px 16px 15px" : '0px 32px 32px 32px' }}
                >
                    <div>
                        <Button onClick={handleClose} variant="outlined">
                            Cancel
                        </Button>

                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.backButton}
                            variant="outlined"
                        >
                            Back
                        </Button>
                        <Button
                            onClick={() => handleNext(true, addMore ? [] : notes)}
                            color="primary"
                            variant="contained"
                            autoFocus
                            id={"next"}
                            disabled={isLoading ||( exchangeCart?.length === 0 && activeStep === steps.length - 1)}
                            style={{ fontWeight:'bold', fontSize:12,
                                border:(isLoading || ( exchangeCart?.length === 0 && activeStep === steps.length - 1)) ? 'none' : '1px solid #00223D',
                                height:40}}
                        >
                            {isLoading ? (
                                <CircularProgress style={{color:'white'}} size={24}/>
                            ): (
                                <React.Fragment>
                                    {(activeStep !== steps.length - 1) ? 'NEXT' : addMore ? 'ADD ITEMS' : 'CREATE ORDER'}
                                </React.Fragment>
                            )}

                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showError}
                >
                <DialogContent style={{paddingLeft: "18px", fontSize: "16px"}}>
                    You may have forgotten to submit a note.
                </DialogContent>
                <DialogActions>
                    <Grid container style={{display:'flex', justifyContent:'flex-end', padding: "10px"}} spacing={1}>
                        <Grid item>
                            <Button variant={'contained'} color={'primary'} onClick={() => submitWithUnsavedNote()}>
                                Submit with note
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant={'outlined'} color={'primary'} onClick={() => handleNext(false, [])}>
                                Submit without any notes
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant={'outlined'} color={'primary'} onClick={() => setShowError(false)}>
                                Edit note
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{vertical, horizontal}}
                ContentProps={{classes: {root: classes.snackbar}}}
                style={{padding: '10px'}}
                key={`${vertical},${horizontal}`} open={showNotification}
                autoHideDuration={10000}
                onClose={hideNotification}
                message={`Exchange Order #S300${value} was successfully created!`}
                action={
                    <React.Fragment>
                        <Button style={{color: 'black', marginRight: '6px'}} size="small" onClick={viewOrder}>
                            View Exchange Order
                        </Button>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={hideNotification}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </React.Fragment>
                }
            >
            </Snackbar>
        </React.Fragment>
    );
}
