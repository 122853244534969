import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {Table} from "../../../../common/table/table";
import PatientsHeader from "../../../patients/view-all-patients/patientsHeader/patientsHeader";
import {endpoints} from "../../../../constants/endpoints";
import {routes} from "../../../../constants/routes/routes";
import {TabSearchBar} from "../../../../common/search/tabSearch/TabSearch";
import {logout, setCriteriaDME,} from "../../../../redux/actions";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {useHistory} from "react-router-dom";
import {setStates} from "../../../../common/search/tabSearch/TabSearchHelper";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {useSnackbar} from "notistack";
import {tableColumnTitles} from "../../../../constants/names/columnTitles";

export default function DmeTab() {
    let history = useHistory();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [dmeProviders, setDmeProviders] = useState([]);
    const [stringStatus, setStringStatus] = useState('');
    const searchValues = useSelector(state => state.SearchCriteriaDME);
    const [dmeFiltersValue, setDmeFiltersValue] = useState({
        input: '',
        criteria: ['All'],
        state: "All",
        isActiveDme: true,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [stateOptions, setStateOptions] = useState([]);
    const filterOptions = [
        {name: "states", placeholder: "State", options: stateOptions, value: dmeFiltersValue.states, isSelect: true},
        {name: "isActiveDme", value: dmeFiltersValue.isActiveDme, positive: "Active DMEs", negative: "Inactive DMEs"},];
    const handleDeleteCriteria = (chip) => {
        setDmeFiltersValue({...dmeFiltersValue, criteria: dmeFiltersValue.criteria.filter(c => chip !== c)});
    };

    function handleFilterUpdate(event) {
        setDmeFiltersValue({...dmeFiltersValue, [event.target.name]: event.target.value})
    }

    const handleInputKeyup = (event) => {
        if (event.keyCode === 13 && dmeFiltersValue.input) {
            if (!dmeFiltersValue.criteria.includes(dmeFiltersValue.input)) {
                if (dmeFiltersValue.criteria[0] === ('All')) {
                    setDmeFiltersValue({...dmeFiltersValue, criteria: [dmeFiltersValue.input], input: ''})
                } else {
                    setDmeFiltersValue({
                        ...dmeFiltersValue,
                        criteria: [...dmeFiltersValue.criteria, dmeFiltersValue.input],
                        input: ''
                    })
                }
            }
        }
    };
    const handleInputChange = (value) => {
        setDmeFiltersValue({...dmeFiltersValue, input: value})
    };

    const clearFilters = () => {
        setDmeFiltersValue({
            input: '',
            criteria: ['All'],
            state: "All",
            isActiveDme: true,
        });
        dispatch(setCriteriaDME(dmeFiltersValue));
    };
    useEffect(() => {
        dispatch(setCriteriaDME(dmeFiltersValue))
    }, [dmeFiltersValue]);

    function getDmeProviders() {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.all_dme_organizations, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let arr = []
                response.data.map(item => {
                    if (item.status === true){
                        item.stringStatus = 'true'
                    } else if(item.status === false) {
                        item.stringStatus = 'false'
                    }
                    arr.push(item)
                });
                setDmeProviders(arr);
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    }

    useEffect(() => {
        getDmeProviders();
        setStates(setStateOptions);
    }, []);

    useEffect(() => {
        if (dmeFiltersValue.isActiveDme === true) {
            setStringStatus('true')
        } else if (dmeFiltersValue.isActiveDme === false) {
            setStringStatus('false')
        }
    }, [dmeFiltersValue.isActiveDme]);


    const columns = [
        {
            title: 'Edit', field: 'dme_organization_id', width: 80,
            render: rowData => <div>
                <Tooltip placement="top" title="Edit provider">
                    <IconButton
                        onClick={() => {
                            history.push({
                                pathname: `${routes.dme_provider_edit}${rowData.dme_organization_id}`,
                                name: rowData.dme_organization_id
                            })
                        }}
                    >
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        },
        {title: 'Name', field: 'dme_name', width: 200, defaultFilter:dmeFiltersValue.input},
        {title: tableColumnTitles.organization, field: 'state', hidden:true,  defaultFilter:dmeFiltersValue.states},
        {title: tableColumnTitles.organization, field: 'stringStatus', hidden:true,  defaultFilter:stringStatus},
        {
            title: 'Address', field: 'address1', width: 200,
            render: rowData => <div>
                <div>{rowData.address1 + ' ' + rowData.address2}</div>
                <div>{rowData.city + ' ' + rowData.state}</div>
            </div>
        },
        {title: 'Phone Number', field: 'phone', width: 220},
        {
            title: 'Status', field: 'status', width: 140,
            render: rowData => <div style={{marginLeft: '-40px', textAlign: 'center'}}>
                {rowData.status ? (
                    <span className={'active_status'}> Active </span>
                ) : (
                    <span className={'inactive_status'}> Inactive </span>
                )}
            </div>
        },
        {
            title: 'Hospices', field: 'hospices', width: 340,
            render: rowData => <div>{rowData.hospices && rowData.hospices.map((org, index) => (
                <div className={'organization_row'} key={index}>
                    {org}
                </div>
            ))}
            </div>
        },
    ];

    return (
        <React.Fragment>
            <Grid container
                  direction="row"
                  justify="center"
                  alignItems="center">
                <Grid item xs={10}>
                    <PatientsHeader buttonText={'DME Provider'}
                                    path={'/dmeprovider/new'}
                                    title={'DME Providers'}
                    />
                </Grid>
                <Grid item>
                    <TabSearchBar
                        filtersValue={dmeFiltersValue}
                        handleDeleteCriteria={handleDeleteCriteria}
                        handleInputKeyup={handleInputKeyup}
                        handleInputChange={handleInputChange}
                        clearFilters={clearFilters}
                        filterOptions={filterOptions}
                        handleFilterUpdate={handleFilterUpdate}
                    />
                </Grid>
                <Table columns={columns} items={dmeProviders} isLoading={isLoading}/>
            </Grid>
        </React.Fragment>
    );
}
