import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {makeStyles} from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import readXlsxFile from 'read-excel-file';
import {tableColumnTitles} from "../../../../constants/names/columnTitles";
import Grid from "@material-ui/core/Grid";
import {componentTitles} from "../../../../constants/names/titles";
import Typography from "@material-ui/core/Typography";
import MaterialTable from "material-table";
import {endpoints} from "../../../../constants/endpoints";
import {status} from "../../../../constants/status/status";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {logout} from "../../../../redux/actions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {buttonText} from "../../../../constants/alert/alertButtonTexts";
import {useSnackbar} from "notistack";
import {routes} from "../../../../constants/routes/routes";
import Plus from "../../../../assets/plus.svg";
import ExportForm from "../../../../common/exportForm/exportForm";
import ClinicalSettings from "./clinicalSettings/clinicalSettings";

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        padding: '9px 18px'
    },
    icon: {
        paddingRight: '5px',
        fontSize: '1.5em'
    },
    root: {
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '50px'
    },
    title: {
        fontWeight: "normal"
    },
    container: {
        padding: 24,
        paddingTop: 36
    }
}));

export default function InventoryAdmin() {
    const fileRef = React.useRef();
    const classes = useStyles();
    const [exportValues, setExportValues] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const columns = [
        {title: tableColumnTitles.itemId, field: 'item_id', width: 112, editable: 'never'},
        {title: tableColumnTitles.itemName, field: 'name', width: 300},
        {title: tableColumnTitles.itemDescription, field: 'description', width: 500},
        {title: tableColumnTitles.category, field: 'category', width: 140},
        {title: tableColumnTitles.itemTags, field: 'tags', width: 150},
        {title: tableColumnTitles.itemImageId, field: 'image', width: 200},
        {title: tableColumnTitles.itemImagePreview, field: 'image', width: 120,

            render: rowData => <React.Fragment>
                <img src={`${process.env.REACT_APP_BUCKET_URL}${rowData.image}`} width={'60px'}/>
            </React.Fragment>

        },
        {title: tableColumnTitles.maxQuantity, field: 'max_quantity_per_item', width: 140},
        {
            title: tableColumnTitles.priorityCode,
            field: 'priority_code',
            width: 140,
            lookup: {routine: 'routine', priority: 'priority'}
        },
        {
            title: tableColumnTitles.clinicalInput,
            field: 'require_clinical_input',
            width: 140,
            lookup: {Y: 'Yes', N: 'No'},
            render: rowData => <React.Fragment>
                <ClinicalSettings item_id={rowData.item_id} name={rowData.name}/>
                </React.Fragment>
        },
    ];
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = React.useState([]);

    const uploadImages = (event) => {
        const files = event.target.files;
        const formData = new FormData();
        for (const file of files) {
            formData.append("file", file);
        }

        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.uploadMasterListImages, {
            method: "POST",
            headers: {
                // "Content-type": "multipart/form-data",
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: formData
        }).then(res => {
                if (res.status === status.success) {
                    enqueueSnackbar(`File was uploaded with success.`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                        anchorOrigin: {vertical: "top", horizontal: "right"},
                    });
                }
                setIsLoading(false);
            }
        ).catch(err => {
            enqueueSnackbar("Something happened. Contact support.", {
                persist: false,
                variant: "error",
            });
        })
    };

    const handleItemsUpload = event => {
        //const files = event.target.files;
        readXlsxFile(event.target.files[0]).then((rows) => {
            setIsLoading(true);
            let arr = [];
            rows.map(row => {
                if (row[0] !== "Item_ID") {
                    arr.push({
                        id: row[0],
                        name: row[1],
                        description: row[2],
                        category: row[3],
                        tags: row[4],
                        image: row[5],
                        max_quantity_per_item: row[6] !== undefined ? row[6] : 1,
                        priority_code: row[7],
                        clinical_input: row[8] === "Y",
                    })
                }
            });
            (async () => {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
            })();
            const token = JSON.parse(localStorage.getItem('key'));
            fetch(endpoints.createMasterItem, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({items: arr})
            }).then(res => {
                    if (res.status === status.created) {
                        setItems([...items, ...arr]);
                    } else {
                        console.log("[Error]: Error encountered while uploading the file.");
                    }
                    setIsLoading(false);
                    fileRef.current.value = '';
                    enqueueSnackbar(`File was uploaded with success.`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                        anchorOrigin: {vertical: "top", horizontal: "right"},
                });
                }
            ).catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
                fileRef.current.value = '';
            })

        })
    };

    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        (async () => {
            const token = JSON.parse(localStorage.getItem('key'));
            const promise = await fetch(endpoints.getAllMasterItems, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + token.accessToken
                }
            });
            const response = await promise.json();
            setItems(response.data);
        })()
    }, []);


    useEffect(() => {
        if (items !== undefined) {
            let final = [];
            items.map(item => {
                let newArr = [];
                newArr.push(item.item_id);
                newArr.push(item.name);
                final.push(newArr)
            });
            setExportValues(final);
        }
    }, [items]);

    const headers = [
        {text: "System_ID", style: 'tableHeader'},
        {text: "Item_Name", style: 'tableHeader'},
    ];
    const excelHeaders = [
        "System_ID",
        "Item_Name",
    ];

    return (
        <React.Fragment>
            <Grid container justify={"center"} alignItems={"center"} direction={"column"} className={classes.container}>

                <Grid item>
                    <Typography variant={"h6"}>
                        {componentTitles.masterInventory}
                    </Typography>
                </Grid>
                <Grid container justify={"space-between"} alignItems={"center"} direction={"row"}>
                    <Grid item>
                        <input
                            ref={fileRef}
                            //accept="image/*"
                            style={{display: 'none'}}
                            id="raised-button-file"
                            type="file"
                            onChange={handleItemsUpload}
                        />
                        <label htmlFor="raised-button-file">
                            <Button variant="outlined" component="span" className={classes.button}>
                                <CloudUploadIcon className={classes.icon}/>
                                {buttonText.uploadMasterList}
                            </Button>
                        </label>
                        <input
                            accept="image/*"
                            style={{display: 'none'}}
                            id="upload-images"
                            multiple
                            type="file"
                            onChange={uploadImages}
                        />
                        <label htmlFor="upload-images">
                            <Button variant="outlined" component="span" className={classes.button}>
                                <CloudUploadIcon className={classes.icon}/>
                                {buttonText.uploadMasterListImages}
                            </Button>
                        </label>
                    </Grid>
                    <Grid item>
                        <a href='https://masteritemsimages.s3.amazonaws.com/Initial+List+of+Items+-+Template+Complete.xlsx'
                           rel="noopener noreferrer" download style={{textDecoration:'none'}}>
                            <Button
                                variant="outlined"
                                color="default"
                                className={classes.button}
                            >
                                <GetAppIcon className={classes.icon}/>
                                Download template
                            </Button>
                        </a>
                    </Grid>
                </Grid>
                <Grid container justify='flex-end' className={classes.buttonContainer} style={{paddingTop:24, paddingBottom: 12}}>
                    <ExportForm
                        name='Export'
                        fileName='MasterInventory'
                        exportValues={exportValues}
                        headers={headers}
                        excelHeaders={excelHeaders}
                    />
                </Grid>
                <Grid item style={{width: '100%'}}>
                    <MaterialTable
                        title={componentTitles.masterInventory}
                        columns={columns}
                        data={items}
                        // options={{
                        //     searchFieldStyle:{border:'1px solid red', input:{backgroundColor:'red'}}
                        // }}
                        isLoading={isLoading}
                        editable={{
                            onRowAdd: (newData) =>
                                new Promise((resolve, reject) => {
                                    (async () => {
                                        const availableToken = await checkTokenAvailability();
                                        if (!availableToken) {
                                            dispatch(logout());
                                            history.push('/login');
                                        }
                                    })();
                                    const token = JSON.parse(localStorage.getItem('key'));
                                    fetch(endpoints.createMasterItem, {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + token.accessToken
                                        },
                                        body: JSON.stringify({
                                            items: [
                                                {
                                                    ...newData,
                                                    max_quantity_per_item: newData.max_quantity_per_item ? parseInt(newData.max_quantity_per_item) : 1
                                                }]
                                        })
                                    }).then(res => {
                                        if (res.status === status.created) {
                                            resolve();
                                            setItems((prevState) => {
                                                const data = [...prevState];
                                                data.push(newData);
                                                return data;
                                            });
                                        } else {
                                            reject();
                                        }
                                        history.push('/refresh/admin/masterinventory');
                                    }).catch(err => {
                                        console.log(err, 'error');
                                        enqueueSnackbar("Something happened. Contact support.", {
                                            persist: false,
                                            variant: "error",
                                        });
                                    })

                                }),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    (async () => {
                                        const availableToken = await checkTokenAvailability();
                                        if (!availableToken) {
                                            dispatch(logout());
                                            history.push('/login');
                                        }
                                    })();
                                    const token = JSON.parse(localStorage.getItem('key'));
                                    fetch(endpoints.updateMasterItemById + oldData.item_id, {
                                        method: "PUT",
                                        headers: {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + token.accessToken
                                        },
                                        body: JSON.stringify({
                                            items: [
                                                {
                                                    ...newData,
                                                    max_quantity_per_item: newData.max_quantity_per_item ? parseInt(newData.max_quantity_per_item) : 1
                                                }]
                                        })
                                    }).then(res => {
                                        if (res.status === status.success) {
                                            resolve();
                                            if (oldData) {
                                                setItems((prevState) => {
                                                    const data = [...prevState];
                                                    data[data.indexOf(oldData)] = newData;
                                                    return data;
                                                });
                                            }
                                        } else {
                                            reject();
                                        }
                                    }).catch(err => {
                                        console.log(err, 'error');
                                        enqueueSnackbar("Something happened. Contact support.", {
                                            persist: false,
                                            variant: "error",
                                        });
                                    })

                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve, reject) => {
                                    (async () => {
                                        const availableToken = await checkTokenAvailability();
                                        if (!availableToken) {
                                            dispatch(logout());
                                            history.push('/login');
                                        }
                                    })();
                                    const token = JSON.parse(localStorage.getItem('key'));
                                    fetch(endpoints.removeMasterItemById + oldData.item_id, {
                                        method: "DELETE",
                                        headers: {
                                            "Content-Type": "application/json",
                                            'Authorization': 'Bearer ' + token.accessToken
                                        }
                                    }).then(res => {
                                        if (res.status === status.nocontent) {
                                            resolve();
                                            setItems((prevState) => {
                                                const data = [...prevState];
                                                data.splice(data.indexOf(oldData), 1);
                                                return data;
                                            });
                                        } else {
                                            reject();
                                        }
                                    }).catch(err => {
                                        console.log(err, 'error');
                                        enqueueSnackbar("Something happened. Contact support.", {
                                            persist: false,
                                            variant: "error",
                                        });
                                    })
                                }),
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
