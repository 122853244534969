export const tooltips = {
    add_facility: "Add additional facility",
    add_region: "Add additional region",
    add_team: "Add additional team",
    add_warehouse: "Add warehouse",
    add_hospice: "Add additional hospice",
    goToPatientProfile: "Go to patient profile",
    zip_autocomplete: "Entering a ZIP code will populate City and State.",
    isRequired: "This field is required.",
    zipOnlyNumbers: "Zip code must contain numbers",
    invalidPhone: "Invalid phone number",
    nameAlreadyExists: "Name entered already exists.",
    pleaseFillInRequired: "Please fill all required fields",
    addHospiceRegion: "Add additional hospice region",
    editUser: "Edit User",
    processOrder: "Process Order",
    completeOrder: "Complete Order",
    completed: "Completed",
    pending: "Pending",
    canceled: "Canceled",
    rejected: "Rejected",
    showThirdCaregiver: "Add Third Caregiver",
    editPatient: "Edit Patient",
    deletePatient: "Delete Patient",
    editContract: "Edit Contract",
    app_support: "Please use this email address only for application issues",
    dme_support: "Please use this phone number for DME issues",
};
