import React, {useEffect, useRef, useState, useCallback } from 'react';
import { debounce } from "lodash";
import {useDispatch, useSelector} from 'react-redux';
import moment from "moment";
import Grid from '@material-ui/core/Grid';
import {logout, setCriteriaPatients} from "../../../redux/actions";
import jwt from "jwt-decode";
import Button from "@material-ui/core/Button";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import ExportForm from "../../../common/exportForm/exportForm";
import {useHistory} from "react-router-dom";
import MapContainer from "./mapByAddress/mapComponent";
import {TabSearchBar} from "../../../common/search/tabSearch/TabSearch";
import {checkPermissions} from "../../../common/permissions/checkPermissions";
import {permissions} from "../../../constants/permissions/permissions";
import {endpoints} from "../../../constants/endpoints";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {useSnackbar} from "notistack";
import Plus from "../../../assets/plus.svg";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {routes} from "../../../constants/routes/routes";
import {makeStyles} from "@material-ui/core/styles";
import {viewPatientsStyle} from "./view-all-patients.style";
import {titles} from "../../../constants/inputs/tableColumnTitles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import NewPatientsTable from "./newPatientsTable/newPatientsTable";
import {isMobile} from "react-device-detect";
import {AllPatientsMobileView} from "./mobileView/mobileView";
import {PatientCard} from "./patientCard/PatientCard";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
let _ = require('lodash');

export default function ViewPatients() {
    const tableRef = useRef();
    const [page, setPage] = React.useState(0);
    const classes = useStyles();
    let history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const dispatch = useDispatch();
    const isLogged = useSelector(state => state.isLogged);
    const searchValues = useSelector(state => state.SearchCriteriaPatients);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(10);
    const [patients, setPatients] = useState([]);
    const [rows,setRows] = useState([]);
    const [allRows,setAllRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filteredPatients, setFilteredPatients] = useState([]);
    const [filtersValue, setFiltersValue] = useState({
        input: '',
        criteria: ['All'],
        notArchived: true,
    });
    const [columnFilters, setColumnFilters] = useState({
        statusSearch: "",
        patientSearch: "",
        addressSearch: "",
        phoneSearch: "",
    });
    const [filterCriteria, setFilterCriteria] = useState({});
    const [map, setMap] = React.useState(false);
    const [nurseOptions, setNurseOptions] = useState([]);
    const [nurseList, setNurseList] = useState([]);
    const [teamOptions, setTeamOptions] = useState([]);
    const [organizationOptions, setOrganizationOptions] = useState([]);
    const statusOptions = [{label: 'All Statuses', value: "All Statuses"},{label: 'Active', value: "Active"},{label: "Discharged", value: "Discharged"},{label: "Expired", value: "Expired"},{label: 'Inactive', value: "Inactive"},{label: "Revoked", value: "Revoked"}];
    const careOptions = [{label: "Facility", value: "Facility"}, {label: "Inpatient unit", value: "Inpatient unit"}, {label: 'Patient Home', value: "Patient Home"}];
    const [searchType, setSearchType] = useState(0);
    const [initial,setInitial] = useState(true);
    const [loadMoreStatus, setLoadMoreStatus ] = useState(false);

    const [hasMore,setHasMore] = useState(true);
    const observer = useRef();
    const patientRef = useCallback((node) => {
        if(loading) return;

        if(observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if(entries[0].isIntersecting && hasMore === true){
                if(initial) setInitial(false);
                loadMore(10,page);
                setPage(page+1);
            }
        });

        if(node) observer.current.observe(node);
    },[loading]);

    function applyFilters(){
        setAllRows([]);
        setPage(0);
        getPatients(10,0, searchType);
    }

    let user = JSON.parse(localStorage.getItem('key'));
    let decoded = jwt(user.accessToken);
    let type = decoded.payload.type;
    let filterOptions = [];
    if (type === "DME") {
        statusOptions.push({value: "Disenrolled", label: "Disenrolled"});
        filterOptions = [
            {
                name: "organization",
                placeholder: "Organization",
                options: organizationOptions,
                value: filtersValue.organization,
                isSelect: true
            },
            {name: "team", placeholder: "Team", options: teamOptions, value: filtersValue.team, isSelect: true},
            {name: "nurse", placeholder: "Nurse", options: nurseOptions, value: filtersValue.nurse, isSelect: true},
            {name: "status", placeholder: "Status", options: statusOptions, value: filtersValue.status, isSelect: true},
            {
                name: "care_setting",
                placeholder: "Care Setting ",
                options: careOptions,
                value: filtersValue.care_setting,
                isSelect: true
            },
        ]
    } else if (type === "Hospice") {
        if (decoded.payload.isPace) {
            statusOptions.push({value: "Disenrolled", label: "Disenrolled"});
        }
        filterOptions = [
            {name: "team", placeholder: "Team", options: teamOptions, value: filtersValue.team, isSelect: true},
            {name: "nurse", placeholder: "Nurse", options: nurseOptions, value: filtersValue.nurse, isSelect: true},
            {name: "status", placeholder: "Status", options: statusOptions, value: filtersValue.status, isSelect: true},
            {
                name: "care_setting",
                placeholder: "Care Setting ",
                options: careOptions,
                value: filtersValue.care_setting,
                isSelect: true
            },
        ]
    }

    const handleColumnSearchInput = (e) => {
        const {name, value} = e.target;
        setColumnFilters(prevState => {
            return {...prevState, [name]: value}
        })
    };

    const clearFilters = () => {
        setFiltersValue({
            input: '',
            criteria: ['All'],
            notArchived: true
        });
        setColumnFilters({
            statusSearch: "",
            patientSearch: "",
            addressSearch: "",
            phoneSearch: "",
        });
        if (isMobile){
            setPage(0);
            setAllRows([]);
            getPatients(10,0, 'reset');
        } else {
            getPatients(10,0, 'reset');
            setRowsPerPage(10);
        }
    };
    function search() {
        tableRef.current && tableRef.current.onQueryChange()
    }
    const handler = useCallback(debounce(search, 300), []);
    useEffect(() => {
        handler();
        dispatch(setCriteriaPatients(filtersValue))
    }, [filtersValue]);

    const handleInputKeyup = (event) => {
        if (event.keyCode === 13 && filtersValue.input) {
            if (!filtersValue.criteria.includes(filtersValue.input)) {
                if (filtersValue.criteria[0] === ('All')) {
                    setFiltersValue({...filtersValue, criteria: [filtersValue.input], input: ''})
                } else {
                    setFiltersValue({
                        ...filtersValue,
                        criteria: [...filtersValue.criteria, filtersValue.input],
                        input: ''
                    })
                }
            }
        }
    };
    const handleInputChange = (event) => {
        setFiltersValue({...filtersValue, input: event})
    };
    const handleDeleteCriteria = (chip) => {
        setFiltersValue({...filtersValue, criteria: filtersValue.criteria.filter(c => chip !== c)});
    };

    function handleFilterUpdate(event) {
        setFiltersValue({...filtersValue, [event.target.name]: event.target.value})
    }

    useEffect(() => {
        if (patients.length === 0) {
            (async () => {
                const availableToken = await checkTokenAvailability();
                if (!availableToken) {
                    dispatch(logout());
                    history.push('/login');
                }
            })();
            const token = JSON.parse(localStorage.getItem('key'));
            fetch(endpoints.distinctNurses, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let result = [];
                    response.data.map(nurse => {
                        result.push({label: nurse.name, value: nurse.name});
                    });
                    const sortedResult = result.sort((a,b) => a.label.localeCompare(b.label));
                    setNurseOptions(sortedResult);
                })
                .catch(err => {
                    console.log(err, 'error');
                });
            let endpoint;
            if (type === "Hospice") {
                endpoint = `${endpoints.getHospiceTeams}${decoded.payload.hospice_organization_id}`
            }
            if (type === "DME" && decoded.payload.role_id !== 6) {
                endpoint = `${endpoints.getAllHospiceTeamsByDmeId}${decoded.payload.dme_organization_id}`
            }
            if (decoded.payload.role_id === 6) {
                endpoint = `${endpoints.getAllHospicesAllTeams}`
            }
            fetch(endpoint, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let result = [];
                    response.data.map(team => {
                        if (!result.some(e => e.label === team.team_name)){
                            result.push({label: team.team_name, value: team.team_name})
                        }
                    });
                    setTeamOptions(result);
                })
                .catch(err => {
                    console.log(err, 'error');
                })
        }
    }, []);

    const getExportValues = () => {
            let final = [];
            rows.map(patient => {
                let newArr = [];
                newArr.push(patient.hospice_name);
                newArr.push(patient.last_name);
                newArr.push(patient.first_name);
                newArr.push(patient.patient_id);
                newArr.push(patient.medical_record);
                newArr.push(new Date(moment(patient.date_of_birth).format('L')));
                newArr.push(patient.primary_caregiver_name);
                newArr.push(patient.primary_caregiver_phone);
                if(patient.facility_name === undefined) {
                    newArr.push('');
                }else {
                    newArr.push(patient.facility_name);
                }
                newArr.push(patient.address1);
                newArr.push(patient.address2);
                newArr.push(patient.city);
                newArr.push(patient.state);
                newArr.push(patient.zip);
                newArr.push(patient.phone_number);
                newArr.push(patient.status);
                newArr.push(patient.last_status_date ? new Date(moment(patient.last_status_date).format('L')) : "");
                final.push(newArr)
            });
            return final;
    };
    useEffect(() => {
        let values = {};
        if (filtersValue.team && filtersValue.team.length > 0) {
            values.team = filtersValue.team;
        }
        if (filtersValue.nurse && filtersValue.nurse.length > 0) {
            values.nurse_name = filtersValue.nurse;
        }
        setFilterCriteria(values)
    }, [filtersValue]);

    useEffect(()=> {
        if(decoded.payload.dme_organization_id !== null && decoded.payload.type === "DME" && organizationOptions.length === 0){
            const token = JSON.parse(localStorage.getItem('key'));
            //dme admin and superadmin roles
            if (decoded.payload.role_id === 6 || decoded.payload.role_id === 5) {
                fetch(endpoints.getAllHospiceIds, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                })
                    .then(response => {
                        return response.json()
                    })
                    .then(response => {
                        let result = [];
                        response.data.map(org => {
                            result.push({label: org.hospice_name, value: org.hospice_name})
                        });
                        setOrganizationOptions(result);
                    })
                    .catch(err => {
                        console.log(err, 'error');
                        enqueueSnackbar("Something happened. Contact support.", {
                            persist: false,
                            variant: "error",
                        });
                    });
            } else {
                fetch(`${endpoints.getContractedHospices}${decoded.payload.dme_organization_id}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.accessToken
                    },
                })
                    .then(response => {
                        return response.json()
                    })
                    .then(response => {
                        let result = [];
                        response.data.map(org => {
                            result.push({label: org.hospice_name, value: org.hospice_name})
                        });
                        setOrganizationOptions(result);
                    })
                    .catch(err => {
                        console.log(err, 'error');
                        enqueueSnackbar("Something happened. Contact support.", {
                            persist: false,
                            variant: "error",
                        });
                    });
            }
        }

    }, []);
    const formatPhoneNumber = (phone) => {
        let cleaned = ('' + phone).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3];
        }
        return null;
    };

    const headers = [
        {text: "Company", style: 'tableHeader'},
        {text: "Last Name", style: 'tableHeader'},
        {text: "First Name", style: 'tableHeader'},
        {text: "Patient ID", style: 'tableHeader'},
        {text: "Medical Record", style: 'tableHeader'},
        {text: "DOB", style: 'tableHeader'},
        {text: "Primary Caregiver Name", style: 'tableHeader'},
        {text: "Primary Caregiver Phone", style: 'tableHeader'},
        {text: "Facility", style: 'tableHeader'},
        {text: "Address 1", style: 'tableHeader'},
        {text: "Address 2", style: 'tableHeader'},
        {text: "City", style: 'tableHeader'},
        {text: "State", style: 'tableHeader'},
        {text: "Postal Code", style: 'tableHeader'},
        {text: "Phone", style: 'tableHeader'},
        {text: "Status", style: 'tableHeader'},
        {text: "Discharge Date", style: 'tableHeader'},
    ];
    const excelHeaders = [
        "Company",
        "Last_Name",
        "First Name",
        "Patient_ID",
        "Medical_Record",
        "DOB",
        "Primary_Caregiver_Name",
        "Primary_Caregiver_Phone",
        "Facility",
        "Address_1",
        "Address_2",
        "City",
        "State",
        "Postal_Code",
        "Phone",
        "Status",
        "Discharge_Date"
    ];
    const handleSearchChange = (event, newValue) => {
        if (newValue === 0) {
            getPatients(10, 0, 'reset');
            setFiltersValue({
                input: '',
                criteria: ['All'],
            })
        } else if (newValue === 1) {
            setFiltersValue({
                input: '',
                criteria: ['All'],
            });
            setFilteredPatients([]);
            setPatients([])
        }
        setSearchType(newValue);
    };
    const searchByLot = (rowsPerPage, page) => {
        setLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.patientsByLot}${filtersValue.input}/${decoded.payload.dme_organization_id}/${rowsPerPage}/${page}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data === undefined){
                    setRows([]);
                    setAllRows([]);
                } else {
                    setRows(response.data);
                    setRowsPerPage(response.data.length)
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };

    function getPatients(limit, page, type) {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem('key'));
        const userData = jwt(JSON.parse(localStorage.getItem("key")).accessToken).payload;
        let endpoint;
        if (userData.hospice_organization_id !== null) {
            endpoint = `${process.env.REACT_APP_DEV_API_URL}/patients/hospice/all`
        }
        if (userData.dme_organization_id !== null) {
            endpoint = `${process.env.REACT_APP_DEV_API_URL}/patients/dme/activenew`
        }
        let search_type;
        if (Number(searchType) === 0){
            search_type = 'default'
        }
        if (Number(searchType) === 1){
            search_type = 'order'
        }
        if (Number(searchType) === 2){
            search_type = 'medical_record'
        }
        if (Number(searchType) === 3){
            search_type = 'lot'
        }
        let body = {
            freeText: filtersValue.input,
            limit: limit ? limit : 10,
            page: page ? page : 0,
            nurse: filtersValue.nurse,
            hospice: filtersValue.organization,
            team: filtersValue.team,
            status: filtersValue.status ? filtersValue.status : "Active",
            careSetting: filtersValue.care_setting,
            dmeId: userData.dme_organization_id,
            hospiceId: userData.hospice_organization_id,
            search_type: search_type,
            app_version: `${process.env.REACT_APP_VERSION}`,
            columnFilters: columnFilters,
        };
        if (type === 'reset'){
            body = {
                freeText: "",
                limit: 10,
                page: 0,
                dmeId: userData.dme_organization_id,
                hospiceId: userData.hospice_organization_id,
                search_type: 'default',
                app_version: `${process.env.REACT_APP_VERSION}`,
                columnFilters: {
                    statusSearch: "",
                    patientSearch: "",
                    addressSearch: "",
                    phoneSearch: "",
                }

            }
        }
        fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(body)
        })
            .then(response => {
                // setLoading(false);
                return response.json()
            })
            .then(result => {
                if (result.status === 'error'){
                    setRows([]);
                    setAllRows([]);
                } else {
                    setRows(result.data);
                    setAllRows(result.data);
                    setLoadMoreStatus(result.data.length !== 10)
                }
                // setFilteredPatients(result.data);
                setLoading(false);
            })
            .catch(err => {
                // setFilteredPatients([]);
                setLoading(false);
            })
    }

    function loadMore(limit, page, type) {
        const token = JSON.parse(localStorage.getItem('key'));
        const userData = jwt(JSON.parse(localStorage.getItem("key")).accessToken).payload;
        let endpoint;
        if (userData.hospice_organization_id !== null) {
            endpoint = `${process.env.REACT_APP_DEV_API_URL}/patients/hospice/all`
        }
        if (userData.dme_organization_id !== null) {
            endpoint = `${process.env.REACT_APP_DEV_API_URL}/patients/dme/activenew`
        }
        let search_type;
        if (searchType === 0){
            search_type = 'default'
        }
        if (searchType === 1){
            search_type = 'order'
        }
        if (searchType === 2){
            search_type = 'medical_record'
        }
        if (searchType === 3){
            search_type = 'lot'
        }
        let body = {
            freeText: filtersValue.input,
            limit: limit ? limit : 10,
            page: page ? page : 0,
            nurse: filtersValue.nurse,
            hospice: filtersValue.organization,
            team: filtersValue.team,
            status: filtersValue.status ? filtersValue.status : "Active",
            careSetting: filtersValue.care_setting,
            dmeId: userData.dme_organization_id,
            hospiceId: userData.hospice_organization_id,
            search_type: search_type,
            app_version: `${process.env.REACT_APP_VERSION}`,
            columnFilters: columnFilters,
        };
        if (type === 'reset'){
            body = {
                freeText: "",
                limit: 10,
                page: 0,
                dmeId: userData.dme_organization_id,
                hospiceId: userData.hospice_organization_id,
                search_type: 'default',
                app_version: `${process.env.REACT_APP_VERSION}`,
                columnFilters: {
                    statusSearch: "",
                    patientSearch: "",
                    addressSearch: "",
                    phoneSearch: "",
                }
            }
        }
        fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(body)
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                setLoadMoreStatus(result?.data?.length !== 10);
                if (result.status === 'error'){
                    setHasMore(false);
                } else {
                    setAllRows([...allRows,...result.data]);
                }
            })
            .catch(err => {
                setHasMore(false);
                setLoading(false);
            })
    }

    useEffect(() => {
        if(rowsPerPage === -1 || isMobile){
            loadMore(10,0);
            setPage(page+2);
        }
    },[rowsPerPage]);

    useEffect(()=> {
        getPatients(10,0);
    },[]);

    const hasPrintPermissions = checkPermissions([permissions.print_patient]);
    const hasDownloadPermissions = checkPermissions([permissions.download_patient]);

    if (isMobile){
        return (
            <AllPatientsMobileView
                getPatients={getPatients}
                filtersValue={filtersValue}
                handleDeleteCriteria={handleDeleteCriteria}
                handleInputKeyup={handleInputKeyup}
                handleInputChange={handleInputChange}
                clearFilters={clearFilters}
                filterOptions={filterOptions}
                handleFilterUpdate={handleFilterUpdate}
                placeholder={"Search by Medical Record"}
                buttonText={"SEARCH"}
                searchType={searchType}
                setSearchType={setSearchType}
                setRowsPerPage={setRowsPerPage}
                applyFilters={applyFilters}
            >
                <React.Fragment>
                <Grid item container spacing={1}>
                    {allRows && allRows.map((row,id) => {
                        return <Grid item xs={12} key={row.patient_id}
                                     // ref={allRows.length === id + 5 ? patientRef : null}
                        >
                            <PatientCard patient={row}/>
                        </Grid>
                    })}
                </Grid>
                    <Grid container justify={'center'} style={{paddingBottom:16}}>
                        {allRows && allRows.length > 0 ?
                            <Button
                                disabled={loadMoreStatus}
                                color={'primary'}
                                variant={'outlined'}
                                onClick={()=> {
                                    loadMore(10,page+1);
                                    setPage(prev => prev+1)
                                }}>Load more {loading && <CircularProgress size={20} style={{marginLeft:8, color:'grey'}}/>}</Button>
                            : loading ? <Grid container direction={'column'} alignItems={'center'} alignContent={'center'} style={{paddingTop:16}}>
                                <CircularProgress color={'primary'} size={28}/>
                                <div style={{color:'gray', paddingTop:8}}>Loading...</div>
                            </Grid>: <h3>No patients found</h3>
                        }

                    </Grid>
                </React.Fragment>
            </AllPatientsMobileView>
        )
    }

    return (
        <React.Fragment>
            {(isLogged) ?
                <Grid container className={classes.root}>
                    <Backdrop className={classes.backdrop} open={false}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Grid item xs={12}>
                        <Typography className={classes.h1}>
                            {titles.patients}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.generalSearchContainer}>
                        {(checkPermissions([permissions.read_patients], "DME")) && <div
                            className={classes.generalSearch}>
                            <Tabs
                                value={searchType}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleSearchChange}
                                variant="scrollable"
                                scrollButtons="off"
                                classes={{
                                    flexContainer: classes.flexContainer
                                }}
                            >
                                <Tab className={classes.tabs} label="GENERAL SEARCH"/>
                                <Tab className={classes.tabs} label="SEARCH BY ORDER#"/>
                                <Tab className={classes.tabs} label="SEARCH BY MR#"/>
                                <Tab className={classes.tabs} label="SEARCH BY LOT"/>
                            </Tabs></div>
                        }
                        {(checkPermissions([permissions.read_patients], "Hospice")) && <div
                            className={classes.generalSearch}>
                            <Tabs
                                value={searchType}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={handleSearchChange}
                                aria-label="disabled tabs example"
                            >
                                <Tab className={classes.tabs} label="GENERAL SEARCH"/>
                                <Tab className={classes.tabs} label="SEARCH BY ORDER#"/>
                                <Tab className={classes.tabs} label="SEARCH BY MR#"/>
                            </Tabs></div>
                        }
                    </Grid>
                    <Grid item xs={12} className={classes.generalSearchContainer}>
                        {searchType === 0 && (
                            <TabSearchBar
                                enableHelper={true}
                                getPatients={getPatients}
                                filtersValue={filtersValue}
                                handleDeleteCriteria={handleDeleteCriteria}
                                handleInputKeyup={handleInputKeyup}
                                handleInputChange={handleInputChange}
                                clearFilters={clearFilters}
                                filterOptions={filterOptions}
                                handleFilterUpdate={handleFilterUpdate}
                                placeholder={"Search by medical record number or last name or first name or address1 or address2 or city or zip or state or patientID"}
                                setPage={setPage}
                                setRowsPerPage={setRowsPerPage}
                            />
                        )}
                        {searchType === 1 && (
                            <TabSearchBar
                                getPatients={getPatients}
                                filtersValue={filtersValue}
                                handleDeleteCriteria={handleDeleteCriteria}
                                handleInputKeyup={handleInputKeyup}
                                handleInputChange={handleInputChange}
                                clearFilters={clearFilters}
                                filterOptions={filterOptions}
                                searchPatients={searchByLot}
                                handleFilterUpdate={handleFilterUpdate}
                                placeholder={"Search by Order"}
                                buttonText={"SEARCH"}
                            />
                        )}
                        {searchType === 2 && (
                            <TabSearchBar
                                getPatients={getPatients}
                                filtersValue={filtersValue}
                                handleDeleteCriteria={handleDeleteCriteria}
                                handleInputKeyup={handleInputKeyup}
                                handleInputChange={handleInputChange}
                                clearFilters={clearFilters}
                                filterOptions={filterOptions}
                                searchPatients={searchByLot}
                                handleFilterUpdate={handleFilterUpdate}
                                placeholder={"Search by Medical Record"}
                                buttonText={"SEARCH"}
                            />
                        )}
                        {searchType === 3 && (
                            <TabSearchBar
                                getPatients={searchByLot}
                                filtersValue={filtersValue}
                                handleDeleteCriteria={handleDeleteCriteria}
                                handleInputKeyup={handleInputKeyup}
                                handleInputChange={handleInputChange}
                                clearFilters={clearFilters}
                                filterOptions={filterOptions}
                                searchPatients={searchByLot}
                                handleFilterUpdate={handleFilterUpdate}
                                placeholder={"Search by Lot Number"}
                                buttonText={"SEARCH"}
                            />
                        )}

                        <Grid container justify='space-between' className={classes.buttonContainer}>
                            <Button variant={'contained'} color={'primary'} className={classes.button}
                                    onClick={() => history.push(routes.add_patient)}
                            >
                                <img
                                    src={Plus}
                                    alt="logo"
                                    width='16'
                                    style={{marginRight: 8}}
                                />
                                ADD NEW PATIENT
                            </Button>
                            {rows && rows.length > 0 && ( hasPrintPermissions || hasDownloadPermissions ) && (
                                <ExportForm
                                    landscape={true}
                                    fileName='Patients'
                                    getExportValues={getExportValues}
                                    showDownload={hasDownloadPermissions}
                                    showPrint={hasPrintPermissions}
                                    headers={headers}
                                    excelHeaders={excelHeaders}
                                />
                            )}
                        </Grid>
                    </Grid>
                    {!isMobile ? map ?
                        <Grid item xs={12} className={classes.map}>
                            <MapContainer/>
                        </Grid> :
                        <Grid item xs={12}
                              className={classes.tableContainer}
                        >
                            <NewPatientsTable
                                enableSearchHeader={searchType === 0}
                                handleSearch={handleColumnSearchInput}
                                addressSearch={columnFilters.addressSearch}
                                patientSearch={columnFilters.patientSearch}
                                statusSearch={columnFilters.statusSearch}
                                phoneSearch={columnFilters.phoneSearch}
                                formatPhoneNumber={formatPhoneNumber}
                                freeText={filtersValue.input}
                                nurse={filtersValue.nurse}
                                hospice={filtersValue.organization}
                                team= {filtersValue.team}
                                status={filtersValue.status}
                                careSetting={filtersValue.care_setting}
                                rows={rows}
                                loading={loading}
                                getPatients={getPatients}
                                searchByLot={searchByLot}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                searchType={searchType}
                                allRows={allRows}
                                loadMore={loadMore}
                                patientRef={patientRef}
                                initial={initial}
                            />

                        </Grid> : ''
                    }
                    
                </Grid> : ''}
        </React.Fragment>
    );
}
const useStyles = makeStyles((theme) => (viewPatientsStyle(theme)));
