import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {endpoints} from "../../../constants/endpoints";
import ExportForm from "../../../common/exportForm/exportForm";
import {checkTokenAvailability} from "../../../common/utils/utils";
import {logout} from "../../../redux/actions";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Table} from "../../../common/table/table";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReportsHeader from "../../../common/reportsHeader/reportsHeader";

export const OOFLFMonthly = props => {
    const isLogged = useSelector(state => state.isLogged);
    const classes = useStyles();
    const token = JSON.parse(localStorage.getItem('key'));
    const [results, setResults] = useState([]);
    const [exportValues, setExportValues] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);

    const generateReport = (payload) => {
        setIsLoading(true);
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        fetch(endpoints.getOOFReport, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(payload)
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                response.data.map(item => item.monthly = item.monthly ? "Yes" : "No" );
                setResults(response.data);
                setIsLoading(false);
            })
            .catch(err => {
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
                setIsLoading(false);
            })
    };
    const clearData = () => {
        setResults([]);
    }
    useEffect(() => {
        if (results !== undefined) {
            let final = [];
            results.map(value => {
                let newArr = [];
                newArr.push(value.hospice_name);
                newArr.push(value.patient_id);
                newArr.push(value.first_name);
                newArr.push(value.last_name);
                newArr.push(value.medical_record);
                newArr.push(value.item_name);
                newArr.push(value.contract_type);
                newArr.push(value.monthly);
                final.push(newArr)
            });
            setExportValues(final);
        }
    }, [results]);
    const headers = [
        {text: "Organization_Name", style: 'tableHeader'},
        {text: "Patient ID", style: 'tableHeader'},
        {text: "Patient First Name", style: 'tableHeader'},
        {text: "Patient Last Name", style: 'tableHeader'},
        {text: "Medical Record Number", style: 'tableHeader'},
        {text: "Item Name", style: 'tableHeader'},
        {text: "Contracted type", style: 'tableHeader'},
        {text: "Monthly type", style: 'tableHeader'}
    ];
    const excelHeaders = [
        "Organization_Name",
        "Patient_ID",
        "Patient_First_Name",
        "Patient_Last_Name",
        "Medical_Record_Number",
        "Item_Name",
        "Contracted_Type",
        "Monthly_Type"
    ];
    return (
        <Grid container className={classes.div}>
            {(isLogged) &&
            <Grid container className={classes.container}>
                <Typography className={classes.maintitle}>OOF Report</Typography>
                <ReportsHeader generateReport={generateReport} clearData={clearData}/>
                <Grid container className={classes.select}>
                    <div className={classes.flex}>
                        <ExportForm
                            fileName='OOF Report'
                            exportValues={exportValues}
                            headers={headers}
                            excelHeaders={excelHeaders}
                            showPrint={true}
                        />
                    </div>
                    <Grid container style={{overflow:'hidden', maxWidth:'calc(100vw - 80px)'}}>
                        <Table
                            search={false}
                            filtering={true}
                            columns={[{title: 'Organization', field: 'hospice_name'},
                                {title: 'Patient ID', field: 'patient_id'},
                                {title: 'First Name', field: 'first_name'},
                                {title: 'Last Name', field: 'last_name'},
                                {title: 'Medical Record Number', field: 'medical_record'},
                                {title: 'Item Name', field: 'item_name', width:200,},
                                {title: 'Contracted Type', field: 'contract_type'},
                                {title: 'Monthly Type', field: 'monthly'}
                            ]}
                            items={results}
                            backGroundColor={"rgba(237, 242, 244, 0.73)"}
                            textColor={"rgba(46, 50, 54, 1)"}
                        />
                    </Grid>

                </Grid>
                <Backdrop className={classes.backdrop} open={isLoading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Grid>
            }
        </Grid>
    )
};

const useStyles = makeStyles((theme) => ({
    div: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignContent: "center",
        alignItems: "center"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    container: {
        padding: 24,
        // maxWidth: 1200,
    },
    title: {
        textAlign: "left",
        padding: 12,
        paddingBottom: 0,
        width: 200
    },
    maintitle: {
        textAlign: "left",
        padding: 12,
        paddingBottom: 0,
        width: 220,
        fontSize:20,
        fontWeight:600
    },
    select: {
        padding: 12,
        display: "flex",
        flexDirection: "column",
        justifyContent: "left"

    },
    org: {
        border: '1px solid lightgrey',
        borderRadius: 4,
        marginRight: 12,
        // height: 40,
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        padding: "9px 0px",
    },
    region: {
        border: '1px solid lightgrey',
        height: 40,
        borderRadius: 4,
        marginBottom: 12,
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
    },
    flex: {
        display: "flex",
        justifyContent: "flex-end",
        maxWidth: 1200,
        marginTop: 20,
        marginBottom: 12
    }
}));
