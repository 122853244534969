import React, {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";

export const RedirectToLoginWithOriginalPath = ({}) => {

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const pathnameComponents = location.pathname.split('/');
        if (JSON.parse(localStorage.getItem('key')) === null) {
            return history.push('/login', {
                redirectOriginPathname: location.pathname,
            });
        }
        if (JSON.parse(localStorage.getItem('key'))?.accessToken) {
            if (pathnameComponents[1] === 'redirect' && pathnameComponents[2] === 'order') {
                history.push(location.pathname.slice(9))
            }
            if (pathnameComponents[1] === 'redirect' && pathnameComponents[2] === 'vieworders') {
                history.push('/orders')
            }
        }
    }, [])

    return <div/>
}