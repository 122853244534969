import React, {useEffect, useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {Table} from "../../../../common/table/table";
import PatientsHeader from "../../../patients/view-all-patients/patientsHeader/patientsHeader";
import {endpoints} from "../../../../constants/endpoints";
import {routes} from "../../../../constants/routes/routes";
import {useDispatch, useSelector} from "react-redux";
import {logout, setCriteriaHospiceUsers} from "../../../../redux/actions";
import {TabSearchBar} from "../../../../common/search/tabSearch/TabSearch";
import {useHistory} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import {
    searchHospiceUsers,
    setHospiceUserOrganizations,
    setRoles
} from "../../../../common/search/tabSearch/TabSearchHelper";
import {tableColumnTitles} from "../../../../constants/names/columnTitles";
import {componentTitles} from "../../../../constants/names/titles";
import {buttonText} from "../../../../constants/alert/alertButtonTexts";
import {tooltips} from "../../../../constants/inputs/tooltips";
import {checkTokenAvailability} from "../../../../common/utils/utils";
import {userTypes} from "../../../../constants/types/userTypes";
import ExportForm from "../../../../common/exportForm/exportForm";

export default function HospiceUsers() {
    let history = useHistory();
    const [hospiceUsers, setHospiceUsers] = useState([]);
    const [stringStatus, setStringStatus] = useState('');
    const dispatch = useDispatch();
    const [exportValues, setExportValues] = useState([]);
    const tableRef = useRef();
    const searchValues = useSelector(state => state.SearchCriteriaHospiceUsers);
    const [hospiceUsersFiltersValue, setHospiceUsersFiltersValue] = useState({
        input: '',
        criteria: ['All'],
        role: undefined,
        organization:  undefined,
        isActiveUser: true,
    });

    const [roleOptions, setRoleOptions] = useState([]);
    const [orgOptions, setOrgOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const filterOptions = [
        {name: "role", placeholder: "Role", options: roleOptions, value: hospiceUsersFiltersValue.role, isSelect: true},
        {
            name: "organization",
            placeholder: "Organization",
            options: orgOptions,
            value: hospiceUsersFiltersValue.organization,
            isSelect: true
        },
        {
            name: "isActiveUser",
            value: hospiceUsersFiltersValue.isActiveUser,
            positive: "Active Users",
            negative: "Inactive Users"
        },
    ];

    const handleDeleteCriteria = (chip) => {
        setHospiceUsersFiltersValue({
            ...hospiceUsersFiltersValue,
            criteria: hospiceUsersFiltersValue.criteria.filter(c => chip !== c)
        });
    };

    function handleFilterUpdate(event) {
        setHospiceUsersFiltersValue({...hospiceUsersFiltersValue, [event.target.name]: event.target.value})
    }

    const handleInputKeyup = (event) => {
        if (event.keyCode === 13 && hospiceUsersFiltersValue.input) {
            if (!hospiceUsersFiltersValue.criteria.includes(hospiceUsersFiltersValue.input)) {
                (async () => {
                    const availableToken = await checkTokenAvailability();
                    if (!availableToken) {
                        dispatch(logout());
                        history.push('/login');
                    }
                })();
                if (hospiceUsersFiltersValue.criteria[0] === ('All')) {
                    setHospiceUsersFiltersValue({
                        ...hospiceUsersFiltersValue,
                        criteria: [hospiceUsersFiltersValue.input],
                        input: ''
                    });
                    searchHospiceUsers(hospiceUsersFiltersValue, setHospiceUsers, setIsLoading, userTypes.hospice);
                } else {
                    setHospiceUsersFiltersValue({
                        ...hospiceUsersFiltersValue,
                        criteria: [...hospiceUsersFiltersValue.criteria, hospiceUsersFiltersValue.input],
                        input: ''
                    });
                    searchHospiceUsers(hospiceUsersFiltersValue, setHospiceUsers, setIsLoading, userTypes.hospice);
                }
            }
        }
    };
    const handleInputChange = (value) => {
        setHospiceUsersFiltersValue({...hospiceUsersFiltersValue, input: value})
    };

    const clearFilters = () => {
        setHospiceUsersFiltersValue({
            input: '',
            criteria: ['All'],
            role: undefined,
            organization:  undefined,
            isActiveUser: true,
        });
        dispatch(setCriteriaHospiceUsers(hospiceUsersFiltersValue));
    };

    const getExportValues = () => {
        if (tableRef?.current?.state?.data !== undefined) {
            let final = [];
            tableRef.current.state.data.map(user => {
                let newArr = [];
                newArr.push(user.first_name + " " + user.last_name);
                let permissions = '';
                user.permissions.map(permission => {
                    permissions += permission + "\n";
                })
                newArr.push(permissions);
                final.push(newArr)
            });
            return final;
        }
    };

    const getHospiceUsers = () => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getHospiceUsers + userTypes.hospice, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data === undefined) {
                    setHospiceUsers([])
                } else {
                    response.data.map(user => {
                        let search = [];
                        search.push(user.first_name);
                        search.push(user.last_name);
                        search.push(user.user_id);
                        search.push(user.email);
                        search.push(user.username);
                        user.search = search;
                        if ( user.status === true){
                            user.stringStatus = 'true'
                        } else if( user.status === false) {
                            user.stringStatus = 'false'
                        }
                    });
                    setHospiceUsers(response.data)
                }
            })
            .catch(err => console.log('error'))
    };
    useEffect(() => {
        (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        setRoles(setRoleOptions, endpoints.hospice_roles, userTypes.hospice);
        // searchHospiceUsers(hospiceUsersFiltersValue, setHospiceUsers, setIsLoading);
        if (hospiceUsers.length === 0) {
            getHospiceUsers()
        }
        setHospiceUserOrganizations(setOrgOptions);
    }, []);
    const columns = [
        {
            title: tableColumnTitles.edit, field: 'user_id', width: 80, sorting: false,
            render: rowData => <div>
                <Tooltip placement="top" title={tooltips.editUser}>
                    <IconButton
                        onClick={() => {
                            history.push({
                                pathname: `${routes.hospice_user_edit}${rowData.user_id}`,
                                state: {
                                    userType: userTypes.hospice,
                                    id: rowData.user_id,
                                }
                            })
                        }}>
                        <EditIcon/>
                    </IconButton>
                </Tooltip>
            </div>
        },
        {
            title: 'User ID', field: 'user_id', width: 110, defaultSort:'desc'
        },
        {
            title: tableColumnTitles.name, field: 'first_name', width: 200,
            render: rowData => <div> {rowData.first_name + ' ' + rowData.last_name} </div>
        },
        {
            title: tableColumnTitles.username, field: 'username', width: 200,
            render: rowData => <div> {rowData.username} </div>
        },
        {
            title: tableColumnTitles.userGroup, field: 'name', width: 200,
            render: rowData => <div> {rowData.name} </div>
        },
        {title: tableColumnTitles.emailAddress, field: 'email', width: 200},
        {title: tableColumnTitles.emailAddress, field: 'hospice_name',hidden:true, defaultFilter:hospiceUsersFiltersValue.organization},
        {title: tableColumnTitles.emailAddress, field: 'search',hidden:true, defaultFilter:hospiceUsersFiltersValue.input},
        {title: tableColumnTitles.emailAddress, field: 'stringStatus',hidden:true, defaultFilter:stringStatus},
        {title: tableColumnTitles.emailAddress, field: 'name',hidden:true, defaultFilter:hospiceUsersFiltersValue.role},
        {title: tableColumnTitles.phoneNumber, field: 'phone', width: 160},
        {
            title: tableColumnTitles.status, field: 'status', width: 140,
            render: rowData => <div style={{marginLeft: '-40px', textAlign: 'center'}}>
                {rowData.status ? (
                    <span className={'active_status'}> Active </span>
                ) : (
                    <span className={'inactive_status'}> Inactive </span>
                )}
            </div>
        },
        {
            title: tableColumnTitles.hospiceOrganization, field: 'organization_name', width: 340,
            render: rowData => <div>{rowData.hospice_name && (
                <div className={'organization_row'}>
                    {rowData.hospice_name}
                </div>
            )}
            </div>
        },
    ];

    const headers = [
        {text: "User Name", style: 'tableHeader'},
        {text: "Permissions", style: 'tableHeader'},
    ];
    const excelHeaders = [
        "User Name",
        "Permission",
    ];

    useEffect(() => {
        if (hospiceUsersFiltersValue.isActiveUser === true) {
            setStringStatus('true')
        } else if (hospiceUsersFiltersValue.isActiveUser === false) {
            setStringStatus('false')
        }
    }, [hospiceUsersFiltersValue.isActiveUser]);

    return (
        <React.Fragment>
            <Grid container
                  direction="column"
                  justifyContent="space-around"
                  style={{paddingLeft:16, paddingRight:16}}>
                <Grid item xs>
                    <PatientsHeader buttonText={buttonText.hospiceUser}
                                    path={'/hospiceuser/new'}
                                    title={componentTitles.hospiceUsers}
                    />
                </Grid>
                <Grid item xs>
                    <TabSearchBar
                        filtersValue={hospiceUsersFiltersValue}
                        handleDeleteCriteria={handleDeleteCriteria}
                        handleInputKeyup={handleInputKeyup}
                        handleInputChange={handleInputChange}
                        clearFilters={clearFilters}
                        filterOptions={filterOptions}
                        handleFilterUpdate={handleFilterUpdate}
                    />
                </Grid>
                <Grid container
                      direction="column"
                      justify="flex-end"
                      justifyContent="space-around"
                      alignItems="flex-end"
                      style={{paddingBottom:16}}>
                    <Grid item xs>
                        <ExportForm
                            landscape={true}
                            fileName='Hospice User Permission'
                            exportValues={exportValues}
                            getExportValues={getExportValues}
                            showPrint={false}
                            headers={headers}
                            excelHeaders={excelHeaders}
                        />
                    </Grid>
                </Grid>
                <Table tableRef={tableRef} columns={columns} items={hospiceUsers} isLoading={isLoading}/>
            </Grid>
        </React.Fragment>
    );
}
