import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, useHistory} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PlusSign from "../../assets/Icon_material-add-circle-outline.svg";
import ListSign from "../../assets/Icon_material-format-list-bulleted.svg"
import PatientSign from "../../assets/patient.svg";
import OrderSign from "../../assets/order.svg";
import SupportSign from "../../assets/support.svg";
import PhoneSign from "../../assets/icon_phone.svg";
import EmailSign from "../../assets/e-mail.svg";
import HelpSign from "../../assets/help.svg";
import {dashboardStyle} from "./dashboard.style";
import jwt from "jwt-decode";
import {checkPermissions} from "../../common/permissions/checkPermissions";
import {permissions} from "../../constants/permissions/permissions";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {checkTokenAvailability, formatNumber, getTokenInformation} from "../../common/utils/utils";
import {logout} from "../../redux/actions";
import {endpoints} from "../../constants/endpoints";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import {Card, CardActions, CardHeader, DialogActions, DialogContent, ListItem} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import OrderIcon from "../../assets/order_icon.svg";
import PatientsIcon from "../../assets/patient_icon.svg";
import OrderApprovalIcon from "../../assets/order_priority_red.svg";
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {calculateTime} from "../../common/newNotificationCenter/notificationTabs/notificationTabsHelper";
import {tooltips} from "../../constants/inputs/tooltips";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {notifySnack} from "../../common/notifications/apiStatusNotification";
import {snackbarMessages} from "../../constants/alert/alertMessages";
import {useSnackbar} from "notistack";
import {orderTypes, utilsTokenIdentifiers} from "../../constants/inputs/values";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function Dashboard(props) {
    const [showNotification, setShowNotification] = useState(props.location.state ? props.location.state.showNotification : false);
    const [notificationList, setNotificationList] = useState([]);
    const [approvalOrdersList, setApprovalOrdersList] = useState([]);
    const [requiringAttentionOrdersList, setRequiringAttentionOrdersList] = useState([]);
    const [dismiss, setDismiss] = useState(false);
    const [open, setOpen] = useState(false);
    const [openApprove, setOpenApprove] = useState(false);
    const [openReject, setOpenReject] = useState(false);
    const [boolean, setBoolean] = useState(true);
    const history = useHistory();
    const classes = useStyles();
    const [username, setUsername] = useState("");
    const [retrievedOncall, setRetrievedOncall] = useState(false);
    const [items, setItems] = useState([]);
    const dispatch = useDispatch();
    let decodedToken;
    const isLogged = useSelector(state => state.isLogged);
    const {enqueueSnackbar} = useSnackbar();

    const handleCloseApprove = () => {
        setOpenApprove(false);
    };

    const handleCloseReject = () => {
        setOpenReject(false);
    };
    const getUserRecentActivity = async (array) => {
        let obj = {
            add: null,
            xl: 3, lg: 4, md: 6, sm: 12, xs: 12,
            title: 'Recent Activity',
        };
        let arr = [];
        const token = JSON.parse(localStorage.getItem('key'));
        if (token) {
            const {user_id} = jwt(token.accessToken).payload;
            const recentOrdersPromise = await fetch(`${endpoints.getUserLastOrders + user_id}`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                    },
                }
            );
            const recentNotesPromise = await fetch(`${endpoints.getUserLastNotes + user_id}`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                    },
                }
            );
            const recentPatientsCreatedPromise = await fetch(`${endpoints.getUserLastPatientsCreated + user_id}`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                    },
                }
            );
            if (checkPermissions([permissions.read_admin])) {
                const recentIncidentsPromise = await fetch(`${endpoints.viewIncidentLogs}`, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                    },
                });
                const recentIncidentsData = await recentIncidentsPromise.json();
                if (recentIncidentsData.status === "success") {
                    obj["recentIncidents"] = [...recentIncidentsData.data];
                    arr = [...arr, ...recentIncidentsData.data];
                }
            }
            const recentOrdersData = await recentOrdersPromise.json();
            const recentNotesData = await recentNotesPromise.json();
            const recentPatientsCreatedData = await recentPatientsCreatedPromise.json();
            if (recentOrdersData.status === "success") {
                obj["recentOrders"] = recentOrdersData.data;
                arr = [...arr, ...recentOrdersData.data];
            }
            if (recentNotesData.status === "success") {
                obj["recentNotes"] = recentNotesData.data;
                arr = [...arr, ...recentNotesData.data];
            }
            if (recentPatientsCreatedData.status === "success") {
                obj["recentPatients"] = recentPatientsCreatedData.data;
                arr = [...arr, ...recentPatientsCreatedData.data];
            }
            obj["data"] = arr.sort(function (a, b) {
                return new Date(b.created_date ? b.created_date : b.incident_date) - new Date(a.created_date ? a.created_date : a.incident_date);
            });

            if (!(obj.recentPatients.length === 0 && obj.recentNotes.length === 0 && obj.recentOrders.length === 0)) {
                array.push(obj);
            }
        }
    }
    const getUserApprovalOrders = async (array) => {
        const token = JSON.parse(localStorage.getItem('key'));
        if (token) {
            const {hospice_organization_id, permissions} = getTokenInformation(history, utilsTokenIdentifiers.payload)
            if (hospice_organization_id !== null && permissions.some((el => el === 'orders_approve'))) {
                let obj = {
                    add: null,
                    xl: 3, lg: 4, md: 6, sm: 12, xs: 12,
                    title: 'Approvals Required',
                };
                let promise = await fetch(endpoints.getApprovalOrdersNotifications, {
                    method: "get",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                    },
                });
                let response = await promise.json();
                if (response.data.length > 0) {
                    setApprovalOrdersList(response.data);
                    setOpen(true);
                    array.push(obj);
                }
            }
        }
    }

    const getRequiringAttentionOrders = async (array) => {
        const token = JSON.parse(localStorage.getItem('key'));
        if (token) {
            const {type, permissions} = getTokenInformation(history, utilsTokenIdentifiers.payload)
            if (type === 'DME' && permissions.some((el => el === 'orders_orders requiring attention'))) {
                let obj = {
                    add: null,
                    xl: 3, lg: 4, md: 6, sm: 12, xs: 12,
                    title: 'Orders Requiring Attention',
                };
                let promise = await fetch(endpoints.orderRequiringAttention, {
                    method: "get",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                    },
                });
                let response = await promise.json();
                if (response.data.length > 0) {
                    setRequiringAttentionOrdersList(response.data);
                    setOpen(true);
                    array.push(obj);
                }
            }
        }
    }

    async function getUserOptions() {
        await (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        let arr = [];
        const token = JSON.parse(localStorage.getItem("key"));
        if (token) {
            const {type} = getTokenInformation(history, utilsTokenIdentifiers.payload);

            checkPermissions([permissions.read_patients]) && arr.push({
                xl: 3, lg: 4, md: 6, sm: 6, xs: 12,
                title: 'Patients',
                add: "Add New Patient",
                view: "View All Patients",
                tile: PatientSign,
                firstSign: PlusSign,
                addRoute: "/patients/new",
                viewRoute: "/patients/all"
            });
            checkPermissions([permissions.read_orders]) && arr.push({
                xl: 3, lg: 4, md: 6, sm: 6, xs: 12,
                title: 'Orders',
                add: "Add New Order",
                view: "View All Orders",
                tile: OrderSign,
                //TODO: make recent orders to actually display recent orders
                // recent: "Recent Orders",
                firstSign: PlusSign,
                addRoute: "/patients/all",
                viewRoute: "/orders"
            });
            checkPermissions([permissions.read_patients, permissions.read_orders]) && await getUserRecentActivity(arr);
            type === 'Portal' && arr.push({
                xl: 3, lg: 4, md: 6, sm: 6, xs: 12,
                title: 'Patient Portal',
                add: null,
                view: "View Patient Profile",
                tile: PatientSign,
                firstSign: PlusSign,
                addRoute: "/patients/new",
                viewRoute: "/portal/profile"
            });
            await getUserApprovalOrders(arr);
            await getRequiringAttentionOrders(arr);
        }
        return arr;
    }

    const getOnCallData = async () => {
        const {
            hospice_organization_id,
            region_id,
            dme_organization_id
        } = getTokenInformation(history, utilsTokenIdentifiers.payload);
        let payload = {};
        if (hospice_organization_id) {
            payload["hospiceId"] = hospice_organization_id;
        }
        if (region_id) {
            payload["regionId"] = region_id;
        }
        if (dme_organization_id) {
            payload["dmeId"] = dme_organization_id;
        }
        await (async () => {
            const availableToken = await checkTokenAvailability();
            if (!availableToken) {
                dispatch(logout());
                history.push('/login');
            }
        })();
        const promise = await fetch(`${endpoints.retrieveOnCallInformation}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                },
                body: JSON.stringify(payload)
            }
        );
        const response = await promise.json();
        let clone = await getUserOptions();
        let newItem;
        if (response.status === 'success') {
            for (const item of response.data) {
                newItem = {
                    xl: 3, lg: 4, md: 6, sm: 12, xs: 12,
                    title: `${item.dme_name} - ${item.region_name}`,
                    add: item.region_phone !== null && item.region_phone !== "" ? formatNumber(item.region_phone, "parentheses") : "-",
                    tile: SupportSign,
                    phone: true,
                    firstSign: PhoneSign,
                    email: {
                        address: item.email !== null && item.email !== "" ? item.email : "-",
                        name: `${item.first_name !== null && item.first_name !== "" ? item.first_name : "-"} ${item.last_name !== null && item.last_name !== "" ? item.last_name : "-"}`
                    },
                    regionHours: `${item.on_call_start_hour !== null && item.on_call_start_hour !== "" && item.on_call_start_hour !== "Invalid date CST" ? moment(item.on_call_start_hour).format("LT") : "-"} - ${item.on_call_end_hour !== null && item.on_call_end_hour !== "" && item.on_call_end_hour !== "Invalid date CST" ? moment(item.on_call_end_hour).format("LT") : "-"} CST`,
                    ahPhone: item.on_call_phone !== null && item.on_call_phone !== "" ? formatNumber(item.on_call_phone, "parentheses") : "-"
                }
                clone.push(newItem);
            }
            setItems(clone);
        } else {
            newItem = {
                xl: 3, lg: 4, md: 6, sm: 6, xs: 12,
                title: `No Region Support`,
                add: "-",
                tile: SupportSign,
                phone: true,
                firstSign: PhoneSign,
                email: {
                    address: "-",
                    name: "-"
                },
                regionHours: "-",
                ahPhone: "-"
            }
            clone.push(newItem);
            setItems(clone);
        }
        setRetrievedOncall(true);
    }

    function getNotifications() {
        const token = JSON.parse(localStorage.getItem("key"));
        if(token) {
            let {user_id} = getTokenInformation(history, utilsTokenIdentifiers.payload)
            fetch(`${endpoints.getUserSystemNotifications}/${user_id}/active`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let arr = [];
                    response.data.map(notif => {
                        if (notif.is_read !== true) {
                            arr.push(notif)
                        }
                    });
                    setNotificationList(arr)
                })
                .catch(err => console.log('error', err))
        }
    }

    function updateNotification(type, notificationId) {
        let {user_id} = getTokenInformation(history, utilsTokenIdentifiers.userId)
        fetch(endpoints.updateUserSystemNotification, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            },
            body: JSON.stringify({
                notificationId: notificationId,
                userId: user_id,
                status: type,
            })
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setNotificationList(response.data)
            })
            .catch(err => console.log('error', err))
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('key'));
        if (token !== null) {
            setUsername(decodedToken = jwt(token.accessToken).payload.first_name);
            if (retrievedOncall === false) {
                getOnCallData()
                setRetrievedOncall(true);
            }
            if (props.location.state?.showNotification && boolean === true) {
                setBoolean(false);
                getNotifications()
            }
        }
        let wrapperElement = document.getElementById('children');
        const executeScroll = () => wrapperElement && wrapperElement.scrollIntoView();
        executeScroll();
    }, []);

    const getListItem = (item) => {
        switch (item.type) {
            case "orders":
                return <Card className={classes.listItem}>
                    <CardHeader
                        avatar={
                            <img
                                src={OrderIcon}
                                alt="logo"
                                width='25'
                            />
                        }
                        action={
                            <IconButton aria-label="settings"
                                        onClick={() => {
                                            item.delivery_order_id && history.push(`/order/delivery/${item.delivery_order_id}`);
                                            item.pickup_order_id && history.push(`/order/pickup/${item.pickup_order_id}`);
                                            item.exchange_order_id && history.push(`/order/exchange/${item.exchange_order_id}`);
                                        }}
                            >
                                <ArrowForwardOutlinedIcon/>
                            </IconButton>
                        }
                        title={
                            <Typography variant={"subtitle1"} className={classes.listItem}
                                        onClick={() => {
                                            item.delivery_order_id && history.push(`/order/delivery/${item.delivery_order_id}`);
                                            item.pickup_order_id && history.push(`/order/pickup/${item.pickup_order_id}`);
                                            item.exchange_order_id && history.push(`/order/exchange/${item.exchange_order_id}`);
                                        }}
                                        style={{fontWeight: 600}}
                            >
                                Order Created <span style={{
                                color: "#4F86A0",
                                fontWeight: 600
                            }}>{item.delivery_order_id && `D100${item.delivery_order_id}`} {item.pickup_order_id && `P200${item.pickup_order_id}`} {item.exchange_order_id && `S300${item.exchange_order_id}`}</span> for {item.first_name} {item.last_name}
                            </Typography>
                        }
                        subheader={calculateTime(item.created_date)}
                    />
                </Card>
            case "notes":
                return <Card className={classes.listItem}>
                    <CardHeader
                        avatar={
                            <MessageOutlinedIcon style={{color: "#4F86A0"}}/>
                        }
                        action={
                            <IconButton aria-label="settings"
                                        onClick={() => {
                                            item.delivery_order_id && history.push(`/order/delivery/${item.delivery_order_id}`);
                                            item.pickup_order_id && history.push(`/order/pickup/${item.pickup_order_id}`);
                                            item.exchange_order_id && history.push(`/order/exchange/${item.exchange_order_id}`);
                                        }}>
                                <ArrowForwardOutlinedIcon/>
                            </IconButton>
                        }
                        title={
                            <Typography variant={"subtitle1"} className={classes.listItem}
                                        onClick={() => {
                                            item.delivery_order_id && history.push(`/order/delivery/${item.delivery_order_id}`);
                                            item.pickup_order_id && history.push(`/order/pickup/${item.pickup_order_id}`);
                                            item.exchange_order_id && history.push(`/order/exchange/${item.exchange_order_id}`);
                                        }}
                            >
                                Added a Comment to Order <span
                                style={{color: "#4F86A0"}}>{item.delivery_order_id && `D100${item.delivery_order_id}`} {item.pickup_order_id && `P200${item.pickup_order_id}`} {item.exchange_order_id && `S300${item.exchange_order_id}`}.</span>
                            </Typography>
                        }
                        subheader={calculateTime(item.created_date)}
                    />
                </Card>
            case "patients":
                return <Card className={classes.listItem}>
                    <CardHeader
                        avatar={
                            <img
                                src={PatientsIcon}
                                alt="logo"
                                width='25'
                            />
                        }
                        action={
                            <IconButton aria-label="settings"
                                        onClick={() => {
                                            history.push(`/patients/patientProfile/${item.patient_id}`)
                                        }}>
                                <ArrowForwardOutlinedIcon/>
                            </IconButton>
                        }
                        title={
                            <Typography variant={"subtitle1"} className={classes.listItem}
                                        onClick={() => {
                                            history.push(`/patients/patientProfile/${item.patient_id}`)
                                        }}>
                                Created New Patient <span
                                style={{color: "#4F86A0"}}> {item.first_name} {item.last_name} </span>
                            </Typography>
                        }
                        subheader={calculateTime(item.created_date)}
                    />
                </Card>
            default:
                return <Card className={classes.listItem}>
                    <CardHeader
                        avatar={
                            <AssessmentIcon style={{color: "#4F86A0"}}/>
                        }
                        action={
                            <IconButton aria-label="settings"
                                        onClick={() => {
                                            history.push(`/incident/logs`)
                                        }}>
                                <ArrowForwardOutlinedIcon/>
                            </IconButton>
                        }
                        title={
                            <Typography variant={"subtitle1"} className={classes.listItem}
                                        onClick={() => {
                                            history.push(`/incident/logs`)
                                        }}>
                                Created New Incident Report with <span
                                style={{color: "#4F86A0"}}> #{item.incident_id}</span>
                            </Typography>
                        }
                        subheader={calculateTime(item.created_date ? item.created_date : item.incident_date)}
                    />
                </Card>
        }
    }
    const changeOrderStatus = (newStatus, approve, orderId, type) => {
        let endpoint;
        let status;
        if (type === orderTypes.delivery) {
            endpoint = endpoints.updateDeliveryOrderStatus;
            status = {
                delivery_order_id: orderId,
                status: newStatus,
            };
        }
        if (type === orderTypes.exchange) {
            status = {
                exchange_order_id: orderId,
                status: newStatus,
            };
            endpoint = endpoints.updateExchangeOrderStatus;
        }

        fetch(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            },
            body: JSON.stringify(status)
        })
            .then(response => {
                if (response.status === 200) {
                    const {first_name, last_name} = getTokenInformation(history, utilsTokenIdentifiers.payload);
                    if (approve === true) {
                        addHistory(orderId, type, `Status Changed: ${first_name} ${last_name} approved order on ${moment(new Date()).format("LLL")}`);
                    } else if (approve === false) {
                        addHistory(orderId, type, `Status Changed: ${first_name} ${last_name} rejected order on ${moment(new Date()).format("LLL")}`);
                    }
                    notifySnack(enqueueSnackbar, response.status, snackbarMessages.orderStatusChanged, true);
                    history.push(`/refresh/dashboard`)
                }
            })
            .catch(err => {
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            });
    };
    const addHistory = async (order_id, type, description) => {
        let endpoint;
        let body;
        if (type === orderTypes.delivery) {
            body = {
                history: [{
                    description: description,
                }],
                deliveryOrderId: order_id
            };
            endpoint = endpoints.addDeliveryOrderHistory;
        }
        if (type === orderTypes.exchange) {
            body = {
                history: [{
                    description: description,
                }],
                exchangeOrderId: order_id
            };
            endpoint = endpoints.addExchangeOrderHistory;
        }
        await fetch(endpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + getTokenInformation(history, utilsTokenIdentifiers.accessToken)
            },
            body: JSON.stringify(body)
        });
    };
    const getListOrderApprovals = (order) => {
        return <Card className={classes.listItem}>
            <CardHeader
                avatar={
                    <img
                        src={OrderApprovalIcon}
                        alt="logo"
                        width='25'
                    />
                }
                title={
                    <Typography variant={"subtitle1"} className={classes.listItem}>
                        Please use the following actions for order <span
                        style={{color: "#4F86A0"}}> {order.order_id} </span>
                    </Typography>
                }
            />
            <CardActions>
                <Button variant='contained' color={"primary"}
                        style={{
                            height: 40,
                            marginLeft: 12,
                            backgroundColor: '#117C70',
                        }}
                        onClick={() => {
                            setOpenApprove(true);
                        }}>Approve</Button>
                <div>
                    <Dialog
                        open={openApprove}
                        onClose={handleCloseApprove}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure that you want to Approve order {order.order_id}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseApprove}
                                    variant={"contained"}
                                    color={'primary'}
                            >No, return</Button>
                            <Button style={{
                                height: 40,
                                marginLeft: 12,
                                backgroundColor: '#117C70',
                            }}
                                    onClick={() => {
                                        if (order.order_id.substring(0, 4) === "D100") {
                                            changeOrderStatus('Open', true, order.order_id.substring(4), orderTypes.delivery);
                                        }
                                        if (order.order_id.substring(0, 4) === "S300") {
                                            changeOrderStatus('Open', true, order.order_id.substring(4), orderTypes.exchange);
                                        }
                                    }
                                    }>
                                Yes, approve
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Button variant={"contained"}
                        color={'primary'}
                        style={{
                            height: 40,
                            marginLeft: 12,
                            backgroundColor: '#CD1D32',
                        }}
                        onClick={() => {
                            setOpenReject(true);
                        }}>Reject</Button>
                <div>
                    <Dialog
                        open={openReject}
                        onClose={handleCloseReject}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you sure that you want to Reject order {order.order_id}?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseReject}
                                    variant={"contained"}
                                    color={'primary'}
                            >No, return</Button>
                            <Button style={{
                                height: 40,
                                marginLeft: 12,
                                backgroundColor: '#CD1D32',
                            }}
                                    onClick={() => {
                                        if (order.order_id.substring(0, 4) === "D100") {
                                            changeOrderStatus('Rejected', false, order.order_id.substring(4), orderTypes.delivery);
                                        }
                                        if (order.order_id.substring(0, 4) === "S300") {
                                            changeOrderStatus('Rejected', false, order.order_id.substring(4), orderTypes.exchange);
                                        }
                                    }
                                    }>
                                Yes, reject
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <Button variant={"contained"}
                        color={'primary'}
                        onClick={() => {
                            if (order.order_id.substring(0, 4) === "D100") {
                                history.push(`/order/delivery/${order.order_id.substring(4)}`)
                            }
                            if (order.order_id.substring(0, 4) === "S300") {
                                history.push(`/order/exchange/${order.order_id.substring(4)}`)
                            }
                        }}>Review</Button>
            </CardActions>
        </Card>
    }

    const getListRequiringOrderAttention= (order) => {
        return <Card className={classes.listItem}>
            <CardHeader
                avatar={
                    <img
                        src={OrderApprovalIcon}
                        alt="logo"
                        width='25'
                    />
                }
                title={
                    <Typography variant={"subtitle1"} className={classes.listItem}>
                        Order <span style={{color: "#4F86A0"}}> {order.order_id} </span> has
                        <span style={{color: "#4F86A0"}}> {order.order_status} </span> status and items with no assets
                    </Typography>
                }
            />
            <CardActions>
              <Button variant={"contained"}
                        color={'primary'}
                        onClick={() => {
                            if (order.order_id.substring(0, 4) === "D100") {
                                history.push(`/order/delivery/${order.order_id.substring(4)}`)
                            }
                            if (order.order_id.substring(0, 4) === "S300") {
                                history.push(`/order/exchange/${order.order_id.substring(4)}`)
                            }
                            if (order.order_id.substring(0, 4) === "P200") {
                                history.push(`/order/pickup/${order.order_id.substring(4)}`)
                            }
                        }}>Review</Button>
            </CardActions>
        </Card>
    }
    return (
        <React.Fragment>
            {isLogged ? (
                <Grid container className={classes.root}>
                    <Grid item xs={12} className={classes.flexStart}>
                        <Typography className={classes.h1}>
                            Welcome {username}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{maxWidth: '100vw'}}>
                        <Grid container justify={"flex-start"}>
                            {React.Children.toArray(
                                items.map((value, index) => (
                                    <Grid item lg={value.lg} md={value.md} sm={value.sm} xs={value.xs}
                                          style={{padding: 8}}>
                                        <div
                                            className={classes.box}
                                            style={{borderBottom: index % 2 === 0 ? '4px solid #4F86A0' : '4px solid #A3C7D2'}}>
                                            <div
                                                className={classes.background}
                                                style={{
                                                    background: `transparent url(${value.tile}) 0% 0% no-repeat padding-box`,
                                                    backgroundPosition: 'right bottom',
                                                    height: '100%'
                                                }}>
                                                {value.title.length > 48 ?
                                                    <Tooltip title={value.title} placement="top">
                                                        <Typography className={classes.tooltipTitle}
                                                                    style={{fontSize: value.title.length > 20 ? 16 : 24}}>
                                                            {value.title}
                                                        </Typography>
                                                    </Tooltip> : <Typography className={classes.h3}
                                                                             style={{fontSize: value.title.length > 20 ? 16 : 24}}>
                                                        {value.title}
                                                    </Typography>
                                                }
                                                {value.add !== null &&
                                                    <div className={classes.addRoute} style={{marginTop: 24}}
                                                         onClick={() => value.addRoute && history.push(value.addRoute)}>
                                                        <img
                                                            src={value.firstSign}
                                                            alt="logo"
                                                            width='25'
                                                            style={{cursor: value.addRoute ? 'pointer' : 'default'}}
                                                        />
                                                        <Typography
                                                            className={classes.h4}
                                                            style={{cursor: value.addRoute ? 'pointer' : 'default'}}>
                                                            {value.phone ? `Contact warehouse - ${value.add}` : value.add}
                                                        </Typography>
                                                        {value.phone && (
                                                            <Tooltip title={tooltips.dme_support} arrow
                                                                     placement="top-end">
                                                                <InfoOutlinedIcon
                                                                    style={{marginLeft: '6px', color: '#4F879F'}}/>
                                                            </Tooltip>
                                                        )}
                                                    </div>}
                                                {value.phone && (
                                                    <div className={classes.phone}>
                                                        <Typography variant={"h3"} className={classes.phoneText}>
                                                            {value?.regionHours ? value?.regionHours : "8:30 am - 5:30 pm CST"}
                                                        </Typography>
                                                    </div>
                                                )}
                                                {value.title === "Recent Activity" && value.data.length > 0 && (
                                                    <div style={{
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        maxHeight: 150,
                                                    }}>
                                                        <List component="nav"
                                                              style={{height: 200, width: "100%", overflowY: "scroll"}}>
                                                            {React.Children.toArray(
                                                                value.data.map((item, index) => {
                                                                    return <ListItem id={index}>
                                                                        {getListItem(item)}
                                                                    </ListItem>
                                                                })
                                                            )}
                                                        </List>
                                                    </div>
                                                )}
                                                {approvalOrdersList.length > 0 && value.title === "Approvals Required" && (
                                                    <div style={{
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        maxHeight: 150,
                                                    }}>
                                                        <List component="nav"
                                                              style={{height: 200, width: "100%", overflowY: "scroll"}}>
                                                            {React.Children.toArray(
                                                                approvalOrdersList.map((item) => {
                                                                    return <ListItem id={item.order_id}>
                                                                        {getListOrderApprovals(item)}
                                                                    </ListItem>
                                                                })
                                                            )}
                                                        </List>
                                                    </div>
                                                )}
                                                {requiringAttentionOrdersList.length > 0 && value.title === "Orders Requiring Attention" && (
                                                    <div style={{
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        maxHeight: 150,
                                                    }}>
                                                        <List component="nav"
                                                              style={{height: 200, width: "100%", overflowY: "scroll"}}>
                                                            {React.Children.toArray(
                                                                requiringAttentionOrdersList.map((item) => {
                                                                    return <ListItem id={item.order_id}>
                                                                        {getListRequiringOrderAttention(item)}
                                                                    </ListItem>
                                                                })
                                                            )}
                                                        </List>
                                                    </div>
                                                )}
                                                {value.view && (
                                                    <div className={classes.view} style={{cursor: 'pointer'}}>
                                                        <img
                                                            src={ListSign}
                                                            alt="logo"
                                                            width='25'
                                                            className={classes.cursor}
                                                            onClick={() => value.viewRoute && history.push(value.viewRoute)}
                                                        />
                                                        <Typography
                                                            className={classes.h4}
                                                            onClick={() => value.viewRoute && history.push(value.viewRoute)}
                                                        >
                                                            {value.view}
                                                        </Typography>
                                                    </div>
                                                )}
                                                {value.email && (
                                                    <React.Fragment>
                                                        <div className={classes.supportEmail}
                                                             onClick={() => window.location = `mailto:${value.email.address}`}>
                                                            <img
                                                                src={EmailSign}
                                                                alt="logo"
                                                                width='25'
                                                                className={classes.cursor}
                                                            />
                                                            {value.email.name.length > 20 ?
                                                                <Tooltip title={value.email.name}>
                                                                    <Typography style={{
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                                className={classes.emailText}>{value.email.name}</Typography>
                                                                </Tooltip> : <Typography
                                                                    style={{
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                    className={classes.emailText}>
                                                                    {value.email.name}</Typography>
                                                            }
                                                            {value.email.address.length > 20 ?
                                                                <Tooltip title={value.email.address}>
                                                                    <Typography style={{
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                                className={classes.emailText}>{value.email.address}</Typography>
                                                                </Tooltip> : <Typography
                                                                    style={{
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        whiteSpace: "nowrap",
                                                                    }}
                                                                    className={classes.emailText}>
                                                                    {value.email.address}</Typography>
                                                            }
                                                        </div>
                                                        {value.ahPhone && <div
                                                            onClick={() => history.push('/support')}
                                                            className={classes.emailContainer}
                                                        >
                                                            <img
                                                                src={HelpSign}
                                                                alt="logo"
                                                                width='25'
                                                                className={classes.email}
                                                            />
                                                            <Typography className={classes.emailText}>
                                                                {"After Hours: " + value.ahPhone}
                                                            </Typography>
                                                            <Tooltip title={tooltips.dme_support} arrow
                                                                     placement="top-end">
                                                                <InfoOutlinedIcon
                                                                    style={{marginLeft: '6px', color: '#4F879F'}}/>
                                                            </Tooltip>
                                                        </div>}
                                                    </React.Fragment>
                                                )}
                                                {value.recent && (
                                                    <Typography className={classes.recent}>
                                                        {value.recent}
                                                    </Typography>
                                                )}
                                                {value.recent && (
                                                    <div
                                                        className={classes.recentContainer}
                                                        onClick={() => value.viewRoute && history.push(value.viewRoute)}
                                                    >
                                                        <Typography className={classes.recentItem}>
                                                            Delivery
                                                        </Typography>
                                                        <Typography className={classes.recentSpacing}>
                                                            Pickup
                                                        </Typography>
                                                        <Typography className={classes.recentSpacing}>
                                                            Exchange
                                                        </Typography>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    </Grid>
                    <Dialog open={showNotification && notificationList.length > 0} PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                        },
                    }} style={{":&focus": {outline: 0}, border: 'none', display: 'flex', justifyContent: 'center'}}>
                        <div style={{
                            margin: 0,
                            padding: 0,
                            paddingBottom: 0,
                            backgroundColor: '#7C7D7E',
                            maxWidth: 600
                        }}>
                            {notificationList.map(notification => {
                                return <React.Fragment>
                                    <Grid item xs={12} style={{
                                        display: 'flex',
                                        fontSize: 16,
                                        fontWeight: 'bold',
                                        paddingBottom: 12
                                    }}>
                                        <Grid container style={{
                                            borderRadius: 4,
                                            backgroundColor: notification.type === "Informational" ? "#E4EBF1" : notification.type === "Warning" ? "#F6F4DF" : notification.type === "Important" ? "#F5B8BF" : "none",
                                            border: notification.type === "Informational" ? "2px solid #7B99B5" : notification.type === "Warning" ? "2px solid #CEC129" : notification.type === "Important" ? "2px solid #CD1D32" : "none",
                                            paddingTop: 24,
                                            paddingLeft: 24,
                                            paddingRight: 12,
                                            paddingBottom: 12,
                                            textAlign: "left"
                                        }}>
                                            <Grid item xs={12} style={{fontSize: 24, paddingBottom: 12}}>
                                                {notification.title}
                                            </Grid>
                                            <Grid item xs={12} style={{paddingBottom: 24}}>
                                                <Typography
                                                    variant="body1"
                                                    style={{whiteSpace: 'pre-line'}}
                                                >
                                                    {notification.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Grid container
                                                      justify={notification.dismissible === true ? "space-between" : 'flex-end'}>

                                                    <Grid item style={{paddingRight: 12}}>
                                                        {notification.dismissible === true ? (
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={dismiss}
                                                                        onChange={(e) => {
                                                                            setDismiss(e.target.checked)
                                                                        }}
                                                                        name="checkedB"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label="Do not show anymore"
                                                            />

                                                        ) : (
                                                            <Button
                                                                variant={"outlined"}
                                                                color={'primary'}
                                                                onClick={() => {
                                                                    setShowNotification(false);
                                                                    updateNotification(true, notification.system_notification_id)
                                                                }}
                                                            >
                                                                I Have Read
                                                            </Button>
                                                        )}
                                                    </Grid>
                                                    <Grid item>
                                                        <Button variant={"contained"} color={'primary'}
                                                                onClick={() => {
                                                                    setShowNotification(false);
                                                                    if (dismiss) {
                                                                        updateNotification(true, notification.system_notification_id)
                                                                    } else {
                                                                        updateNotification(false, notification.system_notification_id)
                                                                    }
                                                                }}
                                                        >
                                                            {notification.dismissible === true ? "Acknowledge" : "Read Later"}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </React.Fragment>
                            })
                            }
                        </div>
                    </Dialog>
                    <Dialog open={open && approvalOrdersList.length > 0} className={classes.pendingOrderDialog}>
                        <div className={classes.pendingDialog}>
                            <React.Fragment>
                                <Grid item xs={12} className={classes.pendingItem}>
                                    <Grid container className={classes.pendingContainer}>
                                        <Grid item xs={12} className={classes.titlePendingOrders}>
                                            Attention Required
                                        </Grid>
                                        <Grid item xs={12} style={{paddingBottom: 24}}>
                                            <Typography
                                                variant="body1"
                                                style={{whiteSpace: 'pre-line'}}
                                            >
                                                You have Pending Orders that require approval.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container justify={"space-between"}>
                                                <Grid item>
                                                    <Button
                                                        variant={"outlined"}
                                                        color={'primary'}
                                                        onClick={() => {
                                                            setOpen(false);
                                                        }}
                                                    >
                                                        Dismiss
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button variant={"contained"}
                                                            color={'primary'}
                                                            onClick={() => {
                                                                setOpen(false);
                                                                //push to view all orders and populate only with the one in pending
                                                                localStorage.setItem('order-filters', JSON.stringify({
                                                                    status: 'Pending'
                                                                }));
                                                                history.push('/orders');
                                                            }}
                                                    >
                                                        Review Orders
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        </div>
                    </Dialog>
                </Grid>
            ) : <Redirect to='/login'/>}
        </React.Fragment>
    )
}
const useStyles = makeStyles((theme) => (dashboardStyle(theme)));
