import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {Dialog, InputLabel, NativeSelect, TextField} from "@material-ui/core";
import {makeStyles, withStyles} from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import CartIcon from "../../assets/shopping-cart.svg";
import TruckIcon from "../../assets/truck.svg";
import ToolIcon from "../../assets/tool.svg";
import FormControl from "@material-ui/core/FormControl";
import Select from '@material-ui/core/Select';
import { use100vh } from 'react-div-100vh'
import NewAddItemModal from "./newAddItemModal";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import jwt from "jwt-decode";
import moment from "moment";
import _ from "lodash";
import {endpoints} from "../../constants/endpoints";
import AddIcon from "@material-ui/icons/Add";
import {OrderItems} from "./orderItems/orderItems";
// import {OrderReview} from "./orderReview/orderReview";
import {notifySnack} from "../../common/notifications/apiStatusNotification";
import {snackbarMessages} from "../../constants/alert/alertMessages";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import {labels} from "../../constants/inputs/labels";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Typography from "@material-ui/core/Typography";
import {Table} from "./orderReview/reviewTable/table";
import FileUpload from "../orders/fileUploadOrders/FileIpload";
import {OrderNotes} from "./orderReview/orderNotes/orderNotes";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {orderReviewStyle} from "./orderReview/orderReview.style";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import {buttonText} from "../../constants/alert/alertButtonTexts";
import {isMobile} from "react-device-detect";

export default function NewCreateOrder({fullWidth = false, ...props}){
    const [open, setOpen] = useState(false);
    const [step, setStep] = useState(2);
    const classes = useStyles();
    const height = use100vh();
    const patientInfo = props.user;
    let history = useHistory();
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    //snackbar position
    const vertical = 'top';
    const horizontal = 'right';

    //modal hooks
    const [showCreateOrder, setShowCreateOrder] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [contractedProviders, setContractedProviders] = useState([]);
    const [dmeId, setDmeId] = useState("");
    const [contractedProvidersList, setContractedProvidersList] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [itemsList, setItemsList] = useState([]);
    const [filteredItemsList, setFilteredItemsList] = useState([]);
    const [activeStep, setActiveStep] = useState(0);
    const [patient_id, setPatientId] = useState(props.user.patient_id);
    const [order_id, setOrder_id] = useState('');
    const steps = ['Create Order', 'Review Order'];
    const [total, setTotal] = useState(0);
    let token = localStorage.getItem('key');
    let currentUser = JSON.parse(token);
    let user =  currentUser?.accessToken ? jwt(currentUser.accessToken) : null;
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [order, setOrder] = useState({
        status: "Open",
        patient_id: props.user.patient_id,
        search: '',
        preferred_date: moment().format("YYYY-MM-DD"),
        user_id: Number(user.payload.user_id),
        category: '',
        dme_organization_id: '',
        hospice_id: props.user.hospice_organization_id,
        priority_code: 'Routine',
        approval: false,
        // items: props.orderPackage === true ? props.additionalItems : [],
        items:[],
        notes: [],
        history: [{
            description: `Order created: ${user.payload.first_name} ${user.payload.last_name} created order on ${moment().format('LLL')}`,
            created_date: moment()
        }]
    });
    const [isUnsavedNote, setIsUnsavedNote] = useState(false);
    const [showError, setShowError] = useState(false);
    const [currentNote, setCurrentNote] = React.useState('');
    const [showWarning, setShowWarning] = useState(false);

    useEffect(()=> {
        setOrder({...order,
            patient_id: props.user.patient_id,
            hospice_id: props.user.hospice_organization_id,
            region_id: props.user.region_id
        });
        setPatientId(props.user.patient_id);
    }, [props.user.patient_id]);

    const handleInput = (event) => {
        const {name, value} = event.target;
        setOrder({...order, [name]: value});
    };

    useEffect(() => {
        let sum = 0;
        order.items.map(item => sum = sum + item.quantity);
        setTotal(sum);
    }, [order]);

    const handleShowCreateOrder = () => {
        setActiveStep(0);
        setShowCreateOrder(true);
    };
    const handleHideCreateOrder = (event, reason) => {
        setShowCreateOrder(false);
        setOrder({
            status: "Open",
            patient_id: props.user.patient_id,
            search: '',
            preferred_date: moment().format("YYYY-MM-DD"),
            user_id: user.payload ? Number(user.payload.user_id) : null,
            category: '',
            dme_organization_id: '',
            hospice_id: props.user.hospice_organization_id,
            priority_code: 'Routine',
            approval: false,
            items: [],
            notes: [],
            history: [{
                description: `Order created: ${user.payload.first_name} ${user.payload.last_name} created order on ${moment().format('LLL')}`,
                created_date: moment()
            }]
        });
        setItemsList([]);
        setFilteredItemsList([]);
        setDmeId("");
        if (reason === 'clickaway') {
            return;
        }
    };
    function createEmailNoteTemplate(note) {
        let string = '';
        string += `<br> <strong>Creator:</strong> ${note.first_name} ${note.last_name}`;
        string += `<br> <strong>Created Date:</strong> ${note.created_date}`;
        string += `<br> <strong>Description:</strong> ${note.description}`;
        return string;
    }

    const addOrderNote = () => {
        let final = moment().format('LLL');
        const arr = [...order.notes];
        let input = {
            user_id: user.payload.user_id,
            first_name:user.payload.first_name,
            last_name:user.payload.last_name,
            creator: `${user.payload.first_name} ${user.payload.last_name}`,
            created_date: final,
            description: currentNote};
        arr.push(input);
        setOrder({...order, notes: arr});
        setCurrentNote('');
        setIsUnsavedNote(false);
        setShowWarning(false);
        return arr;
    };

    const submitWithUnsavedNote = () => {
        let notes = addOrderNote();
        createOrder(false, notes);
    }

    const createOrder = (needNoteValidation, notes) => {
        if (isUnsavedNote && needNoteValidation){
            setShowError(true);
        } else {
            setShowError(false);
            setLoading(true);
            let orderClone = _.cloneDeep(order);
            const data = JSON.parse(localStorage.getItem('key'));
            fetch(`${endpoints.createOrder}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + data.accessToken
                    },
                    body: JSON.stringify({...orderClone, dme_organization_id: dmeId !== "" ? dmeId : order.dme_organization_id,
                        patient_id: patient_id, notes: notes ? notes : orderClone.notes
                    })
                }
            )
                .then(response => {
                    if (response.status === 201) {
                        // setShowCreateOrder(false);
                        // setShowSnackbar(true);
                        props.getPatientOrders && props.getPatientOrders();
                    } else {
                        console.log('error')
                    }
                    return response.json();
                }).then( async response => {
                await setOrder_id(response.data);
                if (order.items.some(item => item.approval === true)) {
                    let patient;
                    if (order.first_name){
                        patient = `${order.first_name} ${order.last_name}`
                    } else if (props.orderProps && props.orderProps.first_name){
                        patient = `${props.orderProps.first_name} ${props.orderProps.last_name}`
                    } else if (patientInfo.first_name){
                        patient = `${patientInfo.first_name} ${patientInfo.last_name}`
                    }
                    fetch(`${endpoints.sendApproveOrderMail}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + data.accessToken
                            },
                            body: JSON.stringify({
                                orderId: `D100${response.data}`,
                                items: order.items,
                                orderType: "Delivery",
                                link: `${process.env.REACT_APP_API_URL}order/delivery/${response.data}`,
                                domain: `${process.env.REACT_APP_API_URL}`,
                                patient: patient,
                                patient_id: props.orderProps ? props.orderProps.patient_id : patientInfo.patient_id,
                                note: order.notes[0] ? createEmailNoteTemplate(order.notes[0]) : (props.orderProps ? (props.orderProps.notes[0] ? createEmailNoteTemplate(props.orderProps.order.notes[0]) : "No notes") : "No notes")
                            })
                        }
                    ).then(() => console.log('email sent'))
                }
                if (files.length > 0){
                    await uploadFiles(response.data);
                } else {
                    // setShowSnackbar(true);
                    setShowCreateOrder(false);
                    setLoading(false);
                    setOpen(false)
                }
                const action = key => (
                    <React.Fragment>
                        <Button onClick={() => {
                            history.push("/regular/refresh/order/delivery/" + response.data);
                            closeSnackbar(key);
                        }}>
                            View Order
                        </Button>
                        <Button onClick={() => {
                            closeSnackbar(key);
                        }}>
                            Close
                        </Button>
                    </React.Fragment>
                );
                enqueueSnackbar(`Order #D100${response.data} has been created with success.`, {
                    variant: 'success',
                    autoHideDuration: 10000,
                    anchorOrigin: {vertical: "top", horizontal: "right"},
                    action,
                });
            })
                .then(response => {
                    setOrder({
                        status: "Open",
                        patient_id: props.user.patient_id,
                        search: '',
                        preferred_date: moment().format("YYYY-MM-DD"),
                        user_id: Number(user.payload.user_id),
                        category: '',
                        dme_organization_id: '',
                        hospice_id: props.user.hospice_organization_id,
                        priority_code: 'Routine',
                        approval: false,
                        items: [],
                        notes: [],
                        history: [{
                            description: `Order created: ${user.payload.first_name} ${user.payload.last_name} created order on ${moment().format('LLL')}`,
                            created_date: moment()
                        }]
                    });
                    setItemsList([]);
                    setFilteredItemsList([]);
                    setDmeId("");
                    // props.closeMobileModal()
                })
                .catch(err => {
                    console.log(err, 'error');
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                })
        }

    };
    const getContractedProviders = () => {
        if (true){
            const data = JSON.parse(localStorage.getItem('key'));
            fetch(`${endpoints.getHospiceContractedProviders}${user.payload.hospice_organization_id}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + data.accessToken
                }
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let object = [];
                    for (let i = 0; i < response.data.length; i++) {
                        object.push({value: response.data[i].dme_id, label: response.data[i].dme_name})
                    }
                    setContractedProviders(response.data);
                    setContractedProvidersList(object);
                    if (response.data.length === 1) {
                        setDmeId(response.data[0].dme_id);
                        setOrder({...order, dme_organization_id:response.data[0].dme_id})
                    }
                })
                .catch(err => {
                    console.log(err, 'error');
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                })
        }
    };

    const getCategoriesList = () => {
        const data = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getCategoriesMasterItems}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + data.accessToken
            }
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data.length > 0) {
                    let object = [];
                    for (let i = 0; i < response.data.length; i++) {
                        object.push({value: response.data[i].category, label: response.data[i].category})
                    }
                    const sortedCategoriesList = object.sort((a, b) => a.label.localeCompare(b.label));
                    const sortedAllCategoriesList = [{ value: "All", label: "All" }, ...sortedCategoriesList];
                    setCategoriesList(sortedAllCategoriesList);
                } else {
                    setCategoriesList([]);
                }
            })
            .catch(err => console.log('error', err))
    };
    const hideSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowSnackbar(false);
    };
    const viewOrder = () => {
        history.push(`/order/delivery/${order_id}`);
    };

    const renderButton = () => {
        return (
            <React.Fragment>
                {props.addMore ? (
                        <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            startIcon={<AddIcon style={{fontSize:28}}/>}
                            onClick={handleShowCreateOrder}
                            style={{
                                fontSize:12,
                                fontWeight:'bold',
                                border:'1px solid #00223D',
                            }}
                        >
                            {props.table ? 'ADD NEW ITEMS' : 'NEW DELIVERY'}
                        </Button>
                    ) :
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        startIcon={<img
                            src={CartIcon}
                            alt="logo"
                            width='16'
                            height='15'
                            style={{cursor:'pointer'}}
                        />}
                        onClick={handleShowCreateOrder}
                    >
                        {props.table ? 'ADD ITEMS' : 'NEW DELIVERY'}
                    </Button>
                }
            </React.Fragment>
        )
    };

    async function uploadFiles (orderId) {
        for (let i=0; i<files.length; i++){
            await createOrderFilesIds(orderId, files[i])
        }
        // await setShowSnackbar(true);
        await setShowCreateOrder(false);
        setLoading(false);
    }
    async function createOrderFilesIds(orderId, file){
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.createFileUploadIdOnOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({
                file_name: file.name,
                orderId: orderId,
                orderType: 'delivery',
            })
        });
        const response = await promise1.json();
        let name = response.data.rows[0].file_name.split(".");
        let fileName = `${response.data.rows[0].file_id}.${name[name.length-1]}`
        await uploadFile(file, fileName)
    }

    async function uploadFile (file, fileName) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.fileUploadOnOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({fileName:  fileName, type:'delivery'})
        });
        const result1 = await promise1.json();
        try{
            const promiseS3 = await fetch(result1.data, { method: 'PUT', body: file});
        } catch (e) {
            console.log(e.stack);
        }
    }

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <OrderItems setActiveStep={setActiveStep} total={total} user={props.user}
                                   items={filteredItemsList}
                                   isPace={props.isPace}
                                   setOrder={setOrder} handleInput={handleInput} order={order}
                                   contractedProvidersList={contractedProvidersList}
                                   categoriesList={categoriesList} addMore={props.addMore}
                />;
            case 1:
                return <div style={{display: 'flex', justifyContent: 'center'}}>
                    {/*<OrderReview*/}
                    {/*    order={order}*/}
                    {/*    setOrder={setOrder}*/}
                    {/*    files={files}*/}
                    {/*    setFiles={setFiles}*/}
                    {/*    setIsUnsavedNote={setIsUnsavedNote}*/}
                    {/*    showError={showError}*/}
                    {/*    setCurrentNote={setCurrentNote}*/}
                    {/*    currentNote={currentNote}*/}
                    {/*    setShowWarning={setShowWarning}*/}
                    {/*    showWarning={showWarning}*/}
                    {/*    addOrderNote={addOrderNote}*/}
                    {/*/>*/}
                </div>;
            default:
                console.log('Unknown step');
        }
    }


    useEffect(()=> {
        console.log(order, 'itemssss')
    },[order])


    useEffect(() => {
        if (user.payload.hospice_organization_id !== null) {
            if (contractedProvidersList.length<1){
                getContractedProviders();
            }
            setOrder((prevState )=> {
                return {
                    ...prevState,
                    dme_organization_id: props.orderPackageDmeId ?  props.orderPackageDmeId : order.dme_organization_id,
                    hospice_id: user.payload.hospice_organization_id,
                    region_id: user.payload.region_id}
            })
        } else {
            //TODO, get here when create dme provider
            setOrder({
                ...order,
                dme_organization_id: props.orderPackageDmeId ? props.orderPackageDmeId : user.payload.dme_organization_id,
                hospice_id: props.user.hospice_organization_id,
                region_id: props.user.region_id
            })
        }
    }, [order.dme_organization_id, props.orderPackageDmeId, props.user.patient_id]);
    useEffect(() => {
        if (contractedProvidersList.length === 1) {
            setOrder({...order, dme_organization_id: contractedProvidersList[0].dme_id})
        }
    }, [contractedProvidersList]);
    useEffect(() => {
        if (open === true && (order.dme_organization_id !== ''|| props.orderDmeId)) {
            const data = JSON.parse(localStorage.getItem('key'));
            let body;
            if (user.payload.hospice_organization_id !== null) {
                body = {
                    dme_id: dmeId !== "" ? dmeId : order.dme_organization_id,
                    hospice_id: order.hospice_id,
                    hospice_region_id: order.region_id,
                };
                if (props.orderDmeId) {
                    body.dme_id = props.orderDmeId
                }
            } else {
                body = {
                    dme_id: props.orderDmeId ? props.orderDmeId : order.dme_organization_id,
                    hospice_id: order.hospice_id,
                    hospice_region_id: order.region_id,
                }
            }
            //TODO check
            fetch(`${endpoints.getHospiceContractedItems}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + data.accessToken
                    },
                    body: JSON.stringify(body)
                }
            )
                .then(response => {
                    return response.json();
                }).then(response => {
                if (response.data.length > 0) {
                    let clonedItems = _.cloneDeep(response.data);
                    clonedItems.map(item => {
                        if (!item.max_quantity_per_item) {
                            item.max_quantity_per_item = 10;
                        }
                    });
                    setItemsList(clonedItems);
                    setFilteredItemsList(clonedItems);
                } else {
                    setItemsList([]);
                    setFilteredItemsList([]);
                }
            })
                .catch(err => console.log('error', err));
        }
    }, [order.dme_organization_id, dmeId, open]);

    useEffect(() => {
        let cloneItemList = _.cloneDeep(itemsList);
        let result = [];
        if (order.category !== '' && order.category !== "All" && order.category !== null) {
            cloneItemList.map(item => {
                if (item.category && _.includes(item.category.toLowerCase(), order.category.toLowerCase())) {
                    result.push(item)
                }
            })
        } else {
            result = cloneItemList;
        }
        setFilteredItemsList(result)
    }, [order.category]);
    useEffect(() => {
        if (order.search !== '') {
            let lorin = _.cloneDeep(itemsList);
            let result = [];
            lorin.map(item => {
                if (_.includes(item.name.toLowerCase(), order.search.toLowerCase())) {
                    result.push(item)
                } else if (_.includes(item.tags, order.search.toLowerCase())) {
                    result.push(item)
                }
            });
            setFilteredItemsList(result)
        } else {
            setFilteredItemsList(itemsList)
        }

    }, [order.search]);

    useEffect(() => {
        if (categoriesList.length === 0 && open) {
            getCategoriesList()
        }
    }, [open]);

    function generateName(){
        return `${patientInfo.first_name} ${patientInfo.last_name}`
    }

    function generateAddress(){
        return `${patientInfo.address1} ${patientInfo.address2}, ${patientInfo.city}, ${patientInfo.state} ${patientInfo.zip}`
    }


    return (
        <React.Fragment>
            <Button onClick={()=> {
                setStep(2)
                setOpen(true)
            }}
                    startIcon={<img
                        src={CartIcon}
                        alt="logo"
                        width='16'
                        height='15'
                        // className={classes.simpleLogo}
                        style={{cursor:'pointer', marginRight:8}}
                    />}
                    variant={"contained"}
                    color={'primary'}
                    fullWidth={fullWidth}
                    style={{fontSize:16, width:'200px', paddingTop:16, paddingBottom:16, height:60}}
            >NEW DELIVERY</Button>
            <Dialog
                open={open} onClose={()=> {
                    setOpen(false)
                    isMobile && props.closeMobileModal();
            }}
                maxWidth={'xl'}
                fullWidth
                classes={{
                    paperFullWidth: classes.paperFullWidth,
                    paperScrollPaper: classes.paperScrollPaper,
                }}
            >
                <Grid container justify={'space-between'} alignItems={'center'} alignContent={'center'} style={{padding: 8,paddingTop:8, paddingBottom:0}}>
                    <Grid item className={classes.title}>New Delivery</Grid>
                    <Grid item>
                        <IconButton onClick={()=> {
                            setOpen(false)
                            isMobile && props.closeMobileModal();
                        }} size={'small'}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <div style={{display:'flex', justifyContent:'space-between', paddingTop:4, paddingBottom:'4'}}>
                    <div style={{paddingLeft:8}}>
                        <div className={classes.subtitle}>{generateName()}</div>
                        <div className={classes.description}>{generateAddress()}</div>
                    </div>
                    {
                        step === 2 && (
                                <div style={{paddingRight:8}}>
                                    <div>Cart items: {order.items.length}</div>
                                    <Button variant={'outlined'} color={'primary'} size={'small'}
                                        onClick={()=> setOrder({...order, items:[]})}
                                    >Clear Cart</Button>
                                </div>
                        )
                    }
                </div>

                <Grid container>
                    {step === 1 && (
                        <Grid container style={{padding:8, paddingLeft:4, paddingRight:4}}>
                            <Grid item xs={6} style={{padding:4}} >
                                <Button fullWidth className={classes.box} style={{backgroundColor:'#315464', color:'white',
                                }} onClick={()=> setStep(2)}>
                                    <img src={CartIcon} alt={'cart_sign'}/>
                                    <div style={{paddingLeft:8, fontWeight: "bold", fontSize: 14,}}>New Delivery</div>
                                </Button>
                            </Grid>
                            <Grid item xs={6} style={{padding:4}}>
                                <Button fullWidth className={classes.box} style={{backgroundColor:'#C2C2C2', color:'white'}}>
                                    <img src={ToolIcon} alt={'tool_sign'}/>
                                    <div style={{paddingLeft:8, fontWeight: "bold", fontSize: 14,}}>Service</div>
                                </Button>
                            </Grid>
                            <Grid item xs={6} style={{padding:4}} >
                                <Button fullWidth className={classes.box} style={{backgroundColor:'#4F86A0', color:'white'}}>
                                    <img src={TruckIcon} alt={'truck_sign'}/>
                                    <div style={{paddingLeft:8, fontWeight: "bold", fontSize: 14,}}>New Pickup</div>
                                </Button>
                            </Grid>
                            <Grid item xs={6} style={{padding:4}} >
                                <Button fullWidth className={classes.box} style={{backgroundColor:'#84B4C1', color:'white'}}>
                                    <img src={ToolIcon} alt={'tool_sign'}/>
                                    <div style={{paddingLeft:8, fontWeight: "bold", fontSize: 14,}}>New Exchange</div>
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                    {step === 2 && (
                        <Grid container>
                            <Grid container
                                  alignContent={'flex-end'} alignItems={'flex-end'}>
                                <Grid sm={3} xs={6}
                                      style={{padding:8, paddingBottom:14}}
                                >
                                    <TextField
                                        label={'Preferred Date'}
                                        size={"small"}
                                        fullWidth
                                        variant={'outlined'}
                                        type='date' style={{backgroundColor:'white', padding:2}}
                                        value={order.preferred_date}
                                        onChange={(e)=>setOrder({...order, preferred_date: e.target.value})}

                                    />
                                </Grid>
                                <Grid sm={3} xs={6} style={{paddingTop:10}}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-native-simple" style={{paddingLeft:8}}>Priority</InputLabel>
                                        <Select
                                            native
                                            autoWidth
                                            value={order.priority_code}
                                            onChange={(e)=> {
                                                let obj = {target: {name:'priority_code',value:e.target.value}};
                                                handleInput(obj)
                                            }}
                                            label="Priority"
                                            inputProps={{
                                                name: 'age',
                                                id: 'outlined-age-native-simple',
                                            }}
                                            // style={{width:200}}
                                            style={{height:40}}
                                        >
                                            <option value={'Routine'}>Routine</option>
                                            <option value={'Priority'}>Priority</option>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid sm={3} xs={6} style={{paddingLeft:8, paddingBottom:16, paddingRight:8}}>
                                    <TextField fullWidth label={'Search'} variant="outlined" size={'small'} name={'search'} onChange={handleInput}/>
                                </Grid>
                                <Grid sm={3} xs={6}>
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="outlined-age-native-simple" style={{paddingLeft:8}}>Category</InputLabel>
                                        <Select
                                            native
                                            autoWidth
                                            defaultValue={'All'}
                                            onReset={()=> console.log('aaa')}
                                            name={'category'}
                                            value={order.category}
                                            onChange={(e)=> {
                                                let obj = {target: {name:'category',value:e.target.value}};
                                                handleInput(obj)
                                            }}
                                            label="Category"
                                            inputProps={{
                                                name: 'age',
                                                id: 'outlined-age-native-simple',
                                            }}
                                            style={{height:40}}
                                            InputLabelProps={{ shrink: true }}
                                        >
                                            <option value={'All'}>All</option>
                                            {categoriesList.map((category,index) =>{
                                                if (index > 0){
                                                    return (
                                                        <option value={category.label}>{category.label}</option>
                                                    )
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container style={{padding:8, paddingTop:0, paddingBottom:4}}>
                                <Grid container alignItems={'stretch'} style={{padding:2, border:'1px solid #ebebeb', height:`calc(${height}px - 278px)`, borderRadius:4, overflow:'scroll', backgroundColor:'whitesmoke'}}>
                                    {filteredItemsList.length > 0 && filteredItemsList.map((item, index)=> {
                                        return (
                                            <Grid item xs={6} style={{padding:4, maxHeight:320}}>
                                                <Grid container style={{backgroundColor:'white',height:'100%', borderRadius:4, padding:8, border: item.prescription_required === true ? "1px solid red" : "0px solid #D5D9DC"}} alignItems={'space-between'} alignContent={'space-between'}>
                                                    <Grid container>
                                                        <Grid item xs={12} style={{display:'flex', justifyContent:'center', fontWeight:'bold', fontSize: item.name.length > 25 ? 13 :15}}>
                                                            {item.name}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
                                                            <div className={classes.image}>
                                                                {(item.image === '.jpeg' || item.image === '') ? (
                                                                    <img src={`${process.env.REACT_APP_BUCKET_URL}placeholder.png`} className={classes.img} />
                                                                ) : (
                                                                    <img src={`${process.env.REACT_APP_BUCKET_URL}${item.image}`} className={classes.img} />
                                                                )}
                                                            </div>
                                                        </Grid>
                                                        <Grid container justify={'flex-end'}>
                                                            <div style={{height:30, width:160, display:"flex", justifyContent:'flex-end'}}>{item.prescription_required === true &&
                                                            <img src={'https://masteritemsimages.s3.amazonaws.com/rx.png'} height='25px' width={'20px'}/>
                                                            }
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
                                                            <Grid container justify={'space-between'} alignItems={'center'} alignContent={'center'}>
                                                                <Grid item className={classes.type} >
                                                                    {item.type === "OOF" && (props.isPace === true ? <span className={classes.red}>{labels.paceOOF}</span> : <span className={classes.red}>{labels.out_of_formulary}</span>)}
                                                                    {item.type === "PI" && <span className={classes.red}>{labels.purchase_item}</span>}
                                                                    {item.type === "Monthly" && props.isPace === true && <span className={classes.red}>{labels.paceMonthly}</span>}
                                                                    {item.type === "LF" && <span className={classes.red}>{labels.limited_formulary}</span>}
                                                                    {item.type === "PD" && (props.isPace === true ? labels.paceFormularyItem : labels.in_formulary)}
                                                                </Grid>

                                                                <Grid item>
                                                                    {
                                                                        item.supplemental_price ? (
                                                                            <div className={classes.oof_price}>

                                                                                ${item.supplemental_price}
                                                                            </div>
                                                                        ) : (
                                                                            <div className={classes.typePrice}>
                                                                                {labels.zero_price}
                                                                            </div>
                                                                        )
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12} style={{display:'flex', justifyContent:'center', paddingTop:4, paddingBottom:0}}>
                                                            <NewAddItemModal
                                                                user={user}
                                                                open={open}
                                                                // onClose={handleClose}
                                                                item={item}
                                                                order={order}
                                                                setOrder={setOrder}
                                                                // handleInput={handleInput}
                                                                patientInfo={patientInfo}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>

                        </Grid>

                    )}
                    {step === 3 && (
                        <Grid container style={{paddingLeft:8, paddingRight:8}}>
                            <OrderReview
                                order={order}
                                setOrder={setOrder}
                                files={files}
                                setFiles={setFiles}
                                setIsUnsavedNote={setIsUnsavedNote}
                                setCurrentNote={setCurrentNote}
                                currentNote={currentNote}
                                setShowWarning={setShowWarning}
                                showWarning={showWarning}
                                addOrderNote={addOrderNote}
                            />
                        </Grid>
                    )}
                    <div style={{display:'flex', justifyContent:'flex-end', paddingBottom:8, paddingRight:8, width:'100%'}}>
                        <Button variant={'outlined'} color={'primary'} style={{paddingTop:4, paddingBottom:4, paddingLeft:12, paddingRight:12, height:36}}
                                onClick={()=> {
                                    setOpen(false)
                                    isMobile && props.closeMobileModal();
                                }}
                        >Cancel</Button>
                        {step === 3 && (
                            <Button variant={'outlined'} color={'primary'} style={{paddingTop:4, paddingBottom:4, paddingLeft:12, paddingRight:12, height:36, marginLeft:8,}}
                                    onClick={()=> {
                                        setStep(step - 1)
                                    }}
                            >Back</Button>
                        )}
                        <Button onClick={()=> {
                            if (step !== 3){
                                setStep(step + 1)
                            } else {
                                createOrder(true)
                            }

                        }} disabled={loading || order.items.length === 0} variant={'contained'} color={'primary'} style={{marginLeft:8, paddingTop:8, paddingBottom:8, paddingLeft:12, paddingRight:12, height:36}}>{ step === 3 ?  <div>{loading ? <CircularProgress style={{color:'white'}} size={20}/> : 'Create order'}</div> : 'Next'}</Button>
                    </div>

                </Grid>


            </Dialog>
            <Snackbar
                anchorOrigin={{vertical, horizontal}}
                ContentProps={{classes: {root: classes.snackbar}}}
                style={{padding: '10px'}}
                key={`${vertical},${horizontal}`}
                open={showSnackbar}
                autoHideDuration={10000}
                onClose={hideSnackbar}
                message={`Order #D100${order_id} was successfully created!`}
                action={
                    <React.Fragment>
                        <Button style={{color: 'black', marginRight: '6px'}} size="small" onClick={viewOrder}>
                            View Order
                        </Button>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={hideSnackbar}>
                            <CloseIcon fontSize="small"/>
                        </IconButton>
                    </React.Fragment>
                }
            >
            </Snackbar>
            {/*<Grid>create delivery</Grid>*/}
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => ({
    paperFullWidth: {
        width: 'calc(100% - 16px)',
        minHeight:328,
    },
    snackbar: {
        backgroundColor: '#117C70'
    },
    paperScrollPaper: {
        maxHeight: 'calc(100% - 16px)'
    },
    title: {
        textTransform:'uppercase',
        fontWeight: "bold",
        fontSize: 20,
        borderBottom:'1px solid #B2D1D9'
    },
    subtitle: {
        fontWeight: "bold",
        fontSize: 18,
    },
    description: {
        color:'grey',
        fontWeight: 500,
        fontSize: 16,
    },
    box: {
        minHeight:100,
        height:180,
        maxHeight:'25vh',
        // border:'1px solid gray',
        borderRadius:4,
        display:'flex',
        justifyContent:'center',
        alignContent:'center',
        alignItems:'center',
        textTransform:'uppercase',
        fontWeight: 600,
    },
    formControl: {
        width:'100%',
        paddingRight:8, paddingLeft:8,
        marginBottom:16,
    },
    image: {
        // border:'1px solid #F6F7F7',
        marginTop:4,
        height:100,
        width:148,
        display:'flex',
        alignItems: 'center',
        justifyContent: "center"
    },
    img: {
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 'auto'
    },
    type: {
        color:'#4E6071',
        fontSize:13
    },
    red: {
        color:'red'
    },
    oof_price: {
        fontSize:12,
        backgroundColor:'#FBECEE',
        color:'#CD1D32',
        borderRadius:4,
        padding:'4px 8px'
    },
    typePrice: {
        fontSize:12,
        backgroundColor:'#D5D9DC',
        color:'#4E6071',
        borderRadius:4,
        padding:'4px 8px'
    },
}));


const OrderReview = ({order, setOrder, files, setFiles, setIsUnsavedNote, setCurrentNote, currentNote, setShowWarning, showWarning, addOrderNote}) => {
    const classes = useStyles();
    const [showClearConfirmation, setShowClearConfirmation] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const columns = [
        {title: 'Item Name', field: 'name', width: 400,
            headerStyle:{textAlign: 'left'},
            render: rowData => <Grid container style={{ display:'flex', alignContent:'center'}}>
                <Grid item style={{ display:'flex', alignContent:'center'}}>
                    <CustomCheckbox
                        // checked
                        checked={selectedItems.some(e => e.tableData.id === rowData.tableData.id)}
                        onChange={(e)=> {
                            if (e.target.checked){
                                setSelectedItems([...selectedItems, rowData])
                            } else {
                                let index = selectedItems.findIndex(e => e.tableData.id === rowData.tableData.id)
                                let clone = _.cloneDeep(selectedItems);
                                clone.splice(index, 1);
                                setSelectedItems(clone);
                            }
                        }}
                    />
                </Grid>
                <Grid item style={{ display:'flex', alignItems:'center'}}>
                    {rowData.type ==="PD" ? <LocalOfferIcon style={{color: '#117C70'}}/> :
                        <React.Fragment>
                            { rowData.approval ? <LocalOfferIcon style={{color: '#CD1D32'}}/> : <LocalOfferIcon style={{color: '#004175'}}/>}
                        </React.Fragment>}
                </Grid>
                <Grid item style={{ display:'flex', alignItems:'center', paddingLeft:12, fontWeight:'bold',color:"#2E3236"}}>
                    {rowData.approval ? <span style={{color:"#CD1D32"}}>
                    {rowData.name}
                </span> : <span>{rowData.name}</span>}

                </Grid>
                {rowData.approval && (
                    <Grid item xs={12} className={'inactive_status'}>Need Authorization</Grid>
                )}
                <Grid item xs={12}> Clinical Settings:
                    <div style={{textAlign: 'center', fontWeight:'bold'}}>
                        {rowData.special_item_info.min_range && (
                            <div style={{display:'flex'}}>
                                <div> {`Min. ${rowData.special_item_info.min_range}`} </div>
                                <div> {`Max. ${rowData.special_item_info.max_range}`} </div>
                            </div>
                        )}
                        {rowData.special_item_info.isContinuous === true && (
                            <div> {`With continuous oxygen therapy`} </div>
                        )}
                        {rowData.special_item_info.isContinuous === false && (
                            <div> {`With PRN oxygen therapy`} </div>
                        )}
                        {rowData.special_item_info.delivery_method === "Mask" && (
                            <div> {`Delivery Method: Mask`} </div>
                        )}
                        {rowData.special_item_info.delivery_method === "Nasal Cannula" && (
                            <div> {`Delivery Method: Nasal Cannula`} </div>
                        )}
                        {rowData.special_item_info.humidifier_for_compressor && (
                            <div> {`Humidifier bottle: ${rowData.special_item_info.humidifier_for_compressor}%`} </div>
                        )}
                        {rowData.special_item_info.weight && (
                            <div> {`Patient Weight: ${rowData.special_item_info.weight}`} </div>
                        )}
                        {rowData.special_item_info.size_lift && (
                            <div> {`Size: ${rowData.special_item_info.size_lift}`} </div>
                        )}
                    </div>
                </Grid>

            </Grid>},
        {
            title: 'Quantity', field: 'quantity', width: 40,
            headerStyle:{textAlign: 'center'},
            render: rowData => <Grid container>
                <div style={{display: "flex", alignItems: 'center'}}>
                    <IconButton
                        style={{color:'#8EB5C7'}}
                        onClick={() => {
                            if (rowData.quantity > 1) {
                                let orderClone = [...order.items];
                                orderClone[rowData.tableData.id].quantity = (orderClone[rowData.tableData.id].quantity - 1);
                                setOrder({...order, items: orderClone})
                            }
                        }}
                    >
                        <RemoveCircleOutlineIcon/>
                    </IconButton>
                    <div style={{width: '12px'}}>{rowData.quantity}</div>
                    <Tooltip
                        title={rowData.quantity < rowData.special_item_info.max_quantity_per_item ?
                            labels.increase_quantity : labels.max_quantity_reached}
                    >
                        <span>
                            <IconButton
                                style={{color:'#8EB5C7'}}
                                disabled={rowData.quantity === rowData.special_item_info.max_quantity_per_item}
                                onClick={() => {
                                    let orderClone = [...order.items];
                                    let index = orderClone.findIndex(i => i.item_ID === rowData.item_ID);
                                    if (orderClone[rowData.tableData.id].quantity < orderClone[rowData.tableData.id].special_item_info.max_quantity_per_item){
                                        orderClone[rowData.tableData.id].quantity = (orderClone[rowData.tableData.id].quantity + 1);
                                        setOrder({...order, items: orderClone})
                                    }
                                }}
                            >
                                <AddCircleOutlineIcon/>
                            </IconButton>
                        </span>
                    </Tooltip>
                </div>
            </Grid>
        },
    ];

    const handleRemoveItem = () => {
        let cart = _.cloneDeep(order.items);
        let indexArr = [];
        selectedItems.map(item => indexArr.push(item.tableData.id));
        indexArr.sort((a, b)=> b-a);
        indexArr.map(e => {
            cart.splice(e,1);
        });
        setOrder({...order, items:cart});
        setSelectedItems([]);
    };
    return (
        <Grid conatainer
            // className={'order_container'}
              style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', overflow: "scroll", width:'100%'}}>
            <Table columns={columns} items={order.items} order={order} setOrder={setOrder}/>
            <Grid item xs={12} style={{display:'flex', justifyContent:'space-between'}}>
                <Button variant={'outlined'} style={{
                    color:'#00223D',
                    fontWeight:'bold',
                    fontSize:12,
                    height:39,
                    border:'1px solid #00223D',
                    display:'flex',
                    alignContent:'center',
                    alignItems:'center',
                    justifyContent:'center'
                }} onClick={handleRemoveItem}
                ><CloseIcon style={{fontSize:18}}/><span style={{paddingTop:2}}>REMOVE SELECTED</span></Button>
                <Button
                    variant='outlined'
                    style={{
                        marginLeft:8,
                        display: 'flex',
                        padding:'6px 12px',
                        borderRadius: '4px',
                        borderColor:'#212121',
                        fontWeight:'bold',
                        fontSize:12,
                        cursor:'pointer',
                    }} onClick={()=> {
                    if (order.items.length > 0) {
                        setShowClearConfirmation(true)
                    }
                }}>
                    CLEAR CART
                </Button>
            </Grid>
            <Grid container>
                <Grid item style={{paddingTop:8, paddingBottom:8, color:'red'}}>
                    {order.items.some(item => item.prescription_required === true) && 'One or more items for cart require prescription. Please upload files below now, or after creating the order.'}
                </Grid>
            </Grid>
            <FileUpload files={files}
                        setFiles={setFiles}
            />
            <Grid item xs={12}>
                <Typography style={{fontSize:24, fontWeight:'bold', color:'#00223D', paddingTop:8}}>
                    Order Notes
                </Typography>
            </Grid>
            <OrderNotes order={order} setIsUnsavedNote={setIsUnsavedNote} setCurrentNote={setCurrentNote} currentNote={currentNote} setShowWarning={setShowWarning} showWarning={showWarning} addOrderNote={addOrderNote} />
            <Dialog
                open={showClearConfirmation}
                onClose={() => setShowClearConfirmation(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                        <span style={{fontSize:16, fontWeight:600}}>
                            All items for the cart will be removed. Do you want to proceed?
                        </span>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowClearConfirmation(false)} variant='outlined' color="secondary" style={{ fontWeight:600}}>
                        No, return
                    </Button>
                    <Button
                        onClick={()=> {
                            setOrder({...order, items: []});
                            setShowClearConfirmation(false);
                        }}
                        variant='contained' color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>

    )
};


const CustomCheckbox = withStyles({
    root: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        },
        "&:checked": {
            color: "#4F86A0"
        },
        "&:disabled": {
            color: "#4F86A0"
        }
    },
    checked: {
        color: "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        }
    },
    disabled: {
        color:  "#4F86A0",
        "&$checked": {
            color: "#4F86A0"
        },
        "&$disabled": {
            color: "#4F86A0"
        }
    }
})(Checkbox);
