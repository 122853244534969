import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import _ from "lodash";

export default function AccessRights({rolesList, user, roleCategories, permissionList, setUser}) {
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Customize access rights
            </Typography>
            <div style={{fontWeight:'bold', fontSize:16}}> User role: {rolesList[_.findIndex(rolesList,{"value":Number(user.role_id)})].label}</div>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Grid container style={{
                        display: 'flex',
                        justifyContent: 'left',
                    }}>
                        {roleCategories.map(category => (
                            <React.Fragment>
                                <Grid item md={3} sm={4} xs={12} style={{padding: '12px'}}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'left',
                                        alignItems: 'center',
                                        paddingLeft: '10px',
                                        fontWeight:'bold',
                                        fontSize:24
                                    }}>{category}</div>
                                    {permissionList.map((permission, permissionIndex) => (
                                        permission.category === category && (
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'left',
                                                alignItems: 'center',
                                            }} key={permissionIndex}>
                                                <Checkbox
                                                    checked={user.permissions.length < 1 || user.permissions.includes(permission.permission_id)}
                                                    onChange={event => {
                                                        if (user.permissions.includes(permission.permission_id)) {
                                                            let list = user.permissions;
                                                            let index = list.indexOf(Number(event.target.name));
                                                           

                                                            if (index !== -1) list.splice(index, 1);
                                                            setUser({...user, permissions: list});
                                                        } else {
                                                            let list = user.permissions;
                                                            list.push(Number(event.target.name));
                                                            setUser({...user, permissions: list})
                                                        }
                                                    }}
                                                    name={permission.permission_id}
                                                    color="primary"
                                                />
                                                <Typography style={{paddingTop: '8px', textAlign:'left'}} gutterBottom>
                                                    {permission.operation + ' ' + permission.category}
                                                </Typography>
                                            </div>
                                        )
                                    ))}
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
