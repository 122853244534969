import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {dashboardStyle} from "../../dashboard/dashboard.style";
import {useHistory} from "react-router-dom";
import PersonIcon from '@material-ui/icons/Person';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AddBoxIcon from '@material-ui/icons/AddBox';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DescriptionIcon from '@material-ui/icons/Description';

export function AdminDashboard(){
    const classes = useStyles();
    const history = useHistory();

    const iconMapper = (iconName) => {
        switch(iconName){
            case "PersonIcon": return <PersonIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "LocalHospitalIcon": return <LocalHospitalIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "SupervisorAccountIcon": return <SupervisorAccountIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "VerifiedUserIcon": return <VerifiedUserIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "AssignmentIcon": return <AssignmentIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "AddBoxIcon": return <AddBoxIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "FlashOnIcon": return <FlashOnIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
            case "DescriptionIcon": return <DescriptionIcon style={{fontSize: '32px', color: '#4F86A0'}}/>
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography className={classes.h3} style={{padding: '15px 48px'}}>Admin Dashboard</Typography>
                </Grid>
            </Grid>

            <Grid container style={{paddingLeft:16, paddingRight:16}}>
                {items.map((item,idx) => (
                    <Grid item xs={12} sm={6} md={4} style={{padding:'5px', position: 'relative'}}>
                        <div
                            className={classes.box}
                            style={{borderBottom: idx % 2 === 0 ? '4px solid #4F86A0' : '4px solid #A3C7D2', minHeight: '420px'}}>
                            <Typography className={classes.h3} style={{display: 'flex', alignItems: 'center'}}>
                                {iconMapper(item.icon)}
                                <span style={{paddingLeft: '10px'}}>{item.title}</span>
                            </Typography>
                            {item.list.map((listItem,idx) => (
                                <div key={idx} style={{display:'flex', marginLeft: '48px', marginTop: '20px',fontSize: '24px',cursor: 'pointer'}} onClick={() => listItem.path && history.push(listItem.path)}>
                                    <Typography className={classes.h4}>{listItem.name}</Typography>
                                </div>
                            ))}
                        </div>
                    </Grid>
                ))}

            </Grid>
        </React.Fragment>
    )
}

const useStyles = makeStyles((theme) => (dashboardStyle(theme)));

//constants
const items = [
    {
        title: "Users",
        icon: "PersonIcon",
        list: [
            {
                name: "Create Hospice User",
                path: '/hospiceuser/new'
            }, {
                name: "Create DME User",
                path: '/dmeuser/new'
            }, {
                name: "View Hospice Users",
                path: '/admin/hospiceusers/all'
            }, {
                name: "View DME Users",
                path: '/admin/dmeusers/all'
            }, {
                name: "View Portal Users",
                path: '/admin/portalusers/all'
            }, {
                name: "Permission Packages",
                path: '/admin/permissionPackages'
            },
        ]
    },
    {
        title: "Providers",
        icon: "LocalHospitalIcon",
        list: [
            {
                name: "Create Hospice Organization",
                path: '/hospice/new'
            }, {
                name: "Create DME Provider",
                path: '/dmeprovider/new'
            }, {
                name: "View Hospice Organizations",
                path: '/admin/hospice/all'
            }, {
                name: "View DME Providers",
                path: '/admin/dme/all'
            },{
                name: "Hospice Type Settings",
                path: '/admin/hospice/type'
            }
        ]
    },
    {
        title: "Admin",
        icon: "SupervisorAccountIcon",
        list: [
            {
                name: "Organization Features",
                path: '/admin/enablefeatures'
            },
            {
                name: "Bulk Upload",
                path: '/admin/bulk'
            },
            {
                name: "PACE Organizations",
                path: '/admin/pacehospice/all'
            },
            {
                name: "DME Research",
                path: '/admin/dmeresearch'
            },
            {
                name: "Hospice Logo",
                path: '/admin/hospice/logo'
            }
        ]
    },
    {
        title: "Audit",
        icon: "VerifiedUserIcon",
        list: [
            {
                name: "Audit Trail Report",
                path: '/admin/audittrail'
            },
            {
                name: "Patient Portal Audit Trail",
                path: '/admin/audittrail/portal'
            },
            {
                name: "Unauthorized Access",
                path: '/admin/audit/unauthorized'
            },
        ]
    },
    {
        title: "Master Inventory",
        icon: "AssignmentIcon",
        list: [
            {
                name: "View Master Inventory",
                path: '/admin/masterinventory'
            },
            {
                name: "Create Custom Item Package",
                path: '/admin/hospice/custompackages/create'
            },
            {
                name: "View Custom Item Packages",
                path: '/admin/hospice/custompackages/view'
            },
            {
                name: "Add Item Resources",
                path: '/admin/item/documents'
            }
        ]
    },
    {
        title:"Contracts",
        icon: "DescriptionIcon",
        list: [
            {
                name: "Create Contract",
                path: '/admin/contract/create'
            },
            {
                name: "View Contracts",
                path: '/admin/contract/all'
            },
        ]
    },
    {
        title: "Surveys",
        icon: "FlashOnIcon",
        list: [
            {
                name: "Create Survey",
                path: '/admin/survey/create'
            },
            {
                name: "View All Surveys",
                path: '/admin/survey/all'
            },
            {
                name: "Send Survey",
                path: '/admin/survey/send'
            },
        ]
    },
    {
        title: "Additional",
        icon: "AddBoxIcon",
        list: [
            {
                name: "Manufacturers",
                path: '/admin/manufacturers'
            },
            {
                name: "Nurses",
                path: '/admin/nurses'
            },
            {
                name: "Technicians",
                path: '/admin/technicians'
            },
            {
                name: "Restricted Domains",
                path: '/admin/restricteddomains'
            },
        ]
    },
]
