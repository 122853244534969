export const forgotStyle = {
    container: {
        justifyContent: 'center',
        alignContent: 'center',
        direction: "column",
        maxWidth: 330
    },
    contentGrid: {
        minHeight: 460,
        minWidth: 300,
        maxWidth: 380,
        opacity: 1,
        paddingLeft: 34,
        paddingRight: 36
    },
    h2: {
        fontSize: 24,
        fontWeight: "bold",
        lineHeight: '32px',
        paddingTop: 91,
        color: "#00223D",
        textAlign: 'left',
        paddingLeft: '4px'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: "flex-start",
        marginTop: -10
    },
    button: {
        fontSize: 12,
        lineHeight: '16px',
        padding: "12px 16px",
    },
    text: {
        fontSize: 14,
        lineHeight: '14px',
        marginTop: 24,
        color: "#3B748F",
        textAlign: 'left',
        cursor: 'pointer',
    },
    poweredLogo: {
        paddingTop: 64,
        paddingBottom: 24
    },
    h3: {
        fontSize: 28,
        fontWeight: "bold",
        lineHeight: '36px',
        paddingTop: 100
    },
    textfield: {
        width: '100%',
        marginTop: 22
    },
    paddingBottom: {
        paddingBottom: 34
    },
    title: {
        color: '#00223D',
        fontWeight: 'bold',
        fontSize: 28,
        paddingBottom: 24,
        paddingTop: 36
    }
};
