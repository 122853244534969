export const createOrderStyle = (theme) => {
    return {
        main: {
            maxWidth: 1400,
            [theme.breakpoints.up('md')]: {
                padding:32,
                width: "90vw",
            },
            [theme.breakpoints.down('md')]: {
                padding:24,
                width: "84vw",
            },
            [theme.breakpoints.down('sm')]: {
                padding:12,
                width: "84vw",
            },
            [theme.breakpoints.down('xs')]: {
                padding:6,
                width: "74vw",
            },
        },
        cursor: {
            cursor:"pointer"
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            width: 'fit-content',
        },
        formControl: {
            marginTop: theme.spacing(2),
            minWidth: 120,
        },
        formControlLabel: {
            marginTop: theme.spacing(1),
        },
        root: {
            width: '100%',
            '& > * + *': {
                marginTop: theme.spacing(2),
            },
        },
        snackbar: {
            backgroundColor: '#4CAF51'
        },
        button: {
            color: '#FFFFFF',
            backgroundColor: '#315464',
            fontSize: 12,
            fontWeight:'bold',
            border:'none',
            width: '136px',
            height: '40px',
            "&:hover": {
                backgroundColor:'#416e82'
            }
        }
    }};