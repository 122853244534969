import React, {useEffect, useState} from 'react';
import {ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DoneIcon from "@material-ui/icons/Done";
import moment from "moment";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {orderTypes} from "../../../../constants/inputs/values";
import {useSnackbar} from "notistack";
import {endpoints} from "../../../../constants/endpoints";

export const TechnicianOperations = ({orderType, isLoading, orderData, accessToken, refreshInfoFunction, technicianView = true}) => {

    const {enqueueSnackbar} = useSnackbar();
    const [technicianOperations, setTechnicianOperations] = useState(false);
    const [arrivalTime, setArrivalTime] = useState(moment());
    const [departureTime, setDepartureTime] = useState(moment());
    const [deliveryType, setDeliveryType] = useState("contact");
    const [smokingSigns, setSmokingSigns] = useState("no");
    const [question1, setQuestion1] = useState("no");
    const [question2, setQuestion2] = useState("no");
    const [question3, setQuestion3] = useState("no");


    useEffect(() => {

        orderData.arrival_time && setArrivalTime(orderData.arrival_time);
        orderData.departure_time && setDepartureTime(orderData.departure_time);
        orderData.delivery_type && setDeliveryType(orderData.delivery_type);
        orderData.smoking_signs && setSmokingSigns(orderData.smoking_signs);
        orderData.questionaire_1 && setQuestion1(orderData.questionaire_1);
        orderData.questionaire_2 && setQuestion2(orderData.questionaire_2);
        orderData.questionaire_3 && setQuestion3(orderData.questionaire_3);

    }, [orderData])
    const updateTechnicianOperations = async () => {
        const payload = {
            arrivalTime,
            departureTime,
            deliveryType,
            smokingSigns,
            question1,
            question2,
            question3,
            special_info_id: orderData.special_info_id,
            delivery_order_id: orderType === orderTypes.delivery ? orderData.delivery_order_id : null,
            pickup_order_id: orderType === orderTypes.pickup ? orderData.pickup_order_id : null,
            exchange_order_id: orderType === orderTypes.exchange ? orderData.exchange_order_id : null,
        }

        const promise = await fetch(endpoints.orderSpecialInformation,{
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            body: JSON.stringify(payload)
        })
        if(promise.status === 200){
            enqueueSnackbar('Saved technician operations.', {variant: "success"});
        } else {
            enqueueSnackbar('Something happened while saving technician operations.', {variant: "warning"});
        }
        refreshInfoFunction();
    }

    return <Grid item>
        <Button
            variant={technicianView ? "contained" : "outlined"}
            color={"primary"}
            onClick={() => setTechnicianOperations(true)}
            disabled={isLoading}
            startIcon={<DoneIcon style={{marginRight: 8}}/>}
            style={ technicianView ? {
                marginLeft: '16px',
                display: "flex",
                fontWeight: 'bold',
                fontSize: 12,
                border: '1px solid #00223D',
                textTransform: 'uppercase'
            } : {
                color: "#00223D",
                fontSize: 12,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                backgroundColor: '#FFF',
                "&:hover": {
                    background: "#EBEBEB"
                },
                border: '1px solid #00223D',
                paddingTop: 8
            }}
        >Technician Operations</Button>
        <Dialog open={technicianOperations}>
            <DialogTitle>
                Technician Operations
            </DialogTitle>
            <DialogContent>
                <Grid item xs={12} container>
                    <Grid item container xs={12}>
                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    size={'small'}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label={"Arrival Time"}
                                    format="MM/dd/yyyy hh:mm"
                                    value={arrivalTime}
                                    onChange={(event, value) => {
                                        setArrivalTime(moment(value))
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                        'id': 'completed_date'
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={6} style={{display: "flex", justifyContent: "flex-end"}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    size={'small'}
                                    margin="normal"
                                    inputVariant="outlined"
                                    label={"Departure Time"}
                                    format="MM/dd/yyyy hh:mm"
                                    value={departureTime}
                                    onChange={(event, value) => {
                                        setDepartureTime(moment(value))
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                        'id': 'completed_date'
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={12} sm={6}>
                            <Typography variant={"h6"}
                                        style={{paddingLeft: 8, paddingTop: 6, display: "flex"}}>
                                Delivery Type
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} style={{
                            display: "flex",
                            alignContent: "flex-end",
                            alignItems: "flex-end",
                            justifyContent: "flex-end"
                        }}>
                            <ButtonGroup disableElevation variant="contained" color="default">
                                <Button color={deliveryType === "contact" && "primary"}
                                        onClick={() => setDeliveryType("contact")}
                                >
                                    Contact
                                </Button>
                                <Button
                                    color={deliveryType === "non_contact" && "secondary"}
                                    onClick={() => setDeliveryType("non_contact")}
                                >
                                    Non Contact
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container style={{paddingTop: 16}}>
                        <Grid item xs={9}>
                            <Typography variant={"h6"}
                                        style={{paddingLeft: 8, paddingTop: 6, display: "flex"}}>
                                Smoking Signs
                            </Typography>
                        </Grid>
                        <Grid item xs={3} style={{
                            display: "flex",
                            alignContent: "flex-end",
                            alignItems: "flex-end",
                            justifyContent: "flex-end"
                        }}>
                            <ButtonGroup disableElevation variant="contained" color="default">
                                <Button color={smokingSigns === "yes" && "primary"}
                                        onClick={() => setSmokingSigns("yes")}
                                >
                                    Yes
                                </Button>
                                <Button
                                    color={smokingSigns === "no" && "secondary"}
                                    onClick={() => setSmokingSigns("no")}
                                >
                                    No
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container style={{paddingTop: 32}}>
                        <Grid item xs={12}>
                            <Typography variant={'h6'}
                                        style={{display: "flex", textAlign: "left"}}> Questionnaire and
                                Rental Agreement: </Typography>
                        </Grid>
                        <Grid item xs={12} container justify={"center"} alignItems={"center"}>
                            <Grid item xs={9}>
                                <Typography variant={"subtitle1"}
                                            style={{paddingLeft: 8, display: "flex", textAlign: "left"}}>
                                    I understand that it is my responsibility to call for any equipment
                                    malfunction.
                                </Typography>
                            </Grid>
                            <Grid item xs={3} style={{
                                display: "flex",
                                alignContent: "flex-end",
                                alignItems: "flex-end",
                                justifyContent: "flex-end"
                            }}>
                                <ButtonGroup disableElevation variant="contained" color="default">
                                    <Button color={question1 === "yes" && "primary"}
                                            onClick={() => setQuestion1("yes")}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        color={question1 === "no" && "secondary"}
                                        onClick={() => setQuestion1("no")}
                                    >
                                        No
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container justify={"center"} alignItems={"center"}>
                            <Grid item xs={7}>
                                <Typography variant={"subtitle1"}
                                            style={{
                                                paddingLeft: 8,
                                                paddingTop: 6,
                                                display: "flex",
                                                textAlign: "left"
                                            }}>
                                    I understand that only equipment personnel may disassemble equipment.
                                </Typography>
                            </Grid>
                            <Grid item xs={5} style={{
                                display: "flex",
                                alignContent: "flex-end",
                                alignItems: "flex-end",
                                justifyContent: "flex-end"
                            }}>
                                <ButtonGroup disableElevation variant="contained" color="default">
                                    <Button color={question2 === "yes" && "primary"}
                                            onClick={() => setQuestion2("yes")}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        color={question2 === "no" && "secondary"}
                                        onClick={() => setQuestion2("no")}
                                    >
                                        No
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container justify={"center"} alignItems={"center"}>
                            <Grid item xs={9}>
                                <Typography variant={"subtitle1"}
                                            style={{
                                                paddingLeft: 8,
                                                paddingTop: 6,
                                                display: "flex",
                                                textAlign: "left"
                                            }}>
                                    I have been thoroughly trained and fully understand how to safely
                                    operate the above
                                    equipment.
                                </Typography>
                            </Grid>
                            <Grid item xs={3} style={{
                                display: "flex",
                                alignContent: "flex-end",
                                alignItems: "flex-end",
                                justifyContent: "flex-end"
                            }}>
                                <ButtonGroup disableElevation variant="contained" color="default">
                                    <Button color={question3 === "yes" && "primary"}
                                            onClick={() => setQuestion3("yes")}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        color={question3 === "no" && "secondary"}
                                        onClick={() => setQuestion3("no")}
                                    >
                                        No
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => {
                        setTechnicianOperations(false);
                        updateTechnicianOperations();
                    }}>
                    Save
                </Button>
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => {
                        setTechnicianOperations(false);
                    }}
                >
                    No, Return to Order
                </Button>
            </DialogActions>
        </Dialog>
    </Grid>
}