import React, {useEffect} from 'react';
import MaterialTable from 'material-table';
import Button from "@material-ui/core/Button";
import {Tooltip} from "@material-ui/core";
import {placeholders} from "../../../../../constants/inputs/paceholders";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

export function Table({items, columns, order, setOrder, handleClearAll, isMobile, handleRemove}) {
    const [data, setData] = React.useState([]);

    useEffect(() => {
        setData(items);
    }, [items]);

    return (
        <div className={'generaltable'} style={{paddingBottom: '8px'}}>
            {isMobile ? <MobileTable data={items} handleRemove={handleRemove}/> : (
                <MaterialTable
                title={'Exchange Items:'}
                isLoading={false}
                localization={{
                body: {
                emptyDataSourceMessage: placeholders.empty_cart
            }
            }}
                options={{
                paging: false,
                search: false,
                headerStyle: {
                backgroundColor: '#F2F6F7',
                color: '#2E3236',
                height: 20,
                '&:hover': {color: '#2E3236'}
            }
            }}
                columns={columns}
                data={data}
                />
                )}
            {items.length !== 0 && (
                <div style={{
                    alignContent: 'right',
                    justifyContent: 'flex-end',
                    display: 'flex',
                    paddingTop: '10px',
                    width: '100%'
                }}>
                    <Tooltip title={'Remove all items'}>
                        <Button
                            variant={"outlined"}
                            color={"default"}
                            onClick={handleClearAll}
                        >Clear items</Button>

                    </Tooltip>
                </div>
            )}
        </div>
    );
}

function MobileTable({data, handleRemove}){
    return (
        <Grid container style={{paddingBottom:16}}>
            <Grid item xs={12} style={{padding:'16px 8px 8px 0px', fontSize:20, fontWeight:'bold'}}>
                Exchange Cart
            </Grid>
            <Grid container style={{border:'1px solid #F2F5F7'}}>
                <Grid container justify={'space-between'} style={{background:'#F2F5F7', padding:'12px 16px', borderRadius:4}}>
                    <Grid item xs={6} style={{fontSize:12, fontWeight:'bold'}}>
                        Item
                    </Grid>
                    <Grid item xs={6} style={{display:'flex', justifyContent:'flex-end'}}>
                        <Grid item style={{fontSize:12,minWidth:60, fontWeight:'bold', textAlign:'left'}}>
                            Action
                        </Grid>
                        <Grid item style={{fontSize:12,minWidth:50, fontWeight:'bold', textAlign:'left'}}>
                            Remove
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container style={{maxHeight:160, overflowY:'scroll'}}>
                    {data.length === 0 && <Grid container style={{height:50}} alignContent={'center'} alignItems={'center'} justify={'center'}>Cart is empty</Grid>}
                    {data.map((item, idx) => {
                        return (
                            <Grid container justify={'space-between'} alignItems={'center'} alignContent={'center'} style={{minHeight:30,background:'white', padding:'8px 16px', borderBottom:'1px solid #E0E0E0'}}>
                                <Grid item xs={6} style={{fontSize:12, fontWeight:'bold'}}>
                                    <Grid container>
                                        <Grid item xs={12} style={{paddingBottom:4}}>
                                            {item.item_name ? item.item_name : item.name}
                                        </Grid>
                                        <Grid item xs={12} style={{paddingBottom:4, fontWeight:'400'}}>
                                            <span style={{color:'gray'}}>Authorization:</span> <strong>{item.approval ? <span style={{color:'red'}}>Yes</span> : "No"}</strong>
                                        </Grid>
                                        <Grid item xs={12} style={{paddingBottom:4, fontWeight:'400'}}>

                                            <div style={{textAlign: 'left'}}>
                                                <span style={{color:'gray'}}>Clinical Settings:</span>
                                                {item.special_item_info ? <div>
                                                    {item.special_item_info.min_range && (
                                                        <React.Fragment>
                                                            <div> {`Min. ${item.special_item_info.min_range}`} </div>
                                                            <div> {`Max. ${item.special_item_info.max_range}`} </div>
                                                        </React.Fragment>
                                                    )}
                                                    {item.special_item_info.isContinuous && (
                                                        <div> {`With continuous oxygen therapy`} </div>
                                                    )}
                                                    {item.special_item_info.humidifier_for_compressor && (
                                                        <div> {`Humidifier bottle: ${item.special_item_info.humidifier_for_compressor}%`} </div>
                                                    )}
                                                    {item.special_item_info.weight && (
                                                        <div> {`Patient Weight: ${item.special_item_info.weight}`} </div>
                                                    )}
                                                    {item.special_item_info.size_lift && (
                                                        <div> {`Size: ${item.special_item_info.size_lift}`} </div>
                                                    )}
                                                </div> : '-'}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} style={{display:"flex", justifyContent:'flex-end'}}>
                                    <Grid item style={{fontSize:12, fontWeight:'bold', minWidth:60,alignItems:'center', alignContent:'center', textAlign:'center', display:'flex'}}>
                                        {item.action}
                                    </Grid>
                                    <Grid item style={{fontSize:12, fontWeight:'bold', minWidth:50,alignItems:'center', alignContent:'center', textAlign:'center', display:'flex'}}>
                                        {item.action === 'Pickup' && (
                                            <IconButton
                                                onClick={(event) => {
                                                    const obj = {
                                                        ...event,
                                                        target:{
                                                            value: item
                                                        }
                                                    };
                                                    handleRemove(obj);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}
