import React, {useEffect, useState} from 'react';
import {endpoints} from "../../../../constants/endpoints";
import {getTokenInformation} from "../../../../common/utils/utils";
import {useHistory} from "react-router-dom";
import {utilsTokenIdentifiers} from "../../../../constants/inputs/values";
import {successMessage} from "../../../../constants/status/status";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import {parseTimeWithUserTimezone} from "../../../../common/timeHandler/timeHandler";
import moment from "moment";
import {Checkbox, Dialog, DialogContent} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Select from "react-select";
import {useSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

export const PrescriptionItemsTable = ({hospiceId, patientId, regionId}) => {

    const [selectedContractId, setSelectedContractId] = useState(null);
    const [contractIds, setContractIds] = useState([]);
    const [prescriptionItemList, setPrescriptionItemList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const navigate = useHistory();
    const [openAuditModal, setOpenAuditModal] = useState(false);
    const [selectedLogs, setSelectedLogs] = useState([]);

    const getContractId = async (dmeId) => {
        const promise = await fetch(endpoints.getHospiceContractId, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(navigate, utilsTokenIdentifiers.accessToken)
            },
            body: JSON.stringify({
                dmeId: dmeId,
                hospiceId: hospiceId,
                regionId: regionId,
            })
        })
        const response = await promise.json();
        if (response.status === successMessage.status) {
            setSelectedContractId(response.data[0].contract_id);
            getPrescriptionItems(response.data[0].contract_id)
        }
    }

    const getPrescriptionItems = async (contractId) => {
        setIsLoading(true);
        setPrescriptionItemList([]);
        const promise = await fetch(endpoints.getPatientPrescriptionItems, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(navigate, utilsTokenIdentifiers.accessToken)
            },
            body: JSON.stringify({
                contractId: contractId,
                patientId: patientId,
            })
        })
        const response = await promise.json();
        if (response.status === successMessage.status) {
            setPrescriptionItemList(response.data);
        }
        setIsLoading(false);
    }

    const getContractedDmeProviders = async () => {
        const promise = await fetch(endpoints.getHospiceContractedProviders + hospiceId, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(navigate, utilsTokenIdentifiers.accessToken)
            },
        })
        const response = await promise.json();
        if (response.status === successMessage.status) {
            const {data} = response;
            if (data.length === 1) {
                getContractId(data[0].dme_id);
            }
            if (data.length > 1) {
                setContractIds(data.map(provider => {
                    return {
                        label: provider.dme_name,
                        value: provider.dme_id,
                    }
                }))
            }
        }

    }

    const addNewPrescriptionItemLog = async (special_item_id, received) => {
        const {user_id} = getTokenInformation(navigate, utilsTokenIdentifiers.payload);
        const promise = await fetch(endpoints.insertPrescriptionItemLogs, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getTokenInformation(navigate, utilsTokenIdentifiers.accessToken)
            },
            body: JSON.stringify({
                user_id: user_id,
                special_item_id: special_item_id,
                received: received
            })
        })
        const response = await promise.json();
        if (response.status === successMessage.status) {
            enqueueSnackbar("Prescription item log updated.", {variant: "success"});
        }
        await getPrescriptionItems(selectedContractId);
    }

    const columns = [
        {title: 'Rx Item Name', field: 'name', width: 200},
        {
            title: 'Rx Delivery Date', field: 'deliveryDate', width: 200,
            render: (rowData) => {
                return rowData.deliveryDate ? parseTimeWithUserTimezone(rowData.deliveryDate) : ""
            }
        },
        {
            title: 'Rx Pickup Date', field: 'pickupDate', width: 200,
            render: (rowData) => {
                return rowData.pickupDate ? parseTimeWithUserTimezone(rowData.pickupDate) : ""
            }
        },
        {
            title: 'Rx Expiration Date', field: 'expiryDate', width: 200,
            render: (rowData) => {
                return rowData.deliveryDate ? parseTimeWithUserTimezone(moment(rowData.deliveryDate).add(1, 'year')) : ""
            }
        },
        {
            title: 'Received', field: 'expiryDate', width: 400,
            render: (rowData) => <Grid container>
                <Grid item xs={6}>
                    <Checkbox
                        color={"primary"}
                        checked={rowData.received}
                        onClick={() => addNewPrescriptionItemLog(rowData.item_id, !rowData.received)}/>
                </Grid>
                <Grid item xs={6}>
                    <Button variant={"outlined"} color={"primary"}
                            onClick={() => {
                                setOpenAuditModal(true);
                                setSelectedLogs(rowData.logs);
                            }}>
                        Audit
                    </Button>
                </Grid>
            </Grid>
        },

    ]

    useEffect(() => {
        if (hospiceId) {
            getContractedDmeProviders();
        }
    }, [hospiceId])

    return <Grid item xs={12}>
        {contractIds.length === 0 && selectedContractId && <Grid item xs={12}>
            <MaterialTable
                isLoading={isLoading}
                columns={columns}
                data={prescriptionItemList}
                title={"Rx Prescription Items"}
                options={{
                    showTitle: true,
                }}
            />
        </Grid>
        }
        {contractIds.length > 0 &&
            <Grid item xs={12} container justify={"flex-start"} alignItems={"flex-start"} spacing={2}>
                <Grid item xs={2} style={{zIndex: 100}}>
                    <Select
                        isLoading={contractIds.length === 0}
                        options={contractIds}
                        value={contractIds.find(contract => contract.dme_id === selectedContractId)}
                        onChange={(e) => {
                            setSelectedContractId(e.value);
                            getContractId(e.value);
                        }}
                    />
                </Grid>
                {
                    selectedContractId && <Grid item xs={12}>
                        <MaterialTable
                            isLoading={isLoading}
                            columns={columns}
                            data={prescriptionItemList}
                            title={"Prescription Items"}
                            options={{
                                showTitle: false,
                            }}
                        />
                    </Grid>
                }
            </Grid>
        }
        <Dialog
            open={openAuditModal}
            onClose={() => {
                setOpenAuditModal(false);
                setSelectedLogs([]);
            }}
            fullWidth
            maxWidth={"md"}
        >
            <DialogContent>
                <Grid container justify={"space-between"} alignContent={"center"} alignItems={"center"}>
                    <Grid item style={{fontSize: 24, fontWeight: 'bold'}}>
                        Prescription Audit
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => {
                            setOpenAuditModal(false);
                            setSelectedLogs([]);
                        }}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container style={{minWidth: 320}}>
                    <Grid item xs={4}>
                        <h4>Date</h4>
                    </Grid>
                    <Grid item xs={4}>
                        <h4>User</h4>
                    </Grid>
                    <Grid item xs={4}>
                        <h4>Received</h4>
                    </Grid>
                </Grid>
                {selectedLogs.map((log, key) => <React.Fragment key={key}>
                    <Grid container style={{
                        fontWeight: 400,
                        padding: 8,
                        border: '1px solid lightgrey',
                        marginBottom: 8,
                        borderRadius: 4
                    }}>
                        <Grid item xs={4}>
                            <div>
                                {parseTimeWithUserTimezone(log.created_date)}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                {log.name}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div>
                                {log.received === true ? "Marked as received" : "Marked as unreceived"}
                            </div>
                        </Grid>
                    </Grid>
                </React.Fragment>)}

            </DialogContent>
        </Dialog>
    </Grid>
}