import React, {useEffect, useState} from "react";
import {Card, Slide} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import FaceIcon from '@material-ui/icons/Face';
import RoomIcon from '@material-ui/icons/Room';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import {formatNumber} from "../../../../common/utils/utils";
import Button from "@material-ui/core/Button";
import {checkPermissions} from "../../../../common/permissions/checkPermissions";
import {permissions} from "../../../../constants/permissions/permissions";
import Tooltip from "@material-ui/core/Tooltip";
import {tooltips} from "../../../../constants/inputs/tooltips";
import EditIcon from "../../../../assets/edit-button.svg";
import DeletePatient from "../deletePatient/deletePatient";
import makeStyles from "@material-ui/core/styles/makeStyles";
import NewCreateOrder from "../../../createOrder/newCreateOrder";
import {useHistory} from "react-router-dom";
import {NewRequest} from "../../../../common/newRequest/newRequest";
import {endpoints} from "../../../../constants/endpoints";
import AddIcon from "@material-ui/icons/Add";
import {IconAlertActions, IconAlerts, IconAlertTypes} from "../../../../common/alerts/IconAlerts/IconAlerts";


const textColor = (status) => {
    switch (status?.toLowerCase()) {
        case "active":
            return "#4F78A0";
        case "revoked":
        case "expired":
            return "#f50057";
        case "discharged":
        case "inactive":
        default:
            return 'rgb(169, 169, 169)';
    }
}

export const generateStatusStyles = (status)=> {
    switch (status?.toLowerCase()) {
        case "active":
            return {
                color:"#4F78A0",
                backgroundColor:"rgba(79,120,160,0.15)",
                padding:'4px 8px',
                borderRadius:4
            };
        case "revoked":
        case "expired":
            return {
                color:"#ec0000",
                backgroundColor:"rgba(236,0,0,0.15)",
                padding:'4px 8px',
                borderRadius:4
            };
        case "discharged":
        case "inactive":
        default:
            return {
                color:'rgb(141,140,140)',
                backgroundColor:"rgba(140, 140, 140,0.15)",
                padding:'4px 8px',
                borderRadius:4
            };
    }
}

export const PatientCard = (props) => {
    const {
        status = "Active",
        medical_record = "#1234567890",
        first_name = "John",
        mi = "",
        last_name = "Doe",
        phone_number = "123-456-7890",
        address1 = "1234 st",
        address2 = "King Street",
        city = "Arlington",
        state = "AL",
        zip = "12346",
        patient_id = 982
    } = props?.patient;
    const classes = useStyles();
    const history = useHistory();

    const [hasItems, setHasItems] = useState(props.patient !== 'Active');

    const checkPatientInventory = async (patient_id) => {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(endpoints.pickupItems + "true" + '/' + patient_id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        });
        let response = await promise.json();
        let {data} = response;
        if (data && data.length === 0) {
            setHasItems(false)
        } else {
            setHasItems(true)
        }
    };

    useEffect(()=> {
        if (props.patient.status !== 'Active'){
            checkPatientInventory(props.patient.patient_id)
        }
    },[])

    function generateName(patient){
        return `${patient.first_name} ${patient.MI ? patient.MI : ""} ${patient.last_name}`
    }
    function generateAddress(patient){
        return `${patient.address1} ${patient.address2 ? patient.address2 : ""}, ${patient.city} ${patient.state} ${patient.zip}`
    }


    return (
        <Slide direction="up" in mountOnEnter unmountOnExit>
            <Card
                  style={{boxShadow:' 0 1px 2px 0 rgba(0,0,0,0.32)'}}
            >
                    <Grid container style={{padding:12, paddingTop:8}}>
                        <Grid item xs={12}>
                            <Grid container justify={'space-between'} alignContent={'center'} alignItems={'center'}>
                                <Typography variant={"overline"} style={{fontSize:14}}><span style={{color:'gray'}}>MRN:</span> #{medical_record} </Typography>

                                <Typography style={{fontWeight: 600}}><span style={generateStatusStyles(status)}>{status}</span></Typography>

                            </Grid>
                        </Grid>
                        <Grid item container xs={6} spacing={1} alignItems={"flex-start"} justify={"flex-start"}>
                            <Grid item xs={12} style={{display: "flex"}}>
                                <Chip
                                    classes={{icon: classes.iconColorPrimary,
                                        root: classes.root,
                                        label: classes.label
                                    }}
                                    icon={<FaceIcon/>}
                                    label={`${last_name}, ${first_name} ${mi}`}
                                    onClick={() => {
                                        history.push({
                                            pathname: `/patients/patientProfile/${patient_id}`,
                                            patient_id: patient_id
                                        })
                                    }}
                                    style={{
                                        border:'none',
                                        backgroundColor:'rgba(64,88,108,0.10)',
                                        fontWeight:'bold',
                                        textAlign:'left'
                                        // bowShadow: '0 1px 2px 0 rgba(0,0,0,0.05)'
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} style={{display: "flex"}}>
                                <a href={`tel: ${phone_number}`}><Chip
                                    color={"primary"}
                                    icon={<ContactPhoneIcon/>}
                                    label={`${formatNumber(phone_number, "parentheses")}`}
                                    variant={"outlined"}
                                    classes={{
                                        root: classes.root,
                                        label: classes.label,
                                        icon: classes.iconColorPrimary,
                                    }}
                                    style={{
                                        border:'none',
                                        backgroundColor:'rgba(64,88,108,0.10)',
                                        // bowShadow: '0 1px 2px 0 rgba(0,0,0,0.05)'
                                    }}
                                />
                                </a>
                            </Grid>
                            <Grid item xs={12} style={{display: "flex"}}>
                                <Chip
                                    classes={{
                                        root: classes.root,
                                        label: classes.label,
                                        icon: classes.iconColorPrimary,
                                    }}
                                    color={"primary"}
                                    icon={<RoomIcon/>}
                                    label={`${address1} ${address2} ${city} ${state} ${zip}`}
                                    variant={"outlined"}
                                    style={{
                                        border:'none',
                                        backgroundColor:'rgba(64,88,108,0.10)',
                                        textAlign:'left'
                                        // bowShadow: '0 1px 2px 0 rgba(0,0,0,0.05)'
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop:8}}>
                            <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end", paddingBottom:4}}>
                                {checkPermissions([permissions.update_patients]) && (
                                    <Tooltip title={tooltips.editPatient}>
                                        <Button
                                            size="normal"
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                history.push({pathname: '/patients/edit', patient_id: patient_id})
                                            }}
                                        >
                                            <img
                                                src={EditIcon}
                                                alt="logo"
                                                width='22'
                                                height='22'
                                                // className={classes.simpleLogo}
                                                style={{cursor: 'pointer'}}
                                            />
                                        </Button>
                                    </Tooltip>
                                )}
                            </Grid>
                            <Grid item xs={12} style={{display: "flex", justifyContent: "flex-end", paddingRight: 8, paddingTop:4}}>
                                {checkPermissions([permissions.delete_patients], 'DME') && (
                                    <DeletePatient rowData={props.patient}/>
                                )}
                            </Grid>
                            {(props.patient.status !== 'Active' && hasItems) && (
                                <Grid item xs={12}  style={{display: "flex", justifyContent: "flex-end", paddingRight: 16, paddingTop:4}} data-html2canvas-ignore="true">
                                    <IconAlerts iconType={IconAlertTypes.patientFlag} action={IconAlertActions.anchor}
                                                data={props.patient.status}/>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} style={{paddingTop: 8, display: "flex", justifyContent:'center'}}>
                            {hasItems || props.patient.status === 'Active' ? (
                                <NewRequest
                                    user={props.patient}
                                    getPatientOrders={props.getPatientOrders}
                                    isPace={props.patient?.ispace}
                                    address={generateAddress(props.patient)}
                                    name={generateName(props.patient)}
                                    fullWidth={true}
                                    hasItems={hasItems}
                                    // autoPickupTrigger={autoPickupTrigger}
                                    // autoPickupReason={autoPickupReason}
                                />
                            ) : (
                                <div style={{
                                    color:'white',
                                    textTransform:'uppercase',
                                    fontWeight:'bold',
                                    maxWidth:420,
                                    width:'100%'
                                }}>
                                    <Button fullWidth disabled variant={'contained'} style={{fontWeight:'bold'}}>
                                        <AddIcon style={{marginRight:4}}/>
                                        NEW REQUEST
                                    </Button>
                                </div>

                            )
                            }

                        </Grid>
                    </Grid>
            </Card>
        </Slide>
    )
};

const useStyles = makeStyles((theme) => ({
    iconColorPrimary: {
        color: "rgba(0,34,61,0.75)",
    },
    label: {
        display: "flex",
        flexWrap: "wrap",
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        textOverflow: 'clip',
    },
    root: {
        height: "100%",
        display: "flex",
        boxShadow:'0 1px 2px 0 hsl(207, 26%, 88%)',
        padding:'6px 4px',
        borderRadius:4,
    }

}));
