import React, {useEffect, useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {endpoints} from "../../../constants/endpoints";
import Typography from "@material-ui/core/Typography";
import {formatNumber} from "../../../common/utils/utils";
import {orderTypes} from "../../../constants/inputs/values";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import {sectionTitles} from "../../../constants/names/titles";
import moment from "moment";
import {status} from "../../../constants/status/status";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import SignaturePad from "react-signature-canvas";
import Button from "@material-ui/core/Button";
import {useHistory, useParams} from "react-router-dom";
import TechnicianAssetSelect from "./components/TechnicianAssetSelect";
import {useSnackbar} from "notistack";
import DoneIcon from "@material-ui/icons/Done";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {TechnicianOperations} from "./components/TechnicianOperations";

export default function TechnicianOrderView() {
    const classes = useStyles();
    const history = useHistory();
    const [orderType, setOrderType] = useState("");
    const [fullOrderId, setFullOrderId] = useState("");
    const [authenticationStatus, setAuthenticationStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [caregiverObject, setCaregiverObject] = useState([{}, {}, {}]);
    const [patientAddress, setPatientAddress] = useState("");
    const [order, setOrder] = useState({});
    const [imageURL, setImageURL] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const {technicianId, orderId} = useParams()
    const {enqueueSnackbar} = useSnackbar();
    const [missingAssetsDialog, setMissingAssetsDialog] = useState(false);
    const [technicianDmes, setTechnicianDmes] = useState([]);

    const sigCanvas = useRef({});
    const save = () => setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    const clear = () => sigCanvas.current.clear();

    const getVisualOrderId = (orderType, orderId) => {
        switch (orderType) {
            case orderTypes.delivery:
                return "D100" + orderId;
            case orderTypes.pickup:
                return "P200" + orderId;
            case orderTypes.exchange:
                return "S300" + orderId;
            default:
                return "";
        }
    }

    const formCaregiverArray = (data) => {
        let array = [{}, {}, {}];
        if (data && data.caregivers?.length > 0) {
            data.caregivers.map(item => {
                if (item.type === "first") {
                    array[0] = item;
                }
                if (item.type === "second") {
                    array[1] = item;
                }
                if (item.type === "third") {
                    array[2] = item;
                }
            })
        }
        setCaregiverObject(array);
    }

    const formatAddressToMapsLinkStyle = (data) => {
        let address = ""
        data.address1 && (address += data.address1);
        data.address2 && (address += data.address2);
        data.city && (address += `+${data.city}`);
        data.state && (address += `+${data.state}`);
        data.zip && (address += `+${data.zip}`);
        setPatientAddress(address);
    }


    const authenticateUser = async () => {
        let urlParts = window.location.href.split('/');
        const urlToken = urlParts[urlParts.length - 3];
        const orderId = getVisualOrderId(urlParts[urlParts.length - 2], urlParts[urlParts.length - 1]);
        const technicianId = urlParts[urlParts.length - 4];
        const authenticationPromise = await fetch(`${endpoints.loginForTechnician}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({orderId: orderId, token: urlToken, technicianId: technicianId})
        })
        if (authenticationPromise.status === status.success) {
            const authenticationResponse = await authenticationPromise.json();
            setAccessToken(authenticationResponse.accessToken);
            setTechnicianDmes(authenticationResponse.dmes);
            await getOrderDetails(authenticationResponse.accessToken);
            setAuthenticationStatus(true);
        } else {
            setAuthenticationStatus(false);
            setIsLoading(false);
        }
    }

    const checkOrderAssetCompletion = () => {
        let items = order.items;
        if (orderType === orderTypes.exchange) {
            items = [...order?.deliveredItems, ...order?.pickupItems]
        }
        let missingAssets = false;
        for (const item of items) {
            if (!item.asset) {
                missingAssets = true;
            }
        }
        setMissingAssetsDialog(missingAssets);
        if (!missingAssets) {
            updateOrderStatus()
        }
    }

    const updateOrderStatus = async () => {
        setIsLoading(true);
        let status = {
            status: "Completed",
        }
        let historyObj = {
            history: [{
                description: `Status Changed: Assigned Technician changed order status to "Completed" on ${moment(new Date()).utc().format("LLL")}`,
            }],
        }
        let updateEndpoint, historyEndpoint;
        if (orderType === orderTypes.delivery) {
            status["delivery_order_id"] = order.delivery_order_id;
            historyObj["deliveryOrderId"] = order.delivery_order_id;
            updateEndpoint = endpoints.updateDeliveryOrderStatus;
            historyEndpoint = endpoints.addDeliveryOrderHistory;

        }
        if (orderType === orderTypes.pickup) {
            status["pickup_order_id"] = order.pickup_order_id;
            historyObj["pickupOrderId"] = order.pickup_order_id;
            updateEndpoint = endpoints.updatePickupOrderStatus
            historyEndpoint = endpoints.addPickupOrderHistory;
        }
        if (orderType === orderTypes.exchange) {
            status["exchange_order_id"] = order.exchange_order_id;
            historyObj["exchangeOrderId"] = order.exchange_order_id;
            updateEndpoint = endpoints.updateExchangeOrderStatus;
            historyEndpoint = endpoints.addExchangeOrderHistory;
        }
        try {
            const orderStatusPromise = await fetch(updateEndpoint, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                },
                body: JSON.stringify(status)
            })
            const orderStatusResponse = await orderStatusPromise.json();
            if (orderStatusResponse.status === "success") {
                await fetch(historyEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken
                    },
                    body: JSON.stringify(historyObj)
                })
            }
        } catch (e) {
            enqueueSnackbar("Something happened while updating order status.", {
                persist: false,
                variant: "warning",
            });
        }
        history.push('/refresh');

        setIsLoading(false);
    }

    const getOrderDetails = async (token) => {
        setIsLoading(true);
        let urlParts = window.location.href.split('/');
        const urlOrderType = urlParts[urlParts.length - 2];
        setOrderType(urlOrderType);
        const orderId = urlParts[urlParts.length - 1];
        setFullOrderId(getVisualOrderId(urlParts[urlParts.length - 2], urlParts[urlParts.length - 1]))
        let promise, response;
        switch (urlOrderType) {
            case orderTypes.delivery:
                promise = await fetch(`${endpoints.getDeliveryOrderById}${orderId}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                });
                response = await promise.json();
                if (response.status === "success") {
                    setOrder(response.data[0]);
                    formCaregiverArray(response.data[0]);
                    formatAddressToMapsLinkStyle(response.data[0]);
                }
                break;
            case orderTypes.pickup:
                promise = await fetch(`${endpoints.getPickupOrderById}${orderId}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                })
                response = await promise.json();
                if (response.status === "success") {
                    setOrder(response.data[0]);
                    formCaregiverArray(response.data[0]);
                    formatAddressToMapsLinkStyle(response.data[0]);
                }
                break;
            case orderTypes.exchange:
                promise = await fetch(`${endpoints.getExchangeOrderById}${orderId}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                })
                response = await promise.json();
                if (response.status === "success") {
                    setOrder(response.data[0]);
                    formCaregiverArray(response.data[0]);
                    formatAddressToMapsLinkStyle(response.data[0]);
                }
                break;
            default:
                break;
        }
        setIsLoading(false);
    }

    useEffect(() => {
        authenticateUser();
    }, [])

    if (isLoading) {
        return (
            <div style={{height: 600, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <CircularProgress/>
            </div>
        )
    }
    if (authenticationStatus) {
        return (
            <Grid container className={classes.padding16} direction={'column'}>
                <Grid item xs={12}>
                    <Typography className={classes.h1}>
                        Technician Order
                    </Typography>
                </Grid>
                <Grid item xs={12} container>
                    <Grid item xs={12} sm={8} md={3} lg={2}>
                        <Typography variant={"h6"}>
                            Order#: {Object.keys(order).length > 0 && fullOrderId}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} md={3} lg={2}>
                        <Typography variant={"h6"}>
                            Order Type: {Object.keys(order).length > 0 && orderType.toUpperCase()}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={8} md={6} lg={4} className={classes.padding16}>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        className={classes.tableHeader}>{sectionTitles.patientInformation}</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        <strong>MR#</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {order?.medical_record}
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        <strong>Patient Name</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {order?.first_name ? order?.first_name : order?.patient_first_name} {order?.last_name ? order?.last_name : order?.patient_last_name}
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        <strong>Phone Number</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        {order?.phone_number ? <a href={`tel:${order?.phone_number}`}
                                                                  className={classes.link}>{formatNumber(order?.phone_number)}</a> :
                                            <a href={`tel:${order?.patient_phone}`}
                                               className={classes.link}> {formatNumber(order?.patient_phone)}</a>}
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        <strong>Address</strong>
                                    </StyledTableCell>
                                    <StyledTableCell align="left">
                                        <a href={`https://www.google.com/maps/search/?api=1&query=${patientAddress}`}
                                           className={classes.link}
                                           target="_blank"
                                           rel="noopener noreferrer"
                                        >
                                            {order?.address1}{order?.address2 && `, ${order?.address2}`}{order?.city && `, ${order?.city},`} {order?.state && `${order?.state},`} {order?.zip && `${order?.zip}`}</a>
                                    </StyledTableCell>
                                </StyledTableRow>
                                {Object.keys(caregiverObject[0]).length > 0 && <React.Fragment>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <strong>{sectionTitles.primaryCaregiver} Name</strong>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {caregiverObject[0].caregiver_first_name} {caregiverObject[0].caregiver_last_name}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <strong>{sectionTitles.primaryCaregiver} Phone</strong>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {<a href={`tel:${caregiverObject[0]?.caregiver_phone_number}`}
                                                className={classes.link}>{formatNumber(caregiverObject[0].caregiver_phone_number)}</a>}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </React.Fragment>}
                                {Object.keys(caregiverObject[1]).length > 0 && <React.Fragment>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <strong>{sectionTitles.secondaryCaregiver} Name</strong>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {caregiverObject[1].caregiver_first_name} {caregiverObject[1].caregiver_last_name}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <strong>{sectionTitles.secondaryCaregiver} Phone</strong>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {<a href={`tel:${caregiverObject[0]?.caregiver_phone_number}`}
                                                className={classes.link}>{formatNumber(caregiverObject[0].caregiver_phone_number)}</a>}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </React.Fragment>}
                                {Object.keys(caregiverObject[2]).length > 0 && <React.Fragment>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <strong>{sectionTitles.tertiaryCaregiver} Name</strong>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {caregiverObject[2].caregiver_first_name} {caregiverObject[2].caregiver_last_name}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" scope="row">
                                            <strong>{sectionTitles.tertiaryCaregiver} Phone</strong>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {<a href={`tel:${caregiverObject[2]?.caregiver_phone_number}`}
                                                className={classes.link}>{formatNumber(caregiverObject[2].caregiver_phone_number)}</a>}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </React.Fragment>}
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        <strong>Patient/Caregiver signature</strong>
                                    </StyledTableCell>
                                    <div className={classes.signatureContainer}>
                                        <SignaturePad
                                            penColor="#000"
                                            ref={sigCanvas}
                                            canvasProps={{
                                                width: 300,
                                                height: 200,
                                                className: "signatureCanvas"
                                            }}
                                        />
                                    </div>
                                    {imageURL ? (
                                        <img
                                            src={imageURL}
                                            alt="my signature"
                                            style={{
                                                display: "block",
                                                margin: "0 auto",
                                                border: "1px solid black",
                                                width: "150px"
                                            }}
                                        />
                                    ) : null}
                                    <Button onClick={save}>Save</Button>
                                    <Button onClick={clear}>Clear</Button>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <StyledTableCell component="th" scope="row">
                                        <strong>Printed Name: </strong>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item container direction={"column"} xs={12} sm={8} md={6} lg={4} className={classes.padding16}>
                    <Grid item xs={12}>
                        <Typography variant={'h6'} style={{textAlign: "start", padding: 16}}>
                            {orderType === orderTypes.pickup || orderType === orderTypes.pickup ? "Items" : "Delivery Items"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>{sectionTitles.itemName}</StyledTableCell>
                                        <StyledTableCell>{sectionTitles.itemAsset}</StyledTableCell>
                                        <StyledTableCell align="left">{sectionTitles.clinicalNotes}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(orderType === orderTypes.delivery || orderType === orderTypes.pickup) && order?.items?.map((row) => (
                                        <StyledTableRow
                                            key={row.special_item_id ? row.special_item_id : row.inventory_item_id}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.name ? row.name : row.item_name}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <TechnicianAssetSelect
                                                    item={row}
                                                    hasAsset={!!row.asset}
                                                    setOrder={setOrder}
                                                    order={order}
                                                    accessToken={accessToken}
                                                    technicianId={technicianId}
                                                    technicianDmes={technicianDmes}
                                                />
                                            </StyledTableCell>
                                            {orderType === orderTypes.delivery ? <StyledTableCell align="left">
                                                {row.min_range !== null && (
                                                    <React.Fragment>
                                                        <div> {`${row.liters_per_minute === true ? 'Liters Per Minute' : 'Air PSI'}`} </div>
                                                        <div> {`Min: ${row.min_range}`} </div>
                                                        <div> {`Max: ${row.max_range}`} </div>
                                                    </React.Fragment>
                                                )}
                                                <div>
                                                    {row.iscontinous === true && `With Continuous oxygen therapy`}
                                                    {row.iscontinous === false && `With PRN oxygen therapy`}
                                                </div>
                                                {row.humidifier_for_compressor !== null && (
                                                    <div> {`Humidifier bottle: ${row.humidifier_for_compressor}%`} </div>
                                                )}
                                                {row.weight !== null && (
                                                    <div> {`Patient Weight: ${row.weight}`} </div>
                                                )}
                                                {row.delivery_method !== null && (
                                                    <div> {`Delivery Method: ${row.delivery_method}`} </div>
                                                )}
                                                {row.size_lift && (
                                                    <div> {`Size: ${row.size_lift}`} </div>
                                                )}
                                                {row.service_note && (
                                                    <div> {`Service Note: ${row.service_note_text}`} </div>
                                                )}
                                            </StyledTableCell> : <StyledTableCell>
                                                -
                                            </StyledTableCell>}
                                        </StyledTableRow>
                                    ))}
                                    {orderType === orderTypes.exchange && order?.deliveredItems?.map((row) => (
                                        <StyledTableRow
                                            key={row.special_item_id ? row.special_item_id : row.inventory_item_id}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.name ? row.name : row.item_name}
                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <TechnicianAssetSelect
                                                    item={row}
                                                    hasAsset={!!row.asset}
                                                    setOrder={setOrder}
                                                    order={order}
                                                    accessToken={accessToken}
                                                    technicianId={technicianId}
                                                />
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {row.min_range !== null && (
                                                    <React.Fragment>
                                                        <div> {`${row.liters_per_minute === true ? 'Liters Per Minute' : 'Air PSI'}`} </div>
                                                        <div> {`Min: ${row.min_range}`} </div>
                                                        <div> {`Max: ${row.max_range}`} </div>
                                                    </React.Fragment>
                                                )}
                                                <div>
                                                    {row.iscontinous === true && `With Continuous oxygen therapy`}
                                                    {row.iscontinous === false && `With PRN oxygen therapy`}
                                                </div>
                                                {row.humidifier_for_compressor !== null && (
                                                    <div> {`Humidifier bottle: ${row.humidifier_for_compressor}%`} </div>
                                                )}
                                                {row.weight !== null && (
                                                    <div> {`Patient Weight: ${row.weight}`} </div>
                                                )}
                                                {row.delivery_method !== null && (
                                                    <div> {`Delivery Method: ${row.delivery_method}`} </div>
                                                )}
                                                {row.size_lift && (
                                                    <div> {`Size: ${row.size_lift}`} </div>
                                                )}
                                                {row.service_note && (
                                                    <div> {`Service Note: ${row.service_note_text}`} </div>
                                                )}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {orderType === orderTypes.exchange && <React.Fragment>
                        <Grid item xs={12} className={classes.padding16}>
                            <Typography variant={'h6'} style={{textAlign: "start"}}>
                                Pickup Items
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>{sectionTitles.itemName}</StyledTableCell>
                                            <StyledTableCell>{sectionTitles.itemAsset}</StyledTableCell>
                                            <StyledTableCell
                                                align="left">{sectionTitles.clinicalNotes}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {(orderType === orderTypes.exchange) && order?.pickupItems?.map((row) => (
                                            <StyledTableRow
                                                key={row.special_item_id ? row.special_item_id : row.inventory_item_id}>
                                                <StyledTableCell component="th" scope="row">
                                                    {row.name ? row.name : row.item_name}
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <TechnicianAssetSelect
                                                        item={row}
                                                        hasAsset={!!row.asset}
                                                        setOrder={setOrder}
                                                        order={order}
                                                        accessToken={accessToken}
                                                        technicianId={technicianId}
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    -
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </React.Fragment>}
                </Grid>
                {order?.notes?.length > 0 && <Grid item xs={12} sm={8} md={6} lg={4} className={classes.padding16}>
                    <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        className={classes.tableHeader}>{sectionTitles.orderNotes}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order.notes?.map((row) => (
                                    <StyledTableRow key={row.name} className={classes.noteContainer}>
                                        {`"${row.description}" added by ${row.first_name + " " + row.last_name} on ${moment(row.created_date).utcOffset(new Date(row.created_date).getTimezoneOffset(), true).format("LLL")}`}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>}
                <Grid item container xs={12} sm={8} md={6} lg={4} justify={"space-between"} spacing={1}>
                    <Grid item>
                        <TechnicianOperations
                            orderType={orderType}
                            isLoading={isLoading}
                            orderData={order}
                            accessToken={accessToken}
                            refreshInfoFunction={() => getOrderDetails(accessToken)}
                        />
                    </Grid>
                    {order.status === "Processing" && <Grid item style={{paddingLeft: 18}}>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            onClick={checkOrderAssetCompletion}
                            disabled={isLoading}
                            startIcon={<DoneIcon/>}
                            style={{
                                marginRight: 16,
                                display: "flex",
                                fontWeight: 'bold',
                                fontSize: 12,
                                border: '1px solid #00223D',
                                textTransform: 'uppercase'
                            }}
                        >Complete order</Button>
                    </Grid>}
                </Grid>
                <Dialog open={missingAssetsDialog}>
                    <DialogTitle>
                        MISSING ASSETS
                    </DialogTitle>
                    <DialogContent>
                        Are you sure you want to complete the order with items missing assets?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => {
                                setMissingAssetsDialog(false)
                                updateOrderStatus();
                            }}>
                            Yes, proceed
                        </Button>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            onClick={() => {
                                setMissingAssetsDialog(false)
                            }}
                        >
                            No, Return to Order
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
    return <Grid container style={{fontSize: 24, height: 'calc(100vh - 60px)'}} justify='center'
                 alignContent={'center'}
                 alignItems={'center'}>
        <Grid item xs={8} style={{background: 'white', borderRadius: 4}}>
            <Grid container alignItems={'center'} alignContent={'center'}
                  style={{padding: 48, paddingLeft: 12, paddingRight: 36, paddingBottom: 12}}>
                <Grid item xs={4}>
                    <LockOutlinedIcon style={{fontSize: 200, color: '#4F889E'}}/>
                </Grid>
                <Grid item xs={8}>
                    <Grid container style={{textAlign: 'left'}}>
                        <Grid item xs={12} style={{fontSize: 36, fontWeight: 'bold', color: "#4F889E"}}>
                            Whoops!
                        </Grid>
                        <Grid item xs={12} style={{fontSize: 20, paddingTop: 8}}>
                            This URL is valid but you are not authorized for this content due to token
                            expiration time
                            reached. Please contact your manager if you require further access.
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#F0F5F7',
        color: theme.palette.common.black,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);


const useStyles = makeStyles((theme) => {
    return {
        padding16: {
            padding: 16
        },
        paddingL16: {
            padding: 16
        },
        h1: {
            textAlign: 'left',
            color: '#00223D',
            fontSize: 26,
            fontWeight: "bold",
            lineHeight: '38px',
            borderBottom: '1px solid #B2D1D9',
            marginBottom: 16,
            textTransform: "uppercase",
            paddingTop: 40,
            paddingLeft: 16
        },
        noteContainer: {
            display: 'flex',
            padding: 8,
        },
        tableHeader: {
            fontSize: 16,
            fontWeight: 700,
        },
        link: {
            color: "blue",
            textDecoration: "underline",
        },
        signatureContainer: {
            height: '200px',
            width: '300px',
            backgroundColor: '#fff',
            border: '1px dashed #666',
            margin: '0 auto',
        },
        signatureCanvas: {
            width: '100%',
            height: '100%',
        }
    }
});
