import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import Popover from '@material-ui/core/Popover';
import {endpoints} from "../../../constants/endpoints";
import {placeholders} from "../../../constants/inputs/paceholders";
import {patientRecordNames} from "../../../constants/inputs/patientRecordNames";
import ItemsTable from "./itemsTable/itemsTable";
import _ from "lodash";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {alertTitles} from "../../../constants/alert/alertTitle";
import {buttonText} from "../../../constants/alert/alertButtonTexts";
import Select from 'react-select';
import {alertMessages, snackbarMessages} from "../../../constants/alert/alertMessages";
import {exchangeOrderStyle} from "./exchangeOrderStyle";
import jwt from 'jwt-decode';
import moment from "moment";
import {CircularProgress} from "@material-ui/core";
import {checkPermissions} from "../../../common/permissions/checkPermissions";
import {permissions} from "../../../constants/permissions/permissions";
import {useSnackbar} from "notistack";
import {CustomAccordion} from "../../../common/accordion/accordion";
import {exportPDF} from "./components/printExchange";
import Link from "@material-ui/core/Link";
import {organizations} from "../../../constants/types/role";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {captionBase64, maxInputLength, orderTypes, utilsTokenIdentifiers} from "../../../constants/inputs/values";
import {notifySnack} from "../../../common/notifications/apiStatusNotification";
import Typography from "@material-ui/core/Typography";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import {checkPaceHospice, formatNumber, getHospiceLogo, getTokenInformation} from "../../../common/utils/utils";
import {DispatchNotification} from "../../../common/notifications/DispatchNotification";
import {useHistory} from "react-router-dom";
import FileUpload from "../fileUploadOrders/FileIpload";
import {IconAlertActions, IconAlerts, IconAlertTypes} from "../../../common/alerts/IconAlerts/IconAlerts";
import {parseISO} from 'date-fns';
import {parseTimeWithUserTimezone} from '../../../common/timeHandler/timeHandler';
import RequestExchange from "../../requestExchange/requestExchange";
import {
    DispatchNotificationToSelectedTechnician
} from "../../../common/notifications/DispatchNotificationToSelectedTechnician";
import {checkItemsForAssetPresence} from "../../../common/orders/OrdersCommon";
import {TechnicianOperations} from "../technicianOrders/components/TechnicianOperations";

export default function ExchangeOrder() {
    const history = useHistory();
    const [files, setFiles] = useState([]);
    const [showAuthorization, setShowAuthorization] = useState(false);

    //TODO change this
    let userType = localStorage.getItem('key');
    userType = JSON.parse(userType);
    let user = jwt(userType.accessToken);

    userType = user.payload.type;
    let user_id = user.payload.user_id;
    const dme_organization_id = user.payload.dme_organization_id;
    const classes = useStyles();
    const isLogged = useSelector(state => state.isLogged);
    const [orderId, setOrderId] = useState();
    const [newAsset, setNewAsset] = useState({
        warehouse_id: null,
        warehouse_name: null,
        dme_organization_id: Number(user.payload.dme_organization_id),
        status: true,
    });
    const {enqueueSnackbar} = useSnackbar();
    const [warehouseList, setWarehouseList] = useState([]);
    const [technicianList, setTechnicianList] = useState([]);
    const [availableAssets, setAvailableAssets] = useState([]);
    const [showInventoryModal, setShowInventoryModal] = useState(false);
    const [showOrderStatusModal, setShowOrderStatusModal] = useState(false);
    const [newOrderStatus, setNewOrderStatus] = useState('');
    const [selectedItems, setSelectedItems] = useState([]);
    const [assetsArray, setAssetsArray] = useState([]);
    const [currentSpecialId, setCurrentSpecialId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [cancelReason, setCancelReason] = useState('');
    const [isPace, setIsPace] = useState(false);
    const [saveNoteLoading, setSaveNoteLoading] = useState(false);
    const [order, setOrder] = useState({
        category: '',//TODO outside hook
        priority: '',
        deliveredItems: [],
        pickupItems: [],
        notes: [],
    });
    const [orgB64LogoCode, setOrgB64LogoCode] = useState(captionBase64);
    const [isUnsavedNote, setIsUnsavedNote] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [invalidLotNo, setInvalidLotNo] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [buttonType, setButtonType] = useState("");
    const [enableCancelOrder, setEnableCancelOrder] = useState(true);
    const [disableRemoveItems, setDisableRemoveItems] = useState(true);


    //Get and set order id from url Params
    useEffect(() => {
        let urlParameters = window.location.href.split('/');
        setOrderId(Number(urlParameters[urlParameters.length - 1]))

    }, []);

    function getAvailableAssets() {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.getAssets, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                setAvailableAssets(response.data)
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })

    }

    function getTechnicians() {
        const token = JSON.parse(localStorage.getItem('key'));
        let decoded = jwt(token.accessToken).payload;
        fetch(`${endpoints.getAllTechnicians}${decoded.dme_organization_id}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.data) {
                    let arr = [];
                    response.data.map(tech => {
                        arr.push({
                            label: `${tech.first_name} ${tech.last_name}`,
                            value: `${tech.first_name} ${tech.last_name}`
                        })
                    });
                    setTechnicianList(arr)
                } else {
                    setTechnicianList([])
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })

    }

    const markFileAsRemoved = (id, fileName) => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.removeFileUploadIdOnOrders, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                file_id: id
            })
        })
            .then(response => {
                if (response.status === 200) {
                    addHistory(order.exchange_order_id, `File Changed: ${user.payload.first_name} ${user.payload.last_name} deleted file ${fileName} on ${moment(new Date()).utc().format("LLL")}`);
                    enqueueSnackbar("File deleted with success.", {
                        persist: false,
                        variant: "success",
                    });
                }

                history.push(`/refresh/order/exchange/${orderId}`);
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            });
    };

    async function uploadFiles(orderId) {
        for (let i = 0; i < files.length; i++) {
            await createOrderFilesIds(orderId, files[i])
        }
        addHistory(order.exchange_order_id, `File Changed: ${user.payload.first_name} ${user.payload.last_name} added file ${files[0].name} on ${moment(new Date()).utc().format("LLL")}`);
        enqueueSnackbar("File uploaded with success.", {
            persist: false,
            variant: "success",
        });
        history.push(`/refresh/order/exchange/${orderId}`);
    }

    async function createOrderFilesIds(orderId, file) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.createFileUploadIdOnOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({
                file_name: file.name,
                orderId: orderId,
                orderType: 'exchange',
            })
        });
        const response = await promise1.json();
        let name = response.data.rows[0].file_name.split(".");
        let fileName = `${response.data.rows[0].file_id}.${name[name.length - 1]}`
        await uploadFile(file, fileName)
    }

    async function uploadFile(file, fileName) {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise1 = await fetch(endpoints.fileUploadOnOrders, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authorization": "Bearer " + token.accessToken,
            },
            body: JSON.stringify({orderId: orderId, fullOrderId: "S300" + orderId , type: 'exchange', fileName, status: order.status, hospiceId : order.hospice_id})
        });
        const result1 = await promise1.json();
        try {
            const promiseS3 = await fetch(result1.data, {method: 'PUT', body: file});
        } catch (e) {
            console.log(e.stack);
        }
    }

    useEffect(() => {
        if (files.length > 0) {
            setIsLoading(true);
            uploadFiles(orderId)
        }
    }, [files]);

    function getOrderDetails() {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(`${endpoints.getExchangeOrderById}${orderId}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                let clone = _.cloneDeep(response.data[0]);
                let orderHistory = [];
                let itemHistory = [];
                let noteHistory = [];
                clone.history.map(action => {
                    let clone = action.description.split(" on ");
                    let newDate = parseTimeWithUserTimezone(action.created_date);
                    action.description = clone[0] + ` on ` + newDate;

                    let description = action.description;
                    let row = description.split(":");
                    if (row[0] === 'Note Added') {
                        noteHistory.push(action);
                    } else if (row[0] === 'Items added' || row[0] === 'Items removed' || row[0] === 'File Changed' || row[0] === 'Dispatched notification to') {
                        itemHistory.push(action);
                    } else if (row[0] === 'Status Changed' || row[0] === 'Order created' || row[0] === 'Order Created') {
                        orderHistory.push(action);
                    }
                });
                clone.historyGroup = {orderHistory, itemHistory, noteHistory};
                setOrder(clone);
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })

    }

    const getAvailableWarehouses = () => {
        if (userType === "DME") {
            const token = JSON.parse(localStorage.getItem('key'));
            fetch(`${endpoints.getUserWarehouses}${user_id}`, {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    let x = [];
                    response.data.map(warehouse => x.push({label: warehouse.name, value: warehouse.warehouse_id}));
                    if (response.data.length === 1) {
                        setNewAsset({
                            ...newAsset,
                            warehouse_id: response.data[0].warehouse_id,
                            warehouse_name: response.data[0].name
                        })
                    }
                    setWarehouseList(x)
                })
                .catch(err => {
                    console.log(err, 'error');
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                })


        }
    };

    useEffect(() => {
        if (orderId !== undefined) {
            getOrderDetails();
            getAvailableAssets();
            getAvailableWarehouses();
            if (userType === 'DME') getTechnicians();
        }
    }, [orderId]);

    useEffect(() => {
        if (order.exchange_order_id) {
            setIsLoading(false)
        }
    }, [order.exchange_order_id]);

    useEffect(() => {
        if(selectedItems.length === 0){
            setDisableRemoveItems(false);
        }
        if(selectedItems.length > 0 && selectedItems.length % 2 !== 0){
            setDisableRemoveItems(true);
        }
        if(selectedItems.length > 0 && selectedItems.length % 2 === 0){
            const checkPairsInDispatchItems = selectedItems.every(item => order.deliveredItems.filter(delItem => item.special_item_id === delItem.special_item_id).length === 1)
            const checkPairsReclaimItems = selectedItems.every(item => order.pickupItems.filter(picItem => item.special_item_id === picItem.special_item_id).length === 1)
            if(checkPairsInDispatchItems || checkPairsReclaimItems){
                setDisableRemoveItems(true);
            }
            if(checkPairsInDispatchItems === false && checkPairsReclaimItems === false){
                setDisableRemoveItems(false);
            }

        }
    }, [selectedItems]);

    useEffect(() => {
        if (order.deliveredItems[0] !== undefined) {
            if (order.deliveredItems[0].availableData === undefined) {
                let arr = [];
                availableAssets.map(asset => arr.push({label: asset.asset, value: asset.asset}));
                setAssetsArray(arr);
            }
        }
        userType === 'Hospice' && setEnableCancelOrder(checkItemsForAssetPresence([...order.deliveredItems, ...order.pickupItems]));
    }, [order.deliveredItems]);

    const selectAssetHandle = (event, itemIndex, type, item_name, asset, onBlur, special_item_id, item_group) => {
        if (type === 'lot') {
            let orderClone = _.cloneDeep(order);
            let lot;
            let item;
            if (item_group === 'pickup') {
                orderClone.pickupItems[itemIndex].lot_no = event.target.value;
                lot = orderClone.pickupItems[itemIndex].lot_no
                item = orderClone.pickupItems[itemIndex].special_item_id;
            }
            if (item_group === 'delivery') {
                orderClone.deliveredItems[itemIndex].lot_no = event.target.value;
                lot = orderClone.deliveredItems[itemIndex].lot_no;
                item = orderClone.deliveredItems[itemIndex].special_item_id;
            }
            setOrder(orderClone);
            if (onBlur) {
                const token = JSON.parse(localStorage.getItem('key'));
                const reg = /^\d+$/;
                if (reg.test(lot) === false) {
                    setInvalidLotNo([...invalidLotNo, item]);
                } else {
                    fetch(endpoints.updateInventoryItemsLotToOrder, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token.accessToken
                        },
                        body: JSON.stringify({
                            lot_no: event.target.value,
                            special_item_id: special_item_id
                        })
                    })
                        .then(response => {
                            return response.json()
                        })
                        .then(response => {
                            if (response.status === 'success') {
                                enqueueSnackbar("Item updated with success", {
                                    persist: false,
                                    variant: "success",
                                });
                            }
                        })
                        .catch(err => {
                            console.log(err, 'error');
                            enqueueSnackbar("Something happened. Contact support.", {
                                persist: false,
                                variant: "error",
                            });
                        })
                }
            }
        }
        if (event.__isNew__ && type === 'asset') {
            let orderClone = _.cloneDeep(order);
            orderClone.deliveredItems[itemIndex].asset = event.value;
            setOrder(orderClone);
            setNewAsset({...newAsset, asset: event.value, item_name: item_name, item_status: "In use"});
            setShowInventoryModal(true);
        }
        if (event.__isNew__ && type === 'pickup_asset') {
            let orderClone = _.cloneDeep(order);
            orderClone.pickupItems[itemIndex].asset = event.value;
            setOrder(orderClone);
            setNewAsset({...newAsset, asset: event.value, item_name: item_name, item_status: "In use"});
            setShowInventoryModal(true);
        }
        if (event.value !== null && event.__isNew__ === undefined && (type === 'asset' || type === 'pickup_asset')) {
            let assetsClone = _.cloneDeep(assetsArray);
            const assetsArrIndex = _.findIndex(assetsArray, function (o) {
                return o.value === event.value
            });
            if (assetsArrIndex > -1) {
                assetsClone.splice(assetsArrIndex, 1)
            }
            setAssetsArray(assetsClone);

            let availableClone = _.cloneDeep(availableAssets);
            const assetsIndex = _.findIndex(availableAssets, function (o) {
                return o.asset === event.value
            });
            if (assetsIndex > -1) {
                availableClone.splice(assetsIndex, 1)
            }
            setAvailableAssets(availableClone);

            let orderClone = _.cloneDeep(order);

            if (type === 'asset') {
                orderClone.deliveredItems[itemIndex].inventory = availableAssets[assetsIndex];
                orderClone.deliveredItems[itemIndex].asset = event.value;
            }

            if (type === 'pickup_asset') {
                orderClone.pickupItems[itemIndex].inventory = availableAssets[assetsIndex];
                orderClone.pickupItems[itemIndex].asset = event.value;
            }


            const token = JSON.parse(localStorage.getItem('key'));
            fetch(endpoints.updateItemsAssetToOrder, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    asset: event.value,
                    special_item_id: special_item_id
                })
            })
                .then(response => {
                    return response.json()
                })
                .then(response => {
                    if (response.status === 'success') {
                        setOrder(orderClone);
                        enqueueSnackbar("Item updated with success", {
                            persist: false,
                            variant: "success",
                        });
                    }
                })
                .catch(err => {
                    console.log(err, 'error');
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                })
        }
    };
    const addToInventory = () => {
        setShowInventoryModal(false);
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.addInventory, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({items: [newAsset]})
        })
            .then(response => {
                return response.json()
            })
            .then(response => {
                if (response.status === 'success') {
                    fetch(endpoints.addInventoryItemsToOrder, {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token.accessToken
                        },
                        body: JSON.stringify({inventory_item_id: response.data, special_item_id: currentSpecialId})
                    })
                        .then(response => {
                            notifySnack(enqueueSnackbar, response.status, snackbarMessages.orderItemAdded, true);
                            return response.json()
                        })
                        .then(response => {

                        })
                }

                // let x =[]
                // let arr = response.data.map(warehouse =>  x.push({label:warehouse.name, value:warehouse.warehouse_id}))
                // console.log(x)
                // if (response.data.length ===1){
                //     setNewAsset({...newAsset, warehouse_id: response.data[0].warehouse_id, warehouse_name: response.data[0].name})
                // }
                // setWarehouseList(x)
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick33 = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const addHistory = (order_id, description, type) => {
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.addExchangeOrderHistory, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                history: [{
                    description: description,
                }],
                exchangeOrderId: order_id,
            })
        })
            .then(response => {
                return response.json()
            })

            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })
    };

    const changeTechnician = (e) => {
        let orderClone = _.cloneDeep(order);
        orderClone.technician_name = e.value;
        if (e.__isNew__) {
            const token = JSON.parse(localStorage.getItem('key'));
            fetch(endpoints.addTechnician, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    name: e.value
                })
            })
                .then(response => {
                    const token = JSON.parse(localStorage.getItem('key'));
                    fetch(endpoints.updateDeliveryTechnician, {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token.accessToken
                        },
                        body: JSON.stringify({
                            technician_name: e.value,
                            delivery_order_id: order.delivery_order_id
                        })
                    })
                        .then(response => {
                            notifySnack(enqueueSnackbar, response.status, snackbarMessages.addedOrderTechnician, true);
                            return response.json()
                        })
                        .catch(err => console.log('error', err));
                    return response.json()

                })

                .catch(err => {
                    console.log(err, 'error');
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                })
        } else {
            const token = JSON.parse(localStorage.getItem('key'));
            fetch(endpoints.updateExchangeTechnician, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    technician_name: e.value,
                    exchange_order_id: order.exchange_order_id
                })
            })
                .then(response => {
                    notifySnack(enqueueSnackbar, response.status, snackbarMessages.addedOrderTechnician, true);
                    return response.json()
                })
                .catch(err => {
                    console.log(err, 'error');
                    enqueueSnackbar("Something happened. Contact support.", {
                        persist: false,
                        variant: "error",
                    });
                })
        }
        setOrder(orderClone);

    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [currentNote, setCurrentNote] = React.useState();

    const addOrderNote = () => {
        setSaveNoteLoading(true);
        let final = moment().utc().format('LLL');
        const arr = [...order.notes];
        let input = {
            user_id: user.payload.user_id,
            first_name: user.payload.first_name,
            last_name: user.payload.last_name,
            creator: `${user.payload.first_name} ${user.payload.last_name}`,
            created_date: final,
            description: currentNote
        };
        arr.push(input);
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.addExchangeOrderNotes, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                notes: [input],
                exchangeOrderId: order.exchange_order_id
            })
        })
            .then(response => {
                notifySnack(enqueueSnackbar, response.status, snackbarMessages.orderNoteAdded, true);
                return response.json()
            })
            .then(response => {
                if (response.status === 'success') {
                    setCurrentNote('');
                    addHistory(orderId, `Note Added: ${user.payload.first_name} ${user.payload.last_name} added a new note on ${moment(new Date()).utc().format("LLL")}`)
                    history.push(`/refresh/order/exchange/${orderId}`);
                    setIsUnsavedNote(false);
                    setShowWarning(false);
                }
                setSaveNoteLoading(false);
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
            })

    };
    const submitWithUnsavedNote = () => {
        addOrderNote();
        order.status === "Open" && processOrderButton(false);
        order.status === "Processing" && completeOrderButton(false);
    }
    const inventoryModalCloseHandler = () => {
        setShowInventoryModal(false)
    };
    const topSection = React.createRef();
    const notesSection = React.createRef();
    const itemsSection = React.createRef();

    const goToSection = (sectionName) => {
        switch (sectionName) {
            case 'notes': {
                notesSection.current.scrollIntoView({behavior: 'smooth'});
                break;
            }
            case 'items': {
                itemsSection.current.scrollIntoView({behavior: 'smooth'});
                break;
            }
            case 'top': {
                topSection.current.scrollIntoView({behavior: 'smooth'});
                break;
            }
        }
    };
    const changeOrderStatus = (newStatus, approve) => {
        setButtonLoading(true);
        const status = {
            exchange_order_id: order.exchange_order_id,
            status: newStatus,
            cancelReason: cancelReason,
        };
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.updateExchangeOrderStatus, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify(status)
        })
            .then(response => {
                notifySnack(enqueueSnackbar, response.status, snackbarMessages.orderStatusChanged, true);
                if (response.status === 200) {
                    if (approve === true) {
                        addHistory(order.exchange_order_id, `Status Changed: ${user.payload.first_name} ${user.payload.last_name} approved order on ${moment(new Date()).format("LLL")}`);
                    } else if (approve === false) {
                        addHistory(order.delivery_order_id, `Status Changed: ${user.payload.first_name} ${user.payload.last_name} rejected order on ${moment(new Date()).format("LLL")}`);
                    } else {
                        if (cancelReason !== "") {
                            addHistory(order.exchange_order_id, `Status Changed: ${user.payload.first_name} ${user.payload.last_name} changed order status to ${newStatus} with reason: ${cancelReason} on ${moment(new Date()).utc().format("LLL")}`);
                        } else {
                            addHistory(order.exchange_order_id, `Status Changed: ${user.payload.first_name} ${user.payload.last_name} changed order status to ${newStatus} on ${moment(new Date()).utc().format("LLL")}`);
                        }
                    }

                    let a = {...order};
                    a.status = newStatus;
                    setOrder(a);
                    setButtonLoading(false);
                    history.push(`/refresh/order/exchange/${order.exchange_order_id}`)
                }
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
                setButtonLoading(false);
            });
        setShowOrderStatusModal(false)
    };
    const statusModalCloseHandler = () => {
        setShowOrderStatusModal(false);
        setNewOrderStatus('');
        if (newOrderStatus === 'Canceled') {
            setCancelReason('');
        }
    };

    const checkItemPair = (item, pairItemList, itemIndex, checked) => {
        let selectedClone = _.cloneDeep(selectedItems);
        if (checked) {
            let checkedItems = [];
            const pairListMultipleItems = pairItemList.filter(possiblePairItem => possiblePairItem.name === item.name);
            if (pairListMultipleItems.length === 1) {
                if (pairItemList[itemIndex].name === item.name) {
                    if (selectedItems.length === 0) {
                        checkedItems = [item, pairItemList[itemIndex]];
                    } else {
                        const exists = _.findIndex(selectedClone, {special_item_id: pairItemList.special_item_id});
                        if (exists === -1) {
                            checkedItems = [...selectedItems, item, pairItemList[itemIndex]];
                        } else {
                            checkedItems = [...selectedItems, item];
                        }
                    }
                    setSelectedItems(checkedItems);
                }
                let pairItemIndex = _.findIndex(pairItemList, {"name": item.name})
                if (pairItemIndex > -1) {
                    if (selectedItems.length === 0) {
                        checkedItems = [item, pairItemList[pairItemIndex]];
                        setSelectedItems(checkedItems);
                    } else {
                        const exists = _.findIndex(selectedClone, {special_item_id: pairItemList.special_item_id});
                        if (exists === -1) {
                            checkedItems = [...selectedItems, item, pairItemList[pairItemIndex]];
                        } else {
                            checkedItems = [...selectedItems, item];
                        }
                        setSelectedItems(checkedItems);
                    }
                }
                if (pairItemIndex === -1) {
                    if (selectedItems.length === 0) {
                        checkedItems = [item];
                    } else {
                        checkedItems = [...selectedItems, item];
                    }
                    setSelectedItems(checkedItems);
                }
            } else {
                if (selectedItems.length === 0) {
                    checkedItems = [item];
                } else {
                    checkedItems = [...selectedItems, item];
                }
                setSelectedItems(checkedItems);
            }
        }
        if (!checked) {
            const selectedItemsMultiple = selectedItems.filter(possibleItem => possibleItem.name === item.name && possibleItem.special_item_id !== item.special_item_id);
            if (selectedItems.length === 1) {
                setSelectedItems([]);
            } else {
                if (selectedItemsMultiple.length === 1) {
                    const findPairItem = _.findIndex(selectedItems, {'special_item_id': selectedItemsMultiple[0].special_item_id});
                    selectedClone.splice(findPairItem, 1);
                }
                const findItemIndex = _.findIndex(selectedItems, {'special_item_id': item.special_item_id});
                selectedClone.splice(findItemIndex, 1);
                setSelectedItems(selectedClone);
            }
        }

    }
    const handleCheckboxChange = (item, index, checked) => {
        const isPickupItem = _.findIndex(order.pickupItems, {'special_item_id': item.special_item_id}) // get item type to send opposite type as pair list
        const isDeliveryItem = _.findIndex(order.deliveredItems, {'special_item_id': item.special_item_id})
        if (isPickupItem > -1) {
            checkItemPair(item, order.deliveredItems, index, checked);
        }
        if (isDeliveryItem > -1) {
            checkItemPair(item, order.pickupItems, index, checked);
        }
        // const newLogicDate = new Date(); // TODO: ADD update to prod date here
        // const creationDate = new Date(order?.created_date);
        // if(creationDate > newLogicDate){
        //     let selectedClone = _.cloneDeep(selectedItems);
        //     if (checked) {
        //         let items;
        //         if (selectedItems.length === 0) {
        //             items = [item]
        //         } else {
        //             items = [...selectedItems, item];
        //         }
        //         setSelectedItems(items)
        //     } else {
        //         let existingIndex = _.findIndex(selectedItems, {'special_item_id': item.special_item_id});
        //         if (selectedItems.length === 1) {
        //             selectedClone = [];
        //         } else {
        //             selectedClone.splice(existingIndex, 1);
        //         }
        //         setSelectedItems(selectedClone);
        //     }
        // }
        // if(creationDate <= newLogicDate){
        //     let selectedClone = _.cloneDeep(selectedItems);
        //
        // }
    };

    const handleItemsRemove = () => {
        setIsLoading(true);
        const token = JSON.parse(localStorage.getItem('key'));
        fetch(endpoints.deleteExchangeItems, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                items: selectedItems,
                exchange_order_id: order.exchange_order_id
            })
        })
            .then(response => {
                if (response.status === 204) {
                    notifySnack(enqueueSnackbar, response.status, snackbarMessages.orderItemRemoved, true);
                    let items = selectedItems.map(function (item) {
                        return item['name'];
                    });
                    const allItems = items.join(', ');
                    addHistory(order.exchange_order_id, `Items removed: ${user.payload.first_name} ${user.payload.last_name} removed ${allItems} from order on ${moment(new Date()).utc().format("LLL")}`);
                    
                    setSelectedItems([])
                    history.push(`/refresh/order/exchange/${orderId}`);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err, 'error');
                enqueueSnackbar("Something happened. Contact support.", {
                    persist: false,
                    variant: "error",
                });
                setIsLoading(false);
            })
    };

    const handleCompletedDateChange = async (event) => {
        const token = JSON.parse(localStorage.getItem('key'));
        if (orderId !== undefined) {
            setIsLoading(true);
            const promise = await fetch(endpoints.updateOrderCompletionDate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    orderId: orderId,
                    orderType: orderTypes.exchange,
                    date: event
                })
            });
            const response = await promise.json();

            if (response.status === "success") {
                notifySnack(enqueueSnackbar, response.status, snackbarMessages.updateOrderCompletionDate, true);
                addHistory(orderId, `Status Changed: ${user.payload.first_name} ${user.payload.last_name} changed completed date to ${event} on ${moment(new Date()).utc().format("LLL")}`);
                history.push(`/refresh/order/exchange/${orderId}`);
            } else {
                notifySnack(enqueueSnackbar, promise.status, snackbarMessages.updateOrderCompletionDate, false);
            }
            setIsLoading(false);
        }
    };

    const handlePreferredDateChange = async (event) => {
        const token = JSON.parse(localStorage.getItem('key'));
        if (orderId !== undefined) {
            setIsLoading(true);
            const promise = await fetch(endpoints.updateOrderPreferredDate, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    orderId: orderId,
                    orderType: orderTypes.exchange,
                    date: event
                })
            });
            const response = await promise.json();

            if (response.status === "success") {
                notifySnack(enqueueSnackbar, response.status, snackbarMessages.updateOrderPreferredDate, true);
                addHistory(orderId, `Status Changed: ${user.payload.first_name} ${user.payload.last_name} changed preferred date to ${event} on ${moment(new Date()).utc().format("LLL")}`);
                history.push(`/refresh/order/exchange/${orderId}`);
            } else {
                notifySnack(enqueueSnackbar, promise.status, snackbarMessages.updateOrderPreferredDate, false);
            }
            setIsLoading(false);
        }
    };


    useEffect(() => {
        async function check() {
            let res = await checkPaceHospice(order.hospice_organization_id);
            setShowAuthorization(res);
            setIsPace(res);
        }

        if (order.hospice_organization_id !== undefined) {
            const token = JSON.parse(localStorage.getItem('key'));
            check();
            getHospiceLogo(setOrgB64LogoCode, token, order.hospice_organization_id);
        }
    }, [order.hospice_organization_id]);

    const updateAuthorizationPaceNo = async (e) => {
        const token = JSON.parse(localStorage.getItem('key'));
        const promise = await fetch(endpoints.updatePaceNoExchange, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token.accessToken
            },
            body: JSON.stringify({
                orderId: orderId,
                authorization_no: order.authorization_pace_no
            })
        });
        const response = await promise.json();
        if (response.status === "success") {
            enqueueSnackbar('Authorization number updated with success', {
                variant: 'success',
                autoHideDuration: 4000,
                anchorOrigin: {vertical: "bottom", horizontal: "left"}
            });
        } else {
            enqueueSnackbar('Unable to update authorization number', {
                variant: 'info',
                autoHideDuration: 4000,
                anchorOrigin: {vertical: "bottom", horizontal: "left"}
            });
        }
    };

    const processOrderButton = (needNoteValidation) => {
        if (isUnsavedNote && needNoteValidation) {
            setShowError(true);
        } else {
            setShowError(false);
            setShowOrderStatusModal(true);
            setNewOrderStatus("Processing");
        }
    };

    const completeOrderButton = (needNoteValidation) => {
        if (isUnsavedNote && needNoteValidation) {
            setShowError(true);
        } else {
            setShowError(false);
            setShowOrderStatusModal(true);
            setNewOrderStatus("Completed");
        }
    };

    return (
        <Grid container style={{minHeight: '500px'}}>
            {(isLogged) ? <Grid container className={classes.parent}>
                {isLoading ? (
                    <div className={classes.loading}>
                        <h2>Order is loading</h2>
                        <CircularProgress/>
                    </div>
                ) : (
                    <Grid container justify={'center'} className={classes.root}>
                        <Grid item xs={12}>
                            <Typography className={classes.h1} ref={topSection}>
                                Orders
                            </Typography>
                        </Grid>

                        <Grid container justify={'space-between'}>
                            <Grid item>
                                <Button
                                    className={classes.buttonGrey}
                                    variant="outlined"
                                    onClick={() => history.push('/orders')}
                                >
                                    View All Orders
                                </Button>
                            </Grid>
                            <Grid item>
                                <Grid container justify={'flex-end'} spacing={1}>
                                    <Grid item>
                                        <TechnicianOperations
                                            orderData={order}
                                            accessToken={getTokenInformation(history, utilsTokenIdentifiers.accessToken)}
                                            isLoading={isLoading}
                                            orderType={orderTypes.exchange}
                                            refreshInfoFunction={() => history.push(`/refresh/order/exchange/${orderId}`)}
                                            technicianView={false}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            className={classes.buttonGrey}
                                            variant="outlined"
                                            onClick={() => goToSection('items')}
                                            startIcon={<VisibilityOutlinedIcon style={{marginRight: 8}}/>}
                                        >
                                            View Items
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            className={classes.buttonGrey}
                                            variant="outlined"
                                            onClick={() => goToSection('notes')}
                                            startIcon={<CommentOutlinedIcon style={{marginRight: 8}}/>}
                                        >
                                            Notes
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            className={classes.buttonGrey}
                                            variant="outlined"
                                            startIcon={<PrintOutlinedIcon style={{marginRight: 8}}/>}
                                            onClick={() => {
                                                exportPDF('print', order, orderId, orgB64LogoCode)
                                            }}
                                        >
                                            Print
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid container style={{paddingTop: 14}}>

                        </Grid>
                        <Grid container className={classes.paper}>
                            <Grid container className={classes.buttons}
                                  style={{display: 'flex', justifyContent: 'space-between'}}>
                                <div className={classes.title}>
                                    Order no: {orderId && ('#' + "S300" + orderId + "- Exchange")}
                                    {order.status === "Open" && <span className={classes.open}>{'Open'}</span>}
                                    {order.status === "Processing" &&
                                    <span className={classes.processing}>{'Processing'}</span>}
                                    {order.status === "Pending" &&
                                    <span className={classes.processing}>{'Pending'}</span>}
                                    {order.status === "Completed" &&
                                    <span className={classes.completed}>{'Completed'}</span>}
                                    {order.status === "Canceled" &&
                                    <Tooltip title={`Reason: ${order.cancel_reason ? order.cancel_reason : "Unknown"}`}><span
                                        className={classes.canceled}>{'Canceled'}</span></Tooltip>}
                                </div>
                                {showAuthorization ? (
                                    <Grid item sm={6} xs={12} className={classes.title}
                                          style={{display: 'flex', justifyContent: 'flex-end', padding: 8}}>
                                        <TextField
                                            placeholder={'Authorization number'}
                                            label={'Authorization number'}
                                            variant={'outlined'}
                                            size={'small'}
                                            value={order.authorization_pace_no}
                                            onChange={(e) => {
                                                setOrder({...order, authorization_pace_no: e.target.value});
                                            }}
                                            onBlur={(e) => updateAuthorizationPaceNo(e)}
                                        />
                                    </Grid>
                                ) : (<React.Fragment>
                                        {
                                            (order.authorization_pace_no && order.authorization_pace_no !== '') && (
                                                <Grid item sm={6} xs={12} className={classes.title}
                                                      style={{display: 'flex', justifyContent: 'flex-end', padding: 8}}>
                                                    <TextField
                                                        disabled
                                                        placeholder={'Authorization number'}
                                                        label={'Authorization number'}
                                                        variant={'outlined'}
                                                        size={'small'}
                                                        value={order.authorization_pace_no}
                                                    />
                                                </Grid>
                                            )
                                        }

                                    </React.Fragment>
                                )}
                                {checkPermissions([permissions.approve_orders], 'Hospice') &&
                                <Grid item sm={showAuthorization ? 12 : 6} xs={12}
                                      style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {
                                        (order.status === 'Pending' || order.status === 'Rejected') &&
                                        <Button variant='contained' color={"primary"}
                                                disabled={buttonLoading}
                                                startIcon={buttonLoading && buttonType === "Approve" ?
                                                    <CircularProgress style={{color: 'white'}} size={24}/> :
                                                    <DoneIcon/>} style={{
                                            backgroundColor: buttonLoading === true ? "" : '#117C70',
                                            textTransform: 'uppercase',
                                            fontSize: 12,
                                            fontWeight: 'bold'
                                        }}
                                                onClick={() => {
                                                    setButtonType('Approve');
                                                    changeOrderStatus('Open', true);
                                                }}
                                        > Approve Order</Button>
                                    }

                                    {
                                        order.status === 'Pending' &&
                                        <Button variant='contained' color={"secondary"} style={{
                                            height: 40,
                                            marginLeft: 12,
                                            backgroundColor: buttonLoading === true ? "" : '#CD1D32',
                                            textTransform: 'uppercase',
                                            fontSize: 12,
                                            fontWeight: 'bold'
                                        }}
                                                startIcon={buttonLoading && buttonType === "Reject" ?
                                                    <CircularProgress style={{color: 'white'}} size={24}/> :
                                                    <ClearIcon/>}
                                                disabled={buttonLoading}
                                                onClick={() => {
                                                    setButtonType('Reject');
                                                    changeOrderStatus('Rejected', false)
                                                }}
                                        > Reject Order</Button>
                                    }

                                </Grid>
                                }
                            </Grid>
                            <Grid container className={classes.columns}>
                                <Grid item sm={4} xs={12} className={classes.column}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                                <span className={classes.h2}
                                                      style={{display: 'flex', textAlign: 'left'}}>
                                                    Patient name:
                                                </span>
                                        </Grid>
                                        <Grid item xs={5} style={{display: 'flex', textAlign: 'left'}}>
                                            <Link target={"_blank"}
                                                  href={`/patients/patientProfile/${order.patient_id}`}
                                                  color={"primary"}>
                                                <span
                                                    className={classes.patient}>{order.first_name && (order.first_name +
                                                    ' ' + order.last_name + ' - #ID ' + order.patient_id)}
                                                </span>
                                            </Link>
                                        </Grid>
                                        {order.infectious_protocol.length > 0 && <Grid item xs={1}>
                                            <IconAlerts iconType={IconAlertTypes.disease}
                                                        data={order.infectious_protocol}/>
                                        </Grid>}
                                        {order.history.filter(item => item.description.includes("Note Added:")).length > 0 &&
                                        <Grid item xs={1}>
                                            <IconAlerts iconType={IconAlertTypes.notes} action={IconAlertActions.anchor}
                                                        data={[notesSection]}/>
                                        </Grid>}
                                        {order.files.length > 0 &&
                                            <Grid item xs={1}>
                                                <IconAlerts iconType={IconAlertTypes.files}/>
                                            </Grid>}
                                    </Grid>
                                </Grid>
                                <Grid item sm={8} xs={12}></Grid>
                                <Grid item sm={4} xs={12} className={classes.column}
                                      style={{borderRight: '1px solid #D5E1E7'}}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <span className={classes.h3} style={{display: 'flex', textAlign: 'left'}}>
                                                Medical Record No:
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                            <span
                                                className={classes.details}>{order.medical_record && (order.medical_record)}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <span className={classes.h3} style={{display: 'flex', textAlign: 'left'}}>
                                                Priority:
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                            <span
                                                className={classes.details}>{order.priority_code && (order.priority_code)}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <span className={classes.h3} style={{display: 'flex', textAlign: 'left'}}>
                                                Created date:
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                            <span className={classes.details}>
                                                {order.created_date && moment(order.created_date).format('L')}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        {checkPermissions([permissions.read_orders], organizations.hospice.type) ?
                                            <React.Fragment>
                                                <Grid item xs={4}>
                                            <span className={classes.h3} style={{display: 'flex', textAlign: 'left'}}>
                                                Preferred date:
                                            </span>
                                                </Grid>
                                                <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                            <span className={classes.details}>
                                               {order.preferred_date && moment(order.preferred_date).format('L')}
                                            </span>
                                                </Grid>
                                            </React.Fragment> : <div>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        inputVariant="outlined"
                                                        label={"Preferred date"}
                                                        format="MM/dd/yyyy"
                                                        value={order.preferred_date ? parseISO(order.preferred_date) : null}
                                                        onChange={(event, value) => handlePreferredDateChange(value)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                            'id': 'preferred_date'
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid container>
                                        {checkPermissions([permissions.read_orders], organizations.hospice.type) ?
                                            <React.Fragment>
                                                <Grid item xs={4}>
                                            <span className={classes.h3} style={{display: 'flex', textAlign: 'left'}}>
                                                Completed date:
                                            </span>
                                                </Grid>
                                                <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                            <span className={classes.details}>
                                               {order.completed_date && moment(order.completed_date).format('L')}
                                            </span>
                                                </Grid>
                                            </React.Fragment> : <div>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        inputVariant="outlined"
                                                        label={"Completed date"}
                                                        format="MM/dd/yyyy"
                                                        value={order.completed_date ? order.completed_date : null}
                                                        onChange={(event, value) => handleCompletedDateChange(value)}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'change date',
                                                            'id': 'completed_date'
                                                        }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item sm={4} xs={12} className={classes.column}
                                      style={{paddingLeft: 40, borderRight: '1px solid #D5E1E7'}}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <span className={classes.h3} style={{display: 'flex', textAlign: 'left'}}>
                                                Location:
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                            <span className={classes.details}>{order.care_setting && order.care_setting}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <span className={classes.h3} style={{display: 'flex', textAlign: 'left'}}>
                                                 {order.is_organization_type === true ? "Organization:" : "Hospice:"}
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                            <span
                                                className={classes.details}>{order.hospice_name && (order.hospice_name)}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <span className={classes.h3} style={{display: 'flex', textAlign: 'left'}}>
                                                 {order.is_organization_type === true ? "Organization Address:" : "Hospice Address:"}
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                            <span className={classes.details}>
                                               {order.hospice_organization_address1 && (order.hospice_organization_address1)} {' '}
                                                {order.hospice_organization_address2 && (order.hospice_organization_address2)}
                                                {order.hospice_organization_city + ', ' + order.hospice_organization_state + " " +
                                                order.hospice_organization_zip}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <span className={classes.h3} style={{display: 'flex', textAlign: 'left'}}>
                                                 {order.is_organization_type === true ? "Organization Phone:" : "Hospice Phone:"}
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                            <span className={classes.details}>
                                              {order.hospice_organization_phone && formatNumber(order.hospice_organization_phone)}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <span className={classes.h3} style={{display: 'flex', textAlign: 'left'}}>
                                                Order by:
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                            <span className={classes.details}>
                                               {`${order.user_first_name} ${order.user_last_name}`}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={4} xs={12} className={classes.column} style={{paddingLeft: 40}}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <span className={classes.h3} style={{display: 'flex', textAlign: 'left'}}>
                                                Patient Address:
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                            <span className={classes.details}>
                                                 {order.address1 && (order.address1)}
                                                {' '}
                                                {order.address2 && (order.address2)}
                                                {order.city + ', ' + order.state + " " + order.zip}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <span className={classes.h3} style={{display: 'flex', textAlign: 'left'}}>
                                                Patient Phone:
                                            </span>
                                        </Grid>
                                        <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                            <span className={classes.details}>
                                                {order.phone_number && formatNumber(order.phone_number)}
                                            </span>
                                        </Grid>
                                    </Grid>
                                    {order.caregivers.map((caregiver, index) => {
                                        return (<React.Fragment>
                                                <Grid container key={index}>
                                                    <Grid item xs={4}>
                                                    <span className={classes.h3}
                                                          style={{display: 'flex', textAlign: 'left'}}>
                                                        {index === 0 && 'Primary contact:'}
                                                        {index === 1 && 'Secondary Contact:'}
                                                        {index === 2 && 'Third Contact:'}
                                                    </span>
                                                    </Grid>
                                                    <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                                    <span className={classes.details}>
                                                       {caregiver.caregiver_first_name && caregiver.caregiver_first_name}
                                                    </span>
                                                    </Grid>
                                                </Grid>
                                                <Grid container key={index}>
                                                    <Grid item xs={4}>
                                                    <span className={classes.h3}
                                                          style={{display: 'flex', textAlign: 'left'}}>
                                                       Phone number:
                                                    </span>
                                                    </Grid>
                                                    <Grid item xs={8} style={{display: 'flex', textAlign: 'left'}}>
                                                    <span className={classes.details}>
                                                       {caregiver.caregiver_phone_number && formatNumber(caregiver.caregiver_phone_number)}
                                                    </span>
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>


                        <Grid ref={itemsSection} container className={`generaltable`}>
                            <Grid container style={{display: 'flex', justifyContent: 'space-between', paddingTop: 36}}>
                                <Grid item className={classes.detailsTitle}>Dispatched items</Grid>
                                <Grid item className={classes.tableHeader}>
                                    <Grid container style={{paddingTop: 6}} spacing={1}>
                                        {checkPermissions([permissions.update_orders], "DME") && (
                                            <Grid item>
                                                <div className={classes.technician}>
                                                    <span className={classes.technicianLabel}>DME Technician:</span>
                                                    <div style={{width: 220}}>
                                                        <Select
                                                            placeholder={'Select DME Technician'}
                                                            options={technicianList}
                                                            value={order.technician_name ? [{
                                                                label: order.technician_name,
                                                                value: order.technician_name
                                                            }] : null}
                                                            onChange={changeTechnician}
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                        )}
                                        {/*<Grid item>
                                            {checkPermissions([permissions.update_orders], "DME") && (
                                                <DispatchNotification
                                                    order={order}
                                                    newOrderStatus={newOrderStatus}
                                                    patientId={order.patient_id}
                                                    orderId={order.exchange_order_id}
                                                    orderType={"Exchange"}
                                                    enqueueSnackbar={enqueueSnackbar}
                                                    addHistory={addHistory}
                                                    orderIdentifier={"S300"}
                                                />)}
                                        </Grid>*/}
                                        <Grid item>
                                            {checkPermissions([permissions.update_orders], "DME") && (
                                                <DispatchNotificationToSelectedTechnician
                                                    order={order}
                                                    enqueueSnackbar={enqueueSnackbar}
                                                    addHistory={addHistory}
                                                    orderId={order.exchange_order_id}
                                                    orderType={"Exchange"}
                                                    orderIdentifier={"S300"}
                                                />)}
                                        </Grid>
                                        <Grid item>
                                            {checkPermissions([permissions.update_orders], "Hospice") && (order.status === "Open" || order.status === "Pending") && (
                                                <RequestExchange
                                                    renderButton={true}
                                                    user={order}
                                                    table={true}
                                                    addMore={true}
                                                    isExchange={true}
                                                    orderId={order.delivery_order_id}
                                                    exchangeId={order.exchange_order_id}
                                                    addHistory={addHistory}
                                                    getOrderDetails={getOrderDetails}
                                                    isPace={isPace}
                                                />
                                                // <CreateOrder
                                                // renderButton={true}
                                                // user={order}
                                                // table={true}
                                                // addMore={true}
                                                // isExchange={true}
                                                // orderId={order.delivery_order_id}
                                                // exchangeId={order.exchange_order_id}
                                                // addHistory={addHistory}
                                                // getOrderDetails={getOrderDetails}
                                                // isPace={isPace}
                                                // />
                                            )}
                                            {(checkPermissions([permissions.update_orders], "DME") && order.status !== 'Canceled') && (
                                                <RequestExchange
                                                    renderButton={true}
                                                    patientId={order.patient_id}
                                                    user={order}
                                                    table={true}
                                                    addMore={true}
                                                    isExchange={true}
                                                    orderId={order.delivery_order_id}
                                                    exchangeId={order.exchange_order_id}
                                                    addHistory={addHistory}
                                                    getOrderDetails={getOrderDetails}
                                                    isPace={isPace}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {order.deliveredItems !== [] && (
                                <ItemsTable
                                    userType={userType}
                                    items={order.deliveredItems}
                                    availableData={assetsArray}
                                    selectAssetHandle={selectAssetHandle}
                                    dme_organization_id={dme_organization_id}
                                    order_dme_organization_id={order.dme_organization_id}
                                    handleCheckboxChange={handleCheckboxChange}
                                    selectedItems={selectedItems}
                                    order={order}
                                    setOrder={setOrder}
                                    setCurrentSpecialId={setCurrentSpecialId}
                                    type={'delivery'}
                                    invalidLotNo={invalidLotNo}
                                    orderId={orderId}
                                />
                            )}
                            <Grid container
                                  justify={"space-between"}
                                  className={classes.tableFooter}>
                                <div>
                                {
                                    (checkPermissions([permissions.delete_orders], 'Hospice') && order.status === "Open") &&
                                    <Button
                                        variant={"outlined"}
                                        color={"primary"}
                                        onClick={handleItemsRemove}
                                        disabled={selectedItems.length !== 0 ? disableRemoveItems : true}
                                        startIcon={<ClearIcon/>}
                                        style={{fontWeight: 'bold', fontSize: 12, border: '1px solid #00223D'}}
                                    >
                                        REMOVE SELECTED
                                    </Button>
                                }
                                {
                                    (checkPermissions([permissions.delete_orders], 'DME') && order.status !== 'Canceled') &&
                                    <Button
                                        variant={"outlined"}
                                        color={"primary"}
                                        onClick={handleItemsRemove}
                                        disabled={selectedItems.length !== 0 ? disableRemoveItems : true}
                                        startIcon={<ClearIcon/>}
                                        style={{fontWeight: 'bold', fontSize: 12, border: '1px solid #00223D'}}
                                    >
                                        REMOVE SELECTED
                                    </Button>
                                }
                                {disableRemoveItems && <span style={{color: "red"}}> Button disabled. Only item pair can be removed. </span>}
                            </div>


                                <div>

                                </div>
                            </Grid>
                        </Grid>

                        <Grid ref={itemsSection} container className={`generaltable`}>
                            <Grid container style={{display: 'flex', justifyContent: 'space-between', paddingTop: 36}}>
                                <Grid item className={classes.detailsTitle}>Reclaimed items</Grid>
                                <Grid item className={classes.tableHeader}>
                                </Grid>
                                {/*<Grid item>*/}
                                {/*    {checkPermissions([permissions.update_orders], "Hospice") && (order.status === "Open" || order.status === "Pending") && (*/}
                                {/*        <RequestPickup showPickup={true}*/}
                                {/*                       name={order.first_name + " " + order.last_name}*/}
                                {/*                       address={order.address2 ? order.address1 + " " + order.address2 : order.address1}*/}
                                {/*                       patient_id={order.patient_id}*/}
                                {/*                       addMore={true}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*    {checkPermissions([permissions.update_orders], "DME") && (*/}
                                {/*        <RequestPickup showPickup={true}*/}
                                {/*                       name={order.first_name + " " + order.last_name}*/}
                                {/*                       address={order.address2 ? order.address1 + " " + order.address2 : order.address1}*/}
                                {/*                       patient_id={order.patient_id}*/}
                                {/*                       addMore={true}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*</Grid>*/}
                            </Grid>

                            {order.pickupItems !== [] && (
                                <ItemsTable
                                    userType={userType}
                                    items={order.pickupItems}
                                    availableData={assetsArray}
                                    selectAssetHandle={selectAssetHandle}
                                    dme_organization_id={dme_organization_id}
                                    order_dme_organization_id={order.dme_organization_id}
                                    handleCheckboxChange={handleCheckboxChange}
                                    selectedItems={selectedItems}
                                    order={order}
                                    setCurrentSpecialId={setCurrentSpecialId}
                                    type={'pickup'}
                                    setOrder={setOrder}
                                    orderId={orderId}
                                />
                            )}
                            <Grid container
                                  justify={"space-between"}
                                  className={classes.tableFooter}>
                                <div>
                                {
                                    (checkPermissions([permissions.delete_orders], 'Hospice') && order.status === "Open") &&
                                    <Button
                                        variant={"outlined"}
                                        color={"primary"}
                                        onClick={handleItemsRemove}
                                        disabled={selectedItems.length !== 0 ? disableRemoveItems : true}
                                        startIcon={<ClearIcon/>}
                                        style={{fontWeight: 'bold', fontSize: 12, border: '1px solid #00223D'}}
                                    >
                                        REMOVE SELECTED
                                    </Button>
                                }
                                {
                                    checkPermissions([permissions.delete_orders], 'DME') &&
                                    <Button
                                        variant={"outlined"}
                                        color={"primary"}
                                        disabled={selectedItems.length !== 0 ? disableRemoveItems : true}
                                        onClick={handleItemsRemove}
                                        startIcon={<ClearIcon/>}
                                        style={{fontWeight: 'bold', fontSize: 12, border: '1px solid #00223D'}}
                                    >
                                        REMOVE SELECTED
                                    </Button>
                                }
                                {disableRemoveItems && <span style={{color: "red"}}> Button disabled. Only item pair can be removed. </span>}
                                </div>
                                <div>

                                    {(order.status === 'Open' && (checkPermissions([permissions.update_orders], 'DME') && order.status === "Open")) && (
                                        <Button
                                            variant={"contained"}
                                            color={"primary"}
                                            onClick={processOrderButton}
                                            startIcon={<DoneIcon/>}
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: 12,
                                                border: '1px solid #00223D',
                                                textTransform: 'uppercase'
                                            }}
                                        >Process order</Button>
                                    )}
                                    {(enableCancelOrder && order.status === 'Processing' && (checkPermissions([permissions.cancel_orders], 'DME'))) && (
                                        <Button
                                            variant={"outlined"}
                                            color={"primary"}
                                            onClick={() => {
                                                setShowOrderStatusModal(true);
                                                setNewOrderStatus("Canceled");
                                            }}
                                            startIcon={<ClearIcon/>}
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: 12,
                                                border: '1px solid #00223D',
                                                textTransform: 'uppercase',
                                                height: 40,
                                                marginRight: 12,
                                            }}
                                        >Cancel order</Button>
                                    )}
                                    {(order.status === 'Processing' && (checkPermissions([permissions.update_orders], 'DME'))) && (
                                        <Button
                                            variant={"contained"}
                                            color={"primary"}
                                            onClick={completeOrderButton}
                                            startIcon={<DoneIcon/>}
                                            style={{
                                                fontWeight: 'bold',
                                                fontSize: 12,
                                                border: '1px solid #00223D',
                                                textTransform: 'uppercase'
                                            }}
                                        >Complete order</Button>
                                    )}
                                    {enableCancelOrder && order.status === 'Open' && (
                                        <Button
                                            variant={"outlined"}
                                            // className={classes.middle}
                                            onClick={() => {
                                                setShowOrderStatusModal(true);
                                                setNewOrderStatus("Canceled");
                                            }}
                                            startIcon={<ClearIcon/>}
                                            style={{
                                                fontWeight: 'bold',
                                                marginLeft: 24,
                                                fontSize: 12,
                                                border: '1px solid #00223D',
                                                textTransform: 'uppercase',
                                                height: 40
                                            }}
                                        >Cancel order</Button>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container style={{
                            backgroundColor: 'white',
                            padding: 24,
                            marginTop: 44,
                            border: '1px solid #F0F0F0',
                            maxWidth: 1428
                        }}>
                            <Grid container>
                                <FileUpload
                                    files={files}
                                    setFiles={setFiles}
                                    existing={order.files}
                                    markFileAsRemoved={markFileAsRemoved}
                                    orderId={orderId}
                                    setIsLoading={setIsLoading}
                                />
                            </Grid>
                            <Grid item>
                                <div className={classes.title}>Order notes</div>
                            </Grid>
                            <Grid container ref={notesSection}
                                // className={classes.columns}
                            >
                                <div
                                    style={{paddingTop: 24, width: '100%', wordBreak: 'break-word'}}>
                                    {order.notes && (
                                        <div style={{paddingBottom: 12}}>
                                            {order.notes.map((note, index) => {
                                                return (
                                                    <div key={index} className={classes.notesSection}>
                                                        <div style={{
                                                            fontSize: 14,
                                                            color: '#2E3236'
                                                        }}>{note.description}</div>
                                                        <div style={{
                                                            fontStyle: 'italic',
                                                            color: "#5D6770"
                                                        }}>{note.first_name + " " + note.last_name + ", " + parseTimeWithUserTimezone(note.created_date)}</div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                    <TextField
                                        placeholder="Some additional notes can be put here..."
                                        label='Add additional note'
                                        multiline
                                        name={patientRecordNames.note}
                                        rows={2}
                                        rowsMax={9}
                                        value={currentNote}
                                        variant={'outlined'}
                                        className={classes.noteInput}
                                        onChange={(e) => {
                                            setCurrentNote(e.target.value);
                                            if (e.target.value !== "") {
                                                setIsUnsavedNote(true);
                                            } else {
                                                setIsUnsavedNote(false);
                                                setShowWarning(false);
                                            }
                                        }}
                                        onBlur={(e) => {
                                            if (currentNote !== "") {
                                                setShowWarning(true);
                                            } else {
                                                setShowWarning(false);
                                            }
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: {letterSpacing: 0.39,}
                                        }}
                                        inputProps={{maxLength: maxInputLength.L1000}}
                                        helperText={currentNote && `${currentNote.length < maxInputLength.L1000 ? currentNote.length.toString() + `/${maxInputLength.L1000}` : currentNote.length.toString() + `/${maxInputLength.L1000} You have reached the character limit for one note.`}`}
                                        error={currentNote && currentNote.length === maxInputLength.L1000}
                                    /> {showWarning && (
                                    <p style={{textAlign: 'left', color: '#FF0000'}}>You may have forgotten to submit a
                                        note.</p>)}
                                    <div className={classes.div}>
                                        <div style={{paddingTop: 10}}
                                            // onClick={handleClick33}
                                        >
                                            {/*<Button*/}
                                            {/*    variant={"outlined"}*/}
                                            {/*>Show history</Button>*/}
                                        </div>
                                        <div style={{
                                            paddingTop: 10,
                                            paddingBottom: 12
                                        }}>
                                            <Button
                                                id={'section1'}
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={addOrderNote}
                                                disabled={saveNoteLoading}
                                                style={{
                                                    fontWeight: 'bold',
                                                    textTransform: 'uppercase'
                                                }}
                                                onMouseDown={(e) => e.preventDefault()}
                                            >Add Note</Button>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{borderTop: '1px solid #F0F0F0'}}>
                                <Grid item style={{paddingTop: 32, paddingBottom: 24}}>
                                    <div className={classes.title}>Order Logs</div>
                                </Grid>

                                <CustomAccordion history={order.historyGroup}/>
                            </Grid>
                        </Grid>
                        <Grid container justify='flex-end'>
                            <Button
                                // className={classes.buttonGrey}
                                variant="contained"
                                color='primary'
                                style={{
                                    marginTop: 24,
                                    marginBottom: 12
                                }}
                                startIcon={<ArrowUpwardIcon/>}
                                onClick={() => goToSection('top')}
                            >
                                BACK TO TOP
                            </Button>
                        </Grid>


                    </Grid>
                )}

                <Popover
                    className={classes.popoverBottom}
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className={classes.popover}>
                        <h3 className={classes.subtitle}>Status History</h3>
                        {order.history && order.history.map((action, index) => {
                            let description = action.description;
                            let row = description.split(":");
                            return (
                                <div key={index} className={classes.status}>
                                    <span className={classes.bold}>{row[0] + ":"}</span>{row[1] + ":" + row[2]}
                                </div>
                            )
                        })}
                    </div>
                </Popover>

                {/*Add item to inventory modal*/}
                <Dialog
                    open={showInventoryModal}
                    onClose={inventoryModalCloseHandler}
                >
                    <DialogTitle>{alertTitles.addNewAsset}</DialogTitle>
                    <DialogContent className={classes.dialog}>
                        <div className={classes.messages}>{alertMessages.assetNotFound}</div>
                        <div className={classes.warehouseSelect}>
                            <div className={classes.subtitleDialog}>Warehouse:</div>
                            <Select
                                placeholder={placeholders.selectWarehouse}
                                onChange={(e) => {
                                    setNewAsset({...newAsset, warehouse_id: e.value, warehouse_name: e.label})
                                }}
                                options={warehouseList}
                                value={newAsset.warehouse !== null && [{
                                    value: newAsset.warehouse_id,
                                    label: newAsset.warehouse_name
                                }]}
                            />
                        </div>
                        <div className={classes.messages}>{alertMessages.assetQuestion}</div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={inventoryModalCloseHandler} variant="outlined" color="secondary">
                            {buttonText.cancel}
                        </Button>
                        <Button onClick={addToInventory} variant="contained" color="primary">
                            {buttonText.yes}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showOrderStatusModal}
                    onClose={statusModalCloseHandler}
                >
                    <DialogTitle>{alertTitles.changeStatus}</DialogTitle>
                    <DialogContent className={classes.dialog}>
                        {
                            (newOrderStatus === 'Processing') && (
                                <div className={classes.messages}>{alertMessages.changeNewOrderStatus}</div>
                            )
                        }
                        {
                            (newOrderStatus === 'Completed') && (
                                <div className={classes.messages}>{alertMessages.changeProcessingOrderStatus}</div>
                            )
                        }
                        {
                            (newOrderStatus === 'Canceled') && (
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div className={classes.messages}>{alertMessages.cancelOrder}</div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <InputLabel>Cancel Reason</InputLabel>
                                        <Select
                                            isSearchable
                                            options={[
                                                {value: "Not Needed", label: "Not Needed"},
                                                {value: "Duplicate Order", label: "Duplicate Order"},
                                                {
                                                    value: "Patient Expired/Discharged",
                                                    label: "Patient Expired/Discharged"
                                                },
                                                {value: "Other", label: "Other"}
                                            ]}
                                            onChange={(event) => setCancelReason(event.value)}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={statusModalCloseHandler} variant="outlined" color="secondary">
                            {buttonText.cancel}
                        </Button>
                        <Button
                            disabled={newOrderStatus === 'Canceled' && cancelReason === ''}
                            onClick={() => changeOrderStatus(newOrderStatus)}
                            variant="contained" color="primary">
                            {buttonText.yes}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={showError}
                >
                    <DialogContent style={{paddingLeft: "18px", fontSize: "16px"}}>
                        You may have forgotten to submit a note.
                    </DialogContent>
                    <DialogActions>
                        <Grid container style={{display: 'flex', justifyContent: 'flex-end', padding: "10px"}}
                              spacing={1}>
                            <Grid item>
                                <Button variant={'contained'} color={'primary'} onClick={() => submitWithUnsavedNote()}>
                                    Submit with note
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant={'outlined'} color={'primary'} onClick={() => {
                                    order.status === "Open" && processOrderButton(false);
                                    order.status === "Processing" && completeOrderButton(false);
                                }}>
                                    Submit without any notes
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant={'outlined'} color={'primary'} onClick={() => setShowError(false)}>
                                    Edit note
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </Grid> : ''}
        </Grid>
    )
}

const useStyles = makeStyles((theme) => (exchangeOrderStyle(theme)));
