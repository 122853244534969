import React from "react";
import {dollarCurrencyFormatter} from "../../../../common/utils/utils";
import {BRStyles} from "../BusinessReview";

export const BRDisplayQuarterTable = ({values}) => {
    const classes = BRStyles();
    return (
        <table style={{textAlign: "left"}}>
            <tbody>
            <tr>
                <th className={classes.tdHeaderCell}>Total</th>
                <th className={classes.tdHeaderCell}>Type</th>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>Total Patient Days</td>
                <td className={classes.tdDataCell}>{values.totalPatientDays.toLocaleString()}</td>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>CPPD - Patient Days</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.cppdPrice)}</td>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>Total CPPD - Per Diem</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.totalCppdPrice)}</td>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>Total DME Spend</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.totalDmeSpend)}</td>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>Outlier Total</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.outlierTotal)}</td>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>Outlier % of Spend</td>
                <td className={classes.tdDataCell}>{values.outlierSpend}%</td>
            </tr>
            <tr>
                <td className={classes.tdDataCell}>Cost Per Patient Day</td>
                <td className={classes.tdDataCell}>{dollarCurrencyFormatter(values.costPerPatientDay)}</td>
            </tr>
            </tbody>
        </table>
    )
}