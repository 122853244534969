import React, {useEffect, useState} from 'react'
import { useIdleTimer } from 'react-idle-timer'
import {useHistory, withRouter} from "react-router-dom";
import {INACTIVITY_ALERT_TIMEOUT, INACTIVITY_EXTRA_TIMEOUT} from "../../constants/inactivityTimer/inactivityTimer";
import {useDispatch} from "react-redux";
import {logout} from "../../redux/actions";
import jwt from "jwt-decode";
import DialogContent from "@material-ui/core/DialogContent";
import Countdown from "react-countdown";
import {DialogActions} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {endpoints} from "../../constants/endpoints";

const InactivityTimer = () => {
    const [open, setOpen] = useState(false);
    let history = useHistory();
    const dispatch = useDispatch();
    let timeoutValue = INACTIVITY_ALERT_TIMEOUT;
    const logUserOut = () => {
        localStorage.setItem('key', JSON.stringify(null));
        dispatch(logout());
        history.push('/login');
        setOpen(false);
    };

    const renderer = (props) => {
        let {minutes, seconds, completed} = props;
        if (completed) {
            logUserOut();
            return null;
        } else {
            return (
                <span>
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
            );
        }
    };

    const isAuthenticated = () => {
        const auth = JSON.parse(localStorage.getItem('key'));
        if (auth) {
            const token = auth.accessToken;
            if (!token) {
                return false;
            }
            const decoded = jwt(auth.accessToken);
            const {exp} = decoded;
            const currentTime = new Date().getTime();
            return currentTime < exp * 1000;
        } else {
            history.push({
                pathname: `/login`
            });

        }
    };

    const handleUserInput = () => {
        renewSession();
        setOpen(false);
    }

    const renewSession = async () => {
        if(localStorage.getItem('key') !== "null") {
            const token = jwt(JSON.parse(localStorage.getItem('key')).accessToken);
            const {exp} = token;
            const currentTime = new Date().getTime();
            if (isAuthenticated()) {
                try {
                    if (currentTime > exp * 1000 - 60000*2) {
                        const userData = JSON.parse(localStorage.getItem("key"));
                        const promise = await fetch(endpoints.renewLogin, {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json',

                            },
                            body: JSON.stringify({refreshToken: userData.refreshToken})
                        });
                        const response = await promise.json();
                        localStorage.setItem('key', JSON.stringify(response));
                    }
                } catch (error) {
                    logUserOut();
                }
            } else {
                logUserOut();
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(
            renewSession,
            60000,
        );
        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleOnIdle = () => {
        if (localStorage.getItem('key') !== "null") {
            setOpen(true);
        }
    }

    useIdleTimer({
        timeout: timeoutValue,
        onIdle: handleOnIdle,
        debounce: 500
    })

    return (
        <React.Fragment>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="u-pa-full u-fx u-fx-column u-fx-align-center">
                    <div className="u-mb-double">
                        Are you still there? If not, you’ll be logged out in{' '}
                        {
                            <Countdown
                                renderer={renderer}
                                precision={2}
                                date={Date.now() + INACTIVITY_EXTRA_TIMEOUT}
                            />
                        }
                    </div>
                    <DialogActions>
                        <Button
                            id="keep-me-logged-in-button"
                            variant="contained"
                            onClick={handleUserInput}
                            color="primary"
                        >
                            Keep me logged in
                        </Button>
                        <Button
                            id="logout-button"
                            variant="outlined"
                            onClick={logUserOut}
                            color="primary"
                        >
                            Logout
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    )
}


export default withRouter(InactivityTimer);
