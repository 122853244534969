import {Dialog} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {useEffect, useState} from "react";
import moment from 'moment'
import makeStyles from "@material-ui/core/styles/makeStyles";
import {findIndex, cloneDeep} from "lodash";
import {endpoints} from "../../../../constants/endpoints";


export default function NewPossibleDuplicates({isOpen, setShowPossibleDuplicates, possibleDuplicates}){
    const [countdown, setCountdown] = useState(10);
    const [profileReviewedOptions, setProfileReviewedOptions] = useState([]);
    const classes = useStyles();
    const token = JSON.parse(localStorage.getItem('key'));
    useEffect(()=> {
        if (!isOpen){
            setCountdown(10);
        }
        function check(){
            if (isOpen && countdown >0){
                setCountdown(prevState => prevState-1)
            }
        }
        const timerId = setInterval(check, 1000);
        return () => clearInterval(timerId);

    },[isOpen])
    useEffect(()=> {
        if(possibleDuplicates.length > 0){
            let arr = [];
            possibleDuplicates.map((item, index) => {
                arr.push({patient_id: item.patient_id, reviewed: false});
            })
            setProfileReviewedOptions(arr);
        }
    },[possibleDuplicates])

    const reviewOption = (patientData) => {
        const index = findIndex(profileReviewedOptions, {patient_id: patientData.patient_id});
        let optionClone = cloneDeep(profileReviewedOptions);
        optionClone[index].reviewed = true;
        setProfileReviewedOptions(optionClone)
    }

    const recordReviewOptions = async () => {
        await fetch(endpoints.reviewProfileOptions, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token.accessToken
                },
                body: JSON.stringify({
                    reviewed_patient_ids: profileReviewedOptions,
                })
            })

    }

    return(
        <Dialog open={isOpen} fullWidth>
            <Grid className={classes.wrapper}>
                <Grid container>
                    <h2 className={classes.margin0}>Possible Duplicate Patient</h2>
                </Grid>
                <Grid container>
                    <h4>Please review the possible duplicate patients below to avoid duplicate profiles</h4>
                </Grid>
                <Grid container className={classes.maxH}>
                {possibleDuplicates.map((patient, index)=> {
                    return (
                        <Grid container key={index} justify={'space-between'} alignItems={'center'} alignContent={'center'} className={classes.container}>
                            <Grid item md={8} sm={8} xs={12}>
                                <Grid container>
                                    <Grid container>
                                        <Grid item xs={12}><strong>{patient.first_name} {patient.last_name} {patient.medical_record && `#${patient.medical_record}`}</strong></Grid>
                                        <Grid item xs={12}>{patient.address1} {patient.address2 && patient.address2}, {patient.city} {patient.state} {patient.zip}</Grid>
                                        <Grid item xs={12} className={classes.gray}>Created: {moment(patient.created_date).format("Lz")} by {patient.username} </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={4} sm={4} xs={12}>
                                <a target="_blank" href={`${process.env.REACT_APP_API_URL}patients/patientProfile/${patient.patient_id}`}>
                                    <Button fullWidth variant={'contained'} color={'primary'} onClick={() => reviewOption(patient)}
                                >Review Profile</Button>
                                </a>

                            </Grid>
                        </Grid>
                    )
                })}
                </Grid>
                <Grid container justify={'flex-end'} alignContent={'center'} alignItems={'center'} className={classes.marginTop}>
                    {countdown > 0 && (
                        <span className={classes.marginRight}>You can close this modal in {countdown > 0 && countdown} seconds</span>
                    )}
                   <Button variant={'contained'} color={'primary'} disabled={countdown >0} onClick={()=> {
                       setShowPossibleDuplicates(false);
                       recordReviewOptions();
                   }}>Close and Continue</Button>
                </Grid>
            </Grid>

        </Dialog>
    )
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        padding:24
    },
    margin0: {
        margin:0
    },
    maxH: {
        maxHeight:320,
        overflow:'scroll'
    },
    gray: {
        color:'gray'
    },
    marginRight: {
        marginRight:8
    },
    marginTop: {
        marginTop:16
    },
    container: {
        border:'1px solid #00223D',
        padding:16,
        borderRadius:4,
        marginBottom:16
    }
}));
